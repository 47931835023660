import { useEffect, useRef, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, TextField, Typography } from '@mui/material';
import { ErrorAlert, WaitButton } from 'components';
import { useStatus } from 'redux-action-status';
import { useFieldComments, useConversationContext } from "./conversation-context";
import { useShareContext } from 'sections/app/share-context';
import { CommentHistory } from '../conversations';

const DataTableFieldComments = ({profile, fieldKey}) => {
  const classes   = buildStyles();
  const historyGridRef = useRef(null);
  const status    = useStatus("comments");
  const { share } = useShareContext();
  const { addComment } = useConversationContext();
  const { comments }  = useFieldComments(fieldKey);
  const [count, setCount] = useState(0);
  const [props, setProps]   = useState({content: "", });

  function onInputChanged(e){
    const prop    = e.currentTarget.id;
    const value   = e.currentTarget.value;
    setProps({...props, [prop]: value});
  }

  //Keep the grid scrolled to the bottom
  useEffect(() => {
    if(historyGridRef.current && comments.length > count){
      const scrollHeight = historyGridRef.current.scrollHeight;
      historyGridRef.current.scrollTop = scrollHeight;
      setCount(comments.length);
    }
  }, [historyGridRef, count, comments]);

  async function onSaveClicked(){
    console.log("Save the comment");
    if(!share || props.content.trim().length === 0) return null;   //can't save it if there's no conversation
    const result  = await addComment(props.content, fieldKey);
    if(result.isOk){
      setProps({...props, content: ""});
    }
  }

  return (
    <Grid container className={classes.commentRoot}>
      {!share && (
        <Grid container justifyContent="center" alignItems="center" className={classes.noConversationGrid}>
          <Typography className={classes.infoText}>Comments are hidden.  Select a conversation above to show comments.</Typography>
        </Grid>
      )}
      {share && (
        <>
          <Grid item sm={6} container sx={{ pr: 1 }} className={classes.historyGrid} ref={historyGridRef}>
            {/* <CommentHistory profile={profile} classes={classes} comments={comments}/> */}
            <CommentHistory comments={comments} />
          </Grid>
          <Grid item sm={6} container direction="column" alignItems="flex-start" className={classes.currentGrid}>
            <Grid item container flexGrow={1}>
              <TextField id="content" 
                value={props.content} 
                onChange={onInputChanged} 
                disabled={status.isWorking} 
                sx={{ fontSize: "0.8rem" }}
                fullWidth size="small" variant="outlined" placeholder="Enter your comment here"
                multiline rows={6}
                className={classes.commentTextField} 
                classes={{root: classes.inputRoot}} 
                inputProps={{ className: classes.commentInput }}
              />
            </Grid>
            <Grid item container>
              <Grid item xs={8}>
                <ErrorAlert error={status.error} className={classes.commentError}/>
              </Grid>
              <Grid item xs={4} container justifyContent="flex-end" alignItems="flex-start">
                <WaitButton id="saveButton" isWaiting={status.isWorking} disabled={props.content.trim().length === 0} onClick={onSaveClicked} size="small" variant="contained" color="primary" title="Save your comment" className={classes.miniButton}>Save</WaitButton>          
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default DataTableFieldComments;

const buildStyles   = makeStyles(theme => ({
  commentRoot  : {
    // padding     : theme.spacing(1),
  },
  noConversationGrid  : {
    height        : 50,
    width         : "100%",
  },
  infoText      : {
    fontSize      : 15,
    fontWeight    : 500,
    fontStyle     : "italic",
  },
  commentError  : {
    marginTop     : theme.spacing(0.5),
  },
  currentGrid   : {
    paddingRight  : theme.spacing(0.5),
  },
  historyGrid   : {
    maxHeight       : 200,
    height: 175,
    overflowY       : "auto",
    paddingLeft     : theme.spacing(0.5),
  },
  existingCommentGrid   : {
    flexWrap    : "nowrap",
    borderBottom  : `1px solid ${theme.palette.grey[200]}`, //`
    marginBottom  : theme.spacing(0.5),
  },
  inputGrid   : {
    flexGrow    : 1,
    padding     : `${theme.spacing(0)} ${theme.spacing(1)}`, //`
  },
  existingTextGrid : {
    padding     : `${theme.spacing(0.5)} ${theme.spacing(0.5)}`, //`
    border      : `1px solid ${theme.palette.grey[300]}`, //`
    borderRadius  : 3,
    background    : theme.palette.common.white,
  },
  commentTextField  : {
    background  : theme.palette.common.white,
  },
  inputRoot     : {
    "& .MuiInputBase-root"  : {
      padding     : `${theme.spacing(.5)} ${theme.spacing(1)}`, //`
    }
  },
  commentInput  : {
    fontSize: 14,
    lineHeight: "20px",
    // padding     : 4,
  },
  miniButton   : {
    marginTop   : theme.spacing(0.5),
    fontSize    : 12,
    boxShadow   : "none",
  },
  existingText   : {
    fontSize    : 13,
    display     : "inline-block",
  },
  iconCol     : {
    width         : 27,
    marginRight   : theme.spacing(0.5),
  },
  avatar      : {
    height        : 26.667,
    width         : 26.667,
    borderRadius  : "50%",
  }
}));

// function CommentHistory({comments, profile, classes}){
//   return (
//     <Grid container direction="column">
//       {comments.map(c => <Comment key={c.id} profile={profile} item={c} classes={classes} />)}
//     </Grid>
//   )
// }

// function Comment({profile, item, classes}){
//   const isMine      = item.createdBy === profile.uid;
//   const direction   = isMine ? "row-reverse" : "row";
//   const color       = isMine ? "primary" : "";
//   const textStyles  = isMine ? {textAlign: "end", marginLeft: 27}: {textAlign: "start", marginRight: 27};
//   const iconTip     = `${isMine ? "You" : "Them"}, ${formatDateFromNow(item.createdAt)}`;  //TODO: get the name of the sharer //`
//   const img         = isMine && profile.photo ? <img className={classes.avatar} src={profile.photo} alt="user profile"/> : <AccountCircleIcon fontSize="small" color={color}/>;
//   return (
//     <Grid item container direction={direction} className={classes.existingCommentGrid}>
//       <Grid item className={classes.iconCol}>
//         <Tooltip title={iconTip}>
//           {img}
//         </Tooltip>
//       </Grid>
//       <Grid item className={classes.existingTextGrid} style={textStyles}>
//         <Typography className={classes.existingText}>{item.content}</Typography>
//       </Grid>      
//     </Grid>
//   );
// }