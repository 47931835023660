import { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// import makeStyles from '@mui/styles/makeStyles';
import { ItemList } from 'components';
import { loadFeedback } from 'store/actions/admin-actions';
import { useStatus } from 'redux-action-status';
import FeedbackItem from './feedback-item';

const FeedbackView = () => {
  // const classes   = buildStyles();
  const [view, setView]      = useState("all");
  const feedback  = useSelector(state => state.admin.feedback);
  const status    = useStatus("feedback");
  const dispatch  = useDispatch();

  useEffect(() => {
    onRefresh();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered  = useMemo(() => {
    if(feedback) return filterList(feedback, view);
    else return null;
  }, [view, feedback]);

  function onRefresh(){
    dispatch(loadFeedback());
  }

  function onAction(action, item){
    console.log("action triggered", action);
  }

  return (
    <>
      <ItemList config={listConfig} items={filtered} status={status} onRefresh={onRefresh} onFilter={v => setView(v)} onAction={onAction} ItemComponent={FeedbackItem}/>
    </>
  );
}

export default FeedbackView;

// const buildStyles   = makeStyles(theme => ({
  
// }));

function filterList(list, view){
  switch(view){
    case "all"  : return list;
    case "happy"  : return _.filter(list, item => item.feedbackType === "happy");
    case "sad"  : return _.filter(list, item => item.feedbackType === "sad");
    case "bug" : return _.filter(list, item => item.feedbackType === "bug");
    default: return list;
  }
}

const listConfig  = {
  title   : "Feedback",
  cols    : [
    {id: 0, label: "Date", className: "active", style: {width: 150}},
    {id: 10, label: "Type", className: "", style: {width: 30}},
    {id: 20, label: "Sender", className: ""},
    {id: 30, label: "Role", className: ""},
    {id: 40, label: "Message", className: "", style: {minWidth: 100}},
    {id: 50, label: "", className: ""},
  ],
  filters : [
    {id: 0, label: "All", value: "all", icon: "clear_all" },
    {id: 10, label: "happy", value: "happy", icon: "sentiment_very_satisfied"},
    {id: 20, label: "sad", value: "sad", icon: "sentiment_very_dissatisfied"},
    {id: 30, label: "bugs", value: "bug", icon: "bug_report"},
  ],
};