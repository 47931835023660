import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import { LoadingBar } from 'components';
import DataTableRegion from './data-table-region';
import { selectProfile } from 'store/selectors/app-selectors';
import { useFormContext } from 'sections/forms/form-context';
import { useFormValues } from 'sections/financials/form-hooks';

export interface IDataTableProps {
  isFirstExpanded: boolean;
}

const DataTable = ({isFirstExpanded}: IDataTableProps) => {
  const { regions } = useFormContext();
  const profile   = useSelector(selectProfile);
  const values = useFormValues();

  if(!regions || !values?.a_isLoaded){
    return (
      <Grid id="data-table" container alignItems="flex-start" justifyContent="center" minHeight={200} p={2}>
        <LoadingBar message="Loading..." sx={{ width: "33%" }}/>
      </Grid>
    );
  }

  const myRegions = regions.filter(r => r.type !== "summary" && r.id !== "docs" && r.id !== "review");
  const rows = myRegions.map((reg, index) => <DataTableRegion key={reg.id} region={reg} isDefaultExpanded={isFirstExpanded && index === 0} values={values} profile={profile} />);

  return (
    <Grid id="data-table" container justifyContent="center" pb={0} fontSize={14}>
      <Grid item xs={12}>
        {rows}
      </Grid>
    </Grid>
  );
}

export default DataTable;