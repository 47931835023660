import { createSelector } from "reselect";
import { ClientDocument, ExportTemplate, FormInfo, FormSummary, RowItem, Share, ShareRequest, TopicItem } from "types";
import { AttorneySlice } from "../reducers/attorney-slice";
import { ClientListProfile } from "@/types/attorney-view-types";
import { orderBy } from "lodash";

type SelectorState = {
  attorney: AttorneySlice;
  share: {
    requests: ShareRequest[] | null;
    shares: Share[] | null;
  };
}

const _getFormInfos = (state: SelectorState) => state.attorney.clientData?.forms as FormInfo[];
const _getAttorneyForms = (state: SelectorState) => state.attorney.forms;
const _getCommonForms = (state: SelectorState) => state.attorney.commonForms;
const _getTemplates = (state: SelectorState) => state.attorney.templates;
const _getClientList = (state: SelectorState) => state.attorney.clients;
const _getShareRequests = (state: SelectorState) => state.share.requests;
const _getShares = (state: SelectorState) => state.share.shares;
const _getClientId = (state: SelectorState) => state.attorney.currentClientId;
const _inItemId = (state: SelectorState, itemId: any) => itemId;

export const selectAttorneyForms = createSelector(
  [_getAttorneyForms],
  rawForms => {
    if(!rawForms) return null;
    const myForms: FormSummary[] = rawForms.map((f: any) => ({ ...f, formGroup: "My" }));
    return myForms;
  }
);

export const selectCommonForms = createSelector(
  [_getCommonForms],
  (common) => {
    if(!common) return null;
    const commonForms = Object.keys(common)?.map(key => (common as any)[key].map((f: any) => ({ ...f, formGroup: key }))).flat();
    return commonForms;
  }
);

export const selectAllForms = createSelector(
  [_getAttorneyForms, _getCommonForms],
  (mine, common) => {
    if(!mine || !common) return null;
    const myForms = mine.map((f: any) => ({ ...f, formGroup: "My" }));
    const commonForms = Object.keys(common)?.map(key => (common as any)[key].map((f: any) => ({ ...f, formGroup: key }))).flat();
    const available: FormSummary[] = [...myForms, ...commonForms];

    return available;
  }
);

//== Selects the form from the attorney / common forms based on the form id provided
export const selectFormDefinition = createSelector(
  [_getAttorneyForms, selectCommonForms, _inItemId],
  (mine, common, formId) => {
    if(!mine || !common) return null;
    let found = mine.find(f => f.id === formId);
    if(!found) found = common.find((f: any) => f.id === formId);
    return found ?? null;
  }
);

export const selectClientList = createSelector(
  [_getClientList, _getShareRequests, _getShares],
  (profiles, requests, shares) : ClientListProfile[] => {

    //create an object of profiles by email
    let items = profiles.reduce((acc, p) => {

      const searchString = `${p.email} ${p.firstName}${p.lastName ?? ""}`.toLowerCase();
      let item: ClientListProfile = { ...p, searchString };
      
      const share = shares?.find(s => s.id === p.shareId);
      const request = requests?.find(r => r.id === p.shareRequestId);
      if(share)  item.share = share;
      if(request) item.request = request;
      item.searchString = `${p.email} ${p.firstName}${p.lastName ?? ""}`.toLowerCase();

      acc[p.email] = item;
      
      return acc;
    }, {} as Record<string, ClientListProfile>);

    //add the requests that don't have a matching profile
    const clientRequests = requests?.filter(r => r.requestType === "client");
    if(clientRequests && clientRequests.length > 0) {
      items = clientRequests.reduce((acc, r) => {
        
        if(!acc[r.email]) {
          const share = shares?.find(s => s.requestId === r.id);
          const searchString = `${r.email} ${r.firstName}${r.lastName ?? ""}`.toLowerCase();
      
          const item: ClientListProfile = {
            id: share?.sharer ?? r.id,
            firstName: r.firstName,
            lastName: r.lastName,
            email: r.email,
            summary: r.message,
            share: share,
            request: r,
            searchString,
          };

          acc[r.email] = item;
        }

        return acc;

      }, items);
    }

    //convert it to an array
    const itemList = Object.keys(items).map(key => items[key]);
    return itemList;
  }
);

export const selectClientProfile = createSelector(
  [_getClientList, _inItemId],
  (profiles, clientId) => {
    if(!profiles || !clientId) return null;
    return profiles.find(p => p.id === clientId) ?? null;
  }
);

export const selectClientShare = createSelector(
  [_getClientId, _getClientList, _getShares],
  (clientId, clients, shares) => {
    if(!clientId || !clients || !shares) return null;

    const client = clients.find(c => c.id === clientId);
    if(!client) return null;
    const share = shares.find(s => s.id === client.shareId);
    return share ?? null;
  }
);

export const selectClientForms = createSelector(
  [_getClientId, _getClientList, _getShares],
  (clientId, clients, shares) => {
    if(!clientId || !clients || !shares) return [];

    const client = clients.find(c => c.id === clientId);
    if(!client) return [];

    const share = shares.find(s => s.id === client.shareId);
    if(!share?.forms) return [];

    const allForms = share.forms;
    const asAssigned = allForms.map(form => ({ ...form, shareId: share.id, isChecked: false, isSelected: false }));
    return asAssigned;
  }
);

export const selectClientDocs = createSelector(
  [selectClientShare],
  (share) => {
    if(!share?.documents) return [];

    const allDocs = share.documents;
    const fromClient = allDocs.filter(d => d.direction === "toClient");
    const items = fromClient.map(doc => ({ ...doc, shareId: share.id, isChecked: false, isSelected: false }));
    return items;
  }
);

export const selectClientUploads = createSelector(
  [selectClientShare],
  (share) => {
    if(!share?.documents) return [];
    const allDocs = share.documents;
    const toClient = allDocs.filter(d => d.direction === "fromClient");
    const items = toClient.map<RowItem<ClientDocument>>(doc => ({ ...doc, shareId: share.id, isChecked: false, isSelected: false }));
    return items;
  }
);

//== As the Attorney, selects the form for the current client
export const selectClientForm = createSelector(
  [_getClientId, _getShares, _getClientList, _inItemId],
  (clientId, shares, clients, documentId) => {
    if(!clientId || !shares || !documentId) return null;
    
    const client = clients.find(c => c.id === clientId);
    if(!client) return null;
    const share = shares.find(s => s.id === client.shareId);
    if(!share) return null;
    
    let form = share.forms?.find(f => f.formId === documentId) ?? null;
    if(form) form = { ...form, shareId: share.id };
    return form;
  }
);

//== For a given form, get the templates associated with the form.
export const selectClientFormTemplates = createSelector(
  [_getAttorneyForms, selectCommonForms, _getTemplates, _inItemId],
  (forms, commonForms, templates, formId) => {
    if(!forms || !templates || !formId) return null;

    //find the form definition
    let formDefinition = forms?.find(f => f.id === formId);
    if(!formDefinition) formDefinition = commonForms?.find((f: any) => f.id === formId);
    if(!formDefinition || !formDefinition.templates) return null;

    const templateIds = formDefinition.templates;
    const formTemplates = templates.filter(t => templateIds.includes(t.id));
    return formTemplates ?? null;
  }
);

//== Selects all the tempaltes for the current account
export const selectAccountTemplates = createSelector(
  [_getTemplates],
  (templates) => {
    if(!templates) return [];
    return templates;
  }
);

//== Selects the templates that are available for the current client
export const selectClientTemplates = createSelector(
  [selectClientForms, selectAllForms, _getTemplates],
  (clientForms, allForms, templates) => {
    if(!clientForms || !templates || !allForms) return [];

    //get the attorney forms for the assigned client forms (templates list is only on the attorney forms)
    const clientFormIds = clientForms.map(f => f.formId);
    const forms = allForms.filter(f => clientFormIds.includes(f.id));
    const templateIds = forms.map(f => f.templates).flat();
    const clientTemplates = templates.filter(t => templateIds.includes(t.id));
    //Now, for each client template, add the formId so generate can get the form definition
    const exportTemplates = clientTemplates.map<ExportTemplate>(t => ({ ...t, formId: forms.find(f => f.templates?.includes(t.id))?.id }));
    
    return exportTemplates;
  }
);

export const selectClientNotes = createSelector(
  [_getClientId, _getClientList],
  (clientId, clients) => {
    if(!clientId || !clients) return null;
    const client = clients.find(c => c.id === clientId);
    if(!client) return null;
    return client.notes;
  }
);

export const selectCurrentClientFormInfo = createSelector(
  [_getFormInfos, _inItemId],
  (formInfos, docId) => {
    if(!docId || !formInfos) return undefined;

    const info = formInfos?.find(form => form.id === docId)
    return info;
  }
);

//== Gets a list of conversation topics for the current client. The list
// consists of the forms and documents shared with the client.
export const selectClientTopics = createSelector(
  [_getClientId, selectClientShare],
  (clientId, share) => {
    if(!clientId || !share) return [];

    const forms: TopicItem[] = share.forms?.map(f => ({ topicType: "f", topicId: f.id, topicName: f.formName})) ?? [];
    const docs: TopicItem[] = share.documents?.map(d => ({ topicType: "d", topicId: d.id, topicName: d.name})) ?? [];

    return orderBy([...forms, ...docs], ["topicName"], ["asc"]);    
  }
);

