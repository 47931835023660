import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Divider, LinearProgress, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { AccountInvitation } from "types";
import { StatusKeys } from "helpers";
import { ErrorStatus, LoadingBar } from "components";
import ProviderProductCard from "./provider-product-card";
import ProviderInvite from "./provider-invite";
import { createAccountApi } from "store/actions/account-actions";


const ProviderWelcome = () => {
  const dispatch = useDispatch();
  const status = useStatus(StatusKeys.accounts);
  // const forceRefresh = useSelector<any, boolean>(state => state.account.needsRefresh);
  const invites = useSelector<any, AccountInvitation[]>(state => state.account.invitations);
  const hasInvites = invites?.length > 0;
  
  // const [showSubscribe, setShowSubscribe] = useState(false);

  const handleChooseProduct = async (product: any) => {
    console.log("Purchase product", product);
    const result = await dispatch(createAccountApi(product.id)) as any;
    if(result.isOk) {
      toast.success("Account created");
      console.log("Account created", result);
    }
  };

  // if(status.error) return <ErrorStatus statusKey={StatusKeys.accounts} sx={{ width: "100%" }} />;
  if(!status.isInitialized && !status.error) return <LoadingBar id="provider-welcome"/>;

  return (
    <Box sx={{ py: 4, px: 12 }}>
      <Stack id="provider-welcome" rowGap={2} alignItems="center" display="flex" width="100%">
        <Typography variant="h5" color="primary" mb={2}>Welcome to FormHop</Typography>

        <ErrorStatus statusKey={StatusKeys.accounts} sx={{ width: "50%" }} />

        {hasInvites && (
          <>
            <ProviderInvite invite={invites[0]}/>
            
            <Divider variant="middle" sx={{ my: 2, width: "10%" }}>
              <Typography variant="body1" color="grey.600">OR</Typography>
            </Divider>
          </>
        )}

        <ProviderProductCard onChoose={handleChooseProduct} />

      </Stack>
    </Box>
  );
};

export default ProviderWelcome;