import firebase, { getStorage } from 'config/firebase-config';
import { getFirestore } from 'config/firebase-config';
// import { asyncForEach } from 'helpers/general-helpers';

const firebaseActions   = {
  signIn: signIn,
  changePassword: changePassword,
  signOut: signOut,
  signUp: signUp,

  create: create,
  createOnly: (info, action) => create(info, action, false),
  createMulti: createMulti,
  getSingle: getSingle,
  updateSingle: updateSingle,
  setSingle: setSingle,         //creates or updates if already there (uses merge: true)
  setMulti: setMulti,           //creates or updates if already there (uses merge: true)
  deleteSingle: deleteSingle,
  deleteMulti: deleteMulti,
  getList: getList,
  collectionGroup: collectionGroup,

  uploadFile: uploadFile,
  getFileUrl: getFileUrl,
  downloadFile: downloadFile,
};


const FirebaseMiddleware = store => next => async action => {
    if(!action.firebase){
        return next(action);
    }

    const { type }  = action.firebase;    
    const result    = await firebaseActions[type](action.firebase, action);

    const nextType  = (!result.isOk && action.failType) ? action.failType : action.type;
    //remove the firebase section so we don't re-run this process...
    const { firebase, failType, ...nextAction } = action;
    const actionWithResult  = {
      // ..._.omit(action, ["firebase", "failType"]),
      ...nextAction,
      completedActions: [...(nextAction.completedActions || []), firebase],
      ...(result || {}),
      type  : nextType,
    };
    
    return next(actionWithResult);
    
}

export default FirebaseMiddleware;

async function create(info, action, andRead = true){
  try{
    let db      = getFirestore();
    let result  = null;

    if(info.key){
      //If there's a key, use that as the document key
      const doc   = db.collection(info.collection).doc(info.key);
      //TODO: implement merge for single create?
      // const merge = info.replace === true ? {} : { merge: true };
      result  = await doc.set(info.value);

      //TODO: result doesn't have the object, need to get it after I create  
      if(andRead === true){
        result  = (await doc.get()).data();
        result  = { ...result, id: info.key, isLoaded: true };
      }
      else{
        result  = {id: info.key, ...info.value, isLoaded: false, ref: doc};
      }
    }
    else{
      //otherwise, let firestore create an id for me
      const doc  = await db.collection(info.collection).add(info.value);
      if(andRead === true){
        result  = {id: doc.id, ...(await doc.get()).data(), isLoaded: true};
      }
      else{
        result  = {id: doc.id, ...info.value, isLoaded: false, ref: doc};
      }
      //TODO: leave it un-hydrated and wait for someone else to hydrate it?
    }

    // console.log(`Created new item in db collection ${info.collection}`, result);
    return {
      status  : "ok",
      isOk    : true,
      data    : result,
    };
  }
  catch(ex){
    console.error(`Failed to create new item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

//=== Adds multiple items to a collection
async function createMulti(info, action, andRead = true){
  try{
    let db = getFirestore();
    let results = [];

    let coll = db.collection(info.collection);
    

    for(const item of info.items){
      //if there is an item.key property, and the key is a function, then...
      if(info.key && typeof info.key === "function"){
        const key = info.key(item);
        const doc   = db.collection(info.collection).doc(key);
        const merge = info.replace === true ? {} : { merge: true };
        await doc.set(item, merge);
        if(andRead === true){
          results.push({id: key, ...(await doc.get()).data(), isLoaded: true});
        }
        else{
          results.push({id: key, ...item, isLoaded: false, ref: doc});
        }
      }
      else{
        //otherwise, let firestore create an id for me
        const doc  = await coll.add(item);
        if(andRead === true){
          results.push({id: doc.id, ...(await doc.get()).data(), isLoaded: true});
        }
        else{
          results.push({id: doc.id, ...info.value, isLoaded: false, ref: doc});
        }
      }
    }
    //TODO: leave it un-hydrated and wait for someone else to hydrate it?

    // console.log(`Created new item in db collection ${info.collection}`, result);
    return {
      status  : "ok",
      isOk    : true,
      data    : results,
    };
  }
  catch(ex){
    console.error(`Failed to create multiple new item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

export async function getSubCollection(docRef, subCollection, collection){
  let items     = [];

  try{
    const snapshot  = await docRef.collection(subCollection).get();
    snapshot.forEach(snap => {
      items.push({id: snap.id, ...snap.data()});
    });
  }
  catch(ex){
    if(ex.code === "permission-denied"){
      console.error(`Permission denied for subCollection '${subCollection}' of collection '${collection}'.`, ex);
    }
    else{
      console.debug(`subCollection ${subCollection} not found.`, ex);    
    }
  }

  return { [subCollection] : items };
}

async function getSingle(info, action){
  try{
    let db      = getFirestore();
    let result  = null;

    if(!info.key){
      throw new Error("Key is required for get.");
    }

    let docRef  = db.collection(info.collection).doc(info.key);
    result      = await docRef.get();
    if(result.exists){

      let data  = {id: result.id, ...result.data(), isLoaded: true}; //, ref: result};
      if(info.subCollections){
        let scPromises  = info.subCollections.map(sub => getSubCollection(docRef, sub, info.collection));
        const scResults  = await Promise.all(scPromises);
        scResults.forEach(r => data = {...data, ...r});
      }

      return {
        status  : "ok",
        isOk    : true,       
        data    : data,
      };
    }
    else{
      //Item doesn't exist
      if(info.mayNotExist === true){
        return {
          status: "ok",
          isOk: true,
          data: null,
          doesNotExist: true,
        };
      }

      return {
        status  : "error",        
        isOk    : false,       
        error   : { code: "not-found", message: "The requested item was not found."},
      };
    }
  }
  catch(ex){
    //TODO: handle permission denied as it may just not exist
    if(isInsufficientPermissions(ex) && info.mayNotExist === true){
      console.warn(`insufficient permissions to retrieve document at key ${info.key} of collection ${info.collection}, but mayNotExist is true`, { ex, info });

      return {
        status  : "ok",
        isOk    : true,
        data    : null,
        wasPermissionDenied  : true,
      }
    }
    // console.error(`Failed to retrieve document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,       
      error   : ex,
      request: info,
    };
  }
}

async function getList(info, action){
  try{
    let db      = getFirestore();
    let collection  = db.collection(info.collection);
    let snapshot    = null;
    if(info.query){
      //TODO: Support multiple queries for filtering (e.g. audit log date range)      
      // const queries = _.isArray(info.query[0]) ? info.query : [info.query];
      // collection    = _.reduce(queries, (coll, qu, i) => {
      //   return coll.where(qu[0], qu[1], qu[2])
      // }, collection);
      const fieldPath = info.query[0] === "__id__" ? firebase.firestore.FieldPath.documentId() : info.query[0];
      collection    = collection.where(fieldPath, info.query[1], info.query[2]);
    }
    if(info.order){
      collection    = collection.orderBy(info.order[0], info.order[1] || "asc");
    }
    
    snapshot      = await collection.get();
    
    let promises = [];
    let list = snapshot.docs.map(doc => {
      const item = {id: doc.id, ...doc.data()}; //, isLoaded: true};    
      if(info.subCollections){
        let scPromises  = info.subCollections.map(sub => getSubCollection(doc.ref, sub, info.collection).then(coll => {
          item[sub] = coll[sub];
        }));
        promises = [...promises, ...scPromises];
        // scResults.forEach(r => item = {...item, ...r});
      }
      return item;
    });

    await Promise.all(promises);
    
    return {
      status  : "ok",
      isOk    : true,
      data    : list,
    };
    
  }
  catch(ex){
    console.error(`Failed to retrieve documents for query`, ex);
    return {
      status  : "error",
      isOk    : false,       
      error   : ex,
      request: info,
    };
  }
}

async function collectionGroup(info, action){
  try{
    const db = getFirestore();

    if(!info.collection){
      throw new Error("Collection is required for collectionGroup.");
    }

    let collection  = db.collectionGroup(info.collection);
    if(info.query){
      const fieldPath = info.query[0] === "__id__" ? firebase.firestore.FieldPath.documentId() : info.query[0];
      collection    = collection.where(fieldPath, info.query[1], info.query[2]);
    }
    if(info.order){
      collection    = collection.orderBy(info.order[0], info.order[1] || "asc");
    }
    
    const snapshot = await collection.get();
    
    let list = snapshot.docs.map(doc => {
      const item = {id: doc.id, ...doc.data()};   
      return item;
    });

    return {
      status  : "ok",
      isOk    : true,
      data    : list,
    };
  }
  catch(ex){
    console.error(`Failed to retrieve documents for query`, ex);
    return {
      status  : "error",
      isOk    : false,       
      error   : ex,
      request: info,
    };
  }
}

async function updateSingle(info, action){
  try{
    let db      = getFirestore();
    // let result  = null;

    if(!info.key){
      throw new Error("Key is required for update.");
    }

    let docRef    = db.collection(info.collection).doc(info.key);
    await docRef.update(info.value);
    const doc     = await docRef.get();
    return {
      status  : "ok",
      isOk    : true,
      data    : { id: info.key, ...doc.data() }, //, isLoaded: true, ref: doc }
    };
  }
  catch(ex){
    console.error(`Failed to update document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function setSingle(info, action){
  try{
    let db      = getFirestore();
    // let result  = null;

    if(!info.key){
      throw new Error("Key is required for set single.");
    }

    let docRef = db.collection(info.collection).doc(info.key);
    await docRef.set(info.value, { merge: true});
    const doc     = await docRef.get();
    return {
      status  : "ok",
      isOk    : true,
      data    : { id: info.key, ...doc.data() }, //, isLoaded: true}, //, ref: doc }
    };
  }
  catch(ex){
    console.error(`Failed to update document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

//info.items = { key: value }
async function setMulti(info, action){
  try{
    let db      = getFirestore();
    // let result  = null;

    if(!info.items){
      throw new Error("Items is required for set multi.");
    }

    const keys = Object.keys(info.items);
    const results = {};

    for(const key of keys){
      let docRef = db.collection(info.collection).doc(key);
      const changes = info.items[key];
      await docRef.set(changes, { merge: true});
      const doc     = await docRef.get();
      results[key] = { id: key, ...doc.data() };
    }

    return {
      status  : "ok",
      isOk    : true,
      data    : results,
    };
  }
  catch(ex){
    console.error(`Failed to update document with key ${info.key}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function deleteSingle(info, action){
  try{
    let db      = getFirestore();
    
    if(!info.key || !info.collection){
      throw new Error("Collection and Key and required for delete.");
    }

    //TODO: Delete any subcollections of the document
    if(info.subCollections){
      for(const sub of info.subCollections){
        const subColl = db.collection(info.collection).doc(info.key).collection(sub);
        try{
          const subDocs = await subColl.get();
          subDocs.forEach(async doc => {
            await doc.ref.delete();
          });
        } catch(ex) { console.info(`no items found in subcollection ${sub}`, ex) }   //skip this, if there's no items in the subcollection
      }
    }

    //delete the item from the collection
    const doc   = db.collection(info.collection).doc(info.key);
    try{
      const result  = await doc.get();  //see if the document exists
      if(result.exists){
        await doc.delete();
      }
    }
    catch(ex){
      console.log("error trying to get document for delete", ex);
      return {
        status  : "error",
        isOk    : false,
        error   : ex
      };
    }

    // console.log(`Deleted item from db collection ${info.collection}`);
    return {
      status  : "ok",
      isOk    : true,
      data    : null,
    };
  }
  catch(ex){
    console.error(`Failed to delete item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function deleteMulti(info, action){
  try{
    let db      = getFirestore();
    
    if(!info.keys || !info.collection){
      throw new Error("Collection and Key and required for delete.");
    }

    //delete the item from the collection
    for(const key of info.keys){
      const doc   = db.collection(info.collection).doc(key);
      try{
        const result  = await doc.get();  //see if the document exists
        if(result.exists){
          await doc.delete();
        }
      }
      catch(ex){
        console.log("error trying to get document for delete", ex);
        return {
          status  : "error",
          isOk    : false,
          error   : ex
        };
      }
    }

    // console.log(`Deleted item from db collection ${info.collection}`);
    return {
      status  : "ok",
      isOk    : true,
      data    : null,
    };
  }
  catch(ex){
    console.error(`Failed to delete item in db collection ${info.collection}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function uploadFile(info, action){
  try{
    if(!info.value || !info.path){
      throw new Error("Value and Path are required for uploadFile.");
    }
    
    let storage = getStorage();
    let ref   = storage.ref(info.path);
    const result  =  await ref.put(info.value);
    
    return {
      status: "ok",
      isOk: true,
      data: result,
    };
  }
  catch(ex){
    console.error(`Failed to save file to path ${info.path}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function getFileUrl(info, action){
  try{
    if(!info.path){
      throw new Error("Path is required for getFileUrl.");
    }
    
    let storage = getStorage();
    let ref   = storage.ref(info.path);
    const url   = await ref.getDownloadURL();
    
    return {
      status: "ok",
      isOk: true,
      data: url,
    };
  }
  catch(ex){
    console.error(`Failed to get file url from path ${info.path}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }

}

async function downloadFile(info, action){
  try{
    if(!info.path){
      throw new Error("Path is required for downloadFile.");
    }
    
    let storage = getStorage();
    let ref   = storage.ref(info.path);
    // const blob = await ref.getBlob();
    const url   = await ref.getDownloadURL();
    
    // This can be downloaded directly:
    const response = await fetch(url);
    
    if(info.downloadType === "json"){
      const data = await response.json();
      return {
        status: "ok",
        isOk: true,
        data,
      };
    }
    else if(info.downloadType === "blob"){
      //Get the file blob
      const blob = await response.blob();

      if(!info.downloadFileName){   //If there's no downloadFileName, then just return the blob
        return {
          status: "ok",
          isOk: true,
          data: blob,
        };
      }
      else {
        //download the file and save it to the downloads directory with the original file name
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = info.downloadFileName;
        downloadLink.click();
        URL.revokeObjectURL(downloadLink.href);

        return {
          status: "ok",
          isOk: true,
          data: info.downloadFileName,
        };
      }
    }
    else{
      return {
        status: "ok",
        isOk: true,
        data: url,
      };
    }
    // let blob = await fetch(url).then(r => r.blob());
    // var xhr = new XMLHttpRequest();
    // xhr.responseType = 'blob';
    // xhr.onload = (event) => {
    //   var blob = xhr.response;
    // };
    // xhr.open('GET', url);
    // xhr.send();

    // return {
    //   status: "ok",
    //   isOk: true,
    //   data,
    // };
  }
  catch(ex){
    console.error(`Failed to download file from path ${info.path}`, ex);
    return {
      status  : "error",
      isOk    : false,
      error   : ex
    };
  }
}

async function signUp(info, action){
  let user  = firebase.auth().currentUser;
  if(user) return { user: user }
  else{    
    try{
      //NOTE: this will also trigger onAuthChanged in firebase-config
      const uname = info.username.toLowerCase();
      const result  = await firebase.auth().createUserWithEmailAndPassword(uname, info.password);
      return {
        ...result,
        isOk    : true,
        status  : "ok",
        user    : result.user,
      };
    }
    catch(ex){
      return {
        isOk      : false,
        status    : "error",
        error     : ex,
      };
    }
  }
}

async function signIn(info, action){
  const user  = firebase.auth().currentUser;
  if(user){ 
    return  { status: "ok", user: user, isAreadySignedIn: true };  
  }
  else{
    try{
      if(info.provider){
        const provider      = PROVIDERS[info.provider](); //new firebase.auth.GoogleAuthProvider();
        const response      = await firebase.auth().signInWithPopup(provider);
        return response;
      }
      else{
        const response      = await firebase.auth().signInWithEmailAndPassword(info.username, info.password);
        return response;
      }
    }
    catch(ex){
        console.error("Exception signing in: ", ex);
        return { status: "error", error  : ex };
    }
  }
}

async function changePassword(info, action){
  const user  = firebase.auth().currentUser;
  if(user){
    try {
      //re-authenticate to verify the current password
      await user.reauthenticateWithCredential(firebase.auth.EmailAuthProvider.credential(user.email, info.oldPassword));
      //change the password
      await user.updatePassword(info.newPassword);
      return { status: "ok", isOk: true, data : null };
    }
    catch(ex){
      console.error("Exception changing password: ", ex);
      return { status: "error", error: ex };
    }
  }
}

async function signOut(info, action){
  const user  = firebase.auth().currentUser;
  if(user){
    await firebase.auth().signOut();
    return { status: "ok", data : null };
  }
}

const PROVIDERS   = {
  google      : () => { return new firebase.auth.GoogleAuthProvider(); },
  facebook    : () => { return new firebase.auth.FacebookAuthProvider(); },
  twitter     : () => { return new firebase.auth.TwitterAuthProvider(); },
  microsoft   : () => { return new firebase.auth.OAuthProvider("microsoft.com"); },
};


function isInsufficientPermissions(error){
  return error.message === "Missing or insufficient permissions.";
}