import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MuiFileInput } from "mui-file-input";
import { CloudUpload as CloudUploadIcon, Close as CloseIcon, ErrorOutline as ErrorOutlineIcon, Work, AccountCircle } from '@mui/icons-material';
import { useStatus } from "redux-action-status";
import { addAttorneyForm, updateAttorneyForm } from "store/actions/attorney-actions";
import { FormFields, FormSummary } from "types";
import { StatusKeys } from "helpers";
import { parseFbResult } from "store/store-helpers";

interface IAddFormDialogProps {
  open: boolean;
  onClose: (isSaved: boolean) => void;
  toEdit?: FormSummary;
}

const getInitialProps = (props: FormSummary | undefined): FormFields => {
  if(!props) return {name: "", category: "", description: "", isClientForm: true, isStarred: false, file: null};
  return {name: props.name, category: props.category ?? "", description: props.description ?? "", isClientForm: !!props.isClientForm, isStarred: !!props.isStarred, file: null};
}

const AddFormDialog = ({open, onClose, toEdit}: IAddFormDialogProps) => {
  const status = useStatus(StatusKeys.forms);
  const initialProps = getInitialProps(toEdit);
  const [props, setProps]   = useState<FormFields>(initialProps);
  const [error, setError]   = useState<string>("");
  const dispatch  = useDispatch();

  const isEditing = useMemo(() => Boolean(toEdit), [toEdit]);

  const filePlaceholder = props.file ? props.file.name : "Upload form*";

  const clearFields = () => {
    setProps({name: "", category: "", description: "", isClientForm: false, isStarred: false, file: null });
    setError("");
  }

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleToggle = (value: boolean) => (e: any) => {
    setProps({...props, isClientForm: value});
  }

  const onFileChange = (e: File | null) => {
    console.log("file change", e);
    setProps({...props, file: e});

  }

  const onCancel = () => {
    clearFields();
    onClose(false);
  }

  const onSave = async () => {
    if(isEditing) await saveExistingForm();
    else await saveNewForm();
  };

  const saveNewForm = async () => {
    console.log("save new form", props);
    if(!props.name || !props.category || !props.file) return;

    const result = await dispatch(addAttorneyForm(props));
    console.log("add form result", result);
    const pr = parseFbResult(result);

    if(pr.isOk){
      clearFields();
      onClose(true);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  }

  const saveExistingForm = async () => {
    console.log("save existing form", props);
    if(!toEdit || !props.name || !props.category) return;

    const result = await dispatch(updateAttorneyForm(toEdit.id, props));
    console.log("update form result", result);
    const pr = parseFbResult(result);

    if(pr.isOk){
      clearFields();
      onClose(true);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  }

  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="form-dialog-title" fullWidth maxWidth="sm">
      
      <DialogTitle id="form-dialog-title">{isEditing ? "Edit" : "Add"} Form</DialogTitle>
      
      <DialogContent>
        
        {error && (
          <Alert icon={<ErrorOutlineIcon fontSize="inherit" />} severity="error">
            {error}
          </Alert>
        )}

        <TextField autoFocus margin="dense" id="name" label="Name*" type="text" fullWidth value={props.name} onChange={handleChange} disabled={status.isWorking}/>
        <TextField margin="dense" id="category" label="Category*" type="text" fullWidth value={props.category} onChange={handleChange}  disabled={status.isWorking}/>
        
        <Grid container spacing={2} py={2} alignContent="center">
          <Grid xs={6}>
            <Typography variant="body2">Who fills this form?</Typography>
          </Grid>
          <Grid container xs={6} justifyContent="flex-end" px={2}>
            <ToggleButtonGroup>
              <Tooltip title="The attorney completes this form">
                <ToggleButton value="attorney" selected={!props.isClientForm} onChange={handleToggle(false)} size="small" color="secondary" sx={{ width: 150 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <Work fontSize="small" />
                    Attorney
                  </Box>
                </ToggleButton>
              </Tooltip>

              <Tooltip title="The client completes this form">
                <ToggleButton value="client" selected={props.isClientForm} onChange={handleToggle(true)} size="small" color="secondary" sx={{ width: 150 }}>
                  <Box display="flex" alignItems="center" gap={1}>
                    <AccountCircle fontSize="small" />
                    Client
                  </Box>
                </ToggleButton>
              </Tooltip>
              
            </ToggleButtonGroup>
          </Grid>
        </Grid>

        <TextField margin="dense" id="description" label="Description" type="text" fullWidth multiline rows={4} value={props.description} onChange={handleChange}  disabled={status.isWorking}/>
        
        {!isEditing && (
          <MuiFileInput sx={{ mt: 1 }} placeholder={filePlaceholder} value={props.file} onChange={onFileChange} 
            clearIconButtonProps={{
              children: <CloseIcon fontSize="small" />
            }}
            InputProps={{
              startAdornment: <CloudUploadIcon />            
            }} disabled={status.isWorking}>
            
          </MuiFileInput>
        )}

      </DialogContent>

      <DialogActions>
        <Button onClick={onCancel} disabled={status.isWorking} color="primary">Cancel</Button>
        <Button onClick={onSave} color="primary" disabled={status.isWorking}>Save</Button>
      </DialogActions>

    </Dialog>
  );
}

export default AddFormDialog;