import { useSelector } from "react-redux";
import { Box, List } from "@mui/material";
import { TopicGroupItem } from "types";
import { TopicGroupListItem } from "components/conversations";
import { selectPetitionerTopicGroups } from "store/selectors/petitioner-selectors";

const MyConversationList = () => {
  
  const conversations = useSelector<any, TopicGroupItem[]>(selectPetitionerTopicGroups);
  
  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="conversation topics">
        {conversations?.map((conversation, index) => (
          <TopicGroupListItem key={conversation.id} topicGroup={conversation} />
        ))}        
      </List>
    </Box>
  );
};

export default MyConversationList;