import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

const DEFAULT_MESSAGE   = "Oops, something bad happened!  You may need to reload your browser to continue.";

class ErrorContainer extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            hasError    : false,
            error       : null
        };
    }

    componentDidCatch(error, info){
        this.setState({
            hasError    : true,
            error       : error
        });
    }

    render(){
        const { classes, message, children }   = this.props;
        const errorMessage  = message || DEFAULT_MESSAGE;
        
        if(this.state.hasError){
            return (
                <Card className={classes.root}>
                    <CardContent>
                        <Typography variant="h5">{errorMessage}</Typography>
                    </CardContent>
                </Card>
            );
        }

        return children;
    }
}

ErrorContainer.propTypes    = {
    message     : PropTypes.string
};

const styles = () => (theme => ({
    root    : {
        borderColor     : theme.palette.error.dark,
        backgroundColor : `${theme.palette.error.light}22`,
    }
}));

export default withStyles(styles)(ErrorContainer);