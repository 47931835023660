import { FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { PracticeCommunicationSchema } from "types/schema";

export interface ICommunicationSettingsProps {
  settings?: PracticeCommunicationSchema;
  onUpdated: (newValue: any) => Promise<boolean>;
}

const CommunicationSettings = ({ settings, onUpdated }: ICommunicationSettingsProps) => {

  const value = settings?.allowComments === undefined ? true : settings.allowComments;

  return (
    <Grid container p={2}>
      <Stack spacing={2}>
        <Typography variant="body1">Choose how you wish to communicate with clients.</Typography>
        <FormControlLabel 
          label="Allow comment on forms and documents"
          labelPlacement="end"
          control={<Switch 
            checked={value}
            onChange={async (e) => await onUpdated({ allowComments: e.target.checked })} 
            />
          }
        />
      </Stack>
    </Grid>
  );
};

export default CommunicationSettings;