import { ReactNode } from "react";
import { CheckCircle, Close } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, Typography } from "@mui/material";

export interface IConfirmDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message?: string;     //will override children
  confirmButtonLabel?: string;
  cancelButtonLabel?: string;
  maxWidth?: DialogProps["maxWidth"];
  children?: ReactNode;
}

const ConfirmDialog = ({ open, onClose, onConfirm, title, message, confirmButtonLabel = "Confirm", cancelButtonLabel = "Cancel", maxWidth, children }: IConfirmDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth={maxWidth}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {message && <Typography>{message}</Typography>}
        {!message && children}
        {!message && !children && <Typography>Are you sure?</Typography>}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" startIcon={<Close />} color="inherit" sx={{ color: "grey.600" }}>{cancelButtonLabel}</Button>
        <Button onClick={onConfirm} variant="text" color="primary" startIcon={<CheckCircle />}>{confirmButtonLabel}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;