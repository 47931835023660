import _ from 'lodash';
import { APP_ACTIONS, PROFILE_ACTIONS } from '../actions/action-types';
import { ADMIN_ACTIONS } from "../actions/admin-actions";
// import { ONBOARDING_ACTIONS } from '../actions/onboarding-actions';
import helpers, {NOT_WORKING, NO_ERROR } from './reducer-helpers';
import { STATUS_FAILED } from 'redux-action-status/index';
import { ACCOUNT_ACTIONS } from '../actions/account-actions';

//The properties of the Settings object(s) that we are interested in
const SETTINGS_WHITELIST = {
    onboarding: ["isInviteRequired"],
};

//Create the Initial State, merging in the common DEFAULT_STATE
const INITIAL_STATE     = _.merge({}, helpers.DEFAULT_STATE, 
    {
        isInitialized   : false,
        isAuthenticated : null,     //default to null so I can tell when the firebase auth stuff has finished
        needsRefresh: false,    //used if something requires the user to refresh the page 

        user            : null,
        profile         : null,
        customer        : null,     //the payments account
        signUpProps     : null,     //{invite: Invite, isReviewer: boolean}

        settings        : null,
        layout          : {},

        regions         : null,
        
        // isTosAgreed     : false,
        lastSaved       : null,
        isWelcomed      : false,

        isSaveDialogOpen: false,
        isOpenDialogOpen: false,
        isExporting     : false,
        exportError     : null,
        exportCount     : 0,
    }
);


function settingsLoaded(state, action){
    const key = action.data.id;
    const values = _.pick(action.data, SETTINGS_WHITELIST[key]);

    return {
        ...state,
        isWorking       : false,
        settings        : {
            ...state.app?.settings,
            ...values,
        }
    };
}

function settingsUpdated(state, action){
    const key = action.data.id;
    const values = _.pick(action.data, SETTINGS_WHITELIST[key]);
    return {
        ...state,
        isWorking       : false,
        settings        : {
            ...state.app?.settings,
            ...values,
        }    
    };
}

function initialize(state, action){
    return {
        ...state,
        isInitialized   : true,
        isWorking       : false,
        lastSaved       : action.data,
        // ...action.data,
    };
}

//APP_ACTIONS.CHANGE_LOCATION
function setLocation(state, action){
    if(action.status !== "ok") return state;
    return {
        ...state,
        profile         : {
          ...state.profile,
          ...action.data,
        },
    };
}

function loadLocation(state, action){
    let newState                = {...state};
    newState.regions            = action.value;
    newState.locationVersion    = action.version;
    return newState;
}

// function tosAgreed(state, action){
//     if(action.status !== "ok") return state;

//     return {
//         ...state,
//         profile     : {
//           ...state.profile,
//           ...action.data,
//         }
//     };
// }

function setWelcomed(state, action){
    return {
        ...state,
        isWelcomed      : action.value
    };
}

//#region Signing In, Signing Out

function onStatusFailed(state, action){
  //If the sign in fails, need to make sure there's no user/profile
  if(action.finishStatusKey !== "signIn") return state;

  return {
      ...state,
      isAuthenticated : false,
      user            : null,
      profile         : null,
  };
}

function onSignedIn(state, action){
    if(action.error) return state; 

    const user      = action.user || action.data;
    const profile   = action.profile ? {
        ...action.profile,
        isUser      : Boolean(action.profile?.role === "user"),
        isReviewer  : Boolean(action.profile?.role === "reviewer"),
    } : null;

    return {
        ...state,
        isAuthenticated     : Boolean(user !== null),
        user                : user,
        profile             : profile,
    };
}

function onSignedOut(state, action){
    return {
        ...state,
        isAuthenticated     : false,
        user                : null,
        profile             : null,
        customer            : null,
        isWelcomed          : false,        //need to go back through welcome
        regions             : null,           //clear out the regions (might be a different location)        
    };
}

//#endregion

//#region Profile Stuff

//Stores information from the signup flow that needs to be passed to the profile creation
function onSignupPropsChanged(state, action) {
  if(!action.value) {
    return {
      ...state,
      signUpProps: null,
    };
  }
  else {
    return {
      ...state,
      signUpProps: action.value,
    };
  }

}

//Handles Loaded or Updated - updated just returns the new object
function onProfileLoaded(state, action){
    if(!action.data) return state;

    const profile   = action.data ? {
        ...action.data,
        settings: action.data?.settings || state.profile?.settings || {},
        isUser      : Boolean(action.data.role === "user"),
        isReviewer  : Boolean(action.data.role === "reviewer"),
    } : null;

    return {
        ...state,
        profile         : profile,
    };
}

function onProfileSettingsUpdated(state, action){
    let profile = state.profile;
    if(!action.data || !action.isOk) return state;

    const existing = profile.settings || {};
    const updated = {
        ...existing,
        [action.category]: {
            ...existing[action.category] || {},
            ...action.changes,        
        },
    };

    const updatedProfile = {
        ...state.profile,
        settings: updated
    };

    return {
        ...state,
        profile: updatedProfile,
    };

}

//#endregion

function onInviteMatched(state, action){
    if(action.status === "ok"){
        return {
            ...state,
            ...NOT_WORKING,
            ...NO_ERROR,
            isInviteMatched     : true,
        };
    }
    else{
        return state;
    }
}

function toggleOpenDialog(state, action){
    return {
        ...state,
        isOpenDialogOpen    : action.meta.forceClose ? false : !state.isOpenDialogOpen
    };
}

// function toggleSaveDialog(state, action){
//     return {
//         ...state,
//         isSaveDialogOpen    : action.meta.forceClose ? false : !state.isSaveDialogOpen
//     };
// }

function exporting(state, action){
    return {
        ...state,
        exportError     : null,
        isExporting     : true,
        status          : "Exporting...",
    };
}

function exported(state, action){
    let expCount      = parseInt(state.exportCount);
    if(action.meta.isPrimary){
        expCount++;
    }

    return {
        ...state,
        isExporting     : false,
        lastExport      : new Date(),
        exportCount     : expCount,
        status          : null,
        profile         : action.data ? action.data : { ...state.profile, exportCount: (state.profile.exportCount || 0) + 1 },
    }
}

function exportFailed(state, action){
    return {
        ...state,
        isExporting     : false,
        exportError     : {
            message: "Export Error", 
            details: "The document failed to export for an unknown reason.  Please contact support at help@formhop.com for assistance.",
            support: action.error
        },
        status          : null
    };
}

function clearErrors(state, action){
    return {
        ...state,
        error           : null,
        exportError     : null,
    };
}

function customerLoaded(state, action){
    return {
        ...state,
        customer: action.data,

    };
}

//Called when the user creates a new account
function onCreatedPractice(state, action){
    if(!action.isOk) return state;

    const { profile } = action.data;
    if(!profile) return state;

    return {
        ...state,
        needsRefresh: true,
        // profile: {
        //     ...state.profile,
        //     ...profile,
        // },
    };

}
//Called when the user accepts an invite from an account
function onJoinedPractice(state, action){
    if(!action.isOk) return state;
    
    const member = action.data;
    if(!member) return state;

    return {
        ...state,
        needsRefresh: true,
        // profile: {
        //     ...state.profile,
        //     accountId: member.accountId,
        // },
    };
}

//========================
// The App reducer
const appReducer    =  {
    //Common
    ...helpers.getDefaultHandlers(APP_ACTIONS),
    [APP_ACTIONS.CLEAR_ALL_ERRORS]      : clearErrors,

    //Initialization
    [APP_ACTIONS.SETTINGS_LOADED]       : settingsLoaded,
    [ADMIN_ACTIONS.SETTINGS_UPDATED]    : settingsUpdated,
    [APP_ACTIONS.INITIALIZE]            : initialize,
    [APP_ACTIONS.READ_LOCATION]         : setLocation,
    [APP_ACTIONS.CHANGE_LOCATION]       : setLocation,
    [APP_ACTIONS.LOAD_LOCATION]         : loadLocation,
    // [ONBOARDING_ACTIONS.TOS_AGREE]      : tosAgreed,
    [APP_ACTIONS.STORE_SIGNUP_PROPS]    : onSignupPropsChanged,

    [APP_ACTIONS.CUSTOMER_LOADED]       : customerLoaded,
    
    [APP_ACTIONS.WELCOME_COMPLETE]      : setWelcomed,

    [APP_ACTIONS.TOGGLE_OPEN_DIALOG]    : toggleOpenDialog,
    // [APP_ACTIONS.TOGGLE_SAVE_DIALOG]    : toggleSaveDialog,

    //Auth
    [APP_ACTIONS.SIGNED_IN]             : onSignedIn,
    [APP_ACTIONS.SIGNED_OUT]            : onSignedOut,

    [APP_ACTIONS.INVITE_MATCHED]        : onInviteMatched,
    [ACCOUNT_ACTIONS.INVITE_ACCEPTED]: onJoinedPractice,
    [ACCOUNT_ACTIONS.CREATED]: onCreatedPractice,

    //Profile Stuff
    [APP_ACTIONS.PROFILE_LOADED]        : onProfileLoaded,
    [APP_ACTIONS.PROFILE_CREATED]       : onProfileLoaded,
    [PROFILE_ACTIONS.UPDATED]           : onProfileLoaded,  //update just returns the object
    [PROFILE_ACTIONS.SETTINGS_UPDATED]  : onProfileSettingsUpdated,
    [APP_ACTIONS.EXPORTING]             : exporting,
    [APP_ACTIONS.EXPORTED]              : exported,
    [APP_ACTIONS.EXPORT_ERROR]          : exportFailed,

    //Need to make sure we clear out user and profile stuff if the sign in fails
    [STATUS_FAILED]                     : onStatusFailed,
};

export default helpers.createReducer(INITIAL_STATE, appReducer);

// function prepareProfile(action){
//   const profile   = action.profile ? {
//     ...action.profile,
//     isUser      : Boolean(action.profile?.role === "user"),
//     isReviewer  : Boolean(action.profile?.role === "reviewer"),
//   } : null;

//   return profile;
// }