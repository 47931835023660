import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Stack, Tooltip, Typography } from "@mui/material";
import { AttachMoney, CheckCircleOutlineOutlined } from "@mui/icons-material";
import { numberHelper } from "helpers";
import WaitButton from "./wait-button";
import { Link } from "react-router-dom";

export type ProductPriceInfo = {
  active: boolean;
  description: string;
  unit_amount: number;
  info?: { description: string };
}

export interface IProductPriceProps {
  priceInfo: ProductPriceInfo;
  onPurchase: () => void;
  isWorking: boolean;
};

const ProductPrice = ({priceInfo, onPurchase, isWorking}: IProductPriceProps) => {
  if(!priceInfo.active) return null;

  const mailToUrl = "mailto:info@anythinksolutions.com?subject=FormHop Small Practice Edition&amp;body=I am interested in licensing FormHop for my law practice.";

  return (
    <Grid container alignItems="flex-start" p={2}>
      <Stack alignItems="center">
        <Typography fontSize="1.3rem" fontWeight="600" mb={2}>{priceInfo.description}</Typography>
        <Typography fontSize="1.1rem" color="grey.600" mb={2}>{priceInfo.info?.description}</Typography>
        {priceInfo.unit_amount === 0 && 
          <Grid container alignItems="center" justifyContent="center">
            <div>
              <CheckCircleOutlineOutlined fontSize="large" color="secondary"/>
            </div>
          </Grid> 
        }
        {priceInfo.unit_amount === -1 && 
          <Typography mt={2} color="secondary">
            <Button component={Link} to={mailToUrl} variant="outlined">CONTACT US</Button>
          </Typography>
        }
        {priceInfo.unit_amount === -2 && 
          <Tooltip title="Request access to the limited beta">
          <Box mt={2}>
            <WaitButton isWaiting={isWorking} disabled={false} color="secondary" variant="outlined" onClick={onPurchase}>
              Join the Beta
            </WaitButton>
          </Box>
        </Tooltip>
        }
        {priceInfo.unit_amount > 0 && 
          <Typography color="secondary">{numberHelper.asCurrencyWithCents(priceInfo.unit_amount / 100)}/mo</Typography>
        }
        {priceInfo.unit_amount > 0 &&
          <Tooltip title="Click to purchase this license">
            <Box mt={2}>
              <WaitButton isWaiting={isWorking} disabled={false} color="secondary" variant="outlined" endIcon={<AttachMoney/>} onClick={onPurchase}>
                Purchase
              </WaitButton>
            </Box>
          </Tooltip>
        }
      </Stack>
    </Grid>
  );
};

export default ProductPrice;