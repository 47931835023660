import { useAttorneyContext } from "./attorney-context";


export const useRefreshClients = () => {
  const context = useAttorneyContext();
  return context.refreshClients;
  // const context = useContext(AttorneyContext);
  // return {
  //   clients: context.clients,
  //   refreshClients: context.refreshClients,
  // }
};

export const useRefreshForms = () => {
  const context = useAttorneyContext();
  return context.refreshForms;
  // const context = useContext(AttorneyContext);
  // return {
  //   forms: context.forms,
  //   refreshForms: context.refreshForms,
  // }
};
