import React from 'react';
import Helmet from 'react-helmet';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { NavLink } from 'react-router-dom';
import logo from "figure-ex-small-color.png";

function LandingHeader({title}){
  
  const classes   = buildStyles();
  const myTitle   = title || "Better Way Forms";

  return (
    <React.Fragment>
    
      <Helmet>
        <title>{myTitle}</title>
      </Helmet>

      <Grid item className={classes.toolbar} container direction="row" alignItems="center">
        <Grid item sm={6}>
          <NavLink to="/" className={classes.brand}>
              <img src={logo} alt="logo" className={classes.logoImage}/> 
              <span className={classes.logoText}>FormHop</span>
          </NavLink> 
        </Grid>
        <Grid item sm={6} container spacing={2} justifyContent="flex-end" className={classes.links}>
          <Grid item>
            <NavLink to="/landing/learn-more" activeClassName={classes.activeLink}>Learn more</NavLink>
          </Grid>
          <Grid item>
            <NavLink to="/landing/lawyers" activeClassName={classes.activeLink}>Lawyers</NavLink>
          </Grid>
          <Grid item>
            <NavLink to="/landing/about" activeClassName={classes.activeLink}>About</NavLink>
          </Grid>
          <Grid item>
            <NavLink to="/landing/contact" activeClassName={classes.activeLink}>Contact</NavLink>
          </Grid>
          {/* <Grid item>
            <NavLink to="/pricing">Pricing</NavLink>
          </Grid> */}
          <Grid item>
            <NavLink to="/app" variant="contained">Launch</NavLink>
          </Grid>
        </Grid>
      </Grid>

    </React.Fragment>
  );
}

export default LandingHeader;

const buildStyles = makeStyles(theme => ({
  toolbar   : {
    height    : 60,
    minHeight : 60,
    position      : "fixed",
    background    : theme.palette.common.white,
    borderBottom  : "1px solid #dee2e6",
    padding       : `0 ${theme.spacing(3)}`,
    zIndex        : 999,
  },
  brand       : {
    color           : theme.palette.primary.main,
    textDecoration  : "none !important",

    "&:hover"   : {
        textDecoration  : "none !important",
        color           : theme.palette.primary.dark,
    }
  },
  logoImage    : {
      maxHeight       : 32,
      maxWidth        : 32,
      marginRight     : theme.spacing(1),
      verticalAlign   : "middle",
  },
  logoText     : {
      fontSize        : 24,
      fontWeight      : 300,
      verticalAlign   : "middle",
  },
  links   : {
    "& a"   : {
      textDecoration  : "none",
      color           : theme.palette.primary.main,
    },
    flexWrap        : "nowrap",
  },
  activeLink  : {
    // fontWeight  : 600,
    color       : `${theme.palette.secondary.main} !important`, //`
  }
}));