import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import Avatar from '@mui/material/Avatar';
import { ItemListRow } from 'components/index';
import { formatDateString } from 'helpers/general-helpers';
  
const AuditLogItem = ({item, config}) => {
  const classes   = buildStyles();
  const uidTitle  = `${item.uid} (click to copy)`;  //`

  function onDelete(){
    console.log("delete me");
  }

  function copyUid(){
    navigator.clipboard.writeText(item.uid);
  }

  
  return (
    <ItemListRow itemName={item.eventType} colSpan={6} onDelete={onDelete} isWorking={false}>
      <td>
        <Tooltip title={item.timestamp}>
          <span>
            {formatDateString(item.timestamp, "M/D h:mm A")}
          </span>
        </Tooltip>
      </td>
      <td>
        <Tooltip title={item.event}>
          <Avatar className={clsx(classes.letterAvatar, item.event)}>{item.event.toUpperCase().substring(0,1)}</Avatar>
        </Tooltip>
      </td>
      <td>{item.displayName}</td>
      <td>{item.email}</td>
      <td title={uidTitle} onClick={copyUid} className={classes.uidCol}>{`${item.uid.substring(0,4)}...`}</td>
      <td></td>
    </ItemListRow>
  );
}

export default AuditLogItem;

const buildStyles   = makeStyles(theme => ({
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
  },
  uidCol  : {
    cursor      : "pointer",
  },
  letterAvatar  : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    fontSize    : 14,
    fontWeight  : "500",
    "& svg"   : {
      height    : 15,
      width     : 15,
      color     : theme.palette.grey[700],
    },
    "&.login"  : {
      backgroundColor     : `${theme.palette.secondary.main}99`, //`
    },    
  }
}));