import { hot } from 'react-hot-loader';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { buildTheme } from 'config/theme-config';
import AppRouter from './app-router';
// import { AnalyticsListener } from 'helpers/analytics';
import LocalTextProvider from "./localization-context";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const theme     = buildTheme();

function App({config}) {
  return (
    <Provider store={config.store}>
      <BrowserRouter>
          {/* <AnalyticsListener trackingId={config.trackingId}> */}
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <>
                  <CssBaseline/>
                  
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <LocalTextProvider>
                      <AppRouter />
                    </LocalTextProvider>
                  </LocalizationProvider>

                </>
              </ThemeProvider>
            </StyledEngineProvider>
          {/* </AnalyticsListener> */}
      </BrowserRouter>
    </Provider>
  );
}

export default hot(module)(App);