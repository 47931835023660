import { Avatar, Box, Paper, SxProps, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AccountCircle, CheckCircle } from "@mui/icons-material";
import { formatDateFromNow } from "helpers/general-helpers";
import { useMemo } from "react";

export interface ICommentProps {
  item: any;
  sx?: SxProps;
}

const Comment = ({item, sx }: ICommentProps) => {
  const theme = useTheme();
  const props = useMemo(() => {
    const { isMine, isMyTeam } = item;
    let itemProps: any = {};
    if(!!item.resolvedAt){
      itemProps = {
        iconColor: theme.palette.grey[500],
        bubbleColor: alpha(theme.palette.grey[500], 0.3),
      };
    }
    else{
      itemProps = {
        iconColor: isMine ? theme.palette.primary.main : (isMyTeam ? theme.palette.secondary.main : theme.palette.grey[500]),
        bubbleColor: isMine ? alpha(theme.palette.primary.light, 0.3) : (isMyTeam ? alpha(theme.palette.secondary.light, 0.3) : "grey.100"),
      };
    }

    itemProps.direction = isMyTeam ? "row-reverse" : "row";
    return itemProps;
  }, [item, theme.palette]);
  const { name, initial } = item;
  // const isResolved = !!item.resolvedAt;
  const iconTip     = `${name}, ${formatDateFromNow(item.createdAt)}`;  //TODO: get the name of the sharer //`
  // const iconColor = isMine ? theme.palette.primary.main : (isMyTeam ? theme.palette.secondary.main : theme.palette.grey[500]);
  // const direction = isMyTeam ? "row-reverse" : "row";
  // const bubbleColor = isMine ? alpha(theme.palette.primary.light, 0.3) : (isMyTeam ? alpha(theme.palette.secondary.light, 0.3) : "grey.100");

  return (
    <Grid container alignItems="center" direction={props.direction} spacing={0.5} flexWrap="nowrap">
      <Grid container alignItems="center" sx={{ minWidth: 32 }}>
        {!item.resolvedAt && (
          <Tooltip title={iconTip}>
            <Box>
              {initial && <Avatar sx={{ width: 24, height: 24, fontSize: "1.1rem", fontWeight: "700", bgcolor: props.iconColor, color: "white" }}>{initial}</Avatar>}
              {!initial && <AccountCircle fontSize="small" sx={{ color: props.iconColor }}/>}
            </Box>
          </Tooltip>
        )}
        {!!item.resolvedAt && (
          <Tooltip title="This comment thread has been resolved">
            <Box sx={{ width: 24, height: 24, }}>  
              <CheckCircle fontSize="small" sx={{ color: "grey.400" }} />
            </Box>
          </Tooltip>
        )}
      </Grid>
      <Grid flexGrow={1} maxWidth="80%" >
        <Paper variant="outlined" elevation={0} sx={{ py: 0.5, px: 1, borderRadius: 2, bgcolor: props.bubbleColor, ...sx }}>
          <Typography width="100%" sx={{fontSize: "1rem", color: "grey.800" }}>{item.content}</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Comment;