import { useMatch } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { IDataTableSectionRowProps } from './data-table-types';
import fHelper from 'helpers/financials-helper';
import { DataTableField, DataTableMultiField } from './data-table-field';
import CommentContainer from './comment-container';
import { useRegion } from '../../sections/financials/parts/region-context';

//==========
// Single value field
function DataTableRow({fieldKey, values, profile}: IDataTableSectionRowProps){
  const { getField } = useRegion();
  const field = getField(fieldKey, false);
  const classes   = buildStyles();
  //NOTE: Toggle this to turn off the commenting feature...  Set it to true and comments will be disabled
  const match     = useMatch("/app/review");     //TODO: a better way to make this read only??

  //Make sure we have a field, and if so, if the condition for the field is met
  if(!field || (field.condition && !fHelper.isConditionMet(field.condition, values))){
    return null;
  }
  
  const isPField  = Boolean(field.params && field.params.length > 1) || Boolean(field.children);
  
  return (
    <tr className={clsx(classes.fieldRow)}>
      <td colSpan={3}>
        <CommentContainer fieldKey={fieldKey} profile={profile} isReadOnly={!!match}>
          {isPField && <DataTableMultiField field={field} values={values} />}
          {!isPField && <DataTableField field={field} values={values} />}
        </CommentContainer>        
      </td>
    </tr>
  );
}

export default DataTableRow;

const buildStyles   = makeStyles((theme: any) => ({
  fieldRow  : {
    "& td": {
      borderBottom  : `1px dotted ${theme.palette.grey[300]}`,
    },
    "& .bold"   : {
      fontWeight  : 700,
    },        
  },  
}));