import { STATUS_START, STATUS_FINISH, STATUS_FAILED, STATUS_RESET, STATUS_CLEAR_ERROR } from "./status-middleware";

export const startStatus = (statusKey, customProps) => async (dispatch, getState) => {
  return await dispatch({type: STATUS_START, statusKey: statusKey, oneWay: true, ...customProps});
}

export const finishStatus = (statusKey, result) => async (dispatch, getState) => {
  return await dispatch({type: STATUS_FINISH, statusKey: statusKey, oneWay: true, ...result});
}

export const failedStatus = (statusKey, result) => async (dispatch, getState) => {
  return await dispatch({type: STATUS_FAILED, statusKey: statusKey, oneWay: true, ...result});
}

export const resetStatus = () => async (dispatch, getState) => {
  return await dispatch({type: STATUS_RESET});
}

export const statusClearError = (statuKey) => async (dispatch, getState) => {
  return await dispatch({type: STATUS_CLEAR_ERROR, statusKey: statuKey});
}