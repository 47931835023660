import { useCallback, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { CloudFolder, SharedCloudFolder } from "types";
import { cloudFoldersOnly, findRecursive, getCloudItems, getFolderChildren, oneDriveRedirectUri, oneDriveScopes, childCount, getRemoteItem } from "helpers/cloud-storage-helpers";
import { useCloudStorage } from "sections/collaborator/parts/provider-accounts/cloud-storage-context/cloud-storage-context";

const useOnedriveFolders = () => {
  const { instance } = useMsal();
  const { setting } = useCloudStorage();
  const msalAccount = useMemo(() => instance.getAllAccounts()[0], [instance]);
  const [isInitialized, setInitialized] = useState(false);
  const [folders, setFolders] = useState<CloudFolder[]>([]);
  const [refreshRequired, setRefreshRequired] = useState<boolean>(false);

  const getFolders = useCallback(async () => {
    try{
      let myFolders = await getFolderChildren<CloudFolder>(instance, "root", setting);
      const sharedWithMe = await getCloudItems<SharedCloudFolder>(instance, "sharedWithMe");
      
      //filter out any items in myFolders that are also in sharedWithMe (these are shortcuts to the shared folder)
      myFolders = myFolders.filter((f: any) => !f.remoteItem?.id || !sharedWithMe.find((s: any) => s.remoteItem?.id === f.remoteItem?.id));
      const allFolders = cloudFoldersOnly([...(myFolders ?? []), ...(sharedWithMe ?? [])]);

      //If there are two folders with the same id, display a warning in the console
      const folderIds = allFolders.map((f: any) => f.remoteItem?.id ?? f.id);
      const duplicateIds = folderIds.filter((id, index) => folderIds.indexOf(id) !== index);
      if(duplicateIds.length > 0){ 
        console.warn("Duplicate folder ids found: ", duplicateIds);
        allFolders.forEach(f => {
          if(duplicateIds.includes(f.id)) console.warn("Duplicate folder: ", f);
        });
      }

      setFolders(allFolders);
      setRefreshRequired(false);
    }
    catch(error){
      console.error("Error fetching folders, attempting to refresh token", error);
      try{
        await instance.acquireTokenPopup({ scopes: oneDriveScopes, redirectUri: oneDriveRedirectUri });
      }
      catch(error){
        console.error("Error acquiring token", error);
        setFolders([]);
        setRefreshRequired(true);
      }
    }
    
    setInitialized(true);    

  }, [instance, setInitialized, setting]);

  //initially, fetch the top level folders
  useEffect(() => {
    if (msalAccount && !isInitialized)  getFolders();
    else if(!msalAccount) setFolders([]);
  }, [instance, msalAccount, isInitialized, getFolders]);

  //takes a folder id and gets the children folders. Will also update the folders state with the new children
  const getChildren = async (folderId: string) => {
    //make sure we don't already have the children for this folder
    const folder = findRecursive(folders, folderId) as CloudFolder;
    if(folder?.children) return folder.children;
    else if (childCount(folder) === 0) return [];

    //If this is a remote folder, need to get the 
    let children: CloudFolder[] = [];
    if((folder as any).remoteItem || folder.isRemote){
      const { remoteItem, parentReference } = (folder as any);
      const driveId = remoteItem?.parentReference?.driveId ?? parentReference?.driveId;
      children = await getRemoteItem(instance, driveId, folder.id, "/children");
    } 
    else children = await getFolderChildren(instance, folder, setting);

    if((children as any).error) return [];

    folder.children = cloudFoldersOnly(children);
    const newFolders = [...folders];
    setFolders(newFolders);
    return children;
  };

  const refresh = async () => {
    getFolders();
  }

  return { folders, getChildren, refresh, refreshRequired, isWorking: !isInitialized };
};

export default useOnedriveFolders;