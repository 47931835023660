import { Route, Routes } from "react-router-dom";
import ClientView from "./client-view";
import ProviderFormView from "../forms/provider-form-view";
import { ProviderDocumentView } from "../docs";
import { ClientProvider } from "./client-context";

const ClientRouter = () => {
  
  return (    
    <Routes>
      {/* <Route path="edit/:clientId" element={<ClientView />}/> */}
      <Route path="forms/:formId" element={<ProviderFormView />}/>
      <Route path="documents/:documentId" element={<ProviderDocumentView />}/>
      <Route path=":tab" element={<ClientView />}/>
      <Route path=":tab/:documentId" element={<ClientView />}/>
      <Route path=":tab/:topicType/:topicId" element={<ClientView />}/>       
      <Route path="*" element={<ClientView />}/>     
    </Routes>
  );
};

const ClientRouterWrapper = () => {
  return (
    <ClientProvider>
      <ClientRouter />
    </ClientProvider>
  );
};

export default ClientRouterWrapper;