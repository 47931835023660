import { diff } from 'deep-diff';
import { APP_ACTIONS } from '../actions/action-types';
import { CONVO_ACTIONS } from '../actions/conversation-actions';
import helpers, { immRemoveItem } from './reducer-helpers';
import { orderBy, pick } from 'lodash';
import { hashString } from 'helpers/model-helpers';


//==================
// Initial Reducer State
const INIT_STATE  = {
  baseline: null,
  conversations: null,
  selected: { shareId: null, topicId: null },
  topic: null,
  currentComments: [],
  dirtyTopics: [],
};


//===================
// Helper functions
//TODO: Move all this to the server to find and return only conversations that are dirty.
const viewedAt = (topic, uid) => { 
  const viewed = topic.viewed ?? {};
  return viewed[uid] ?? null;
}

const isTopicDirty =  (topic, isUser, uid) => {
  const lastViewed = viewedAt(topic, uid); //isUser ? topic.sharerViewedAt : viewedAt(topic, uid);    //last viewed by THIS user
  const lastTouched = isUser ? topic.reviewerTouchedAt : topic.sharerTouchedAt; //last touched by the OTHER user
  return !lastViewed || lastTouched > lastViewed;
};

const listDirtyTopics = (conversations, isUser, uid) => {
  if(!conversations) return [];
  return conversations
    .flatMap(c => c.topics
      .filter(t => isTopicDirty(t, isUser, uid))
        .map(t => ({ 
          ...t, 
          shareId: c.shareId, 
          sharerId: c.sharerId, 
          reviewerId: c.reviewerId 
        }
      )
    )
  );
};

const keyProps = ["id", "sharerViewedAt", "sharerTouchedAt", "reviewerViewedAt", "reviewerTouchedAt"];
const convoKeys = (data) => data.length === 0 ? null : hashString(JSON.stringify(data.flatMap(c => c.topics.map(t => pick(t, keyProps)))));

const convoMap = (data) => data.reduce((acum, convo) => { 
  return {
    ...acum, 
    [convo.shareId]: { ...convo, }
  }; 
}, {});

//==================
// Reducer functions

//#region Loading Conversation or conversations
function conversationsLoaded(state, action){
  const items = action.data ?? [];

  //first, check for any changes
  //baseline is used to determine if any conversations have been updated since the last refresh
  const baseline = state.baseline;
  const newBaseline = convoKeys(items);
  if(baseline === newBaseline){
    console.log("no conversation changes");
    return state;    //no changes
  } 

  const convos = action.data ? convoMap(action.data) : [];
  
  //dirty is used to determine if there are any new comments since this user has last viewed the conversation
  // const dirty = dirtyConvos(items, action.isUser);
  const dirty = listDirtyTopics(items, action.isUser, action.uid);
    
  return {
    ...state,
    conversations   : {
      ...state.conversations,
      ...convos,
    },
    baseline: newBaseline,
    dirty,
  };
}

function topicLoaded(state, action){
  //if the data is null, the conversation wasn't found
  const data = action.data || null;
  const { shareId, topicId, topicType } = action;

  if(!data){
    //no topic in the db, so just select the current topic and move on
    return {
      ...state,
      selected: { shareId, topicId, topicType },
      topic: null,
      currentComments: [],
    };
  }

  const share = state.conversations ? state.conversations[shareId] : null;
  let { comments, ...topicData } = data;
  const topic = {...topicData, refreshedAt: Date.now() };
  comments = comments ? orderBy(comments, ["createdAt"], ["asc"]) : [];

  const newShare = {
    ...share,
    topics: share.topics.map(t => t.id === topicId ? topic : t)
  };

  const newConversations = {
    ...state.conversations,
    [shareId]: newShare,
  };

  return {
    ...state,
    conversations   : newConversations,
    selected: { shareId, topicId, topicType },
    topic,
    currentComments: comments, //orderedComments,
  };
}

//Happens when the current user either views or touches the topic
function topicTouched(state, action) {
  const { shareId, topicId, uid } = action;
  const existingConvo = state.conversations ? state.conversations[shareId] : null;
  const topic = existingConvo ? existingConvo.topics.find(t => t.id === topicId) : null;
  if(!topic) return state;

  //update the share topic to indicate the user has viewed or touched the topic
  const updatedTopic = action.data; // { ...topic, participants: { ...topic.participants ?? {}, [uid]: action.data } };
  const updatedConvo = {
    ...existingConvo,
    topics: existingConvo.topics.map(t => t.id === topicId ? updatedTopic : t)
  };

  const updatedConversations = {
    ...state.conversations,
    [shareId]: updatedConvo,
  };

  const nextTopic = state.selected.topicId === topicId ? updatedTopic : state.topic;
  const existingDirty = (state.dirty ?? []).find(d => d.shareId === shareId && d.id === topicId);
  const dirty = !!existingDirty ? immRemoveItem(state.dirty, existingDirty) : state.dirty;
  
  return {
    ...state,
    conversations   : updatedConversations,
    topic: nextTopic,
    dirty,
  };

}

function commentsRefreshed(state, action){
  const { shareId, topicId } = action;
  //make sure this is for the current conversation
  if(shareId !== state.selected.shareId || topicId !== state.selected.topicId) return state;
  
  const existing = state.currentComments ?? [];
  const next = orderBy(action.data ?? [], ["createdAt"], ["asc"]);
  const changes = diff(existing, next);

  const topic = { ...state.topic, refreshedAt: Date.now() };
  
  if(!changes){
    console.debug("comments refreshed: no changes");
    return { ...state, topic };    //no changes to the comments
  }
  console.debug("comments refreshed: changes");
    
  return {
    ...state,
    topic,
    currentComments : next,
  };
}

function commentsResolved(state, action){
  const { shareId, topicId } = action;
  //make sure this is for the current conversation
  if(shareId !== state.selected.shareId || topicId !== state.selected.topicId) return state;

  const existing = state.currentComments ?? [];
  const next = existing.map(c => {
    const updated = action.data.find(d => d.id === c.id);
    return updated ?? c;
  });

  return {
    ...state,
    currentComments : next,
  };
}
//#endregion

function clearSelected(state, action){
  const { shareId = null, topicId = null, topicType = null } = action;
  if(state.selected.shareId === shareId && state.selected.topicId === topicId) return state;

  return {
    ...state,
    selected: { shareId, topicId, topicType },
    topic: null,
    currentComments: [],
  };
}

//#region Create or Update Conversation
function conversationCreated(state, action){
  return {
    ...state,
    conversations   : {
      [action.convoId]  : action.data,
      ...state.conversations,
    }
  };
}

function topicCreated(state, action){
  const { shareId, topicId } = action;
  //get the conversation and add the new topic to it
  const convo = state.conversations[shareId];
  if(!convo) return state;
  const updatedConvo = { ...convo, topics: [...convo.topics, action.data] };
  const updatedConversations = { ...state.conversations, [shareId]: updatedConvo };

  //Make sure the topic is for the current conversation
  const currentTopic = (shareId === state.selected.shareId || topicId === state.selected.topicId) ? action.data : state.topic;
  
  return {
    ...state,
    conversations: updatedConversations,
    topic: currentTopic,
  };
}

//#endregion

//#region Create Comment
function commentCreated(state, action){
  const { shareId, topicId } = action;
  //Make sure the comment is for the current conversation
  if(shareId !== state.selected.shareId || topicId !== state.selected.topicId) return state;
  const current = state.currentComments ?? [];
  
  //check for existing in case the refresh has already added it
  const existing = current.find(c => c.id === action.data.id);
  if(existing) return state;

  const newComments = orderBy([...current, action.data], ["createdAt"], ["asc"]);
  
  return {
    ...state,
    currentComments : newComments,
  };
}
//#endregion


function onSignedOut(state, action){
  return {...INIT_STATE };
}

//==================
// Reducer creation

const convoReducer  = {
  [CONVO_ACTIONS.CONVOS_LOADED]         : conversationsLoaded,
  [CONVO_ACTIONS.CONVO_LOADED]          : topicLoaded,
  [CONVO_ACTIONS.CONVO_CLEARED]        : clearSelected,
  [CONVO_ACTIONS.CONVO_CREATED]         : conversationCreated,
  [CONVO_ACTIONS.TOPIC_CREATED]         : topicCreated,
  [CONVO_ACTIONS.CONVO_TOUCHED]         : topicTouched,
  [CONVO_ACTIONS.COM_CREATED]           : commentCreated,
  [CONVO_ACTIONS.COMMENTS_REFRESHED]    : commentsRefreshed,
  [CONVO_ACTIONS.COMMENTS_RESOLVED]     : commentsResolved,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INIT_STATE, convoReducer);

// function getCurrentTopic(shareId, topicId, conversations) {
//   if(!shareId || !topicId || conversations.length === 0) return null;

//   const convo = conversations[shareId];
//   if(!convo) return null;
//   const topic = convo.topics.find(c => c.id === topicId);
//   return topic ?? null;
// }

// function flattenComments(topic, topicType){
//   const allComments   = topic?.comments;
  
//   //Only need to do this if it's for a form (where there are field-specific comments)
//   //otherwise, just return the array
//   if(topicType !== "f") return allComments ?? [];
//   else if(!allComments) return {};
  
//   let flat    = {};
//   allComments.forEach(cmt => {
//     const myCmt   = {...cmt, conversationId: topic.id };
//     const node  = flat[myCmt.fieldKey];
//     if(node === undefined){
//       flat[myCmt.fieldKey]  = [myCmt];
//     }
//     else{
//       node.push(myCmt);
//     }
//   });

//   return flat;
// }

// function updateCurrentComments(collection, action){
//   //Add to the flat comments collection as well
//   const newItem   = {conversationId: action.convoId ?? action.shareId, ...action.data};
//   console.log("adding new item to current conversation.", newItem);

//   const comments  = {...collection};
//   if(comments[action.data.fieldKey]){
//     comments[action.data.fieldKey].push(newItem)
//   }
//   else{
//     comments[action.data.fieldKey]  = [newItem];
//   }

//   return comments;
// }