import React from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
// import Grid from '@mui/material/Grid';


export default function SimpleError({title, message, messages, isVisible}){

  if(!isVisible || !message) return null;
  const classes   = buildStyles();
  let i = 0;
  
  return (

    <Card elevation={0} className={classes.errorCard} variant="outlined">
      {title && 
        <h4 className={classes.errorTitle}>{title}</h4>
      }
      {message && 
        <span className={classes.errorItem}>{message}</span>
      }
      {messages && 
        <ul className={classes.errorList}>
          {_.map(messages, e => <li key={i++} className={classes.errorListItem}>{e}</li>)}
        </ul>
      }
    </Card>
  );
}

const buildStyles   = makeStyles(theme => ({
  errorCard         : {
    background      : theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "5",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
  errorTitle  : {

  },
  errorItem   : {

  },
  errorList   : {
    margin          : theme.spacing(0.5),
    paddingLeft     : theme.spacing(2),
    fontSize        : 16,
  },
  errorListItem   : {

  }
}));