import { AccountInvitation, ProviderAccount } from "types/account-types";
import helpers from "./reducer-helpers";
import { APP_ACTIONS } from "../actions/action-types";
import { ACCOUNT_ACTIONS } from "../actions/account-actions";

export type AccountSlice = {
  isInitialized: boolean;
  hasAccount: boolean;
  hasInvitations?: boolean;
  id?: string;
  type?: string;
  account: ProviderAccount | null;
  invitations: AccountInvitation[];
  needsRefresh: boolean;
}

const INIT_STATE: AccountSlice = {
  isInitialized: false,
  hasAccount: false,
  account: null,
  invitations: [],
  needsRefresh: false,
};

//====
// Reducer Functions
//====

//#region Account Management

function accountCreated(state: AccountSlice, action: any) {
  //data: { account: {..., members: []}, profile };
  // const accountData = action.data?.account;
  return {
    ...state,
    isInitialized: true,
    // hasAccount: !!accountData?.id,
    // account: accountData,
    needsRefresh: true    //force the app to refresh
  };
}

function accountLoaded(state: AccountSlice, action: any) {
  const accountData = action.data;
  if(!accountData){
    return {
      ...state,
      isInitialized: true,
      hasAccount: false,
    };
  }

  let { account_invitations, ...account} = accountData ?? {};
  if(!account_invitations) account_invitations = state.account?.invitations ?? [];

  return {
    ...state,
    isInitialized: true,
    hasAccount: !!account?.id,
    id: account?.id,
    account: {
      ...account,
      invitations: account_invitations,
    },
  };
};

function accountUpdated(state: AccountSlice, action: any) {
  if(!action.isOk) return state;
  const accountUpdates = action.data;
  return {
    ...state,
    account: {
      ...state.account,
      ...accountUpdates,
    }
  };
}

function logoDownloaded(state: AccountSlice, action: any) {
  return {
    ...state,
    account: {
      ...state.account,
      logoUrl: action.data,
    }
  };
}

//#endregion

//#region Members & Invitations

function membersLoaded(state: AccountSlice, action: any) {
  if(!action.isOk) return state;
  const { members, invitations } = action.data;

  return {
    ...state,
    account: {
      ...state.account,
      members,
      invitations,
    }
  };
}

function memberInvited(state: AccountSlice, action: any) {
  return {
    ...state,
    account: {
      ...state.account,
      invitations: [...state.account?.invitations ?? [], action.data],
    }
  };
}

function memberRemoved(state: AccountSlice, action: any) {
  if(!action.isOk) return state;

  const memberId = action.memberId;
  return {
    ...state,
    account: {
      ...state.account,
      members: state.account?.members?.filter(m => m.id !== memberId) ?? [],
    }
  };
}

function myInvitationsLoaded(state: AccountSlice, action: any) {
  return {
    ...state,
    hasInvitations: action.data?.length > 0,
    invitations: action.data,
  };
}

function invitationAccepted(state: AccountSlice, action: any) {
  if(!action.isOk) return state;
  
  return {
    ...state,
    hasInvitations: false,
    invitations: [],
  };
}

function invitationRemoved(state: AccountSlice, action: any) {
  if(!action.isOk) return state;

  return {
    ...state,
    account: {
      ...state.account,
      invitations: state.account?.invitations?.filter(i => i.id !== action.inviteId) ?? [],
    }
  };
}

//#endregion


//#region Account Links

function accountLinkAdded(state: AccountSlice, action: any) {
  return {
    ...state,
    account: {
      ...state.account,
      links: [...state.account?.links ?? [], action.data],
    }
  };
}

//#endregion

function accountCleared(state: AccountSlice, action: any) {
  return {
    isInitialized: false,
    hasAccount: false,
  };
};

//====
// Action Handlers
//====
const ACTION_HANDLERS = {
  [ACCOUNT_ACTIONS.CREATED]: accountCreated,
  [ACCOUNT_ACTIONS.LOADED]: accountLoaded,
  [ACCOUNT_ACTIONS.UPDATED]: accountUpdated,  //same as loaded for now
  [ACCOUNT_ACTIONS.MEMBERS_LOADED]: membersLoaded,
  [ACCOUNT_ACTIONS.LOGO_DOWNLOADED]: logoDownloaded,
  [ACCOUNT_ACTIONS.MEMBER_INVITED]: memberInvited,
  [ACCOUNT_ACTIONS.MY_INVITES_LOADED]: myInvitationsLoaded,
  [ACCOUNT_ACTIONS.INVITE_ACCEPTED]: invitationAccepted,
  [ACCOUNT_ACTIONS.MEMBER_REMOVED]: memberRemoved,
  [ACCOUNT_ACTIONS.INVITE_REMOVED]: invitationRemoved,
  [ACCOUNT_ACTIONS.LINK_ADDED]: accountLinkAdded,

  [APP_ACTIONS.SIGNED_OUT]: accountCleared,
};


//==== The Reducer
export default helpers.createReducer(INIT_STATE, ACTION_HANDLERS);