import { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// import makeStyles from '@mui/styles/makeStyles';
import { ItemList, InviteDialog } from 'components';
import { useStatusMulti } from 'redux-action-status';
import { loadWaitlist, loadInvitations, inviteFromWaitlist } from 'store/actions/admin-actions';
import { selectWaitlist } from 'store/selectors/admin-selectors';
import WaitListItem from './waitlist-item';
import InvitationDialog from '../common/invitation-dialog';

const WaitlistView = () => {
  // const classes                   = buildStyles();
  const [view, setView]           = useState("all");
  const [toInvite, setToInvite]   = useState(null);
  const [invitation, setInvitation]   = useState(null);
  const waitlist  = useSelector(selectWaitlist);
  const status    = useStatusMulti(["waitlist", "invitations"]);
  const dispatch  = useDispatch();

  const filtered  = filterList(waitlist, view);
  // const isEmpty   = status.isInitialized && filtered.length === 0;

  useEffect(() => {
    if(!waitlist){
      onRefresh();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onRefresh(){
    dispatch(loadWaitlist());
    dispatch(loadInvitations());
  }

  function onAction(action, item){
    if(action === "invite"){
      setToInvite(item);
    }
    else if(action === "view_invitation"){
      setInvitation(item);
    }
  }

  async function sendInvite(model){
    const result  = await dispatch(inviteFromWaitlist({...model, waitlistId: toInvite.id}));
    if(result.isOk){
      setToInvite(null);
    }
  }

  return (
    <>
      <ItemList config={listConfig} items={filtered} status={status} onRefresh={onRefresh} onFilter={v => setView(v)} onAction={onAction} ItemComponent={WaitListItem}/>

      <InviteDialog isOpen={Boolean(toInvite)} onClose={() => setToInvite(null)} type="wait" sendCallback={sendInvite} waitlistItem={toInvite} />
      <InvitationDialog isOpen={Boolean(invitation)} onClose={() => setInvitation(null)} invitation={invitation}/>
    </>
  );
}

export default WaitlistView;

// const buildStyles   = makeStyles(theme => ({  
// }));

function filterList(list, view){
  switch(view){
    case "all"  : return list;
    case "waiting"  : return _.filter(list, item => !item.isInvited);
    case "invited"  : return _.filter(list, item => item.isInvited && !item.isAccepted);
    case "accepted" : return _.filter(list, item => item.isAccepted);
    default: return list;
  }
}

const listConfig  = {
  title   : "Waitlist",
  cols    : [
    {id: 0, label: "Name", className: "active",},
    {id: 10, label: "Email", className: ""},
    {id: 20, label: "Status", className: ""},
    {id: 30, label: "Waitlisted On", className: ""},
    {id: 40, label: "Invited On", className: ""},
    {id: 50, label: "Accepted On", className: ""},
    {id: 60, label: "", className: ""},
  ],
  filters : [
    {id: 0, label: "All", value: "all", icon: "clear_all" },
    {id: 10, label: "Waiting", value: "waiting", icon: "hourglass_empty"},
    {id: 20, label: "Invited", value: "invited", icon: "mail_outline"},
    {id: 30, label: "Accepted", value: "accepted", icon: "done_all"},
  ],
}