import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import Icon from '@mui/material/Icon';
import { TBodyWrapper } from 'components';

const ItemList = ({config, items, status, onRefresh, onAdd, onFilter, onAction, ItemComponent}) => {
  const classes         = buildStyles();
  const [view, setView] = useState(config.views ? config.views[0].value : "all");
  const isEmpty         = status.isInitialized && items.length === 0;

  useEffect(() => {
    if(!items){
      onRefresh();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function doFilter(view){
    setView(view);
    onFilter(view);
  }

  return (
        <Grid id="list-view" container justifyContent="center" className={classes.listRoot}>
          
          <Card id="list-card" elevation={0} variant="outlined" className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Grid container className={classes.cardGrid}>
                
                <Grid item container className={classes.cardHeader} alignItems="center">
                  <Grid item sm={9} container alignItems="center">
                    <Grid item sm={3}>
                      <Typography variant="h5" className={classes.title}>{config.title}</Typography>
                    </Grid>
                    <ListFilter config={config} view={view} onFilter={doFilter} classes={classes}/>
                  </Grid>
                  <Grid item sm={3}>
                    <IconButton size="small" color="secondary" title="Refresh" className={classes.refreshButton} onClick={onRefresh}>
                      <RefreshIcon fontSize="small" />
                    </IconButton>
                    {onAdd && 
                      <IconButton size="small" color="secondary" title="New" className={classes.refreshButton} onClick={onAdd}>
                        <AddIcon fontSize="small" />
                      </IconButton>                  
                    }                    
                  </Grid>
                </Grid>

                <Grid item container className={classes.cardBody}>
                  <table id="users-table" className={classes.itemTable}>
                    <thead>
                      <tr className="table-header">
                        {_.map(config.cols, col => <th key={col.id} className={col.className} style={col.style}>{col.label}</th>)}
                      </tr>
                    </thead>
                    <TBodyWrapper colSpan={config.cols.length} isWorking={status.isWorking} isEmpty={isEmpty}>
                      {items ? _.map(items, i => <ItemComponent key={i.id} item={i} onAction={onAction} />) : null}
                    </TBodyWrapper>
                  </table>
                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>
  );
}

export default ItemList;

const buildStyles   = makeStyles(theme => ({
  listRoot  : {
    padding     : theme.spacing(1),
  },
  title   : {
    fontSize    : 15,
    fontWeight  : 700,
    color       : theme.palette.grey[800],
  },
  card  : {
    minHeight   : 300,
    marginBottom  : theme.spacing(3),
    width       : "100%",
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  refreshButton   : {
    float           : "right",
  },
  listNav   : {
    background      : "transparent",
    height          : 45,
    "& button"  : {
      "&.Mui-selected" : {
        background    : theme.palette.grey[50],
        borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
        borderTop     : `2px solid ${theme.palette.primary.main}`, //`
      },
      "& .MuiBottomNavigationAction-label"    : {
        fontSize        : 14,
        fontWeight      : "600",
      },
      "& svg, .MuiIcon-root"     : {
        fontSize      : 16,
      }
    },
    
  },
  filterRow     : {
    height          : 45,
    padding         : theme.spacing(2),
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  errorCard   : {
    width           : "80%",
  },
  cardBody      : {
    // marginTop       : theme.spacing(2),
  },
  itemTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
}));

function ListFilter({config, view, onFilter, classes}){
  if(!config.filters) return null;

  return (
    <Grid item sm={6}>
      <BottomNavigation value={view} onChange={(e,val) => onFilter(val)} className={classes.listNav}>
        {
          _.map(config.filters, filter => <BottomNavigationAction key={filter.id} label={filter.label} value={filter.value} title={filter.title || filter.label} icon={<Icon fontSize="small">{filter.icon}</Icon>} />)
        }                      
      </BottomNavigation>
    </Grid>
  );
}