// import React from "react";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import logo from 'figure-ex-small-color.png';
import ProfileMenu from './header/profile-menu';
// import SharingMenu from './header/sharing-menu';
import ConversationsButton from './header/conversations-button';
import SyncButton from './header/sync-button';
import { selectProfile } from 'store/selectors/app-selectors';
// import { selectConversations } from 'store/selectors/conversation-selectors';
import { useRouteMatches } from 'helpers/hooks';
import { selectCurrentForm } from 'store/selectors/petitioner-selectors';
import { Tooltip, Typography } from '@mui/material';

function Header(){
		const classes = buildStyles();
		const profile = useSelector(selectProfile);
		const isRouteMatch = useRouteMatches(["/app", "/my", "/admin"]);
		const form = useSelector(selectCurrentForm);
		// const conversations   = useSelector(selectConversations);
		// const hasConversations 	= Boolean(conversations && conversations.length > 0);

		return (
			<Grid container direction="row" alignItems="center" justifyContent="space-between" className={classes.root}>
				<Grid item sm={4} xs={5}>
					<Link to="/app" className={classes.brand}>
						<img src={logo} alt="logo" className={classes.logoImage}/> 
						<span className={classes.logoText}>FormHop</span>
					</Link> 
				</Grid>
				<Grid item container justifyContent="center" sm={4} xs={1}>
					{ form && (
						<Tooltip title="Form Name" arrow>
							<Typography fontSize="1.3rem" fontWeight="500" color="primary.dark">{form.formName}</Typography> 
						</Tooltip>
					)}					
				</Grid>
				<Grid item sm={4} xs={6} container justifyContent="flex-end" alignItems="center">
					{Boolean(isRouteMatch) && <ConversationsButton />}
					{Boolean(profile.isUser && isRouteMatch) && <SyncButton />}
					{/* <SharingMenu /> */}
					<ProfileMenu />
				</Grid>
			</Grid>
		);
}

export default Header;


const buildStyles   = makeStyles(theme => ({
	root    : {
		height          : 60,
		minHeight       : 60,
		background      : theme.palette.common.white,
		position        : "relative",
		borderBottom    : `1px solid #dee2e6`,
		color           : "#808080", 
		padding         : `0 ${theme.spacing(3)}`,
	},
	brand       : {
		color           : theme.palette.primary.main,
		textDecoration  : "none !important",

		"&:hover"   : {
			textDecoration  : "none !important",
			color           : theme.palette.primary.dark,
		}
	},
	logoImage    : {
		maxHeight       : 32,
		maxWidth        : 32,
		marginRight     : theme.spacing(1),
		verticalAlign   : "middle",
	},
	logoText     : {
		fontSize        : 24,
		fontWeight      : 300,
		verticalAlign   : "middle",
	},
	headerButton    : {
		padding         : "3px 6px",
		textTransform   : "none",
		fontSize        : 14,
		fontWeight      : 400,

		"& svg"         : {
			height          : "0.75em",
		}
	},
	menu        : {
		"& li"  : {
			fontSize    : 16,
			minHeight   : "unset",
		}
	},
}));

// const LocationButton = ({classes, location, changeLocation}) => {
// 	const [isOpen, setOpen]     = useState(false);
// 	const anchorRef             = React.useRef(null);    

// 	const toggleMenu    = () => {
// 		setOpen(prevOpen => !prevOpen);
// 	}
// 	const onClose       =(e) => {
// 		if(anchorRef.current && anchorRef.current.contains(e.target)){
// 			return;
// 		}
// 		setOpen(false);
// 	}

// 	return (
// 		<div>
// 			<Button color="primary" ref={anchorRef} className={classes.headerButton} onClick={toggleMenu} variant="outlined">
// 				<PersonPinCircle/>
// 				<span>{location ? location.name : "not set"}</span>
// 				<ArrowDownIcon/>
// 			</Button>
// 			<Popover open={isOpen} anchorEl={anchorRef.current} anchorOrigin={{vertical: "bottom", horizontal: "left"}} transformOrigin={{vertical: "top", horizontal: "left"}}>
// 				<Paper id="location-menu-grow">
// 					<ClickAwayListener onClickAway={onClose}>
// 						<MenuList className={classes.menu}>
// 							<MenuItem onClick={changeLocation}>Change Location</MenuItem>
// 						</MenuList>
// 					</ClickAwayListener>
// 				</Paper>
// 			</Popover>
// 		</div>
// 	);
// }