import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useRegion } from "sections/financials/parts/region-context";
import { IDataTableSectionProps } from "./data-table-types";
import DataTableColumnarRow from "./data-table-columnar-row";
import { cellSx, labelCellSx, valueSx } from "./data-table-constants";
import DataTableColumnarRowEditor from "./data-table-columnar-row-editor";

const DataTableColumnarSection = ({section, values, profile, isEditing}: IDataTableSectionProps) => {
  const { fields } = useRegion();

  if(!section.config?.inputHeaders) throw new Error("No input headers defined for columnar section");
  const headerColumns = (
    <Grid container xs={12} flexWrap="nowrap" columnSpacing={1}>
      <Grid xs flexGrow={1}><Typography color="primary" fontSize="1.1rem">{section.title}</Typography></Grid>
      {!isEditing && section.config.inputHeaders.map((h, index) => 
        <Grid key={index} container sx={valueSx}>
          <Typography fontSize="1rem" color="primary">{h}</Typography>
        </Grid>)}
    </Grid>
  );

  const sectionFields = fields.filter(f => section.fields.includes(f.id));

  const rows = sectionFields?.map(f => {
    if(isEditing) return <DataTableColumnarRowEditor key={f.id} fieldKey={f.id} values={values} profile={profile} section={section} />;
    else return <DataTableColumnarRow key={f.id} fieldKey={f.id} values={values} profile={profile} />;    
  });

  return (
    <Table className="data-table-columnar-section" width="100%" size="small" sx={tableSx}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ ...cellSx, ...labelCellSx }}>
            {headerColumns}
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows}
      </TableBody>
    </Table>
  );
};

export default DataTableColumnarSection;

const tableSx = {
  "& th,td": {
    fontSize: 14,
  }
};