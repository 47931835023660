import { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ReviewSection from './review-section';
import DocsSection from './docs-section';

function FinalSection({regions, region, section, values, fields, classes}){
  const [step, setStep] = useState(0);

  const rSection        = region.sections[1];

  return (
    <Grid item container className={classes.root}>
      <Grid item container className={classes.options} justifyContent="center">
        <Tabs value={step} onChange={(e, val) => setStep(val)} indicatorColor="primary" textColor="primary">
          <Tab icon={<AssignmentTurnedInIcon fontSize="small"/>} label="Review"/>
          <Tab icon={<GetAppIcon fontSize="small"/>} label="Export"/>          
        </Tabs>
      </Grid>

      <Grid item container className={classes.stepContent}>
          {step === 0 && <ReviewSection regions={regions} region={region} section={rSection} values={values} fields={fields}/> }
          {step === 1 && <DocsSection section={section} /> } 
      </Grid>
    </Grid>
  );
}

const styles  = (theme => ({
  root  : {

  },
  congrats  : {
    fontSize    : 22,
    fontWeight  : 500,
    color       : theme.palette.secondary.main,   
    margin      : theme.spacing(1), 
    // textAlign   : "center",
  },

  stepContent   : {
    marginTop       : theme.spacing(2),
  },
  buttonIcon      : {
    marginRight   : theme.spacing(1),
  },
  instructions  : {
    // textAlign     : "center",
    fontSize      : "16px",
    "& .bold"     : {
      fontWeight    : 600,
    },
    "& .italic"   : {
      fontStyle     : "italic",
    }
  },
}));

export default withStyles(styles)(FinalSection);