import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { selectStatus, statusClearError } from '.';
import { MultiStatusInfo, SliceStatus, StatusInfo } from './status-types';

export function useStatus(key: string){
  const dispatch = useDispatch();
  //using the selector will make sure it gets cached
  const status    = useSelector<any, StatusInfo>(state => (selectStatus as any)(state, key));  
  const onClear = () => dispatch(statusClearError(key));

  return {
    ...status,
    onClear
  } as SliceStatus;
}

// export function useStatusReset(key){
//   const dispatch = useDispatch();
//   return () => dispatch({type: "STATUS_RESET", statusKey: key});
// }

export function useStatusMulti(keys: string | string[]){
  let keysArray = _.isArray(keys) ? keys : [keys];
  //remove any nulls or undefineds
  keysArray = _.compact(keysArray);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const statuses  = _.compact(keysArray.map(key => useSelector(state => (selectStatus as any)(state, key))));
  let initial = { isWorking: false, error: false, isInitialized: true, statuses: [] };

  const merged    = _.reduce(statuses, (accum: MultiStatusInfo, status: StatusInfo) => {
    const result  = {
      isWorking       : accum.isWorking || Boolean(status?.isWorking),
      error           : accum.error || Boolean(status?.error),
      isInitialized   : accum.isInitialized && Boolean(status?.isInitialized),
      statuses        : [...accum.statuses, status],
    };
    return result;
  }, initial);

  return merged;
}