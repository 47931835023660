export async function readFileAsJson(file){

  let pRead   = new Promise((resolve, reject) => {

    var reader  = new FileReader();
    
    reader.onload = (function(fData){
        return function(e){
            const content   = e.target.result;
            if(content){
              try{
                const jData     = JSON.parse(content);
                resolve(jData);
              }
              catch(ex){
                reject("Failed to parse file as JSON.");
              }
                
            }
            else{
                reject("File did not return any content.");
            }
        };
    })(file);
  
    reader.readAsText(file);
  
  });

  return pRead;
}