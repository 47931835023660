import { useMemo } from "react";
import { Card, CardContent, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { PeopleOutline, PersonOutline } from "@mui/icons-material";
import { ClientProfile } from "types";
import { formatDateString } from "helpers/general-helpers";
import { cardStyles } from "helpers/style-helpers";
import { useClient } from "../../client-context";

export interface IClientCardProps {
  client: any;
  onClose: () => void;
}

const ClientProfileCard = () => {
  const { currentClient } = useClient();
  
  const clientName = useMemo(() => {
    if(currentClient.isCouple){
      if(currentClient.lastName === currentClient.partnerLastName) return `${currentClient.firstName} & ${currentClient.partnerFirstName} ${currentClient.lastName}`;
      else return `${currentClient.firstName} ${currentClient.lastName} & ${currentClient.partnerFirstName} ${currentClient.partnerLastName}`;
    }
    else return `${currentClient.firstName} ${currentClient.lastName}`;
  }, [currentClient]);
  
  if(!currentClient) return null;

  return (
    <Card id="client-profile-card" {...cardStyles.primary}>
      <CardContent sx={{ position: "relative" }}>
        <Grid sm={12}>
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>
                    <Grid container justifyContent="space-between" alignItems="center" alignContent="center">
                      Name
                        {currentClient.isCouple && (
                          <Tooltip title="Couple">
                            <PeopleOutline fontSize="small" />
                          </Tooltip>
                        )}
                        {!currentClient.isCouple && (
                          <Tooltip title="Individual">
                            <PersonOutline fontSize="small" />
                          </Tooltip>
                        )}
                    </Grid>
                  </TableCell>
                  <TableCell>{clientName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{currentClient.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone</TableCell>
                  <TableCell>{currentClient.phone}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ verticalAlign: "flex-start" }}>Address</TableCell>
                  <TableCell><Address profile={currentClient} /></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Login</TableCell>
                  <TableCell>{formatDateString(currentClient?.profile?.lastLogin)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell>{formatDateString(currentClient.createdAt ?? currentClient?.profile?.createdOn, "MMM D, YYYY")}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Areas</TableCell>
                  <TableCell>{currentClient.areas ? currentClient.areas.join(", "): ""}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Summary</TableCell>
                  <TableCell>{currentClient.summary}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ClientProfileCard;

const Address = ({ profile } : { profile: ClientProfile }) => {
  return (
    <Stack direction="column" gap={1}>
      {profile.address1 && <span>{profile.address1}</span>}
      {profile.address2 && <span>{profile.address2}</span>}
      <span>{profile.city ? `${profile.city}, ` : ""}{profile.state} {profile.zip}</span>
    </Stack>
  );
}