import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Loading } from './';
import { Box, Typography } from '@mui/material';

function TBodyWrapper({colSpan, isWorking, isEmpty, workingText, emptyText, children}){
  const classes     = buildStyles();
  const myIsEmpty   = Boolean(!isWorking && (isEmpty || children === null));

  return (
    <tbody className={classes.tableBody}>
      <WorkingRow colSpan={colSpan} isWorking={isWorking} workingText={workingText}/>
      <EmptyRow colSpan={colSpan} isEmpty={myIsEmpty} emptyText={emptyText}/>
      {!isWorking && children}
    </tbody>
  );
}

TBodyWrapper.propTypes  = {
  colSpan       : PropTypes.number,  
  isWorking     : PropTypes.bool,
  isEmpty       : PropTypes.bool,
  workingText   : PropTypes.string,
  emptyText     : PropTypes.string,
};

TBodyWrapper.defaultProps   = {
  colSpan       : 1,
  isWorking     : false,
  isEmpty       : false,
  emptyText     : "No items found",
  workingText   : "Loading...",
};

const buildStyles   = makeStyles(theme => ({
  tableBody : {
    "& #empty-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
    "& #working-row"   : {
      "& td"   : {
        padding     : theme.spacing(3),
        fontSize    : "1.15rem",
      }
    },
  }
}));

export default TBodyWrapper;

function EmptyRow({isEmpty, colSpan, emptyText}){
  if(!isEmpty) return null;
  return (
    <tr id="empty-row">
      <td colSpan={colSpan} style={{textAlign: "center"}}>
        <Typography variant="h6" color="textSecondary" sx={{ py: 2, fontSize: 15, fontWeight: "500" }}>{emptyText}</Typography>
      </td>
    </tr>
  );
}

function WorkingRow({isWorking, colSpan, workingText}){
  if(!isWorking) return null;
  return (
    <tr id="working-row">
        <td colSpan={colSpan} >
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Loading isVisible={isWorking} message={workingText} />
          </Box>
      </td>
    </tr>
  );
}