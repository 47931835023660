import { Typography } from "@mui/material";

export interface ISectionSubtitleProps {
  section: {
    subTitle?: string;
    desc?: string;
  }
}

const SectionSubtitle = ({ section }: ISectionSubtitleProps) => {

  const content = section.subTitle ?? section.desc;
  if(!content) return null;

  if(content.toLowerCase().startsWith("<html>")){
    return (
      <Typography sx={{ mb: 1 }}>
        <div dangerouslySetInnerHTML={{__html: content}} />
      </Typography>
    );
  }

  return (
    <Typography color="grey.800" fontSize="1.2rem" fontStyle="italic" sx={{ mb: 1 }}>
      {section.subTitle ?? section.desc}
    </Typography>
  );
}

export default SectionSubtitle; 
