import _ from 'lodash';
import ErrorHelper from '../../helpers/error-helpers';
import {APP_ACTIONS} from '../actions/action-types';
// import { getFirebase } from 'react-redux-firebase';

function getValueFromStorage(key){
    const strValue  = localStorage.getItem(key);
    let readVal     = strValue;
    if(strValue){
        try{
            let objVal  = JSON.parse(strValue);
            readVal     = objVal;
        }
        catch(ex){} //ignore it if it's not an object
    }
    return readVal;
}

function writeToStore(props, store, next, action){
    const {domain, prop, key, tag, value}  = props;
    const storeKey  = key || `${domain}.${prop}`;
    //Execute the next action so the reducer will be updated
    const result    = next(action);
    
    let toStore     = value;
    if(!toStore){
        //Get the data from the store
        toStore     = store.getState()[domain];
        if(prop){
            toStore     = toStore[prop];
        }
        toStore         = _.isArray(toStore) ? toStore : _.omit(toStore, ["isInitialized", "isDirty", ]);     //don't store this value, if it's there 
    }
    const storeStr  = JSON.stringify(toStore);

    //TODO: Determine if the value changed?

    //Write the data
    localStorage.setItem(storeKey, storeStr);

    if(tag){
        //Also need to write the tag to the storage
        localStorage.setItem(tag.key, tag.value.toString());
    }

    //Need to return something out of the middleware...
    return result;
}

function readFromStore(props, store, next, action){
    const {domain, prop, key}  = props;
    const storeKey  = key || `${domain}.${prop}`;
    action.data     = getValueFromStorage(storeKey);    

    return next(action);
}

function writeValueToStore(props, store, next, action){
    const {domain, prop, key, value}  = props;
    const storeKey  = key || `${domain}.${prop}`;
        
    //Execute the next action so the reducer will be updated
    const result    = next(action);
    let writeVal    = value;
    if(value){
        try{
            const objVal    = JSON.stringify(value);
            if(objVal && objVal !== undefined) writeVal = objVal;
        }
        catch(ex){}
    }

    if(value === null || value === undefined){
        //They provided null, so remove the item
        localStorage.removeItem(storeKey);
    }
    else{
        //Write the data
        localStorage.setItem(storeKey, writeVal);
    }

    //Need to return something out of the middleware...
    return result;
}

function readList(props, store, next, action){
    const count     = localStorage.length;
    const filter    = props.filter || (v => { return true; });
    let result      = {};
    for(let i = 0; i < count; i++){
        const key   = localStorage.key(i);
        if(filter(key)) {
            const storeKey  = key.replace("figex.", "");
            result[storeKey] = getValueFromStorage(key);
        }
    }

    //mutate the action and add the list so the reducer can capture it
    action.data     = result;

    return next(action);  
}

function deleteValue(props, store, next, action){
    const {domain, prop, key}  = props;
    const storeKey  = key || `${domain}.${prop}`;
    //Execute the next action so the reducer will be updated
    next(action);
    
    //Write the data
    localStorage.removeItem(storeKey);

    //Need to return something out of the middleware...
    return {isOk: true };
}

async function deleteAll(props, store, next, action){
    //Execute the next action so the reducer will be updated
    await next(action);
    
    //Delete the data
    localStorage.removeItem("figex.lastSaved");
    localStorage.removeItem("figex.lastDownloaded");
    localStorage.removeItem("figex.values");
    // localStorage.removeItem("figex.locationKey");

    //Need to return something out of the middleware...
    return {isOk: true };
}

async function processItem(props, store, next, action){
    switch(props.type){
        case "write"        : return writeToStore(props, store, next, action);
        case "write_value"  : return writeValueToStore(props, store, next, action);
        case "read"         : return readFromStore(props, store, next, action);
        case "read_list"    : return readList(props, store, next, action);
        case "delete"       : return deleteValue(props, store, next, action);
        case "deleteAll"    : return await deleteAll(props, store, next, action);
        default:
            console.error("Local Storage Action not recognized", props);
            return null;
    }
}

const localStorageMiddleware = store => next => action => {
    if(!action.localStorage){
        return next(action);        //nothing to do here
    }

    try{
        // if(_.isArray(action.localStorage)){
        //     let result  = {};
        //     //NOTE: This won't necessarily work because next(action) is called with each round...
        //     action.localStorage.forEach(ls => {
        //         const storeKey  = ls.key || `${ls.domain}.${ls.prop}`;
        //         result[storeKey]    = processItem(ls, store, next, action);
        //     });

        //     return result;
        // }
        // else{
            return processItem(action.localStorage, store, next, action);
        // }
    }
    catch(ex){
        const err   = ErrorHelper.tryNormalize(ex);
        console.error("Error occurred during local storage read/write operation", err);
        return store.dispatch({
            type    : APP_ACTIONS.ERROR,
            error   : err,
            ..._.omit(action, ["type"]),
        });
    }
}

export default localStorageMiddleware;