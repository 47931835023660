import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import clsx from 'clsx';
import withStyles from '@mui/styles/withStyles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearAllErrors } from 'store/actions/app-actions';
import ScrollTo from 'react-scroll-to-component';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import { Alert } from '@mui/material';

const DEFAULT_TITLE     = ""; //"The system encountered an error:";

class ErrorAlert extends React.Component{
    
    componentDidUpdate(prevProps){
        if(this.props.error && !prevProps.error){
            ScrollTo(this.refs.errorAlert)
        }
    }

    getDetails(error){
        let index = 0;
        let items = null;

        if(_.isString(error)){
            return <span>{error}</span>
        }
        else if(error.details){
            let details     = error.details.split("\n");
            details         = _.compact(details);
            items           = _.map(details, item => (<li key={index++} className="error-details">{item}</li>));
        }
        else if(error.validation){
            items           =  _.map(error.validation || [], (v) => <li key={index++} className="error-details">{v?.message || v}</li>);            
        }
        
        return (!items || items.length === 0) ? null : (
            <ul className="m-l-sm list-unstyled">
                {items}
            </ul>
        );
    }

    getBody(title, error, classes){

        title               = title || DEFAULT_TITLE;
        const details       = this.getDetails(error);
        
        return (
            <Grid className="error-body" item sm={12} container direction="column">
                {title && <h3 className={classes.title}>{title}</h3>}
                <h5 className={classes.subTitle}>{error.message}</h5>
                {details}
            </Grid>
        );
    }

    render(){

        const { error, title, onClose, classes, className }   = this.props;

        if(!error) return null;
        const body  = this.getBody(title, error, classes);

        return (
            <Card id="error-alert" elevation={0} ref="errorAlert" className={clsx(classes.root, className)} variant="outlined">
                <Grid container>
                    {body}
                    {onClose && 
                        <IconButton
                            className={classes.closeButton}
                            onClick={onClose}
                            title="Dismiss Error"
                            size="large">
                            <CloseIcon size="sm"/>
                        </IconButton>
                    }
                </Grid>
            </Card>
        );
    }

}

ErrorAlert.propTypes = {
    error       : PropTypes.any,
    onClose     : PropTypes.func,
    title       : PropTypes.string,
    domain      : PropTypes.string,
    domainKey   : PropTypes.string,
}

function mapState(state, props){
    let result  = {};
    if(props.domain){
        const errorKey  = props.domainKey || "error";
        result.error    = state[props.domain][errorKey];
    }

    return result;
}

function bindActions(dispatch){
    return {
        actions     : bindActionCreators({clearAllErrors}, dispatch)
    };
}

const styles    = (theme => ({
    root            : {
        background      : theme.palette.error.light,
        border          : `1px solid ${theme.palette.error.dark}`,
        borderRadius    : 5,
        color           : theme.palette.error.contrastText,
        marginBottom    : 10,
        padding         : "10px 0 0 10px",
        position        : "relative",
        width: "100%",
    },
    closeButton     : {
        position        : "absolute",
        top             : theme.spacing(.5),
        right           : theme.spacing(.5),
        padding         : theme.spacing(.5),
        fontSize        : "0.5rem",
    },
    title           : {
        fontSize        : "1rem",
        fontWeight      : 500,
        marginTop       : 0,
        marginBottom    : theme.spacing(1),
    },
    subTitle           : {
        fontSize        : ".9rem",
        fontWeight      : 400,
        marginTop       : 0,
        marginBottom    : theme.spacing(1),
    }
}));


export default connect(mapState, bindActions)(withStyles(styles, {withTheme: true})(ErrorAlert));