import { useDispatch } from 'react-redux';
import { Paper, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FormField, FormRegion } from 'types';
import helper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
import { cardStyles } from 'helpers/style-helpers';
import { NotFound, ErrorBoundary } from 'components';
import { updateFormStatus } from 'store/actions/value-actions';
import { Section, RegionNav } from '.';
import { usePetitionerForm, useRegionStatus } from '../form-hooks';
import { useRegion } from './region-context';
import RegionSubtitle from './region-subtitle';

export interface IRegionProps {
  reposition: () => void;
}

const Region = ({ reposition }: IRegionProps) => {
  const dispatch  = useDispatch();
  const { documentId, values, onValueChanged, saveData } = usePetitionerForm();
  const { region, sections, fields } = useRegion();
  const isComplete = useRegionStatus(region!.id);

  if(!region) { 
    console.log("region not found in region component");
    return <NotFound/>;
  } 

  const _onChange = async (sectionId: string, fieldId: string, value: any, details: any, index: number) => {
    if(isComplete){
      await dispatch(updateFormStatus(documentId, region.id, false));
    }
    return onValueChanged(region.id, sectionId, fieldId, value, details, index);
  };
      
  const sectionNodes  = sections.map(section => <Section key={section.id} region={region} section={section} onChange={_onChange}></Section>);

  return (
    <Paper id={region.id} data-type="region" {...cardStyles.primaryX({pr: 1})} >
      <Stack spacing={2}>
        
        <Grid id="region-header" container justifyContent="space-between" sx={{pr: 2}}>      
          <Typography variant="h2" fontSize="1.1rem" color="secondary" >{region.title}</Typography>
          <Typography variant="h2" fontSize="1.1rem" fontWeight="500" color="secondary" >{calcRegionTotal(region, values, fields)}</Typography>
        </Grid>

        <RegionSubtitle region={region} />
        
        <div className="jr-card-body">
          <ErrorBoundary>
            <Grid container>
              {sectionNodes}
            </Grid>
            <RegionNav region={region} onSave={saveData} reposition={reposition}/>
          </ErrorBoundary>
        </div>
      </Stack>
    </Paper>        
  );
}

export default Region;

const calcRegionTotal = (region: FormRegion, data: Record<string, any>, fields: FormField[]) => {
  if(!region.totals) return null;
  return nHelper.asCurrency(helper.calculateRegionTotal(region, data, undefined, fields), "$0");
}