import { Box, ListItemButton, ListItemIcon, ListItemText, SxProps } from "@mui/material";
import TooltipConditional from "../tooltip-conditional";
import BadgeWrapper from "../badge-wrapper";

export interface ITopicListItemProps {
  label: string;
  icon: any;
  isSelected: boolean;
  isDirty: boolean;
  onClick: () => void;
  sx?: SxProps;
}

const TopicListItem = ({label, icon, isSelected, isDirty, onClick, sx}: ITopicListItemProps) => {
  return (
    <TooltipConditional title="This conversation has new messages" condition={isDirty}>
      {/* <ListItem
        secondaryAction={ isDirty ? <Chip size="small" color="secondary" label="new" /> : null }    //or <CommentIcon fontSize="small" color="secondary" />
        disablePadding
      > */}
        <ListItemButton selected={isSelected} onClick={onClick} sx={sx}>
          <ListItemIcon>
            <BadgeWrapper show={isDirty} variant="dot" color="secondary">
              <Box sx={{ color: isDirty ? "secondary.main" : "inherit" }}>
                {icon}
              </Box>
            </BadgeWrapper>
          </ListItemIcon>
          <ListItemText primary={label}  sx={{ color: isDirty ? "secondary.main" : "inherit", "& .MuiTypography-root": { fontWeight: isDirty ? "700" : "400" } }} />  
        </ListItemButton>
      {/* </ListItem> */}
    </TooltipConditional>
  );
};

export default TopicListItem;