import { useRef, useState } from "react";
import { useHoverDirty } from "react-use";
import { alpha, Box, TableCell, TableRow, useTheme } from "@mui/material";
import { FormField, FormSection } from "types";
import { sameAsFlag } from "helpers/model-helpers";
import fHelper from 'helpers/financials-helper';
import { useAppContext } from "sections/app/app-context";
import { useRegionSelection } from "./region-context";
import { InputField } from "./input-field";
import { SameAsInput } from "./v1.0.0";
import CommentCell from "./comment-cell";

const QuestionSectionRow = ({section, field, values}: {section: FormSection, field: FormField, values: any}) => {
  const theme = useTheme();
  const { allowComments } = useAppContext();
  const { selectedField, handleFieldSelected } = useRegionSelection();
  const rowRef = useRef<any>(null);
  const isHovered = useHoverDirty(rowRef);
  const inputCols = section.config?.inputHeaders?.length ?? 1;
  
  const [isSameAs, setSameAs] = useState(field.sameAs ? values[sameAsFlag(field)] ?? false : false);
  const onSameAsChange = (value: boolean) => {
    setSameAs(value);
  };

  if(field.type === "separator") return <SeparatorRow label={field.label} colSpan={inputCols} />;
  else{
    if(field.condition){
      if(!fHelper.isConditionMet(field.condition, values)) return null;
    }

    const dProps = isSameAs ? { disabled: true, style: { visibility: "hidden" } } : undefined;
    const fields = field.params ?? field.childFields ?? [field];
    const fieldInputs    = fields.map(p => <InputField key={p.id} field={p} showLabel={false} sectionId={undefined} rowIndex={undefined} dataProps={dProps} onChangeOverride={undefined}/>);
    let inputCells: any = null;

    if(inputCols > 1 && fieldInputs.length > 1){  //header cols and multiple inputs, so put each input in its own cell
      inputCells = fieldInputs.map((f, index) => {
        return (
          <TableCell key={index} align="center">
            {f}
          </TableCell>
        );
      });
    }
    else if(inputCols > 1){   //header cols, but only one input, so center it
      inputCells = (
        <TableCell align="center" colSpan={inputCols}>
          {/* {fieldInputs} */}
          <Box width="100%" sx={{ display: "flex", columnGap: 1, flexGrow: 1, justifyContent: "flex-end" }} >
            {fieldInputs}
            {field.sameAs && <SameAsInput field={field} values={values} onChange={onSameAsChange} />}
          </Box>
        </TableCell>
      );      
    }
    else {  //No header cols, so right-align
      inputCells = (
        <TableCell colSpan={inputCols}>
          <Box width="100%" sx={{ display: "flex", columnGap: 1, flexGrow: 1, justifyContent: "flex-end" }} >
            {fieldInputs}
            {field.sameAs && <SameAsInput field={field} values={values} onChange={onSameAsChange} />}
          </Box>
        </TableCell>
      );
    }
    
    const label = field.label?.length > 0 ? field.label : field.childFields?.[0].label ?? field.childFields?.[1].label ?? "";
    const isSelected = selectedField?.id === field.id;
    const bgcolor = isSelected ? alpha(theme.palette.primary.light, 0.15) : (isHovered ? alpha(theme.palette.info.light, 0.1) : "unset");

    const handleClick = () => handleFieldSelected(field.id);

    return (
      <TableRow ref={rowRef} onClick={handleClick} onFocus={handleClick} className="question-row" sx={{ bgcolor }}>
        <TableCell className="question-label">{label}</TableCell>
        {inputCells}
        <CommentCell isHovered={isHovered && allowComments} sectionId={section.id} fieldId={field.id} />
      </TableRow>
    );
  }
};

export default QuestionSectionRow;

const SeparatorRow = ({colSpan, label, classNames}: {colSpan: number, label?: string, classNames?: string}) => {
  const cns = classNames ?? "question-row separator-row";
  
  return (
    <TableRow className={cns}>
      <TableCell colSpan={colSpan}>{label ?? ""}</TableCell>
    </TableRow>
  );
};
