import { useDispatch } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import PropTypes from 'prop-types';
import nHelper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { InputField } from './';
import { withFieldChanged } from './with-field-changed';
import { deleteRow } from 'store/actions/value-actions';
import { Box, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { textStyles } from 'helpers/style-helpers';
import { useRegion } from './region-context';
import { compact } from 'lodash';

function ListSection({section, values, onChange, onFieldChanged}){
    const dispatch  = useDispatch();
    const { getSectionFields, getField } = useRegion();

    const renderHeader = (section, fields, data, isCollapsed) => {
        let titleRow    = null;
        
        if(section.title){
            let colSpan     = fields.length;
            let headerField = null;
            let descField   = null;

            if(section.headerField){
                const hField    = getField(section.headerField, false);
                headerField     = <InputField field={hField} data={data} onChangeOverride={onFieldChanged}/>;                
            }

            if(section.desc){
                descField = (
                    <p className="section-desc">
                        {section.desc}
                    </p>
                )
            }

            titleRow    = (
              <tr className="section-header">
                <td colSpan={colSpan} className="section-title-col">
                  {headerField}
                  <Typography color="primary" fontSize="1.2rem" component="span">{section.title}</Typography>
                  <Typography color="grey.700" fontSize="1rem" fontStyle="italic">{descField}</Typography>
                </td>
              </tr>
            );
        }

        let colHeaders  = null;
        if(!isCollapsed){
            const cols      =  fields.map(f => (<td key={f.id} className={"field-" + (f.size || "sm")}>{f.header ?? f.label}</td>))
            colHeaders      = (
              <tr className="list-col-headers">
                  {cols}
                  <td className="delete-col"></td>
              </tr>
            );
        }

        return (
            <thead>
                {titleRow}
                {colHeaders}
            </thead>
        )
    }

    const renderRow = (fields, sectionId, data, index) => {
      const isEmpty   = data === null;
      data            = data || {};
      let cIndex      = 0;
      const dataProps = {"data-index" : index};

      const cols  = fields.map(f => {
        const myIndex   = cIndex++;
        const colClass  = `list-col-${f.type} field-${f.type}`;
        return (
          <TableCell key={myIndex} className={colClass}>
            <InputField field={f} sectionId={sectionId} rowIndex={index} data={data} index={myIndex} dataProps={dataProps}/>
          </TableCell>
        );
      });

      return (
        <TableRow key={index}>
          {cols}
          {!isEmpty && 
            <TableCell className="delete-col">
              <Tooltip title="Delete this row">
                <DeleteIcon fontSize="small" onClick={() => dispatch(deleteRow(sectionId, index))}/>
              </Tooltip>
            </TableCell>
          }
        </TableRow>
      );
    }

    const renderSubtotalRow = (fields, rowData) => {
      rowData        = rowData || [];
      let total   = 0;

      const cols  = fields.map(f => {
        let content     = null;
        let colKey      = `${f.id}-total`;

        if(f.isSummed){
          content      = fHelper.sumField(f, rowData);
          total       += content;
          if(f.type === "currency") content = nHelper.asCurrency(content);
        }
        else if(fields.indexOf(f) === 0){
          //This is the first field, so add a "Totals" value
          return (
            <TableCell key={colKey}>
              <Typography {...textStyles.summaryFieldLabelX({fontWeight: "600"})}>Total</Typography>
            </TableCell>
          );
        }

        return (
          <TableCell key={colKey}>
            <Typography {...textStyles.summaryFieldLabelX({fontWeight: "600", textAlign: "end"})}>{content}</Typography>
          </TableCell>
        );
      });

      if(total === 0) return null;
      return (
          <TableRow className="section-subtotal">
            {cols}
          </TableRow>
      )
    }

    const renderRows = (section, fields, data, isCollapsed) => {
        
        if(isCollapsed){
            return null;
        }

        let rowData     = data[section.id] ? [...data[section.id]] : [];
        rowData = compact(rowData);

        const minRows   = 1;       
        if(rowData.length < minRows){
            for(let i = 0; i < minRows; i++) rowData.push(null);
        }
        else{
            rowData.push(null); //always have one blank row at the end
        }

        let index       = 0;
        const rowNodes  = rowData.map(d => renderRow(fields, section.id, d, index++));
        const subtotals = renderSubtotalRow(fields, rowData);

        return (
            <TableBody onChange={onFieldChanged}>
              {rowNodes}
              {subtotals}
            </TableBody>
        );
    }

    const fields        = getSectionFields(section.id); //fHelper.getFields(section.fields);
    const isCondUndef   = fHelper.isConditionUndefined(section.condition, values);
    const isCondNotMet  = !fHelper.isConditionMet(section.condition, values);
    const isCollapsed   = isCondUndef || isCondNotMet;
    const headerRow     = renderHeader(section, fields, values, isCollapsed);
    const body          = renderRows(section, fields, values, isCollapsed);

    return (
      <Box id="section-list" sx={{ width: "100%", borderBottom: "1px solid", pb: 1, mb: 2, borderBottomColor: "grey.300" }}>
        <Table size="small" className="section-table list-section">
          {headerRow}
          {body}
        </Table>
      </Box>
    );
}

ListSection.propTypes   = {
    section     : PropTypes.object.isRequired,
    values      : PropTypes.object.isRequired,
    onChange    : PropTypes.func.isRequired
};

export default withFieldChanged(ListSection);