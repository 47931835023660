import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CopyIcon from '@mui/icons-material/FileCopy';
// import { WaitButton } from 'components';
import { useStatus } from 'redux-action-status';

const UserDialog = ({profile, onClose}) => {
  const classes   = buildStyles();
  const status    = useStatus("users");
  
  if(!profile) return null;

  function copyId(){
    navigator.clipboard.writeText(profile.id);
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs">
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>{profile.displayName}</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField value={profile.uid} disabled className={classes.textField} fullWidth label="Uid" 
                InputProps={{
                  endAdornment : <InputAdornment position="end" aria-label="copy UID">
                    <IconButton onClick={copyId} size="small" title="Copy UID">
                      <CopyIcon fontSize="small"/>
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField value={profile.displayName} disabled className={classes.textField} fullWidth label="Display Name" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={profile.email} disabled className={classes.textField} fullWidth label="Email" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={profile.role} disabled className={classes.textField} fullWidth label="Role" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={profile.createdOn} disabled className={classes.textField} fullWidth label="Created On" />
            </Grid>                    
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        {/* <WaitButton color="danger" size="small" isWaiting={status.isWorking} disabled={!canDelete} onClick={onDelete}>Delete</WaitButton> */}
        <Button size="small" onClick={onClose} disabled={status.isWorking}>Cancel</Button>
      </DialogActions>

    </Dialog>
  );
}

export default UserDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  textField           : {
    // width         : "100%",
    "& input, textarea, label"   : { 
      fontSize      : 16,      
    },
    "& label" : {
      color   : theme.palette.grey[400],
    }
  },
}));