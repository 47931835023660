import { STATUS_START, STATUS_FINISH, STATUS_FAILED, STATUS_RESET, STATUS_CLEAR_ERROR } from './status-middleware';

//--------------
// The default status structure
export const DEFAULT_STATUS  = { isWorking: false, error: null, isInitialized: false, loadedAt: null, };

//--------------
// Initial state, no statuses until we start doing something
const INIT_STATE  = {};

function started(state, action){
  //create the status for this key if it doesn't already exist
  const key   = action.statusKey || action.startStatusKey;
  if(!key) return state;
  const status  = state[key] || {...DEFAULT_STATUS};
  if(status.isWorking) return state;  //exit if we're already working

  return {
    ...state,
    [key]     : {
      ...status,
      isWorking     : true,
      error         : null,
    }
  };
}

function finished(state, action){
  const key   = action.statusKey || action.finishStatusKey;
  if(!key) return state;
  return {
    ...state,
    [key]     : {
      ...state[key],
      isWorking     : false,
      isInitialized : true,
      loadedAt      : getNowString(),
    }
  };
}

function failed(state, action){
  const key   = action.statusKey || action.finishStatusKey;
  if(!key) return state;
  return {
    ...state,
    [key]     : {
      ...state[key],
      isWorking     : false,
      error         : action.error,
      // isInitialized: true,    //TODO: is it ok that we are still initialized, even if there's an error?
    }
  };
}

function clearError(state, action){
  const key   = action.statusKey;
  if(!key) return state;
  return {
    ...state,
    [key]     : {
      ...state[key],
      error         : null,
    }
  };
}

//reset the status to it's empty state.
function reset(state, action){
  // if(action.statusKey) return {...state, [action.statusKey]: {...DEFAULT_STATUS}};
  return {...INIT_STATE};    
}

//--------------
// The map for the reducer
const statusReducer = {
  [STATUS_START]      : started,
  [STATUS_FINISH]     : finished,
  [STATUS_FAILED]     : failed,
  [STATUS_RESET]      : reset,
  [STATUS_CLEAR_ERROR]: clearError,
};

//--------------
// Create the reducer from the map
export default createReducer(INIT_STATE, statusReducer);

//===================
// helper functions


//--------------
// Creates a reducer on the fly out of an object map
function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type)) {
      return handlers[action.type](state, action)
    } 
    else {
      return state
    }
  }
}

//--------------
// Used to track when an item was loaded
export function getNowString(){
  return new Date().toISOString();
}