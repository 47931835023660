import { useSelector } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { selectNavCounts } from "store/selectors/petitioner-selectors";
import { useAppContext } from "sections/app/app-context";
import NavCard from "./nav-card";

export interface IPetitionerHomeNavProps {
  onSelected: (id: string) => void;
  selected: string;
}

const PetitionerHomeNav = ({ onSelected, selected }: IPetitionerHomeNavProps) => {
  const isShort = true; //useMediaQuery('(max-height:750px)');
  const { allowComments } = useAppContext();
  const counts = useSelector<any, Record<string, number>>(selectNavCounts);
  const height = isShort ? 130 : 170;
  const itemCols = allowComments ? 3 : 4;

  const needsAttention = (item: any) => {
    if(item.id === "todo" && counts.toDoCount > 0) return true;
    if(item.id === "conversations" && counts.convoCount > 0) return true;
    return false;
  }

  return (
    <Grid container width="100%" justifyContent="center" py={3} height={height}>
      <Grid container sm={12} md={10} py={1}>
        {navCards(allowComments).map((item, index) => {
          let subtitle = item.countKey ? `${counts[item.countKey]} ${item.countSuffix}` : "click to view";
          if(isShort) subtitle = `${counts[item.countKey]}`;
          return (
            <Grid key={index} container xs={itemCols} px={4} justifyContent="stretch">
              <NavCard 
                onClick={() => onSelected(item.id)} 
                title={item.label} 
                isSelected={selected === item.id} 
                subTitle={subtitle}
                isAttention={needsAttention(item)}
                compact={isShort}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  );
};

export default PetitionerHomeNav;


const navCards = (allowComments: boolean) => {
  let items = [
    { id: "todo", label: "To Do", countKey: "toDoCount", countSuffix: "tasks" },
    { id: "done", label: "Done", countKey: "doneCount", countSuffix: "complete" },
    { id: "uploads", label: "Uploads", countKey: "uploadsCount", countSuffix: "uploads" },
  ];

  if(allowComments) items.push(    { id: "conversations", label: "Conversations", countKey: "convoCount", countSuffix: "unread" },);

  return items;
};