import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from "react-router-dom";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import DataTable from '../../components/values-table/data-table';
import { IfBlock, NavButton } from 'components';
import ConversationChooser from '../financials/parts/conversation-chooser';
import { useStatusMulti } from 'redux-action-status';
import { chooseConversation } from 'store/actions/conversation-actions';
import ErrorAlert from 'components/error-alert';
import { ShareProvider } from '../app/share-context';

const ViewData = () => {
  const classes = buildStyles();
  const dispatch = useDispatch();
  const status = useStatusMulti(["conversations", "comments"]);
  const { conversationId }  = useParams(); 
  const conversations = useSelector(state => state.convo.conversations);
  
  const hasConvos = Boolean(conversations);

  useEffect(() => {
    //Only do this once we've got the conversations loaded
    if(conversations){
      const id  = conversationId || null;
      dispatch(chooseConversation(id));
    }
  }, [conversations, conversationId, dispatch]);
  
  return (
    <Grid id="data-view" container className={classes.root}>
      <Grid item container style={{flexWrap: "nowrap"}} spacing={2}>
        <Card className={classes.headerCard} variant="outlined">
          <CardContent>
            <Grid container>
              <Grid item xs={9} container alignItems="flex-start">
                <Typography variant="h5" color="secondary" className={classes.title}>Your FormHop data</Typography>
              </Grid>
              <Grid item xs={3} container justifyContent="flex-end" alignItems="flex-start">
                {/* <Loading isVisible={status.isWorking} size="small" noMessage={true} /> */}
                <NavButton to="/my/data" title="Back to Data Management" color="primary" size="small" className={classes.backButton}><CloseIcon fontSize="small"/> Close</NavButton>
              </Grid>
              <Grid item>
                <Typography variant="h6" className={classes.info}>Select a conversation to view comments from your <Link to="/my/sharing">reviewers</Link>.</Typography>
              </Grid>
              <IfBlock condition={hasConvos}>
                <Grid item container className={classes.selectorGrid}>
                  <ErrorAlert error={status.error} title="Failed to update conversation" className={classes.error}/>
                  <ConversationChooser />
                </Grid>
              </IfBlock>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item container className={classes.dataTableContainer}>
        <DataTable isFirstExpanded={true}/>
      </Grid>
    </Grid>
  );
};


//TODO: need to get this working.
const ViewDataWrapper = () => {
  return (
    <ShareProvider>
      <ViewData />
    </ShareProvider>
  );
};

export default ViewDataWrapper;

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(1),
  },
  headerCard  : {
    width     : "100%",
    margin    : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    marginBottom  : theme.spacing(1),
    // background    : theme.palette.grey[50],
  },
  backButton  : {
    fontSize    : 15,
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
  },
  dataTableContainer  : {
    padding     : theme.spacing(1),
  },
  info  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[600],
    marginBottom  : theme.spacing(2),
    "& a, a:hover"  : {
      textDecoration  : "none",
      color           : theme.palette.secondary.main,
    }
  },
  selectorGrid  : {
    padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`

  },  
  error   : {
    width     : "100%",
  }
}));