//A react context provider that provides the attorney context to the children components.
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStatus } from "redux-action-status";
import { AccountMember, ProviderAccount } from "types";
import { AccountSettingsSchema } from "types/schema";
import { LoadingBar } from "components";
import { StatusKeys } from "helpers";
import { loadSentRequests, loadShares } from "store/actions/share-actions";
import { loadAttorneyForms, loadClientList } from "store/actions/attorney-actions";
import { AccountSlice } from "store/reducers/account-slice";
import { updateAccountProfile } from "store/actions/account-actions";

export type IAttorneyContext = {
  refreshClients: () => void;
  refreshForms: () => void;
  account: ProviderAccount | null;
  members: AccountMember[];
  getMember: (id: string) => AccountMember | null;
  accountSettings: AccountSettingsSchema | undefined;
  updateAccountSettings: (settingType: string, newValue: any) => Promise<boolean>;
}

export const AttorneyContext = createContext<IAttorneyContext | null>(null);

export const useAttorneyContext = () => {
  const context = useContext(AttorneyContext);
  if(!context) throw new Error("useAttorneyContext must be used within an AttorneyProvider.");
  return context;
};

export const AttorneyProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const accountSlice = useSelector<any, AccountSlice>(state => state.account);
  const account = useMemo(() => accountSlice.account, [accountSlice]);
  const [isReady, setReady] = useState(false);
  const shareStatus = useStatus(StatusKeys.shares);
  const accountSettings = useSelector<any, AccountSettingsSchema>(state => state.account?.account?.settings);
  
  const refreshClients = async () => {
    const pZero = dispatch(loadClientList());
    const pOne  = dispatch(loadSentRequests(true));
    const pTwo  = dispatch(loadShares(true));
    await Promise.all([pZero, pOne, pTwo]);
  }

  const refreshForms = async () => {
    await dispatch(loadAttorneyForms());
  }

  const getMember = useCallback((id: string) => {
    return account?.members?.find(member => member.id === id) ?? null;
  }, [account?.members]);

  const updateAccountSettings = async (settingType: string, newValue: any) => {
    const changes = {
      settings: {
        ...accountSettings,
        [settingType]: newValue,
      },
    };

    const result = await dispatch(updateAccountProfile(changes)) as any;
    return result.isOk;
  }

  useEffect(() => {
    async function init(){
      //shares are initialized by the app-context for all user types, so no need to trigger it here.
      await refreshForms();
      setReady(true);
    };
    if(!isReady) init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  const contextValue = {
    refreshClients,
    refreshForms,
    account,
    members: account?.members ?? [],
    getMember,
    accountSettings,
    updateAccountSettings,
  };

  return (
    <>
      <AttorneyContext.Provider value={contextValue}>
        {isReady && shareStatus.isInitialized && children}
        {(!isReady || !shareStatus.isInitialized) && <LoadingBar />}
      </AttorneyContext.Provider>
    </>
  );
}