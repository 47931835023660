import _ from 'lodash';
import { APP_ACTIONS } from '../actions/action-types';
import { ONBOARDING_ACTIONS } from '../actions/onboarding-actions';
import helpers from './reducer-helpers';

//Create the Initial State, merging in the common DEFAULT_STATE
const INITIAL_STATE     = _.merge({}, helpers.DEFAULT_STATE, 
    {
      invitation        : null,
      invitationCode    : null,
      invitationError   : null,

      // tosAgreed         : null,
    }
);

// function waitlisted(state, action){
//   return {
//     ...state,
//     isWaitlisted    : true,
//   };
// }

function inviteLoaded(state, action){
  const data  = action.data || { status: "error", error: {code: "not-found", message: "This invitation code was not found. Please confirm the code and try again."}};
  let invite  = null;
  let error   = null;

  if(action.status === "ok"){
    if(action.data.acceptedOn){
      //Someone's already used this invite
      error   = {code: "invite-used", message: "This invitation has already been used.  Do you need to log in as a returning user?"};
    }
    else{
      invite  = data;
    }
  }
  else{
    error   = data.error;
  }
  
  return {
    ...state,
    invitation      : invite,
    invitationCode  : invite?.id,
    invitationError : error,
  };
}

function inviteUpdated(state, action){

  return {
    ...state,
    invitation      : {...state.invite, ...action.data},
    invitationCode  : action.data?.id,
    invitationError : null,
  };
}

// function tosAgreed(state, action){
//   return {
//     ...state,
//     tosAgreed     : action.value,
//   };
// }
  
function onSignedOut(state, action){
  return {...INITIAL_STATE };
}

//========================
// The App reducer
const onboardingReducer    =  {
  // [ONBOARDING_ACTIONS.JOINED_WAITLIST]        : waitlisted,
  [ONBOARDING_ACTIONS.INVITE_LOADED]    : inviteLoaded,
  [ONBOARDING_ACTIONS.INVITE_UPDATED]   : inviteUpdated,
  // [ONBOARDING_ACTIONS.TOS_AGREED]       : tosAgreed,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

export default helpers.createReducer(INITIAL_STATE, onboardingReducer);