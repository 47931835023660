import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useHoverDirty } from 'react-use';
import { compact } from 'lodash';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, alpha, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DeleteOutline } from '@mui/icons-material';
import { FormField, FormSection } from 'types';
import { deleteRow } from 'store/actions/value-actions';
import { asCurrency, textStyles } from 'helpers';
import fHelper from 'helpers/financials-helper';
import { sameAsFlag } from 'helpers/model-helpers';
import { useAppContext } from 'sections/app/app-context';
import { useRegion, useRegionSelection } from './region-context';
import { withFieldChanged } from "./with-field-changed";
import CommentCell from './comment-cell';
import { InputField } from './';
import SectionSubtitle from './section-subtitle';
import { SameAsListInput } from './v1.0.0';

export interface ISectionComponentProps {
  section: FormSection;
  fields: FormField[];
  values: any;
  //comes from withFieldChanged
  onFieldChanged: (event: any) => void;
}

const ListSection2 = ({ section, fields, values, onFieldChanged }: ISectionComponentProps) => {
  const dispatch  = useDispatch();
  if(!fields || !fields.length) return null;    //wait until we have fields to work with

  const isCondUndef   = fHelper.isConditionUndefined(section.condition, values);
  const isCondNotMet  = !fHelper.isConditionMet(section.condition, values);
  const isSameAs = section.sameAs ? values[sameAsFlag(section)] ?? false : false;
  const isCollapsed   = isCondUndef || isCondNotMet || isSameAs;
    
  const tableProps = {
    "& td, th": { borderBottom: "none", p: 0.5 },
  };

  const handleRowDeleted = async (sectionId: string, index: number) => {
    await dispatch(deleteRow(sectionId, index))
  }

  return (
    <Box id="section-list" sx={{ width: "100%", borderBottom: "1px solid", pb: 1, mb: 2, borderBottomColor: "grey.300", ...tableProps }}>
      <Table size="small" className="section-table list-section">
        <ListSectionHeader section={section} fields={fields} values={values} isCollapsed={isCollapsed} onFieldChanged={onFieldChanged} />
        {!isCollapsed && <ListSectionBody section={section} fields={fields} values={values} isCollapsed={isCollapsed} onFieldChanged={onFieldChanged} onDeleteRow={handleRowDeleted} />}
      </Table>
    </Box>
  );
};

export default withFieldChanged(ListSection2); 

interface IListSectionPartProps {
  section: FormSection;
  fields: FormField[];
  isCollapsed?: boolean;
  values?: any;
  index?: number;
  onFieldChanged?: (event: any) => void;
  onDeleteRow?: (sectionId: string, index: number) => void;
}

//== Header Row for the list section
const ListSectionHeader = ({ section, fields, values, isCollapsed, onFieldChanged }: IListSectionPartProps) => {
  const theme = useTheme();
  const rowRef = useRef<any>(null);
  const { allowComments } = useAppContext();
  const { selectedField, handleFieldSelected } = useRegionSelection();
  const isHovered = useHoverDirty(rowRef);
  const { getField } = useRegion();
  const { title, headerField, desc } = section;
  const colSpan = fields.length;

  const headerFieldProps = headerField ? getField(headerField, false) : null;
  const isSelected = selectedField?.id === section.id;
  const bgcolor = isSelected ? alpha(theme.palette.primary.light, 0.15) : (isHovered ? alpha(theme.palette.info.light, 0.1) : "unset");

  return (
    <TableHead>
      {!!title && (
        <>
          <TableRow ref={rowRef} onClick={() => handleFieldSelected(section.id)} className="section-title" sx={{ bgcolor }}>
            <TableCell colSpan={colSpan} className="section-title-col">
              <Grid container columnGap={1} alignItems="center">
                {!!headerFieldProps && <InputField field={headerFieldProps} onChangeOverride={onFieldChanged} sectionId={undefined} rowIndex={undefined} dataProps={undefined} showLabel={undefined} />}
                <Typography color="primary" fontSize="1.2rem" component="span">{title}</Typography>
                {!!section.sameAs && (<SameAsListInput section={section} values={values} onChange={() => {}} />)}
              </Grid>
            </TableCell>
            <CommentCell isHovered={isHovered && allowComments} sectionId={section.id} />
          </TableRow>

          {(section.desc || section.subTitle) && (
            <TableRow className="section-subtitle">
              <TableCell colSpan={colSpan} className="section-subtitle-col" sx={{ pt: 0 }}>
                <SectionSubtitle section={section} />
              </TableCell>
            </TableRow>
          )}
        </>
      )}

      {!isCollapsed && (
        //The Column Header Row
        <TableRow className="list-col-headers" >
          {fields.map(f => (
            <TableCell key={f.id} className={`field-${f.size || "sm"}`} sx={{ fontSize: "1.1rem", fontWeight: "400" }}>
              {(f as any).header ?? f.label}
            </TableCell>
          ))}
          <TableCell className="delete-col" />
        </TableRow>
      )}

    </TableHead>
  );
};

//== Row for the list section
// values will be the valueRow from the ListSectionBody
const ListSectionRow = ({ section, fields, values, index, onDeleteRow }: IListSectionPartProps) => {
  const isEmpty   = values === null;
  values            = values || {};
  const dataProps = {"data-index" : index ?? 0};
  const canDelete = !section.list?.rows;

  return (
    <TableRow>
      
      {/* The field columns */}
      {fields.map(f => (
        <TableCell key={f.id} className={`list-col-${f.type} field-${f.type}`}>
          <InputField field={f} sectionId={section.id} rowIndex={index} dataProps={dataProps} onChangeOverride={undefined} showLabel={undefined}/>
        </TableCell>
      ))}

      {/* The Delete Row Column */}
      {(!isEmpty && onDeleteRow) &&  
        <TableCell className="delete-col">
          <Tooltip title={canDelete ? "Delete this row" : "Clear this row"}>
            <DeleteOutline fontSize="small" onClick={() => onDeleteRow(section.id, index!)}/>
          </Tooltip>
        </TableCell>
      }
    </TableRow>
  );
};

//== Subtotal Row.
// values will be the valueRows array from the ListSectionBody
const ListSectionSubtotalRow = ({ section, fields, values }: IListSectionPartProps) => {

  const hasSubtotals = fields.some(f => f.isSummed);
  if(!hasSubtotals) return null;

  const subtotals = fields.map(f => f.isSummed ? fHelper.sumField(f, values) : 0);
  const total = subtotals.reduce((acc, val) => acc + val, 0);
  if(total === 0) return null; //no need to show a row if there's no total

  return (
    <TableRow className="section-subtotal">
      {fields.map((f, i) => (
        <TableCell key={`${f.id}-total`} className="section-subtotal-col">
          {i === 0 && <Typography {...textStyles.summaryFieldLabelX({fontWeight: "600"})}>Total</Typography>}
          {i !== 0 && f.isSummed && subtotals[i] > 0 && (
            <Typography {...textStyles.summaryFieldLabelX({fontWeight: "600", textAlign: "end"})}>
              {f.type === "currency" ? asCurrency(subtotals[i]) : subtotals[i]}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRow>
  );
};

//== Body of the List Section
const ListSectionBody = ({ section, fields, values, isCollapsed, onFieldChanged, onDeleteRow }: IListSectionPartProps) => {
  if(isCollapsed) return null;  //Nothing to show here, if we're collapsed (though the section handles this too)

  const valueRows = compact(values[section.id] ? [...values[section.id]] : []);
  const currRowCount = valueRows.length;
  const minRows = section.list?.rows ?? 1;
  if(currRowCount < minRows){
    const neededRows = minRows - currRowCount;
    for(let i = 0; i < neededRows; i++) valueRows.push(null);
  }
  else if(currRowCount !== section.list?.rows)  valueRows.push(null);  //add an empty row at the end
  // else valueRows.push(null);

  return (
    <TableBody onChange={onFieldChanged}>
      {valueRows.map((valueRow, index) => <ListSectionRow key={index} section={section} index={index} fields={fields} values={valueRow} onDeleteRow={onDeleteRow} onFieldChanged={onFieldChanged} /> )}
      <ListSectionSubtotalRow section={section} fields={fields} values={valueRows} />
    </TableBody>
  )
};