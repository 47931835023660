import { useDispatch } from "react-redux";
import { Box, Chip, IconButton, Menu, MenuItem, TableCell, TableRow, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { EditOutlined, MoreVert as MoreVertIcon, Star, StarOutlineOutlined } from '@mui/icons-material';
import { toast } from "react-toastify";
import { FormSummary } from "types";
import { tableStyles } from "helpers/style-helpers";
// import { useAnchor } from "helpers/hooks";
import { updateAttorneyForm } from "store/actions/attorney-actions";

export interface IFormListItemProps {
  item: FormSummary;
  onEdit: (item: FormSummary) => void;  
}

function FormListItem({ item, onEdit }: IFormListItemProps) {
  const dispatch = useDispatch();
  const statusProps = getFormStatus(item);
  const { title, ...chipProps } = getFormResponsibility(item);
  
  const onFavoriteChange = async () => {
    const updates = { isStarred: !item.isStarred };
    const result = await dispatch(updateAttorneyForm(item.id, updates)) as any;
    if(result.isOk) toast("Form updated successfully", { type: "success" });
  }

  return (
    <TableRow>
      <TableCell sx={{...tableStyles.cell, minWidth: "20rem" }}>
        
        <Grid container gap={1} alignItems="center">
          <IconButton size="small" onClick={onFavoriteChange}>
            { item.isStarred ? <Star fontSize="small" color="primary"/> : <StarOutlineOutlined fontSize="small" /> }
          </IconButton>
          {item.name}
        </Grid>

      </TableCell>
      <TableCell sx={tableStyles.cell}>{item.category}</TableCell>
      <TableCell sx={tableStyles.cell}>
        <Tooltip title={title}>
          <Chip variant="outlined" size="small" {...chipProps as any} />        
        </Tooltip>
      </TableCell>
      <TableCell sx={tableStyles.cell}>{item.description}</TableCell>
      <TableCell sx={tableStyles.cell}>
        <Chip variant="outlined" size="small" {...statusProps as any} />
      </TableCell>
      <TableCell sx={tableStyles.cell}>
        <Tooltip title="Edit form">
          <IconButton size="small" onClick={() => onEdit(item)}>
            <EditOutlined fontSize="small" />
          </IconButton>
        </Tooltip>
        {/* <IconButton size="small" onClick={onClick}>
          <MoreVertIcon fontSize="small"/>
        </IconButton> */}
          {/* TODO: make this dependent on the status of the row */}
        {/* <Menu id="moreMenu" anchorEl={anchor} open={isOpen} onClose={onClose} disabled={status.isWorking}>
          <MenuItem onClick={onToggleDelete} disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Delete invitation</MenuItem>
          <MenuItem disabled={Boolean(item.acceptedOn) || status.isWorking} fontSize="small">Re-send email</MenuItem>              
        </Menu> */}
      </TableCell>
    </TableRow>
  );

}

export default FormListItem;

const getFormStatus = (item: any) => {
  if(item.templateFileName) return { color: "success", label: "Ready", sx: { cursor: "pointer" } };
  if(item.configFileName) return { color: "success", label: "Ready", sx: { cursor: "pointer" } }; //{ color: "info", label: "Assignable" };
  else return { color: "warning", label: "Pending", sx: { cursor: "pointer" } };
};

const getFormResponsibility = (item: FormSummary) => {
  if(item.isClientForm) return { color: "info", label: "Client", title: "The client will complete this form", sx: { cursor: "pointer" } };
  else return { color: "success", label: "Attorney", title: "The attorney will complete this form", sx: { cursor: "pointer" } };
};