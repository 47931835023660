import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useDropArea } from "react-use";
import { isArray } from "lodash";
import { Button, Chip, IconButton, Theme, Tooltip, Typography, alpha } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircle, CheckCircleOutline, Forum, UploadFile } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { AssignedDocument } from "types/client-document-types";
import { StatusKeys } from "helpers";
import { formatShortDate } from "helpers/general-helpers";
import ItemCard from "../parts/item-card";
import PetitionerUploadDialog from "../petitioner-uploads/petitioner-upload-dialog";
const documentImage = require("./document.jpg");

export interface IRequestCardProps {
  document: AssignedDocument;
  theme: Theme;
}

const RequestCard = ({ document, theme, }: IRequestCardProps) => {
  if(!!document.filePath) return <FulfilledRequest document={document} theme={theme} />;
  return <PendingRequest document={document} theme={theme} />;
};


export default RequestCard;

const PendingRequest = ({ document, theme, }: IRequestCardProps) => {
  const status = useStatus(StatusKeys.clientDocuments);
  const itemUrl = `/app/${document.shareId}/documents/${document.id}`;
  // const isApproved = !!document.submittedAt;
  const [file, setFile] = useState<File | null>(null);
  const [isUploading, setUploading] = useState(false);
  const isDirty = (document as any).isDirty ?? false;

  const onDropped = (fileType: "files" | "uri" | "text") => (data: any) => {
    if(fileType !== "files") return console.log('dropped', fileType, data);
    if(isArray(data)) data = data[0];

    setFile(data);
    setUploading(true);
    // console.log('dropped', fileType, data);
  }

  const onUploaded = (uploaded: boolean) => {
    if(uploaded) toast.success("File uploaded successfully");
    setFile(null);
    setUploading(false);
  };
  const [bond, state] = useDropArea({
    onFiles: onDropped("files"),
    onUri: uri => onDropped("uri"),
    onText: text => onDropped("text"),
  });

  return (
    <ItemCard title={document.name} headerImage={documentImage}>
      
      {isDirty && (
        <Tooltip title="You have unread comments about this upload request">
          <IconButton component={Link} to={itemUrl} sx={{ position: "absolute", top: 5, right: 5, bgcolor: theme.palette.secondary.light, color: "white" }} size="small"><Forum fontSize="small" /></IconButton>
        </Tooltip>
      )}

      <Grid id="contentGrid" container direction="column" {...bond } sx={{ flexGrow: 1, height: "100%" }}>          
        <Tooltip title="This is a document that your attorney has asked you to upload">
          <Typography mt={1} align="center" width="100%" fontWeight="800" fontSize="1rem" textTransform="uppercase">Document Request</Typography>
        </Tooltip>
        {document.description && <Typography fontSize="1rem" textAlign="center" px={1} sx={{ pointerEvents: "none" }}>{document.description}</Typography>}
        
        <Grid id="dropGrid" container direction="column" width="100%" justifyContent="center" alignItems="center" sx={{ flexGrow: 1, borderWidth: "1px 0", pointerEvents: "none" }}>
          <Grid container direction="column" alignItems="center" p={2} sx={{ bgcolor: state.over ? alpha(theme.palette.primary.main, 0.25) : "unset", border: `1px dashed ${theme.palette.grey[300]}`, borderRadius: 1 }}>
            <UploadFile fontSize="medium" sx={{ color: state.over ? theme.palette.primary.main : theme.palette.grey[500] }}/>
            {!state.over && (<Typography variant="body2" color="grey.500" textAlign="center">Drop file here</Typography>)}
            {state.over && (<Typography variant="body2" color="primary" textAlign="center">Drop when ready</Typography>)}            
          </Grid>
        </Grid>
        
        <Grid id="buttonGrid" direction="column" container px={2} py={1} rowGap={1} alignItems="center">                   
          <Button onClick={() => setUploading(true)} disabled={status.isWorking} color="secondary" size="small" fullWidth>Upload Document</Button>
        </Grid>

        {!!isUploading && <PetitionerUploadDialog open={true} onClose={onUploaded} file={file} request={document}/> }
      </Grid>

    </ItemCard>
  );

};

const FulfilledRequest = ({ document, theme, }: IRequestCardProps) => {
  const itemUrl = `/app/${document.shareId}/documents/${document.id}`;
  const isDirty = (document as any).isDirty ?? false;

  return (
    <ItemCard title={document.name} headerImage={documentImage} url={itemUrl} clickable>
      
      {isDirty && (
        <Tooltip title="You have unread comments about this upload request">
          <IconButton component={Link} to={itemUrl} sx={{ position: "absolute", top: 5, right: 5, bgcolor: theme.palette.secondary.light, color: "white" }} size="small"><Forum fontSize="small" /></IconButton>
        </Tooltip>
      )}

      <Grid id="contentGrid" container direction="column" sx={{ flexGrow: 1, height: "100%" }}>          
        <Tooltip title="This is a document that your attorney has asked you to upload">
          <Typography mt={1} align="center" width="100%" fontWeight="800" color={alpha(theme.palette.secondary.main, 0.75)} fontSize="1rem" textTransform="uppercase">Document Request</Typography>
        </Tooltip>
        
        <Grid container direction="column" width="100%" justifyContent="center" alignItems="center" py={2} sx={{ flexGrow: 1, borderWidth: "1px 0" }}>
          <Tooltip title={`You uploaded this document on ${formatShortDate(document.createdAt)}`}>
            <Chip icon={<CheckCircle fontSize="small" />} label="Uploaded" color="primary" />
          </Tooltip>
        </Grid>

        <Grid direction="column" container px={2} py={1} rowGap={1} alignItems="center">                   
          <Button component={Link} to={itemUrl} color="secondary" size="small" fullWidth>View Document</Button>
        </Grid>

      </Grid>

    </ItemCard>
  );
}