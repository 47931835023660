import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useCloudDrive } from "../cloud-storage-context/onedrive-context";
import { CloudProviders } from "helpers/cloud-storage-helpers";
import { useCloudStorage } from "../cloud-storage-context/cloud-storage-context";

const OneDriveLogin = () => {
  const { updateSetting } = useCloudStorage();
  const { login, status } = useCloudDrive();

  const handleLogin = async () => {
    const isSuccess = await login();
    if(isSuccess) await updateSetting({ provider: CloudProviders.OneDrive });
  }

  return (
    <Grid container sm={12} justifyContent="center" alignItems="center">
      <Button onClick={handleLogin} disabled={status === "login"} variant="outlined" color="primary">Connect</Button>
    </Grid>
  );
}

export default OneDriveLogin; 
