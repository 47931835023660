import { Button, TableCell, TableRow } from "@mui/material";
import { headerFieldSx } from "./data-table-list-utils";

const DataTableListEmptyRow = ({isEditing, isEmpty, colCount, onRowAdded}: { isEditing: boolean, isEmpty: boolean, colCount: number, onRowAdded: () => void}) => {
  if(!isEmpty && !isEditing) return null;
  
  if(!isEditing) {
    return (
      <TableRow>
        <TableCell colSpan={colCount} sx={{...headerFieldSx(""), borderBottom: "none" }}> - none - </TableCell>
      </TableRow>
    );
  }
  else {
    return (
      <TableRow>
        <TableCell colSpan={colCount} sx={headerFieldSx("", { borderBottom: "none" })}>
          <Button onClick={onRowAdded} variant="text" color="secondary" size="small" sx={{ fontSize: "0.9rem" }}>Add Row</Button>
        </TableCell>
      </TableRow>
    );
  }
};

export default DataTableListEmptyRow;