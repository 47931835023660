import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import StorageIcon from '@mui/icons-material/Storage';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DownloadIcon from '@mui/icons-material/GetAppOutlined';
import UploadIcon from '@mui/icons-material/PublishOutlined';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PinDropIcon from '@mui/icons-material/PinDropOutlined';
import LaunchIcon from '@mui/icons-material/LaunchOutlined';
import { downloadRawData } from 'store/actions/value-actions';
import { selectLocation } from 'store/selectors/app-selectors';
import { trackEvent, Events } from 'helpers/analytics';
import { NavButton } from 'components';
import ClearDataDialog from './parts/clear-data-dialog';
import LoadDataDialog from './parts/load-data-dialog';
import { ShareProvider } from '../app/share-context';

const ManageData = () => {
  const [props, setProps]   = useState({isMoveOpen: false, isClearOpen: false, isLoadOpen: false});
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const location  = useSelector(state => selectLocation(state));

  const onDownload  = () => {
    trackEvent(Events.download);
    dispatch(downloadRawData());
  }

  const toggleProp = (key) => {
    setProps({...props, [key]: !props[key]});
  }

  return (
    <Grid id="data-view" container>
      <Grid item container>
        <Grid item sm={9} container alignContent="flex-start" direction="column">
          <Grid item><Typography className={classes.title}>Your FormHop Data</Typography></Grid>
          <Grid item><Typography className={classes.subTitle}>You have complete control of your data with the options below</Typography></Grid>
        </Grid>
        <Grid item sm={3} container alignItems="center" justifyContent="center">
          <div className={classes.avatarWrapper}>
            <div className={classes.avatarWrapperInner}>
              <StorageIcon className={classes.avatar} color="primary"/>
            </div>
          </div>
        </Grid>      
      </Grid>

      {/* <Grid item xs={12}>
        <Typography variant="h5" color="secondary" className={classes.title}>Your FormHop Data</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.info}>Take control of your data with the options below</Typography>
      </Grid> */}

      <Grid item xs={12} className={classes.mainContent}>

        <Card variant="outlined" className={classes.settingCard}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="column">
              
              <Grid item xs={12} container className={classes.commandGrid}  alignItems="center">
                <Grid item xs={8} container direction="column">
                  <Typography className={classes.commandText}>View my data</Typography>
                  <Typography className={classes.commandSubText}>View all the data you've entered for your Sworn Financials</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <NavButton disabled to="/my/data/view" color="secondary" size="small" className={classes.commandButton} endIcon={<ArrowRightIcon/>}>View</NavButton>
                </Grid>
              </Grid>
              
              <Grid item xs={12} container className={classes.commandGrid} alignItems="center">
                <Grid item xs={8} container direction="column">
                  <Typography className={classes.commandText}>Download my data</Typography>
                  <Typography className={classes.commandSubText}>Download your data to a file that you can store offline</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <Button color="secondary" size="small" className={classes.commandButton} endIcon={<DownloadIcon />} onClick={onDownload}>Download</Button>
                </Grid>
              </Grid>

              {/* <Grid item xs={12} container className={classes.commandGrid} alignItems="center">
                <Grid item xs={8} container direction="column">
                  <Typography className={classes.commandText}>Delete my data</Typography>
                  <Typography className={classes.commandSubText}>Permenantly delete all your FormHop data</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <Button color="secondary" size="small" className={classes.commandButton} endIcon={<DeleteForeverIcon />} onClick={() => toggleProp("isClearOpen")}>Delete</Button>
                </Grid>
                <ClearDataDialog isOpen={props.isClearOpen} onClose={() => toggleProp("isClearOpen")}/>
              </Grid> */}

              <Grid item xs={12} container className={classes.commandGrid} alignItems="center">
                <Grid item xs={8} container direction="column">
                  <Typography className={classes.commandText}>Load data from a file</Typography>
                  <Typography className={classes.commandSubText}>Load your data from a file that you previously downloaded from FormHop</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <Button color="secondary" size="small" className={classes.commandButton} endIcon={<UploadIcon />} onClick={() => toggleProp("isLoadOpen")}>Load</Button>
                </Grid>
                <LoadDataDialog isOpen={props.isLoadOpen} onClose={() => toggleProp("isLoadOpen")}/>
              </Grid>

              {/* <Grid item xs={12} container className={classes.commandGrid} alignItems="center">
                <Grid item xs={8} container direction="column">
                  <Typography className={classes.commandText}>Change my Location</Typography>
                  <Typography className={classes.commandSubText}>Your current location is set to <strong>{location?.name}</strong>.  This feature is not current supported.</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <Button disabled color="secondary" size="small" className={classes.commandButton} endIcon={<PinDropIcon/>}>Change</Button>
                </Grid>
              </Grid> */}

            </Grid>
          </CardContent>
        </Card>

        <Card variant="outlined" className={classes.settingCard}>
          <CardContent className={classes.cardContent}>
            <Grid container direction="column">
              <Grid item xs={12} container className={classes.commandGrid} alignItems="center">
                <Grid item xs={8}>
                  <Typography className={classes.commandText}>FormHop Privacy Policy</Typography>
                  <Typography className={classes.commandSubText}>We take privacy and data security seriously.  Read our privacy policy to learn more.</Typography>
                </Grid>
                <Grid item xs={4} container justifyContent="flex-end">
                  <NavButton to="/legal/terms-of-service#privacy" newTab color="secondary" size="small" className={classes.commandButton} endIcon={<LaunchIcon/>}>Read</NavButton>
                  {/* <Link to="/legal/terms-of-service#privacy" component={Button} target="_blank" color="secondary" size="small" className={classes.commandButton} endIcon={<LaunchIcon/>}>Read</Link> */}
                </Grid>
              </Grid>

            </Grid>
          </CardContent>
        </Card>
      </Grid>

    </Grid>
  );
};

export default ManageData;

const buildStyles   = makeStyles(theme => ({
  mainContent  : {
    padding     : theme.spacing(1, 0),
    width       : "90%",
  },
  avatarWrapper      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "90px",
    width         : "90px",
    lineHeight    : "90px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
    marginBottom  : theme.spacing(2),
    padding: theme.spacing(1),
  },
  avatarWrapperInner: {
    backgroundColor: theme.palette.primary.main,
    borderRadius  : "50%",
    height        : "74px",
    width         : "74px",
    lineHeight    : "74px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
  },
  avatar: {
    color: "#f4f4f4",
    height: 48,
    width: 48,
  },
  title   : {
    fontSize    : 25,
    fontWeight  : 500,
    // textAlign   : "center",
    marginBottom: theme.spacing(1),
    color       : theme.palette.primary.main,
  },
  subTitle  : {
    fontSize      : 18,
    fontWeight    : 400,
    // textAlign     : "center",
    marginBottom  : theme.spacing(1),
    color         : theme.palette.grey[600],
  },
  info  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    marginBottom  : theme.spacing(2),
  },
  settingCard   : {
    marginBottom  : theme.spacing(2),
  },
  cardContent   : {
    padding   : 0,
    "&:last-child": {
      paddingBottom   : theme.spacing(1),
    },
  },
  commandGrid   : {
    padding       : theme.spacing(1, 2),
    "&:not(:last-child)": {
      borderBottom  : `1px solid ${theme.palette.grey[200]}`,
    },
    "&:last-child": {
      paddingBottom   : 0,
    },
  },
  commandText   : {
    fontSize      : 18,
    fontWeight    : 400,
  },
  commandSubText  : {
    fontSize      : 15,
    fontWeight    : 300,
    color         : theme.palette.grey[500],
    "& strong"  : {
      color       : theme.palette.primary.main,
      fontWeight  : 700,
    }
  },
  commandButton : {
    fontSize      : 14,
    fontWeight    : 500,
  },
}));