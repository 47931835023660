// import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import ArrowRightIcon from '@mui/icons-material/ArrowForwardIos';
import SaveIcon from '@mui/icons-material/Save';
import LaunchIcon from '@mui/icons-material/Launch';
// import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ConfirmDialog, IfBlock, NavButton } from 'components';
import { formatDateString } from 'helpers/general-helpers';
import { useInput } from 'helpers/hooks';
import { updateProfile } from 'store/actions/app-actions';
import { selectCustomer } from 'store/selectors/app-selectors';
import { useMemo, useState } from 'react';
import ChangePasswordDialog from './change-password-dialog';

const ProfileCard = () => {
  const classes   = buildStyles();
  const profile   = useSelector(state => state.app.profile);
  const user      = useSelector(state => state.app.user);
  const customer = useSelector(state => selectCustomer(state));
  const isPasswordProvider = useMemo(() => user.providerData[0].providerId === "password", [user]);
  const [isPasswordOpen, setPasswordOpen] = useState(false);

  const dispatch  = useDispatch();

  const [name, onChangeName, isEditingName, toggleEditName, cancelEditName, commitName]   = useInput(profile.displayName);

  async function saveProfile(){
    const updates   = { displayName : name };
    const result  = await dispatch(updateProfile(updates));
    if(result.isOk){
      commitName();      
    }
    //TODO: Deal with an error updating the profile
  }

  function handleChangePassword(){
    setPasswordOpen(true);
  }

  const isReviewer  = Boolean(profile.role === "reviewer");
  const accountType = isReviewer ? "Small Practice" : "Client Account";
  const userType = isReviewer ? "Attorney" : "Client";

  console.log("Profile Customer", customer);

  return (
    <Card className={classes.cardRoot} elevation={0} variant="outlined">
      <CardContent className={classes.cardContent}>
        {/* <Typography className={classes.cardHeader}>Profile info</Typography>                   */}
        <table className={classes.profileTable}>
          <tbody>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Name</td>
              <td>
                <IfBlock condition={isEditingName}>
                  <TextField value={name} onChange={onChangeName} variant="outlined" fullWidth size="small" className={classes.editField} inputProps={{className: classes.editInput}}/>
                </IfBlock>
                {!isEditingName && profile.displayName}
              </td>
              <td className={classes.actionsCol}>
                <IfBlock condition={isEditingName}>
                  <Button size="small" color="primary" startIcon={<SaveIcon/>} onClick={saveProfile}>Save</Button>
                  <Button size="small" onClick={cancelEditName}>Cancel</Button>
                </IfBlock>
                {!isEditingName && <Button size="small" endIcon={<ArrowRightIcon/>} onClick={toggleEditName}>Change</Button>}
              </td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Email</td>
              <td>{profile.email}</td>
              <td className={classes.actionsCol}>
                {/* <Button size="small" disabled endIcon={<ArrowRightIcon/>}>Change</Button> */}
              </td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Account type</td>
              {/* <td>{customer?.isLicensed ? customer.licenses[0].description : "Free User"}</td> */}
              <td>{accountType}</td>
              <td className={classes.actionsCol}>
                {/* <NavButton to="/my/subscription" size="small" endIcon={<ArrowRightIcon/>}>{customer?.isLicensed ? "Subscription" : "Purchase"}</NavButton> */}
              </td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>User type</td>
              <td>{userType}</td>
              <td className={classes.actionsCol}>
              {/* {!isReviewer && <Button size="small" disabled endIcon={<ArrowRightIcon/>}>Are you a lawyer?</Button>}
              {isReviewer && <Button size="small" disabled endIcon={<ArrowRightIcon/>}>Not a lawyer?</Button>} */}
              </td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Last sign in</td>
              <td>{formatDateString(user.metadata.lastSignInTime)}</td>
              <td className={classes.actionsCol}></td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Account created</td>
              <td>{formatDateString(profile.createdOn)}</td>              
              <td className={classes.actionsCol}></td>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Username</td>
              <td>{profile.id}</td>
              <td className={classes.actionsCol}></td>
            </tr>
              <tr className={classes.profileRow}>
                <td className={classes.labelCol}>Password</td>
                <IfBlock condition={profile.provider === "password"}>
                  <td>***********</td>
                  <td className={classes.actionsCol}>
                    <Button onClick={handleChangePassword} size="small" endIcon={<ArrowRightIcon/>}>Change</Button>
                  </td>
                </IfBlock>      
                <IfBlock condition={profile.provider !== "password"}>
                  <td colSpan={2} className={classes.italic}>Managed by {profile.provider}</td>
                </IfBlock>
            </tr>
            <tr className={classes.profileRow}>
              <td className={classes.labelCol}>Terms of Service accepted</td>
              <td>{formatDateString(profile.tosAcceptedOn)}</td>
              <td className={classes.actionsCol}>
                <NavButton to="/legal/terms-of-service" newTab size="small" className={classes.actionButton} endIcon={<LaunchIcon/>}>Read</NavButton>
              </td>
            </tr>
          </tbody>                    
        </table>

        {/* Dialog for changing user password */}
        {(isPasswordOpen && isPasswordProvider) && <ChangePasswordDialog open={isPasswordOpen} onClose={() => setPasswordOpen(false)}/>}
        {(isPasswordOpen && !isPasswordProvider) && <ConfirmDialog open={isPasswordOpen} onConfirm={() => setPasswordOpen(false)} onClose={() => setPasswordOpen(false)} confirmButtonLabel="OK" title="Cannot change password" message="You are logged in with a 3rd party (e.g. google or facebook). To change your password, please visit the appropriate provider."/>}

      </CardContent>
    </Card>
  );
}

export default ProfileCard;

const buildStyles   = makeStyles(theme => ({
  cardRoot  : {
    width         : "100%",
    marginRight   : theme.spacing(3),
  },
  cardHeader   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
  },
  profileTable  : {
    // marginTop       : theme.spacing(2),
    fontSize        : 16, 
    fontWeight      : 500,   
    width           : "100%",
  },
  profileRow    : {
    "&:not(:last-child)": {
      "& td"  : {
        borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
      },
    }
  },
  labelCol    : {
    fontSize        : 12,
    fontWeight      : 600,
    padding         : `${theme.spacing(2.5)} ${theme.spacing(0)}`, //`
    textTransform   : "uppercase",
    color           : theme.palette.grey[500],
    width           : 250,
  },
  actionsCol  : {
    textAlign   : "right",
  },
  actionButton  : {

  },
  editField   : {
    background    : `${theme.palette.primary.light}33`, //`
  },
  editInput   : {
    fontSize      : 16,
  },
  italic  : {
    fontStyle   : "italic",
    color       : theme.palette.grey[500],
  }
}));