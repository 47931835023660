import { useCallback } from 'react';
import { Grid, Stack, Box, Divider, ToggleButtonGroup, ToggleButton, Tooltip } from '@mui/material';
import { Star, StarOutline, CheckCircleOutline, RadioButtonChecked, RadioButtonUnchecked, AccountCircleOutlined, ForumOutlined, ArchiveOutlined } from '@mui/icons-material';
import { SearchField } from 'components';

export type ClientListFilter = {
  starred: boolean;
  unstarred: boolean;
  active: boolean;
  invited: boolean;
  notinvited: boolean;
  mine: boolean;
  unseen: boolean;
  archived: boolean; 
}

interface ClientListToolbarProps {
  filter: ClientListFilter;
  setFilter: (filter: ClientListFilter) => void;
  onSearchChange: (value: string) => void;
  allowComments?: boolean;
}

const ClientListToolbar = ({ filter, setFilter, onSearchChange, allowComments }: ClientListToolbarProps) => {
  
  const handleFilter = useCallback((field: keyof ClientListFilter, value?: boolean) => () => {
    setFilter({...filter, [field]: value ?? !filter[field]});
  }, [filter, setFilter]);

  return (

    <Grid id="filter-row" container sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid" }}>
      <Stack direction="row" spacing={2} alignItems="center">
        
        <SearchField placeholder="search" onChange={(val) => onSearchChange(val)} />

        <Box py={1} height="100%">
          <Divider orientation="vertical" />
        </Box>

        <ToggleButtonGroup>
          <Tooltip title="Show starred clients">
            <ToggleButton value="starred" size="small" color="primary" selected={filter.starred} onChange={handleFilter("starred")} sx={{ py: 0, height: "2.3rem" }}>
              <Star fontSize="small" />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Show unstarred clients">
            <ToggleButton value="unstarred" size="small" color="primary" selected={filter.unstarred} onChange={handleFilter("unstarred")} sx={{ py: 0, height: "2.3rem" }}>
              <StarOutline fontSize="small" />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Show active clients">
            <ToggleButton value="account" size="small" color="primary" selected={filter.active} onChange={handleFilter("active")} sx={{ py: 0, height: "2.3rem" }}>
              <CheckCircleOutline fontSize="small" />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Show invited clients">
            <ToggleButton value="account" size="small" color="primary" selected={filter.invited} onChange={handleFilter("invited")} sx={{ py: 0, height: "2.3rem" }}>
              <RadioButtonChecked fontSize="small" />
            </ToggleButton>
          </Tooltip>

          <Tooltip title="Show not invited clients">
            <ToggleButton value="account" size="small" color="primary" selected={filter.notinvited} onChange={handleFilter("notinvited")} sx={{ py: 0, height: "2.3rem" }}>
              <RadioButtonUnchecked fontSize="small" />
            </ToggleButton>
          </Tooltip>

          {/* <Tooltip title="Show / Hide archived clients">
            <ToggleButton value="account" size="small" color="primary" selected={filter.archived} onChange={handleFilter("archived")} sx={{ py: 0, height: "2.3rem" }}>
              <ArchiveOutlined fontSize="small" />
            </ToggleButton>
          </Tooltip> */}
          
        </ToggleButtonGroup>

        <ToggleButtonGroup>
          <Tooltip title="Only assigned to me">
            <ToggleButton value="assigned" size="small" color="primary" selected={filter.mine} onChange={handleFilter("mine")} sx={{ py: 0, height: "2.3rem" }}>
              <AccountCircleOutlined fontSize="small" />
            </ToggleButton>
          </Tooltip>
          {allowComments && (
            <Tooltip title="Show only clients with unseen messages">
              <ToggleButton value="account" size="small" color="primary" selected={filter.unseen} onChange={handleFilter("unseen")} sx={{ py: 0, height: "2.3rem" }}>
                <ForumOutlined fontSize="small" />
              </ToggleButton>
            </Tooltip>
          )}
        </ToggleButtonGroup>

        <ToggleButtonGroup color="secondary">
          <Tooltip title="Show active clients">
            <ToggleButton value="active" size="small" color="secondary" selected={!filter.archived} onChange={handleFilter("archived", false)} sx={{ py: 0, height: "2.3rem" }}>
              Active
            </ToggleButton>
          </Tooltip>
          <Tooltip title="Show Archived Clients">
            <ToggleButton value="archived" size="small" color="secondary" selected={filter.archived} onChange={handleFilter("archived")} sx={{ py: 0, height: "2.3rem" }}>
              Archived
            </ToggleButton>
          </Tooltip>
        </ToggleButtonGroup>
        
      </Stack>
    </Grid>
  );
};

export default ClientListToolbar;