import React from 'react';
import _ from 'lodash';
import { InputField } from './';
import { Typography } from '@mui/material';

const REGEXP = /{\w+}/g;

export const QuestionRow = ({field, data, colProps}) => {
  return (
    <tr className="question-row">
      <td {...colProps}>
        <Question field={field} data={data}/>
      </td>
    </tr>
  );
}

export const Question = ({field, data}) => {
    const {id, params}    = field;
    let pIndex          = 0;
    const label = field.label ?? field.header ?? "";
    const matches       = label.match(REGEXP);
    
    const parts         = _.map(label.split(REGEXP), (part, index) => {
      let node        = null;
      if(pIndex < params.length){
        const match = matches[pIndex++];
        const mId   = match.replace("{", "").replace("}","");
        const p     = _.find(params, {id: mId});
        node        = <InputField key={p.id} field={p} data={data} showLabel={false}/>;
      }

      return (
        <React.Fragment key={`${id}-label-${index}`}>
          <Typography color="grey.700" fontSize="1rem">{part}</Typography>
          {node}
        </React.Fragment>
      );        
    });

    return (
      <React.Fragment>
        {parts}
      </React.Fragment>
    )
    
}