import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { AssignmentTurnedIn, AttachFile, CalendarMonth, ExpandLess, ExpandMore, Group as GroupIcon, Inbox as InboxIcon } from "@mui/icons-material";
import { TopicGroupItem, TopicItem } from "types";
import { TopicListItem } from "components/conversations";
import { selectDirtyByTopic } from "store/selectors/conversation-selectors";

export interface IConversationGroupItemProps {
  topicGroup: TopicGroupItem;
  selectedId?: string;
}

const TopicGroupListItem = ({ topicGroup }: IConversationGroupItemProps) => {
  const navigate = useNavigate();
  const { shareId, topicId } = useParams();
  const [open, setOpen] = useState(true);
  const dirty = useSelector<any, any>(state => (selectDirtyByTopic as any)(state, topicGroup.id));

  const topics = useMemo<TopicItem[]>(() => {
    const items: TopicItem[] = topicGroup.topics?.map(t => ({ topicType: t.topicType, topicId: t.topicId, topicName: t.topicName })) ?? [];
    return items;
  }, [topicGroup]);

  const isConversationSelected = useMemo(() => shareId === topicGroup.id, [shareId, topicGroup.id]);

  // const handleExpandClick = (uid: string) => () => {
  //   setOpen(!open);
  // };

  const handleConversationClick = (selectedTopic: string, selectedType?: string) => () => {
    let url: string = "";
    if(isConversationSelected && selectedTopic === topicId) return; //already selected
    
    const slash = topicGroup.baseUrl.endsWith("/") ? "" : "/";
    if(selectedType) url = `${topicGroup.baseUrl}${slash}${topicGroup.id}/${selectedType}/${selectedTopic}`;
    else url = `${topicGroup.baseUrl}${slash}${topicGroup.id}/g/${selectedTopic}`;

    navigate(url, { replace: true });
  };

  return (
    <>
      <ListItemButton sx={{ pl: 1 }}>
        <ListItemIcon>
          <GroupIcon />
        </ListItemIcon>
        <ListItemText primary={topicGroup.label} primaryTypographyProps={{ fontSize: "1.2rem", fontWeight: "500", flexWrap: "nowrap" }} />
        {/* {open ? <ExpandLess /> :  <ExpandMore /> } */}
      </ListItemButton>
        
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {/* General Conversation Item */}
          <TopicListItem label="General" icon={<InboxIcon fontSize="small" color="inherit" />} isSelected={topicId === "_general_"} isDirty={dirty["_general_"]} onClick={handleConversationClick("_general_", "g")} sx={{ pl: 3 }} />
          <TopicListItem label="Schedule" icon={<CalendarMonth fontSize="small" color="inherit" />} isSelected={topicId === "_schedule_"} isDirty={dirty["_schedule_"]} onClick={handleConversationClick("_schedule_", "g")} sx={{ pl: 3 }} />
        
          <Divider sx={{ ml: 4 }}/>
        
          {/* Form & Document Conversations */}
          {
            topics.map(topic => (
              <TopicListItem 
                key={topic.topicId} 
                label={topic.topicName} 
                icon={topic.topicType === "f" ? <AssignmentTurnedIn fontSize="small" color="inherit" /> : <AttachFile fontSize="small" color="inherit" />} 
                isSelected={topicId === topic.topicId} 
                isDirty={dirty[topic.topicId]} 
                onClick={handleConversationClick(topic.topicId, topic.topicType)} 
                sx={{ pl: 3 }}
              />
            ))
          }
        </List>
      </Collapse>
    </>
  );
};

export default TopicGroupListItem;