import { SyntheticEvent, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom"
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Alert, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { PersonOutlined as ProfileIcon, ContentCopyOutlined as FormsIcon, Notes as NotesIcon, QuestionAnswerOutlined as ConversationsIcon, History as HistoryIcon, CloudUploadOutlined } from "@mui/icons-material";
import { ErrorStatus } from "components";
import { ClientProvider, useClient } from "./client-context";
import { useDispatch, useSelector } from "react-redux";
import { loadClientValues } from "store/actions/attorney-actions";
import { StatusKeys } from "helpers/status-keys";
import { useShareContext } from "../app/share-context";
import ClientSummaryPanel from "./parts/client-summary-panel";
import { ShareStatus } from "types";
import ClientProfileCard from "./parts/client-profile/client-card-profile";
import ClientProfileCardEdit from "./parts/client-profile/client-card-profile-edit";
import ClientDocumentsCard from "./parts/client-documents-card";
import ClientDatesCard from "./parts/client-dates-card";
import ClientNotesCard from "./parts/client-notes-card";
import ClientConversationsCard from "./parts/client-conversations-card/client-card-conversations";
import ClientUploadsCard from "./parts/client-uploads-card/client-card-uploads";
import { useAppContext } from "../app/app-context";

const ClientView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { clientId, tab } = useParams();
  const { allowComments } = useAppContext();
  const { share, shareStatus } = useShareContext();
  const { isNew, isEdit, inviteClient } = useClient();
  const clientData = useSelector<any>(state => state.attorney.clientData);
  const tabIndex = tabNames.indexOf(tab?.toUpperCase() ?? "PROFILE");

  useEffect(() => {
    async function initClient(){
      await dispatch(loadClientValues(share!.sharer));
    }
    if(share && !clientData) initClient();
  }, [clientData, share, dispatch]);

  const onTabChange = (event: SyntheticEvent, newIndex: number) => {
    if(newIndex === 0) return navigate(`/app/clients/${clientId}`);
    const newTab = tabNames[newIndex].toLowerCase();    
    return navigate(`/app/clients/${clientId}/${newTab}`, { replace: true });
  };

  return (
    <Grid id="client-dashboard" container sx={{ m: 1, width: "100%" }}>
      <Grid xs={12}>
        <ErrorStatus statusKey={StatusKeys.client} sx={{ mb: 2, width: "100%" }}/>      
        <ErrorStatus statusKey={StatusKeys.shares} sx={{ mb: 2, width: "100%" }}/>      
      </Grid>

      {/* Notification about inviting client */}
      <Grid smOffset={4} mdOffset={3} lgOffset={2} sm={8} md={9} lg={10}>
        {shareStatus === ShareStatus.NOT_INVITED && (
          <Alert severity="info" action={<Button color="inherit" size="small" onClick={inviteClient}>Invite</Button>}>
            <Typography variant="body2">Client has not been invited yet. Click the button to send an invite.</Typography>
          </Alert>
        )}
      </Grid>
      
      {/* Summary panel on the left */}
      <Grid sm={4} md={3} lg={2}>
        <ClientSummaryPanel />
      </Grid>

      {/* Main content area */}
      <Grid sm={8} md={9} lg={10}>
        <Box sx={{ width: "100%" }}>
          <Tabs value={tabIndex ?? 0} onChange={onTabChange} aria-label="Client Tabs" role="navigation" variant="scrollable" scrollButtons="auto">
            <Tab label="Contact" icon={<ProfileIcon fontSize="small" />} iconPosition="start" {...a11yProps(0)} sx={tabSx}/>
            <Tab label="Documents" icon={<FormsIcon fontSize="small" />} iconPosition="start" {...a11yProps(1)} sx={tabSx} hidden={isEdit}/>
            <Tab label="Client Uploads" icon={<CloudUploadOutlined fontSize="small" />} iconPosition="start" {...a11yProps(2)} sx={tabSx} hidden={isEdit} />
            <Tab label="Notes" icon={<NotesIcon fontSize="small" />} iconPosition="start" {...a11yProps(3)} sx={tabSx} hidden={isEdit}/>
            { allowComments && <Tab label="Conversations" icon={<ConversationsIcon fontSize="small" />} iconPosition="start" {...a11yProps(4)} sx={tabSx} hidden={isEdit}/> }
            {/* <Tab label="Timeline" icon={<HistoryIcon fontSize="small" />} iconPosition="start" {...a11yProps(5)} sx={tabSx} hidden={isEdit}/> */}
          </Tabs>
        </Box>
        
        {tabIndex === 0 && ((isEdit) ? <ClientProfileCardEdit /> : <ClientProfileCard />)}
        {tabIndex === 1 && <ClientDocumentsCard /> }
        {tabIndex === 2 && <ClientUploadsCard /> }
        {tabIndex === 3 && <ClientNotesCard />}
        {tabIndex === 4 && allowComments && <ClientConversationsCard />}
        {/* {tabIndex === 4 && !allowComments && <ClientDatesCard />} */}
        {/* {tabIndex === 5 && <ClientDatesCard />} */}

        </Grid>
    </Grid>
  );
};

export default ClientView;

export const ClientViewWrapper = () => {
  return (
    <ClientProvider>
      <ClientView />
    </ClientProvider>
  );
};

const tabNames = ["PROFILE", "DOCUMENTS", "UPLOADS", "NOTES", "CONVERSATIONS", "TIMELINE"];

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const tabSx = { py: 0, fontSize: "1rem", minHeight: 56 };