import { Grid } from "@mui/material";
import PetitionerDocumentView from "./petitioner-document-view";
import CustomerNavBar from "components/navigation";

//This view is for the Customer's perspective
const DocumentContainer = () => {

  return (
    <Grid id="document-container" container>
      <CustomerNavBar />
      <PetitionerDocumentView />
    </Grid>
  );
};

export default DocumentContainer;