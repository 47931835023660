import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


function WelcomePanel({header, subheader, bgImage, attribution, leftColSize, children}){
  const classes   = useStyles({bgImage: bgImage});
  const nameUrl   = `https://unsplash.com/${attribution.handle}`;
  const leftCol   = leftColSize ? parseInt(leftColSize) : 6;
  const rightCol  = 12 - leftCol;

  return (
    <Grid container className={classes.root}>
      <Grid item sm={leftCol} className={classes.leftCol}>
        <div className={classes.overlay}>
          <span className={classes.attribution}>Photo by <a href={nameUrl} target="blank">{attribution.name}</a> on <a href="https://unsplash.com/" target="blank">Unsplash</a></span>
        </div>
      </Grid>
      <Grid item sm={rightCol} xs={12} className={classes.rightCol}>
        <Grid container className={classes.rightContent}>
          <Grid container className={classes.headerBar}>
            <Typography variant="h2" className={classes.header}>{header}</Typography>
          </Grid>
          {subheader && <Typography variant="h4" className={classes.subHeader}>{subheader}</Typography>}
            
          <Grid container className={classes.content}>
            {children}
          </Grid>

        </Grid>
      </Grid>  
    </Grid> 
  )
}


const useStyles  = makeStyles(theme => ({
  root        : {
    height          : "100%",
  },
  leftCol   : props => ({
    // height              : "100vh",
    backgroundImage     : `url(${props.bgImage})`,
    backgroundSize      : "cover",
    backgroundPosition  : "50%",   
    position            : "relative", 
    borderRadius        : "4px 0 0 4px",
    padding             : "1px 0 1px 1px",
  }),
  rightCol  : {
    borderRadius        : "0 4px 4px 0",
    // padding             : theme.spacing(2),
  },
  attribution : {
    fontSize        : 14,
    fontWeight      : 400,
    position        : "absolute",
    right           : "10px",
    bottom          : "10px",
    color           : `${theme.palette.grey[900]}33`,
    "& a"           : {
        color       : `${theme.palette.grey[900]}33`,
    }
  },
  rightContent : {
    height          : "100%",
    position        : "relative",
    alignContent    : "flex-start",
  },
  headerBar   : {
    // height      : "45px",
    borderTop   : `10px solid ${theme.palette.primary.dark}`,
    background  : theme.palette.primary.main,
    padding     : 15,
    borderRadius: "0 4px 0 0",
  },
  header  : {
    fontSize        : 28,
    fontWeight      : 400,
    // marginBottom    : 15,
    color           : theme.palette.primary.contrastText,
    marginBottom    : 0,
  },
  subHeader   : {
    padding       : 15,
    fontSize      : 18,
    fontWeight    : 400,
    // marginBottom  : 10,
    color         : theme.palette.grey[700],
  },
  content   : {
    borderRadius  : 6,
    padding       : "5px 15px",    
  },  
}));


export default WelcomePanel;