import { Grid } from "@mui/material";
import { ErrorAlert } from "components";
import { useStatus } from "redux-action-status";
import FormList from "./form-list";

const AttorneyForms = () => {
  const status = useStatus("forms");
  
  return (
    <Grid id="attorney-forms" container marginX={1} marginY={2}>
      <ErrorAlert error={status.error} title="Failed to load Client List" sx={{ width: "80%" }}/>
      <FormList />
    </Grid>
  );
};

export default AttorneyForms;