import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

export interface IClientDocumentListProps {
  children: React.ReactNode;
  isEmpty?: boolean;
}

const ClientDocumentList = ({ children, isEmpty }: IClientDocumentListProps) => {

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell width={36} sx={{ py: 0 }}></TableCell>
            <TableCell width={36} sx={{ py: 0 }}></TableCell>
            <TableCell>Document</TableCell>
            <TableCell></TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Due</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {children}
          {isEmpty && (
            <TableRow>
              <TableCell colSpan={6} sx={{ py: 2, textAlign: "center" }}>
                <Typography variant="body2" color="secondary">Click the Add or Upload button to get started</Typography>
              </TableCell>
            </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientDocumentList;