import React from 'react';
import { Route, Routes } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
// import LandingPage from './landing-page';
import LearnMorePage from './learn-more-page';
import AboutPage from './about-page';
import ContactPage from './contact-page';
import LegalPage from '../legal/legal-page';
import LawyerLanding from './landing-lawyer';

const LandingContainer = () => {
  console.log("NAV: LandingContainer");
  const classes   = buildStyles();

  return (
    <div id="landing-container" className={classes.root}>
      <Routes>        
        <Route path="/landing/learn-more" element={<LearnMorePage />}/>
        <Route path="/landing/about" element={<AboutPage />}/>
        <Route path="/landing/contact" element={<ContactPage />}/>  
        <Route path="/landing/lawyer" element={<LawyerLanding />}/>  
        <Route path="/*" element={<LegalPage />}/>
        {/* <Route path="/" element={<LandingPage />}/> */}
      </Routes>
    </div>
  );
}

export default LandingContainer;

const buildStyles   = makeStyles(theme => ({
  root  : {
    // padding     : theme.spacing(1),
  },
  
}));