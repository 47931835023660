import _, { isString } from "lodash";
// import cookie from 'react-cookie';
import ErrorHelper from '../../helpers/error-helpers';
import { addQueryParameters } from '../actions/action-helpers';
import { getBearerToken } from "config/firebase-config";

//Get the base url from the window location...
const BASE_URL = `${window.location.origin}/api/`;
const CD_HEADER = "Content-Disposition";
const CT_HEADER = "Content-Type";

const DEFAULT_HEADERS = {
    "Accept"        : "application/json",
    // "X-XSRF-TOKEN"  : cookie.load("XSRF-TOKEN"),
    "Content-Type"  : "application/json"
};


function isJson(response){
    const cType     = response.headers?.get(CT_HEADER);
    return cType?.indexOf("application/json") >= 0;
}

function isText(response){
    const cType     = response.headers?.get(CT_HEADER);
    return cType?.indexOf("text") >= 0;
}

function isAttachment(response){
    const cd    = response.headers?.get(CD_HEADER);
    return cd?.indexOf("attachment") >= 0;
}

//----------------------
// Deals with a JSON response from the server to the fetch request.
//----------------------
const handleJsonResponse = async (response) => {
    if(response.ok){
        try{
            const json  = await response.json();
            return json;
            // if(json.success){
            //     return json;
            // }
            // else{
            //     const result    = ErrorHelper.normalizeAjaxError(json);
            //     throw result;
            // }
        }
        catch(ex){
            console.log(ex);
            throw ErrorHelper.normalizeNetworkError(response, ex.message);
        }
    }
    else{
        const message   = await response.text();
        const err       = JSON.parse(message);
        const result    = ErrorHelper.normalizeNetworkError(response, err);
        throw result;
    }
}

//----------------------
// Deals with an Attachment response from the server to the fetch request.
//----------------------
const handleFileResponse = async (response, fetchInfo) => {
    if(response.ok){
        console.log("File Response was OK");
        let cd    = response.headers.get(CD_HEADER);

        if(cd){
            let fileName = null;
            const cdParts = cd.split(';');
            const fname = _.find(cdParts, cdp => cdp.indexOf("filename=") >= 0);
            if(fname) fileName    = fname.trim().substr("filename=".length);

            if(fileName){ 
                try {
                    var blob = await response.blob()
                    if(fetchInfo.blobOnly) return blob;     //if they just want the blob, return it.

                    //To get the file to download, need to create a virtual link pointed to the
                    // file url and "click" it.
                    var url = window.URL.createObjectURL(blob);
                    var linkElement = document.createElement('a');
                    linkElement.setAttribute('href', url);
                    linkElement.setAttribute("download", fileName);
        
                    var clickEvent = new MouseEvent("click", {
                        "view": window,
                        "bubbles": true,
                        "cancelable": false
                    });
                    linkElement.dispatchEvent(clickEvent);

                    return true;    //indicate it worked

                } catch (ex) {
                    console.log(ex);
                }
            }
        }
    }
}

//----------------------
// Prepares the request object by adding headers, options and body as necessary.
//----------------------
const prepareRequest = async (verb, value, headers, addToken) => {
    const myHeaders = headers ? _.merge({}, DEFAULT_HEADERS, headers) : DEFAULT_HEADERS;
    const method = verb.toUpperCase();
    if(addToken){
        const token = await getBearerToken();
        myHeaders["Authorization"] = `Bearer ${token}`;
    }

    let options = {
        method : method,
        // credentials : 'include',
        headers : myHeaders
    };

    if(value && (method === 'PUT' || method === 'POST')){
        // options.body = JSON.stringify(value);
        //check for FormData, which would potentially include a file
        if (value instanceof FormData) {
            // If value is FormData, set the body directly and remove Content-Type header
            options.body = value;
            delete myHeaders['Content-Type']; // Let the browser set the Content-Type header
        } else {
            // Otherwise, stringify the value
            options.body = JSON.stringify(value);
            myHeaders['Content-Type'] = 'application/json';
        }
    }

    return options;
}

//----------------------
// Prepares the URL for the API - pre-pends the base address and deals
// with pagination and query parameters.
//----------------------
const prepareUrl = (url, pagination, params) => {

    let workingUrl      = addQueryParameters(url, pagination, params);

    //Does it need the base url pre-pended?
    if(workingUrl.indexOf("http") !== 0){
        //Make sure there's not a leading /
        if(workingUrl.indexOf("/") === 0){
            workingUrl = workingUrl.substr(1);
        }
        return BASE_URL + workingUrl;
    }
    else{
        return workingUrl;
    }
};


//----------------------
// The main entrypoint - takes the fetch structure from an Action and
// executes the api request
//----------------------
async function callApi(fetchInfo){ 
    const url       = fetchInfo.url;
    const verb      = fetchInfo.verb || "GET";
    const value     = fetchInfo.data || null;
    const headers = fetchInfo.headers || null;
    const addToken = fetchInfo.token === true;
    // const isAtt = fetchInfo.isAttachment === true;    

    const init      = await prepareRequest(verb, value, headers, addToken);
    const fullUrl   = prepareUrl(url, fetchInfo.pagination, fetchInfo.params);
    const request   = new Request(fullUrl, init);

    let response    = await fetch(request);
    let data = null;

    if(response.ok){
        data = await getResponseBody(response, fetchInfo);
        
        return {
            isOk: true,
            status: response.status,
            data,
        };
    }
    else{
        let error = { statusText: response.statusText, };
        try{
            const errorBody = await getResponseBody(response, fetchInfo);
            // const errorBody = await response.json();
            error.message = isString(errorBody) ? errorBody : errorBody.error ?? errorBody.message ?? "Unknown error";
        }
        catch(ex){
            error.message = "Unknown"; //await response.text();
        }
        return {
            isOk: false,
            status: response.status,
            error,
        };
    
    }

    //Otherwise, not a supported type... just return the raw response
    
    
}

const getResponseBody = async (response, fetchInfo) => {
    let data = null;
    if(isText(response)){
        data = await response.text();
    }
    else if(isAttachment(response) || fetchInfo.isAttachment) { 
        data = await handleFileResponse(response, fetchInfo);
    }
    else if(isJson(response)){
        data = await handleJsonResponse(response);
    }
    
    return data;
}

//----------------------
// Module Export
const apiMiddleware = {
    fetch         : callApi
};

export default apiMiddleware;