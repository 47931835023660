import { Chip } from "@mui/material";
import { useMemo } from "react";
import { Share, ShareStatus } from "types";
import { getShareStatus } from "helpers/model-helpers";
import { CheckCircleOutlineOutlined, RadioButtonCheckedOutlined, RadioButtonUncheckedOutlined } from "@mui/icons-material";

export interface IClientStatusChipProps {
  share: Share | null;
}

const ClientStatusChip = ({ share } : IClientStatusChipProps) => {
  // const status = useMemo(() => getShareStatus(share), [share]);
  const status = useMemo(() => getShareStatus(share), [share]);
    
  const chipProps = useMemo<any>(() => {
    
    if(!status === null) return null;
    switch(status){
      case ShareStatus.ACCEPTED:
        return { label: "active", color: "primary", variant: "outlined", icon: <CheckCircleOutlineOutlined fontSize="small"/> };
      case ShareStatus.INVITED:
        return { label: "invited", color: "secondary", variant: "outlined", icon: <RadioButtonCheckedOutlined fontSize="small" /> };
      case ShareStatus.REJECTED:
        return { label: "rejected", color: "error", variant: "outlined" };
      default:
        return { label: "not invited", color: "default", variant: "filled", icon: <RadioButtonUncheckedOutlined fontSize="small" /> };
    };
      
  }, [status]);

  if(status === ShareStatus.ACCEPTED) return null;

  return (
    <>
      {chipProps && (
        <Chip color={chipProps.color} variant={chipProps.variant} label={chipProps.label} />
      )}
    </>
  );
};

export default ClientStatusChip;