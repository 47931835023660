import { Chip, SxProps, Theme } from "@mui/material";
import { SameAsConfig } from "types";
import { isSameAs, sameAsFlag } from "helpers/model-helpers";
import { useFormSameAs, useFormValue } from "sections/financials/parts/v2.0.0/part-hooks";
import { useState } from "react";

export interface ISameAsChipProps {
  item: SameAsConfig;
  values: Record<string, any>;
  sx?: SxProps<Theme>;
}

const SameAsChip = ({item, values, sx}: ISameAsChipProps) => {
  const label = item.sameAsLabel ?? "Same as Client 1";
  const [isSameAs, sameAsValue] = useFormSameAs(item);
  
  // const flagId = sameAsFlag(item);
  // const [, flagValue] = useFormValue(flagId);
  
  // const sameAs = isSameAs(item, values);

  if(!isSameAs) return null;

  return (
    <Chip 
      label={label}
      size="small" 
      sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", mx: 1, ...(sx ?? {}) }} 
    />
  );
}

export default SameAsChip; 
