import { ClientNoteSchema } from "types/schema"

export type NoteFilters = { 
  starred: boolean;
  unstarred: boolean;
  calls: boolean;
  search?: string;
}

export const filterNotes = (notes: ClientNoteSchema[] | null | undefined, filter: NoteFilters) => {
  if(!notes) return [];

  let filtered = notes.filter(note => {
    if(filter.starred && note.isStarred) return true;
    if(filter.unstarred && !note.isStarred) return true;
    if(filter.calls && note.tags?.includes("call")) return true;
    return false;
  });

  if(filter.search){
    filtered = filtered.filter(note => note.content.toLowerCase().includes(filter.search!));
  }

  //order the notes newest to oldest
  const ordered = filtered.sort((a, b) => b.createdAt - a.createdAt);

  return ordered;
}