import { useMemo, useState } from "react"
import { Button, Stack, SxProps, TextField } from "@mui/material"
import { StatusKeys } from "helpers";
import { useStatus } from "redux-action-status";

export interface ICommentInputProps {
  onCommentSaved: (content: string) => Promise<boolean>;
  rows?: number;
  sx?: SxProps;
}

const CommentInput = ({ onCommentSaved, rows = 4, sx }: ICommentInputProps) => {
  const [content, setContent] = useState<string>("");
  const canSave = useMemo(() => content?.trim().length > 0, [content]);
  const status = useStatus(StatusKeys.conversations);

  const handleChange = (e: any) => {
    setContent(e.target.value);
  }

  const handleSave = async () => {
    if(!canSave) return;
    const isOk = await onCommentSaved(content);
    if(isOk){
      setContent("");   //clear out the content to start fresh.
    }
  };

  return (
    <Stack width="100%" spacing={1} pr={2}>
      <TextField id="comment-input" 
        onChange={handleChange} value={content} 
        placeholder="Enter your message" fullWidth variant="outlined" multiline rows={rows}
        size="small" sx={{ "& textarea": { fontSize: "1.1rem" }, 
          "& .MuiInputBase-input::placeholder": {
            fontSize: "1.2rem",
            fontStyle: "italic",
            color: "grey.800",
          } 
        }}
      />
      <Button onClick={handleSave} disabled={!canSave || status.isWorking} variant="outlined" disableElevation size="small" sx={{ alignSelf: "end", minWidth: "10rem" }}>Save</Button>
    </Stack>
  );
};

export default CommentInput;