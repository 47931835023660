import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Alert, Box, Divider, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatus } from "redux-action-status";
import { ShareStatus } from "types";
import { StatusKeys } from "helpers";
import { ErrorStatus, SearchField } from "components";
import ClientConversationList from "./client-conversation-list";
import MyConversation from "sections/my/my-conversations/my-conversation";
import { ConversationProvider } from "components/values-table/conversation-context";
import { useNavigate, useParams } from "react-router-dom";
import { useShareContext } from "sections/app/share-context";
import { chooseConversation } from "store/actions/conversation-actions";

const ClientConversationsCardCore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shareId, shareStatus } = useShareContext();
  const { clientId, topicType, topicId } = useParams();
  // const currentConversation = useSelector<any, Conversation>(selectCurrentConversation);
  const [ filter, setFilter ] = useState({ search: "" });
  const status = useStatus(StatusKeys.conversations);

  // If there's no topic, select the general topic
  useEffect(() => {
    if(!topicType && !topicId){
      navigate(`/app/clients/${clientId}/conversations/g/_general_`, { replace: true });
    }
  }, [clientId, topicType, topicId, navigate]);


  useEffect(() => {
    if(!status.isInitialized || !shareId || !topicType || !topicId) return;
    dispatch(chooseConversation(shareId, topicId, topicType));
  }, [shareId, topicType, topicId, status, dispatch]);

  return (
    <Paper id="client-conversations-card" variant="outlined" elevation={0} sx={{ minHeight: 500, height: "calc(100vh - 11rem)", width: "100%", borderRadius: 2 }}>
      <Grid container height="100%" alignItems="flex-start" position="relative">
        {status.error && (
          <Box sx={{ width: "100%" }}>
            <ErrorStatus statusKey={StatusKeys.dates} sx={{width: "100%"}}/>
          </Box>
        )}

        {(shareStatus !== ShareStatus.ACCEPTED) && (
          <Box width="100%" py={4} px={4}>
            <Typography variant="body2" fontStyle="italic" color="grey.600" textAlign="center">Your conversation will begin when the client accepts your invitation</Typography>
          </Box>
        )}

        {(shareStatus === ShareStatus.ACCEPTED) && (
          <>
            {/* Header Grid */}
            <Grid container sm={12} columnGap={1} flexWrap="nowrap" sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderBottomColor: "grey.200", borderRadius: "6px 6px 0.5px 0", px: 2, py: 1 }}>
              <Grid container sm={4} md={3}>
              </Grid>
              <Grid container sm={8} md={9}>
                <Divider orientation="vertical" sx={{ mr: 1 }}/>
                <SearchField placeholder="search selected conversation" onChange={(val) => setFilter({...filter, search: val})} />
              </Grid>
              
            </Grid>

            {/* Body Grid */}
            <Grid container sm={12} columnGap={1} flexWrap="nowrap" flexGrow={1} height="calc(100% - 54px)">
              <Grid container sm={4} md={3}>
                <ClientConversationList />
              </Grid>
              <Grid container sm={8} md={9} sx={{ p: 2, pr: 0, position: "relative", overflowY: "auto", maxHeight: "calc(100vh - 14rem)" }} >
                {!topicId && (
                  <Box sx={{ width: "70%", mt: 5 }}>
                    <Typography variant="body2" textAlign="center" color="grey.500" fontStyle="italic">Choose a conversation to begin</Typography>
                  </Box>
                )}
                {!!topicId && (
                  <MyConversation />
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  )
};

const ClientConversationCard = () => {
  return (
    <ConversationProvider>
      <ClientConversationsCardCore />
    </ConversationProvider>
  );
};

export default ClientConversationCard;