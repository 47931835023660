import React from 'react';
import PropTypes from 'prop-types';
import nHelper from 'helpers/number-helper';

export const withFieldChanged = (Child) => {
    class internalControl extends React.Component{
        onFieldChanged = (event) => {
            const target    = event.target;
            const field     = target.id;
            let value       = null;
            let attr        = null;
            
            if(field === "") return console.warn("Field id is missing on input element", target);

            if(target.type === "checkbox"){
                value       = target.checked;
                if(getAttrValue(target, "data-isnegative", false) === "true") value = !value;
            }
            else{
                attr            = target.attributes["data-type"];
                const dataType  = attr ? attr.value : null;
                value           = target.value;
                    
                if(dataType === "currency"){
                    value       = value ? nHelper.asFloat(value) : null;
                }
            }
            
            const attrIndex = target.attributes["data-index"];
            let index       = attrIndex ? parseInt(attrIndex.value) : null;
            
            return this.props.onChange(this.props.section.id, field, value, null, index);
        }

        render(){
            return <Child onFieldChanged={this.onFieldChanged} {...this.props} />;
        }
    }

    return internalControl;
}

withFieldChanged.propTypes    = {
    onChange    : PropTypes.func.isRequired,
    section     : PropTypes.object.isRequired
};

function getAttrValue(element, name, defaultValue){
    const attr = element.attributes[name];
    return attr ? attr.value : (defaultValue || null);
}
