import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { changePassword } from "store/actions/app-actions";

export interface IChangePasswordDialogProps {
  open: boolean;
  onClose: () => void;
}

type ChangePasswordModel = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const MIN_LENGTH = 8;

const ChangePasswordDialog = ({open, onClose}: IChangePasswordDialogProps) => {
  const dispatch = useDispatch();
  const [model, setModel] = useState<ChangePasswordModel>({currentPassword: "", newPassword: "", confirmPassword: ""});
  const [noMatch, setNoMatch] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  
  const canSave = useMemo(() => {
    return model.currentPassword.length > 0 && 
      model.newPassword.length > 0 && 
      model.confirmPassword === model.newPassword;
  }, [model]);

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setModel({...model, [key]: val});
    setNoMatch(null);
    setError(null);
  }

  const handleBlur = (e: any) => {
    const key = e.target.id;
    const val = e.currentTarget.value;
    if(key === "confirmPassword" && model.newPassword.length > 0 && val !== model.newPassword){
      setNoMatch("Passwords do not match");
    }
    else if(key === "newPassword" && model.confirmPassword.length > 0 && val !== model.confirmPassword){
      setNoMatch("Passwords do not match");
    }
    else if(val.length > 0 && val.length < MIN_LENGTH){
      setNoMatch("Password must be at least 8 characters long");
    }
    else if(val === model.currentPassword){
      setNoMatch("New password must be different from current password");
    }
    else {
      setNoMatch(null);
    }
  }

  const handleSave = async () => {
    const result = await dispatch(changePassword(model.currentPassword, model.newPassword)) as any;
    if(result.isOk){
      toast.success("Password successfully changed");
      onClose();
    }
    else {
      setError(result.error.message);
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Change Password</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} my={2}>
          {error && 
            <Grid xs={12}>
              <Alert severity="error">{error}</Alert>              
            </Grid>
          }
          <Grid xs={12}>
            <TextField
              onChange={handleChange}
              fullWidth
              id="currentPassword"
              label="Current Password"
              type="password"
              variant="outlined"
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              id="newPassword"
              label="New Password"
              type="password"
              variant="outlined"
              error={!!noMatch}              
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              onChange={handleChange}
              onBlur={handleBlur}
              fullWidth
              id="confirmPassword"
              label="Confirm New Password"
              type="password"
              variant="outlined"
              error={!!noMatch}
              helperText={noMatch}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={!canSave}>Change Password</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangePasswordDialog;