import { useState } from "react";
import { useSelector } from "react-redux";
import { isArray } from "lodash";
import { useDropArea } from "react-use";
import { Box, Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CloudUploadOutlined, UploadFile } from "@mui/icons-material";
import { toast } from "react-toastify";
import PetitionerUploadDialog from "./petitioner-upload-dialog";
import { selectPetitionerUploads } from "store/selectors/petitioner-selectors";
import UploadItem from "./upload-item";

const PetitionerUploadsList = () => {
  // const theme = useTheme();
  const items = useSelector(selectPetitionerUploads);
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setOpen] = useState(false);

  const onDropped = (fileType: "files" | "uri" | "text") => (data: any) => {
    if(fileType !== "files") return console.log('dropped', fileType, data);
    if(isArray(data)) data = data[0];

    setFile(data);
    setOpen(true);
    // console.log('dropped', fileType, data);
  }

  const onUploaded = (uploaded: boolean) => {
    if(uploaded) toast.success("File uploaded successfully");
    setFile(null);
    setOpen(false);
  };

  const [bond, state] = useDropArea({
    onFiles: onDropped("files"),
    onUri: uri => onDropped("uri"),
    onText: text => onDropped("text"),
  });


  return (
    <Grid id="petitioner-uploads-view" {...bond } container direction="column" alignItems="flex-start" flexWrap="nowrap" xs={12} spacing={4} sx={{ width: "100%", height: "100%"}}>
      
      {state.over && (
        <Grid id="petitioner-drop-zone" container height="100%" width="100%" justifyContent="center" alignItems="flex-start" pt={4} sx={{ pointerEvents: "none" }}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" sx={{ mt: 4, p: 4, border: "1px dashed", borderColor: "primary.main", borderRadius: 1 }}>
            <UploadFile fontSize="large" color="primary"/>
            <Typography variant="body1" color="primary" textAlign="center">Drop files when ready</Typography>
          </Box>
        </Grid>
      )}

      {!state.over && (
        <>
          {/* <Grid container xs={8} xsOffset={2} mt={4} spacing={2} alignItems="flex-start"> */}
            <Grid container xs={12} justifyContent="center" alignItems="center" columnGap={1} flexWrap="nowrap" sx={{ mt: 4 }}>
              <Typography variant="body1" color="grey.600" textAlign="center">Drop files anywhere, or click </Typography>
              <Button variant="outlined" color="primary" onClick={() => setOpen(true)} endIcon={<CloudUploadOutlined fontSize="small" />}>Upload</Button>
              <Typography variant="body1" color="grey.600" textAlign="center">to share a file with your attorney</Typography>
            </Grid>
          {/* </Grid> */}

          <Grid container xs={12} py={2} pt={4} rowGap={2}>
            {items.map((item, index) => (
              <Grid key={index} container xs={6} px={4} justifyContent="stretch">
                <UploadItem item={item} />
              </Grid>
            ))}
          </Grid>
        </>
      )}

      {!!isOpen && <PetitionerUploadDialog open={true} onClose={onUploaded} file={file}/> }

    </Grid>
  );
};

export default PetitionerUploadsList;
