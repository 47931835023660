import { useMemo } from "react";
import { Chip, CircularProgress, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircle, SyncDisabledOutlined, SyncOutlined, SyncProblemOutlined } from "@mui/icons-material";
import { useCloudSync } from "hooks/cloud-storage";
import { useClient } from "sections/collaborator/client-context";
import { useCloudStorage } from "../cloud-storage-context/cloud-storage-context";

enum SyncStatus {
  SYNCED = "synced",
  SYNCING = "syncing",
  NOT_SYNCED = "not synced",
  SYNC_ERROR = "sync error"
}

const ClientSyncChip = () => {
  const { setting } = useCloudStorage();
  const { canSync } = useCloudSync();
  const { currentClient, syncStatus, doSync } = useClient();
  const syncedAt = !!currentClient.cloudSync?.syncedAt;
  
  const clientSyncStatus = useMemo(() => {
    if(syncStatus.isSyncing) return SyncStatus.SYNCING;
    else if(syncStatus.error) return SyncStatus.SYNC_ERROR;
    else if(syncedAt) return SyncStatus.SYNCED;
    else return SyncStatus.NOT_SYNCED;
  }, [syncStatus, syncedAt]);

  const cloudSyncMode = setting?.syncMode ?? "manual";
  
  if(!canSync) return null;

  return (
    <>
      { clientSyncStatus === SyncStatus.SYNCING && (
        <Grid container alignItems="center" px={1.5} sx={{ height: 34, borderRadius: 8, border: `1px solid`, borderColor: "info.main" }}>
          <CircularProgress size={18} color="info" />
          <Typography ml={1} fontSize="1.1rem" color="info.main">Syncing...</Typography>
        </Grid>
      )}
      { clientSyncStatus !== SyncStatus.SYNCING && (
        <Tooltip title={syncTooltips[clientSyncStatus]} placement="top">
          <Chip
            onClick={() => doSync(true)}
            label={syncLabels[clientSyncStatus]}
            color={syncColors[clientSyncStatus] as any}
            size="small"
            variant="outlined"
            sx={{ py: 2, px: 1 }}
            icon={syncIcons[clientSyncStatus]}
          />
        </Tooltip>
      )}
    </>
  )
};

export default ClientSyncChip;


const syncLabels = {
  [SyncStatus.SYNCED]: "Synced",
  [SyncStatus.SYNCING]: "Syncing...",
  [SyncStatus.NOT_SYNCED]: "Not synced",
  [SyncStatus.SYNC_ERROR]: "Sync error"
};

const syncColors = {
  [SyncStatus.SYNCED]: "primary",
  [SyncStatus.SYNCING]: "info",
  [SyncStatus.NOT_SYNCED]: "default",
  [SyncStatus.SYNC_ERROR]: "error"
};

const syncIcons = {
  [SyncStatus.SYNCED]: <CheckCircle fontSize="small" />,
  [SyncStatus.SYNCING]: <SyncOutlined fontSize="small" />,
  [SyncStatus.NOT_SYNCED]: <SyncDisabledOutlined fontSize="small" />,
  [SyncStatus.SYNC_ERROR]: <SyncProblemOutlined fontSize="small" />
};

const syncTooltips = {
  [SyncStatus.SYNCED]: "Client files are synced with cloud storage",
  [SyncStatus.SYNCING]: "Syncing client files...",
  [SyncStatus.NOT_SYNCED]: "Client files are not synced",
  [SyncStatus.SYNC_ERROR]: "Error syncing client files"
};