import { useMemo, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { ClientDocument, RowItem } from "types";
import { useAnchor } from "helpers/hooks";
import ClientUploadItem from "./client-upload-item";
import { useClient } from "../../client-context";
import { UploadFilter } from "./client-card-uploads";

export interface IClientUploadsListProps {
  items: RowItem<ClientDocument>[];
  isEmpty?: boolean;
  filter?: UploadFilter;
}

const ClientUploadsList = ({ items, isEmpty, filter }: IClientUploadsListProps) => {
  const { currentClient: client } = useClient();

  // const filtered = useMemo(() => {
  //   if(!filter) return items;
  //   return items.filter(item => {
  //     if(filter.requested) return !!item.requestedAt;
  //     if(filter.other) return true;
  //     return false;
  //   });
  // }, [items, filter]);

  // const [anchor, isOpen, onOpen, onClose, positionProps] = useAnchor("bottom-right");
  // const [hovered, setHovered] = useState<any | null>(null);

  const handleSelected = (formId: string) => (e: any) => {
    // const updated = items.map(item => {
    //   if(item.id === formId) return { ...item, isSelected: !item.isSelected };
    //   else if(item.isSelected) return { ...item, isSelected: false };
    //   return item;
    // });
    // setAssigned(updated);
  };

  const handleChecked = (formId: string) => (e: any) => {
    // const updated = items.map(item => {
    //   if(item.id === formId) return { ...item, isChecked: e.target.checked };
    //   return item;
    // });
    // setAssigned(updated);
  }; 

  const handleHover = (id: string) => (isEntering: boolean) => (e: React.MouseEventHandler) => {
    // if(isEntering){
    //   onOpen(e);
    //   const item = items.find(item => item.id === id);
    //   setHovered(item)
    // }
    // else {
    //   onClose();
    //   setHovered(null);
    // }
  };

  return (
    <TableContainer>
      <Table size="small" sx={{ "& a,p,th": { fontSize: "1.1rem" } }}>
        <TableHead>
          <TableRow>
            <TableCell width={36} sx={{ py: 0, ...cellSx }}></TableCell>
            <TableCell sx={cellSx}>Name</TableCell>
            <TableCell align="center" sx={cellSx}>Assigned</TableCell>
            <TableCell align="center" sx={cellSx}>Type</TableCell>
            <TableCell align="right" sx={cellSx}>Requested</TableCell>
            <TableCell align="right" sx={cellSx}>Uploaded</TableCell>
            {/* <TableCell sx={cellSx}></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {items.map(item => <ClientUploadItem key={item.id} item={item} onChecked={handleChecked} onSelected={handleSelected} onHover={handleHover(item.id)} /> )}
          {isEmpty && (
            <TableRow>
              <TableCell colSpan={6} sx={{ py: 2, textAlign: "center" }}>
                <Typography variant="body2" color="secondary">{client?.firstName ?? "The client"} hasn't uploaded any files yet</Typography>
              </TableCell>
            </TableRow>
            )
          }
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ClientUploadsList;

const cellSx = { color: "grey.500" };