import React from 'react';
import { connect } from 'react-redux';

class ErrorBoundary extends React.Component {

    constructor(props){
        super(props);
        this.state  = {
            hasError    : false,
            error       : null
        };
    }

    componentDidCatch(error, info){
        this.setState({
            hasError    : true,
            error       : error
        });
    }

    render(){
        if(this.state.hasError){
            return (
                <div className="error-boundary">
                    <div className="page-content">
                        <div className="page-error page-js-error">
                            <div className="text font-red">Ouch!</div>
                            <div className="details">
                                <h1>Uhm, this is embarrasing...</h1>
                                <p>
                                    FormHop has encountered an unexpected error.
                                    <br/>
                                    We apologize for the inconvenience.  Please try <a href="/">starting over</a>.
                                    <br/>
                                    {/* If that doesn't work, you could also try <a href="/logout">Logging Out</a>, and then back in. */}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return this.props.children;
    }
}

function mapState(state, myProps){
    return {
    };
}

function mapActions(dispatch){
    return {
        actions     : null
    };
}

export default connect(mapState, mapActions)(ErrorBoundary);