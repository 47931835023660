import { useCallback } from "react";
import { useSelector } from "react-redux";
import { AccountMember, Comment, CommentOwner } from "types";

export function useCommentOwner(): (item?: Comment) => CommentOwner {
  const profile = useSelector<any, any>(state => state.app.profile);
  const teamMembers = useSelector<any, AccountMember[]>(state => state.account.account?.members);
  const providers = useSelector<any, any[]>(state => state.petitioner.providers);
  
  const { role, uid } = profile;

  const reviewerCallback = useCallback((item?: Comment) => {
    if(!item) return { isMine: true, isMyTeam: true, name: "You", initial: null };
    else if(item.createdBy === uid) return { isMine: true, isMyTeam: true, name: "You", initial: null };
    else{
      const member = teamMembers.find(m => m.id === item.createdBy);
      const memberName = member ? member?.profile.displayName ?? "Reviewer" : "Client";
      const initial = member ? memberName[0] : null;

      const co: CommentOwner = { 
        isMine: false, 
        isMyTeam: !!member,
        name: member ? member?.profile.displayName ?? "Reviewer" : "Client",
        initial, 
      };

      return co;
    }
  }, [uid, teamMembers]);

  const petitionerCallback = useCallback((item?: Comment) => {
    if(!item) return { isMine: true, isMyTeam: true, name: "You", initial: null };
    else if(item.createdBy === uid) return { isMine: true, isMyTeam: true, name: "You", initial: null };
    else {
      const providerMembers = providers.flatMap(p => p.members);
    
      if(item.createdBy === uid) return { isMine: true, isMyTeam: true, name: "You", initial: null };
      else {
        const member = providerMembers.find(m => m.uid === item.createdBy);
        const name = member ? member.displayName ?? member.email : "Reviewer";
        const initial = member ? name[0] : null;
        
        const co: CommentOwner = { isMine: false, isMyTeam: false, name, initial };
        return co;
      }
    }
  }, [uid, providers]);

  if(role !== "reviewer") return petitionerCallback;
  else return reviewerCallback;
}