import { pick } from "lodash";
import helpers, { immAddOrReplace } from "./reducer-helpers";
import { ATTORNEY_ACTIONS, StateAbbreviations } from "../actions/attorney-actions";
import { ClientProfile, Form } from "types";
import { ExportTemplateSchema } from "types/schema";
import { APP_ACTIONS } from "../actions/action-types";

export type AttorneySlice = {
  forms: Form[] | null;
  commonForms: Record<StateAbbreviations, any[]> | null;
  templates: ExportTemplateSchema[] | null;
  currentClientId: string | null,
  currentClient: ClientProfile | null,
  userProfiles : Record<string, any>,
  clients : ClientProfile[],
  clientData : Record<string, any> | null,
  editRegionId: string | null,      //When editing client data, which region is being edited
  isEditingClientData : boolean,
};

const INIT_STATE: AttorneySlice = {
  forms: null,
  commonForms: null,
  templates: null,
  currentClientId: null,
  currentClient: null,
  userProfiles : {},
  clientData : null,
  clients: [],
  editRegionId: null,
  isEditingClientData: false,
}

//====
// Reducer Functions
//====

//#region Forms

function formsLoaded(state: any, action: any) {
  const { accountForms, commonForms, templates } = action.data;

  return {
    ...state,
    forms: accountForms, //action.data,
    commonForms: {
      [action.stateCode]: commonForms,
    },
    templates: templates,
  };
}

function formAdded(state: any, action: any) {
  return {
    ...state,
    forms: [...state.forms ?? [], action.data],
  };
}

function formUpdated(state: AttorneySlice, action: any) {
  if(action.error) return state;    //handled by the status hook
  if(!state.forms) return state;

  const existing = state.forms.find(form => form.id === action.formId);
  if(!existing) return formAdded(state, action);
  const updated = {...existing, ...action.data};
  return {
    ...state,
    forms: immAddOrReplace(state.forms, updated, (f: any) => f.id === updated.id),
  };

}

//#endregion

//#region Clients
function chooseClient(state: AttorneySlice, action: any) {
  const clientProfile = action.clientId === null ? null : state.clients.find(client => client.id === action.clientId);

  return {
    ...state,
    currentClientId: action.clientId,
    currentClient: clientProfile,
    clientData: null,
  };
}

function clientCreated(state: AttorneySlice, action: any){
  if(!action.isOk) return state;

  const { profile } = action.data;
  return {
    ...state,
    clients: [
      ...state.clients, 
      profile,
    ],
  };
}

function clientUpdated(state: AttorneySlice, action: any){
  if(action.error) return state;    //handled by the status hook
  const existing = state.clients.find(client => client.id === action.clientId);
  if(!existing) throw new Error("client not found in reducer");

  const updated = { ...existing, ...action.data };
  const currentClient = state.currentClient?.id === updated.id ? { ...state.currentClient, ...action.data } : state.currentClient;

  return {
    ...state,
    currentClient,
    clients: immAddOrReplace(state.clients, updated, (c: any) => c.id === updated.id),
  };
}

function clientListLoaded(state: AttorneySlice, action: any){
  return {
    ...state,
    clients: action.data,
  };
}

//When a client is loaded, it includes the client, profile and share, plus sub-collections
function clientLoaded(state: AttorneySlice, action: any){
  if(!action.isOk) return state;
  const { client, profile, share } = action.data;
  
  const clientData = { ...client, _loadedAt: Date.now() };

  if(state.currentClientId === client.id){
    //This is the selected client, so update the currentClient
    const currentClient = { ...clientData, profile, share };
    return {
      ...state,
      currentClient,
      clients: immAddOrReplace(state.clients, clientData, (c: any) => c.id === client.id),
    };
  }
  else {
    //Not currently selected, so just update the client list
    return {
      ...state,
      clients: immAddOrReplace(state.clients, clientData, (c: any) => c.id === client.id),
    };
  }  
}

function clientValuesLoaded(state: AttorneySlice, action: any){
  if(action.error) return state;    //handled by the status hook
  
  if(!action.data){   //means this user doesn't have a values document yet
    return {
      ...state,
      clientData: {
        id: action.clientId,
        forms: [],
        values: {
          a_isLoaded: true,          
        }
      }
    };
  }

  return {
    ...state,
    clientData  : {
      ...pick(action.data, ["id", "appVersion", "meta", "uid"]),
      loadedAt: Date.now(),
      forms: action.data.forms,
      values  : {
        a_isLoaded  : true,
        ...action.data.values,
      },
    },
  };
}

function clientValuesUpdated(state: AttorneySlice, action: any) {
  if(action.error) return state;    //handled by the status hook
  if(!state.clientData) return state;

  const updated = {
    ...state.clientData,
    values: {
      ...state.clientData.values,
      ...action.values,
    },
  };

  return {
    ...state,
    clientData: updated,
  };
}

function setEditClientData(state: AttorneySlice, action: any){
  return {
    ...state,
    editRegionId: action.isEditing ? action.regionId : null,
    isEditingClientData: action.isEditing,
  };
}


//#endregion

//#region Client Notes

function clientNotesLoaded(state: AttorneySlice, action: any){
  if(!action.isOk) return state;
  const client = state.clients.find(client => client.id === action.clientId);
  if(!client){
    console.error("could not find client for notes", action.clientId);
    return state;
  } 

  const updatedClient = {
    ...client,
    notes: action.data,
  };

  return {
    ...state,
    clients: immAddOrReplace(state.clients, updatedClient, (c: any) => c.id === updatedClient.id),
  };
}

function clientNoteAdded(state: AttorneySlice, action: any){
  if(!action.isOk) return state;
  const client = state.clients.find(client => client.id === action.clientId);
  if(!client){
    console.error("could not find client for notes", action.clientId);
    return state;
  } 

  const updatedClient = {
    ...client,
    notes: [...client.notes ?? [], action.data],
  };

  return {
    ...state,
    clients: immAddOrReplace(state.clients, updatedClient, (c: any) => c.id === updatedClient.id),
  };
}

function clientNoteUpdated(state: AttorneySlice, action: any){
  if(!action.isOk) return state;
  const client = state.clients.find(client => client.id === action.clientId);
  if(!client){
    console.error("could not find client for notes", action.clientId);
    return state;
  } 

  const updatedClient = {
    ...client,
    notes: immAddOrReplace(client.notes, action.data, (n: any) => n.id === action.noteId),
  };

  return {
    ...state,
    clients: immAddOrReplace(state.clients, updatedClient, (c: any) => c.id === updatedClient.id),
  };

}

function clientNoteDeleted(state: AttorneySlice, action: any){
  if(!action.isOk) return state;
  const client = state.clients.find(client => client.id === action.clientId);
  if(!client){
    console.error("could not find client for notes", action.clientId);
    return state;
  } 

  const existingNotes = client.notes ?? [];
  const updatedClient = {
    ...client,
    notes: existingNotes.filter(n => n.id !== action.noteId),
  };

  return {
    ...state,
    clients: immAddOrReplace(state.clients, updatedClient, (c: any) => c.id === updatedClient.id),
  };
}

//#endregion

function onSignedOut(state: AttorneySlice, action: any){
  return {...INIT_STATE };
}

//#endregion


//==== Action Handlers
const ACTION_HANDLERS = {
  [ATTORNEY_ACTIONS.FORMS_LOADED]: formsLoaded,
  [ATTORNEY_ACTIONS.FORM_ADDED]: formAdded,
  [ATTORNEY_ACTIONS.FORM_UPDATED]: formUpdated,

  [ATTORNEY_ACTIONS.CLIENT_CHOSEN]    : chooseClient,
  [ATTORNEY_ACTIONS.CLIENT_LIST_LOADED]  : clientListLoaded,
  [ATTORNEY_ACTIONS.CLIENT_LOADED]    : clientLoaded,

  [ATTORNEY_ACTIONS.CLIENT_CREATED]    : clientCreated,
  [ATTORNEY_ACTIONS.CLIENT_UPDATED]    : clientUpdated,
  [ATTORNEY_ACTIONS.CLIENT_VALUES_LOADED]    : clientValuesLoaded,
  [ATTORNEY_ACTIONS.CLIENT_VALUES_UPDATED]  : clientValuesUpdated,

  [ATTORNEY_ACTIONS.CLIENT_NOTES_LOADED]  : clientNotesLoaded,
  [ATTORNEY_ACTIONS.CLIENT_NOTE_ADDED]  : clientNoteAdded,
  [ATTORNEY_ACTIONS.CLIENT_NOTE_UPDATED]  : clientNoteUpdated,
  [ATTORNEY_ACTIONS.CLIENT_NOTE_DELETED]  : clientNoteDeleted,

  [ATTORNEY_ACTIONS.TOGGLE_EDIT_CLIENT_DATA]  : setEditClientData,

  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

//==== The Reducer
export default helpers.createReducer(INIT_STATE, ACTION_HANDLERS);