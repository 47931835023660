import { useSelector } from "react-redux";
import { TableCell, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FormField as FormFieldType, FormSection } from "../../types";
import { useRegion } from "sections/financials/parts/region-context";
import { FormInput } from "sections/financials/parts/v2.0.0/form-input";
import fHelper from "helpers/financials-helper";
import { useAppContext } from "sections/app/app-context";
import FormSameAsField from "sections/financials/parts/v2.0.0/form-sameas-field";
// import { useFormContext } from "sections/forms/form-context";
export interface IDataTableFieldEditorProps {
  fieldKey: string;
  section: FormSection;
}

export interface IDataTableRowEditorProps {
  field: FormFieldType;
  section: FormSection;
}

const DataTableRowEditor = ({ fieldKey, section }: IDataTableFieldEditorProps) => {
  const { getField } = useRegion();
  const field = getField(fieldKey, false);
  
  if(!field) return null;
  else if(field.condition) return <ConditionalRow field={field} section={section} />;
  else return <StandardTableRow field={field} section={section} />;
}

export default DataTableRowEditor; 

//=== ConditionalField ===//
// This is broken out so that only the conditional fields need to retrieve all the values.
// This way, we can avoid unnecessary re-renders of the entire table, and only the specific fields
// that need to will re-render when a value changes.
const ConditionalRow = ({ field, section }: IDataTableRowEditorProps) => {
  const { isReviewer } = useAppContext();
  const values    = useSelector<any>(state => isReviewer ? state.attorney.clientData?.values : state.values);

  if(!field || (field.condition && !fHelper.isConditionMet(field.condition, values))){
    return null;
  }

  return (
    <StandardTableRow field={field} section={section}/>
  );

};

const cellSx = { 
  py: 0.75, 
  borderBottom: "1px dotted", 
  borderBottomColor: "grey.300" ,
  // display: "flex",
  // justifyContent: "flex-end",
};

const StandardTableRow = ({ field, section }: IDataTableRowEditorProps) => {
  return (
    <TableRow sx={{ borderBottom: "1px dotted", borderBottomColor: "grey.300" }} className="data-table-row-editor">
      <TableCell sx={cellSx}>
        <Typography fontSize="17px" fontWeight="300">
          {field.label}
        </Typography>
      </TableCell>
      <TableCell colSpan={2} sx={cellSx} align="right">
        <Grid container xs={12} flexWrap="nowrap" justifyContent="flex-end" columnGap={0.5}>
          <FormSameAsField item={field} /> 
          <FormInput field={field} />
        </Grid>
      </TableCell>
    </TableRow>
  );
};
