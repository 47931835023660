import { Box, Table, TableBody } from '@mui/material';
import { FormField, FormSection } from 'types';
import fHelper from 'helpers/financials-helper';
import { withFieldChanged } from './with-field-changed';
import TableSectionHeader from './table-section-header';
import QuestionSectionRow from './question-row-2';

export interface IQuestionSectionProps {
  section: FormSection;
  fields: FormField[];
  values: any;
  onChange: (sectionId: string, fieldId: string, total: number, details: any, index: number) => void;
  onFieldChanged: (event: any) => void;
}

const QuestionSection2 = ({ section, fields, values, onFieldChanged }: IQuestionSectionProps) => {
  const isCollapsed   = !fHelper.isConditionMet(section.condition, values);

  return (
    <Box data-id="question-section" width="100%" sx={{ borderBottom: "1px solid", pb: 2, borderBottomColor: "grey.300" }}>
      <Table className="section-table question-section" size="small" onChange={onFieldChanged}>
        <TableSectionHeader sectionId={section.id} onChange={() => {}} />
        <TableBody>
          {/* {rows} */}
          {!isCollapsed && (
            fields.map(f => <QuestionSectionRow key={f.id} section={section} field={f} values={values}/>)
          )}
        </TableBody>
      </Table>
    </Box>
  );
}

export default withFieldChanged(QuestionSection2);