import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Icon from '@mui/material/Icon';
import Typography from '@mui/material/Typography';


function MenuListItem({icon, text, onClick, className = null}){
  const classes   = buildStyles();
  return (
    <MenuItem onClick={onClick} className={className}>
      <ListItemIcon>
        <Icon fontSize="small" color="secondary">{icon}</Icon>
      </ListItemIcon>
      <Typography color="secondary" className={classes.menuItem}>{text}</Typography>
    </MenuItem>
  );
}

export default MenuListItem;

const buildStyles   = makeStyles(theme => ({
  menuItem    : {
    fontSize    : "1rem",
  }
}));