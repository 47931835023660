import { useState } from "react";
import { alpha, Box, Typography, useTheme } from "@mui/material";
import { sameAsFlag } from "helpers/model-helpers";
import { usePetitionerForm } from "../../form-hooks";
import { FormField } from "types";

export interface ISameAsInputProps {
  field: FormField;
  values: any;
  onChange: (value: boolean) => void;
}

export const SameAsInput = ({field, values, onChange}: ISameAsInputProps) => {
  const theme = useTheme();
  const id = sameAsFlag(field);
  const [checked, setChecked] = useState(values[id] ?? false);
  const { onValueChanged } = usePetitionerForm(true);

  const handleToggle = () => {
    handleChange({ target: { checked: !checked } });    
  }

  const handleChange = (event: any) => {
    const { checked: boxChecked } = event.target;
    if(boxChecked !== checked) {
      setChecked(boxChecked);
      onChange(boxChecked);
      onValueChanged(field.regionId!, field.sectionId!, id, boxChecked, null, undefined);
    }
  };

  const boxSx = {
    px: 0.5,
    cursor: "pointer", 
    display: "flex", 
    textWrap: "nowrap",
    alignItems: "center", 
    border: "1px solid", 
    borderRadius: 1, 
    borderColor: checked ? "secondary.dark" : "grey.500", 
    bgcolor: checked ? alpha(theme.palette.secondary.light, 0.25) : "transparent",
    "&:hover": {
      bgcolor: checked ? alpha(theme.palette.secondary.light, 0.25) : "grey.100"
    }
  }

  return (
    <Box onClick={handleToggle} sx={boxSx}>
      <Typography variant="caption" sx={{ mr: 1 }} fontSize="small">Same as Client 1</Typography>
      <input type="checkbox" checked={checked} onChange={handleChange} />      
    </Box>
  );
};