import { styled } from "@mui/material/styles";
import Switch, { switchClasses } from "@mui/material/Switch";

const SwitchText = styled(Switch)(({ theme, trueLabel = "YES", falseLabel = "NO" }: any) => ({
  width: 80,
  height: 40,
  padding: 10,
  [`& .${switchClasses.switchBase}`]: { padding: 10, transform: "translateX(4px)", color: "#ff6a00" },
  [`& .${switchClasses.thumb}`]: { width: 20, height: 20, backgroundColor: "#fff" },
  [`& .${switchClasses.track}`]: {
    background: theme.palette.grey[400], //"linear-gradient(to right, #ee0979, #ff6a00)",
    opacity: "1 !important",
    borderRadius: 20,
    position: "relative",
    "&:before, &:after": {
      display: "inline-block",
      position: "absolute",
      top: "50%",
      width: "50%",
      transform: "translateY(-50%)",
      color: "#fff",
      textAlign: "center",
      fontSize: "0.75rem",
      fontWeight: 500
    },
    "&:before": {
      content: `"${trueLabel}"`, //'"YES"',
      left: 4,
      opacity: 0
    },
    "&:after": {
      content: `"${falseLabel}"`, //'"NO"',
      right: 4
    }
  },
  [`& .${switchClasses.checked}`]: {
    [`&.${switchClasses.switchBase}`]: {
      color: "#185a9d",
      transform: "translateX(36px)",
      "&:hover": { backgroundColor: "rgba(24,90,257,0.08)" }
    },
    [`& .${switchClasses.thumb}`]: { backgroundColor: "#fff" },
    [`& + .${switchClasses.track}`]: {
      background: `${theme.palette.primary.light} !important`, //"linear-gradient(to right, #43cea2, #185a9d)",
      "&:before": { opacity: 1 },
      "&:after": { opacity: 0 }
    }
  }
}));

export default SwitchText;