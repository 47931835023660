import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CloudUploadOutlined, FilePresent } from "@mui/icons-material";
import { Button } from "@mui/material";
import { AssignedDocument } from "types";

export interface IDocumentNavButtonProps { 
  document: AssignedDocument, 
  shareId?: string, 
  documentId?: string
}

const DocumentNavButton = ({document, shareId, documentId}: IDocumentNavButtonProps) => {
  const props = useMemo<any>(() => {
    if(document.id === documentId) return { variant: "outlined", color: "secondary", cursor: "default" };
    return { variant: "text", color: "inherit", component: Link, to: `/app/${shareId}/documents/${document.id}` };
  }, [document, shareId, documentId]);

  const icon = useMemo<any>(() => {
    return document.direction === "fromClient" ? <CloudUploadOutlined fontSize="small" /> : <FilePresent fontSize="small" />
  }, [document.direction]);

  if(!document || !shareId) return null;

  return (
    <Button 
      {...props}
      size="small" 
      startIcon={icon}>
        {document.name}
    </Button>
  )
};

export default DocumentNavButton;