import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InfoOutlined } from "@mui/icons-material";
import { Button, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ProviderAccount } from "types";
import { updateAccountProfile } from "store/actions/account-actions";
import { parseFbResult } from "store/store-helpers";
import { toast } from "react-toastify";
import { initializeProps } from "helpers/model-helpers";

const fields = ["website", "email", "phone", "address", "address2", "city", "state", "zip"];


export interface IAccountContactCardProps {
  onboardingMode?: boolean;
  onSaved?: () => void;
  onBack?: () => void;
}

const AccountContactCard = ({ onboardingMode, onSaved, onBack}: IAccountContactCardProps) => {
  const dispatch = useDispatch();
  // const theme = useTheme();
  const accountProps = useSelector<any, ProviderAccount>(state => state.account.account);
  const [initialValues, setInitialValues] = useState<Record<string, any>>(initializeProps(accountProps, fields));
  const [props, setProps] = useState<Record<string, any>>(initialValues);
  // const [error, setError] = useState<string | null>();
  const hasChanges = useMemo(() => fields.some(f => initialValues[f] !== props[f]), [props, initialValues]);

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleSave = async () => {
    if(!hasChanges && onboardingMode) {
      onSaved && onSaved();
      return;
    }

    const result = await dispatch(updateAccountProfile(props));
    const pr = parseFbResult(result);
    if(pr.isOk){
      toast.success("Profile updated successfully");
      setInitialValues(initializeProps(props, fields));

      if(onboardingMode && onSaved) onSaved();
    }
    else {
      // setError(pr.error.toString());
      toast.error("Error updating profile, see details above");
    }
  }

  return (
    <Paper id="account-contact" variant="outlined" sx={{ borderRadius: "0 0 8px 8px", width: "100%", minHeight: 500 }}>
      
      {!onboardingMode && (
        // Info banner 
        <Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ bgcolor: "grey.200", borderBottom: "1px solid", borderColor: "grey.300", px: 2, py: 1 }}>
          
          <Grid container>
            <InfoOutlined sx={{ fontSize: "1.5rem", color: "grey.600", mr: 1, mt: 0.25 }} />
            <Typography fontSize="1.3rem" fontWeight="300" color="grey.600">Help your clients and prospects reach you. This is part of your firm's public profile.</Typography>
          </Grid>

          <Button onClick={handleSave} variant="text" color="secondary" disabled={!hasChanges}>Save Changes</Button>

        </Grid>
      )}

      <TableContainer sx={{ px: 2, py: 1 }}>
        <Table sx={{ fontSize: "1.1rem" }}>
          <TableBody>            
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Website</TableCell>
              <TableCell>
                <TextField id="website" value={props.website} onChange={handleChange} size="small" fullWidth />
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Email</TableCell>
              <TableCell>
                <TextField id="email" value={props.email} onChange={handleChange} size="small" fullWidth helperText="e.g. info@your-firm.com" />
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Phone</TableCell>
              <TableCell>
                <TextField id="phone" value={props.phone} onChange={handleChange} size="small" fullWidth helperText="Your firm's main phone line"/>
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem", verticalAlign: "top" }}>Address</TableCell>
              <TableCell>
                <Grid container gap={0.5}>
                  <Stack width="100%" spacing={1}>
                    <TextField id="address" value={props.address} onChange={handleChange} label={props.address ? "" : "Address line 1"} size="small" fullWidth InputLabelProps={{ shrink: true }}/>
                    <TextField id="address2" value={props.address2} onChange={handleChange} label={props.address2 ? "" : "Address line 2"} size="small" fullWidth InputLabelProps={{ shrink: true }}/>
                    <Stack direction="row" gap={1}>
                      <TextField id="city" value={props.city} onChange={handleChange} label={props.city ? "" : "City"} size="small" InputLabelProps={{ shrink: true }}/>
                      <TextField id="state" value={props.state} onChange={handleChange} label={props.state ? "" : "State"} size="small" InputLabelProps={{ shrink: true }} sx={{ width: "5rem" }}/>
                      <TextField id="zip" value={props.zip} onChange={handleChange} label={props.zip ? "" : "Zip"} size="small" InputLabelProps={{ shrink: true }}/>
                    </Stack>
                  </Stack>

                </Grid>
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>

          </TableBody>
        </Table>
      </TableContainer>

      {onboardingMode && (
        <Grid container width="100%" justifyContent="space-between" py={2} px={2}>
          <Button onClick={onBack} variant="contained" color="inherit" disableElevation sx={{ width: "8rem" }}>Back</Button>
          <Grid container columnGap={2}>
            <Button onClick={handleSave} variant="contained" color="inherit" disableElevation sx={{ width: "8rem" }}>Skip</Button>
            <Button onClick={handleSave} variant="contained" color="primary" disableElevation sx={{ width: "8rem" }} disabled={!hasChanges}>Finish</Button>
          </Grid>
        </Grid>
      )}

    </Paper>
  );
};

export default AccountContactCard;