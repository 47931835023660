import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AttachFileOutlined, Close, NotesOutlined } from "@mui/icons-material";
import { Chip, Paper, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useHoverDirty, useToggle } from "react-use";
import { AssignedDocument } from "types";
import { formatShortDate } from "helpers/general-helpers";
import { ConfirmDialog } from "components";
import { deleteDocument } from "store/actions/share-actions";

export interface IUploadItemProps {
  item: AssignedDocument;
}

const UploadItem = ({ item }: IUploadItemProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const itemRef = useRef<any>();
  const isHovered = useHoverDirty(itemRef);
  const [isConfirming, toggleConfirm] = useToggle(false);

  const handleClick = (e: React.MouseEvent) => {

    let target: any = e.target;
    while (target) {
      if (target.tagName === "BUTTON" || target.tagName === "A" || target.tagName === "svg") return;
      target = target.parentNode;
    }

    const itemUrl = `/app/${item.shareId}/documents/${item.id}`;
    navigate(itemUrl);
  };

  const removeItem = async () => {
    toggleConfirm();
    const result = await dispatch(deleteDocument(item.shareId, item.id));
    if((result as any).isOk){
      toast.success("File removed successfully");
    }
  }

  return (
    <Paper ref={itemRef} onClick={handleClick} variant="outlined" sx={{ width: "100%", px: 1, py: 2, cursor: "pointer", filter: isHovered ? "drop-shadow(5px 5px 10px #ccc)" : undefined, transition: 'filter 0.3s', }}>
      <Grid container justifyContent="space-between" flexWrap="nowrap">
        <Grid flexGrow={1} container alignItems="center" columnGap={1} flexWrap="nowrap">
          <Tooltip title={`You uploaded this file on ${formatShortDate(item.createdAt)}`}>
            <AttachFileOutlined fontSize="small" color="inherit"/>
          </Tooltip>
          <Typography variant="body2" color="grey.600" textAlign="center">{item.name}</Typography>
        </Grid>
        <Grid container alignItems="center" columnGap={0.5} flexWrap="nowrap">
          <Chip size="small" label={item.fileExtension} />
          {item.description && (
            <Tooltip title={item.description}>
              <NotesOutlined fontSize="small" color="inherit"/>
            </Tooltip>
          )}
          <Tooltip title="Remove this file">
            <Close fontSize="small" sx={{ color: "grey.400"}} onClick={toggleConfirm} />
          </Tooltip>
        </Grid>
        {isConfirming && <ConfirmDialog open={isConfirming} onConfirm={removeItem} onClose={toggleConfirm} title="Remove this file" message="Are you sure you want to remove this file?" />}
      </Grid>
    </Paper>
  );
};

export default UploadItem;