import { useState } from "react";
import { alpha, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { sameAsFlag } from "helpers/model-helpers";
import { IFormSameAsProps } from "./part-types";
import { useFormValue } from "./part-hooks";
import { useFieldChanged } from "sections/forms/form-editor-context";

const FormSameAsField = ({item, rowIndex, sx}: IFormSameAsProps) => {
    const theme = useTheme();
    const flagId = sameAsFlag(item);
    const [, flagValue] = useFormValue(flagId);
    const [checked, setChecked] = useState(!!flagValue);
    const onFieldChanged = useFieldChanged(flagId, flagId, rowIndex);
    
    if(!item.sameAs) return null;

    const handleToggle = () => {
      handleChange({ target: { checked: !checked } });    
    }
  
    const handleChange = (event: any) => {
      const { checked: boxChecked } = event.target;
      if(boxChecked !== checked) {
        setChecked(boxChecked);
        onFieldChanged(boxChecked);
      }
    };
  
    const boxSx = {
      px: 0.5,
      cursor: "pointer", 
      // display: "flex", 
      alignItems: "center", 
      border: "1px solid", 
      borderRadius: 1, 
      borderColor: checked ? "secondary.dark" : "grey.500", 
      bgcolor: checked ? alpha(theme.palette.secondary.light, 0.25) : "transparent",
      "&:hover": {
        bgcolor: checked ? alpha(theme.palette.secondary.light, 0.25) : "grey.100"
      },
      ...(sx ?? {})   //add any provided sx values
    }
  
    return (
      <Grid container onClick={handleToggle} sx={boxSx} flexWrap="nowrap">
        <Typography variant="caption" sx={{ mr: 1, textWrap: "nowrap" }} fontSize="small">Same as Client 1</Typography>
        <input type="checkbox" checked={checked} onChange={handleChange} />      
      </Grid>
    );
};

export default FormSameAsField; 
