import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStatus } from "redux-action-status";
import { ClientForm, FormConfiguration, FormField, FormInfo, FormRegion, FormSchema } from "types";
import { StatusKeys } from "helpers";
import { LoadingBar } from "components";
import { downloadFormConfig } from "store/actions/share-actions";
import { selectClientForm, selectClientFormTemplates } from "store/selectors/attorney-selectors";
import { selectPetitionerForm } from "store/selectors/petitioner-selectors";
import { selectFormInfo } from "store/selectors/share-selectors";
import { ExportTemplateSchema } from "@/types/schema";

/*====
This Provider / Context will provide core form details (schema, regions, fields) to the children components.
It will work for both Provider and Client perspectives.
====*/

export type IFormContext = {
  form: ClientForm;
  formInfo: FormInfo;
  templates: ExportTemplateSchema[];
  schema: FormSchema;
  regions: FormRegion[];
  fields: FormField[];
  isReady: boolean;
  valueChanged: (field: FormField, value: any, index?: number) => void;
}

export const FormContext = createContext<IFormContext | null>(null);

export const useFormContext = () => {
  const context = useContext(FormContext);
  if(!context) throw new Error("useFormContext must be used within a FormProvider");
  return context;
};

const FormProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  //client id will be present for attorney, shareId will be present for petitioner
  const { formId, documentId } = useParams();
  
  //Get the form, which is retrieved differently, depending on the context
  const cliForm = useSelector<any, any>(state => selectClientForm(state, formId ?? documentId ?? "default"));
  const petForm = useSelector<any, any>(state => selectPetitionerForm(state, formId ?? documentId ??"default"));
  const formInfo = useSelector<any, FormInfo>(state => (selectFormInfo as any)(state, formId ?? documentId));
  const templates = useSelector<any, ExportTemplateSchema[]>(state => (selectClientFormTemplates as any)(state, formId));   //this only applies to attorneys, so only for formId
  const config = useSelector<any, FormConfiguration>(state => state.share.formConfigs[formId ?? documentId ??"default"]);
  const status = useStatus(StatusKeys.formContext);
  const isAttorney = useMemo<boolean>(() => !!cliForm, [cliForm]);
  const form = useMemo<ClientForm>(() => cliForm ?? petForm, [cliForm, petForm]);
  
  const [isReady, setIsReady] = useState(!!form && !!config && !status.isWorking);

  //Load the configuration for the current form
  useEffect(() => {
    async function refreshConfig(){
      await dispatch(downloadFormConfig(form));
      setIsReady(true);
    }

    if(!status.isWorking && !!form && (!config || !!config.isError)) {
      refreshConfig();
    }
  }, [status, form, config, dispatch]);

  const schema = useMemo(() => config?.schema ?? null, [config]);
  
  const regions = useMemo<FormRegion[]>(() => {
    const regionsMap = schema?.layout?.regions ?? null;
    if(!regionsMap) return [];
    const regionArray = Object.keys(regionsMap).map(key => regionsMap[key]);
    //add the regionId to every section in each region
    regionArray.forEach(r => {
      r.sections?.forEach(s => s.regionId = r.id);
    });
    return regionArray;
  }, [schema]);

  const fields = useMemo<FormField[]>(() => {
    if(!config) return [];
    const allFields: FormField[] = config.schema.fields;
    return allFields;
  }, [config]);

  const onValueChanged = useCallback((field: FormField, value: any, index?: number) => {
    if(isAttorney){
      console.log("attorney form value changed", field, value, index);
    }
  }, [isAttorney]);

  // const saveFormValues = useCallback(() => {
  //   if(isAttorney){
  //     console.log("attorney form values saved");
  //   }
  //   else {
  //     console.log("petitioner form values saved");
  //   }
  // }, [isAttorney]);

  const context = {
    form,
    formInfo,
    templates,
    schema,
    regions,
    fields,
    isReady,
    valueChanged: onValueChanged,
  };
  
  return (
    <FormContext.Provider value={context}>
      { !isReady && <LoadingBar message="Loading form..." /> }
      { isReady && (
        children
      )}
    </FormContext.Provider>
  );

};

export default FormProvider;