import { FormField } from "types";

export interface ISelectFieldProps {
  field: FormField;
  dataProps?: any;
  value: any;
}

const SelectField = ({ field, dataProps, value } : ISelectFieldProps ) => {
  const classNames = `form-control form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  if(!field.options) throw new Error("Select field must have options");

  const options = field.options.map(opt => <option key={opt.id} value={opt.id} disabled={opt.isDisabled}>{opt.label}</option>);
  value = value ? value : field.options[0].id;
    
  return (
      <select id={field.id} 
        className={classNames} 
        value={value} 
        {...dataProps} 
        placeholder="choose" 
        onChange={() => {}}
      >
        {options}
      </select>
  )

};

export default SelectField;