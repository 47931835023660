import React, { useState, useEffect } from 'react';
// import clsx from "clsx";
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
// import AcceptedIcon from '@mui/icons-material/CheckCircleOutline';
import CommentIcon from '@mui/icons-material/CommentOutlined';
import { StatusChip, ItemListRow } from 'components';
import { deleteWaitlistItem } from 'store/actions/admin-actions';
import { useStatus } from 'redux-action-status';
import { formatDateString } from 'helpers/general-helpers';
// import UserDialog from '../common/user-dialog';

function InvitationItem({item, onAction}){
  const classes           = buildStyles();
  const status            = useStatus("waitlist");
  const [data, setData]   = useState(item);
  const [isDeleting, setDeleting]   = useState(false);
  
  const dispatch  = useDispatch();

  useEffect(() => {
    async function prepareItem(item){
      let data  = item;
      if(!data.isLoaded && data.ref){
        data  = {...data, isLoaded: true, ...(await data.ref.data())};
      }
      setData(data);
    }
    prepareItem(item);
    return undefined;
  }, [item]);

  const onToggleDelete = () => { setDeleting(!isDeleting); }
  const onDelete  = (e) => { dispatch(deleteWaitlistItem(item.id)); }

  // async function onMenuClick(item, action){
  //   console.log("action " + action + " for item " + item.name);
  //   if(action === "delete"){
  //     onToggleDelete();
  //   }
  //   else if(action === "invite"){
  //     // await onInvite(item);
  //     await onAction("invite", item);
  //   }
  //   else if(action === "view_invitation"){
  //     await onAction("view_invitation", item.invite);
  //   }
  // }

  // function onMenuClosed(){
  //   // setOver(false);
  // }

  function onShowUser(){
    onAction("view_user", item.profile);
  }

  function onShowInvite(){
    onAction("view_invitation", item);
  }

  return (
    <ItemListRow isLoading={!item.isLoaded && item.ref} colSpan={4} itemName={data.name} isDeleting={isDeleting} onCancelDelete={onToggleDelete} onDelete={onDelete} isWorking={status.isWorking}>
      <td>{formatDateString(data.issuedOn, "MMM D, YYYY")}</td>
      <td>
        {data.issuedToEmail && 
          <Tooltip title={data.issuedToEmail}>
            <Button onClick={onShowInvite} color="secondary" className={classes.linkButton}>{data.issuedTo}</Button>
          </Tooltip>
        }
        {!data.issuedToEmail && 
          <Button onClick={onShowInvite} color="secondary" className={classes.linkButton}>{data.issuedTo}</Button>
        }
      </td>
      <td>
        <StatusChip status={data.status}/>
      </td>
      <td title={data.isAccepted ? formatDateString(data.acceptedOn) : ""}>{data.acceptedOn ? formatDateString(data.acceptedOn, "MMM D, YYYY") : ""}</td>
      <td title={data.profile?.uid}>
        {data.isAccepted && <Button onClick={onShowUser} color="secondary" className={classes.linkButton}>{data.profile?.displayName}</Button>}
      </td>
      <td>
        {data.message && <Tooltip title={data.message}><CommentIcon fontSize="small" color="disabled" className={classes.messageIcon}/></Tooltip>}
      </td>
      {/* TODO: can have a message on the invite... show it here.  Icon with a tooltip for the message */}
      {/* <td className="moreCol">            
        <ItemMenu item={item} options={menuOptions[item.status]} status={status} onMenuClick={onMenuClick} onMenuClosed={onMenuClosed} />            
      </td>     */}
    </ItemListRow>
  );
}

export default InvitationItem;

const buildStyles   = makeStyles(theme => ({
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : 14,
    fontWeight        : "400",
    padding           : "2px 6px",
    justifyContent    : "flex-start",
  },
  accepted  : {
    color       : theme.palette.primary.main,
    fontWeight  : 600,
  },
  pending  : {
    // color       : theme.palette.primary.main,
    fontWeight  : 600,
  },
  messageIcon: {
    height: 18,
    width: 18,
    color        : `${theme.palette.grey}88`, //`
  }
}));

// const menuOptions   = {
//   waiting   : [
//     {id: 0, label: "Invite User", action: "invite" },
//     {id: 10, label: "Remove from Waitlist", action: "delete" },
//   ],
//   invited   : [
//     {id: 0, label: "View invitation", action: "view_invitation" },
//     {id: 10, label: "Delete Invitation", action: "delete" },
//   ],
//   accepted   : [
//     {id: 0, label: "View invitation", action: "view_invitation" },
//     {id: 10, label: "View user", action: "view_user" },
//   ]
// };