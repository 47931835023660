import { FormField } from "types";
import SwitchField from "./switch-field";
import { Checkbox, FormControlLabel } from "@mui/material";

export interface IBooleanFieldProps {
  field: FormField;
  dataProps?: any;
  value: any;
  showLabel?: boolean;
  onChangeOverride?: (event: any) => void;
}

const BooleanField = ({ field, dataProps, value, showLabel, onChangeOverride }: IBooleanFieldProps) => {

  if(field.subtype === "switch" || field.subtype === "toggle"){
    return <SwitchField field={field} dataProps={dataProps} value={value} />;
  }

  //subLabel, isNegative aren't on current schema, as they're legacy
  const { id, subLabel = null, isNegative = false } = (field  as any);
  const defaultValue = field.defaultValue === undefined ? false : !!field.defaultValue;
  const currValue = value === undefined ? defaultValue : value;
  const cbValue = currValue; //!!value; //field.isNegative ? !!!value : !!value;
  const cbSize = field.size === "md" ? "medium" : "small";
  const classNames = `field-${field.size ? field.size : "sm"} field-checkbox`;
  const cbLabel = showLabel === true ? field.label || subLabel || null : null;
  const negProp = isNegative ? { "data-isnegative": true } : null;
  const inpProps = { ...dataProps, ...negProp };

  return (
    <FormControlLabel 
      label={cbLabel} 
      className="field-checkbox-label" 
      control={
        <Checkbox 
          id={id} 
          size={cbSize} 
          className={classNames} 
          checked={cbValue} 
          onChange={onChangeOverride} 
          inputProps={inpProps} 
        />
      } 
    />
  );
}

export default BooleanField; 
