import _ from 'lodash';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Box, alpha, Grid, Tooltip, Typography } from '@mui/material';
import { AccountCircleOutlined, CheckBox, CheckBoxOutlineBlank, ContentCopy } from '@mui/icons-material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { toast } from 'react-toastify';
import { useFieldComments } from './conversation-context';
import numberHelper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { IfBlock } from 'components/index';
import { sameAs } from 'helpers/model-helpers';
import SameAsChip from './same-as-chip';
import DataTableCommentTooltip from './data-table-comment-tooltip';

const ttPropsB   = {placement: "left", enterDelay: 750};
const valueColProps = { 
  maxWidth: 170, 
  width: "100%", 
  "&:hover": { 
    "& .MuiSvgIcon-root": {
      display: "inline",
    }
  }
};

export function DataTableField({field, values}){
  const classes   = buildStyles();
  const isPField  = Boolean(field.params && field.params.length > 0);
  let rawValue     = isPField ? getParamValue(field.params[0], values) : getFieldValue(field, values);

  let value       = rawValue;
  let fieldProps = { 
    "&:hover": { 
      "& .MuiSvgIcon-root": {
        display: "inline",
      }
  }};

  const handleCopyValue = (e) => {
    e.stopPropagation();
    const value = (rawValue === undefined || rawValue === null) ? "- not set -" : rawValue;
    navigator.clipboard.writeText(value);
    toast.info(`Value copied to the clipboard`, { autoClose: 1500 })
  };

  if(field.type === "boolean" || field.type === "checkbox"){
    if(field.subtype === "switch"){
      value = value ? "YES" : "NO";
    } 
    else {
      rawValue = value ? "yes" : "no";
      value = <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{value ? <CheckBox fontSize="small" sx={{ color: "grey.600" }}/> : <CheckBoxOutlineBlank fontSize="small" sx={{ color: "grey.600" }}/>}</Box>;
      fieldProps = { ...fieldProps, justifyContent: "flex-end" };
    }
  }
  
  const hasValue  = !!rawValue;
  const { hasComments, isResolved }   = useFieldComments(field.id, true);
  
  return (
    <Grid container className={classes.fieldLabel} flexWrap="nowrap" data-type="field-label">
      <DataTableCommentTooltip>
        <Grid item container flexGrow={1} className={clsx({"bold": hasValue})}>
          <FieldLabel item={field} values={values} hasValue={hasValue} />
          {/* {prepareLabel(field)} */}
        </Grid>
      </DataTableCommentTooltip>
      
      <Grid item container justifyContent="space-between" sx={valueColProps}>
        <Tooltip title={hasValue ? "click to copy value" : ""} {...ttPropsB}>
          <Grid item container sx={fieldProps} xs={10} onClick={handleCopyValue} flexWrap="nowrap" justifyContent="flex-end">
            <SameAsChip item={field} values={values} />
            {value}
            {hasValue && <ContentCopy sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", marginLeft: 1, display: "none" }} />}
          </Grid>
        </Tooltip>
        <Grid item xs={2}>
          {hasComments && !isResolved && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
        </Grid>
      </Grid>
    </Grid>
  );
}

export function DataTableMultiField({field, values}){
  const children  = field.params ? field.params : field.children;
  const classes   = buildStyles();
  const vField    = getFieldValue(field, values);
  const hasFValue = !!vField;
  const p0        = children[0];
  const v0        = getParamValue(p0, values);
  const hasValue  = !!v0;
  const fType     = p0.type;
  const { hasComments }   = useFieldComments(field.id);

  const handleCopyValue = (value) => (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(value);
    toast.info(`Value copied to the clipboard`, { autoClose: 1500 })
  };

  if(p0.label){
    //Since the first parameter has a label, need to break it out onto it's own line
    return (
      <Grid container className={classes.singleLineParamLabel} data-type="single-line-param-label">
        <DataTableCommentTooltip>
          <Grid container>
            <Grid item className={clsx(classes.labelCol, {"bold": hasValue})}>
              <FieldLabel item={field} values={values}  hasValue={hasValue} />
              <IfBlock condition={!hasFValue && hasComments}>
                <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>
              </IfBlock>
            </Grid>
            <IfBlock condition={hasFValue}>
              <Tooltip title="click to copy value" {...ttPropsB}>
                <Grid item onClick={handleCopyValue(vField)} className={clsx(classes[field.type])} sx={valueColProps}>
                  {vField}
                  <ContentCopy sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", marginLeft: 1, display: "none" }} />
                  {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
                </Grid>
              </Tooltip>
            </IfBlock>
            <Grid container className={classes.paramFields}>
              {_.map(children, p => <ChildField key={p.id} param={p} values={values} classes={classes} />)}
            </Grid>
          </Grid>
        </DataTableCommentTooltip>
      </Grid>
    );
  }
  else{
    //No label for the first parameter, so the value goes on the first line with the label from the field
    const otherChildren   = [...children.slice(1)];
    return (
      <Grid container className={classes.multiLineParamLabel} data-type="multi-line-param-label">
        <DataTableCommentTooltip>
          <Grid item className={clsx(classes.labelCol, {"bold": hasValue})}>
          {/* {prepareLabel(field)} */}
            <FieldLabel item={field} values={values}  hasValue={hasValue} />
          </Grid>
        </DataTableCommentTooltip>
        <Tooltip title={!!v0 ? "click to copy value" : ""} {...ttPropsB}>
          <Grid item onClick={handleCopyValue(v0)} className={clsx(classes[fType])} sx={valueColProps}>
            {v0}
            <ContentCopy sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", marginLeft: 1, display: "none" }} />
            {hasComments && <ChatBubbleIcon color="secondary" className={classes.commentIcon}/>}
          </Grid>
        </Tooltip>
        <Grid container className={classes.paramFields}>
          {_.map(otherChildren, p => <ChildField key={p.id} param={p} values={values} classes={classes} />)}
        </Grid>
      </Grid>
    );
  }
}

const buildStyles   = makeStyles(theme => ({
  labelCol  : {
    flexGrow  : 1,
  },  
  commentIcon   : {
    alignSelf   : "flex-end",
    float       : "right",
    fontSize    : 14,
    verticalAlign : "center",
    marginRight   : theme.spacing(1),
    color         : theme.palette.secondary.main, //theme.palette.grey[600],
  },
  paramFields   : {
    marginTop       : theme.spacing(0.25),
  },
  parameterField  : {
    paddingLeft     : theme.spacing(1.5),
  },
  hover: {
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, 0.05),
      "& .MuiSvgIcon-root": {
        display: "inline",
      },
    },
  }, 
}));

function ChildField({param, values, classes}){
  //Check to see if this parameter should be displayed
  if(param.condition && !fHelper.isConditionMet(param.condition, values)) return null;
  const vField    = getParamValue(param, values);
  
  const handleCopyValue = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(vField);
    toast.info(`Value copied to the clipboard`, { autoClose: 1500 })
  };

  // const fType     = param.type;
  return ( param.label && 
    <Grid container className={classes.parameterField} data-type="child-field-param">
      <Grid item className={clsx(classes.labelCol)}>
        {/* {prepareLabel(param)} */}
        <FieldLabel item={param} values={values} />
      </Grid>
      <Tooltip title={!!vField ? "click to copy value" : ""} {...ttPropsB}>
        <Grid item container sx={valueColProps} onClick={handleCopyValue}>
          {vField}
          {!!vField && <ContentCopy sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", marginLeft: 1, display: "none" }} />}
        </Grid>
      </Tooltip>
    </Grid>
  );
}

function getParamValue(p, values){

  if(p.options){
    const v   = values[p.id]?.toString().toUpperCase();
    const opt = _.find(p.options, o => o.id.toString().toUpperCase() === v);
    return opt ? opt.label : "";
  }
  else{
    let value =  values[p.id];
    if(p.type === "currency") value = numberHelper.asCurrency(value);
    else if(p.type === "checkbox"){
      if(Boolean(value)) return "yes";
      // else return "no";
    }
    return value;
  }
}

function getFieldValue(field, values){
  const myType  = field.type;
  let value   = values[field.id];

  const [isSameAs, , sameAsValue] = sameAs(field, values);
  value = isSameAs ? sameAsValue : value;
  
  if(field.options){
    let cVal = value;
    if(typeof field.options[0].id === "number" && typeof value === "string") cVal = parseInt(value);
    const oVal  = _.find(field.options, o => o.id === cVal);
    return oVal ? oVal.label : ""; //field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    return numberHelper.asCurrency(value);
  }
  else{
    return value;
  }
}

function prepareLabel(item, values){
  let result  = item.label;

  if(result && result.indexOf("{") >= 0){
    result  = result.substr(0, item.label.indexOf("{"));
  }

  //If this is an other, need to check for a user-provided label associated with it
  if(item.isOther && values){
    const othKey    = `${item.id}Label`;  //`
    const othLabel  = values[othKey];
    if(othLabel){
      result  = `${result}: ${othLabel}`;   //`
    }
  }

  return result;
}

const FieldLabel = ({item, values, hasValue}) => {
  let label  = item.label;

  if(label && label.indexOf("{") >= 0){
    label  = label.substr(0, item.label.indexOf("{"));
  }

  //If this is an other, need to check for a user-provided label associated with it
  if(item.isOther && values){
    const othKey    = `${item.id}Label`;  //`
    const othLabel  = values[othKey];
    if(othLabel){
      label  = `${label}: ${othLabel}`;   //`
    }
  }

  const isAttorney = (item.isAttorneyOnly === true);

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Typography component="span" variant="body2" fontSize="0.9rem" color={isAttorney ? "secondary" : "#000000cc"} fontWeight={hasValue ? "700" : "400" } fontStyle={isAttorney ? "italic" : "none" }>
        {label}
      </Typography>
      {isAttorney && (
        <Tooltip title="This field is not visible to the client, and must be filled by an attorney" placement="top">
          <AccountCircleOutlined fontSize="1rem" sx={{ color: "grey.600", marginLeft: 1 }} />
        </Tooltip>
      )}
    </Box>
  );
};