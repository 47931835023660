import React from 'react';
import { Helmet } from 'react-helmet';
import { Route, Routes } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import makeStyles from '@mui/styles/makeStyles';

import TermsOfService from './terms-of-service';

export default function LegalPage(){
  const classes   = buildStyles();

  return (
    <Grid className={classes.root}>
      <Helmet>
        <title>FormHop Legal</title>
      </Helmet>
      <Routes>
        <Route path="/terms-of-service" element={<TermsOfService />} />
      </Routes>
    </Grid>
  );
}

const buildStyles   = makeStyles(theme => ({
  root  : {
    padding     : theme.spacing(2),
  }
}));