import { useNavigate } from "react-router-dom";
import { Divider, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Tooltip } from "@mui/material";
import { CheckCircle, CheckCircleOutline, Close, MoreVert } from "@mui/icons-material";
import { useAnchor } from "helpers/hooks";
import { formatShortDate } from "helpers/general-helpers";
import { useFormStatus, usePetitionerForm } from "../../form-hooks";

const RegionMenu = () => {
  const navigate = useNavigate();
  const { submitForm } = usePetitionerForm();
  const formStatus = useFormStatus();
  const [anchorEl, isOpen, onOpen, onClose, position]   = useAnchor("bottomRight");

  return (
    <>
      {/* {formStatus.isSubmitted && (
        <Tooltip title="(Submitted) Form Options">
          <IconButton size="small" sx={{ color: "primary.main" }} onClick={onOpen} aria-haspopup="true" color="primary" >
            <CheckCircleOutline fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      {!formStatus.isSubmitted && ( */}
        <Tooltip title="Form Options">
          <IconButton size="small" sx={{ color: "grey.600" }} onClick={onOpen} aria-haspopup="true" color="primary" >
            <MoreVert fontSize="small" />
          </IconButton>
        </Tooltip>
      {/* )} */}

      <Popover open={isOpen} anchorEl={anchorEl} onClose={onClose} {...position}>
        <MenuList>
          
          {formStatus.isSubmitted && (
            <Tooltip title={`You submitted this form on ${formatShortDate(formStatus.submittedOn ?? formStatus.updatedOn)}`} placement="top">
              <MenuItem>
                <ListItemIcon>
                  <CheckCircle fontSize="small" color="primary" />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: "primary" }}>Submitted</ListItemText>
              </MenuItem>
            </Tooltip>
          )}
          {!formStatus.isSubmitted && (
            <MenuItem onClick={submitForm} >
              <ListItemIcon>
                <CheckCircleOutline fontSize="small"/>
              </ListItemIcon>
              <ListItemText>Submit</ListItemText>
            </MenuItem>
          )}

          <Divider />

          <MenuItem onClick={() => navigate("/app")}>
            <ListItemIcon>
              <Close fontSize="small"/>
            </ListItemIcon>
            <ListItemText>Save & Close</ListItemText>
          </MenuItem>

        </MenuList>
      </Popover>
    </>
  );
};

export default RegionMenu;