import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Tab, Tabs, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Close as CloseIcon, ErrorOutline as ErrorOutlineIcon, Gavel, Work } from '@mui/icons-material';
import { useStatus } from "redux-action-status";
import { selectAllForms } from "store/selectors/attorney-selectors";
import { assignFormsApi } from "store/actions/attorney-actions";
import { parseFbResult } from "store/store-helpers";
import { useClient } from "../../client-context";
import { useShareContext } from "sections/app/share-context";
import { AssignableForm } from "./assignable-form-types";
import AssignableFormList from "./assignable-form-list";
import AssignedFormProps from "./assigned-form-props";

interface IAssignFormDialogProps {
  open: boolean;
  onClose: (shouldRefresh?: boolean) => void;
}


const AssignFormDialog = ({open, onClose}: IAssignFormDialogProps) => {
  const dispatch = useDispatch();
  const status = useStatus("shares");
  const { clientId, currentClient: client } = useClient();
  const { share } = useShareContext();
  
  const [tabIndex, setTabIndex] = useState<number | undefined>(0);    //0: My Forms, 1: State Forms
  
  const allForms = useSelector(selectAllForms);
  const alreadyAssigned = useMemo(() => share!.forms, [share]);
  
  //All the forms that are available, and not current assigned, as AssignableForm objects
  const [assignable, setAssignable] = useState<AssignableForm[]>([]);

  //The forms that are currently displayed based on the filter
  const filteredItems = useMemo<AssignableForm[]>(() => {
    const tabFilter = tabIndex === 0 ? "My" : "CO";
    const filtered = assignable.filter(form => form.formGroup === tabFilter);
    return filtered;
  }, [assignable, tabIndex, ]);
  
  const checkedCount = useMemo(() => assignable.filter(form => form.isChecked).length, [assignable]);
  const selectedItem = useMemo(() => filteredItems.find(form => form.isSelected), [filteredItems]);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if(!allForms || !alreadyAssigned){
      setAssignable([]);
    }
    else{
      const unassigned = allForms.filter(form => !alreadyAssigned.find(f => f.formId === form.id));
      const assignable = unassigned.map(form => ({...form, isChecked: false, isSelected: false}));
      setAssignable(assignable);
    }
  }, [allForms, alreadyAssigned]);

  const clearFields = () => {
    const cleared = assignable.map(form => ({...form, isChecked: false, isSelected: false, dueOn: "", notes: ""}));
    setAssignable(cleared);
    setError("");
  }

  const onCancel = () => {
    clearFields();
    onClose();
  }

  const onSave = async () => {
    const checked = assignable?.filter(form => form.isChecked) ?? [];
    if(!checked || checked.length === 0){
      setError("Please select at least one form to assign");
      return;
    }
    setError("");
    
    const result = await dispatch(assignFormsApi(clientId!, checked));
    console.log("save form result", result);
    const pr = parseFbResult(result);
    if(pr.isOk){
      clearFields();
      onClose(true);
    }
    else setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
  }

  const handleSelectForm = (formId: string) => {
    const updated = assignable.map(form => {
      if(form.id === formId) {
        return { ...form, isSelected: !form.isSelected };
      }
      else if(form.isSelected) return { ...form, isSelected: false };
      return form;
    });
    setAssignable(updated);
  }

  const handleCheckForm = (formId: string) => {
    const updated = assignable.map(form => {
      if(form.id === formId) return { ...form, isChecked: !form.isChecked, isSelected: true };
      else if(form.isSelected) return { ...form, isSelected: false };
      return form;
    });
    setAssignable(updated);
  }; 

  const handleChange = (formId: string) => (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    const updated = assignable.map(f => {
      if(f.id === formId) return { ...f, [key]: val };
      return f;
    });
    setAssignable(updated);
  }

  if(!clientId){
    console.error("could not open assign form dialog: no client id", { share, client });
    return null;
  } 

  return(
    <Dialog open={open} onClose={onCancel} aria-labelledby="assign-dialog-title" fullWidth maxWidth="lg">
        
      <DialogTitle id="assign-dialog-title">
        <Grid container justifyContent="space-between" alignItems="center">
          Add documents for {client?.profile?.displayName}
          <IconButton onClick={onCancel} color="primary" disabled={status.isWorking}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      
      <DialogContent>
        {error && (
          <Alert icon={<ErrorOutlineIcon fontSize="inherit" />} severity="error">
            {error}
          </Alert>
        )}

        <Grid container alignContent="flex-start" gap={2} sx={{ height: "60vh", }}>
          <Grid container xs={12}>
            <Box sx={{ width: "100%" }}>
              <Tabs value={tabIndex ?? 0} onChange={(_, index) => setTabIndex(index)} aria-label="Client Tabs" role="navigation">
                <Tab label="My Forms" icon={<Work fontSize="small" />} iconPosition="start" />
                <Tab label="Colorado Forms" icon={<Gavel fontSize="small" />} iconPosition="start" />
              </Tabs>
            </Box>
          </Grid>

          <Grid container xs={12} sx={{ border: "1px solid", borderColor: "grey.200", height: "50vh" }}>
            <Grid xs={7} direction="column">
              <AssignableFormList items={filteredItems} onChecked={handleCheckForm} onSelected={handleSelectForm} />
            </Grid>          
            <Grid xs={5} p={2} sx={{ backgroundColor: "grey.200" }}>
              {selectedItem && selectedItem.isChecked && <AssignedFormProps item={selectedItem} onChange={handleChange(selectedItem.id)} /> }
              {selectedItem && !selectedItem.isChecked && <Typography variant="body2" fontStyle="italic" color="grey.600" p={1}>Check the box to assign this form</Typography> }
            </Grid>

          </Grid>
        </Grid>
      </DialogContent>

      <Grid container justifyContent="space-between" sx={{ px: 2, py: 1 }}>
        <Grid container xs={6}>
          <Typography variant="caption" color="secondary">{checkedCount} Forms selected</Typography>
        </Grid>
        <Grid container xs={6} justifyContent="flex-end">
          <Button onClick={onCancel} disabled={status.isWorking} color="primary">Cancel</Button>
          <Button onClick={onSave} color="primary" disabled={checkedCount <= 0 || status.isWorking}>Save</Button>
        </Grid>
      </Grid>
    
    </Dialog>
  );
};

export default AssignFormDialog;