import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { AttachFile, CheckCircle, Close, CloudUpload, ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { MuiFileInput } from "mui-file-input";
import { useStatus } from "redux-action-status";
import { ClientDocument, ClientDocumentUpload } from "types";
import { StatusKeys } from "helpers";
import ShareDocumentToggle from "../share-document-toggle";
import { addClientDocument, updateClientDocument } from "store/actions/attorney-actions";
import { parseFbResult } from "store/store-helpers";
import { updateDocument } from "store/actions/share-actions";

export interface IUploadDocumentProps {
  open: boolean;
  onClose: (uploadedCount: number) => void;
  toEdit?: ClientDocument;
}

const defaultDoc = {name: "", category: "", description: "", isSharedWithClient: false, file: null, dueAt: null};
const initialProps = (doc?: ClientDocument) => {
  if(!doc) return defaultDoc;
  return {
    name: doc.name,
    category: doc.category,
    description: doc.description,
    isSharedWithClient: doc.isSharedWithClient ?? false,
    file: null,
    dueAt: doc.dueAt ?? null,
  };
}

const UploadDocumentDialog = ({open, onClose, toEdit}: IUploadDocumentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { clientId, shareId } = useParams<{clientId: string, shareId: string}>();

  const [props, setProps] = useState<ClientDocumentUpload>(initialProps(toEdit));
  const isEdit = useMemo(() => toEdit !== undefined, [toEdit]);
  const dueDate = useMemo(() => props.dueAt ? moment(props.dueAt) : null, [props.dueAt]);
  const [error, setError]   = useState<string | null>(null);
  const isValid = useMemo(() => props.name.length > 0 && (isEdit || !!props.file), [props, isEdit]);
  const status = useStatus(StatusKeys.clientDocuments);
  const filePlaceholder = props.file ? props.file.name : "Click to upload document";
  const isClientUpload = toEdit?.direction === "fromClient";

  const minDueDate = useMemo(() =>{
    if(toEdit?.dueAt){
      const mmt = moment().startOf("day").valueOf();
      if(toEdit.dueAt < mmt) return moment(toEdit.dueAt).startOf("day");
      else return moment().startOf("day");
    }
    else{
      return moment().startOf("day");
    }
  }, [toEdit?.dueAt]);
  
  
  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleDateChange = (value: Moment | null) => {
    let numVal: number | undefined | null = value?.valueOf();
    if(!numVal || isNaN(numVal)) numVal = null;
    setProps({...props, dueAt: numVal});
  }

  const handleToggleChange = (value: boolean) => {
    setProps({...props, isSharedWithClient: value});
  }
  
  const handleFileChange = (e: File | null) => {
    console.log("file change", e);
    setProps({...props, file: e});
  }

  const clearFields = () => {
    setProps({name: "", category: "", description: "", isSharedWithClient: false, file: null, dueAt: null });
    setError("");
  }
  
  const onCancel = () => {
    clearFields();
    onClose(0);
  }

  const onSave = async () => {
    if(isEdit) await onEditDocument();
    else await onAddDocument();    
  };

  const onAddDocument = async () => {
    console.log("Add Document", props);
    if(!props.name || !props.file || !clientId) return;

    const result = await dispatch(addClientDocument(clientId, props));
    console.log("add document result", result);
    const pr = parseFbResult(result);

    if(pr.isOk){
      clearFields();
      onClose(1);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  };

  const onEditDocument = async () => {
    if(!toEdit || !props.name || (!clientId && !shareId)) return;

    let result: any = null;
    if(isClientUpload){
      if(!shareId) { console.error("no share id for editing client upload"); return; }
      result = await dispatch(updateDocument(shareId, toEdit.id, props));
    }
    else{
      //TODO: when there's time to test, remove this and use the updateDocument for the share above
      if(!clientId) { console.error("no client id for editing client upload"); return; }
      result = await dispatch(updateClientDocument(clientId, toEdit.id, props));
    }
    console.log("edit document result", result);
    const pr = parseFbResult(result);

    if(pr.isOk){
      clearFields();
      onClose(1);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(0)} fullWidth maxWidth="sm">
      <DialogTitle>
        <AttachFile fontSize="small" />
        {isEdit ? "Edit" : "Upload"} Document
      </DialogTitle>

      <DialogContent>
        {!isEdit && (
          <Typography variant="body2" color="textSecondary" mb={2}>
            Attach a document to the client's account. If you don't share the document now, you can choose to share it with the client later.
          </Typography>
        )}
        
        {error && (
          <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error" sx={{ mb: 2 }}>
            {error}
          </Alert>
        )}

        <Grid container pt={1}>
          <Stack gap={2} width="100%" alignItems="center" justifyContent="center">
            
            {!isEdit && (
              <MuiFileInput autoFocus={!toEdit} value={props.file} onChange={handleFileChange} disabled={status.isWorking} placeholder={filePlaceholder}  sx={{ width: "100%", mt: 1, bgcolor: props.file ? alpha(theme.palette.secondary.light, 0.3) : undefined }}
                clearIconButtonProps={{ children: <Close fontSize="small" /> }}
                InputProps={{ startAdornment: props.file ? <CheckCircle color="secondary" /> : <CloudUpload color="primary"/> }} 
              />
            )}

            {!isClientUpload && (
              <Grid container alignItems="center" width="100%" flexWrap="nowrap">
                <Grid xs={6}>
                  <DatePicker label="Due Date" value={dueDate} minDate={minDueDate} onChange={handleDateChange} onError={(dateError) => setError(dateError)}
                    slotProps={{
                      textField: {
                        helperText: error,
                      },
                    }}
                  />
                </Grid>
                <Grid xs={6} container justifyContent="flex-end" height="63px">
                    <ShareDocumentToggle isShared={props.isSharedWithClient ?? false} onChanged={handleToggleChange} disabled={status.isWorking} />
                </Grid>
              </Grid>
            )}

            <TextField autoFocus={!!toEdit} value={props.name} onChange={handleChange} disabled={status.isWorking} margin="dense" id="name" label="Name" required type="text" fullWidth />
            {/* <TextField value={props.category} onChange={handleChange}  disabled={status.isWorking} margin="dense" id="category" label="Category" type="text" fullWidth/> */}
            <TextField value={props.description} onChange={handleChange}  disabled={status.isWorking} margin="dense" id="description" label="Description" type="text" fullWidth multiline rows={4}/>            
            
          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={status.isWorking}>Cancel</Button>
        <Button onClick={onSave} disabled={!isValid || status.isWorking} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadDocumentDialog;