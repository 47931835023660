import { useEffect, useMemo, useRef, useState } from "react";
import { useStatus } from "redux-action-status";
import { Button, Stack, SxProps, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { StatusKeys } from "helpers";
import CommentItem from "./comment";
import { Comment } from "types";
import { useToggle } from "react-use";

export interface ICommentHistoryProps {
  comments: Comment[];
  sx?: SxProps;
}

const CommentHistory = ({ comments, sx }: ICommentHistoryProps) => {
  const containerRef = useRef<any>(null);
  const [count, setCount] = useState(0);
  const status = useStatus(StatusKeys.conversations);
  const [showResolved, toggleResolved] = useToggle(false);
  const hasResolved = useMemo(() => comments.some(c => c.resolvedAt), [comments]);

  const visibleComments = useMemo(() => {
    return showResolved ? comments : comments.filter(c => !c.resolvedAt);
  }, [comments, showResolved]);

  const toggleMessage = showResolved ? "Hide resolved comments" : "Show resolved comments";

  //Keep the grid scrolled to the bottom
  useEffect(() => {
    if(containerRef.current && comments.length > count){
      const scrollHeight = containerRef.current.scrollHeight;
      containerRef.current.scrollTop = scrollHeight;
      setCount(comments.length);
    }
  }, [containerRef, count, comments]);

  return (
    <Stack id="comment-history" ref={containerRef} spacing={1} sx={{ overflowY: "auto", minHeight: 175, width: "100%", p: 1, ...sx }}>
      {hasResolved && (
        <Grid container width="100%" py={0.5} px={1} sx={{ bgcolor: "grey.300" }}>
          <Button onClick={toggleResolved} size="small" color="secondary" sx={{ textTransform: "none", fontStyle: "italic", fontWeight: "500" }}>{toggleMessage}</Button>
        </Grid>
      )}

      {visibleComments.map((comment, idx) => (
        <CommentItem key={idx} item={comment} />
      ))}
      {visibleComments?.length === 0 && (
        <Typography variant="body2" textAlign="center" color="grey.500" fontStyle="italic">Add a comment to begin the conversation</Typography>
      )}
    </Stack>
  );
};

export default CommentHistory;