import helper from 'helpers/number-helper';
import fHelper from 'helpers/financials-helper';
import { Box, Typography } from '@mui/material';
import { FormField, FormSection } from 'types';

export interface ISubtotalRowProps {
  label: string;
  data: any;
  section: FormSection;
  fields: FormField[];
}

export const SubtotalRow = ({label, data, section, fields}: ISubtotalRowProps) => {
  const total     = fHelper.calculateSectionTotal(section, data, undefined, fields);
  const totalStr  = helper.asCurrency(total, "$0");

  return (
    <tr>
      <td colSpan={2}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
          <Typography fontSize="0.9rem" fontWeight="700">{total && label ? label : ""}</Typography>
        </Box>
      </td>
      <td colSpan={1} className="field-currency">
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 1, mr: 1 }}>
          <Typography fontSize="0.9rem" fontWeight="700">{totalStr}</Typography>
        </Box>
        {/* <input type="text" className="form-control" disabled value={totalStr}></input> */}
      </td>
    </tr>
  )
};