import { ReactNode } from "react";
import { Badge, Box } from "@mui/material";

export interface IBadgeWrapperProps {
  show: boolean;
  color?: "primary" | "secondary" | "error" | "info" | "success" | "warning";
  variant?: "standard" | "dot";
  content?: string;
  children: ReactNode;

}

const BadgeWrapper = ({ show, color, variant, content, children }: IBadgeWrapperProps) => {
  return show ? 
    <Badge badgeContent={content} variant={variant} color={color} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >{children}</Badge> : 
    <Box color={color}>{children}</Box>;
};

export default BadgeWrapper;