import React from 'react';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LandingHeader from './landing-header';
import SecurityIcon from '@mui/icons-material/Security';
import FaceIcon from '@mui/icons-material/Face';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import LandingFooter from './landing-footer';

//Sharon McCutcheon
//@sharonmccutcheon
import ppwk from './paperwork-lg.jpg';


function LearnMorePage(){

  const classes   = buildStyles({bg1: ppwk});

  return (
    <Grid container className={classes.root} direction="column" alignItems="flex-start" justifyContent="space-between">
      <LandingHeader title="FormHop - Learn More" />

      <Grid item className={classes.contents} container direction="column" alignItems="stretch">

        <Grid item className={classes.topRow} container direction="row" alignItems="flex-start">
          <Grid item xs={5} className={classes.textCard} container direction="row">
            <h3>We know<br/>We've been there</h3>
            <p>You're emotionally exhausted, confused and frustrated.  Amicable or not, divorce is horrible.  And on top of it all, you're buried in paperwork.</p>
            <p>That's where we come in.  While we can't do anything about the emotional pain, we <span className={classes.italic}>can</span> help with the paperwork.</p>
            <p>FormHop has developed a secure, intuitive application that makes it easy to complete your Sworn Financial Statements.</p>
          </Grid>
        </Grid>   

        <Grid item className={classes.secondRow} container direction="row" alignItems="flex-start">
          <Grid item container direction="row" justifyContent="center">
            <Grid item xs={12} container justifyContent="center">
              <h4 className={classes.mx0}>So we decided to do something about it</h4>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <h5 className={classes.mx0}>You have more important things to deal with, so we'll simplify the paperwork for you.</h5>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="center" alignItems="flex-start" style={{marginTop: "25px"}}>
            {featureConfig.map(fc => <FeatureCard key={fc.id} config={fc} classes={classes}></FeatureCard>)}
          </Grid>
        </Grid>

        <Grid item className={classes.secondRow} container direction="row" alignItems="flex-start">
          <Grid item container direction="column" justifyContent="center" alignItems="center">
            <h4 className={classes.mx0}>And we'd love for you to give it a try</h4>
            <h5 className={classes.mx0}>There's no obligation, it's free to try, so go ahead, check another thing off your list.</h5>
          </Grid>
          <Grid item container direction="row" justifyContent="center" alignItems="flex-start" style={{marginTop: "25px", marginBottom: "100px"}}>
          <Button className={classes.blockButton} component={Link} to="/app" color="secondary" variant="contained">GET STARTED</Button>
          </Grid>
        </Grid>

      </Grid>  
      <LandingFooter />    

    </Grid>
  )
}

export default LearnMorePage;

const buildStyles = makeStyles(theme => ({
  root  : {
    width     : "100%",   
    "& h3"        : {
      fontSize    : "1.3em",
    }, 
    "& h4"        : {
      fontSize    : "1.5em",
      fontWeight  : 400,
    },
    "& h5"        : {
      fontSize    : "1.3em",
      fontWeight  : 300,
    },
  },
  contents  : {
    paddingTop  : 60,
    // overflowX   : "hidden",
    minHeight   : "75vh",
  },
  topRow   : props => ({
    height      : "100%",
    backgroundImage     : `url(${props.bg1})`,
    backgroundSize      : "cover",
    backgroundPosition  : "0%",
    width               : "100%",
    opacity             : "0.8",
  }),
  italic  : {
    fontStyle   : "italic",
  },
  textCard    : {
    margin        : theme.spacing(3),
    padding       : theme.spacing(2),
    background    : "#ffffff55",
    color         : "#000",
    borderRadius  : "4px",
    "& h3"        : {
      marginTop   : 0,
      // fontSize    : "1.3em",
    },
    "& p"         : {
      fontSize    : "1.1em",
    }
  },
  secondRow       : {
    marginTop     : "50px",
  },
  featureCard     : {
    borderRadius  : 5,
    minHeight     : 200,
    margin        : theme.spacing(3),
    padding       : theme.spacing(1),
    "& h3"        : {
      fontSize    : "1em",
      fontWeight  : 400,
    },
    "&:hover"    : {
      borderColor     : theme.palette.primary.dark,
      borderThickness : 3,
      // background      : `${theme.palette.primary.light}44`,
      "& h2"  : {
        color     : theme.palette.primary.main,
      }
    }
  },
  featureTitle    : {
    marginTop     : theme.spacing(2),
  },
  featureBody     : {
    padding       : theme.spacing(3),
  },
  title     : {
    marginBottom  : 0,
    marginTop     : "-10px",
  },
  mx0   : {
    marginTop     : 0,
    marginBottom  : 0,
  }
}));


const featureConfig   = [
  {
    id      : 0,
    icon    : <SecurityIcon/>,
    title   : "Secure",
    subTitle  : "FormHop was built with security in mind",
    body    : "Your data never leaves your computer unless you want it to. We understand how sensitive your financial information is.  Your data is only ever stored on your computer, and will only be shared with us if you choose to export your financials at the end.  At that point, we do not store it, we only use it to merge into the Sworn Financials template.  We never store it on our servers, or anywhere beside your local computer."
  },
  {
    id      : 10,
    icon    : <FaceIcon/>,
    title   : "Simple",
    subTitle  : "FormHop was built with you in mind",
    body    : "Think of it as Turbo Tax for your Sworn Financials. We've been through this process, and understand how complicated and confusing the paperwork is.  So we used our 20 years of experience to build a simple user experience that guides you through the process."
  },
  {
    id      : 20,
    icon    : <HourglassEmptyIcon/>,
    title   : "Fast",
    subTitle  : "FormHop was built to save you time",
    body    : "See real-time sub-totals and totals as you make changes, and immediately understand the impact of the changes you make. Whether you're trying to gather and calculate all the numbers yourself, or your accountant is helping you, it's not just a one-and-done situation, you'll iterate on your Sworn Financials for weeks. And that's before you even hand it over to your lawyer for review.  Make that iterative process much easier with FormHop."
  },
  {
    id      : 30,
    icon    : <AssignmentTurnedInIcon/>,
    title   : "Finished",
    subTitle  : "FormHop will was built to get it done",
    body    : "A click of a button is all it takes to merge your financial data into the official Sworn Financial Statement template. Don't waste your time with confusing fillable pdfs, or worse, useless Word documents.  Let FormHop do the hard work so you can focus your time and energy where you need it most."
  }
];

function FeatureCard({config, classes}){

  return (
    <Grid item xs={5}>
      <Paper className={classes.featureCard} elevation={0} variant="outlined">
        <Grid container>
          <Grid item container justifyContent="center" direction="column" alignItems="center" className={classes.featureTitle}>
            <Grid item><h2 className={classes.mx0}>{config.icon}</h2></Grid>
            <Grid item><h2 className={classes.title}>{config.title}</h2></Grid>
          </Grid>
          <Grid item container justifyContent="center" className={classes.featureSubtitle}>
            <h3 className={classes.mx0}>{config.subTitle}</h3>
          </Grid>
          <Grid item container className={classes.featureBody}>
            <p>{config.body}</p>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
}