import { useEffect, useMemo, useState } from "react";
import { Box, Button, Divider, MenuItem, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { SimpleTreeView, TreeItem2 } from "@mui/x-tree-view";
import { FolderShared, Star } from "@mui/icons-material";
import { CloudFolder, SharedCloudFolder } from "types";
import { useOnedriveFolders } from "hooks/cloud-storage";
import { childCount, findRecursive, getFolderProps } from "helpers/cloud-storage-helpers";
import { CloudStorageSettingSchema } from "types/schema";
import { ConfirmDialog, LoadingBar } from "components";
import { useAppContext } from "sections/app/app-context";
import { useAttorneyContext } from "sections/collaborator/attorney-context";
import { useCloudDrive } from "../cloud-storage-context/onedrive-context";
import { useCloudStorage } from "../cloud-storage-context/cloud-storage-context";

export interface IMicrosoftSyncSettingsProps {
  onToggleEdit: () => void;
}

//TODO: setup these descriptions for the different sync modes
const syncDescriptions: Record<string, string> = {
  manual: "FormHop will only sync clients when you manually trigger it",
  starred: "FormHop will automatically sync clients that you have starred",
  all: "FormHop will automatically sync all clients",
};

const OneDriveFolderTree = ({ onToggleEdit }: IMicrosoftSyncSettingsProps) => {
  const { uid } = useAppContext();
  const { setting, updateSetting } = useCloudStorage();
  const { accountSettings, updateAccountSettings } = useAttorneyContext();
  const { folders, getChildren, refresh, isWorking } = useOnedriveFolders();
  const { cloudFolder } = useCloudDrive();

  const syncType = useMemo(() => setting?.syncMode ?? "manual", [setting]);
  const syncFolderId = useMemo(() => setting?.id, [setting]);
  const topFolders = useMemo(() => folders?.filter((f: any) => !f.parentId), [folders]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  //Note, the selected id may be different from the syncFolderId due to the remote items
  const [selectedId, setSelectedId] = useState<string | null>(cloudFolder?.id ?? null);

  const confirmMessage = "Other users in your organization are syncing to a different folder. Are you sure you want to choose this folder?";
  const [isConfirming, setConfirming] = useState(false);
  const [isConfirmed, setConfirmed] = useState(false);

  //Finds and selects the initial folder if there's already one set
  useEffect(() => {
    if(folders?.length > 0 && cloudFolder?.id) {
      const item = findRecursive(folders, cloudFolder.id);
      if(item) setSelectedId(item.id);
    }
    else setSelectedId(cloudFolder?.id ?? null);
  }, [folders, cloudFolder?.id]);

  //Handles the selected item in the treeview
  const handleSelectChanged = (event: any, itemId: string | null) => {
    if(itemId){
      setSelectedId(itemId);      
      const folder = findRecursive(folders, itemId);
      console.log("selected folder", folder);
    }
    else {
      setSelectedId(null);
    }
  };

  //Handles the refresh button, reloads the folders
  const handleRefreshClicked = () => {
    setExpandedItems([]);
    refresh();
  }

  //Handles saving the selected folder
  const handleSaveFolder = async () => {
    if(selectedId){
      const folder = findRecursive(folders, selectedId);
      console.log("selected folder", folder);
      
      //Make sure there's something to save
      if(!folder || folder.id === syncFolderId) {
        onToggleEdit();
        return;
      }

      //updateSetting will merge in the existing value
      const newValue: Partial<CloudStorageSettingSchema> = {
        ...getFolderProps(folder, "onedrive", syncType),
      };

      //Update the account setting with the drive id and folder id, if there isn't a setting for it already
      const existingAccountProps = accountSettings?.cloudStorage;
      const setByMe = (existingAccountProps?.setBy === uid);
      const isChanged = (existingAccountProps?.driveId !== newValue.driveId || existingAccountProps?.id !== newValue.id);

      //Make sure it matches, and warn the user if they're choosing a different folder (unless it was the who chose the other folder...)
      if(!!existingAccountProps && (!setByMe && isChanged && !isConfirmed)){
        console.warn("Selecting a different folder than the one you're currently syncing, this may cause issues. Confirming with the user.");
        setConfirming(true);
        return;
      }
      
      await updateSetting(newValue);

      if(!existingAccountProps || (isChanged && setByMe) ){
        //Add an account setting for this drive and folder to try to keep team members
        // from using different folders.
        const accountSettingValue = {
          provider: "onedrive",
          driveId: newValue.driveId,
          id: newValue.id,
          name: newValue.name,
          setBy: uid,
        };

        await updateAccountSettings("cloudStorage", accountSettingValue);
      }

      onToggleEdit();
    }
  };

  const handleConfirm = async () => {
    setConfirmed(true);
    setConfirming(false);
    await handleSaveFolder();
    setConfirmed(false);
  }

  //Cancels any changes, and returns to the display view
  const cancelChange = () => {
    onToggleEdit();
  }

  //Handles the tree item expanded event
  const handleTreeItemExpanded = (event: any, items: string[]) => {
    const diff = items.filter(x => !expandedItems.includes(x));
    //get the children for the newly expanded items
    diff.forEach((id) => getChildren(id));
    setExpandedItems(items);
  };

  return (
    <Stack width="100%" rowGap={2}>
      {isWorking && <LoadingBar message="Loading..." />}


      {/* TODO: make this editable, and setup the other modes */}
      {/* <Grid container sm={12}>
        <Tooltip title="Sync Mode">
          <TextField variant="outlined" size="small" value={syncType} fullWidth select disabled>  
            <MenuItem value="manual">Manual</MenuItem>
            <MenuItem value="starred">Auto Starred</MenuItem>
            <MenuItem value="all">Auto All</MenuItem>
          </TextField>
        </Tooltip>
      </Grid> */}

      {!isWorking && (
        <Stack>
          <Box width="100%" sx={{ display: "flex", justifyContent: "center", mb: 1, flexWrap: "wrap" }}>
            <Typography variant="caption">Select where your client folders should go in OneDrive</Typography>
          </Box>
          <SimpleTreeView checkboxSelection onExpandedItemsChange={handleTreeItemExpanded} selectedItems={selectedId} expandedItems={expandedItems} onSelectedItemsChange={handleSelectChanged}>
            {topFolders.map((folder: any, index: number) => (<NestedTreeItem key={index} folder={folder} existingId={accountSettings?.cloudStorage?.id}></NestedTreeItem>))}
          </SimpleTreeView>
        </Stack>
      )}

      <Divider sx={{ my: 1 }} />

      <Grid container sm={12} justifyContent="space-evenly">
        {setting && <Button variant="outlined" onClick={cancelChange} size="small" disabled={isWorking}>Cancel</Button>}
        <Button variant="outlined" onClick={handleRefreshClicked} size="small" disabled={isWorking}>Refresh</Button>
        <Button variant="outlined" onClick={handleSaveFolder} size="small" disabled={isWorking} color="primary">Save</Button>
      </Grid>
      
      {isConfirming && <ConfirmDialog open={isConfirming} title="Select different folder?" message={confirmMessage} onConfirm={handleConfirm} onClose={() => setConfirming(false)} />}
    </Stack>
  );
};

export default OneDriveFolderTree;

//===
// A nested tree item component that recursively renders children
//===
const NestedTreeItem = ({ folder, existingId }: { folder: CloudFolder | SharedCloudFolder, existingId?: string }) => {
  const folderId = folder.id;
  const owner = (folder as SharedCloudFolder).remoteItem?.createdBy?.user;
  const isExisting = existingId && existingId === folderId;
  
  const label = (!owner && !isExisting) ? folder.name : (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Typography>{folder.name}</Typography>
      {isExisting && (
        <Tooltip title="This is the folder you, or someone in your organization, is currently syncing to">
          <Star fontSize="small" color="secondary" />
        </Tooltip>      
      )}
      {!!owner && (
        <Tooltip title={`This is a shared folder, owned by ${owner.displayName ?? owner.email}`}>
          <FolderShared fontSize="small" sx={{ color: "grey.600" }} />
        </Tooltip>
      )}
    </Stack>
  );

  return (
    <TreeItem2 key={folder.id} itemId={folderId} label={label}>
      {childCount(folder) > 0 && !folder.children && <TreeItem2 itemId={folderId + "-loading"} label="Loading..."></TreeItem2>}
      {!!folder.children && folder.children.map((child: any) => (
        <NestedTreeItem key={child.id} folder={child}></NestedTreeItem>
      ))}
    </TreeItem2>
  );
}