import { useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { IFormFieldInputProps } from "./part-types";

//NOTE: MaterialUI Components don't use the native events, so they don't bubble to get handled by the withFieldChange HOC.
// Therefore, we need to explicitly handle the change event here, and call the handleValueChanged function from the region context
const FormToggleField = ({ field, initialValue, onChanged, dataProps, } : IFormFieldInputProps ) => {
  // const [valueKey, initialValue] = useAttorneyFormValue(field.id, sectionId, rowIndex);
  // const onFieldChanged = useFieldChanged(valueKey, field.id, rowIndex);
  
  const [val, setVal] = useState<string | null>(initialValue ?? null);
  const classNames = `form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  
  //=== watch the initialValue. It may change if this is a sameAs field, or there's some other dependency
  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  if(!field.options) throw new Error("Toggle field must have options");

  const handleChange = (e: any) => {
    let nextValue = e.target.value;
    //If they click the same value, unselect it
    if(nextValue === val) nextValue = null;

    setVal(nextValue);

    if(onChanged){ //  && nextValue !== initialValue){
      onChanged(nextValue);
    }
  }

  const options = field.options.map(opt => (
    <ToggleButton 
      key={opt.id} 
      value={opt.id.toString()} 
      disabled={opt.isDisabled} 
      size="small"
      onChange={handleChange}
      sx={{ py: 0.5, fontSize: "0.85rem" }}
    >
      {opt.label}
    </ToggleButton>
  ));
  
  return (
      <ToggleButtonGroup
        id={field.id} 
        value={val} 
        className={classNames} 
        {...dataProps} 
        size="small"
        color="secondary"        
      >
        {options}
      </ToggleButtonGroup>
  );
};

export default FormToggleField;