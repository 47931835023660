import { FormField } from "types";

export const inputFieldSizes = {
  xs: "50px",
  sm: "150px",
  md: "250px",
  lg: "450px",
  xl: "650px",
};

export const inputFieldTypes: Record<string, any> = {
  text: {},
  currency: { textAlign: "right" },
  number: { textAlign: "right" },
};


export const getDefaultValue = (field: FormField) => {
  switch(field.type){
    case "boolean":
      return field.defaultValue === undefined ? false : !!field.defaultValue;
    case "number":
      return field.defaultValue !== undefined ? parseFloat(field.defaultValue) : undefined;
    default:
      return field.defaultValue;
  }
};