import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { ClientCloudFolderIds, ClientDocument, CloudSyncStatus, CurrentClient } from "types";
import { CloudStorageSettingSchema } from "types/schema";
import { getFileName, uploadToFolder } from "helpers/cloud-storage-helpers";
import { clientDocumentsSynced, updateClient } from "store/actions/attorney-actions";
import { downloadBlob } from "store/actions/share-actions";
import { useCloudDrive } from "sections/collaborator/parts/provider-accounts/cloud-storage-context/onedrive-context";
import { useCloudStorage } from "sections/collaborator/parts/provider-accounts/cloud-storage-context/cloud-storage-context";

const useCloudSync = () => {
  const dispatch = useDispatch();
  const { setting } = useCloudStorage();
  const { canSync, getClientFolder } = useCloudDrive();
  const { instance } = useMsal();
  const [syncStatus, setSyncStatus] = useState<CloudSyncStatus>({ isSyncing: false, isSynced: false });
  
  const isClientSynced = (client?: CurrentClient) => {
    const isSyncing = canSync && !!client?.cloudSync?.syncedAt;
    return isSyncing;
  }

  const syncClient = async (client: CurrentClient, documents?: ClientDocument[]) => {
    if (!canSync || !setting) return null;

    setSyncStatus({...syncStatus, isSyncing: true });
    const { cloudSync } = client;
    const { syncedAt } = cloudSync ?? {};

    const clientFolders = await getClientFolder(client);
    if(!clientFolders) {
      //something went wrong
      console.error("failed to get or create client folder");
      setSyncStatus({...syncStatus, isSyncing: false, error: "Failed to get or create client folder" });
      return null;
    }
    
    //TODO: Now, check for the timestamp of the last update, and find any documents that have changed since then.
    // download the documents, and then upload them to the client folder in onedrive
    // const docs = client.share.documents ?? [];
    const docs = documents ?? client.share?.documents ?? [];
    const changes = docs.filter(d => !!d.filePath && (!syncedAt || !d.syncId || d.modifiedAt > syncedAt));
    if(changes.length === 0){
      console.log("no changes to sync");

      //If this client hasn't been synced before, update it with the sync info to flag it as connected
      if(!cloudSync){
        console.log("no files to sync, but setting up client to sync in the future");
        const clientUpdates: any = { cloudSync: { syncedAt: Date.now(), syncFolderId: clientFolders.clientFolderId } };
        await dispatch(updateClient(clientUpdates, client.id));
      }

      setSyncStatus({...syncStatus, isSyncing: false, isSynced: true });
      return;
    }

    console.log("sync changes doc count: ", changes?.length);

    //enumerate the documents and download them, then upload them to cloud storage
    let updatedCount = 0;
    let results: any[] = [];    //will be the file id or an error for each doc

    for(const doc of changes){
      console.log("syncing doc: ", doc.id);
      const result = await syncFile(doc, setting, clientFolders);
      results.push(result);
      console.log("synced doc: ", doc.id);
      if(!result.error) updatedCount++;
    }

    //update the client with the new syncedAt timestamp
    if(updatedCount > 0){
      const timestamp = Date.now();
      const clientUpdates: any = { cloudSync: { ...cloudSync, syncedAt:  timestamp, syncFolderId: clientFolders.clientFolderId } };
      await dispatch(updateClient(clientUpdates, client.id));
      await dispatch(clientDocumentsSynced(client.id, results, timestamp));
    }

    setSyncStatus({...syncStatus, isSyncing: false, isSynced: true });    
  };

  //===
  //== Does the work of syncing the file. Downloads it from firebase storage, then uploads it to the cloud drive
  const syncFile = async (doc: ClientDocument, setting: CloudStorageSettingSchema, clientFolders: ClientCloudFolderIds) => {
    console.log("syncing doc: ", doc.id);

    //Download from FB Storage
    const fileResult = await dispatch(downloadBlob(doc)) as any;
    if(fileResult.error){
      console.error("download error: ", fileResult.error);
      return { docId: doc.id, error: fileResult.error };
    }

    //Upload to the Cloud Drive
    const targetFolderId = (doc.direction === "toClient") ? clientFolders.toFolderId : clientFolders.fromFolderId;
    const fileName = getFileName(doc);
    const uploadResult = await uploadToFolder(instance, targetFolderId, fileResult.data, fileName, setting);
    console.debug("syncFile: upload result: ", uploadResult);
    
    if(uploadResult.error){
      console.error("upload error: ", uploadResult.error);
      return { docId: doc.id, error: uploadResult.error };       
    }
    else return { docId: doc.id, path: uploadResult.parentReference.path, fileId: uploadResult.id, eTag: uploadResult.eTag };
  }

  //===
  //== Uploads a single file to the toClient folder of the client
  const uploadFile = async (client: CurrentClient, file: any, fileName: string, documentId: string) => {
    if (!canSync || !setting) return null;

    setSyncStatus({...syncStatus, isSyncing: true });

    const clientFolders = await getClientFolder(client);
    if(!clientFolders) {
      //something went wrong
      console.error("failed to get or create client folder");
      setSyncStatus({...syncStatus, isSyncing: false, error: "Failed to get or create client folder" });
      return null;
    }

    const targetFolderId = clientFolders.toFolderId;
    const uploadResult = await uploadToFolder(instance, targetFolderId, file, fileName, setting);
    console.debug("upload result: ", uploadResult);
    
    if(uploadResult.error){
      console.error("upload error: ", uploadResult.error);
      setSyncStatus({...syncStatus, isSyncing: false, error: uploadResult.error });
      return false;
    }
    else {
      const timestamp = Date.now();
      //TODO: update the client document with the fileId info
      const results = [{ docId: documentId, path: uploadResult.parentReference.path, fileId: uploadResult.id, eTag: uploadResult.eTag }];
      await dispatch(clientDocumentsSynced(client.id, results, timestamp));
      setSyncStatus({...syncStatus, isSyncing: false, error: null });
      return true;
    }
  };

  return { canSync, isClientSynced, syncClient, uploadFile, syncStatus };
};

export default useCloudSync;