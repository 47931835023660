import React from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { ErrorAlert, Loading, NavButton, TextDivider } from 'components';
import { exportData } from 'store/actions/app-actions';
import { trackEvent, Events } from 'helpers/analytics';
import WordIcon from './ms-word-icon.svg';
import { useStatus } from 'redux-action-status';
//TODO: Paywall
// import { selectCustomer, selectProfile } from 'store/selectors/app-selectors';

const DocsSection = ({section, classes}) => {
  const status        = useStatus("export");
  const error         = useSelector(state => state.app.exportError);
  const values        = useSelector(state => state.values);
  const locKey        = useSelector(state => state.app.profile.locationKey);
  const dispatch      = useDispatch();

  //TODO: Paywall - the following can be enabled to re-add the paywall for an individual
  // const customer      = useSelector(state => selectCustomer(state));
  // const profile       = useSelector(state => selectProfile(state))
  const canExport = true; //useMemo(() => customer?.isLicensed || profile?.licenseOverride === true, [customer, profile]);

  const onExport = (doc) => {
    if(!canExport) return;

    const toExport  = {
        a_locationKey   : locKey,
        a_form          : doc,
        ...values,
    };
    const isPrimary  = doc.order === 0;
    
    trackEvent(Events.export, {label: `${locKey}, ${doc}`, value: doc});
    dispatch(exportData(toExport, isPrimary));
  }

  const options       = _.map(section.items, doc => <ExportOption key={doc.id} option={doc} onExport={onExport} isWorking={status.isWorking} classes={classes} isLicensed={canExport}/>);

  return (
    <Grid item container className={classes.root}>
      
      <Grid item xs={12} container direction="column" alignItems="center" justifyContent="center">
        <Typography className={classes.instructions}>
          Export the documents below and submit them to your lawyer.
          {/* Please read the descriptions of each document to determine which ones are required and which ones are optional. */}
        </Typography>
      </Grid>

      <Grid container alignItems="center" justifyContent="center">
        <Loading className={classes.loading} align="center" isVisible={status.isWorking} message="Exporting your document..."/>
      </Grid>

      <Grid container className={classes.docsGrid}>
        {error && 
          <Grid item sm={12} container direction="column">
            <ErrorAlert domain="app" domainKey="exportError"/>
          </Grid>
        }

        {options}
        {!canExport && (
          <Grid container alignItems="center" justifyContent="center" className={classes.docsOverlay} >
            <Grid item container direction="column" alignItems="center" justifyContent="center" className={classes.docsInnerOverlay}>
              <Typography className={classes.overlayText}>This feature is only avilable in the Premium Edition</Typography>
              <NavButton to="/my/subscription" variant="contained" color="secondary">Upgrade to Premium</NavButton>
            </Grid> 
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography className={classes.disclaimer}>
          <span className="bold">Fine print:</span> By using FormHop, and exporting your legal documents, you agree that 
          FormHop does not make any warranties or guarantees about the accuracy or completeness of the exported documents. 
          It is your responsibility to review and verify the exported legal documents with your lawyer.
          Any errors or omissions are not the responsibility of FormHop, and FormHop cannot be held liable for any mistakes.
        </Typography>
      </Grid>
      
    </Grid>
  );
}

const styles = (theme => ({
  root    : {
    marginBottom  : theme.spacing(3),
    borderWidth   : "0 0 1px 0",
    borderStyle   : "solid",
    borderColor   : theme.palette.grey[300],
    paddingBottom : theme.spacing(3),
  },
  body      : {
    fontSize    : 18,
    marginBottom  : theme.spacing(0.5),
  },
  bodySmall   : {
    fontSize    : 14,
    marginBottom  : theme.spacing(0.5),
  },
  error   : {
    width   : "100%",
  },
  instructions  : {
    margin        : `${theme.spacing(2)} ${theme.spacing(0)}`, //`
    fontSize      : 16,
  },
  disclaimer  : {
    color         : theme.palette.grey[500],
    marginTop     : theme.spacing(3),
    marginBottom  : theme.spacing(1),
    fontSize      : 14,
    "& .bold"     : {
      fontWeight    : 600,
    },
    "& .italic"   : {
      fontStyle     : "italic",
    }
  },
  docsGrid    : {
    marginTop     : theme.spacing(2),
    position      : "relative",
  },
  docsOverlay : {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: `${theme.palette.grey[800]}99`,
    borderRadius: 5,
    zIndex: 98,
  },
  docsInnerOverlay: {
    margin: `${theme.spacing(0)} ${theme.spacing(4)}`, //`
    padding: theme.spacing(4),
    zIndex: 99,
  },
  overlayText: {
    fontSize: 18,
    fontWeight: 500,
    backgroundColor: theme.palette.grey[700],
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`, //`,
    borderRadius: 3,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  exportOption  : {
    padding         : `${theme.spacing(3)} ${theme.spacing(2)}`, //`
  },
  optionSpacer  : {
    marginBottom    : theme.spacing(2),
  },
  docIcon     : {
    height      : 72,
    width       : 72,
  },
  exportButton  : {
    marginTop     : theme.spacing(1),
    alignSelf     : "flex-start",
  },
  chip  : {
    fontSize    : 15,
    marginLeft  : theme.spacing(1),
    height      : 26,
  }
}));

export default withStyles(styles, {theme: true})(DocsSection);

function ExportOption({option, isWorking, onExport, classes, isLicensed}){

  return (
    <>
      <Grid item sm={12} container alignItems="center" justifyContent="space-between" className={classes.exportOption}>
        <Grid item sm={2} container alignItems="center" justifyContent="center">
          <img src={WordIcon} className={classes.docIcon} alt="MS Word Doc"/>        
        </Grid>
        <Grid item sm={10} container direction="column">
          <Grid item container direction="row">
            <Typography className={classes.body}>{option.name}</Typography>      
            <Chip label={option.isRequired ? "Required" : "Conditional"} color={option.isRequired ? "secondary" : "default" } className={classes.chip} />
          </Grid>
          <Typography className={classes.bodySmall}>{option.desc}</Typography>
          <Button onClick={() => onExport(option.id)} disabled={isWorking || !isLicensed} className={classes.exportButton} color="primary"size="small" variant="outlined">Export</Button>
        </Grid>      
      </Grid>
      <Grid item container justifyContent="center" className={classes.optionSpacer}>
        <TextDivider cols={10}/>
      </Grid>
    </>
  );
}