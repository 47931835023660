import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { List, ListItemButton, ListItemText, Paper } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AccountSettingsSchema } from "types/schema";
import { updateAccountProfile } from "store/actions/account-actions";
import UploadSettingsRow from "./parts/common-uploads-setting";
import CloudStorageSetting from "./parts/cloud-storage-setting";
import CommunicationSettings from "./parts/communication-settings";

const AccountSettingsCard = () => {
  const dispatch = useDispatch();
  const { subtab } = useParams();
  const navigate = useNavigate();
  const settings = useSelector<any, AccountSettingsSchema>(state => state.account.account.settings ?? {});

  const tab = subtab ?? "cloudStorage";

  const handleSettingSelected = (id: string) => () => {
    if(tab === id) return;
    navigate(`/app/account/settings/${id}`);
  }

  const handleSettingsUpdated = (settingType: string) => async (newValue: any) => {
    const changes = {
      settings: {
        ...settings,
        [settingType]: newValue,
      },
    };

    const result = await dispatch(updateAccountProfile(changes)) as any;
    return result.isOk;
  }

  return (
    <Paper id="account-profile" variant="outlined" sx={{ borderRadius: "0 0 8px 8px", width: "100%", minHeight: 500 }}>
      
      <Grid container sm={12} columnGap={1} flexWrap="nowrap">
        <Grid container sm={4} md={3} sx={{ borderRadius: 2 }}>
          <List component="nav" aria-label="settings">
            {SettingTypes.map((setting) => (
              <ListItemButton key={setting.id} selected={tab === setting.id} onClick={handleSettingSelected(setting.id)}>
                <ListItemText primary={setting.name} secondary={setting.description} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid container sm={8} md={9}>
          {tab === "cloudStorage" && <CloudStorageSetting settings={settings?.cloudStorage} onUpdated={handleSettingsUpdated("cloudStorage")} />}
          {tab === "clientUploads" && <UploadSettingsRow settings={settings?.clientUploads} onUpdated={handleSettingsUpdated("clientUploads")}/>}          
          {tab === "communication" && <CommunicationSettings settings={settings?.communication} onUpdated={handleSettingsUpdated("communication")} />}
        </Grid>
      </Grid>

      

    </Paper>
  );
}

export default AccountSettingsCard; 

const SettingTypes = [
  { id: "cloudStorage", name: "Cloud Storage", description: "Link your cloud storage accounts" },
  { id: "clientUploads", name: "Common Upload Requests", description: "Documents you regularly request from your clients" },
  { id: "communication", name: "Communication Settings", description: "Notification and client communication preferences" },
];