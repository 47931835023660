import { useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { ArrowDropDown, CloudDownloadOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ClientDocument } from "types";
import { DocumentVersionSchema } from "types/schema";
import { formatDateString } from "helpers/general-helpers";
import { downloadDocument } from "store/actions/share-actions";

const DocumentVersionsButton = ({ document } : { document: ClientDocument }) => {
  const dispatch = useDispatch();
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const open = Boolean(anchor);
  
  if(!document.versions) return null;

  const handleToggle = (event: any) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleDownload = (version: DocumentVersionSchema) => async () => {
    const result = await dispatch(downloadDocument(document, version.version)) as any;
    if(result.isOk) toast("Document version downloaded successfully", { type: "success" });
    setAnchor(null);
  }

  return (
    <>
      <Tooltip title="Click to download a previous version">
        <Button onClick={handleToggle} endIcon={<ArrowDropDown fontSize="small" />} sx={{ color: "grey.600"}}>
          <CloudDownloadOutlined fontSize="small" />
        </Button>
      </Tooltip>
      <Menu id="split-button-menu" anchorEl={anchor} open={open} onClose={handleClose} >
        {document.versions!.map(version => (
          <MenuItem key={version.version} onClick={handleDownload(version)}>
            <Typography variant="body2">Version {version.version} - {formatDateString(version.createdAt)}</Typography>
          </MenuItem>
        ))}
      </Menu>      
    </>
  );
};

export default DocumentVersionsButton;