import React from 'react';
// import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
// import SyncIcon from '@mui/icons-material/SyncAlt';
import CloudDoneIcon from '@mui/icons-material/CloudDoneOutlined';
import CloudOffIcon from '@mui/icons-material/CloudOff';
// import SyncDisabledIcon from '@mui/icons-material/SyncDisabled';
// import SyncProblemIcon from '@mui/icons-material/SyncProblem';
import { saveDataToServer } from 'store/actions/value-actions';
import { selectProfile } from 'store/selectors/app-selectors';
import { useInterval } from 'helpers/hooks';

const SAVE_INTERVAL  = 60000;    //auto-save every 60 seconds...

const SyncButton = () => {
  const dispatch  = useDispatch();
  const profile   = useSelector(state => selectProfile(state));
  const dbProps   = useSelector(state => state.values.database);

  const isSyncing = profile.isRemote;
  const classes   = buildStyles();
  const syncTip   = isSyncing ? `Data last synced at ${dbProps.savedAt || dbProps.updatedAt}. Click here for more information` : "Your data is stored locally. Click here for more information."; //`
    
  //an effect that will run a timer and save the data to the database automatically ever 60 seconds
  useInterval(() => {
    console.log("interval triggered");
    if(!dbProps.isWorking) dispatch(saveDataToServer());
  }, SAVE_INTERVAL);

  // if(!isWelcomed) return null;
  
  return (
    <Tooltip title={syncTip}>
      <IconButton
        component={Link}
        to="/my/data"
        className={classes.menuButton}
        size="large">
        {isSyncing && <SyncTimer isWorking={dbProps.isWorking}/>}
        {!isSyncing && <CloudOffIcon fontSize="small"/>}
      </IconButton>
    </Tooltip>
  );
}

export default SyncButton;

const buildStyles   = makeStyles(theme => ({
  menuButton   : {
    marginLeft      : theme.spacing(1),
    color         : theme.palette.grey[400],
    border        : `1px solid ${theme.palette.grey[300]}`, //`
    padding       : theme.spacing(0.5),
    "&:hover"   : {
      color         : theme.palette.secondary.main, //grey[600],
      background    : theme.palette.grey[200],
    },    
  },
}));

function SyncTimer({isWorking}){
  const dispatch  = useDispatch();
  
  //an effect that will run a timer and save the data to the database automatically every 60 seconds
  useInterval(() => {
    console.log("interval triggered");
    if(!isWorking) dispatch(saveDataToServer());
  }, SAVE_INTERVAL);

  return <CloudDoneIcon fontSize="small"/>;
}