
export const STATUS_START    = "STATUS_START";
export const STATUS_FINISH   = "STATUS_FINISH";
export const STATUS_FAILED   = "STATUS_FAILED";
export const STATUS_RESET     = "STATUS_RESET";
export const STATUS_CLEAR_ERROR     = "STATUS_CLEAR_ERROR";

export const statusMiddleware = store => next => async action => {
  //SKip if there's no status key
  if(!action.statusKey && !action.statusReset) return next(action);
  //Skip this if it's the actions we are supposed to trigger
  if(action.type === STATUS_START || action.type === STATUS_FINISH || action.type === STATUS_FAILED || action.type === STATUS_CLEAR_ERROR) return next(action);

  const { statusKey, getError, statusReset } = action;
  const { dispatch, getState }  = store;

  if(statusReset === true){
    //Clear everything out (perhaps on a sign-out)
    const result  = await next(action);   //perform actions first so everything else is cleaned up, and we don't trigger effects
    await dispatch({
      type            : STATUS_RESET,
    });
    return result;
  }
    
  //Check, and only trigger if it's not already working
  const state   = getState()?.status || {};
  if(!state[statusKey]?.isWorking){
    await dispatch({
      type            : STATUS_START,
      startStatusKey  : statusKey,
    });
  }

  //Perform the action...
  const result  = await next(action);

  //Now send the finished...
  let error   = getError ? getError(result) : (result.error || null);

  if(error){
    await dispatch({
      type              : STATUS_FAILED,
      finishStatusKey   : statusKey,
      error             : error,
    });  
  }
  else{
    await dispatch({
      type            : STATUS_FINISH,
      finishStatusKey : statusKey,
    });  
  }

  return result;  //return the original result of the action, not my result
}