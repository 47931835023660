import { useState } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Stack, Typography } from '@mui/material';
import { sendPasswordReset } from 'config/firebase-config';
import { toast } from 'react-toastify';

//mode: "create" | "forgot" | "login";
function StandardLogin({ onAuth, onToggle, isWorking, tab}){
  const classes   = useStyles();
  const isCreate = tab === 1;
  const isLogin = tab === 0;
  const isForgot = tab === 2;
  const btnLabel  = isCreate ? "Create Account" : (isForgot ? "Send" : "Login");

  const [email, setEmail]       = useState("");
  const [pwd, setPwd]           = useState("");
  const [confirm, setConfirm]   = useState("");
  const [errors, setErrors]     = useState(null);

  const doAuth  = (e) => {
    if(isForgot) return doForgot();

    setErrors(null);
    const errors  = validateCredentials(email, pwd, confirm, isCreate);
    if(!errors){
      return onAuth(email, pwd);
    }
    else{
      setErrors(errors);
    }
  }

  const setForgot = () => {
    setErrors(null);
    onToggle(2);
  }

  const doForgot = () => {
    setErrors(null);
    const errors  = validateEmail(email);
    if(!errors){
      sendPasswordReset(email);
      toast.info("An email has been sent to the address provided. Please check your inbox for further instructions.");
      onToggle(0);
    }
    else{
      setErrors(errors);
    }

  }

  const onKeyPress = (e) => {
    if(e.key === "Enter"){
      doAuth();
    }
  }

  let i   = 0;

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {errors && 
        <Card elevation={0} className={classes.errorCard} variant="outlined">
          <ul className={classes.errorList}>
            {_.map(errors, e => <li key={i++} className={classes.errorItem}>{e}</li>)}
          </ul>
        </Card>
      }
      <Grid container sm={10} justifyContent="center" textAlign="center" rowGap={2}>
        {isForgot && (
          <>
            <Typography variant="h6" fontSize="1.5rem" fontWeight="500" mb={2}>Forgot Your Password?</Typography>
            <Typography variant="h6" fontSize="1.3rem" fontWeight="500" mb={2}>Enter your email address and click send to continue.</Typography>
          </>
        )}
        <TextField id="email" type="email" size="small" label="email" variant="filled" disabled={isWorking} className={classes.textField} value={email} onChange={(e) => setEmail(e.target.value)} onKeyPress={onKeyPress}/>
        {!isForgot && <TextField id="password" type="password" size="small" label="password" variant="filled" disabled={isWorking} className={classes.textField} value={pwd} onChange={(e) => setPwd(e.target.value)} onKeyPress={onKeyPress}/> }
        {isCreate && <TextField id="passwordConfirm" type="password" size="small" label="confirm password" variant="filled" disabled={isWorking} className={classes.textField} value={confirm} onChange={(e) => setConfirm(e.target.value)} onKeyPress={onKeyPress}/> }
        <Grid container xs={12} className={classes.buttonRow} justifyContent="space-between" alignItems="center">
          <Grid xs container>
            {isLogin && (
              <>
                <Stack direction="row" alignItems="center">
                  <Typography fontSize="small">First time here?</Typography>
                  <Button onClick={() => onToggle(1)} className={classes.linkButton} size="small" color="primary">Create Account</Button>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Typography fontSize="small">Forgot your password?</Typography>
                  <Button onClick={setForgot} className={classes.linkButton} size="small" color="primary">Click Here</Button>
                </Stack>
              </>
            )}
            {isCreate && (
              <Stack direction="row" alignItems="center">
                <Typography fontSize="small">Already have an account?</Typography>
                <Button onClick={() => onToggle(0)} className={classes.linkButton} size="small" color="primary">Sign in</Button>
              </Stack>
            )}
          </Grid>
          <Grid xs container justifyContent="flex-end" columnGap={1} flexWrap="nowrap" flexGrow={1}>
            {isForgot && (<Button onClick={() => onToggle(0)} className={classes.btn} variant="outlined" disabled={isWorking} sx={{ color: "grey.600", borderColor: "grey.600" }}>Back</Button> )}
            <Button className={classes.btn} color="primary" variant="outlined" onClick={doAuth} disabled={isWorking}>{btnLabel}</Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default StandardLogin;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `0 ${theme.spacing(1)}`,
  },
  buttonRow        : {
    marginTop     : theme.spacing(2),
  },
  textField           : {
    width         : "100%",
    "& input, label"   : { 
      fontSize      : 18,
    }
  },
  errorCard         : {
    background      : `${theme.palette.error.light}33`,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
  errorList   : {
    margin          : theme.spacing(0.5),
    paddingLeft     : theme.spacing(2),
    fontSize        : 16,
  },
  errorListItem   : {

  },
  linkButton    : {
    textTransform     : "none",
    fontSize          : "1rem",
    fontWeight        : "400",
    padding           : "2px 6px",
  }
}));

function validateCredentials(email, password, confirmPassword, isConfirm){
  let errors  = [];
  if(email.indexOf("@") <= 0) errors.push("Email address is not valid.");
  if(!email || !password || (isConfirm && !confirmPassword)) errors.push("All fields are required.");
  if(isConfirm && password !== confirmPassword) errors.push("Password and Confirm Password don't match.");

  //TODO: Password Complexity...

  return errors.length === 0 ? null : errors;
}

function validateEmail(email){
  let errors  = [];
  if(email?.indexOf("@") <= 0) errors.push("Email address is not valid.");
  if(!email) errors.push("All fields are required.");

  return errors.length === 0 ? null : errors;
}