import { ReactNode } from "react";
import { Route, Routes } from "react-router-dom";
import { Box, Card, Stack, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { boxStyles } from "helpers";
import { Header } from "sections/financials/layout";
import PetitionerHome from "./petitioner-home";
import FormContainer from "sections/financials/form-container";
import { DocumentContainer } from "../docs";
import PetitionerProvider from "./contexts/petitioner-context";
import MyConversations from "../my/my-conversations/my-conversations";

const PetitionerView = () => {

  return (
    <Grid id="petitioner-view" container xs={12}>

      <Box sx={{ ...boxStyles.visibleMobile }}>
        <Card elevation={0} variant="outlined" sx={{ margin: "15%", padding: 2, color: "primary.main", }}>
          <Stack gap={2}>
            <Typography>
              We don't currently support mobile devices.  We're a small team, and we're working on it, but for now, please visit us from a full-sized web browser on your computer. We promise it will be worth your while.
              Thanks!
            </Typography>
          </Stack>
        </Card>
      </Box>      

      <Box id="petitioner-view-routes" sx={{ ...boxStyles.hiddenMobile, width: "100%" }}>
        <Routes>
          <Route path=":shareId/forms/:documentId" element={<PetitionerLayout><FormContainer /></PetitionerLayout>} />
          <Route path=":shareId/forms/:documentId/:regionId" element={<PetitionerLayout><FormContainer /></PetitionerLayout>} />
          <Route path=":shareId/documents/:documentId" element={<PetitionerLayout><DocumentContainer /></PetitionerLayout>} />
          <Route path="/conversations" element={<MyConversations /> } />
          <Route path="conversations/:shareId/:topicType/:topicId" element={<MyConversations />} />
          <Route path="conversations/:shareId/:topicId" element={<MyConversations />} />
          <Route path="/:tab" index element={<PetitionerHome />} />
          <Route path="/" element={<PetitionerHome />} />
        </Routes>
      </Box>
    </Grid>
  );
};

const PetitionerWrapper = () => {
  return (
    <PetitionerProvider>
      <PetitionerView />
    </PetitionerProvider>
  )
};

export default PetitionerWrapper;

const PetitionerLayout = ({children} : { children: ReactNode }) => {
  return (
    <Grid id="petitioner-view-layout" container xs={12}>
      <Header />
      <Grid container xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};