import _ from 'lodash';
import Api from './api-middleware';
import ErrorHelper from '../../helpers/error-helpers';
import { APP_ACTIONS } from '../actions/action-types';

const fetchMiddleware = store => next => async action => {
    if(!action.fetch){
        return next(action);
    }

    const { fetch, types }  = action;
    const { dispatch }      = store;
    const myAction          = _.omit(action, ['fetch', 'types']);

    //dispatch the action type to flag that we're about to perform this action
    if(types?.action){
        dispatch({
            type    : types.action,
            ...myAction        
        });
    }

    //execute the API call, then dispatch success or failure 
    try{
        const apiResult = await Api.fetch(fetch);
        const nextType  = (!apiResult.isOk && action.failType) ? action.failType : action.type;
        const actionWithResult = {
            type: nextType || APP_ACTIONS.NO_OP,
            ...apiResult,
            ...myAction
        };
        // if(!actionWithResult.isOk) return actionWithResult;     //if it failed, don't call next.
        // else return next(actionWithResult);    
        return next(actionWithResult);
    }
    catch(ex){
        const err   = ErrorHelper.tryNormalize(ex);
        console.error("Error occurred during fetch", err, { ...fetch });
        //TODO: Dispatch an app-level unhanded exception?
        const errorAction = {
            isOk: false,
            type    : types?.failure || APP_ACTIONS.NO_OP,
            error   : err,
            ...myAction
        };

        return next(errorAction);
        // return errorAction;
    } 
}

export default fetchMiddleware;