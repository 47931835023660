import { orderBy } from "lodash";
import { createSelector } from "reselect";
import { ProviderInfo, Share, TopicGroupItem } from "types";

type SelectorState = {
  values: {
    [key: string]: any;
    forms: {
      id: string;
      status: Record<string, boolean>;
    }[];
  },
  petitioner: {
    forms: any[];
    currentFormId: string | null;
    providers: ProviderInfo[];
  },
  share: {
    shares: Share[];
    invitations: any[];
  },
  convo: {
    dirty: any[];
  }
}

const _getFormStatuses = (state: SelectorState) => state.values.forms;
const _getForms = (state: SelectorState) => state.petitioner.forms;
const _getCurrentFormId = (state: SelectorState) => state.petitioner.currentFormId;
const _getShares = (state: SelectorState) => state.share.shares;
// const _getInvites = (state: SelectorState) => state.share.invitations;
const _getProviders = (state: SelectorState) => state.petitioner.providers;
const _inItemId = (state: SelectorState, itemId: string) => itemId;
const _dirtyConversations = (state: SelectorState) => state.convo.dirty ?? [];

export const selectProviders = createSelector(
  [_getProviders, _getShares],
  (providers, shares) => {
    if(!providers) return [];
    if(!shares) return [];

    const providersWithShares = providers.map(p => {
      const share = shares.find(s => s.accountId === p.id);
      return { ...p, shareId: share?.id ?? null };
    });

    return providersWithShares;
  }
);

export const selectPrimaryProvider = createSelector(
  [_getProviders, _getShares],
  (providers, shares) => {
    if(!providers || !shares) return null;
    //primary provider will be the first one that has an accepted share
    const accepted = providers.filter(p => shares.find(s => s.accountId === p.id && !!s.sharer));
    return (accepted[0] ?? null) as ProviderInfo;
  }
);

export const selectPetitionerForms = createSelector(
  [_getForms, _dirtyConversations, _getFormStatuses],
  (rawForms, dirty, statuses) => {
    if(!rawForms) return [];
    //include undefined for backward compatibility
    let clientForms = rawForms.filter(f => f.isClientForm === true || f.isClientForm === undefined);
    
    clientForms = clientForms.map(frm => {
      const isDirty = dirty.find(d => d.shareId === frm.shareId && d.id === frm.id);
      // const formDirty = dirty.find(d => d.shareId === frm.shareId && d.id === frm.id) ? ({...frm, isDirty: true}) : frm
      const status = statuses.find(s => s.id === frm.id);
      return { 
        ...frm,
        isDirty, 
        status: status ?? null 
      };
    });

    return clientForms;
  }
);

//== As the petitioner, selects the specific form
export const selectPetitionerForm = createSelector(
  [_getForms, _getShares, _inItemId],
  (forms, shares, formId) => {
    if(!forms || !formId) return null;
    //Get the form from the petitioner slice, but the form with the config is actually on the share, so we need that one
    const form =  forms.find(f => f.formId === formId) ?? null;
    const share = form?.shareId ? shares?.find(s => s.id === form.shareId) : null;
    if(!share) return null;
    const shareForm = share.forms?.find((f: any) => f.formId === formId);
    return { ...shareForm, shareId: share.id };
  }
);

export const selectFormStatus = createSelector(
  [_getFormStatuses, _inItemId],
  (formStatuses, formId) => {
    if(!formStatuses || !formId) return null;
    const form = formStatuses.find(f => f.id === formId);
    return form?.status ?? null;
  }
);

export const selectPetitionerDocs = createSelector(
  [_getShares, _dirtyConversations],
  (shares, dirty) => {
    if(!shares) return [];
    const allDocs = shares.flatMap(s => s.documents?.map((d: any) => ({...d, shareId: s.id}) ?? [])).filter(d => !!d);
    let shared = allDocs.filter(d => d.isSharedWithClient === true && (d.direction === "toClient" ));
    //check to see if there are any unseen conversations on the doc
    shared = shared.map(doc => dirty.find(d => d.shareId === doc.shareId && d.id === doc.id) ? ({...doc, isDirty: true}) : doc);

    return shared;
  }
);

export const selectPetitionerUploadRequests = createSelector(
  [_getShares, _dirtyConversations],
  (shares, dirty) => {
    if(!shares) return [];
    const allDocs = shares.flatMap(s => s.documents?.map((d: any) => ({...d, shareId: s.id}) ?? [])).filter(d => !!d);
    let uploads = allDocs.filter(d => d.direction === "fromClient" && !!d.requestedAt);
    //check to see if there are any unseen conversations on the doc
    uploads = uploads.map(doc => dirty.find(d => d.shareId === doc.shareId && d.id === doc.id) ? ({...doc, isDirty: true}) : doc);

    return uploads;
  }
);

export const selectPetitionerUploads = createSelector(
  [_getShares, _dirtyConversations],
  (shares, dirty) => {
    if(!shares) return [];
    const allDocs = shares.flatMap(s => s.documents?.map((d: any) => ({...d, shareId: s.id}) ?? [])).filter(d => !!d);
    let uploads = allDocs.filter(d => d.direction === "fromClient" && (!d.requestedAt || !!d.filePath));  //exclude requests that haven't been fulfilled
    //check to see if there are any unseen conversations on the doc
    uploads = uploads.map(doc => dirty.find(d => d.shareId === doc.shareId && d.id === doc.id) ? ({...doc, isDirty: true}) : doc);

    return uploads;
  }
);

export const selectPetitionerDoc = createSelector(
  [_getShares, _inItemId],
  (shares, docId) => {
    if(!shares || !docId) return null;
    const doc = shares.flatMap(s => s.documents?.map((d: any) => ({...d, shareId: s.id}))).find(d => d.id === docId);
    if(doc.direction === "toClient" && !doc.isSharedWithClient) return null;   //this is only for docs the attorney shared with the client
    return doc ?? null;
  }
);

export const selectPetitionerTopicGroups = createSelector(
  [_getShares, _getProviders],
  (shares, providers) => {
    if(!shares) return [];
    const groups = shares.reduce<TopicGroupItem[]>((acc, s) => {
      const provider = providers.find(p => p.id === s.accountId);
      let reviewerName = "Attorney";
      if(provider) reviewerName = provider.name;
      const topicGroup: TopicGroupItem = {
        id: s.id,
        baseUrl: `/app/conversations`,
        label: reviewerName, 
        topics: [],
      };

      const formTopics = s.forms?.map((f: any) => ({ topicType: "f", topicId: f.formId, topicName: f.formName })) ?? [];
      const docTopics = s.documents?.map((d: any) => ({ topicType: "d", topicId: d.id, topicName: d.name })) ?? [];
      topicGroup.topics = orderBy([...formTopics, ...docTopics], ["topicName"], ["asc"]);
      acc.push(topicGroup);
      return acc;
    }, []);

    return groups;
  }
);

export const selectCurrentForm = createSelector(
  [_getCurrentFormId, _getForms],
  (formId, forms) => {
    if(!formId || !forms) return null;
    return forms.find(f => f.id === formId) ?? null;
  }
);

export const selectNavCounts = createSelector(
  [selectPetitionerForms, selectPetitionerDocs, selectPetitionerUploadRequests, selectPetitionerUploads, _dirtyConversations],
  (forms, docs, requests, uploads, dirty) => {
    
    if(!forms || !docs) return { toDoCount: 0, doneCount: 0, uploadsCount: 0, convoCount: 0 };

    let toDoCount = forms.filter(f => !f.status?.isSubmitted).length;
    toDoCount += docs.filter(d => !d.submittedAt).length;
    toDoCount += requests.filter(d => !d.filePath).length;

    let doneCount = forms.filter(f => f.status?.isSubmitted).length;
    doneCount += docs.filter(d => d.submittedAt).length;
    doneCount += requests.filter(d => !!d.filePath).length;

    let uploadsCount = uploads?.length ?? 0;

    return { toDoCount, doneCount, uploadsCount, convoCount: dirty.length };
  }
);