// import _ from 'lodash';
// import { watchFirebaseAuth } from 'config/firebase-config';
// import { TOS_KEY } from '../localstorage-keys';
import { trackEvent, Events } from 'helpers/analytics';
import rHelpers from 'store/reducers/reducer-helpers';

export const ONBOARDING_ACTIONS = {
  JOINED_WAITLIST   : 'JOINED_WAITLIST',
  INVITE_LOADED     : 'INVITE_LOADED',
  INVITE_UPDATED    : 'INVITE_UPDATED',
  TOS_AGREE         : 'TOS_AGREED',
  // WAITLIST_FAILED   : 'WAITLIST_FAILED',
};

export const joinWaitlist = (name, email, location) => async (dispatch, getState) => {
  trackEvent(Events.waitlist_joined, {label: location});

  //merge in a few additional fields
  const data    = {
    date        : rHelpers.getCurrentDateTime(), 
    name        : name,
    email       : email,
    location    : location,
    invitedOn   : null, 
    invitationId: null 
  };

  const result  = await dispatch({
    type      : ONBOARDING_ACTIONS.JOINED_WAITLIST,
    value     : location,
    firebase  : {
      type        : "createOnly",
      collection  : "waitlist",
      key         : email,        //use the email as the doc key to prevent dupes
      value       : data,
    },
    statusKey   : "waitlist",
  });

  return result;
}

export const getInvitation = (code) => async (dispatch, getState) => {
  trackEvent(Events.invitation_entered, {label: code});

  //TODO: if invite has already been used, don't validate it

  const result  = await dispatch({
    type      : ONBOARDING_ACTIONS.INVITE_LOADED,
    value     : code,
    firebase  : {
      type        : "getSingle",
      collection  : "invitations",
      key         : code,
    },
  });

  return result;
}

export const updateInvitation = (code, user) => async (dispatch, getState) => {

  const updates   = {
    acceptedOn  : rHelpers.getCurrentDateTime(), 
    acceptedBy  : user.email,
    userId      : user.uid, 
  };

  return dispatch({
      firebase    : {
          type        : "updateSingle",
          collection  : "invitations",
          key         : code,
          value       : updates,
      },
      type        : ONBOARDING_ACTIONS.INVITE_UPDATED
  });
}

// export const agreeToTos = () => async (dispatch, getState) => {
//   const profile  = getState().app.profile;

//   const acceptedOn    = rHelpers.getCurrentDateTime();
//   const updates   = {
//       tosAcceptedOn   : acceptedOn, 
//   };

//   return dispatch({
//       type        : ONBOARDING_ACTIONS.TOS_AGREE,
//       value       : acceptedOn,
//       localStorage: {
//           type        : "write_value",
//           key         : TOS_KEY,
//           value       : rHelpers.getCurrentDateTime(),
//       },
//       firebase    : {
//           type        : "updateSingle",
//           collection  : "profiles",
//           key         : profile.id,
//           value       : updates,
//       },
//   });
// }