import { useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Badge, Tooltip, Typography } from '@mui/material';
import { Forum as ForumIcon, Block as BlockIcon } from '@mui/icons-material';
import { selectConversations } from 'store/selectors/conversation-selectors';
import { useConversationId, useProfile } from 'helpers/selector-hooks';
import { useStatusMulti } from 'redux-action-status';
import { chooseConversation } from 'store/actions/conversation-actions';

const ConversationChooser = () => {
  const classes         = buildStyles();
  const dispatch = useDispatch();
  const conversations   = useSelector(selectConversations);
  const status          = useStatusMulti(["conversations", "comments"]);
  const profile         = useProfile();
  const conversationId = useConversationId();
  const { pathname } = useLocation();
  const isHide = !conversationId
  const hideUrl = `${pathname}`;

  useEffect(() => {
    if(conversations){
      const id  = conversationId || null;
      dispatch(chooseConversation(id));
    }
  }, [conversations, conversationId, dispatch]);
  
  if(!conversations || conversations.length === 0) return null;


  return (
    <Grid id="convo-selector" container className={classes.convoSelector} justifyContent="flex-start">

      <Tooltip title="Hide all comments">
        <Link to={hideUrl}>
          <Grid id="0" item container className={clsx(classes.convoGrid, {"selected": isHide})} justifyContent="center" alignItems="center">
            <BlockIcon fontSize="small" className={classes.icon}/>
            <Typography className={classes.convoText}>Hide Comments</Typography>
          </Grid>
        </Link>
      </Tooltip>

      <Grid item className={classes.separator} container>
        
      </Grid>
      
      { conversations.map(convo => (
        <ConversationCard 
          key={convo.id} 
          profile={profile} 
          convo={convo} 
          isWorking={status.isWorking} 
          classes={classes} 
        />
      ))}
    </Grid>
  );
}

export default ConversationChooser;

const buildStyles   = makeStyles(theme => ({
  convoSelector  : {
    // padding     : theme.spacing(1),
    "& a, a:hover"   : {
      textDecoration  : "none",
      "& svg"   : {
        color       : theme.palette.primary.main,
      }
    }
  },
  separator : {
    width       : theme.spacing(1),
    borderRight : `1px solid ${theme.palette.grey[300]}`, //`
    marginRight : theme.spacing(2),
  },
  convoGrid   : {
    border    : `1px solid ${theme.palette.grey[300]}`, //`
    borderRadius  : 5,
    background  : theme.palette.common.white,   
    padding     : `${theme.spacing(1)} ${theme.spacing(2)}`, //`
    marginRight : theme.spacing(1),
    cursor      : "pointer",
    flexWrap    : "nowrap",
    width       : "auto",
    "&.selected"  : {
      background    : `${theme.palette.primary.light}33`, //`
    },
    "&.disabled"  : {
      // background      : theme.palette.grey[100],
      cursor          : "default",
    }
  },
  convoText   : {
    fontSize    : 14,
    fontWeight  : 500,
    color       : theme.palette.primary.main,
    display     : "inline-block",
  },  
  icon  : {
    display     : "inline-block",
    marginRight : theme.spacing(1),
  },
  convoBadge  : {
    right       : theme.spacing(1),
  }
}));

function ConversationCard({profile, convo, isWorking, classes}){
  // const isSelected  = Boolean(selectedId === convo.reviewerId);
  const lastViewed  = profile.isUser ? convo.sharerViewedAt : convo.reviewerViewedAt;
  const newCount    = _.countBy(convo.comments, cmt => cmt.createdBy !== profile.uid && cmt.createdAt > lastViewed)?.true;
  const cardTip     = `Show comments from ${convo.invite?.senderName}`;
  const { pathname, search } = useLocation();
  const isSelected = search.includes(`conversation=${convo.reviewerId}`);
  const url = `${pathname}?conversation=${convo.reviewerId}`;
  
  if(newCount > 0){
    return (
      <Badge key={convo.id} badgeContent={newCount} color="secondary" classes={{badge: classes.convoBadge}}>
        <Tooltip title={cardTip}>
          <Link to={url}>
            <Grid id={convo.reviewerId} item container className={clsx(classes.convoGrid, {"selected": isSelected, "disabled": isWorking})} justifyContent="center" alignItems="center">
              <ForumIcon fontSize="small" className={classes.icon}/>
              <Typography className={classes.convoText}>{convo.invite?.senderName}</Typography>
            </Grid>
          </Link>
        </Tooltip>
      </Badge>
    );
  }
  else{
    return (
      <Tooltip title={cardTip}>
        <Link to={url}>
          <Grid id={convo.reviewerId} item container className={clsx(classes.convoGrid, {"selected": isSelected, "disabled": isWorking})} justifyContent="center" alignItems="center">
            <ForumIcon fontSize="small" className={classes.icon}/>
            <Typography className={classes.convoText}>{convo.invite?.senderName}</Typography>
          </Grid>
        </Link>
      </Tooltip>
    );
  }
}