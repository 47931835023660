import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AddCircleOutline, AddTaskOutlined, Close } from "@mui/icons-material";
import { Button, IconButton, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { toast } from "react-toastify";
import { ClientDocument, RowItem } from "types";
import { MultiSelectChip } from "components";
import { addClientDocumentRequest } from "store/actions/attorney-actions";
import { useClient } from "../../client-context";
import { Link } from "react-router-dom";

export interface IClientUploadRequestProps {
  items: RowItem<ClientDocument>[];
  onClose?: () => void;
}

export type ClientUploadRequest = {
  name: string;
  description: string;
}

const initialValues: ClientUploadRequest = { name: "", description: "" };

const ClientUploadRequestForm = ({ items, onClose }: IClientUploadRequestProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { clientId } = useClient();
  const commonRequests = useSelector<any, any[]>(state => state.account.account.settings?.clientUploads ?? null);

  const [mode, setMode] = useState<string>("common");
  const [values, setValues] = useState<ClientUploadRequest>(initialValues);
  const [commonItems, setCommonItems] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  //The common items that haven't already been assigned to this client
  const availableCommon = useMemo(() => {
    if(!commonRequests || !items) return [];
    return commonRequests.filter((r: any) => !items.find((i: any) => i.name === r.name));  
  }, [commonRequests, items]);

  const settingUrl = "/app/account/settings/clientUploads";

  const handleChange = (e: any) => {
    const key = e.target.id;
    const val = e.currentTarget.value;
    setValues({ ...values, [key]: val });
    if(error && key === "name") setError(null);
  }

  const handleCommonChange = (items: any[]) => {
    setCommonItems(items);
  }

  const handleSave = async () => {
    if(!values.name){
      setError("Name is required");
      return;
    }

    const result = await dispatch(addClientDocumentRequest(clientId, values)) as any;
    if(result.isOk){
      toast("Document request added successfully", { type: "success" });
      setValues(initialValues);
    }
  };

  const handleSaveCommon = async () => {
    if(!commonItems?.length){
      toast("Please select at least one item to add", { type: "error" });
      return;
    }

    const promises = await commonItems.map((item: any) => dispatch(addClientDocumentRequest(clientId, item)));
    const results = await Promise.all(promises);
    if(results.every((r: any) => r.isOk)){
      toast("Document requests added successfully", { type: "success" });
      setCommonItems([]);
    }    
  }

  return(
    <Grid container sm={12} p={2} columnGap={1} rowGap={1.5} sx={{ height: 160, bgcolor: alpha(theme.palette.info.light, 0.25), border: `solid ${theme.palette.info.main}`, borderWidth: "1px 0" }}>
      <Grid container sm={12} columnGap={1} alignItems="center">
        <AddTaskOutlined fontSize="small" color="info"/>
        <Typography variant="body2" color="secondary">Request a document from the client</Typography>

        <Stack direction="row" spacing={1} ml={2}>
          <ToggleButtonGroup size="small" value={mode}>
            <ToggleButton value="common" onClick={() => setMode("common")} sx={{ py: 0 }}>Common</ToggleButton>
            <ToggleButton value="custom" onClick={() => setMode("custom")} sx={{ py: 0 }}>Custom</ToggleButton>
          </ToggleButtonGroup>
        </Stack>

      </Grid>

      {mode === "common" && (
        <Grid container sm={12} rowGap={1}>
          <Grid sm={10}>
            {availableCommon?.length > 0 && (
              <Stack spacing={1}>
                <MultiSelectChip options={availableCommon} selected={commonItems} idProperty="name" displayProperty="name" onChange={handleCommonChange} placeholder="Choose a common upload request"/>
                <Typography variant="caption" fontStyle="italic" color="grey.600">Common requests can be managed in <Link to={settingUrl}>Account Settings</Link>.</Typography>
              </Stack>
            )}
            {(commonRequests?.length > 0 && availableCommon?.length <= 0) && (
              <Typography variant="caption" fontStyle="italic" ml={1}>All common document requests have been assigned.</Typography>
            )}
            {(!commonRequests || commonRequests?.length === 0) && (
              <Typography variant="caption" fontStyle="italic" ml={1}>No common requests available. Visit your <Link to={settingUrl}>Account Settings</Link> to add common upload requests.</Typography>
            )}
          </Grid>
          <Grid container sm={2} justifyContent="flex-end" alignItems="flex-start" columnGap={1}>
            <Tooltip title="Send request(s)">
              <>
                <Button onClick={handleSaveCommon} disabled={commonItems?.length <= 0} variant="outlined" size="small" color="primary">Save</Button>
              </>
            </Tooltip>
            <Tooltip title="Close request form">
              <IconButton onClick={onClose} size="small" color="inherit"><Close fontSize="small"/></IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {mode === "custom" && (
        <Grid container sm={12} rowGap={1}>
          <Grid container sm={12} columnGap={1} flexWrap="nowrap">
            <Grid container sm={4}>
              <TextField id="name" value={values.name} onChange={handleChange} required helperText={error} error={!!error} size="small" placeholder="Document Name*" variant="outlined" fullWidth sx={textSx}/>
            </Grid>
            <Grid container sm={6}>
              <TextField id="description" value={values.description} onChange={handleChange} size="small" placeholder="Description" variant="outlined" fullWidth sx={textSx}/>
            </Grid>
            <Grid container sm={2} justifyContent="flex-end" alignItems="flex-start" columnGap={1}>
              <Tooltip title="Send request">
                <>
                  <Button onClick={handleSave} disabled={!values.name} variant="outlined" size="small" color="primary" >Save</Button>
                </>
              </Tooltip>
              <Tooltip title="Close request form">
                <IconButton onClick={onClose} size="small" color="inherit"><Close fontSize="small"/></IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid container sm={12}>
            <Typography variant="caption" fontStyle="italic" color="grey.600">Enter a name and optional description, and click 'SAVE'.</Typography>
          </Grid>
        </Grid>
      )}

    </Grid>
  );
};

export default ClientUploadRequestForm; 

const textSx = {
  "& .MuiInputBase-root": { fontSize: "1rem", },
}