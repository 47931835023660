import { createSelector } from 'reselect';
import _ from 'lodash';
import { formatDate, formatDateString } from 'helpers/general-helpers';

const _getWaitlist      = state => state.admin.waitlist;
const _getInvitations   = state => state.admin.invitations;
const _getProfiles      = state => state.admin.profiles;
const _getAdmins        = state => state.admin.admins;
const _getAuditLog      = state => state.admin.auditLog;

const shortDateFormat   = "M/D/YY";

export const selectInvitations = createSelector(
  [_getInvitations, _getProfiles],
  (invites, profiles) => {
    if(!invites) return null;
    if(!profiles) return invites;

    const invitations = invites.map(inv => {
      const isAccepted = Boolean(inv.acceptedOn);
      const profile = isAccepted ? profiles.find(p => p.uid === inv.userId) : null;

      return {
        ...inv,
        isAccepted,
        status: isAccepted ? "accepted" : "invited",
        profile,
      };
    });

    return invitations;
  }
)

export const selectWaitlist = createSelector(
  [_getWaitlist, _getInvitations],
  (waitlist, invitations) => {
    if(!waitlist) return null;
    if(!invitations) return waitlist;

    const result  = _.map(waitlist, item => {
      const invite    = item.invitationId ? _.find(invitations, {id: item.invitationId}) : null;
      const isInvited = Boolean(item.invitedOn);
      const isAccepted= Boolean(invite && invite.acceptedOn);
    
      return {
        ...item, 
        status      : isAccepted ? "accepted" : (isInvited ? "invited" : "waiting"),
        isInvited,
        isAccepted,
        date            : formatDateString(item.date, shortDateFormat),
        dateLong        : formatDate(item.date),
        invitedOn       : item.invitedOn ? formatDateString(item.invitedOn, shortDateFormat) : "",
        invitedOnLong   : item.invitedOn ? formatDate(item.invitedOn) : "",
        acceptedOn      : invite?.acceptedOn ? formatDateString(invite.acceptedOn, shortDateFormat) : "",
        acceptedOnLong  : invite?.acceptedOn ? formatDate(invite.acceptedOn) : "",
        invite,
      };

    });

    return result;
  }
);

export const selectProfiles = createSelector(
  [_getProfiles, _getAdmins, _getInvitations],
  (profiles, admins, invitations) => {
    if(!profiles) return null;
    if(!invitations || !admins) return profiles;

    const result  = _.map(profiles, item => {
      const invite    = item.inviteId ? _.find(invitations, {id: item.invitationId}) : null;
      const admin     = _.find(admins, adm => adm.email === item.email);
      const other     = Boolean(admin) ? { role: "admin", isAdmin: true } : null;

      return {
        ...item, 
        ...other,
        invite,
      };

    });

    return result;
  }
);

export const selectAuditLog = createSelector(
  [_getAuditLog, _getProfiles],
  (auditLog, profiles) => {
    if(!auditLog) return null;
    if(!profiles) return auditLog;

    const result  = _.map(auditLog, item => {
      const profile   = _.find(profiles, p => p.uid === item.uid);
      
      return {
        ...item, 
        displayName   : profile?.displayName,
        email         : profile?.email,
        profile       : profile,
      };

    });

    return result;
  }
);