import React from 'react';
import withStyles from '@mui/styles/withStyles';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import WelcomePanel from './welcome-panel';
import { downloadRawData } from 'store/actions/value-actions';
import { trackEvent, Events } from 'helpers/analytics';

import contractBg from './contract.jpg';
const attribution   = {
  name    : "Cytonn Photography", //"Joanna Kosinska",
  handle  : "@cytonn_photography", //"@joannakosinska",
};
const subHead   = "You are not required to create an account, but it unlocks special features."

function WelcomeResume({classes, onResume, onOpen, onStartOver}){
  const dispatch  = useDispatch();
  const [isConfirmed, setConfirmed]   = React.useState(false);
  const [isOpen, setIsOpen]           = React.useState(false);
  const cbProps   = {'aria-label': 'primary checkbox',};

  const onFileChange = (e) => {
    const file  = e.target.files[0];
    onOpen(file);
  }

  const onDownload = () => {
    dispatch(downloadRawData());
    trackEvent(Events.download);
  }


  return (
    <WelcomePanel header="Welcome Back" subHeader={subHead} bgImage={contractBg} attribution={attribution}>
      
      <Grid container className={classes.actionRow} alignItems="center" justifyContent="center">
        <Typography variant="body2" className={classes.headline}>It looks like you've been here before.</Typography>
        <Button className={classes.actionButton} variant="outlined" color="secondary" onClick={onResume}>Pick up where I left off</Button>
        <Grid item xs={12} container justifyContent="center">or</Grid>
        
        <input accept=".json" className={classes.input} style={{ display: 'none' }} id="openButton" type="file" onChange={onFileChange} />
        <label htmlFor="openButton">
          <Button variant="outlined" component="span" color="primary" className={classes.actionButton}>
            Open a saved file
          </Button>
        </label> 
      </Grid>

      <Grid container className={classes.voteSection}>
        <Typography className={classes.info}>Want to start over?</Typography>   
        <Button className={classes.linkButton} color="primary" onClick={() => setIsOpen(!isOpen)}>Click here.</Button>           
      </Grid>
      {isOpen && 
        <Grid container className={classes.actionRow} alignItems="center" justifyContent="center">
          <Typography variant="body2">
            You can start over, however you'll lose all previously entered data unless you first download and save it.
          </Typography>
          <Grid item xs={12} container justifyContent="center">
            <FormGroup row>
              <FormControlLabel className={classes.actionCheck}
                control={
                  <Checkbox checked={isConfirmed} onChange={() => setConfirmed(!isConfirmed)} value="confirmed" inputProps={cbProps}/>        
                }
                label="I understand"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} container justifyContent="center">
            <Button className={classes.otherButton} variant="outlined" disabled={!isConfirmed} color="primary" onClick={onDownload}>Download</Button>
            <Button className={classes.otherButton} variant="outlined" disabled={!isConfirmed} color="secondary" onClick={onStartOver}>Start Over</Button>
          </Grid>
        </Grid>
      }
      </WelcomePanel>
  )
}

const styles = (theme =>({
  actionRow   : {
    margin    : `${theme.spacing(3)} ${theme.spacing(2)}`
  },
  actionButton  : {
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(1),
    // width       : 250,
  },
  actionCheck   : {
    "& span"    : {
      fontSize    : 15,
    },
  },
  headline : {
    marginBottom : theme.spacing(1),
  },
  otherButton  : {
    marginTop   : theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight : 10,
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  }, 
  linkButton  : {
    border    : "none",
    fontSize  : 18,
    fontWeight: 300,
    color     : theme.palette.primary.main,
    padding   : 0,
    marginLeft: theme.spacing(1),
    marginTop : theme.spacing(-1),
    textTransform : "lowercase",
  },
}));

export default withStyles(styles)(WelcomeResume);
