export const STATES   = [
  { id: "AL",   name: "Alabama",          code : "AL",  isWaitList: true },
  { id: "AK",   name: "Alaska",           code : "AK",  isWaitList: true },
  { id: "AZ",   name: "Arizona",          code : "AZ",  isWaitList: true },
  { id: "AR",   name: "Arkansas",         code : "AR",  isWaitList: true },
  { id: "CA",   name: "California",       code : "CA",  isWaitList: true },
  { id: "CO",   name: "Colorado",         code : "CO",  isWaitList: false },
  { id: "CT",   name: "Connecticut",      code : "CT",  isWaitList: true },
  { id: "DE",   name: "Delaware",         code : "DE",  isWaitList: true },
  { id: "FL",   name: "Florida",          code : "FL",  isWaitList: true },
  { id: "GA",   name: "Georgia",          code : "GA",  isWaitList: true },
  { id: "HI",   name: "Hawaii",           code : "HI",  isWaitList: true },
  { id: "ID",   name: "Idaho",            code : "ID",  isWaitList: true },
  { id: "IL",   name: "Illinois",         code : "IL",  isWaitList: true },
  { id: "IN",   name: "Indiana",          code : "IN",  isWaitList: true },
  { id: "IA",   name: "Iowa",             code : "IA",  isWaitList: true },
  { id: "KS",   name: "Kansas",           code : "KS",  isWaitList: true },
  { id: "KY",   name: "Kentucky",         code : "KY",  isWaitList: true },
  { id: "LA",   name: "Louisiana",        code : "LA",  isWaitList: true },
  { id: "ME",   name: "Maine",            code : "ME",  isWaitList: true },
  { id: "MD",   name: "Maryland",         code : "MD",  isWaitList: true },
  { id: "MA",   name: "Massachusetts",    code : "MA",  isWaitList: true },
  { id: "MI",   name: "Michigan",         code : "MI",  isWaitList: true },
  { id: "MN",   name: "Minnesota",        code : "MN",  isWaitList: true },
  { id: "MS",   name: "Mississippi",      code : "MS",  isWaitList: true },
  { id: "MO",   name: "Missouri",         code : "MO",  isWaitList: true },
  { id: "MT",   name: "Montana",          code : "MT",  isWaitList: true },
  { id: "NE",   name: "Nebraska",         code : "NE",  isWaitList: true },
  { id: "NV",   name: "Nevada",           code : "NV",  isWaitList: true },
  { id: "NH",   name: "New Hampshire",    code : "NH",  isWaitList: true },
  { id: "NJ",   name: "New Jersey",       code : "NJ",  isWaitList: true },
  { id: "NM",   name: "New Mexico",       code : "NM",  isWaitList: true },
  { id: "NY",   name: "New York",         code : "NY",  isWaitList: true },
  { id: "NC",   name: "North Carolina",   code : "NC",  isWaitList: true },
  { id: "ND",   name: "North Dakota",     code : "ND",  isWaitList: true },
  { id: "OH",   name: "Ohio",             code : "OH",  isWaitList: true },
  { id: "OK",   name: "Oklahoma",         code : "OK",  isWaitList: true },
  { id: "OR",   name: "Oregon",           code : "OR",  isWaitList: true },
  { id: "PA",   name: "Pennsylvania",     code : "PA",  isWaitList: true },
  { id: "RI",   name: "Rhode Island",     code : "RI",  isWaitList: true },
  { id: "SC",   name: "South Carolina",   code : "SC",  isWaitList: true },
  { id: "SD",   name: "South Dakota",     code : "SD",  isWaitList: true },
  { id: "TN",   name: "Tennessee",        code : "TN",  isWaitList: true },
  { id: "TX",   name: "Texas",            code : "TX",  isWaitList: true },
  { id: "UT",   name: "Utah",             code : "UT",  isWaitList: true },
  { id: "VT",   name: "Vermont",          code : "VT",  isWaitList: true },
  { id: "VA",   name: "Virginia",         code : "VA",  isWaitList: true },
  { id: "WA",   name: "Washington",       code : "WA",  isWaitList: true },
  { id: "WV",   name: "West Virginia",    code : "WV",  isWaitList: true },
  { id: "WI",   name: "Wisconsin",        code : "WI",  isWaitList: true },
  { id: "WY",   name: "Wyoming",          code : "WY",  isWaitList: true },
];

export const COUNTIES  = [
  { id: "not-here",     state   : "all",  name : "Not Here",  isWaitList : true, },
  { id: "co-boulder",   state   : "co",   name : "Boulder",   isWaitlist : false, },
  { id: "co-other",     state   : "co",   name : "Other",     isWaitlist : true, },
  { id: "ma-middlesex", state   : "ma",   name : "Middlesex", isWaitlist : true, },
  { id: "ma-other",     state   : "ma",   name : "Other",     isWaitlist : true, },
  { id: "pa-other",     state   : "pa",   name : "Other",     isWaitlist : true, },
];