import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Stack, Typography, useMediaQuery } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { makeStyles } from "@mui/styles";
import { useStatusMulti } from "redux-action-status";
import { ErrorStatus } from "components";
import SharingRequestItem from "../my/parts/sharing-request-item";
import { StatusKeys } from "helpers/status-keys";
import PetitionerTasksList from "./petitioner-tasks/petitioner-tasks-list";
import { chooseConversation } from "store/actions/conversation-actions";
import PetitionerUploadsList from "./petitioner-uploads/petitioner-uploads-view";
import PetitionerHomeHeader from "./parts/petitioner-home-header";
import PetitionerHomeNav from "./parts/petitioner-home-nav";
import { selectPrimaryProvider } from "store/selectors/petitioner-selectors";
import { signOut } from "store/actions/app-actions";

const PetitionerHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShort = useMediaQuery('(max-height:750px)');
  const { tab = "todo" } = useParams();
  const classes = buildStyles();
  const status = useStatusMulti([StatusKeys.shares, StatusKeys.petitioner]);
  const invites = useSelector(state => state.share.invitations);
  const providers = useSelector(state => state.petitioner.providers);
  const primaryProvider = useSelector(selectPrimaryProvider);
  
  const heightOffset = isShort ? 240 : 310;

  //Only show pending invites that have a shareId on them.
  const pending = invites?.filter(i => !!i.shareId && (!i.status || i.status === 0)) ?? [];
  const hasPending = Boolean(pending.length > 0);

  const unrecognized = status.isInitialized && !primaryProvider && !hasPending;

  useEffect(() => {
    dispatch(chooseConversation(null));
  }, []);

  const handleTabSelected = (id) => {
    if(id === tab) return;
    else if(id === "todo") navigate("/app", { replace: true });
    else if(id.indexOf("/my") < 0) navigate(`/app/${id}`, { replace: true });
    else navigate(id);
  }

  const handleSignOut = async () => {
    await dispatch(signOut());
  }

  return (
    <Grid id="home-root" container xs={12} sx={{overflowY: "hidden"}}>

      <PetitionerHomeHeader unrecognized/>

      {unrecognized && (
        <Stack width="100%" display="flex" justifyItems="center" alignItems="center" mt={3}>
          <Typography variant="h6" color="primary" noWrap>Hello there!</Typography>
          <Typography maxWidth="75%" textAlign="center" variant="body1" fontSize="1.2rem" fontWeight="300" mb={1}>FormHop is available by invitation only.</Typography>
          <Typography maxWidth="75%" textAlign="center" variant="body1" fontSize="1.2rem" fontWeight="300">If you were directed here by your lawyer, ask them to invite you at this email address.</Typography>
          <Button onClick={handleSignOut} variant="outlined" color="primary" sx={{ mt: 2 }}>Sign Out</Button>
        </Stack>
      )}

      {hasPending && (
        <Grid xs={12} container justifyContent="center" my={4}>
          <Grid width="60%" container className={classes.cardGrid}>
            <Grid container className={classes.pendingRow}>
              <ErrorStatus statusKey={StatusKeys.shares} sx={{ width: "100%", mb: 2 }}/>
              {pending.map(p => <SharingRequestItem key={p.id} invite={p} providers={providers}/>)}                  
            </Grid>

          </Grid>
        </Grid>
      )}

      {primaryProvider && (
        <>
          <PetitionerHomeNav selected={tab} onSelected={handleTabSelected} />
          <Box id="petitioner-home-content" px={4} width="100%" height={`calc(100vh - ${heightOffset}px)`} bgcolor="grey.200" sx={{ overflowY: "auto" }}> 
            { tab === "todo" && <PetitionerTasksList filter="incomplete" /> }
            { tab === "done" && <PetitionerTasksList filter="complete" /> }
            { tab === "uploads" && <PetitionerUploadsList /> }
          </Box>
        </>
      )}

    </Grid>
  )
}

export default PetitionerHome;

const buildStyles = makeStyles(theme => ({
  tableRow: {
    "&:last-child": {
      "& td" : {
        borderBottom: "none"
      },
    },
  }
}));