import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isString } from "lodash";
import { Checkbox, Chip, Menu, MenuItem, Stack, TableCell, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AttachFile, Cancel, CheckCircle, CloudOutlined, SettingsSuggestOutlined, Share } from "@mui/icons-material";
import { useMeasure } from "react-use";
import { toast } from 'react-toastify';
import { useAnchor } from "helpers/hooks";
import { formatDateString, formatShortDate } from "helpers/general-helpers";
import { updateClientDocument } from "store/actions/attorney-actions";
import { AssignedDocument } from "../../../../types/client-document-types";
import { useClient } from "../../client-context";
import { ClientAssignmentChip } from "../chips";
import { useAttorneyContext } from "../../attorney-context";

export interface IClientDocumentItemProps {
  document: AssignedDocument;
  onChecked: (e: any) => void;
  onSelected: (e: any) => void;
  onHover: (isEntering: boolean) => (e: any) => void;
  onShare: () => void;
}

const ClientDocumentItem = ({ document, onChecked, onSelected, onHover, onShare }: IClientDocumentItemProps) => {
  const [cellRef, { width: cellWidth } ] = useMeasure();  
  const theme = useTheme();
  const dispatch = useDispatch();
  const { members } = useAttorneyContext();
  const { clientId } = useClient();
  const fileExtension = useMemo(() => document.originalFileName.split(".").pop(), [document.originalFileName]);
  const isApproved = !!document.submittedAt;
  const nameProps = {component: Link, to: `/app/clients/${clientId}/documents/${document.id}`};

  const toggleShared = () => {
    console.log("toggle shared to", !document.isSharedWithClient);
    onShare();
  };

  const handleAssign = async (e: any) => {
    let uid   = isString(e) ? e : e.target.value;
    if(uid === "") uid = null;
    const result: any = await dispatch(updateClientDocument(clientId, document.id, { assignedTo: uid }));
    if(result.isOk){
      toast.success("Document assigned successfully");
    }
  };
  
  return (
    <TableRow key={document.id} sx={{backgroundColor: (document.isSelected ? alpha(theme.palette.primary.light, 0.25) : undefined)}} >
      <TableCell width={36} sx={{ px: 0 }}>
        <Checkbox value={document.isChecked} onChange={onChecked} />
      </TableCell>
      <TableCell width={36} sx={{ px: 0 }}>
        <Grid container justifyContent="center" alignItems="center">
          { document.templateId && (
            <Tooltip title={`this document was generated on ${formatDateString(document.createdAt)}`}>
              <SettingsSuggestOutlined fontSize="small" color="primary"/>
            </Tooltip> 
          )}
          {!document.templateId && (
            <Tooltip title="Uploaded document">
              <AttachFile fontSize="small" color="primary" />
            </Tooltip>
          )}
        </Grid>
      </TableCell>
      <TableCell onClick={onSelected} ref={cellRef} sx={{ maxWidth: "28rem" }}>
        <Grid container alignItems="center">          
          <Typography {...nameProps} sx={{ width: cellWidth - 48, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{document.name}</Typography>
        </Grid>
      </TableCell>
      <TableCell align="center">
        <Grid container justifyContent="center" alignItems="center" columnGap={1} flexWrap="nowrap">
          <ClientAssignmentChip assignedTo={document.assignedTo} members={members} onChange={handleAssign} variant="subtle" />
          { document.syncId && (
            <Tooltip title={`This document was synced on ${formatShortDate(document.syncedAt)}`}>
              <CloudOutlined fontSize="small" sx={{ color: "grey.600" }}/>
            </Tooltip> 
          )}
          <Tooltip title="File type">
            <Chip size="small" label={fileExtension} />
          </Tooltip>
        </Grid>
      </TableCell>
      <TableCell onClick={onSelected}>
        {isApproved && (
          <Tooltip title={`The client approved this document on ${formatShortDate(document.submittedAt)}`}>
            <Chip icon={<CheckCircle fontSize="small" />} label="Approved" color="primary"/>
          </Tooltip>
        )}
        {!isApproved && (<SharedChip isShared={!!document.isSharedWithClient} onToggle={toggleShared} />)}
      </TableCell>
      <TableCell onClick={onSelected}>{document.dueAt ? formatShortDate(document.dueAt) : ""}</TableCell>
      <TableCell onClick={onSelected}>
        <Stack direction="row" spacing={1}>
        {/*   <Tooltip title="Download this document">
            <IconButton onClick={handleDownload} size="small" color="secondary">
              <CloudDownload fontSize="small" />
            </IconButton>
          </Tooltip>
        */}
          {/* {(document.description) && (
            <>
              <IconButton size="small" color="secondary" onMouseEnter={onHover(true)} onMouseLeave={onHover(false)}>
                <Notes fontSize="small" />
              </IconButton>                         
            </>
          )}                       */}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ClientDocumentItem;

const SharedChip = ({ isShared, onToggle }: { isShared: boolean, onToggle?: () => void }) => {
  const [anchor, isOpen, onOpen, onClose]  = useAnchor();
  
  const onItemClicked = () => {
    onClose();
    onToggle!();
  }

  return (
    <>
      <Chip
        onClick={(e) => isOpen ? onClose() : onOpen(e)}
        label={isShared ? "Shared" : "Not Shared"}
        color={isShared ? "primary" : "default"}
        size="small"
        variant="outlined"
        sx={{ py: 2, px: 1 }}
        icon={isShared ? <Share color="primary" /> : <Cancel />}
      />
      {onToggle && (
        <Menu id="moreMenu" anchorEl={anchor} open={isOpen} onClose={onClose}>
          <MenuItem onClick={onItemClicked} disabled={isShared}>Share document</MenuItem>
          <MenuItem onClick={onItemClicked} disabled={!isShared}>Do not share document</MenuItem>              
        </Menu>
      )}
    </>
  )
};