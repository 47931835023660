import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux"
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Divider, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { AddCircleOutline, Refresh } from "@mui/icons-material";
import { ProviderAccount } from "types";
import { tableStyles } from "helpers/style-helpers";
import { InviteDialog, SearchField } from "components";
import { useAppContext } from "sections/app/app-context";
import { inviteMemberApi, loadAccountMembers, removeInvitation, removeMember } from "store/actions/account-actions";
import { toast } from "react-toastify";
import AccountMememberListItem from "./account-member-list-item";

const sortProp = (prop: string) => (item: any): string => {
  if(!item) return "";
  switch(prop){
    case "email": return item.email;
    
    case "name":
    default:
      return item.profile?.displayName ?? `${item.firstName} ${item.lastName}`;

    case "role":
      return item.role ?? "invited";
    //TODO: sort by other props
  }
}

const AccountMemberList = () => {
  const dispatch = useDispatch();
  const { uid } = useAppContext();
  const account = useSelector<any, ProviderAccount>(state => state.account.account);
  const me = account?.members.find(m => m.id === uid);
  const isAccountAdmin = me?.role === "admin";

  const items = useMemo(() => {
    const allItems = [...account?.members ?? [], ...account?.invitations ?? []];
    const sorter = sortProp("name");
    return allItems.sort((a, b) => sorter(a).localeCompare(sorter(b)));
  }, [account]);

  
  const [isAdding, setAdding] = useState(false);

  const handleInvite = async (invitation: any) => {
    const result = await dispatch(inviteMemberApi(invitation)) as any;
    if(result.isOk){
      toast.success("Invitation sent");
    }
  };

  const onRefresh = async () => {
    await dispatch(loadAccountMembers());
  }

  const onSearchChange = (val: string) => {
    console.log("Search for", val);
  }

  const handleEdit = (member: any) => {
    console.log("Edit member", member);
  }

  const handleDelete = async (item: any, isMember: boolean) => {
    const itemType = isMember ? "Member" : "Invitation";
    
    let result: any;
    if(isMember){
      result = await dispatch(removeMember(item.id)) as any;
    }
    else {
      result = await dispatch(removeInvitation(item.id)) as any;
    }
    // const result = await dispatch(removeMember(member.id)) as any;
    
    if(result.isOk){
      toast.success(`${itemType} removed`);
    }
  }

  return (
    <Box width="100%">
      <Paper id="account-member-list" variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: "0 0 8px 8px" }}>
        <Stack id="list-content">
          <Grid id="card-header" container gap={1} alignItems="center" flexWrap="nowrap" sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid", backgroundColor: "grey.200" }}>
            
            <Grid container sm={6} gap={2} alignItems="center">
              {/* <Typography variant="h5" sx={{ fontSize: "1.2rem", fontWeight: "700", color: "grey.800" }}>My Team</Typography>
              <Divider orientation="vertical" flexItem /> */}
              {isAccountAdmin && (
                <Button onClick={() => setAdding(true)} size="small" endIcon={<AddCircleOutline />}>Add</Button>
              )}
                          
            </Grid>

            <Grid container sm={6} gap={2} flexWrap="nowrap" alignItems="center" justifyContent="flex-end">
              <Divider orientation="vertical" flexItem />
              
              <SearchField placeholder="search" onChange={(val) => onSearchChange(val)} />

              <Tooltip title="Refresh this list">
                <IconButton size="small" color="secondary" sx={{ float: "right" }} onClick={onRefresh}>
                  <Refresh fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          {/* <Grid id="filter-row" container gap={2} sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid" }}>
            
            <Grid container gap={2} alignItems="center">
              <SearchField placeholder="search" onChange={(val) => onSearchChange(val)} />
            </Grid>
            <Grid id="filter-column" container gap={2} alignItems="center">
            </Grid>
            
          </Grid> */}

          <TableContainer id="member-list">

            <Table id="members-table" size="small" sx={{ fontSize: "1.1rem" }}>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ ...tableStyles.headerCell, fontWeight: "700", color: "grey.800" }}>Name</TableCell>
                  <TableCell sx={{ ...tableStyles.headerCell, fontWeight: "700", color: "grey.800" }}>Email</TableCell>
                  <TableCell sx={{ ...tableStyles.headerCell, fontWeight: "700", color: "grey.800" }}>Role</TableCell>
                  <TableCell sx={{ ...tableStyles.headerCell, fontWeight: "700", color: "grey.800" }}></TableCell>
                  <TableCell sx={{ ...tableStyles.headerCell, fontWeight: "700", color: "grey.800" }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {items?.map(member => <AccountMememberListItem key={member.id} account={account} item={member} onEdit={isAccountAdmin ? handleEdit: undefined} onDelete={isAccountAdmin ? handleDelete: undefined} />)}
              </TableBody>
            </Table>

          </TableContainer>

        </Stack>
      </Paper>

      {isAdding && <InviteDialog isOpen={isAdding} onClose={() => setAdding(false)} sendCallback={handleInvite} type="member" />}
    </Box>
  );
};

export default AccountMemberList;