import { useState } from "react";
import { Close, Search } from "@mui/icons-material";
import { Divider, IconButton, InputBase, Paper } from "@mui/material";
import { useDebounce } from "react-use";

export interface ISearchFieldProps {
  onChange: (filter: string) => void;
  placeholder?: string;
}

const SearchField = ({ onChange, placeholder = "filter" }: ISearchFieldProps) => {
  const [filterString, setFilterString] = useState("");
  
  const onClear = () => {
    setFilterString("");
    onChange("");
  }

  const [, cancel] = useDebounce(() => onChange(filterString?.toLowerCase()), 250, [filterString]);

  return (
    <Paper elevation={0} sx={{ width: '18rem', p: "0 4px", display: "flex", alignItems: "center", border: "0.5px solid", borderRadius: 1, borderColor: "grey.400" }}>
      <IconButton onClick={() => onChange(filterString)}  type="button" sx={{ p: '2px 4px' }} aria-label="search">
        <Search fontSize="small" />
      </IconButton>
      <InputBase id="search-field"
        value={filterString}
        onChange={(e) => setFilterString(e.target.value)}
        size="small" 
        placeholder={placeholder}     
        sx={{ fontSize: "1rem", flexGrow: 1, "& input": { pb: 0} }}   
      />
      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
      <IconButton onClick={onClear} color="primary" sx={{ p: '2px 4px' }} aria-label="directions">
        <Close fontSize="small"/>
      </IconButton>
    </Paper>
  )
};

export default SearchField;