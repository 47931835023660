import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatus } from "redux-action-status";
import { ClientProfile } from "types";
import { StatusKeys } from "helpers";
import { LoadingBar } from "components";
import DataTable from "components/values-table";
import FormProvider, { useFormContext } from "./form-context";
import { ShareProvider, useShareContext } from "../app/share-context";
import ProviderFormProperties from "./provider-form-properties";
import RegionProvider from "../financials/parts/region-context";
import { ConversationProvider } from "components/values-table/conversation-context";
import { loadClientValues } from "store/actions/attorney-actions";
import { chooseConversation } from "store/actions/conversation-actions";
import ProviderFormHeader from "./provider-form-header";

const ProviderFormViewCore = () => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const navigate = useNavigate();
  const { form } = useFormContext();
  const { share } = useShareContext();
  const client = useSelector<any, ClientProfile | undefined>(state => state.attorney.currentClient);
  const clientValues = useSelector<any, any>(state => state.attorney.clientData);
  const editRegionId = useSelector<any, any>(state => state.attorney.editRegionId);
  const status = useStatus(StatusKeys.forms);  
  const convoStatus = useStatus(StatusKeys.conversations);
  const clientName = useMemo(() => `${client?.firstName} ${client?.lastName}`.trim(), [client]);
  const isEditing = !!editRegionId;

  const [isPropsVisible, setPropsVisible] = useState(true);

  const handleRefresh = useCallback(async () => {
    await dispatch(loadClientValues(share!.sharer));
    return;
  }, [share, dispatch]);

  useEffect(() => {
    if(share && !clientValues) handleRefresh();
  }, [clientValues, share, handleRefresh]);

  useEffect(() => {
    if(convoStatus.isInitialized && !!share) dispatch(chooseConversation(share.id, form.id, "f"));
  }, [convoStatus.isInitialized, share, form, dispatch]);

  const onGoBack = () => {
    navigate(`/app/clients/${clientId}/documents`);
  }

  const togglePropsVisible = () => {
    setPropsVisible(!isPropsVisible);
  }

  return (
    <Grid id="provider-form-view" container xs={12} px={4} pt={1}>
      {status.isWorking && (
        <LoadingBar message="Working..." />
      )}

      {/* <ErrorStatus statusKey="forms" sx={{mb: 1}} /> */}

      {/* Header */}
      <ProviderFormHeader 
        formName={form?.formName ?? ""} 
        clientName={clientName} 
        onClose={onGoBack} 
        showProperties={isPropsVisible} 
        onToggleProperties={togglePropsVisible} 
      />

      {/* Body */}

      <Grid id="provider-form-view-body" container xs={12} mt={2} spacing={2} columnGap={2} flexWrap="nowrap">
        
        {isPropsVisible && !isEditing && (
          <Grid container sm={6} md={4} lg={3} sx={{ display: { xs: "none", sm: "flex" }}}>
            <ProviderFormProperties onHide={togglePropsVisible} onRefresh={handleRefresh}/>
          </Grid>
        )}
        
        <Grid container xs alignContent="flex-start">
          <Grid id="data-table-container" container xs={12} alignItems="flex-start" flexGrow={1}>
            <RegionProvider>
              <DataTable isFirstExpanded={false}/>
            </RegionProvider>
          </Grid>
        </Grid>
      </Grid>

    </Grid>
  );
};

const ProviderFormView = () => {
  return (
    <ShareProvider>
      <ConversationProvider>
        <FormProvider>
          <ProviderFormViewCore />
        </FormProvider>
      </ConversationProvider>
    </ShareProvider>
  );
};

export default ProviderFormView;