import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Card from '@mui/material/Card';
import clsx from 'clsx';

function StatusCard({message, status, isVisible, result, children, ...otherProps}){
  
  if(!isVisible || (!message && !children)) return null;

  const classes   = buildStyles();
  
  if(result){
    //TODO: Automatically show the result information
  }

  const className   = status ? classes[status] : classes.default;
  const cardClass   = `card-${status}`;

  return (
    <Card elevation={0} className={clsx(className, classes.default, cardClass, otherProps?.className)} variant="outlined">
      <span>{message}</span>
      {children}
    </Card>
  )
}

export default StatusCard;

StatusCard.propTypes  = {
  message   : PropTypes.string,
  status    : PropTypes.oneOf(["ok", "warning", "error"]).isRequired,
  isVisible : PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

const buildStyles   = makeStyles(theme => ({
  default   : {
    borderRadius    : 5,
    margin          : `${theme.spacing(1)} ${theme.spacing(0.5)}`,
    padding         : theme.spacing(1),
    position        : "relative",
    width           : "100%",
  },
  error     : {
    background      : alpha(theme.palette.error.light, 0.2),
    border          : `1px solid ${theme.palette.error.dark}`,
    color           : theme.palette.error.main,    
  },
  ok      : {
    background      : `${theme.palette.primary.light}33`,
    border          : `1px solid ${theme.palette.primary.dark}`,
    color           : theme.palette.primary.main,
  },
  warning : {
    background      : "#fff3cd",
    border          : `1px solid #ffeeba`,
    color           : "#856404",
  }
}));