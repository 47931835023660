import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import InvitedIcon from '@mui/icons-material/MailOutline';
import AcceptedIcon from '@mui/icons-material/DoneAll';
import WaitingIcon from '@mui/icons-material/HourglassEmpty';

const StatusChip = ({status}) => {

  const classes   = buildStyles();

  return (
    <Tooltip title={status || ""}>
      <Avatar className={clsx(classes[status], classes.chip)}>
        {ICONS[status]}
      </Avatar>
    </Tooltip>    
  );
}

export default StatusChip;

const buildStyles   = makeStyles(theme => ({
  chip      : {
    height      : theme.spacing(3),
    width       : theme.spacing(3),
    fontSize      : 14,
    fontWeight    : 300,
    "& svg"   : {
      // fontSize    : 12,
      height      : 14,
      width       : 14,
      color       : theme.palette.grey[700],
    }
  },
  waiting   : {
    backgroundColor        : `${theme.palette.grey[500]}55`, //`
  },
  invited   : {
    backgroundColor        : `${theme.palette.secondary.main}55`, //`
  },
  accepted  : {
    backgroundColor        : `${theme.palette.primary.main}55`, //`
  },
}));

const ICONS   = {
  waiting   : <WaitingIcon fontSize="small" />,
  invited   : <InvitedIcon fontSize="small" />,
  accepted  : <AcceptedIcon fontSize="small" />
};