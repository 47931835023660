import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';
import { ProviderAccount } from 'types';
import { LoadingBar } from 'components';
import PracticeAccountView from './parts/provider-accounts/practice-account-view';

const ProviderAccountView = () => {
  const isInitialized = useSelector<any, boolean>(state => state.account.isInitialized);
  const account = useSelector<any, ProviderAccount>(state => state.account.account);

  return (
    <>
      { !isInitialized &&  <LoadingBar />}
      { isInitialized && account?.accountType === "practice" && <PracticeAccountView /> }
      { isInitialized && account?.accountType === "enterprise" && <Box sx={{ p: 4 }}><Typography variant="h5">Coming Soon...</Typography></Box> }
    </>
  );
}

export default ProviderAccountView;