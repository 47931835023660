import _, { find } from 'lodash';
import { trackEvent, Events } from 'helpers/analytics';
import { StatusKeys } from 'helpers/status-keys';
import { REFRESH_INTERVAL } from 'helpers/model-helpers';

const CONVO_LOADED = "CONVERSATION_LOADED";
const CONVOS_LOADED = "CONVERSATIONS_LOADED";
const CONVO_CREATED = "CONVERSATION_CREATED", TOPIC_CREATED = "TOPIC_CREATED";
const COM_CREATING    = "COMMENT_CREATING", COM_CREATED = "COMMENT_CREATED"; //, COM_CREATE_FAILED = "COMMENT_CREATE_FAILED";
const CONVO_CLEARED  = "CONVERSATION_CLEARED";
const CONVO_TOUCHED  = "CONVERSATION_TOUCHED";
const COMMENTS_REFRESHED = "COMMENTS_REFRESHED", COMMENTS_RESOLVED = "COMMENTS_RESOLVED";

export const CONVO_ACTIONS  = {
  CONVO_LOADED,
  CONVOS_LOADED,
  CONVO_CREATED,
  TOPIC_CREATED,
  COM_CREATING, COM_CREATED, // COM_CREATE_FAILED,
  CONVO_CLEARED, 
  CONVO_TOUCHED,
  COMMENTS_REFRESHED,
  COMMENTS_RESOLVED,
};

//---
// Loads all the conversations the current user is having
export const loadMyConversations = () => async(dispatch, getState) => {
  const profile   = getState().app.profile;
  const isUser    = (profile.role === "user");
  if(!isUser && !profile.accountId) return null; //no account id, so can't load the conversations
  
  const query     = isUser ? ["sharerId", "==", profile.uid] : ["accountId", "==", profile.accountId];

  const result  = await dispatch({
    type    : CONVOS_LOADED,
    firebase: {
      type            : "getList",
      collection      : "conversations",
      query           : query,
      hydrate         : true,
      subCollections: ["topics"],
    },
    isUser: isUser,
    uid: profile.uid,
    statusKey: StatusKeys.conversations,
  });

  return result;
};

//---
// Loads a conversation based on a specific share
export const loadTopic = (shareId, topicId, topicType) => async(dispatch, getState) => {

  const result  = await dispatch({
    type        : CONVO_LOADED,
    firebase  : {
      type        : "getSingle",
      collection  : `conversations/${shareId}/topics`,
      key         : topicId,
      subCollections  : ["comments", "participants"],
      hydrate     : true,
      mayNotExist : true,         //indicates a permission denied shouldn't automatically be an error
    },
    shareId,
    topicId,
    topicType,
    statusKey : StatusKeys.conversations,
  });

  //the reducer automatically selects the conversation that was just loaded
  //Also flag this as the selected conversation
  // await dispatch({type: CONVO_SELECTED, shareId, topicId, topicType});
  return result;
};

export const refreshCurrentComments = (force = false) => async (dispatch, getState) => {
  const state = getState();
  const { shareId, topicId, topicType } = state.convo.selected;
  const topic = state.convo.topic;
  if(!shareId || !topicId || !topic) return null;
  // const topic = state.convo.conversations[shareId]?.topics.find(t => t.id === topicId);

  if(!force){
    const lastRefreshed = topic?.refreshedAt || 0;
    //make sure it wasn't refreshed within the last 10 seconds
    if((Date.now() - lastRefreshed) < REFRESH_INTERVAL) return null;  //don't refresh too often
  }

  const result = await dispatch({
    type: COMMENTS_REFRESHED,
    firebase: {
      type: "getList",
      collection: `conversations/${shareId}/topics/${topicId}/comments`,
      hydrate: true,
      mayNotExist: true,
    },
    shareId,
    topicId,
    topicType,
    statusKey: StatusKeys.conversations,    //might want to change to a background status, so it doesn't cause loading indicators
  });

  return result;
};


export const chooseConversation = (shareId, topicId, topicType) => async (dispatch, getState) => {
  const state   = getState();
  const current = state.convo.selected || {};
  if(shareId === current.shareId && topicId === current.topicId) return null; //no change

  //if there's a topic selected, get the comments for the conversation
  if(shareId && topicId && topicType){
    const result = await dispatch(loadTopic(shareId, topicId, topicType));
    await dispatch(viewedConversation());
    return result;
  }
  else {
    return await dispatch({type: CONVO_CLEARED, shareId, topicId, topicType});
  }
};

//marks the conversation as viewed by the current user
// does this by writing (or updating) a document at /conversations/{shareId}/topics/{topicId}/participants/{userId}
// with the viewedAt timestamp
const viewedConversation = (isTouched) => async(dispatch, getState) => {
  const state = getState();
  const profile = state.app.profile;
  const conversations = state.convo.conversations;
  const isReviewer = profile.role === "reviewer";
  const selected = state.convo.selected;
  const { shareId, topicId } = selected;

  if(!conversations || !shareId || !topicId) { console.warn("No current topic selected"); return null; }
  const convo = conversations[shareId];
  const topic     = convo ? convo.topics?.find(t => t.id === topicId) : null;
  if(!topic) { console.warn("No topic found to update", shareId, topicId); return null; }
  
  // const path = `conversations/${shareId}/topics/${topicId}`;
  const now = Date.now();
  const modelUpdates = isReviewer ? {
    viewed: { [profile.uid]: now, },
    ...(isTouched ? { touched: { [profile.uid]: now } } : {}),
    ...(isTouched ? { reviewerTouchedAt: now } : {}),
  } : {
    sharerViewedAt: now,
    viewed: { [profile.uid]: now, },
    ...(isTouched ? { touched: { [profile.uid]: now } } : {}),
    ...(isTouched ? { sharerTouchedAt: now } : {}),
  };

  //update the conversation
  return dispatch({
    type      : CONVO_TOUCHED,
    firebase: {
      type        : "setSingle", //"updateSingle",
      collection  : `conversations/${shareId}/topics`,
      key         : topic.id,
      value       : modelUpdates, //myUpdates,
    },
    uid: profile.uid,
    shareId,
    topicId,
    statusKey   : StatusKeys.conversations,
  });

};

//---
// Creates a new comment.  Will create a new conversation if necessary, before creating the comment
export const createComment = (content, fieldKey) => async (dispatch, getState) => {
  const state = getState();
  const profile = state.app.profile;
  const { shareId, topicId, topicType } = state.convo.selected || {};

  if(!shareId || !topicId) {
    console.error("attempted to create a comment, but no current conversation", shareId, topicId);
    return { status: false, error: "No conversation selected to create comment."};
  }

  const result  = await ensureTopic(dispatch, state, profile, shareId, topicId, topicType);
  if(!result.status){
    return result;
  } 

  //Now, create the comment
  const model   = {
    createdAt     : Date.now(), //now,    
    createdBy     : profile.uid,
    fieldKey      : fieldKey ?? null,
    content       : content,
  };
  
  const comResult   = await dispatch({
    type      : COM_CREATED,
    firebase  : {
      type        : "create",
      collection  : `conversations/${result.conversation.id}/topics/${result.topic.id}/comments`,
      value       : model,
    },
    shareId, //used by the reducer
    topicId,
    statusKey : StatusKeys.conversations,
  });

  if(!result.isNew){
    //Track that the conversation has been touched by the current user
    await dispatch(viewedConversation(true));
  }

  trackEvent(Events.com_created);
  return comResult;
};

export const resolveThread = (fieldKey) => async (dispatch, getState) => {
  const state = getState();
  const profile = state.app.profile;
  const { shareId, topicId, topicType } = state.convo.selected || {};
  const { currentComments = [] } = state.convo;

  if(!shareId || !topicId) {
    console.error("attempted to create a comment, but no current conversation", shareId, topicId);
    return { status: false, error: "No conversation selected to create comment."};
  }

  const { conversation, topic, error }  = await ensureTopic(dispatch, state, profile, shareId, topicId, topicType);
  if(!!error) return error;

  const modelChange = {
    resolvedAt: Date.now(),
    resolvedBy: profile.uid,
  };

  const changes = currentComments.filter(c => c.fieldKey === fieldKey).reduce((acc, c) => {
    acc[c.id] = modelChange;
    return acc;
  }, {});

  const resolveResults  = await dispatch({
    type: COMMENTS_RESOLVED,
    firebase: {
      type: "setMulti",
      collection: `conversations/${conversation.id}/topics/${topic.id}/comments`,
      items: changes,
    },
    shareId,
    statusKey   : StatusKeys.conversations,
  });

  //TODO: should I mark the conversation as touched by the current user?
  if(resolveResults.ok){
    //Track that the conversation has been touched by the current user
    await dispatch(viewedConversation(true));
  }

  return resolveResults;
};


//=======================
// Helper Functions

//-----
// Ensures there is a conversation for the comments.  Either validates it exists, or creates a new one
async function ensureTopic(dispatch, state, profile, shareId, topicId, topicType){
  let share   = state.share.shares.find(s => s.id === shareId);
  if(!share){
    return {status: false, error: "Failed to create conversation because there is no sharing agreement between parties."}; //No sharing agreement
  } 
  if(share.sharer !== profile.uid && share.accountId !== profile.accountId){
    return  {status: false, error: "Failed to create conversation because current user is not a participant in provided sharing agreement."}; //I'm not part of this coversation
  } 
  
  //conversations are a map with the shareId as the key
  let isNew   = false;
  // let conversation = state.convo.conversations?[shareId] : null; //state.convo.conversations[shareId
  let conversation   = find(state.convo.conversations, c => c.shareId === shareId);  //it may not appear in the state by the share id, so find it
  
  if(!conversation){
    //This is a new conversation, need to create it
    
    const conversationModel   = {
      requestId: share.requestId,
      shareId: share.id,
      accountId: share.accountId,
      sharerId: share.sharer,
      reviewerId: share.reviewer,
      // ...touched
    };

    const createConversationResult  = await dispatch({
      type    : CONVO_CREATED,
      firebase  : {
        type        : "create",
        collection  : "conversations",
        key         : share.id,   //shareId may be the share request id, if it's the reviewer
        value       : conversationModel,
      },
      shareId,
      statusKey   : StatusKeys.conversations,
    });

    if(!createConversationResult.isOk){
      return { status: false, error: createConversationResult.error };    //Failed to create the conversation
    }
    else{
      trackEvent(Events.convo_created);
      conversation   = createConversationResult.data;
      isNew   = true;
    }
  }

  //TODO: Now, create the topic, if it doesn't exist
  let topic = conversation.topics?.find(c => c.id === topicId) ?? null;
  if(!topic){
    const now       = Date.now();
    const touched   = profile.isUser ? { sharerTouchedAt : now, sharerViewedAt: now } : { reviewerTouchedAt : now, reviewerViewedAt: now };
    
    const topicModel = {
      createdAt   : now,
      createdBy   : profile.uid,
      topicType,
      ...touched,
    };

    const createTopicResult  = await dispatch({
      type    : TOPIC_CREATED,
      firebase  : {
        type        : "create",
        collection  : `conversations/${shareId}/topics`,
        key         : topicId,
        value       : topicModel,
      },
      shareId,
      topicId,
      statusKey   : StatusKeys.conversations,
    });

    if(!createTopicResult.isOk){
      console.error("Failed to create conversation topic", createTopicResult.error);
      return { status: false, error: createTopicResult.error };    //Failed to create the topic
    }

    topic = createTopicResult.data;
    isNew = true;
  }

  return {status: true, conversation, topic, isNew };
}