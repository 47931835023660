import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import { Card, CardContent, Grid, Tooltip, Typography } from "@mui/material";
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { WaitButton } from "components";
import numberHelper from "helpers/number-helper";

const SubscriptionChoices = ({stripeProduct, onPurchase, isWorking}) => {
  const classes   = buildStyles();

  return (
    <Card variant="outlined" className={classes.settingCard}>
      <CardContent>
        <Typography className={classes.cardHeader}>Purchase Options</Typography>
        <Typography className={classes.cardSubHeader}>You will be taken to a secure checkout page to complete your purchase, then re-directed back here when you are finished</Typography>
        <Grid container>
          {stripeProduct.prices.map(price => <ProductPrice key={price.id} priceInfo={price} onPurchase={onPurchase(price.id)} isWorking={isWorking} classes={classes} />) }
        </Grid>
      </CardContent>
    </Card>
  );
}

export default SubscriptionChoices;

const ProductPrice = ({priceInfo, onPurchase, isWorking, classes}) => {
  if(!priceInfo.active) return null;

  return (
    <Grid item sm={4} xs={12} container className={classes.commandGrid} alignItems="flex-start">
      <Grid container direction="column" alignItems="center">
          <Typography className={classes.commandText}>{priceInfo.description}</Typography>
          <Typography className={classes.commandSubText}>{priceInfo.info?.description}</Typography>
          {priceInfo.unit_amount === 0 && 
            <Grid container alignItems="center" justifyContent="center">
              <div className={classes.checkWrapper}>
                <CheckCircleIcon className={classes.checkAvatar} color="secondary"/>
              </div>
            </Grid> 
          }
          {priceInfo.unit_amount === -1 && 
            <Typography className={classes.commandTextPrice} color="secondary">
              <a href="mailto:info@workwithswift.com?subject=FormHop Family Practice Edition&amp;body=I am interested in licensing FormHop for my practice.">CONTACT US</a>
            </Typography>
          }
          {priceInfo.unit_amount > 0 && 
            <Typography className={classes.commandTextPrice} color="secondary">
              {numberHelper.asCurrencyWithCents(priceInfo.unit_amount / 100)}
            </Typography>
          }
          {priceInfo.unit_amount > 0 &&
            <Tooltip title="Click to purchase this license">
              <div>
                <WaitButton isWaiting={isWorking} color="secondary" variant="outlined" className={classes.commandButton} endIcon={<AttachMoneyIcon/>} onClick={onPurchase}>Purchase</WaitButton>
              </div>
            </Tooltip>
          }
      </Grid>
    </Grid>
  );
}

const buildStyles   = makeStyles(theme => ({
  settingCard   : {
    marginBottom  : theme.spacing(2),
  },
  cardHeader   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
    textAlign: "center",
  },
  cardSubHeader: {
    fontSize: 18,
    fontWeight: 400,
    marginBottom: theme.spacing(3),
    color         : theme.palette.grey[600],
    textAlign: "center",
  },
  commandGrid   : {
    padding       : theme.spacing(3),
    "&:not(:last-child)": {
      borderRight  : `1px solid ${theme.palette.grey[200]}`,
    },
    "&:last-child": {
      paddingBottom   : 0,
    },
  },
  commandText   : {
    fontSize      : 20,
    fontWeight    : 600,
    display: "inline",
    marginBottom: theme.spacing(2),
  },
  commandTextPrice   : {
    fontSize      : 18,
    fontWeight    : 400,
    display: "inline",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  commandSubText  : {
    fontSize      : 15,
    fontWeight    : 300,
    color         : theme.palette.grey[500],
    "& strong"  : {
      color       : theme.palette.primary.main,
      fontWeight  : 700,
    }
  },
  commandButton : {
    fontSize      : 14,
    fontWeight    : 500,
  },
  checkWrapper: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius  : "50%",
    height        : "50px",
    width         : "50px",
    lineHeight    : "50px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
    marginBottom  : theme.spacing(2),
    marginTop     : theme.spacing(4),
  },
  checkAvatar: {
    color: "#f4f4f4",
    height: 48,
    width: 48,
  },  
}));

