import { createStore, compose, applyMiddleware } from 'redux';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import rootReducer from './reducers/root-reducer';
import {FetchMiddleware, LocalStorageMiddleware, FirebaseMiddleware } from './middleware';
import { statusMiddleware } from 'redux-action-status';

// import { reactReduxFirebase } from 'react-redux-firebase';
// import { reduxFirestore } from 'redux-firestore';
// import { getFirebase } from 'react-redux-firebase';

export default function buildStore(){ //history, firebase, rrfConfig){
    const composeEnhancer   = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; 
    const middleware        = isDev() ? applyDevMiddleware : applyProdMiddleware;

    const store = createStore(
        rootReducer, //connectRouter(history)(rootReducer),
        composeEnhancer(
            middleware(),
            // reactReduxFirebase(firebase, rrfConfig),
            // reduxFirestore(firebase)
        )
    );

    return store;
}

function isDev(){
    return window.origin.indexOf("localhost:") >= 0;
}

//-----------
// Break apart middleware list into 2, so we don't have the verbose logging
// in production, only in dev.

function applyDevMiddleware(){
    const logger = createLogger({
        collapsed: true,
    });

    return applyMiddleware(
        thunk,
        // thunk.withExtraArgument(getFirebase),
        statusMiddleware,
        FirebaseMiddleware,
        FetchMiddleware,
        LocalStorageMiddleware,
        // routerMiddleware(history),
        logger
    );
}

function applyProdMiddleware(){
    return applyMiddleware(
        thunk,
        // thunk.withExtraArgument(getFirebase),
        statusMiddleware,
        FirebaseMiddleware,
        FetchMiddleware,
        LocalStorageMiddleware,
        // routerMiddleware(history)
    );
}