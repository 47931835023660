import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateClientNote } from "store/actions/attorney-actions";
import { DeleteForever, EditOutlined, Star, StarBorder } from "@mui/icons-material";
import { Avatar, Box, Button, IconButton, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ClientNoteSchema, ProfileSchema } from "types/schema";
import { useClient } from "../../client-context";
import { useAttorneyContext } from "../../attorney-context";

export interface IClientNoteItemProps {
  note: ClientNoteSchema;
  onDelete: (id: string) => void;
  onEdit: (id: string) => void;
  editingId?: string | null;
}

const ClientNoteItem = ({ note, editingId, onEdit, onDelete }: IClientNoteItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { clientId } = useClient();
  const { getMember } = useAttorneyContext();
  //TODO: change this to the note's author when we have multi-user accounts.
  const profile = useSelector<any, ProfileSchema>(state => state.app.profile);
  const author = useMemo(() => getMember(note.createdBy), [getMember, note.createdBy]);

  const initials = useMemo(() =>  getInitials(author?.profile?.displayName || "Unknown"), [author?.profile?.displayName]);
  const [isDeleting, setDeleting] = useState(false);
  const isEditing = editingId === note.id;

  const handleStarred = async () => {
    const updates = { isStarred: !note.isStarred, content: note.content, tags: note.tags };
    await dispatch(updateClientNote(clientId, note.id!, updates)) as any;    
  }

  return (
    <Grid container px={2} pb={2} pt={0.25} sx={{ position: "relative", backgroundColor: isEditing ? alpha(theme.palette.secondary.light, 0.3) : "unset" }}>
      <Grid container sm={12} alignItems="center">
        <Grid container sm={8} alignItems="center">
          <Avatar sx={{ bgcolor: "primary.light", color: "primary.contrastText", width: 32, height: 32, fontSize: "1rem", fontWeight: 600 }}>{initials}</Avatar>
          <Typography fontSize="1rem" fontWeight="300" ml={1}>{author?.profile?.displayName ?? "Unknown"}</Typography>
          <Typography fontSize="0.9rem" fontWeight="200" ml={1}>{new Date(note.createdAt).toLocaleString()}</Typography>
          {isEditing && <Typography fontSize="0.8rem" fontWeight="800" ml={1} color="secondary">EDITING</Typography>}
        </Grid>
        <Grid container sm={4} justifyContent="flex-end">
          <Tooltip title="Star this Note">
            <IconButton onClick={handleStarred} size="small" disabled={!!editingId}>{ note.isStarred ? <Star fontSize="small" color="secondary"/> : <StarBorder fontSize="small" /> }</IconButton>
          </Tooltip>
          <Tooltip title="Edit this Note">
            <IconButton size="small" disabled={!!editingId} onClick={() => onEdit(note.id!)}><EditOutlined fontSize="small" /></IconButton>
          </Tooltip>
          <Tooltip title="Delete this Note">
            <IconButton size="small" disabled={!!editingId} onClick={() => setDeleting(true)}><DeleteForever fontSize="small" /></IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container sm={12} pl="40px" pr={3}>
        <Typography fontSize="1.1rem" fontWeight="400">{note.content}</Typography>
      </Grid>
      {isDeleting && (
        <Box sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", p: 1, bgcolor: alpha(theme.palette.error.light, 0.8) }}>
          <Grid container sm={12} alignItems="center" direction="column" rowGap={1}>
            <Typography fontSize="1.1rem" fontWeight="700">Are you sure you want to delete this note?</Typography>
            <Stack direction="row" spacing={2}>
              <Button variant="contained" size="small" disableElevation color="error" onClick={() => onDelete(note.id!)}>Delete</Button>
              <Button variant="contained" size="small" disableElevation onClick={() => setDeleting(false)}>Cancel</Button>
            </Stack>
          </Grid>
        </Box>
      )}
    </Grid>
  )
};

export default ClientNoteItem;

const getInitials = (name: string) : string => {
  return name?.split(" ").map((part) => part[0]).join("") || name[0] || "A";
}