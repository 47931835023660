import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import { joinWaitlist } from 'store/actions/onboarding-actions';
import { useStatus } from 'redux-action-status';
import { IfBlock } from 'components';


function InvitationWaitlist({isOpen}){
  const classes   = buildStyles();

  const user                     = useSelector(state => state.app.user);
  const [waitlist, setWaitlist]  = useState({});
  const [name, setName]          = useState(user ? user.displayName : "");
  const [email, setEmail]        = useState(user ? user.email : "");
  const [location, setLocation]  = useState("");
  const status                   = useStatus("waitlist");
  const dispatch                 = useDispatch();

  if(!isOpen) return null;

  const onWaitlist = async () => {
    //TODO: Validate email
    if(name && email && location){
      const result  = await dispatch(joinWaitlist(name, email, location));
      if(result.status  === "ok"){
        setWaitlist({isWaitlisted: true, warning: null, error: null});
      }
      else{
        setWaitlist({isWaitlisted: false, warning: null, error: result.error});
      }
    }
    else{
      setWaitlist({isWaitlisted: false, warning: "All fields are required", error: null});
    }  
  }

  return (
    <Grid container>
      <IfBlock condition={!waitlist.isWaitlisted}>
        <Typography className={classes.info}>Enter your information below and we'll add you to our waitlist.</Typography>
        <Grid item sm={10} container>
          <TextField id="name" label="Name" fullWidth={true} value={name} onChange={(e) => setName(e.target.value)}  disabled={status.isWorking} variant="standard"/>
        </Grid>
        <Grid item sm={10} container>
          <TextField id="email" label="Email Address" fullWidth={true} value={email} onChange={(e) => setEmail(e.target.value)}  disabled={status.isWorking} variant="standard"/>
        </Grid>
        <Grid item sm={10} container>
          <TextField id="location" label="City, State" fullWidth={true} value={location} onChange={(e) => setLocation(e.target.value)}  disabled={status.isWorking} variant="standard"/>
        </Grid>
        <Grid item sm={10} container justifyContent="flex-end" className={classes.btnRow}>
          <Button variant="outlined" color="primary" onClick={onWaitlist} disabled={status.isWorking}>Join Waitlist</Button>
        </Grid>
      </IfBlock>

      {waitlist?.isWaitlisted && 
        <Card elevation={0} className={classes.infoCard} variant="outlined">
          <span>Thank you!  We've added you to our waitlist, and will notify you when a spot is available.</span>
        </Card>
      }
      {waitlist?.warning && 
        <Card elevation={0} className={classes.warnigCard} variant="outlined">
          <span>{waitlist.warning}</span>
        </Card>
      }
      {waitlist?.error && 
        <Card elevation={0} className={classes.errorCard} variant="outlined">
          <span>We're sorry, there was a problem adding you to our waitlist. If this persists, please try again later. ({waitlist.error?.code}).</span>
        </Card>
      }
    </Grid>
  );
}

export default InvitationWaitlist;

const buildStyles   = makeStyles(theme => ({
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  },   
  btnRow    : {
    marginTop     : theme.spacing(2),
  },
  infoCard         : {
    background      : `${theme.palette.primary.light}33`,
    border          : `1px solid ${theme.palette.primary.dark}`,
    borderRadius    : 5,
    color           : theme.palette.primary.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",

  },
  warningCard         : {
    background      : "#fff3cd",
    border          : `1px solid #ffeeba`,
    borderRadius    : 5,
    color           : "#856404",
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
  errorCard         : {
    background      : theme.palette.error.light,
    border          : `1px solid ${theme.palette.error.dark}`,
    borderRadius    : 5,
    color           : theme.palette.error.main,
    margin          : "10px 0",
    padding         : "2.5px 5px",
    position        : "relative",
    width           : "100%",
  },
}));