import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SharingListItem from './sharing-list-item';
import { TBodyWrapper } from 'components';
import { SHARE_CODES, isPending } from 'config/share-config';

const MySharesList = ({invites, shares, isWorking}) => {
  const classes   = buildStyles();
  const history   = _.filter(invites, i => !isPending(i));
  const accepted  = _.filter(history, h => h.status === SHARE_CODES.accepted);
  const [props, setProps]   = useState({showRejects : false, items: accepted});
  const hasHistory  = Boolean(history.length > 0);
  
  useEffect(() => {
    const items     = props.showRejects ? history : _.filter(history, h => h.status === SHARE_CODES.accepted);
    setProps({...props, items: items});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invites]);

  if(!hasHistory) return null;

  const toggleRejects   = () => {
    const nextVal   = !props.showRejects;
    const items     = nextVal ? history : _.filter(history, h => h.status === SHARE_CODES.accepted);
    setProps({showRejects: nextVal, items: items});
  }

  const findShare   = (invite) => { return _.find(shares, s => s.requestId === invite.id); };
  
  return (
    <Card id="past-invitations" elevation={0} variant="outlined" className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Grid container>
          
          <Grid item container className={classes.cardHeader} alignItems="center">
            <Grid item sm={9}>
              <Typography className={classes.title}>Your Reviewers</Typography>
              {/* <Typography className={classes.subTitle}>Below is a list of users you are allowing to view your data</Typography> */}
            </Grid>                  
            <Grid item sm={3} container justifyContent="flex-end">
              <Button size="small" className={classes.actionButton} onClick={toggleRejects}>{props.showRejects ? "Show Accepted" : "Show All"}</Button>
            </Grid>
          </Grid>
          
          <Grid item container className={classes.cardBody}>
            <table id="client-table" className={classes.inviteTable}>
              <thead>
                <tr className="table-header">
                  <th className="active">Name</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Invited On</th>
                  {/* <th>Message</th> */}
                  <th></th>
                </tr>
              </thead>
              <TBodyWrapper colSpan={5} isWorking={isWorking} isEmpty={props.items.length === 0} emptyText="You have not accepted any share requests.  Click Show Rejected to see rejected requests.">
                { _.map(props.items, i => <SharingListItem key={i.id} invite={i} share={findShare(i)} />)}
              </TBodyWrapper>
            </table>
          </Grid>
        </Grid>

      </CardContent>
    </Card>
  );
}

export default MySharesList;

const buildStyles   = makeStyles(theme => ({
  card  : {
    width       : "100%",
    marginRight : theme.spacing(2),
    // minHeight   : 300,
    marginBottom: theme.spacing(4),
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: theme.spacing(1),
  },
  subTitle  : {
    fontSize    : 17,
    fontWeight  : 400,
    color       : theme.palette.grey[600],
  },
  inviteTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    },
    "& tr#empty-row"  : {
      "& td"  : {
        fontSize    : 16,
        fontWeight  : 400,
        fontStyle   : "italic",
        textAlign   : "center",
        color       : theme.palette.grey[500],
      }
    }
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
    color           : theme.palette.secondary.main,
  },
  
}));