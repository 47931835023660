import { Link } from "react-router-dom";
import { Button, Paper, Table, TableBody, TableCell, TableRow, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { AccountInvitation } from "types";
import { StatusKeys } from "helpers";
import { formatDateString } from "helpers/general-helpers";
import { useDispatch } from "react-redux";
import { acceptInvite } from "store/actions/account-actions";

const ProviderInvite = ({invite}: { invite: AccountInvitation }) => {
  const dispatch = useDispatch();
  // const theme = useTheme();
  const status = useStatus(StatusKeys.accounts);
  const url = "#";
  const emailUrl = `mailto:${invite.profile.email}`;

  const onAccept = async () => {
    console.log("accept invite");
    const result = await dispatch(acceptInvite(invite)) as any;
    if(result.isOk){
      console.log("Invite accepted");
      toast.success("You have successfully joined the practice");
    }
  };

  const onReject = () => {
    console.log("Reject invite");
  };

  const practiceName = invite.account.name ? <>the practice <strong>{invite.account.name}</strong></> : `their practice`;

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container>

        <Grid container sm={12}>
          <Typography variant="h6" fontSize="1.3rem" fontWeight="500">
            <Link to={url}><strong>{invite.profile.displayName}</strong></Link> has invited you to join {practiceName}
          </Typography>
          <Typography variant="body2" mb={2}>
            By accepting this invitation, you will be able to collaborate with <strong>{invite.profile.displayName}</strong> and all clients, forms, documents and notes will be shared.
          </Typography>
        </Grid>

        <Grid container sm={12}>
          <Grid container sm={8}>
            <Table size="small">
              <TableBody sx={{ "& td": { borderBottomWidth: 0, padding: 0.5, pl: 0 }}}>
                <TableRow>
                  <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Requested On:</TableCell>
                  <TableCell>{formatDateString(invite.invitedAt)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Sender Email:</TableCell>
                  <TableCell><a href={emailUrl} target="_blank" rel="noopener noreferrer">{invite.profile.email}</a></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Message: </TableCell>
                  <TableCell>{invite.message ?? "Your invitation to FormHop"}</TableCell>
                </TableRow>
              </TableBody>
            </Table>        
          </Grid>
          <Grid container sm={4} alignItems="flex-end" justifyContent="flex-end" spacing={1} gap={1}>
            <Button size="small" color="error" variant="outlined" disabled={status.isWorking} onClick={onReject}>Reject</Button>
            <Button size="small" color="secondary" variant="outlined" disabled={status.isWorking} onClick={onAccept}>Accept</Button>
          </Grid>
        </Grid>


      </Grid>
    </Paper>
  );

};

export default ProviderInvite;