import { useMemo } from "react";
import { useAnchor } from "helpers/hooks";
import { AccountCircleOutlined, NoAccountsOutlined } from "@mui/icons-material";
import { Chip, Menu, MenuItem, Tooltip } from "@mui/material";

export interface IClientAssignmentChipProps { 
  assignedTo: string | null | undefined, 
  members: any[], 
  onChange: (member: any) => void,
  variant?: "outlined" | "subtle", 
}

const ClientAssignmentChip = ({ assignedTo, members, variant, onChange }: IClientAssignmentChipProps ) => {
  // const theme = useTheme();
  const [anchor, isOpen, onOpen, onClose]  = useAnchor();
  const isAssigned = useMemo(() => !!assignedTo, [assignedTo]);
  const name = useMemo(() => assignedTo ? members.find(m => m.id === assignedTo)?.profile?.displayName : "unassigned", [assignedTo, members]);
  const variantSx = variant === "subtle" ? { borderWidth: 0, color: assignedTo ? "primary" : "grey.400" } : { };
  const tt = useMemo(() => assignedTo ? `Assigned to ${name}, click to change` : "Unassigned, click to assign", [assignedTo, name]);
  // const colorSx = assignedTo ? { color: theme.palette.secondary.main } : { };

  const onItemClicked = (uid: string) => () => {
    onClose();
    onChange(uid);
  }

  return (
    <>
      <Tooltip title={tt}>
        <Chip
          onClick={(e) => isOpen ? onClose() : onOpen(e)}
          label={name}
          color={!assignedTo ? "default" : "secondary"}
          size="small"
          variant="outlined"
          sx={{ py: 2, px: 1, ...variantSx }}
          icon={ isAssigned ? <AccountCircleOutlined color={!assignedTo ? "inherit" : "secondary"} /> : <NoAccountsOutlined color="inherit" /> }
        />
      </Tooltip>
      <Menu id="membersMenu" anchorEl={anchor} open={isOpen} onClose={onClose}>
        { isAssigned && <MenuItem value="" onClick={onItemClicked("")}>Unassign</MenuItem>}
        {members.map(member => (
          <MenuItem key={member.id} value={member.id} onClick={onItemClicked(member.profile.uid)}>{member.profile?.displayName}</MenuItem>
        ))}            
      </Menu>
    </>
  )
};

export default ClientAssignmentChip;