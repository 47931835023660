import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
// import { withStyles } from '@mui/material/styles';
import { connect } from 'react-redux';
import Loading from './loading';

class LoadingContainer extends React.Component{

    render(){
        const { isVisible, isWorking, className }    = this.props;
        if(!isVisible && !isWorking){
            return null;
        }

        return (
            <div className={clsx("loading-container", className)}>
                <div className="loading-wrapper">
                    <Loading {...this.props} />
                </div>
            </div>
        );
    }
}

LoadingContainer.propTypes = {
    isVisible   : PropTypes.bool,
    message     : PropTypes.string,
    noMessage   : PropTypes.bool,
    align       : PropTypes.string,
    domain      : PropTypes.string,
    workingKey  : PropTypes.string
};

function mapState(state, props){
    let result  = {};

    if(props.domain){
        const wKey          = props.workingKey || "isWorking";
        const domain        = props.domain;
        result.isWorking    = state[domain][wKey];
        result.status       = state[domain].status;
    }

    return result;    
}


export default connect(mapState)(LoadingContainer);