import React from 'react';
import { useSelector } from 'react-redux';
import ProfileView from './my-profile';
import ClientsView from '../collaborator/provider-view';
import { selectProfile } from 'store/selectors/app-selectors';

const MyDefaultView = () => {
  const profile   = useSelector(selectProfile);
  
  return (
    <>
      {profile.isReviewer && <ClientsView />}  
      {!profile.isReviewer && <ProfileView />}
    </>
  );
}

export default MyDefaultView;