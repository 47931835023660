import { useSelector } from 'react-redux';
import { useLocation, } from 'react-router-dom';
import { selectProfile } from 'store/selectors/app-selectors';
import { selectValue, selectRowValue } from 'store/selectors/value-selectors';
import { parseCondition } from './form-config-helpers';

export function useConversationId(){
  const { search } = useLocation();
  if(search.includes("conversation=")){
    //use regexp to extract the value of the search parameter conversation from the search string
    const regex = /conversation=([^&]*)/;
    const match = regex.exec(search);
    if(match){
      return match[1];
    }
    return null;    
  }
}

export function useProfile(){
  const profile     = useSelector(selectProfile);
  return profile;
}

export function useValue(fieldKey, values){
  let value   = useSelector(state => selectValue(state, fieldKey ?? "undefined"));
  if(!value && !!values) value = values[fieldKey];
  return value;
}

export function useRowValue(fieldKey, rowIndex, values){
  let value   = useSelector(state => selectRowValue(state, fieldKey, rowIndex));
  if(!value && !!values) value = values[fieldKey]?.[rowIndex];
  return value;
}

export function useConditionValue(condition){
  let key           = null;
  if(condition){
    const cond = parseCondition(condition);
    key = cond.key;       
  }

  const condValue = useValue(key);
  if(condValue !== null && condValue !== undefined){
    return condValue.toString();
  } 

  return null;
}

export function useIsMyComment(){
  const profile = useSelector(state => state.app.profile);
  const members = useSelector(state => state.account.account?.members);

  const { role, uid } = profile;

  if(role !== "reviewer") return (item) => item.createdBy === uid;
  else{
    return (item) => {
      if(item.createdBy === uid) return true;
      else{
        const member = members.find(m => m.id === item.createdBy);
        return !!member;
      }
    };
  }
}