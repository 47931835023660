import React, { useState, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
// import makeStyles from '@mui/styles/makeStyles';
import { ItemList } from 'components';
import { loadAuditLog, loadProfiles } from 'store/actions/admin-actions';
import { selectAuditLog } from 'store/selectors/admin-selectors';
import { useStatusMulti } from 'redux-action-status';
import AuditLogItem from './auditlog-item';

const AuditLogView = () => {
  // const classes   = buildStyles();
  const [view, setView]      = useState("all");
  const auditLog  = useSelector(selectAuditLog);
  const profiles  = useSelector(state => state.admin.profiles);
  const status    = useStatusMulti(["auditLog", "profiles"]);
  const dispatch  = useDispatch();

  useEffect(() => {
    onRefresh();
    if(!profiles) dispatch(loadProfiles());    //get the users (if we don't have them) so we can show who is who
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filtered  = useMemo(() => {
    if(auditLog) return filterList(auditLog, view);
    else return null;
  }, [view, auditLog]);

  function onRefresh(){
    dispatch(loadAuditLog());
  }

  function onAction(action, item){
    console.log("action triggered", action);
  }

  return (
    <>
      <ItemList config={listConfig} items={filtered} status={status} onRefresh={onRefresh} onFilter={v => setView(v)} onAction={onAction} ItemComponent={AuditLogItem}/>
    </>
  );
}

export default AuditLogView;

// const buildStyles   = makeStyles(theme => ({  
// }));

function filterList(list, view){
  switch(view){
    case "all"  : return list;
    case "login"  : return _.filter(list, item => item.event === "login");
    default: return list;
  }
}

const listConfig  = {
  title   : "Audit Log",
  cols    : [
    {id: 0, label: "Date", className: "active", style: {minWidth: 130}},
    {id: 10, label: "Event", className: ""},
    {id: 20, label: "Name", className: ""},
    {id: 30, label: "Email", className: ""},
    {id: 40, label: "Uid", className: ""},
    {id: 50, label: "", className: ""},
  ],
  filters : [
    {id: 0, label: "All", value: "all", icon: "clear_all" },
    {id: 10, label: "login", value: "login", icon: "verified_user"},
  ],
};