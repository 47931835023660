import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Alert, Box, Button } from "@mui/material";
import { ClientCloudFolderIds, CloudFolder, CurrentClient } from "types";
import { CloudStorageSettingSchema } from "types/schema";
import { FROM_FOLDER_NAME, TO_FOLDER_NAME, createFolder, cloudFoldersOnly, getFolder, getFolderChildren, oneDriveRedirectUri, oneDriveScopes, childCount, getRemoteItem, getFolderName } from "helpers/cloud-storage-helpers";
import { useCloudStorage } from "./cloud-storage-context";

//TODO: when we support another cloud provider, move this to a types folder to share between the specific
// cloud provider contexts
export interface ICloudDriveContext {
  name: string;
  isAuthenticated: boolean;
  providerAccount: any;     //the underlying account of the provider, e.g. the MSAL account
  login: () => Promise<boolean>;
  logout: () => Promise<void>;
  canSync: boolean;
  status?: string;
  error: any | undefined;

  setting?: CloudStorageSettingSchema;
  cloudFolder: CloudFolder | null;  
  getClientFolder: (client: CurrentClient) => Promise<ClientCloudFolderIds | null>;
}

export const OneDriveContext = createContext<ICloudDriveContext | null>(null);

export const useCloudDrive = () => {
  const context = useContext(OneDriveContext);
  if(!context) throw new Error("useOneDriveCloudContext must be used within a OneDriveCloudProvider");
  return context;
}

export const OneDriveProvider = ({ children }: { children: React.ReactNode }) => {
  const { setting } = useCloudStorage();
  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [msalAccount, setMsalAccount] = useState<any>(null);
  const [isWorking, setIsWorking] = useState(false);
  const [error, setError] = useState<any>(null);
  const [initError, setInitError] = useState<any>(null);
  const [cloudFolder, setCloudFolder] = useState<CloudFolder | null>(null);
  const canSync = useMemo(() => isAuthenticated && !!msalAccount && !!cloudFolder, [isAuthenticated, msalAccount, cloudFolder]);

  useEffect(() => {
    async function getSyncFolder(){

      if(!setting?.id) return null;
      
      setIsWorking(true);
      
      const { id, driveId, isRemote } = setting;
      let syncFolder: CloudFolder;

      try{ 
        if(isRemote) syncFolder = await getRemoteItem(instance, driveId, id);
        else syncFolder = await getFolder<CloudFolder>(instance, setting.id);
      }
      catch(error: any){
        console.warn("Failed to initialize the cloud sync folder, re-authentication is required.", error.message);
        setInitError(error);
        setIsWorking(false);
        return null;
      }

      //Now, get the children for the folder
      //SyncFolder, even when remote, doesn't come back with "remoteItem" so need to decide here how we're getting the children
      if(childCount(syncFolder) === 0) syncFolder.children = [];
      else {
        let children: CloudFolder[] = [];

        if(!isRemote) children = await getFolderChildren(instance, syncFolder, setting);
        else children = await getRemoteItem(instance, driveId, id, "/children");

        syncFolder.children = cloudFoldersOnly(children);
      }

      setIsWorking(false);
      return syncFolder;
    }

    if (isAuthenticated && !!msalAccount && !!setting?.id && cloudFolder?.id !== setting?.id && !isWorking && !error && !initError){
      getSyncFolder().then(setCloudFolder);
    }
  }, [isAuthenticated, msalAccount, isWorking, instance, setting, cloudFolder, error, initError]);

  //Set the current MSAL Account, if there are multiple
  useEffect(() => {
    if(accounts.length > 0) {
      setMsalAccount(accounts[0]);
    }
  }, [accounts]);

  const login = useCallback(async () => {
    setError(null);

    try {
      const result = await instance.loginPopup({
        scopes: oneDriveScopes,
        redirectUri: oneDriveRedirectUri,
      });

      console.log("Result", result);
      return true;
      
      } catch (error) {
        console.log("Error", error);
        setError(error);
        return false;
      }
  }, [instance]);

  const logout = useCallback(async () => {
    setError(null);
    instance.logoutPopup();  
  }, [instance]);

  const reconnect = useCallback(async () => {
    await login();
    setInitError(null);
  }, [login]);

  //Get the folder for a client (or create it if it doesn't exist)
  const getClientFolder = useCallback(async (client: CurrentClient) => {
    if(!cloudFolder || !client || !setting) return null;
    const clientFolderName = getFolderName(client);
    let clientFolder = cloudFolder.children?.find((f: any) => f.name === clientFolderName);
    
    if(!clientFolder){
      clientFolder = await createFolder(instance, setting, cloudFolder.id, clientFolderName);
      const toFolder = await createFolder(instance, setting, clientFolder.id, TO_FOLDER_NAME);
      const fromFolder = await createFolder(instance, setting, clientFolder.id, FROM_FOLDER_NAME);
      clientFolder.children = [toFolder, fromFolder];    
      //add the clientFolder to the syncFolder's children
      const newSync = { ...cloudFolder, children: [...cloudFolder.children ?? [], clientFolder] };
      setCloudFolder(newSync);
    }
    else if(!clientFolder.children){
      const { isRemote, driveId } = setting;
      let children: CloudFolder[] = [];
      if(!isRemote) children = await getFolderChildren(instance, clientFolder, setting);
      else children = await getRemoteItem(instance, driveId, clientFolder.id, "/children");

      // const children = await getFolderChildren(instance, clientFolder,  setting); // fetchOneDrive(instance, `/items/${clientFolder.id}/children`);
      clientFolder.children = cloudFoldersOnly(children);
    }
    
    return { 
      clientFolderId: clientFolder.id, 
      toFolderId: clientFolder.children?.find((f: any) => f.name === TO_FOLDER_NAME)?.id ?? "", 
      fromFolderId: clientFolder.children?.find((f: any) => f.name === FROM_FOLDER_NAME)?.id ?? "", 
    } as ClientCloudFolderIds;
        
  }, [instance, cloudFolder, setting]);

  //The Provider Value
  const value = useMemo(() => ({
    name: "onedrive",
    isAuthenticated,
    providerAccount: msalAccount,
    login,
    logout,
    canSync,
    status: inProgress,
    error,
    cloudFolder,
    getClientFolder,
    setting,
  }), [isAuthenticated, msalAccount, login, logout, inProgress, setting, error, cloudFolder, canSync, getClientFolder]);

  return (
    <OneDriveContext.Provider value={value}>
      {/* TODO: create a sync button in the top toolbar to show sync progress... */}
      {/* Can use this for reference: https://mui.com/material-ui/react-progress/#interactive-integration */}
      {/* {isWorking && (
        <LoadingBar  message=""/>
      )} */}
      {initError && (
        <Box sx={{ width: "100%", mb: 2 }}>
          <Alert severity="info" 
            onClose={() => setInitError(null)} 
            action={<Button color="inherit" size="small" onClick={reconnect}>Sign In</Button>}
          >
            Please sign in to your OneDrive account to continue syncing.            
          </Alert>
        </Box>
      )}
      {children}
    </OneDriveContext.Provider>
  );
  
}
