import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import { CircularProgress } from '@mui/material';

const DEFAULT_MESSAGE   = "Loading...";
// const ALIGNMENTS        = {
//     "left"      : "left",
//     "right"     : "right",
//     "center"    : "center"
// };

class Loading extends React.Component{

  getMessage(message, isNoMessage, classes){
    if(isNoMessage){
      return null;
    }
    else{
      //className="loading-message"
      return (
        <Grid item className={classes.message}>
          <span>{message}</span>
        </Grid>
      );
    }
  }

  //TODO: Make this better!!

  render(){
    const { isVisible, isWorking, message, status, noMessage, align, classes, size, className }    = this.props;
    if(!isVisible && !isWorking){
        return null;
    }
    const mySize        = size ? `size-${size}` : "size-small";   //`
    const myAlign       = align ? `align-${align}` : "align-left";  //.
    const myTitle       = status || message || DEFAULT_MESSAGE;
    const displayMsg    = this.getMessage(myTitle, noMessage, classes);

    return(
      <Grid className={clsx(classes.loadingRoot, myAlign, mySize, className)} container alignItems="center">
        <Grid className={classes.animation} item>
          <CircularProgress />                    
        </Grid>
        {displayMsg}
      </Grid>
    );
  }
}

Loading.propTypes = {
    isVisible   : PropTypes.bool,
    message     : PropTypes.string,
    noMessage   : PropTypes.bool,
    align       : PropTypes.string,
    domain      : PropTypes.string,
    workingKey  : PropTypes.string,
    size        : PropTypes.oneOf(["small", "medium", "large"]),
};

function mapState(state, props){
    let result  = {};

    if(props.domain){
        const wKey          = props.workingKey || "isWorking";
        const domain        = props.domain;
        result.isWorking    = state[domain][wKey];
        result.status       = state[domain].status;
    }

    return result;    
}

const styles = (theme => ({
    loadingRoot    : {
      width   : "auto",
      "&.size-small"  : {
        paddingTop    : theme.spacing(0.5),
        "& .MuiCircularProgress-root"   : {
          height  : "20px !important",
          width   : "20px !important",
        }
      },
      "&.size-medium"  : {
        paddingTop    : theme.spacing(0.5),
        "& .MuiCircularProgress-root"   : {
          height  : "40px !important",
          width   : "40px !important",
        }
      },
      "&.size-large"  : {
        // paddingTop    : theme.spacing(0.5),
        "& .MuiCircularProgress-root"   : {
          height  : "60px !important",
          width   : "60px !important",
        }
      },
      "&.align-center"  : {
        display   : "flex",
        flexDirection   : "column",
        justifyContent  : "center",
      }
    },
    animation   : {

    },
    message     : {
        fontSize        : "1.1rem",
        marginLeft      : theme.spacing(1),
        color           : theme.palette.primary.dark,
    },
}));

export default withStyles(styles, {withTheme: true})(connect(mapState)(Loading));