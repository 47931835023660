import { Fragment, useRef, useState } from "react";
import { useHoverDirty } from "react-use";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Button, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { AddCircleOutline, RemoveCircleOutline } from "@mui/icons-material";
import { FormField, FormSection } from "types";
import fHelper from "helpers/financials-helper";
import { ConfirmDialog } from "components";
import { useAppContext } from "sections/app/app-context";
import { useRegion, useRegionSelection } from "./region-context";
import { InputField } from "./input-field";
import { withFieldChanged } from "./with-field-changed";
import CommentCell from "./comment-cell";
import SectionSubtitle from "./section-subtitle";

export interface IListTableSectionProps {
  section: FormSection;
  values: any;
  onChange: (sectionId: string, fieldId: string, total: number, details: any, index: number) => void;
  onFieldChanged: (event: any) => void;
}

const ListTableSection = ({ section, values, onFieldChanged }: IListTableSectionProps) => {
  const isCondUndef   = fHelper.isConditionUndefined(section.condition, values);
  const isCondNotMet  = !fHelper.isConditionMet(section.condition, values);
  const isCollapsed   = isCondUndef || isCondNotMet;
    
  //TODO: conditions, 

  return (
    <Table onChange={onFieldChanged} sx={{ width: "100%" }} className="section-table" data-type="list-table-section">
      <TableHead>
        <ListTableHeader section={section} />
      </TableHead>
      <ListTableBody section={section} values={values} />
    </Table>
  )
};

export default withFieldChanged(ListTableSection);


const cellProps = {
  borderBottomWidth: 0,
  px: 0,
  py: 0.25,
};

const ListTableHeader = ({ section }: { section: FormSection}) => {
  if(!section.title) return null;
  // const subTitle = section.subTitle ?? section.desc;
  // const headerField = section.headerField ? getField(section.headerField) : null;
  //TODO: Header Fields

  return (
    <TableRow data-type="list-table-header">
      <TableCell sx={{ px: 0, py: 0.5 }}>
        {/* {headerField && <InputField field={headerField} onChange={() => {}}/> */}
        <Typography color="primary" fontSize="1.2rem">{section.title}</Typography>
        <SectionSubtitle section={section} />
        {/* {subTitle && <Typography component="span" color="grey.800" fontSize="1.2rem" fontStyle="italic" sx={{ my: 1 }}>{subTitle}</Typography>} */}
      </TableCell>
    </TableRow>
  );
};


const ListTableBody = ({ section, values }: { section: FormSection, values: any }) => {
  const itemRows = values[section.id] ? [...values[section.id]] : []
  const { handleRowAdded, handleRowDeleted } = useRegion();
  const [confirmIndex, setConfirmIndex] = useState(-1);

  const isCondUndef   = fHelper.isConditionUndefined(section.condition, values);
  const isCondNotMet  = !fHelper.isConditionMet(section.condition, values);
  const isCollapsed   = isCondUndef || isCondNotMet;
  const label = `${section.itemLabel ?? "Item"}`;

  const handleAddItem = () => {
    handleRowAdded(section.id);
  }

  const handleConfirmRemove = (index: number) => () => {
    if(itemRows[index] === null) return handleRowDeleted(section.id, index);  //don't prompt if it's an empty row
    setConfirmIndex(index);
  }

  const handleRemoveItem = () => {
    console.log("removing item", confirmIndex);
    handleRowDeleted(section.id, confirmIndex);
    setConfirmIndex(-1);
    return;
  }

  if(isCollapsed) return null;

  const minRows   = 1;       
  if(itemRows.length < minRows){
      for(let i = 0; i < minRows; i++) {
        handleAddItem();
      }
  }

  return (
    <>
      <TableBody>
        {itemRows.map((itemData, i) => {
          return (
            <Fragment key={i}>
              <ListTableRow section={section} values={itemData} index={i} onRemove={handleConfirmRemove(i)} />
              <DividerTableRow isLast={i === itemRows.length - 1} itemLabel={section.itemLabel} onAddItem={handleAddItem}/>
            </Fragment>
          )
        })}
      </TableBody>
      {confirmIndex >= 0 && <ConfirmDialog open={true} title="Remove Item" message={`Are you sure you want to remove ${label} ${confirmIndex + 1}? Any data for this ${label} will be permenantly deleted.`} onConfirm={handleRemoveItem} onClose={() => setConfirmIndex(-1)} />}
    </>
  );
};


const DividerTableRow = ({ isLast, itemLabel = "Item", onAddItem }: { isLast?: boolean, itemLabel?: string, onAddItem: () => void }) => {
  return (
    <TableRow>
      <TableCell colSpan={2}>
        {!isLast && (
          <Grid container sm={8} smOffset={2} alignItems="center" py={1}>
            <Divider sx={{ width: "100%" }} />
          </Grid>
        )}
        {isLast && (
          <Grid container sm={8} smOffset={2} alignItems="center" py={1}>
            <Grid sm={5}>
              <Divider/>
            </Grid>
            <Grid sm={2} sx={{ textAlign: "center" }}>
              <Button onClick={onAddItem} variant="text" color="primary" startIcon={<AddCircleOutline />}>Add</Button>
            </Grid>
            <Grid sm={5}>
              <Divider />
            </Grid>
          </Grid>
        )}
      </TableCell>
    </TableRow>
  );
}

interface IListTableRowProps {
  section: FormSection;
  values: any;
  index: number;
  onRemove: () => void;
}

const ListTableRow = ({ section, values, index, onRemove }: IListTableRowProps ) => {
  const { getSectionFields } = useRegion();
  const fields = getSectionFields(section.id) as FormField[];
  const label = `${section.itemLabel ?? "Item"} ${index + 1}`;

  return (
    <TableRow className="item-row">
      <TableCell>
        
        <Table size="small" className="item-table">
          <TableHead>
            <TableRow>
              <TableCell colSpan={2} sx={{...cellProps }}>
                <Grid container alignItems="center" columnGap={2}>
                  <Typography color="primary" fontSize="1.1rem" fontWeight="500">{label}</Typography>
                  {index > 0 && (
                    <Tooltip title={`Remove ${label}`} placement="top">
                      <IconButton onClick={onRemove} size="small" sx={{ color: "grey.400" }}><RemoveCircleOutline fontSize="small" /></IconButton>
                    </Tooltip>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ "& .td": { borderBottomWidth: 0 }}}>
            {fields.map(field => <ListTableFieldRow key={field.id} section={section} field={field} index={index} values={values} />)}            
          </TableBody>
        </Table>
      </TableCell>
    </TableRow>
  )
};

export interface IListTableFieldRowProps {
  section: FormSection;
  field: FormField;
  index: number;
  values: any;
}

const valueCellProps = {
  ...cellProps,
  textAlign: "right",
};

const ListTableFieldRow = ({section, field, values, index}: IListTableFieldRowProps) => {
  const theme = useTheme();
  const { allowComments } = useAppContext();
  const { selectedField, handleFieldSelected } = useRegionSelection();
  const rowRef = useRef<any>(null);
  const isHovered = useHoverDirty(rowRef);

  if(field.condition){
    if(!fHelper.isConditionMet(field.condition, values)) return null;
  }

  const dataProps = {"data-index" : index};
  const fieldItems = field.params ?? [field];
  const fields    = fieldItems.map(fi => <InputField key={fi.id} field={fi} sectionId={section.id} rowIndex={index} dataProps={dataProps} showLabel={false} onChangeOverride={null}/>);
  const isSelected = selectedField?.id === field.id && selectedField?.index === index;
  const bgcolor = isSelected ? alpha(theme.palette.primary.light, 0.15) : (isHovered ? alpha(theme.palette.info.light, 0.1) : "unset");

  return (
    <TableRow ref={rowRef} onClick={() => handleFieldSelected(field.id, index)} className="question-row" sx={{ bgcolor }}>
      <TableCell className="question-label" sx={cellProps}>{field.label}</TableCell>
      <TableCell className="question-value" sx={valueCellProps}>
        {fields}
      </TableCell>
      <CommentCell isHovered={isHovered && allowComments} sectionId={section.id} fieldId={field.id} />
    </TableRow>
  );
}