// import { createSelector } from 'redux-action-status/reselect';
// import _ from 'lodash';
import createCachedSelector from 're-reselect';
import { DEFAULT_STATUS } from './status-reducer';

const _getStatuses      = state => state.status;
const _inItemId         = (state, itemId) => itemId;

export const selectStatus   = createCachedSelector(
    [_getStatuses, _inItemId],
    (statuses, statusKey) => {
        const status  = statuses[statusKey];
        return status || {...DEFAULT_STATUS};
    }
)((_, key) => key); //key for re-reselect caching
