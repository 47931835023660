import { Box, Button, Stack, TableCell, TableRow, alpha, useTheme } from "@mui/material";
import { ReactNode } from "react";


export interface IConfirmationRowProps {
  colSpan: number;
  onYes: () => void;
  onNo: () => void;
  children: ReactNode;
}

const ConfirmationRow = ({ colSpan, onYes, onNo, children }: IConfirmationRowProps) => {
  const theme = useTheme();

  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ bgcolor: alpha(theme.palette.error.light, 0.25) }}>
        <Stack direction="row" gap={1} display="flex" alignItems="center" justifyContent="space-between">
          <Box>{children}</Box>
          <Stack direction="row" gap={1}>
            <Button variant="contained" disableElevation onClick={onYes} size="small" color="primary">Yes</Button>
            <Button variant="contained" disableElevation onClick={onNo} size="small" color="error">No</Button>
          </Stack>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ConfirmationRow;