import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Stack, Typography, alpha, useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatusMulti } from "redux-action-status";
import { useLoc } from "hooks";
import { StatusKeys } from "helpers";
import { LoadingBar } from "components";
import { selectPrimaryProvider } from "store/selectors/petitioner-selectors";
import { loadProviderLogo } from "store/actions/petitioner-actions";
import ProfileMenu from "sections/financials/layout/header/profile-menu";
// import ConversationsButton from "sections/financials/layout/header/conversations-button";

const PetitionerHomeHeader = ( { unrecognized }: { unrecognized?: boolean }) => {
  const { s } = useLoc();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isShort = useMediaQuery('(max-height:750px)');
  const status = useStatusMulti([StatusKeys.shares, StatusKeys.petitioner]); // useStatus(StatusKeys.petitioner);
  const primaryProvider = useSelector(selectPrimaryProvider);
  const height = primaryProvider ? (isShort ? 110 : 180) : (isShort ? 120 : 160);

  useEffect(() => {
    if(!status.isWorking && primaryProvider?.logoPath && !primaryProvider?.logoUrl){
      dispatch(loadProviderLogo(primaryProvider.id, primaryProvider.logoPath));
    }
  }, [status, primaryProvider, dispatch]);

  const content = useMemo(() => {
    function loadProviderInfo() {
      return {
        title: primaryProvider?.name,
        tagline: "powered by FormHop",
        instructions: "",
      }      
    }
    function loadWelcomeInfo() {
      return {
        title: s("petitioner.welcome"),
        tagline: s("tagline"),
        instructions: unrecognized ? "" : "You've been invited to collaborate, please review the invitation below to continue.",
        logo: "",
      };
    }

    if(status.isInitialized && primaryProvider) return loadProviderInfo();
    else if(status.isInitialized) return loadWelcomeInfo();
    else return null;

  }, [status, unrecognized, primaryProvider, s]);

  return (
    <Grid id="header-root" container xs={12} alignItems="flex-start" bgcolor={alpha(theme.palette.primary.main, 0.25)} width="100%" sx={{ height }}>
      {status?.isWorking && (
        <Grid container width="100%" position="absolute">
          <LoadingBar message={content ? "" : "Loading, one moment..."} />
        </Grid>
      )}
      {content && (
        <Grid container direction={isShort ? "row" : "column"} xs={12} alignItems="center" columnGap={isShort ? 8 : 4} justifyContent="center" pt={2} pb={3}>
          <Box sx={{ position: "absolute", right: "10px", top: "10px" }}>
            {/* <ConversationsButton /> */}
            <ProfileMenu simple/>
          </Box>
          {primaryProvider?.logoUrl && (
            <Box sx={{ justifyContent: "center"}} mb={1}>
              <img src={primaryProvider.logoUrl} alt={`${primaryProvider?.name} logo`} height={64} />
            </Box>
          )}
          <Grid container direction="column" alignItems="center" >
            <Typography fontSize="1.5rem" fontWeight="600" color="primary">{content.title}</Typography>
            <Typography fontSize="1.2rem" fontWeight="300" color="grey.500" mb={1}>{content.tagline}</Typography>
            <Typography fontSize="1.3rem" color="grey.800">{content.instructions}</Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PetitionerHomeHeader;