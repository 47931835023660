import { Box, Card, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { FormField } from 'types';
import helper from 'helpers/financials-helper';
import nHelper from 'helpers/number-helper';
import { cardStyles, textStyles } from 'helpers/style-helpers';
import { usePetitionerForm } from '../form-hooks';
import { useMemo } from 'react';

export interface SummarySectionProps {
  regionId: string;
}

interface SummaryRowProps {
  field: any,
  values: any,
  regions: any[],
  fields: FormField[];
}

const SummarySection = ({ regionId }: SummarySectionProps) => {
  const { regions, values, schema } = usePetitionerForm();
  const region = useMemo(() => regions.find(r => r.id === regionId), [regions, regionId]);
  
  if(!region || !schema) {
    console.error("SummarySection: region not found", regionId);
    return null;
  }

  const summaryFields = schema.fields.filter((f: any) => region?.fields?.includes(f.id)) as FormField[];
  // const fields = fHelper.getFields(region.fields) as any[];
  const rows = summaryFields.map(f => <SummaryRow key={f.id} field={f} values={values} regions={regions} fields={schema.fields}/>);

  return (
    <Card {...cardStyles.primaryX()}>
      {region.title && 
        <Typography variant="h2" {...textStyles.summarySectionLabel}>{region.title}</Typography>
      }
      <Box>
        <Table width="100%" size="small">
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

export default SummarySection;

const SummaryRow = ({field, values, regions, fields}: SummaryRowProps) => {
  let total       = 0;
  if(field.calculation){
    total   = helper.calculateSummaryField(field, values, regions, fields);
  }
  else{
    total   = helper.calculateSummaryRegion(field, values, regions, fields);
  }
  
  const totalStr  = nHelper.asCurrency(total);
  const color     = getColor(field, total);
  const border = field.calculation ? "1px solid #ddd" : "none";
  const mods = field.calculation ? { fontWeight: "600" } : {};
  
  return (
    <TableRow key={field.id} sx={{ borderBottom: border }}>
      <TableCell><Typography {...textStyles.summaryFieldLabelX(mods)}>{field.label}</Typography></TableCell>
      <TableCell><Typography color={color} {...textStyles.summaryFieldValueX(mods)}>{totalStr}</Typography></TableCell>
    </TableRow>
  );    
}

const colorMap: Record<string, string> = {
  "danger": "red",
  "success": "green",
  "warning": "yellow",
};

const getColor = (field: any, total: number): string => {
  let colorClass  = "";
  if(field.color){
    if(field.color === "conditional"){
      colorClass  = total > 0 ? colorMap["success"] : total < 0 ? colorMap["danger"] : "";
    }
    else{
      colorClass  = colorMap[field.color];
    }
  }

  return colorClass;
}