import { IconButton, Table, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { FormInput } from "sections/financials/parts/v2.0.0/form-input";
import { Delete } from "@mui/icons-material";
import { IDataTableListRowProps } from "../data-table-types";
import FormSameAsField from "sections/financials/parts/v2.0.0/form-sameas-field";

export function DataTableListRowEditor({sectionId, row, rowIndex, fields, onRowDeleted, itemLabel}: IDataTableListRowProps){
  return (
    <TableRow>
      <TableCell sx={{ padding: 0.5 }}>
        <Table>
          <TableRow>
            <TableCell sx={{ display: "flex", ...cellSx }}>
              <Typography color="secondary">{itemLabel ?? "Item"} {rowIndex + 1}</Typography>
              <Tooltip title="Delete row">
                <IconButton onClick={onRowDeleted} size="small" color="primary" aria-label="delete" sx={{ml: 2}}>
                  <Delete sx={{ fontSize: "1.25rem" }} />
                </IconButton>
              </Tooltip>
            </TableCell>
          </TableRow>
          {
            fields.map((f, index) => <DataTableListRowEditorItem key={index} field={f} sectionId={sectionId} rowIndex={rowIndex} />)            
          }
        </Table>        
      </TableCell>
    </TableRow>
  );
};

const cellSx = { 
  py: 0.5,
  pl: 0,
  pr: 1,
  borderBottom: "none",
};

const DataTableListRowEditorItem = ({field, sectionId, rowIndex}: {field: any, sectionId: string, rowIndex: number}) => {
  
  return (
    <TableRow >
      <TableCell sx={{ pl: 1, py: 0.5, borderBottom: "none" }}>{field.label}</TableCell>
      <TableCell sx={cellSx} align="right">
        <FormSameAsField item={field} />
        <FormInput field={field} sectionId={sectionId} rowIndex={rowIndex} />
      </TableCell>
    </TableRow>
  );
};