import { Route, Routes } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import ManageData from './manage-data';
import ViewData from './view-data';

const MyDataView = () => {
  const classes   = buildStyles();
  
  return (
    <Grid id="my-data-view" container className={classes.dataView}>
      <Routes>
        <Route path="/*" end element={<ManageData />} />
        <Route path="view" end element={<ViewData />} />
        <Route path="view/:conversationId" element={<ViewData />} />
      </Routes>
    </Grid>
  );
}

export default MyDataView;

const buildStyles   = makeStyles(theme => ({
  dataView  : {
    padding     : theme.spacing(1),
    width       : "100%", //"90%",
  },
  title   : {
    fontSize    : 22,
    fontWeight  : 500,
    marginBottom: 0,
  },
  tab   : {
    fontSize  : 15,
  },
  tabPanel  : {
    padding   : `${theme.spacing(2)} 0`, //`
  }
}));

