import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, ButtonGroupProps, ButtonProps, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, PopperPlacementType } from "@mui/material";
import { ReactNode, useRef, useState } from "react";

export type SplitButtonOption = {
  label: string;
  icon?: ReactNode;
  onClick: () => void;
}

export interface ISplitButtonProps extends ButtonGroupProps {
  label: string;
  onClick: () => void;
  buttonProps?: ButtonProps;
  options: SplitButtonOption[];
  popperPlacement?: PopperPlacementType | undefined;
}

const SplitButton = ({ label, onClick, buttonProps, popperPlacement, options, ...groupProps }: ISplitButtonProps) => {
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup {...groupProps} ref={anchorRef} >
        <Button onClick={onClick} {...buttonProps}>{label}</Button>
        <Button onClick={handleToggle}  size="small" aria-label="split button" aria-controls="split-button-menu" aria-haspopup="true" aria-expanded={false}>
          <ArrowDropDown fontSize="small" />
        </Button>
      </ButtonGroup>

      <Popper sx={{ zIndex: 1 }} open={isOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement={popperPlacement}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={index} onClick={option.onClick}>
                      {option.icon && (<ListItemIcon>{option.icon}</ListItemIcon>)}
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

    </>
  )
};

export default SplitButton;