import { SxProps, Theme } from "@mui/material";

//SX for the list header cells
export const headerFieldSx = (fieldType: string, sx?: any) => ({ 
  fontWeight: 300, 
  fontSize: 15, 
  color: "grey.500",
  p: 0.25,
  textAlign: fieldType === "currency" ? "right !important" : "left",
  ...(sx ?? {})
});

export const commentIconSx = {
  alignSelf : "flex-end",
  float : "right",
  fontSize : 14,
  verticalAlign : "center",
  marginLeft : 3,
  color : "secondary.main",
};