import { useState } from "react";
import { useDispatch } from "react-redux";
import moment, { Moment } from "moment";
import { ErrorOutline, Share } from "@mui/icons-material";
import { Alert, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { useDateValidation } from "helpers/component-hooks";
import { DocumentSchema } from "types/schema";
import { updateClientDocument } from "store/actions/attorney-actions";
import { StatusKeys } from "helpers";
import { ClientDocument } from "types";
import { useParams } from "react-router-dom";

export interface IShareDocumentProps {
  item: ClientDocument;
  open: boolean;
  onClose: () => void;
}

const ShareDocumentDialog = ({item, open, onClose}: IShareDocumentProps) => {
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [props, setProps] = useState({ description: item.description ?? ""});
  const [dateValue, setDateValue] = useState<Moment | null>(item.dueAt ? moment(item.dueAt) : null);
  const { setError, errorMessage } = useDateValidation();
  const minValue = moment().startOf("day");
  const status = useStatus(StatusKeys.clientDocuments);

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  };

  const handleSave = async () => {

    const changes = item.isSharedWithClient ? unShareChanges() : shareChanges();

    console.log("save document", changes);
    const result = await dispatch(updateClientDocument(clientId!, item.id, changes)) as any;
    if(result.isOk){
      toast("Document has been updated", {type: "success"});
      onClose();
    }
  };

  const shareChanges = () => {
    const changes: Partial<DocumentSchema> = {
      ...props,
      ...(dateValue ? {dueAt: dateValue.toDate().getTime()} : {}),
      isSharedWithClient: true,
    };
    return changes;
  }

  const unShareChanges = () => {
    const changes: Partial<DocumentSchema> = {
      isSharedWithClient: false,
    };
    return changes;
  }

  return (
    <Dialog open={open} onClose={() => onClose()} fullWidth maxWidth="sm">
      <DialogTitle>
        <Box display="flex" alignContent="center" alignItems="center">
          <Share fontSize="small" sx={{ mr: 1 }}/>
          <Typography component="span">Document Sharing</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        
        {status.error && (
          <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error">
            {status.error?.code ?? status.error}
          </Alert>
        )}

        {item.isSharedWithClient && (
          <Box sx={{ mt: 2 }}>
            <Alert severity="warning">Click the un-share button to turn off sharing for this document</Alert>
          </Box>
        )}
        { !item.isSharedWithClient && (
          <Stack spacing={2} mt={2}>
            <DatePicker 
              label="Due Date" 
              value={dateValue} 
              minDate={minValue} 
              onChange={(newVal) => setDateValue(newVal)} 
              onError={(newError) => setError(newError)}
              slotProps={{
                textField: {
                  helperText: errorMessage,
                },
              }}
            />
            <TextField id="description" value={props.description} onChange={handleChange} label="Description" multiline rows={3} />
          </Stack>
        )}

      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose()}>Cancel</Button>
        {!item.isSharedWithClient && <Button onClick={handleSave} color="primary">Share</Button>}
        {item.isSharedWithClient && <Button onClick={handleSave} color="primary">Un-Share</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default ShareDocumentDialog;