import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { ArrowBack, ArrowForward, CheckCircleOutline, Close } from '@mui/icons-material';
import { FormRegion } from 'types';
import { updateFormStatus } from 'store/actions/value-actions';
import { useFormStatus, usePetitionerForm, useRegionStatus } from '../form-hooks';

export interface IRegionNavProps {
  region: FormRegion;
  onSave: () => Promise<void>;
  reposition: () => void;
}

const RegionNav = ({region, onSave, reposition}: IRegionNavProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shareId, documentId } = useParams();
  const { submitForm } = usePetitionerForm();
  const formStatus = useFormStatus();
  const isComplete = useRegionStatus(region.id);

  const onNav = async (to: string | undefined) => {
    if(to !== undefined){
      //Save changes before we nav
      const toUrl = `/app/${shareId}/forms/${documentId}${to}`;
      await onSave();
      navigate(toUrl);
      reposition();
    }
  }

  const handleClose = async () => {
    navigate("/app");
  }

  const handleSubmit = async () => {
    //checkbox not displayed on the final page, so mark it complete when submitted.
    await dispatch(updateFormStatus(documentId, region.id, true));  //Mark this region as complete
    await submitForm();
  }

  function toggleComplete(e: any){
    const newVal  = e.target.checked;
    dispatch(updateFormStatus(documentId, region.id, Boolean(newVal)));
  }

  if(region.next !== undefined || region.prev !== undefined){
    return (
      <Grid container alignItems="center" flexWrap="nowrap" pt={2}>
        <Grid item xs={3} container justifyContent="flex-start">
          {region.prev !== undefined && (
            <Tooltip title="Go to the previous section">
              <Button size="small" onClick={() => onNav(region.prev)} color="primary" startIcon={<ArrowBack fontSize="small"/>}>Previous</Button>
            </Tooltip>
          )}
        </Grid>
          <Grid item xs={6} container justifyContent="center" flexWrap="nowrap">
            {region.next !== undefined && (
              <>
                <Grid item>
                  <Checkbox checked={isComplete} size="small" color="primary" onChange={toggleComplete} />
                </Grid>
                <Grid item container direction="column">
                  <Typography fontSize="medium" color="primary">I'm done with this section</Typography>
                  <Typography fontSize="small">(You can always return and make changes)</Typography>
                </Grid>
              </>
            )}
          </Grid>
        <Grid item xs={3} container justifyContent="flex-end">
          {region.next !== undefined && (
            <Tooltip title="Go to the next section">
              <Button size="small" onClick={() => onNav(region.next)} color="primary" endIcon={<ArrowForward fontSize="small"/>}>Save & Next</Button>
            </Tooltip>
          )}
          {region.next === undefined && (
            <Stack direction="row" spacing={1}>
              <Tooltip title="Close this form and return later to finish up">
                <Button size="small" onClick={handleClose} variant="outlined" color="primary" startIcon={<Close fontSize="small"/>}>Close</Button>
              </Tooltip>
              {!formStatus.isSubmitted && (
                <Tooltip title="Submit this form to your attorney">
                  <Button size="small" onClick={handleSubmit} disabled={formStatus?.isSubmitted} variant="contained" disableElevation color="primary" endIcon={<CheckCircleOutline fontSize="small"/>}>Submit</Button>
                </Tooltip>
              )}
              {/* {formStatus.isSubmitted && (
                <Box height="100%" display="flex" alignItems="center">
                  <Tooltip title={`You submitted this form on ${formatShortDate(formStatus.submittedOn ?? formStatus.updatedOn)}`}>
                    <Chip icon={<CheckCircle fontSize="small" />} label="Submitted" color="primary"/>
                  </Tooltip>
                </Box>
              )} */}
            </Stack>
          )}
        </Grid>
      </Grid>
    )
  }
  else{
    return null;
  }
}

export default RegionNav;