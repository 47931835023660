import _ from 'lodash';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

export function useNav(){
  const navigate   = useNavigate();
  const onNav   = (url) => {
    navigate(url);
  }
  return onNav;
}

export function useQuery(){
  return new URLSearchParams(useLocation().search);
}

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export function useAnchor(position){
  const [anchor, setAnchor]   = useState(null); //TODO: this is throwing errors in the console, need to switch to a ref here.

  const onOpen    = (e) => { setAnchor(e.currentTarget); };
  const onClose   = (e) => { setAnchor(null); };
  const positionProps   = positions[position] || positions["bottomRight"];

  return [
    anchor,
    Boolean(anchor),
    onOpen,
    onClose,
    positionProps,
  ];
}

export function useHover(){
  const onEnter   = (e) => { setIsOver(true); };
  const onExit    = (e) => { setIsOver(false); };
  const [isOver, setIsOver]    = useState(false);

  return [
    isOver,
    onEnter,
    onExit,
  ];
}

export function useRouteMatches(paths) {
  const { pathname } = useLocation();
  const isMatch = useMemo(() => {
    if(_.isString(paths)) return pathname.startsWith(paths);
    else if(Array.isArray(paths)) return paths.some(path => pathname.startsWith(path));
    else {
      console.warn("useRouteMatches: paths must be a string or array of strings", paths); 
      return false;
    } 
    //switch to a regex
    // const routeMatcher 	= pathname.match(/\/app|\/my|\/admin/);
    // const routeMatcher = 
    // return Boolean(routeMatcher);
    // return pathname === "/app" || pathname === "/my" || pathname === "/admin";
  }, [pathname, paths]);

  return isMatch;  
}


// export function useAnchor(position){
//     const [anchor, setAnchor]   = useState(null);

//     const onEnter   = (e) => { setAnchor(e.currentTarget); };
//     const onExit    = (e) => { setAnchor(null); };
//     const positionProps   = positions[position] || positions["bottomRight"];

//     // console.log("** run useHover");

//     return [
//       anchor,
//       Boolean(anchor),
//       onEnter,
//       onExit,
//       positionProps,
//     ];
// }

export function usePrepareModel(model){
  const [data, setData]   = useState(null);

  useEffect(() => {
    async function prepareItem(item){
      let data  = item;
      if(!data.isLoaded && data.ref){
        data  = {...data, isLoaded: true, ...(await data.ref.data())};
      }
      setData(data);
    }
    prepareItem(model);
    return undefined;
  }, [model]);

  return data;
}

export function usePrepareModels(models){
  const [data, setData]   = useState([]);

  useEffect(() => {

    async function prepareItems(items){
      let data  = await Promise.all(_.map(items, async (item) => {
        if(!item.isLoaded && item.ref){
          const itemData  = await item.ref.data();
          return {...item, isLoaded: true, ...itemData};
        }
        else return item;
      }));

      setData(data);
    }

    if(!models || models.length === 0) setData(models);
    else prepareItems(models);

    return undefined;
  }, [models]);

  return data;
}

export function useInput(defaultValue){
  const [original, setOriginal]   = useState(defaultValue || "");
  const [value, setValue]         = useState(defaultValue || "");
  const [isEditing, setEditing]   = useState(false);

  const onChange  = (e) => {
    setValue(e.currentTarget.value);
  }

  const onToggle  = () => {
    setEditing(!isEditing);
  }

  const onCancel  = () => {
    setValue(original);
    setEditing(false);
  }

  const onCommit  = () => {
    setOriginal(value);
    setEditing(false);
  }

  return [value, onChange, isEditing, onToggle, onCancel, onCommit];
}

//------
// handles the dirty work for importing a lazy component.
// use as follows:
//  1. create an "importer", which looks like this ():
//    const importFooter = school => lazy(() => import(`./theme/${school}/footer.js`).catch(() => console.error("failed to load footer")));
//  2. call the hook as follows:
//    const footer  = useLazyComponent(() => importFooter(school), {config: config}, "Loading...");
//  3. add {footer} to the jsx where appropriate
export function useLazyComponent(importer, componentProps, fallback){
  const [component, setComponent]   = useState(null);

  useEffect(() => {
    async function loadComponent(){
      const Component  = await importer(); //importComponent(importPath, failFunc);
      return (
        <React.Suspense fallback={fallback}>
          <Component {...componentProps} />
        </React.Suspense>
      )
    }
    Promise.all([loadComponent()])
      .then(results => {
        console.log("resolved component");
        setComponent(results[0])
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return component;
}

const positions   = {
  bottomRight   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "right"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "right",
    }
  },
  bottomLeft   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "left"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "left",
    }
  },
  bottomCenter   : {
    anchorOrigin  : {
      vertical    : "bottom", 
      horizontal  : "center"
    },
    transformOrigin   : {
      vertical    : "top",
      horizontal  : "center",
    }
  },
}