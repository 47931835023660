import _ from 'lodash';
import { create, all } from 'mathjs'
import FIELDS from '../config/fields-config';
import { getMultiType, getSectionFields, isMultiCondition, splitMultiConditions } from './form-config-helpers';

const math = create(all, {});

export const calculateRegionTotal = (region, data, dataField, allFields) => {
    if(!data) return 0;
    return _.sum(region.sections.map(section => {
        const sectionFields = allFields ? getSectionFields(section, allFields, region.id) : getFields(section.fields);
        return calculateSectionTotal(section, data, dataField, sectionFields)
    }));
}

export const getOtherTotals = (region, section, data, allFields) => {
    if(!data || !section.otherTotalFields || !_.isArray(section.otherTotalFields)) return null;
    
    const output    = {};
    const fields    = section.otherTotalFields;
    allFields = allFields ?? FIELDS;
    for(var i = 0; i < fields.length; i++){
        // eslint-disable-next-line no-loop-func
        const field     = _.find(allFields, f => f.id === fields[i]);
        if(field.isOptionTotals && field.options){
            //TODO: total things up by each option...            
            const list      = data[section.id];
            field.options.forEach(opt => {
                const filtered  = _.filter(list, li => ((li[field.id] === opt.id) || (opt.id === 0 && li[field.id] === undefined)));
                if(filtered.length > 0){
                    const total     = _.sumBy(filtered, f => f[field.optionsTotalField]);
                    const key       = `${field.id}_${opt.id}`;
                    output[key]     = total; 
                }
            });
        }
        else{
            const id        = `t_${region.id}_${section.id}_${fields[i]}`;
            const sectionFields = allFields.filter(f => section.fields.includes(f.id));
            output[id]      = calculateSectionTotal(section, data, fields[i], sectionFields);
        }
    }

    return output;
}

export const calculateSectionTotal = (section, data, dataField, sectionFields) => {
    if(!data) return 0;
    
    if(section.condition){
        if(!isConditionMet(section.condition, data)) return 0;
    }

    const fields = sectionFields ?? section.fields ?? getFields(section.fields);
    
    if(section.type === "list"){
        //For a list, there are multiple rows, and one of the fields will be the field to total
        const rows          = data[section.id];
        if(!rows || rows.length === 0) return 0;

        const totalFieldId  = dataField || section.totalField;
        const totalField    = sectionFields ? sectionFields.find(f => f.id === totalFieldId) : getField(totalFieldId); //dataField ? _.find(fields, f => f.id == dataField) : _.find(fields, f => f.isTotal);    
        if(!totalField) return 0;
        
        if(totalField.calculation){
            return sumCalculatedField(totalField, rows);
        }
        return _.sumBy(rows, d => d[totalField.id]);
    }
    else{
        const sectionData   = _.pick(data, section.fields);
        const fieldsToTotal = fields.filter(f => isTotaled(f));
        const sum = _.sumBy(fieldsToTotal, f => sectionData[f.id]);
        return sum;
    }
}

export const addRowTotals = (section, data, allFields) => {
    const rowData   = data[section.id];
    const tFields   = getFields(section.rowTotalFields, undefined, allFields);
    const updated   = _.map(rowData, row => {
        const rowTotals     =     _.reduce(tFields, (output, field) => {
            return {
                ...output,
                [field.id]    : calculateCalcField(field, row),
            };
        }, {});

        return {
            ...row,
            ...rowTotals,
        };
    });

    return updated;
}

export const addRowOptionNames = (section, data, allFields) => {
    const rowData   = data[section.id];
    const oFields   = getFields(section.addOptionNames, undefined, allFields);
    const updated   = _.map(rowData, row => {
        const names     =     _.reduce(oFields, (output, field) => {
            const fieldKey  = field.id + "Name";
            const val       = parseInt(row[field.id]);
            const name      = field.options[val];
            return {
                ...output,
                [fieldKey]   : name ? name.label : "- unknown -",
            };
        }, {});

        return {
            ...row,
            ...names,
        };
    });

    return updated;
}

export const calculateCalcField = (field, row) => {
    const expr      = math.parse(field.calculation);
    const varsFunc  = getCalculatedParameters(field.calculation);
    var p           = varsFunc(row);
    var val         = math.simplify(expr, p).evaluate();  
    return val;
}

export const sumField = (field, rows) => {
    rows    = _.compact(rows);
    if(!rows || rows.length === 0) return 0;
    
    if(field.calculation){
        return sumCalculatedField(field, rows);
    }
    else{
        return _.sumBy(rows, r => r ? r[field.id] : 0);
    }
}

const sumCalculatedField = (field, rows) => {
    const expr      = math.parse(field.calculation);
    const varsFunc  = getCalculatedParameters(field.calculation);

    let total       = 0;
    rows.forEach(row => {
        var p       = varsFunc(row);
        var val     = math.simplify(expr, p).evaluate();  
        total       += val;
    });

    return total; // _.sumBy(rows, d => d ? math.eval(expr, vars(d)) : 0);
}

const getCalculatedParameters = (formula) => {    
    return (d) => {
        const parts     = formula.split(" ");
        let vars        = {};
        for(var i = 0; i <= parts.length; i = i + 2){
            vars[parts[i]]  = d[parts[i]] || 0;
        }
        return vars;
    };
}

const calculateSummaryField = (field, data, regions, regionFields) => {
    let calcStr     = field.calculation;
    const parts     = calcStr.split(" ");
    let vars        = {};
    for(var i = 0; i < parts.length; i = i + 2){
        let vKey    = parts[i];
        let vSubKey = null;
        
        if(vKey.indexOf("[") > 0){
            const orig  = vKey;
            const idx   = vKey.indexOf("[");
            vSubKey     = vKey.substr(idx + 1, vKey.length - idx - 2); 
            vKey        = vKey.substr(0, idx);
            calcStr     = calcStr.replace(orig, vKey);    //Need to update the calcStr so math can parse it
        }
        
        const vReg  = _.find(regions, r => r.id === vKey);
        const vVal  = calculateRegionTotal(vReg, data, vSubKey, regionFields);
        vars[vKey]  = vVal || 0;
    }

    const expr      = math.parse(calcStr);
    const total     = math.simplify(expr, vars).evaluate();
    return total;
}

export const calculateSummaryRegion = (field, data, regions, regionFields) => {
    let vKey        = field.regionId;
    let vSubKey     = null;
    
    //Deal with custom total keys, like: debts[balance] to sum the balance prop of the debts region
    if(vKey.indexOf("[") > 0){
        const idx   = vKey.indexOf("[");
        vSubKey     = vKey.substr(idx + 1, vKey.length - idx - 2); 
        vKey        = vKey.substr(0, idx);
    }
    
    const vReg  = _.find(regions, r => r.id === vKey);
    const vVal  = calculateRegionTotal(vReg, data, vSubKey, regionFields);
    return vVal;
}

const toPairs = (array) => {
    return array.reduce((result, value, index, array) => {
        if (index % 2 === 0) result.push(array.slice(index, index + 2));
        return result;
      }, []);
}

const isTotaled = (field) => {
    return field && (field.type === undefined || (field.type !== "question" && field.type !== "checkbox"));
    // return field && (!field.type || (field.type != "question"  && field.type != "checkbox");
}

const isNotQuestion =(field) => {
    return field && (!field.type || field.type !== "question");
}

const isConditionUndefined = (condition, data) => {
    if(condition){
        const parts     = condition.split(":");
        return !data || data[parts[0]] === undefined;
        // const condData  = data && data[parts[0]] ? data[parts[0]].toString() : undefined;
        // return condData === undefined;
    }

    return false;
}

const isNullOrUndefined = (val) => {
    return val === null || val === undefined;
};

const isConditionMet = (condition, data) => {
    if(condition){
        if(isMultiCondition(condition)){
            const opType = getMultiType(condition);
            const conditions = splitMultiConditions(condition);
            return opType === "&&" ? 
                conditions.every(c => isConditionMet(c, data)) : 
                conditions.some(c => isConditionMet(c, data));
        }

        const parts     = condition.split(":");
        const condData  = data && (isNullOrUndefined(data[parts[0]])) ? null : data[parts[0]].toString();
        let value       = parts[2] === "null" ? null : parts[2];
        if(value.indexOf("[") >= 0 && value.indexOf("]") > 0){
            value = value.replace("[", "").replace("]","").split(",");
        }

        // value           = value;

        switch(parts[1]){
            case "="        : 
                if(_.isArray(value)) return value.indexOf(condData) >= 0;
                else return condData === value;      //the data matches the condition value

            case "!="       : 
                if(_.isArray(value)) return value.indexOf(condData) < 0;
                else return condData !== value;
                
            //TODO: Deal with other comparison operators...
            default: return false;
        }
    }

    return true;
}

const isConditionMet2 = (condition, condData) => {
    if(condition){
        if(isMultiCondition(condition)){
            const opType = getMultiType(condition);
            const conditions = splitMultiConditions(condition);
            return opType === "&&" ? 
                conditions.every(c => isConditionMet2(c, condData)) : 
                conditions.some(c => isConditionMet2(c, condData));
        }

        const parts     = condition.split(":");
        // const condData  = data && (data[parts[0]] !== undefined) ? data[parts[0]].toString() : null;
        let value       = parts[2] === "null" ? null : parts[2];
        if(value.indexOf("[") >= 0 && value.indexOf("]") > 0){
            value = value.replace("[", "").replace("]","").split(",");
        }

        // value           = value;

        switch(parts[1]){
            case "="        : 
                if(_.isArray(value)) return value.indexOf(condData) >= 0;
                else return condData === value;      //the data matches the condition value

            case "!="       : 
                if(_.isArray(value)) return value.indexOf(condData) < 0;
                else return condData !== value;
                
            //TODO: Deal with other comparison operators...
            default: return false;
        }
    }

    return true;
}

export const getField = (fieldId, raiseError = true, fieldsOverride) => {
    if(!_.isString(fieldId)){
        if(raiseError) console.error("Invalid Field Id provided", fieldId);
        return null;
    }
    const allFields = fieldsOverride || FIELDS;
    const field     = _.find(allFields, {id: fieldId});    
    if(!field && raiseError) console.error("Unrecognized field: " + fieldId);
    return field;
}

const getFields = (idList, raiseError = true, fieldsOverride) => {
    if(idList === "*") return FIELDS;
    return _.map(idList, id => getField(id, raiseError, fieldsOverride));
}

function generateUid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
      return v.toString(16);
    });
}

export const filterOutSummaryRegions = (region) => {
    return (region.type !== "summary" && region.id !== "docs" && region.id !== "review")
}

export const filterInSummaryRegions = (region) => {
    return (region.type === "summary");
};

const financialsHelper = {
    calculateRegionTotal    : calculateRegionTotal,
    calculateSectionTotal   : calculateSectionTotal,
    calculateCalcField      : calculateCalcField,
    calculateSummaryField   : calculateSummaryField,
    calculateSummaryRegion  : calculateSummaryRegion,
    toPairs                 : toPairs,
    isNotQuestion           : isNotQuestion,
    isConditionMet          : isConditionMet,
    isConditionMet2         : isConditionMet2,
    isConditionUndefined    : isConditionUndefined,
    sumField                : sumField,
    getField                : getField,
    getFields               : getFields,
    getOtherTotals          : getOtherTotals,
    addRowTotals            : addRowTotals,
    addRowOptionNames       : addRowOptionNames,
    generateUid             : generateUid,
    filterOutSummaryRegions : filterOutSummaryRegions,
};

export default financialsHelper;