import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { FormField } from "types";
import { useRegion } from "../region-context";

export interface IToggleFieldProps {
  field: FormField;
  dataProps?: any;
  value: any;
}

//NOTE: MaterialUI Components don't use the native events, so they don't bubble to get handled by the withFieldChange HOC.
// Therefore, we need to explicitly handle the change event here, and call the handleValueChanged function from the region context
const ToggleField = ({ field, dataProps, value } : IToggleFieldProps ) => {
  // const opts = field.options;
  const [val, setVal] = useState<string>(value ?? null);
  const { handleValueChanged } = useRegion();
  const classNames = `form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  //row index gets assigned as a dataProp
  const rowIndex = dataProps ? dataProps["data-index"] : null;

  if(!field.options) throw new Error("Toggle field must have options");

  const handleChange = async (event: React.MouseEvent<HTMLElement>, newValue: any) => {
    console.log(newValue);
    if(newValue === value) newValue = null;   //if they're clicking the same value, unselect it
    setVal(newValue);
    await handleValueChanged(field, newValue, null, rowIndex);
  };

  const options = field.options.map(opt => (
    <ToggleButton 
      key={opt.id} 
      value={opt.id.toString()} 
      disabled={opt.isDisabled} 
      size="small"
      onChange={handleChange}
      sx={{ py: 0.5, fontSize: "0.85rem" }}
    >
      {opt.label}
    </ToggleButton>
  ));
  
  return (
      <ToggleButtonGroup
        id={field.id} 
        value={val} 
        className={classNames} 
        {...dataProps} 
        size="small"
        color="secondary"        
      >
        {options}
      </ToggleButtonGroup>
  );
};

export default ToggleField;