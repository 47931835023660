//Status Keys used throughout the app for different area
export const StatusKeys = {
  formContext: "formcontext",
  conversations: "conversations",
  client: "client",
  clients: "clients",
  clientDocuments: "clientDocuments",
  shares: "shares",
  dates: "dates",   //client dates, from both perspectives
  notes: "notes",   //client notes
  forms: "forms",   //attorney forms

  subscriptions: "subscriptions",
  accounts: "accounts",

  petitioner: "petitioner",
};