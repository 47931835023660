import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

function TextDivider({text = null, cols, textCols = null}){

  const classes   = useStyles();
  const myCols    = cols || 8;
  const tCols     = textCols || 2;
  const dCols     = Math.ceil((12 - tCols) / 2);

  return (
    <Grid item sm={myCols} container justifyContent="center" alignItems="center" className={classes.divider}>
      <Grid item sm={dCols} container alignItems="center">
        <Divider style={{width: "100%"}}/>          
      </Grid>
      <Grid item sm={tCols} container alignItems="center" justifyContent="center">
        {text && <Typography className={classes.info}>{text}</Typography>}
        {!text && <Divider style={{width: "100%"}}/>  }
      </Grid>
      <Grid item sm={dCols} container alignItems="center">
        <Divider style={{width: "100%"}}/>          
      </Grid>
    </Grid>
  );
}

export default TextDivider;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(3)} ${theme.spacing(1)}`,
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
  },
  divider         : {
    marginTop         : theme.spacing(1),
  },
}));