import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import Helmet from 'react-helmet';
import { Box } from '@mui/material';
import { useLoc } from 'hooks';
import LandingContainer from '../landing/landing-container';
import AppContainer from './app-container';
import Initializing from './app-initializing';
import AppAuth from './app-auth';

function AppRouter(){
  const { s } = useLoc();
  const needsRefresh = useSelector(state => state.app.needsRefresh);

  useEffect(() => {
    if(needsRefresh) window.location.reload();
  }, [needsRefresh]);

  return (
    <div id="app-router">
      <Helmet><title>{s("appTitle")}</title></Helmet>
      <Box id="app-router-routes" >
        <Routes>
          {/* These auth routes are used as redirects for the auth flow. They should not manipulate the URL in any way */}
          <Route path="/auth" element = { <AppAuth /> } />
          <Route path="/auth/*" element = { <AppAuth /> } />

          <Route path="/legal/*" element={<LandingContainer />} />  
          <Route path="/initializing" element={<Initializing />} />
          
          <Route path="/" exact element={<Navigate to="/app" /> } />
          <Route path="/*" element={ <AppContainer /> } />           
        </Routes>
      </Box>
    </div>
  );
}

export default AppRouter;