import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { initializePayments } from "helpers/payments-helper";
import { useStatus } from "redux-action-status";
import { loadStripeCustomer, updateProfileSetting } from "store/actions/app-actions";
import { loadMyConversations } from "store/actions/conversation-actions";
import { initializeSharing } from "store/actions/share-actions";
import { loadData } from "store/actions/value-actions";
import { selectProfile } from "store/selectors/app-selectors";
import { loadClientList } from "store/actions/attorney-actions";
import { selectAllowComments } from "store/selectors/conversation-selectors";
import { ProfileSettings } from "types/schema";
import { SettingDefaults, StatusKeys, settingCategory } from "helpers";

export type IAppContext = {
  profile: any;
  uid: string;
  isUser: boolean;
  isReviewer: boolean;
  isAuthed: boolean;
  settings: ProfileSettings;    //the settings for this user
  allowComments: boolean;
  settingValue: (key: string, fallback?: any) => any;
  settingJsonValue: (key: string, defaults?: Record<string, any>) => Record<string, any>;
  saveSetting: (key: string, value: any) => Promise<void>;
}

export const AppContext = createContext<IAppContext | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);
  if(!context) throw new Error("useAppContext must be used within a AppProvider");
  return context;
};

export const useProfileJsonSetting = <T = Record<string, any>>(key: string) => {
  const { settingJsonValue, saveSetting } = useAppContext();
  const [value, setValue] = useState<T>(settingJsonValue(key) as T);

  const updateSetting = useCallback(async (newValue: T | Partial<T> | null) => {
    if(newValue === null) {
      await saveSetting(key, null);
      setValue({} as T);
      return;
    }

    setValue({ ...value, ...newValue });
    const strValue = JSON.stringify(newValue);
    await saveSetting(key, strValue);
  }, [key, value, saveSetting]);

  return { setting: value, updateSetting };
}

//TODO: Create a useSetting hook that will get the value of a setting, and save it when changed

export const AppProvider = ({ children }: { children: ReactNode }) => {
  const profile = useSelector<any, any>(selectProfile);
  const isAuthed = useSelector<any, boolean>(state => state.app.isAuthenticated);

  const sharing = useStatus(StatusKeys.shares);
  const conversations = useStatus("conversations");

  //check the setting for allowing comments
  //TODO: NEXT - test this out to make sure the selector works as expected
  const allowComments = useSelector<any, boolean>(selectAllowComments);
  
  const dispatch  = useDispatch();
  const { isUser, isReviewer, uid, settings } = profile;

  //Effect to retrieve values data
  useEffect(() => {
    async function loadValues(){
      await dispatch(loadData());
    }
    if(isAuthed === true && isUser) loadValues();
  }, [isAuthed, isUser, dispatch]);

  //TODO: turn payments back on post-beta
  //Initialize payments
  // useEffect(() => {
  //   async function runAsync(){
  //     initializePayments();
  //     await dispatch(loadStripeCustomer()); //load the customer so we have subscription information
  //   }

  //   if(isAuthed) runAsync();
  // }, [isAuthed, dispatch]);

  //Initialize sharing
  useEffect(() => {    
    if(isAuthed && uid &&!sharing.isInitialized && !sharing.isWorking) {
      dispatch(initializeSharing());
    }
  }, [isAuthed, uid, sharing, dispatch]);

  //load the provider client profiles
  useEffect(() => {
    if(isAuthed && isReviewer){
      dispatch(loadClientList());
    }
  }, [isAuthed, isReviewer, dispatch]);

  //Load all the user's conversations (works for either user or reviewer)
  useEffect(() => {
    if(isAuthed && uid && sharing.isInitialized && !conversations.isInitialized && !conversations.isWorking && !conversations.error){
      console.debug("loading user's conversations");
      dispatch(loadMyConversations());
    } 
  }, [isAuthed, uid, sharing, conversations, dispatch]);
  
  //#region Settings Functions

  const settingValue = useCallback((key: string, fallback?: any) => {
    const category = settingCategory(key);
    const cat = settings[category];
    let val = cat ? settings[category][key] : undefined;
    if(val === undefined){
      if(fallback) return fallback;
      //look for a default in the helpers
      val = SettingDefaults[key];      
    }
    return val;
  }, [settings]);

  const settingJsonValue = useCallback((key: string, defaults?: Record<string, any>): Record<string, any> => {
    const category = settingCategory(key);
    const cat = settings[category];
    let val = cat ? settings[category][key] : undefined;
    
    if(typeof val === "string"){
      val = JSON.parse(val);
    }

    if(defaults){
      if(!val) return defaults;
      else return { ...defaults, ...val };
    }

    if(val === undefined) val = SettingDefaults[key] ?? {};
    return val;
  }, [settings]);

  const saveSetting = useCallback(async (key: string, value: any) => {
    const updates = { [key]: value };
    const result = await dispatch(updateProfileSetting(key, updates)) as any;
    if(!result.isOk){
      console.error("Error updating setting", key, value);
    }
  }, [dispatch]);
  
  //#endregion

  const contextValue = useMemo(() => ({
    profile,
    uid: profile.uid,
    isUser: profile.isUser ?? false,
    isReviewer: profile.isReviewer ?? false,
    isAuthed,
    settings: settings,
    allowComments,
    settingValue,
    settingJsonValue,
    saveSetting,
  }), [profile, isAuthed, settings, allowComments, settingValue, settingJsonValue, saveSetting]);

  console.debug("app-context rendered");
  return (
    <AppContext.Provider value={contextValue}>
      { children }
    </AppContext.Provider>
  );

};