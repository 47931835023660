import moment from 'moment';
import { Navigate } from 'react-router-dom';

export function formatDate(toFormat){
  try{
    if(toFormat.toDate){
      return toFormat.toDate().toLocaleString();
    }
    if(typeof(toFormat) !== typeof(Date)){
      return new Date(toFormat).toLocaleString();
    }
    else{
      return toFormat.toLocaleString();
    }
  }
  catch(ex){
    return "invalid date";
  }
}

const defaultFormatString   = "MMM D, YYYY h:mm A"

export function formatDateString(toFormat, formatString = defaultFormatString){
  if(!toFormat) return "";
  
  try{
    if(toFormat.toDate){
      return moment(toFormat.toDate()).format(formatString || defaultFormatString);
    }
    if(typeof(toFormat) !== typeof(Date)){
      return new moment(new Date(toFormat)).format(formatString || defaultFormatString);
    }
    else{
      return new moment(new Date(toFormat)).format(formatString || defaultFormatString);
    }
  }
  catch(ex){
    return "invalid date";
  }
}

export function formatDateFromNow(toFormat){
  try{
    return new moment(new Date(toFormat)).fromNow();
  }
  catch(ex){
    return "invalid date";
  }
}

export function formatShortDate(toFormat){
  try{
    return new moment(new Date(toFormat)).format("MMM D, YYYY");
  }
  catch(ex){
    return toFormat; // "invalid date";
  }
}

export function dateStringToNumber(dateString){
  try{
    return new Date(dateString).getTime();
  }
  catch(ex){
    return dateString;  //fallback
  }
}

//----------
// Creates a redirect adding a redirect search parameter, if necessary
export function redirectAddingReroute(location, to, defaultPath){
  const myDefault   = defaultPath || "/app";

  let search        = new URLSearchParams(location?.search ?? "");
  if(location.pathname !== myDefault){
    search.append("redirect", location.pathname);
  }

  // const target  = { pathname: to, search: search.toString() };  //need to pass along the search if there is one
  // return redirect(target); // <Redirect to={target} />;
  const target = `${to}?${search.toString()}`;
  return <Navigate to={target} replace={true}/>;
}

//----------
// Creates a redirect that takes into account the redirect search parameter that may exist
export function redirectConsideringReroute(location, to){
  const search    = new URLSearchParams(location?.search ?? "");
  const redirect  = decodeURIComponent(search.get("redirect") || to);
  // return redirect(redirect); // <Redirect to={redirect} />;
  return <Navigate to={redirect} replace={true}/>;
}

//----------
// Creates a redirect adding the current query. Filter which keys are included
// using the keysToInclude array
export function redirectWithQuery(location, to, keysToInclude){
  let original  = new URLSearchParams(location?.search ?? "");
  let updated   = keysToInclude ? new URLSearchParams() : original;
  if(keysToInclude){
    keysToInclude.forEach(key => {
      const val   = original.get(key);
      if(val) updated.append(key, val);
    });
  }
  
  // const target  = { pathname: to, search: updated.toString() };  //need to pass along the search if there is one
  const target = `${to}?${updated.toString()}`;
  return <Navigate to={target} replace={true}/>; // <Redirect to={target} />;
}

//----------
// Asynchronously enumarates an array, and executes a callback function
export async function asyncForEach(array, callback) {
  let promises  = [];
  for (let index = 0; index < array.length; index++) {
    promises.push(callback(array[index], index, array));
  }
  return await Promise.all(promises);
}

export function getRegionStatusKey(documentId, regionId){
  return `${documentId}::${regionId}`;
}

export const formatExternalUrl = (url) => {
  if(!url) return "";
  if(url.startsWith("http")) return url;
  return `https://${url}`;
}