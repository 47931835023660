import { TableCell, TableHead, TableRow } from "@mui/material";
import { FormField } from "types";
import { headerFieldSx } from "./data-table-list-utils";

const DataTableListHeader = ({ fields, isEditing, isEmpty }: { fields: FormField[], isEditing: boolean, isEmpty: boolean }) => {

  const header = (field: FormField) => { return (field as any).shortHeader ?? (field as any).header ?? field.label };

  if(isEditing || isEmpty) return null;

  return (
    <TableHead>
      <TableRow>
        {fields.map(field => <TableCell key={field.id} sx={headerFieldSx(field.type)}>{header(field)}</TableCell>)}
      </TableRow>
    </TableHead>
  );
};

export default DataTableListHeader;