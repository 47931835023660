import { useRef } from "react";
import { useHoverDirty } from "react-use";
import { Avatar, Paper, Stack, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Circle } from "@mui/icons-material";

export interface INavCardProps {
  title: string;
  // subTitle?: string;
  subTitle?: string;
  isSelected?: boolean;
  isAttention?: boolean;
  compact?: boolean;
  onClick?: () => void;
}

const NavCard = ({ title, subTitle, isSelected, isAttention, compact, onClick}: INavCardProps) => {
  const theme = useTheme();
  const itemRef = useRef<any>();
  const isHovered = useHoverDirty(itemRef);
  
  const baseColor = isSelected ? theme.palette.secondary : theme.palette.primary;
  const titleBgcolor = isSelected ? `linear-gradient(0deg, #fff 65%, ${alpha(baseColor.light, 0.15)} 100%)` : "white";

  return (
    <Paper 
      ref={itemRef} 
      onClick={onClick} 
      variant="outlined" 
      sx={{ 
        width: "100%", 
        cursor: "pointer", 
        bgcolor: alpha(baseColor.light, 0.25),
        filter: isHovered ? "drop-shadow(5px 5px 10px #ccc)" : undefined, 
        transition: 'filter 0.3s',
        height: compact ? 65 : 95,
      }}
    >
      <Stack>
        <Grid 
          container 
          justifyContent={compact ? "space-between" : "center"}
          alignContent="center" 
          flexWrap="nowrap"
          flexGrow={1} 
          px={2} py={2} 
          sx={{ transition: 'background-color 0.3s', boxShadow: "0 4px 6px -4px " + baseColor.main, background: titleBgcolor, position: "relative", borderRadius: 1 }}
        >
          { isAttention && (
            <Tooltip title="This item needs your attention" arrow>
              <Circle sx={{ color: alpha(theme.palette.secondary.light, 0.5), position: "absolute", top: 0, right: 0, transform: "translate(50%, -50%)" }} />
            </Tooltip>
          )}
        
          <Typography 
            variant="body1" 
            color={isHovered ? baseColor.dark : baseColor.light} 
            fontSize="1.3rem" 
            fontWeight={isSelected ? "800" : "400"} 
            noWrap 
            textTransform="uppercase"
            sx={{ transition: 'color 0.3s', textShadow: isSelected ? "0 0 3px #ccc9, 0 0 3px #ccc9, 0 0 3px #ccc9;" : undefined }}
          >
            {title}
          </Typography>   

          {/* {compact && <Typography variant="body2" color={ isAttention ? "secondary" : "grey.700" } fontWeight={ isAttention ? "700" : "400" } noWrap>{subTitle}</Typography>}        */}
          {compact && <Avatar sx={{ fontSize: "1.3rem", fontWeight: "700", height: 28, width: 28, bgcolor: isSelected ? theme.palette.secondary.light : alpha(theme.palette.primary.light, 0.75)}}>{subTitle}</Avatar>}
        
        </Grid>
        {!compact && (
          <Grid container justifyContent="center" alignContent="center" flexGrow={0} py={1}>
            <Typography variant="body2" color={ isAttention ? "secondary" : "grey.700" } fontWeight={ isAttention ? "700" : "400" } noWrap>{subTitle}</Typography>
          </Grid>
        )}
      </Stack>
    </Paper>
  );
};

export default NavCard;