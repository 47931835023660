import { Link } from "react-router-dom";
import { Button, Chip, IconButton, Stack, Theme, Tooltip, Typography, alpha } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircle, Forum } from "@mui/icons-material";
import { formatDateFromNow, formatDateString, formatShortDate } from "helpers/general-helpers";
import { useStatus } from "redux-action-status";
import { StatusKeys } from "helpers";
import { AssignedDocument } from "@/types/client-document-types";
import ItemCard from "../parts/item-card";
import { useMemo } from "react";
const documentImage = require("./document.jpg");

export interface IDocumentCardProps {
  document: AssignedDocument;
  theme: Theme;
}

const dayLength = 1000 * 60 * 60 * 24;

const DocumentCard = ({ document, theme, }: IDocumentCardProps) => {
  const status = useStatus(StatusKeys.clientDocuments);
  const itemUrl = `/app/${document.shareId}/documents/${document.id}`;
  const isApproved = !!document.submittedAt;
  const isDirty = (document as any).isDirty ?? false;
  
  const dueDateColor = useMemo(() => {
    if(!document.dueAt) return "grey.600";
    const diff = new Date(document.dueAt).getTime() - new Date().getTime();
    if(diff < 0) return "error.main";
    if(diff < (dayLength * 3)) return "warning.main";
    return "inherit";
  }, [document.dueAt]);

  return (
    <ItemCard title={document.name} headerImage={documentImage} url={itemUrl} clickable>
      
      {isDirty && (
        <Tooltip title="You have unread comments about this form">
          <IconButton component={Link} to={itemUrl} sx={{ position: "absolute", top: 5, right: 5, bgcolor: theme.palette.secondary.light, color: "white" }} size="small"><Forum fontSize="small" /></IconButton>
        </Tooltip>
      )}

      <Stack sx={{ flexGrow: 1, justifyContent: "space-between" }}>          
        <div>
          <Tooltip title="This is a document that your attorney has asked you to review and approve">
            <Typography mt={1} align="center" width="100%" fontWeight="800" color={alpha(theme.palette.secondary.main, 0.75)} fontSize="1rem" textTransform="uppercase">Document to Review</Typography>
          </Tooltip>
          
          <Stack spacing={1} px={2} py={1} width="100%">
            {!isApproved && (
              <Stack direction="row" justifyContent="space-between" width="100%">
                <Typography variant="body1" fontSize="1.1rem" color="grey.500">Due:</Typography>
                {document.dueAt && (
                  <Tooltip title={formatDateString(document.dueAt, "MMM D, YYYY")}>
                    <Typography variant="body1" color={dueDateColor}>{formatDateFromNow(document.dueAt)}</Typography>
                  </Tooltip>
                )}
                {!document.dueAt && <Typography variant="body1" color="grey.600" fontStyle="italic">Not set</Typography>}
              </Stack>
            )}
            <Grid container width="100%" justifyContent="center">
              {isApproved && (
                <Tooltip title={`You approved this document on ${formatShortDate(document.submittedAt)}`}>
                  <Chip icon={<CheckCircle fontSize="small" />} label="Approved" color="primary" />
                </Tooltip>
              )} 
            </Grid>
            {document.description && <Typography fontSize="1rem" textAlign="center" px={1}>{document.description}</Typography>}
          </Stack>
        </div>
        
        <Grid direction="column" container px={2} py={1} rowGap={1} alignItems="center">                   
          <Button component={Link} to={itemUrl} disabled={status.isWorking} color="secondary" size="small" fullWidth>Open Document</Button>
        </Grid>

      </Stack>

    </ItemCard>
  );

};

export default DocumentCard;