import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import "firebase/compat/storage";
// import 'firebase/analytics';
import 'firebase/compat/firestore';
import { trackEvent, TrackUser, Events } from 'helpers/analytics';

const config = {
  apiKey              : process.env.REACT_APP_API_KEY, 
  authDomain          : process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL         : process.env.REACT_APP_DATABASE_URL,
  projectId           : process.env.REACT_APP_PROJECT_ID,
  storageBucket       : process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId   : process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId               : process.env.REACT_APP_APP_ID,
  measurementId       : process.env.REACT_APP_MEASURE_ID,
};

let isInitialized = false;
let watcher = null;
let firestore = null;
let storage = null;
// let token = null;

//Export the method to initialize
export const initializeFirebase = () => {
  if(!isInitialized){
    firebase.initializeApp(config);
    // firebase.analytics();
    firestore = firebase.firestore();
    storage = firebase.storage();
    isInitialized = true;
  }

  return firebase;
}

export const getFirestore = () => {
  return firestore;
}

export const getStorage = () => {
  return storage;
}

export const getBearerToken = async () => {
  // if(!token){
    return await firebase.auth().currentUser.getIdToken(true);
  // }

  // return token;
};

export const getFirestoreId = (collectionPath) => {
  const newDocRef = firestore.collection(collectionPath).doc();
  return newDocRef.id;
}

// export const clearBearerToken = () => {
//   token = null;
// };

//Reloads the current user so that any new claims are updated
export const reloadUser = () => {
  return firebase.auth().currentUser.reload();
};

export const sendPasswordReset = (email) => {
  if(email.indexOf("@test.com") >= 0){
    console.log("FORGOT PASSWORD: would send email, but this is a test account.", email);
    return;
  }
  firebase.auth().sendPasswordResetEmail(email);
}

//TODO: Convert this into a Component that wraps the app...
// Or a HOC...

//Export a watcher function to monitor when the state changes
export const watchFirebaseAuth = (getState, onSignedIn, onSignedUp, onSignedOut, onSignInError) => {
  if(!watcher){
    watcher   = firebase.auth().onAuthStateChanged(async (user) => {
        if(user){
          //logged in
          trackEvent(Events.log_in);
          TrackUser(user);
          const pKey      = user.email || user.uid;     //not all providers have an email address?
          const profile   = await getProfile(pKey);

          if(!user || !profile){
            onSignedUp(user);
            //TODO: change this to be the sign up flow - since we have a user, but no profile yet.

            // onSignInError({message: "Sign In Failed: Profile failed to load."});
            // onSignedOut();  //make sure they're not partially signed in.
          }
          else{
            onSignedIn(user, profile);          
          }
        }
        else{
          const isSignedIn  = Boolean(getState().app.user);
          if(isSignedIn){
            trackEvent(Events.log_out);
            TrackUser(null);            
          }
          
          // clearBearerToken();
          onSignedOut();          
        }
    });
  }

  return watcher;
}

//Export the configured firebase app by default
export default firebase;

//---------------------
// Gets a user's profile from their UID
//
export async function getProfile(userId){

  try{
    const db    = getFirestore();
    let docRef  = db.collection("profiles").doc(userId);
    const result      = await docRef.get();

    if(result.exists){
      const data  = {id: result.id, ...result.data()};

      //get the settings sub-collection, if it exists
      data.settings = {};
      try{
        const settingsRef = docRef.collection("settings");
        const settings = await settingsRef.get();
        data.settings = settings.docs.reduce((acc, doc) => {
          acc[doc.id] = doc.data();
          return acc;
        }, {});
      }
      catch(ex){
        console.warn("Failed to load settings", ex);
      }

      return data;
    }
  }
  catch(ex){
    console.error("Failed to load profile", ex);
  }

  return null;
}