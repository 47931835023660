
import { FieldCondition, FormField, FormRegion, FormSection } from "types";

//=== CONDITIONS ====//
//will be in the format: key:operator:value
//e.g. age:>=:18
// or isWorking:=:1
// or salSched:=:[1,2]
// can also have multiple conditions in one string, separated by || or &&
// e.g. age:>=:18||isWorking:=:1
// e.g. age:>=:18&&isWorking:=:1

export const isMultiCondition = (condString: string): boolean => {
  return condString.indexOf("||") > -1 || condString.indexOf("&&") > -1;
};

export const getMultiType = (condString: string): string => {
  return condString.indexOf("||") > -1 ? "||" : "&&";
}

export const splitMultiConditions = (condString: string): string[] => {
  const type = getMultiType(condString);
  return condString.split(type).map(c => c.trim());
}

//=== Parses a single condition string into a FieldCondition object
export const parseCondition = (condString: string): FieldCondition => {
  if(!condString) throw new Error("Invalid Argument: condition is missing");
  else if(condString.indexOf("||") > -1 || condString.indexOf("&&") > -1) throw new Error("Invalid Argument: condition has multiple conditions");

  const parts = condString.split(":");
  if(parts.length !== 3) throw new Error("Invalid Argument: condition is not in the correct format");

  return {
    key: parts[0],
    operator: parts[1],
    value: parts[2]
  };
};

//=== Parses a single condition string into a FieldCondition object
export const parseMultiCondition = (condString: string): FieldCondition[] => {
  if(!condString) throw new Error("Invalid Argument: condition is missing");
  else if(!isMultiCondition(condString)) return [parseCondition(condString)];
  else if(condString.indexOf("||") > -1 && condString.indexOf("&&") > -1) throw new Error("Invalid Argument: condition has both || and &&");

  const joiner = getMultiType(condString);
  const parts = condString.split(joiner);

  return parts.map(p => parseCondition(p));
};


//=== SCHEMA ===//
export const getRegionFields = (region: FormRegion, allFields: FormField[]): FormField[] | null => {
  if(!region || !allFields) return null;
    
    //Gets all the fields from the sections, including any header fields
    const fieldIds = [
      ...region.sections.flatMap(s => s.fields),    //fields for the sections
      ...region.sections.map(s => s.headerField).filter(f => f),  //header fields
    ];

    //Get fields that have a children property and grab those as well
    const childFieldIds = allFields.filter(f => f.children).flatMap(f => f.children);
    fieldIds.push(...childFieldIds);

    const fields = fieldIds.includes("*") ? allFields : allFields.filter(f => fieldIds.includes(f.id));
    return fields;
};

///===
/// This will take a short-cut multi field and generate fields for the children so they don't have to all be defined individually
/// in the config.
export const expandMultiFields = (allFields: FormField[]): FormField[] => {
  //Find any fields that have the multi flag.
  const multiFields = allFields.filter(f => f.multi === true);
  if(multiFields.length === 0) return allFields;

  //for each multi field, create a new field for each child field
  const generatedFields: FormField[] = [];
  multiFields.forEach(mf => {
    if(!mf.children) return;

    //each child field will inherit the parent field properties, except for a few that aren't applicable
    const { id, label, children, condition, fields = {}, multi, ...childProps } = mf;
    mf.children.forEach(childId => {
      //Make sure there isn't already a field with this id
      const existing = allFields.find(f => f.id === childId);
      if(!existing){
        const overrides = fields[childId] ?? {};  //the .fields property may contain overrides for this chlid field
        const newField = { id: childId, ...childProps, ...overrides } as FormField;
        generatedFields.push(newField);
      }
    });
  });

  return [...allFields, ...generatedFields];
}

export const getSectionFields = (section: FormSection, allFields: FormField[], regionId?: string): FormField[] | null => {
  if(!section) return null;
  if(!section.fields) return [];
  if(section.fields === "*") return allFields;
  const sectionId = section.id;

  let sectionFields = allFields
      .filter(f => section.fields.includes(f.id))
      //for any fields with child fields add the child fields to the field
      .map(f => !f.children ? f : { ...f, childFields: f.children.map<FormField >(id => ({...allFields.find(f => f.id === id), regionId, sectionId }) as FormField) });

  //filter out so we only have actual fields, and add the sectionId (to the top level fields)
  sectionFields = sectionFields.filter(f => !!f).map(f => ({ ...f, regionId, sectionId }));
  return sectionFields;
};