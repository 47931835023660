import { SxProps } from "@mui/material";
import { Theme } from "@mui/system";


export const tableStyles = {
  table: { fontSize: "1.1rem" },
  headerRow: { backgroundColor: "grey.200", borderBottom: "1px solid", borderBottomColor: "gray.300" },
  headerCell: { fontSize: "1rem", paddingY: 0.5, fontWeight: "500", color: "grey.700" },
  row: { },
  cell: { fontSize: "1rem", paddingY: 1, fontWeight: "500", color: "grey.800" },
  noLastRowBorder: { "&:last-child td": { borderBottom: "none" } },
} as const;

export const buttonStyles = {
  action: { size: "small", color: "secondary",  variant: "text", sx: { fontSize: "1rem", fontWeight: "500", textTransform: "none", py: 1 }},
  toolbarSx: { py: 0.5, },
} as const;

export const cardStyles = {
  primary: { variant: "outlined", elevation: 0, sx: { minHeight: 500, width: "100%", mb: 2 } },
  primaryX: (sx: SxProps<Theme> = {}) => ({ variant: "outlined", sx: { width: "100%", p: 2, mb: 2, bgColor: "white", ...sx } }) as any,
} as const;

export const paperStyles = {
  card: { variant: "outlined", sx: { minHeight: 500, width: "100%", borderRadius: 2 } },
  cardX: (sx: SxProps<Theme> = {}) => ({ variant: "outlined", sx: { width: "100%", borderRadius: 2, p: 2, bgColor: "white", ...sx } }),
} as const;

export const iconStyles = {
  avatarLarge: { sx: { borderWidth: 0.5, borderStyle: "solid", borderColor: "grey.300", borderRadius: "50%", height: 90, width: 90, lineHeight: 90, display: "block", marginBottom: 2 } },  
} as const;

export const textStyles = {
  title: { sx: { fontSize: "1.75rem", fontWeight: "500", color: "primary.main" }},
  subTitle: { sx: { fontSize: "1.2rem", fontWeight: "400", color: "grey.600" }},
  subTitleX: (sx: SxProps<Theme> = {}, props: any = {}) => ({ sx: { fontSize: "1.2rem", fontWeight: "400", color: "grey.600", ...sx }, ...props }) as any,
  summarySectionLabel: { color: "secondary", sx: { fontWeight: "600", fontSize: "1.1rem", mb: 1 }},
  summaryFieldLabelX: (sx: SxProps<Theme> = {}) => ({ color: "grey.800", sx: { fontSize: "1.05rem", fontWeight: "300", mb: 0.5, ...sx }}) as any,
  summaryFieldValueX: (sx: SxProps<Theme> = {}) => ({ sx: { fontSize: "1rem", fontWeight: "400", mb: 0.5, textAlign: "end", ...sx }}) as any,
} as const;

export const boxStyles = {
  visibleMobile: { display: { sm: "none", xs: "unset" }},
  hiddenMobile: { display: { sm: "unset", xs: "none" }},
} as const;