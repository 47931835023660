import { ClientDocument, FormAssignment } from "./form-types";
import { ClientDateSchema } from "./schema";

export type ProfileInfo = {
  id: string;
  displayName?: string;
  email: string;
  photoURL?: string;
}

export type Share = {
  id: string;
  accountId: string;
  reviewer: string;
  sharer?: string;
  
  reviewerInfo?: ProfileInfo;
  
  requestId: string;    //TODO: can we get rid of the ShareRequest
  invitedAt?: number;
  viewedAt?: number;
  rejectedAt?: number;

  sharedAt?: string;
  forms?: FormAssignment[];
  dates?: ClientDateSchema[];
  documents?: ClientDocument[];

  createdAt: number;    //missing from old shares
}

export type ShareRequest = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  invitationId?: string;
  invitedAt: string;
  message: string;
  requestType: string;
  senderName: string;
  senderEmail: string;
  senderType: string;
  status: number;
  senderId: string;
  share?: Share;
  viewedAt?: string;
}

export enum ShareStatus {
  NOT_INVITED = 0,
  INVITED = 50,
  VIEWED = 75,
  ACCEPTED = 100,
  REJECTED = 999,
}