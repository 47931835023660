import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AccountCircle, AccountCircleOutlined, AssignmentTurnedInOutlined, Notes, SpeakerNotesOutlined, Work, WorkOutline, WorkOutlined } from "@mui/icons-material";
import { Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useMeasure } from "react-use";
import { FormInfo } from "types";
import ClientFormProgress from "../chips/client-form-progress";
import { formatShortDate } from "helpers/general-helpers";
import { AssignedForm } from "../../../../types/client-document-types";
import { useClient } from "../../client-context";
import { selectCurrentClientFormInfo } from "store/selectors/attorney-selectors";

export interface IClientFormItemProps {
  form: AssignedForm;
  onChecked: (e: any) => void;
  onSelected: (e: any) => void;
  onHover: (isEntering: boolean) => (e: any) => void;
  onOpen: (e: any) => void;
  canOpen: boolean;
}

const ClientFormItem = ({ form, onChecked, onSelected, onHover, onOpen, canOpen }: IClientFormItemProps) => {
  const { clientId } = useClient();
  const formInfo = useSelector<any, FormInfo | undefined>(state => selectCurrentClientFormInfo(state, form.id));
  const [cellRef, { width: cellWidth } ] = useMeasure();
  const theme = useTheme();
  // const ttip = canOpen ? "View the client's form" : "You can view the client's form after they accept the invitation";
  const nameProps = canOpen ? {component: Link, to: `/app/clients/${clientId}/forms/${form.id}`} : { component: "span" };

  return (
    <TableRow key={form.id} sx={{backgroundColor: (form.isSelected ? alpha(theme.palette.primary.light, 0.25) : undefined)}} >
      <TableCell width={36} sx={{ px: 0 }}>
        <Checkbox value={form.isChecked} onChange={onChecked} />
      </TableCell>
      <TableCell width={36} sx={{ px: 0 }}>
        <Grid container justifyContent="center" alignItems="center">
          <Tooltip title="Fillable Form">
            <AssignmentTurnedInOutlined fontSize="small" color="primary" />
          </Tooltip>
        </Grid>
      </TableCell>
      <TableCell onClick={onSelected} ref={cellRef} sx={{ maxWidth: "28rem" }}>
        <Grid container alignItems="center">
          <Typography {...nameProps}  sx={{ width: cellWidth - 48, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{form.formName}</Typography>            
        </Grid>
      </TableCell>
      <TableCell width={36} sx={{ px: 0 }} align="center">
        <Tooltip title={form?.isClientForm === false ? "Attorney Form" : "Client Form"}>
          <Box>
            {form?.isClientForm === false && <WorkOutline fontSize="small" sx={{ color: "grey.500" }} /> }
            {form?.isClientForm !== false && <AccountCircleOutlined fontSize="small" sx={{ color: "grey.500" }} /> }
          </Box>
        </Tooltip>
      </TableCell>
      <TableCell onClick={onSelected}>
        <ClientFormProgress formInfo={formInfo}/>
      </TableCell>
      <TableCell onClick={onSelected}>{form.dueAt ? formatShortDate(form.dueAt) : (form as any).dueOn}</TableCell>
      <TableCell onClick={onSelected}>
        {/* {form.notes && (
          <>
            <IconButton size="small" color="secondary" onMouseEnter={onHover(true)} onMouseLeave={onHover(false)}>
              <Notes fontSize="small" />
            </IconButton>                            
          </>
        )}                       */}
      </TableCell>
    </TableRow>
  );
};

export default ClientFormItem;