import { getApp } from "@firebase/app";
import { getProducts, getStripePayments, getCurrentUserSubscriptions, onCurrentUserSubscriptionUpdate, createCheckoutSession } from "@stripe/firestore-stripe-payments";

//TODO: design and configure to work in either production or development mode.

const paymentsConfig = {
  productsCollection: "stripe_products",
  customersCollection: "stripe_customers",
};
let paymentsApi = null;
let paymentsListener = null;

//TODO: pull the price id from the .env / .env.development so we can switch
// between development and production pricing.
const STRIPE_PRICE = process.env.REACT_APP_STRIPE_PRICE_ID;   //price_1KahONDRHPnQncXm1Emfi58r in dev

export const LicenseInfo = {
  "price_free": {
    displayName: "Free Edition",
    description: "Full access to use FormHop to complete your Sworn Financials, unlimited changes and edits of your information, you own your data and can download it at any time"
  },
  [STRIPE_PRICE]: {
    displayName: "Premium Edition",
    description: "All the features of the Free version, plus unlimited exports of the necessary legal forms pre-filled with your data**",
    months: 6,
    isLifetime: false
  },
  "price_lawyer": {
    displayName: "Family Practice Edition",
    description: "This edition is for Family Practice Lawyers who want to improve the experience of their clients by offering FormHop to all thier clients."
  }
};

export const BasicPrice = {
    metadata: {},
    description: "Free Edition",
    recurring: null,
    tax_behavior: "unspecified",
    billing_scheme: "per_unit",
    active: true,
    unit_amount: 0,
    tiers_mode: null,
    trial_period_days: null,
    type: "one_time",
    currency: "usd",
    transform_quantity: null,
    interval: null,
    interval_count: null,
    product: "prod_LHFuooXlBCAAKw",
    tiers: null,
    id: "price_free"
};

export const LawyerPrice = {
  metadata: {},
  description: "Family Practice Edition",
  recurring: null,
  tax_behavior: "unspecified",
  billing_scheme: "per_unit",
  active: true,
  unit_amount: -1,
  tiers_mode: null,
  trial_period_days: null,
  type: "monthly",
  currency: "usd",
  transform_quantity: null,
  interval: null,
  interval_count: null,
  product: "prod_LHFuooXlBCAAKw",
  tiers: null,
  id: "price_lawyer"
};

//Initialize the payments functionality
export const initializePayments = () => {
  if(!paymentsApi){
    const firebase = getApp();
    paymentsApi = getStripePayments(firebase, paymentsConfig);
    console.log("Stripe Payments initialized.");
  }
}

export const getPaymentProducts = async () => {
  initializePayments();

  const products = await getProducts(paymentsApi, {
    includePrices: true,
    activeOnly: true,
  });

  const withInfo = products.map(prd => {
    const prices = [BasicPrice, ...prd.prices, LawyerPrice];

    const withLicense = prices.map(pri => {
      const info = LicenseInfo[pri.id];
      return {...pri, info};
    });

    return {...prd, prices: withLicense};
  });

  return withInfo;
}

export const getPaymentSubscription = async () => {
  initializePayments();

  const subscriptions = await getCurrentUserSubscriptions(paymentsApi);
  return subscriptions;
}

export const purchaseSubscription = async (priceId) => {
  // const price = priceId === 1 ? "price_1KahONDRHPnQncXm1Emfi58r" : "";

  try{
    const session = await createCheckoutSession(paymentsApi, {
      mode: "payment",
      price: priceId,    //TODO: Price for the product in question
      success_url: window.location.href,
      cancel_url: window.location.href,
    });
    window.location.assign(session.url);
    return session;
  }
  catch(error){
    console.error("Error creating payment session.", error);
    return Promise.reject(error);
  }
}

export const registerPaymentsListener = (onChange) => {
  if(paymentsListener) throw new Error("Listener already registered.");

  paymentsListener = onCurrentUserSubscriptionUpdate(paymentsApi, (snapshot) => {
    console.log("payment listener fired");
    for (const change in snapshot.changes) {
      // if (change.type === "added") {
      //   console.log(`New subscription added with ID: ${change.subscription.id}`);
      // }
      console.log("subscription changed: ", change);
    }
  },
  (error) => {
    console.error("Error registering payments listener", error);
  });
  
  console.log("payment listener registered");
}

export const unregisterPaymentsListener = () => {
  if(paymentsListener) paymentsListener();
}