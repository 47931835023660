import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import { StatusKeys } from 'helpers';
import { WaitButton, ErrorAlert } from 'components';
import { useStatus } from 'redux-action-status';
import { inviteClient } from 'store/actions/attorney-actions';
import { CheckCircle } from '@mui/icons-material';
import { useAttorneyContext } from './attorney-context';

export interface IInviteDialogProps {
  isOpen: boolean;
  isCopyOnly?: boolean;
  onClose: (success?: boolean) => void;
  waitlistItem?: any;
  clientProfile?: any;
}

const getSignupUrl = () => {
  const myUrl = window.location.origin;
  return myUrl.indexOf("formhop-prod.web.app") >= 0 ? `https://app.formhop.com/welcome/signup` : `${myUrl}/welcome/signup`;
}

const InviteClientDialog = ({isOpen, isCopyOnly, onClose, clientProfile}: IInviteDialogProps) => {
  const dispatch  = useDispatch();
  const { clientId } = useParams();
  const { account } = useAttorneyContext();
  const [props, setProps]   = useState({firstName: clientProfile.firstName, lastName: clientProfile.lastName, email: clientProfile.email, message: ""});
  const status    = useStatus(StatusKeys.clients);
  const [isSent, setSent] = useState(!!isCopyOnly);    //TODO: Testing - switch to true
  const [copied, setCopied] = useState(false);
  const canSave   = props.firstName.length > 0 && props.email.length > 0;
  const link = getSignupUrl(); //window.location.origin.indexOf("formhop-prod.web.app") `${window.location.origin}/welcome/signup`;

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const onInvite = async () => {
    let model: any   = {
      ...props,
      requestType : "client",   
    };

    console.log("Sending invite", model);
    if(!clientId) { console.error("no client id"); return; }
    const result = await dispatch(inviteClient(clientId, model));
    console.log("Invite result", result);

    if((result as any).isOk){
      setSent(true);    //toggle to the copy view
    }
  }

  const handleCopy = () => {
    console.log("handle copy");
    const content = `${account!.name} would like to invite you to create an account on FormHop, a tool that you can easily use to fill out intake forms and collaborate with us about your matters.${props.message ? `\n\n${props.message}` : ""}\n\nPlease use the email address ${props.email} when creating your account.\n\nClick here to begin: ${link}`;
    navigator.clipboard.writeText(content);//(content);
    setCopied(true);
  } 

  const onDialogClose = () => {
    if(!status.isWorking) onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onDialogClose} maxWidth="sm" fullWidth>
      <DialogTitle>Invite client</DialogTitle>

      <DialogContent>
        {!isSent && (
          <Grid container spacing={2} sx={{ pt: 1 }}>
            {status.error && (
              <Grid item xs={12}>
                <ErrorAlert error={status.error} title="Invitation failed" />          
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField id="firstName" value={props.firstName} onChange={handleChange} variant="outlined" fullWidth label="First name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="lastName" value={props.lastName} onChange={handleChange} variant="outlined" fullWidth label="Last name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="email" value={props.email} type="email" onChange={handleChange} variant="outlined" fullWidth label="Email address *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="message" value={props.message} onChange={handleChange} variant="outlined" fullWidth multiline rows={3} label="Optional message" />
            </Grid>
          </Grid>
        )}
        {isSent && (
          <Stack rowGap={1}>
            <Typography fontStyle="italic" color="grey.600">Please copy the invitation below and email it to your client.</Typography>
            <Stack sx={{ border: "1px solid", borderColor: "grey.400", borderRadius: 1, padding: 1, bgcolor: "grey.200" }} rowGap={1}>
              <Typography>{account!.name} would like to invite you to create an account on FormHop, a tool that you can easily use to fill out intake forms and collaborate with us about your matters.</Typography>
              <Typography>Please use the email address <strong>{props.email}</strong> when creating your account.</Typography>
              <Typography>Click here to begin: {link}.</Typography>
            </Stack>
            {copied && (
              <Box mt={2}>
                <Typography color="green" fontWeight="600" display="flex" alignItems="center"><CheckCircle fontSize="small" color="success" sx={{ mr: 1}} /> Successfully copied to clipboard</Typography>
              </Box>
            )}
          </Stack>
        )}
      </DialogContent>
      
      <DialogActions>
        {!isSent && <WaitButton color="primary" size="small" isWaiting={status.isWorking} disabled={!canSave || status.isWorking} onClick={onInvite}>Create Invitation</WaitButton>}
        {isSent && <Button color="primary" size="small" disabled={!canSave || status.isWorking} onClick={handleCopy}>Copy Invitation</Button>}
        <Button size="small" onClick={() => onClose(false)} disabled={status.isWorking}>{isSent ? "Close" : "Cancel"}</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteClientDialog;