import { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { inputFieldSizes, inputFieldTypes } from "./part-utils";
import { IFormFieldInputProps } from "./part-types";

const FormTextField = ({ field, initialValue, onChanged, dataProps } : IFormFieldInputProps ) => {
  const [val, setVal] = useState(initialValue ?? "");
  
  const classNames = `field-${field.size ? field.size : "sm"} field-${field.type}`;
  const width = inputFieldSizes[field.size ?? "sm"];
  const sx = inputFieldTypes[field.type ?? "text"];
  const multiline = (field.rows && field.rows > 1) ? { multiline: true, rows: field.rows } : {};
  
  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  const handleChange = (e: any) => {
    if(onChanged){ // && val !== initialValue){
      onChanged(val);
    }
  };

  return (
    <TextField 
      onChange={(e) => setVal(e.target.value)}
      onBlur={handleChange}
      id={field.id}
      className={classNames} 
      value={val} 
      size="small"
      {...multiline}    //if this is a multi-row field, add the multiline props
      {...dataProps}  
      placeholder={field.label} 
      autoComplete="off" 
      inputProps={{ 
        style: { 
          fontSize: "14px",
          padding: "6px 12px", 
        } 
      }}
      sx={{
        // width: "100%",
        width: width,
        ...sx,
      }}
    />
  );

};

export default FormTextField;
