import { createSelector } from "reselect";
import { FormConfiguration, FormInfo, Share, ShareRequest } from "types";

type SelectorState = {
  attorney: {
    clientData: {
      forms: FormInfo[];
    };
  };
  share: {
    requests: ShareRequest[] | null;
    invitations: ShareRequest[] | null;
    shares: Share[] | null;
    formConfigs: Record<string, FormConfiguration>;
  };
  values: {
    forms: FormInfo[];
  };
}

const _getShares = (state: SelectorState) => state.share.shares;
const _getInviteRequests  = (state: SelectorState) => state.share.requests;
const _inItemId           = (state: SelectorState, itemId: any) => itemId;

export const selectInvites = createSelector(
  [_getInviteRequests, _getShares],
  (invites, shares) => {
    if(!invites) return null;
    if(!shares) return invites;

    const merged  = invites.map(inv => {
      const searchString = `${inv.email} ${inv.firstName}${inv.lastName}`.toLowerCase();
      const share   = shares.find(share => share.requestId === inv.id);
      return {
        ...inv,
        ...(share ? { share } : {}),
        searchString  : searchString,
      };      
    });

    return merged;
  }
);

export const selectRequest = createSelector(
  [_getInviteRequests, _inItemId],
  (requests, requestId) => {
    if(!requests || !requestId) return null;
    return requests.find(req => req.id === requestId);    
  }
);

//== Selects the share for a specific client. Used by attorney to get the share for a client.
export const selectShareBySharer = createSelector(
  [_getShares, _inItemId],
  (shares, sharerUid) => {
    if(!shares || !sharerUid) return null;
    return shares.find(share => share.sharer === sharerUid);    
  }
);

export const selectShareById = createSelector(
  [_getShares, _inItemId],
  (shares, shareId) => {
    if(!shares || !shareId) return null;
    return shares.find(share => share.id === shareId);    
  }
);

//=== Selectors for Forms, which could be for the provider or the client
const _getAttornyFormsInfo = (state: SelectorState) => state.attorney.clientData?.forms as FormInfo[];
const _getClientFormsInfo = (state: SelectorState) => state.values.forms;

export const selectFormInfo = createSelector(
  [_getAttornyFormsInfo, _getClientFormsInfo, _inItemId],
  (attorneyForms, clientForms, formId) => {
    if(!formId || (!attorneyForms && !clientForms)) return null;
    return attorneyForms ? attorneyForms.find(f => f.id === formId) : clientForms[formId] ?? null;
  }
);

//=== Selector for form configuration
const _getFormConfigs = (state: SelectorState) => state.share.formConfigs;

export const selectFormConfig = createSelector(
  [_getFormConfigs, _inItemId],
  (configs, formId) => {
    return configs[formId];
  }
);

export const selectFormRegions = createSelector(
  [_getFormConfigs, _inItemId],
  (configs, formId) => {
    const formConfig = configs[formId];
    if(!formConfig) return [];

    const regions = formConfig.schema.layout.regions;
    const regionArray = Object.keys(regions).map(key => regions[key]);
    return regionArray;
  }
)