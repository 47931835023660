
// Setting Keys for the User Profile
const SettingKeys = {
  app: {
    notes: {
      isAdding: "app.notes.isAdding"
    },
    dates: {
      filters: "app.dates.filters", //sort is part of filters
      // sort: "app.dates.sort",
    },
    documents: {
      dismissedAlerts: "app.documents.dismissedAlerts",
    },
    layout: {
      sidebarCollapsed: "app.layout.sidebarCollapsed",
    },
    cloudSync: "app.cloudSync",
  },
};

//Default Values for the settings in the User Profile
export const SettingDefaults = {
  [SettingKeys.app.notes.isAdding]: true,
};

export const settingCategory = (key: string) => {
  return key.split(".")[0];
}

export default SettingKeys;