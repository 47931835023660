import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { selectPetitionerDocs, selectPetitionerForms, selectPetitionerUploadRequests } from "store/selectors/petitioner-selectors";
import { AssignedDocument } from "types/client-document-types";
import FormCard from "./petitioner-form-item";
import DocumentCard from "./petitioner-doc-item";
import RequestCard from "./petitioner-request-item";

export interface IPetitionerTasksListProps {
  filter?: "all" | "incomplete" | "complete"; //"forms" | "documents" | 
}

const PetitionerTasksList = ({ filter = "all" }: IPetitionerTasksListProps) => {
  const theme = useTheme();
  const forms = useSelector(selectPetitionerForms);
  const documents = useSelector<any, AssignedDocument[]>(selectPetitionerDocs);
  const requests = useSelector<any, AssignedDocument[]>(selectPetitionerUploadRequests);
  // const statuses = useSelector<any, FormInfo[]>(state => state.values.forms);
  
  const filteredForms = useMemo(() => {
    if(filter === "incomplete") return forms.filter(f => !f.status?.isSubmitted);
    else if(filter === "complete") return forms.filter(f => f.status?.isSubmitted);
    return forms;
  }, [forms, filter]);

  const filteredDocs = useMemo(() => {
    if(filter === "incomplete") return documents.filter(d => !d.submittedAt);
    else if(filter === "complete") return documents.filter(d => d.submittedAt);
    return documents;  
  }, [documents, filter]);

  const filteredRequests = useMemo(() => {
    if(filter === "incomplete") return requests.filter(d => !d.filePath);
    else if(filter === "complete") return requests.filter(d => !!d.filePath);
    return requests;  
  }, [requests, filter]);
  
  return (
    <Grid id="petitioner-form-list" container spacing={4} sx={{ width: "100%"}}>

      <Grid xs={8} xsOffset={2} container mt={2} mb={1}>
        <Grid container width="100%" justifyContent="center">
          <Typography variant="body1" color="grey.600" textAlign="center">{messageMap[filter]}</Typography>
        </Grid>
      </Grid>

      {filteredForms?.map((form, index) => (
        <Grid xs={12} sm={6} md={4} xl={3} key={index}>
          <FormCard form={form} theme={theme}/>
        </Grid>
      ))}

      {filteredRequests?.map((doc, index) => (
        <Grid xs={12} sm={6} md={4} xl={3} key={index}>
          <RequestCard key={index} document={doc} theme={theme} />
        </Grid>
      ))}

      {filteredDocs?.map((doc, index) => (
        <Grid xs={12} sm={6} md={4} xl={3} key={index}>
          <DocumentCard key={index} document={doc} theme={theme} />
        </Grid>
      ))}

    </Grid>
  );
};

export default PetitionerTasksList;

const messageMap = {
  all: "Below are the tasks you're attorney has asked you to complete",
  incomplete: "These documents from your attorney need your attention",
  complete: "Yaay, you've finished these tasks!",
};