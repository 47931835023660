import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareIcon from '@mui/icons-material/Share';
import PeopleIcon from '@mui/icons-material/People';
import { IfBlock, ErrorAlert, WaitContainer, InviteDialog, ErrorStatus } from 'components';
import SharingRequestItem from './parts/sharing-request-item';
import MySharesList from './parts/my-shares-list';
import { loadReceivedRequests, loadShares, updateReceivedInvite } from 'store/actions/share-actions';
import { getNowString } from 'store/actions/action-helpers';
import { isNotViewed } from 'config/share-config';
import { useStatusMulti } from 'redux-action-status';

//TODO: OBSOLETE

const MySharing = () => {
  const classes     = buildStyles();
  const status      = useStatusMulti(["requests", "shares"]);
  const invites     = useSelector(state => state.share.invitations);
  const shares      = useSelector(state => state.share.shares);
  const [props, setProps]     = useState({isInviting: false, isRefresh: false});
  const dispatch    = useDispatch();
  
  //Load the data...
  useEffect(() => {
    async function init(){
      const isForced  = props.isRefresh;
      setProps({...props, isRefresh: false})
      if(isForced || !invites) await dispatch(loadReceivedRequests());
      if(isForced || !shares) await dispatch(loadShares());
    };
    if(props.isRefresh || !invites || !shares) init();   //should have current items already, so only re-load by request
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isRefresh]);

  //Also, mark these invites as viewed
  useEffect(() => {
    async function viewed(){
      const notViewed   = _.filter(invites, isNotViewed);
      notViewed.forEach(async (invite) => {
        await dispatch(updateReceivedInvite(invite.id, {viewedAt: getNowString()}));
      });
    }
    if(invites && _.some(invites, i => !i.viewedAt)) viewed();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invites]);

  

  const pending     = _.filter(invites, i => !!i.shareId && (!i.status || i.status === 0));
  const history     = _.filter(invites, i => i.status !== 0);
  const hasPending  = Boolean(pending.length > 0);
  const hasHistory  = Boolean(history.length > 0);

  return (
        <Grid id="invite-list" container className={classes.inviteList}>
          
          <Grid item container>
            <Grid item sm={9} container alignContent="flex-start" direction="column">
              <Grid item><Typography className={classes.title}>Your Sharing</Typography></Grid>
              <Grid item><Typography className={classes.subTitle}>Decide who to collaborate with</Typography></Grid>                 
            </Grid>
            <Grid item sm={3} container alignItems="center" justifyContent="center">
              <div className={classes.avatarWrapper}>
                <div className={classes.avatarWrapperInner}>
                  <PeopleIcon className={classes.avatar} color="primary"/>
                </div>
              </div>
            </Grid>             
          </Grid>


          {/* <Grid item xs={12} container>
            <Grid item xs={4} container alignItems="center" justifyContent="flex-end">
              <Button size="small" color="secondary" variant="contained" className={classes.shareButton} onClick={() => setProps({...props, isInviting: true})}>Share with your lawyer</Button>
              <IconButton size="small" color="secondary" title="Refresh the share list" className={classes.shareButton} onClick={() => setProps({...props, isRefresh: true})}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid> */}

        <Grid item container className={classes.mainContent}>

        {/* <InviteDialog type="lawyer" isOpen={props.isInviting} onClose={() => setProps({isInviting: false})}/> */}

          <Grid item container justifyContent="center" mb={2}>
            {/* <ErrorAlert error={status.error} title="Failed to load share list" /> */}
            <ErrorStatus statusKey="shares" title="An error with the share request" sx={{ width: "60%" }} />
            <ErrorStatus statusKey="requests" title="An error with the loading your sharing information" sx={{ width: "60%" }} />
          </Grid>

          <IfBlock condition={!hasPending && !hasHistory}>
            <Grid item xs={12} container direction="column" justifyContent="flex-start" alignItems="flex-start" spacing={2} style={{padding: "32px",}}>
              <Typography className={classes.emptyText}>You do not have any share requests at this time.</Typography>
              <Typography className={classes.emptyText}>If you'd like to share your data with your Lawyer, click the button above.</Typography>
            </Grid>
          </IfBlock>

          <IfBlock condition={hasPending}>
            <Card id="sharing-card" elevation={0} variant="outlined" className={classes.card}>
              <CardContent className={classes.cardContent}>
                <Grid container className={classes.cardGrid}>
                  
                  <Grid item container className={classes.pendingRow}>
                    <WaitContainer isWaiting={status.isWorking}>
                      {_.map(pending, p => <SharingRequestItem key={p.id} invite={p} />)}
                    </WaitContainer>
                  </Grid>

                </Grid>
              </CardContent>
            </Card>
          </IfBlock>

          <MySharesList invites={invites} shares={shares} isWorking={status.isWorking} />

          <Grid item container justifyContent="flex-end">
            
            <Button size="small" title="Refresh the share list" endIcon={<RefreshIcon fontSize="small" />} className={classes.shareButton} onClick={() => setProps({...props, isRefresh: true})} >Refresh</Button>
            <Button size="small" color="secondary" variant="contained" endIcon={<ShareIcon fontSize="small" />} className={classes.shareButton} onClick={() => setProps({...props, isInviting: true})}>Share with your lawyer</Button>
          </Grid>  

        </Grid>

      </Grid>
  );
}

export default MySharing;

const buildStyles   = makeStyles(theme => ({
  inviteList  : {
    padding     : theme.spacing(1),
  },
  mainContent  : {
    padding     : `${theme.spacing(1)} 0`, //`
    // width       : "90%",
  },
  avatarWrapper      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "90px",
    width         : "90px",
    lineHeight    : "90px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
    marginBottom  : theme.spacing(2),
    padding: theme.spacing(1),
  },
  avatarWrapperInner: {
    backgroundColor: theme.palette.primary.main,
    borderRadius  : "50%",
    height        : "74px",
    width         : "74px",
    lineHeight    : "74px",
    display       : "flex",
    alignItems    : "center",
    justifyContent: "center",
  },
  avatar: {
    color: "#f4f4f4",
    height: 48,
    width: 48,
  },
  title   : {
    fontSize    : 25,
    fontWeight  : 500,
    // textAlign   : "center",
    marginBottom: theme.spacing(1),
    color       : theme.palette.primary.main,
  },
  subTitle  : {
    fontSize      : 18,
    fontWeight    : 400,
    // textAlign     : "center",
    marginBottom  : theme.spacing(1),
    color         : theme.palette.grey[600],
  },
  // title   : {
  //   fontSize    : 22,
  //   fontWeight  : 500,
  //   marginBottom: theme.spacing(1),
  // },
  info  : {
    fontSize      : 15,
    fontWeight    : 400,
    color         : theme.palette.grey[500],
    marginBottom  : theme.spacing(2),
  },
  card  : {
    width       : "100%",
    marginRight : theme.spacing(2),
    // minHeight   : 300,
    marginBottom: theme.spacing(4),
  },
  cardContent   : {
    padding     : 0,
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  shareButton   : {
    fontSize        : 15,
    fontWeight      : 500, 
    textTransform   : "none",
    marginRight     : theme.spacing(2),   
  },
  emptyText     : {
    fontSize        : 17,
    fontWeight      : 500,
    fontStyle       : "italic",
    color           : theme.palette.grey[500],
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  pendingRow     : {
    minHeight       : 45,
    padding         : theme.spacing(2),
  },
  errorCard   : {
    width           : "100%",
    marginRight     : theme.spacing(2),
  },
  inviteTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
}));