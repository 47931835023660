import { createContext, useEffect, useState } from "react";
import { default as strings } from "../../config/localization.json";

export interface ILocalizationContext {
  location: string;
  setLocation: (location: string) => void;
  s: (key: string) => string;   //get a string
  sl: (key: string) => string;  //get a string as lowercase
  su: (key: string) => string;  //get a string as uppercase
  sc: (key: string) => string;  //get a string as capitalized
}

export const LocalizationContext = createContext<ILocalizationContext | null>(null);

const LocalizationProvider = ({ children }: { children: React.ReactNode }) => {
  const [location, setLocation] = useState<string>("en");
  const [locStrings, setLocStrings] = useState<Record<string, any>>(strings.en);

  useEffect(() => {
    const aStrings = strings as any;
    const loc = (aStrings as Record<string, Record<string, string>>)[location];
    if(!loc) {
      console.warn(`Localization not found for location: ${location}`);
      return;
    }

    setLocStrings(loc);
  }, [location]);

  const getString = (key: string) => {

    if(key.indexOf(".") > -1){
      const pathParts = key.split(".");
      const value = pathParts.reduce((acc, p) => {
        const nextPart = acc[p];
        if(!nextPart) {
          console.warn(`Missing localization path: ${key}`);
          return acc;
        }
        return nextPart;
      }, locStrings);

      return value;
    }

    const value = locStrings[key];
    if(!value) {
      console.warn(`Missing localization string for key: ${key}`);
      return key;
    }

    return value || "<missing>";
  }

  const s = (key: string) => getString(key);
  const sl = (key: string) => getString(key).toLowerCase();
  const su = (key: string) => getString(key).toUpperCase();
  const sc = (key: string) => getString(key).charAt(0).toUpperCase() + key.slice(1);

  const context = {
    location,
    setLocation,
    s,
    sl,
    su,
    sc,
  };

  return (
    <LocalizationContext.Provider value={context}>
      {children}
    </LocalizationContext.Provider>
  );
};

export default LocalizationProvider;