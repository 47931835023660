import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Header } from '../financials/layout';
import DefaultView from './my-default-view';
import ProfileView from './my-profile';
import DataView from './my-data';
import SharingView from './my-sharing';
import { initialize } from 'store/actions/app-actions';
import { LoadingContainer } from 'components';
import MyNav from './parts/my-nav';
import MySubscriptionView from "./my-subscription";
import MyConversations from './my-conversations/my-conversations';
import PetitionerProvider from '../petitioner/contexts/petitioner-context';

function MyView(){
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const isInitialized   = useSelector(state => state.app.isInitialized);
  const isAuthed        = useSelector(state => state.app.isAuthenticated);

  useEffect(() => {
    if(!isInitialized){
      async function init(){
        await dispatch(initialize());
      }
      init();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  return (
    <Grid container>
      <Grid item sm={12} container>
        <Header/>
      </Grid>
      <Grid item container className={classes.viewBody}>
        <Grid item>
          <MyNav/>
        </Grid>
        <Grid item container alignItems="flex-start" className={classes.contentWrapper}>
          {!isAuthed && <LoadingContainer isVisible={isInitialized} style={{width: "100%"}}/>}
          {isAuthed && 
            <Routes>
              <Route path="/*" exact element={<DefaultView />} />
              <Route path="profile" exact element={<ProfileView />} />
              <Route path="data" element={<DataView />} />
              <Route path="data/*" element={<DataView />} />
              <Route path="conversations" element={<MyConversations />} />
              <Route path="conversations/:shareId/:topicType/:topicId" element={<MyConversations />} />
              <Route path="conversations/:shareId/:topicId" element={<MyConversations />} />
              <Route path="sharing" element={<SharingView />} /> 
              <Route path="subscription" element={<MySubscriptionView />} />
            </Routes>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

const MyViewWrapper = () => {
  return (
    <PetitionerProvider>
      <MyView/>
    </PetitionerProvider>
  );
};

export default MyViewWrapper;

const buildStyles   = makeStyles(theme => ({
  viewBody  : {
    flexWrap    : "nowrap",
  },
  contentWrapper  : {
    position        : "relative",
    paddingTop      : theme.spacing(2),
    paddingLeft     : theme.spacing(2),
    height          : "calc(100vh - 60px)",
    overflowY       : "auto",
    overflowX       : "hidden",
  },
}));