import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleEditClientData, updateClientValues } from "store/actions/attorney-actions";
import { useFormValues } from "../financials/form-hooks";

export type IFormEditorContext = {
  isEditing: boolean;
  editRegionId: string | null;
  toggleEditing: (value?: boolean) => void;
  changes: Record<string, any>;
  fieldChanged: (valueKey: string, fieldId: string, rowIndex?: number) => (value: any) => void;
  addRow: (valueKey: string) => () => void;
  deleteRow: (valueKey: string, rowIndex: number) => () => void;
  saveChanges: (clientUid: string) => Promise<boolean>;
}

const emptyFieldChange = (valueKey: string, fieldId: string,  rowIndex?: number) => (value: any) => {
  //We should not reach this place, so throw a warning if we do
  console.warn("useFormEditor.onFieldChanged called when not within a FormEditorContext provider", { valueKey, fieldId, rowIndex, value });
};

export const FormEditorContext = createContext<IFormEditorContext | null>(null);

//=== Hooks ===
export const useFormEditor = () => {
  const context = useContext(FormEditorContext);
  if(!context) {
    //Don't want to throw an error here, as this is a common use case
    const empty: IFormEditorContext = { 
      isEditing: false,
      editRegionId: null,
      toggleEditing: () => {},
      changes: {},
      fieldChanged: emptyFieldChange,
      addRow: () => () => {},
      deleteRow: () => () => {},
      saveChanges: async () => false,
     };

     return empty;
  }

  return context;
};

export const useFieldChanged = (valueKey: string, fieldId: string, rowIndex?: number) => {
  const context = useContext(FormEditorContext);
  if(!context) {
    console.warn("useFieldChanged called when not within a FormEditorContext provider", { valueKey, fieldId, rowIndex });
    return emptyFieldChange(valueKey, fieldId, rowIndex);
  }

  return context.fieldChanged(valueKey, fieldId, rowIndex);
};

export const useEditorValue = (valueKey: string) => {
  const context = useContext(FormEditorContext);
  if(!context) {
    console.warn("useEditorValue called when not within a FormEditorContext provider", { valueKey });
    return undefined;
  }

  return context.changes[valueKey] ?? undefined;
};

//=== Provider ===
export interface IFormEditorProviderProps {
  regionId: string;
  children: React.ReactNode;
}

const FormEditorProvider = ({ children, regionId }: IFormEditorProviderProps) => {
  const dispatch = useDispatch();
  const storeValues = useFormValues();

  const [changes, setChanges] = useState<Record<string, any>>({});
  const editRegionId = useSelector<any, string | null>(state => state.attorney.editRegionId);
  const isEditing = useMemo(() => editRegionId === regionId, [editRegionId, regionId]);
  
  ///<summary>
  /// Toggle the editing state for the current region
  ///</summary>
  const toggleEditing = useCallback((value?: boolean) => {
    const nextValue = value === undefined ? !isEditing : value;
    dispatch(toggleEditClientData(nextValue, regionId));
    if(nextValue === false) setChanges({}); //clear out any changes
  }, [regionId, isEditing, dispatch]);

  ///<summary>
  /// Handle a field change event
  ///</summary>
  const onFieldChanged = useCallback((valueKey: string, fieldId: string, rowIndex?: number) => (nextValue: any) => {
    console.log("Field changed called", { valueKey, fieldId, rowIndex, nextValue });
    
    //Row changes are handled differently than single field changes
    const nextValues = rowIndex === undefined ? 
      getFieldChanges(nextValue, changes, storeValues, valueKey, fieldId) : 
      getRowChanges(nextValue, changes, storeValues, valueKey, fieldId, rowIndex);

    if(!!nextValues) {
      setChanges(nextValues);
      console.log("changedValues", nextValues);
    }
    else {
      console.log("No changes");
    }

  }, [changes, storeValues]);

  ///<summary>
  /// Add a new row to a value list
  ///</summary>
  const onRowAdded = useCallback((valueKey: string) => () => {
    const workingValue = changes[valueKey] ?? storeValues[valueKey];
    const nextValues = workingValue ? workingValue.slice() : [];
    nextValues.push({});
    setChanges({ ...changes, [valueKey]: nextValues });
  }, [changes, storeValues]);

  ///<summary>
  /// Delete a row from a value list
  ///</summary
  const onRowDeleted = useCallback((valueKey: string, rowIndex: number) => () => {
    const workingValue = changes[valueKey] ?? storeValues[valueKey];
    if(!workingValue) return;

    const nextValues = workingValue.slice();
    nextValues.splice(rowIndex, 1);
    setChanges({ ...changes, [valueKey]: nextValues });
  }, [changes, storeValues]);

  ///<summary>
  /// Save the changes to the client data
  ///</summary>
  const onSaveChanges = useCallback(async (clientUid: string) => {
    console.log("save changes", changes);
    const result = await dispatch(updateClientValues(clientUid, changes));
    console.log("save result", result);
    const isSaved = (result as any).isOk;
    
    if(isSaved) {
      setChanges({});
    }

    return isSaved;
  }, [dispatch, changes]);

  const providerValue = {
    isEditing, 
    editRegionId, 
    toggleEditing, 
    changes, 
    fieldChanged: onFieldChanged,
    addRow: onRowAdded,
    deleteRow: onRowDeleted,
    saveChanges: onSaveChanges,
  };

  return (
    <FormEditorContext.Provider value={providerValue}>
      {children}
    </FormEditorContext.Provider>
  );
};

export default FormEditorProvider;


//=== Helper Functions ===

function areSame(value1: any, value2: any) {
  if(value1 === value2) return true;
  if(!value1 && value2 === "") return true;
  if(!value2 && value1 === "") return true;
  return false;
}

function getFieldChanges(nextValue: any, changes: Record<string, any>, originalValues: Record<string, any>, valueKey: string, fieldId: string) {
  const workingValue = changes[valueKey];
  const originalValue = originalValues[valueKey];

  if(workingValue === undefined || !areSame(workingValue, nextValue)){
    let nextValues = changes;

    // If the new value is the same as the original, delete this from the changed values
    if(areSame(originalValue, nextValue)){
      const { [valueKey]: omit, ...rest } = changes;
      nextValues = rest;
    }
    else {
      //If there's no changed value, and the next value is the same as the original, nothing changed
      if(!workingValue && areSame(originalValue, nextValue)) return null;   //if nothing changed from the original, skip
      //If the new value is different from the original, add it to the changed values
      nextValues = { ...changes, [valueKey]: nextValue };
    }

    // nextValues = { ...changes, [valueKey]: nextValue };
    return nextValues;
  }

  return null;    //no changes
}

function getRowChanges(nextValue: any, changes: Record<string, any>, originalValues: Record<string, any>, valueKey: string, fieldId: string, rowIndex: number) {
  //Firebase will replace arrays, so the changes need to include the original array, with the modified row
  const workingValue = changes[valueKey] ?? originalValues[valueKey];

  if(!workingValue){
    //no current values, add a new row
    const item = { [fieldId]: nextValue, };
    return { ...changes, [valueKey]: [item] };
  }
  else {
    const nextValues = workingValue.slice();
    nextValues[rowIndex] = { ...nextValues[rowIndex], [fieldId]: nextValue };
    return { ...changes, [valueKey]: nextValues };
  }
}