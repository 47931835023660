import { useMemo } from 'react';
// import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
// import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// import { OverlayScrollbarsComponent as ScrollContainer } from 'overlayscrollbars-react';
import { usePetitionerForm } from '../../form-hooks';
import RegionTabs from './region-tabs';
import { CustomerNavButton } from 'components';
import RegionMenu from './region-menu';

export interface IRegionHeaderProps {
  reposition: () => void;
}

const RegionHeader = ({reposition}: IRegionHeaderProps) => {
  // const navigate = useNavigate();
  const { regions } = usePetitionerForm();
  // const formStatus = useFormStatus();
  const headerRegions = useMemo(() => regions?.filter(r => (r.type !== "summary" && r.type !== "docs")) ?? [], [regions]);

  return (
    // <Box width="100vw" height={60}>
    //   <ScrollContainer defer options={{ scrollbars: { autoHide: "move" } }} >
        <Box sx={ rowSx }>
          {/* Home & Nav menu */}
          <CustomerNavButton />

          {/* Form Region Tabs */}
          <RegionTabs items={headerRegions} onChanged={() => reposition()}/>

          <RegionMenu />
          {/* Submit button / chip  */}
          {/* <Grid2 container flexGrow="1" justifyContent="flex-end" alignItems="center" flexWrap="nowrap">
            {formStatus.isSubmitted && (
              <Tooltip title={`You submitted this form on ${formatShortDate(formStatus.submittedOn ?? formStatus.updatedOn)}`}>
                <Chip icon={<CheckCircle fontSize="small" />} label="Submitted" color="primary"/>
              </Tooltip>
            )}
            {!formStatus.isSubmitted && (
              <Tooltip title="Save and submit this form to your attorney for review">
                <Button onClick={submitForm} variant="text" size="small" endIcon={<CheckCircleOutline fontSize="small" />}>Submit</Button>
              </Tooltip>
            )}
            <Tooltip title="Save and close this form">
              <IconButton size="small" sx={{ ml: 1 }} onClick={() => navigate("/app")}>
                <Close fontSize="small"/>
              </IconButton>
            </Tooltip>
          </Grid2> */}
        </Box>
    //   </ScrollContainer>
    // </Box>
  );
}

export default RegionHeader;

//Style for the tab row
const rowSx = {
  height: 60,
  minHeight: 60,
  borderBottom: "1px solid #dee2e6",
  bgcolor: "white",
  color: "#808080",
  px: 2,
  display: "flex",
  alignItems: "center",
  gap: 2,
};