import { useMemo } from "react";
import { alpha, Button, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Cancel, Edit, Save } from "@mui/icons-material";
import { FormRegion } from "types";
import { useClient } from "sections/collaborator/client-context";
import { useFormEditor } from "sections/forms/form-editor-context";

export interface IDataTableRegionToolbarProps {
  region: FormRegion;
};

const DataTableRegionToolbar = ({ region }: IDataTableRegionToolbarProps) => {
  const theme = useTheme();  
  const { clientUid } = useClient();
  const { isEditing, editRegionId, toggleEditing, changes, saveChanges } = useFormEditor();
  
  //only allow editing of one region at a time
  const isEditingOther = useMemo(() => !!editRegionId && editRegionId !== region.id, [editRegionId, region.id]);
  const canSave = useMemo(() => Object.keys(changes).length > 0, [changes]);

  const handleEdit = () => {
    toggleEditing(true);
  }

  const handleSaveEdits = async () => {
    const isSaved = await saveChanges(clientUid);
    if(isSaved) toggleEditing(false);
  }

  const handleCancelEdits = () => {
    if(isEditing) toggleEditing(false);
  }

  return (
    <Grid container mb={1} spacing={1} alignContent="center" width="100%" sx={{ bgcolor: isEditing ? alpha(theme.palette.info.light, 0.3) : "unset" }}>
      <Grid container xs={6} columnGap={1} alignItems="center">
        {!isEditingOther && (
          <Tooltip title="Edit client form data" >              
            <>
              <Button onClick={handleEdit} disabled={isEditing || isEditingOther} size="small" endIcon={<Edit sx={{ fontSize: "1.5rem" }} /> } >
                Edit
              </Button>
            </>
          </Tooltip>
        )}
        {isEditingOther && <Typography variant="body2" color="grey.400" fontStyle="italic">A different region is being edited</Typography> }
      </Grid>
      <Grid container xs={6} spacing={1} columnGap={1} justifyContent="flex-end" alignItems="center">
        {isEditing && (
          <>
            <Tooltip title="Save client form data" >
              <>
                <Button onClick={handleSaveEdits} color="secondary" disabled={!isEditing || !canSave} size="small" endIcon={<Save sx={{ fontSize: "1.5rem" }} /> } >
                  Save
                </Button>
              </>
            </Tooltip>
            <Tooltip title="Cancel all changes" >
              <Button onClick={handleCancelEdits} size="small" endIcon={<Cancel sx={{ fontSize: "1.5rem" }} /> } >
                Cancel
              </Button>
            </Tooltip>
          </>
        )}
      </Grid>

    </Grid>
  );
}

export default DataTableRegionToolbar; 
