import { useCallback, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { getRemoteShareableLink, getShareableLink } from "helpers/cloud-storage-helpers";
import { useCloudStorage } from "sections/collaborator/parts/provider-accounts/cloud-storage-context/cloud-storage-context";

const useCloudPreview = () => {
  const { instance } = useMsal();
  const [previewFileId, setPreviewFileId] = useState<string | null>(null);
  const [preview, setPreview] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);
  const { setting } = useCloudStorage();

  const loadPreview = useCallback(async (fileId: string) => {
    if(fileId === previewFileId) return;

    let preview: any = null;
    if(setting?.isRemote) preview = await getRemoteShareableLink(instance, setting.driveId, fileId);
    else preview = await getShareableLink(instance, fileId);

    if(preview.error){
      console.error("Error fetching preview", preview.error);
      setError(preview.error);
      return;
    }
    
    setPreviewFileId(fileId);
    console.log("file preview info: ", preview);
    setPreview(preview);
  }, [instance, previewFileId, setting]);

  return { previewUrl: preview?.link?.webUrl, loadPreview, error };
};

export default useCloudPreview;