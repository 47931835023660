import { createContext, useCallback, useContext, useMemo } from "react";
import { CloudStorageSettingSchema } from "types/schema";
import { OneDriveProvider } from "./onedrive-context";
import { useProfileJsonSetting } from "sections/app/app-context";
import SettingKeys from "helpers/setting-keys";

export type ICloudStorageContext = {
  setting: CloudStorageSettingSchema | undefined;
  updateSetting: (newValue: CloudStorageSettingSchema | Partial<CloudStorageSettingSchema> | null) => Promise<void>;
  provider: string | undefined;
  setProvider: (newProvider: string) => void;  
}

export const CloudStorageContext = createContext<ICloudStorageContext | null>(null);

export const useCloudStorage = () => {
  const context = useContext(CloudStorageContext);
  if(!context) throw new Error("useCloudStorage must be used within a CloudStorageProvider");
  return context;
}

export const CloudStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const { setting, updateSetting } = useProfileJsonSetting<CloudStorageSettingSchema>(SettingKeys.app.cloudSync);
  const isOneDrive = useMemo(() => setting?.provider === "onedrive", [setting?.provider]);

  //Sets the cloud storage provider
  const setProvider = useCallback(async (newProvider: string) => {
    if(newProvider !== setting?.provider){
      const newValue = { ...setting, provider: newProvider };
      await updateSetting(newValue);
    }
  }, [setting, updateSetting]);

  
  const providerValue = useMemo(() => ({
    provider: setting?.provider,
    setting,
    updateSetting,
    setProvider,
  }), [setting, updateSetting, setProvider]);

  return (
    <CloudStorageContext.Provider value={providerValue}>
      
      {/* OneDrive provider */}
      {/* {isOneDrive && ( */}
        <OneDriveProvider>
          {children}
        </OneDriveProvider>
      {/* )} */}

      {/* No provider selected yet */}
      {/* {!isOneDrive && (
        children
      )} */}
      
    </CloudStorageContext.Provider>
  );
}