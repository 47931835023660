import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { FormField } from "types";
import { financialsHelper as fHelper } from "helpers";
import { useConditionValue } from "helpers/selector-hooks";
import { parseMultiCondition } from "helpers/form-config-helpers";

export interface ConditionProps {
  field: FormField;
  children: ReactNode;
}

export function Condition({ field, children }: ConditionProps) {
  if(!field.condition) return children;
  const cond = field.condition;

  if(cond.indexOf("||") > -1 || cond.indexOf("&&") > -1){
    return (<MultiCondition field={field}>{children}</MultiCondition>)
  }

  return (<SingleCondition field={field}>{children}</SingleCondition>)
}

//Condition Component for a single condition
export function SingleCondition({ field, children }: ConditionProps) {
  const conditionValue = useConditionValue(field.condition);
  const isMet = fHelper.isConditionMet2(field.condition, conditionValue);
  return isMet ? children : null;
};

//Condition component for a multi-condition
export function MultiCondition({ field, children }: ConditionProps) {
  const allValues = useSelector<any, Record<string, any>>(state => state.values);
  if(!field.condition) return children;

  const conditions = parseMultiCondition(field.condition);
  const isMet = conditions.every(c => fHelper.isConditionMet2(c, allValues));
  return isMet ? children : null;
};
