import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import MenuList from '@mui/material/MenuList';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { MenuListItem } from 'components';
import { signOut } from 'store/actions/app-actions';
import { selectProfile } from 'store/selectors/app-selectors';
import { saveDataToServer } from 'store/actions/value-actions';
import { useAnchor } from 'helpers/hooks';

export default function ProfileMenu({simple = false}){
  const classes               = buildStyles();
  const dispatch              = useDispatch();
  const user 	 		            = useSelector(state => state.app.user);		
  // const customer              = useSelector(state => selectCustomer(state));
  const profile               = useSelector(selectProfile);
  const account = useSelector(state => state.account.account);
  
  const navigate               = useNavigate();
  const [anchorEl, isOpen, onOpen, onClose, position]   = useAnchor("bottomLeft");
  const requiresAccount = Boolean(profile.isReviewer && !account);
  
  if(!user) return null;
  
  async function onSignOut(){
    await dispatch(saveDataToServer());
    onClose();
    await dispatch(signOut());    
  }
  function onNavigate(url){
    navigate(url);
    onClose();
  }

  const hasPhoto    = Boolean(profile.photo) || Boolean(profile.photoUrl);
  const image       = hasPhoto ? <img className={classes.avatar} src={profile.photo ?? profile.photoUrl} noreferrerpolicy="no-referrer" alt="profile"></img> : <AccountCircleIcon className={classes.avatar}/>;
  const menuItems = getMenuItems(profile);

  return (
    <>
      <Button aria-haspopup="true" color="primary" className={classes.menuButton} onClick={onOpen}>
        {image}{!simple && profile.displayName}
      </Button>          

      <Popover open={isOpen} anchorEl={anchorEl} {...position} onClose={onClose} >
        <Grid container justifyContent="center" className={classes.popoverContent}>
          
          <Grid item sm={12} container direction="column" alignItems="center" className={classes.profilePanel}>
            {hasPhoto && <img className={classes.largeAvatar} src={profile.photo ?? profile.photoUrl} noreferrerpolicy="no-referrer" alt="avatar"/> }
            {!hasPhoto && <AccountCircleIcon fontSize="large" color="primary"/> }
            <Typography className={classes.bold}>{profile.displayName}</Typography>
            <Typography>{profile.email}</Typography>
          </Grid>

          <Grid item sm={8}>
            <Divider />
          </Grid>
          
          <Grid item sm={12} container className={classes.menuContent}>
            <MenuList className={classes.menuList}>
              {!requiresAccount && menuItems.map((item, index) => (
                <MenuListItem key={index} text={item.text} icon={item.icon} onClick={() => onNavigate(item.url)} />
              ))}              
              {!requiresAccount && (
                <Grid container justifyContent="center" style={{margin: "10px 0"}}>
                  <Grid item sm={8}>
                    <Divider />
                  </Grid>
                </Grid>
              )}
              <MenuListItem text="Log Out" icon="exit_to_app" onClick={onSignOut}/>
            </MenuList>
          </Grid>
          
        </Grid>              
      </Popover>
    </>
  );
}

const buildStyles   = makeStyles(theme => ({  
  menuButton    : {
    cursor        : "pointer",
    padding         : "3px 6px",
    textTransform   : "none",
    fontSize        : 14,
    fontWeight      : 400,
    marginLeft      : theme.spacing(1.5),
  },
  avatar      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "36.66px",
    width         : "36.66px",
    lineHeight    : "36.66px",
    marginRight     : theme.spacing(1),
  },
  largeAvatar   : {
    borderRadius  : "50%",
    height        : "60px",
    width         : "60px",
    lineHeight    : "60px",
    marginBottom  : theme.spacing(1),
  },
  popoverContent  : {
    maxWidth    : 300,
  },
  profilePanel: {
    padding         : `${theme.spacing(2)} 0`,
    "& svg"     : {
      marginBottom  : theme.spacing(1),
    },
    "& p"       : {
      fontSize        : "16px",
      color           : theme.palette.grey[600],
      fontWeight      : 300,
    }
  },
  bold: {
    fontWeight: "600 !important",
  },
  menuContent   : {
    padding           : `0 5px`,
  },
  menuList    : {
    width   : "100%",
  },
}));

const menuConfig = [
  { text: "My Profile", icon: "account_circle", url: "/my", user: true,},
  { text: "My Data", icon: "storage", url: "/my/data", user: true, },
  // { text: "My Sharing", icon: "group", url: "/my/sharing", user: true, },
  
  { text: "My Profile", icon: "account_circle", url: "/app/profile", attorney: true,},
  { text: "My Clients", icon: "group", url: "/app", attorney: true},
  { text: "My Account", icon: "payment", url: "/app/account", attorney: true, },
  
  { text: "Administration", icon: "settings", url: "/admin", admin: true},
  // { text: "About FormHop", icon: "info", url: "/system/info"},
  // { text: "Upgrade to Premium", icon: "shopping_cart", url: "/my/subscription"},
];

const getMenuItems = (profile) => {
  return menuConfig.filter(item => {
    if(item.admin && !profile.isAdmin) return false;
    if(item.attorney && !profile.isReviewer) return false;
    if(item.user && profile.isReviewer) return false;
    return true;
  });
}