import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Beforeunload } from 'react-beforeunload';
import { Grid } from '@mui/material';
import { OverlayScrollbarsComponent as ScrollContainer } from 'overlayscrollbars-react';
import { filterOutSummaryRegions } from 'helpers/financials-helper';
import { ErrorAlert, LoadingBar } from 'components';
import { RegionHeader } from './layout';
import { Region } from './parts';
import { usePetitionerForm } from './form-hooks';
import { initializeFormStatus, saveDataLocal } from 'store/actions/value-actions';
import { chooseForm } from 'store/actions/petitioner-actions';
import FormSidebar from './form-sidebar';
import { useRegionSelection } from './parts/region-context';
import './ffcls.css';
import './custom.scss';
import { useParams } from 'react-router-dom';
import { useAppContext } from '../app/app-context';

const scrollToTop = (ref) => {
    try{
        // ref.current.scrollTo({ top: 0, left: 0, behavior: "smooth"});
        const sb = ref.current.osInstance();
        const { viewport } = sb.elements();
        viewport.scrollTo({ top: 0, left: 0, behavior: "smooth"});
    }
    catch(ex){}
};

const PetitionerFormView = () => {
    const regionRef = useRef(null);
    const dispatch = useDispatch();
    const { regionId } = useParams();
    const { allowComments } = useAppContext();
    const { documentId, saveData, regions, values, hasSummary } = usePetitionerForm();
    const { handleFieldSelected } = useRegionSelection();
    const isWorking = useSelector(state => state.app.isWorking);
    const isExporting = useSelector(state => state.app.isExporting);
    const hasValues = values?.a_isLoaded;
    const isBusy        = !hasValues || isWorking || isExporting;

    useEffect(() => {
      //Make sure we have status information for this document
      const statusRegions = regions.filter(filterOutSummaryRegions).map(r => r.id);
      dispatch(initializeFormStatus(documentId, statusRegions));

      return () => {
          onUnload();
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const scrollUp = useCallback(() => {
      if(regionRef.current) scrollToTop(regionRef);          
    }, []);


    const onBlur = (e) => {
      const nodeType  = e.target.nodeName;
      if(nodeType === "INPUT" || nodeType === "SELECT"){
        if(values.isDirty){
          dispatch(saveDataLocal());                                
        }
      }      
    }

    const onUnload = async () => {
      console.log("unloading form-view");
      if(values.isDirty){
        await saveData();
      }
      await dispatch(chooseForm(null));
    }

    const handleSidebarClicked = (e) => {
      handleFieldSelected(null);
    };

    const showSidebar = allowComments && regionId !== "review"; //true; //(regionId !== "review") && (hasSummary || hasRegionComments || !!selectedField);
    const cols = showSidebar ? [9, 3] : [12];
    
    return (
      <Beforeunload onBeforeunload={onUnload}>
          
        <Grid container direction="row">
          <Grid item sm={12} container direction="column">
              <RegionHeader reposition={scrollUp}/>
          </Grid>
          <Grid item sm={12} container flexWrap="nowrap" sx={{ position: "relative", pt: 2, pl: 2, height: "calc(100vh - 123px)", overflow: "none" }}>
            {!isBusy && (
              <>
                <Grid item container sm={cols[0]} xs={12} id="outer-grid" sx={{ pr: 2 }}>
                  <Grid item id="inner-grid" sm={12} onBlur={onBlur} sx={{  height: "calc(100vh - 137px)" }}>
                    <ScrollContainer ref={regionRef} defer style={{ width: "100%", height: "100%", paddingRight: 4 }} options={{ scrollbars: { autoHide: "move" } }} >
                      <ErrorAlert domain="app" />
                      <ErrorAlert domain="app" domainKey="exportError" />
                      <div id="scroll-target" />
                      {(isBusy || !regions) && <LoadingBar /> }                    
                      {!!regions && <Region reposition={scrollUp}/> }
                    </ScrollContainer>
                  </Grid>
                </Grid>
                {showSidebar && (
                  <Grid id="form-sidebar" onClick={handleSidebarClicked} item sm={cols[1]} xs={0} container>
                    <FormSidebar hasSummary={hasSummary} reposition={scrollUp}/>
                  </Grid>          
                )}      
              </>
            )}
          </Grid>
        </Grid>

      </Beforeunload>
    );
};

export default PetitionerFormView;