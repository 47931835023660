import { useState } from "react"
import { useDispatch } from "react-redux"
import { useParams } from "react-router-dom"
import moment, { Moment } from "moment"
import { Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { DatePicker } from "@mui/x-date-pickers"
import { toast } from "react-toastify"
import { ClientForm } from "types"
import { formatShortDate } from "helpers/general-helpers"
import { ConfirmDialog } from "components"
import { parseFbResult } from "store/store-helpers"
import { updateFormAssignment } from "store/actions/attorney-actions"

export interface IFormDueDateProps {
  form: ClientForm;
}

const FormDueDate = ({ form }: IFormDueDateProps) => {
  const dispatch = useDispatch();
  const { clientId } = useParams<{clientId: string}>();
  const [nextDueAt, setNextDueAt] = useState<any>(form.dueAt ? moment(form.dueAt) : null);
  const [isConfirming, setConfirming] = useState<boolean>(false);
  
  const handleDateChange = (value: Moment | null) => {
    let numVal: number | undefined | null = value?.valueOf();
    if(!numVal || isNaN(numVal)) numVal = null;

    if(numVal !== form.dueAt){
      console.log("change date to: ", numVal ? new Date(numVal) : null );
      setNextDueAt(value);
      setConfirming(true);
    }
  }

  const handleCancelDueDate = () => {
    setNextDueAt(moment(form.dueAt));
    setConfirming(false);
  }

  const handleSaveDueDate = async () => {
    //TODO: implement the save
    console.log("save date change to: ", nextDueAt ? new Date(nextDueAt) : null );
    const updates = { dueAt: nextDueAt?.valueOf() ?? null };
    const result = await dispatch(updateFormAssignment(clientId!, form.id, updates));
    const pr = parseFbResult(result);

    if(pr.isOk){
      toast.success("Due date updated successfully");
      setNextDueAt(moment(form.dueAt));
      setConfirming(false);
    }
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
      {form.dueAt ? formatShortDate(form.dueAt) : <Typography fontSize="1.2rem" color="grey.600" fontStyle="italic">Not Set</Typography>}
      <DatePicker value={nextDueAt} onChange={handleDateChange} sx={{ width: 48, "& input": { display: "none" }, "& fieldset" : { display: "none" } }} />
      <ConfirmDialog open={isConfirming} onClose={handleCancelDueDate} onConfirm={handleSaveDueDate} title="Update Due Date" message="Are you sure you want to update the due date?" />
    </Grid>
  );
};

export default FormDueDate;