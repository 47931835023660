import { useMemo, useState } from "react";
import { DateValidationError } from "@mui/x-date-pickers";

export const useDateValidation = () => {
  const [error, setError] = useState<DateValidationError | null>(null);
  const errorMessage = useMemo(() => {
    switch (error) {
      case "maxDate":
      case "minDate": {
        return "Date must be today or later";
      }

      case "invalidDate": {
        return "Date is not valid";
      }

      default: {
        return "";
      }
    }
  }, [error]);

  return {
    error,
    setError,
    errorMessage
  };
}