import React, { useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleIcon from '@mui/icons-material/People';
import FeedbackIcon from '@mui/icons-material/Feedback';
import DnsIcon from '@mui/icons-material/Dns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import NoteIcon from '@mui/icons-material/Note';
import ListAltIcon from "@mui/icons-material/ListAlt";

const AdminNav = () => {
  const classes   = buildStyles();
  const [isCollapsed, setCollapsed]   = useState(false);

  return (
    <Grid item container className={clsx(classes.navWrapper, {"collapsed": isCollapsed})} direction="column" justifyContent="flex-start">
      <NavLink to="/app" className={classes.navItem} title="Back to the App"><ArrowBackIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Back"}</NavLink>
      <NavLink to="/admin" end className={classes.navItem} title="Dashboard"><DashboardIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Dashboard"}</NavLink>
      <NavLink to="/admin/users" end className={classes.navItem} title="Users"><PeopleIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Users"}</NavLink>
      <NavLink to="/admin/invitations" end className={classes.navItem} title="Invitations"><NoteIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Invitations"}</NavLink>
      <NavLink to="/admin/feedback" end className={classes.navItem} title="feedback"><FeedbackIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Feedback"}</NavLink>
      <NavLink to="/admin/waitlist" end className={classes.navItem} title="Waitlist"><ListAltIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Waitlist"}</NavLink>
      <NavLink to="/admin/auditlog" end className={classes.navItem} title="Audit Log"><DnsIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Audit Log"}</NavLink>
      <IconButton size="small" className={classes.expandButton} onClick={() => setCollapsed(!isCollapsed)} title="Expand / Collapse Menu">
        {isCollapsed && <ChevronRight fontSize="small" />}
        {!isCollapsed && <ChevronLeft fontSize="small" />}
      </IconButton>
    </Grid>
  );
}

export default AdminNav;

const buildStyles   = makeStyles(theme => ({
  navWrapper      : {
    // padding     : theme.spacing(2),
    marginTop  : theme.spacing(4),
    width       : 180,

    "&.collapsed" : {
      width   : 60,
    }
  },  
  navItem   : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.grey[800],

    marginBottom  : theme.spacing(2),
    padding       : theme.spacing(1), //`${theme.spacing(1)} ${theme.spacing(1)}`, //`,
    borderRadius  : "0 5px 5px 0", 
    textDecoration  : "none",
    "&:hover"   : {
      textDecoration  : "none",
      background    : `${theme.palette.grey[400]}44`, //`
    },
    "&.active"  : {
      color         : theme.palette.secondary.main,
      background    : `${theme.palette.secondary.light}44`, //`
    }
  },
  navIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : theme.spacing(-1),   //`
  },
  expandButton : {
    marginTop     : theme.spacing(2),
    alignSelf     : "flex-start",
    marginLeft    : theme.spacing(1),
  },
  disabled  : {
    color       : theme.palette.grey[400],
  },
}));