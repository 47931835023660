import { createContext, useCallback, useContext, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FormField, FormRegion, FormSection } from "types";
import { expandMultiFields, getRegionFields } from "helpers/form-config-helpers";
import { LoadingBar, NotFound } from "components";
import { addRow, deleteRow } from "store/actions/value-actions";
import { useAppContext } from "sections/app/app-context";
import { useFormContext } from "sections/forms/form-context";
import { usePetitionerForm } from "../form-hooks";
import LEGACY_FIELDS from "../../../config/fields-config";

export type IRegionContext = {
  isReady: boolean,
  regionId: string,
  region: FormRegion | null,
  sections: FormSection[],
  fields: FormField[],
  getSectionFields: (sectionId: string, regionId?: string) => any,
  getField: (fieldId: string, regionOnly?: boolean) => FormField | undefined,
  handleValueChanged: (field: FormField, value: any, details: any, index: any) => Promise<void>,
  handleRowAdded: (sectionId: string) => void,
  handleRowDeleted: (sectionId: string, rowIndex: number) => void,
  handleFieldSelected: (fieldId: string | null, index?: number) => void,
  selectedField: null | { id: string, index?: number },
}

export const defaultRegionContext = {
  isReady: false,
  regionId: "",
  region: null,
  sections: [],
  fields: [],
  getSectionFields: (sectionId: string, regionId?: string) => null,
  getField: (fieldId: string, regionOnly?: boolean) => undefined,
  handleValueChanged: async (field: FormField, value: any, details: any, index: any) => {},
  handleRowAdded: (sectionId: string) => {},
  handleRowDeleted: (sectionId: string, rowIndex: number) => {},

  handleFieldSelected: (id: string | null, index?: number) => {},
  selectedField: null,
};

//=== The context for the region
export const RegionContext = createContext<IRegionContext>(defaultRegionContext);


//=== Some Hooks to work with the context
export const useRegion = () => {
  const context = useContext(RegionContext);
  if(!context) throw new Error("useRegion must be used within a RegionProvider");
  return context;
};

export const useRegionSelection = () => {
  const { selectedField, handleFieldSelected } = useRegion();
  return { selectedField, handleFieldSelected };
};

//=== The provider for the region context
const RegionProvider = ({ children } : { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const { schema, regions } = useFormContext();
  const { onValueChanged } = usePetitionerForm(true);
  const { isReviewer } = useAppContext();
  const { clientId, regionId = "" } = useParams();
  const [selectedField, setSelectedField] = useState<{ id: string, index?: number } | null>(null);
  const [regionFields, setRegionFields] = useState<FormField[]>([]);  //fields for this region

  const slug = useMemo(() => clientId ? "/review" : (regionId === "" ? "" : `/${regionId}`), [clientId, regionId]);

  const formFields = useMemo<FormField[] | null>(() => {
    if(!schema) return null;
    const version = (schema as any).version ?? "1.0.0";
    let allFields: FormField[] = version === "1.0.0" ? LEGACY_FIELDS as any : schema.fields;
    
    //if we're the client, remove any attorney-only fields
    if(!isReviewer) allFields = allFields.filter(f => !f.isAttorneyOnly);

    //warn if we have duplicate fields, and remove them
    const fieldIds = allFields.map(f => f.id);
    const duplicates = fieldIds.filter((id, index) => id !== "_SCHEMA_NOTES_" && fieldIds.indexOf(id) !== index);
    if(duplicates.length > 0) console.error("region-context: duplicate fields found", duplicates);
    allFields = allFields.filter((f, index) => fieldIds.indexOf(f.id) === index);

    //automatically create fields that are defined as multi-field children
    allFields = expandMultiFields(allFields);

    return allFields;
  }, [schema, isReviewer]);

  const region = useMemo<FormRegion | null>(() => {
    const region = regions.find(r => r.path === slug);
    if(region){
      const rFields = getRegionFields(region, formFields!);
      if(rFields) setRegionFields(rFields);
      setSelectedField(null);
    }
    return region ?? null;
  }, [regions, slug, formFields]);

  const sections = useMemo<FormSection[]>(() => {
    if(!region) return [];
    let items = region.sections.map(s => ({ ...s, regionId: region.id }));
    
    //if we're the client, remove any attorney-only fields
    if(!isReviewer) items = items.filter(f => !f.isAttorneyOnly);

    return items;

  }, [region, isReviewer]);

  const isReady = useMemo(() => !!schema && !!regions && regionFields?.length > 0, [schema, regions, regionFields]);

  //== Get the fields for a section
  const getSectionFields = useCallback((sectionId: string, regionId?: string) => {
    //TODO: switch this to use the getSectionFields func in form-config-helpers
    if(!region && !regionId) return null;
    const currRegion = region ?? regions.find(r => r.id === regionId);
    if(!currRegion) return null;
    const section = currRegion.sections.find(s => s.id === sectionId);
    if(!section) return null;

    if(!section.fields) return [];
    if(section.fields === "*") return regionFields;
    
    let sectionFields = regionFields
      .filter(f => section.fields.includes(f.id))
      //for any fields with child fields add the child fields to the field
      .map(f => !f.children ? f : { ...f, childFields: f.children.map(id => ({...regionFields.find(f => f.id === id), regionId: currRegion.id, sectionId: section.id })) });

    //filter out so we only have actual fields, and add the sectionId
    sectionFields = sectionFields.filter(f => !!f).map(f => ({ ...f, regionId: currRegion.id, sectionId }));
    return sectionFields;
  }, [region, regions, regionFields]);


  //--Get a specific field by the field id
  const getField = useCallback((fieldId: string, regionOnly: boolean = true) => {
    if(!formFields) return undefined;

    if(!regionOnly){
      const formField = formFields.find(f => f.id === fieldId);
      if(!formField){
        console.warn("region-context: field not found", fieldId);
        return undefined;
      }
      return formField;
    }
    else{
      const regionField = regionFields.find(f => f.id === fieldId);
      if(!regionField){
        console.warn("region-context: field not found", fieldId);
        return undefined;
      }
      return regionField;
    }
  }, [regionFields, formFields]);

  const handleValueChanged = useCallback(async (field: FormField, value: any, details: any, index: any) => {
    if(!onValueChanged) return;    //not viewing this as a petitioner
    console.log("region-context: value changed", { fieldId: field.id, value, details, index });
    
    const regionId = field.regionId;
    const sectionId = field.sectionId;
    if(!regionId || !sectionId) throw new Error("Region and Section Ids are missing from field");

    await onValueChanged(regionId, sectionId, field.id, value, details, index);
  }, [onValueChanged]);

  const handleRowAdded = useCallback((sectionId: string) => {
    console.log("region-context: row added", { sectionId });
    dispatch(addRow(sectionId));
  }, [dispatch]);

  const handleRowDeleted = useCallback((sectionId: string, rowIndex: number) => {
    console.log("region-context: value changed", { sectionId, rowIndex });
    dispatch(deleteRow(sectionId, rowIndex));

  }, [dispatch]);

  const handleFieldSelected = useCallback((fieldId: string | null, index?: number) => {
    console.log("field selection", { fieldId, index, selectedField });
    if(!fieldId) setSelectedField(null);
    //If it's the same field, do nothing
    else if(fieldId === selectedField?.id && index === selectedField?.index) return; //setSelectedField(null);
    //make sure it's a field or section in the current region
    else if(regionFields.find(f => f.id === fieldId) === undefined && sections.find(s => s.id === fieldId) === undefined) setSelectedField(null);
    else {
      const field = { id: fieldId, index };
      setSelectedField(field);
    }
  }, [selectedField, regionFields, sections]);

  const providerValue = useMemo<IRegionContext>(() => ({
    isReady,
    regionId,
    region,
    sections,
    fields: regionFields,
    getSectionFields,
    getField,
    handleValueChanged,
    handleRowAdded,
    handleRowDeleted,
    handleFieldSelected,
    selectedField,
  }), [regionId, region, sections, regionFields, selectedField, isReady, getSectionFields, getField, handleValueChanged, handleRowAdded, handleRowDeleted, handleFieldSelected]);

  if(isReady && !region){
    console.log("region-context: region not found", { slug, isReady, schema, region, });
    return <NotFound />;
  } 

  return (
    <RegionContext.Provider value={providerValue} data-type="region-provider">
      {!isReady && <LoadingBar />}
      {isReady && children}
    </RegionContext.Provider>
  );
}

export default RegionProvider;
