import { useMatch } from "react-router-dom";
import { Box, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { FormField } from "@/types";
import { IDataTableSectionRowProps } from "./data-table-types";
import { numberHelper, financialsHelper as fHelper } from "helpers";
import DataTableRow from "./data-table-row";
import CommentContainer from "./comment-container";
import { useRegion } from "sections/financials/parts/region-context";
import { cellSx, labelCellSx, valueSx } from "./data-table-constants";

//==========
// Multi-column row
function DataTableColumnarRow({fieldKey, values, profile}: IDataTableSectionRowProps){
  const { fields, getField } = useRegion();
  const field = getField(fieldKey, false);
  //NOTE: Toggle this to turn off the commenting feature...  Set it to true and comments will be disabled
  const match     = useMatch("/app/review");     //TODO: a better way to make this read only??

  //Make sure we have a field, and if so, if the condition for the field is met
  if(!field || (field.condition && !fHelper.isConditionMet(field.condition, values))){
    return null;
  }

  const hasChildren = field.children && field.children.length > 0;
    if(!hasChildren){
      return <DataTableRow key={field.id} fieldKey={field.id} values={values} profile={profile} />;      
    }

    const children = fields.filter(cf => field.children?.includes(cf.id));
    const childValues = children.map(c => getFieldValue(c, values));
    const hasValues = childValues.some(cv => !!cv.rawValue);
    const label = field.label?.length > 0 ? field.label : children?.[0].label ?? children?.[1].label ?? "";

    return (
      <TableRow key={field.id}>
        <TableCell colSpan={3} sx={{...cellSx, ...labelCellSx}}>
          <CommentContainer fieldKey={field.id} profile={profile} isReadOnly={!!match}>
            <Grid container xs={12} flexWrap="nowrap" columnSpacing={1}>
              <Grid xs flexGrow={1} sx={{ fontWeight: hasValues ? "700" : "unset"}}>{label}</Grid>
              {childValues.map((cv, index) => {
                const { value, fieldProps } = cv;
                return  <Grid container key={index} sx={{...valueSx, ...fieldProps}}>{value}</Grid>;
              })}
            </Grid>
          </CommentContainer>
        </TableCell>
      </TableRow>
    );
}

export default DataTableColumnarRow;


//TODO: This is copied from data-table-multi-field.tsx.  It should be moved to a helper file
function getFieldValue(field: FormField, values: Record<string, any>){
  const myType = field.type;
  let rawValue = values[field.id];
  let value = rawValue;
  let sx = {};

  if(field.options){
    let cVal = value;
    if(typeof field.options[0].id === "number" && typeof value === "string") cVal = parseInt(value);
    const oVal  = field.options.find(o => o.id === cVal);
    value = oVal ? oVal.label : ""; //field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    value = numberHelper.asCurrency(value);
  }
  else if(myType === "boolean"){
    if(field.subtype === "switch"){
      value = value ? "YES" : "NO";
    } 
    else {
      value = <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{value ? <CheckBoxOutlined fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small"/>}</Box>;      
      sx = { color: "grey.600", justifyContent: "flex-end" };
    }
  }
  
  return { value, rawValue, fieldProps: sx };
}