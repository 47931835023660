import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

function NavButton({to, replace, newTab, isIcon, ...otherProps}){
  const navigate   = useNavigate();
  const onNav     = () => {
    if(Boolean(newTab)){
      window.open(to, "_blank");
    }
    else if(Boolean(replace)){
      navigate(to, {replace: true});
    }
    else{
      navigate(to);
    }
  };

  return Boolean(isIcon) ? <IconButton {...otherProps} onClick={onNav} size="large" /> : <Button {...otherProps} onClick={onNav}/>;
}

export default NavButton;