import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Button, Typography, Table, TableBody, TableCell, TableRow, Divider } from '@mui/material';
import { useStatusMulti } from 'redux-action-status';
import { formatDateString, formatExternalUrl } from 'helpers/general-helpers';
import { acceptInviteApi, rejectInvite, updateReceivedInvite } from 'store/actions/share-actions';
import { loadProviderLogo } from 'store/actions/petitioner-actions';

//NOTE: This represents a pending share request, so only handles scenarios for pending requests

const SharingRequestItem = ({invite, providers}) => {
  const dispatch  = useDispatch();
  const classes   = buildStyles();
  // const url       = `/profiles/${invite.id}`; //`
  // const invite = invite;
  const status    = useStatusMulti(["requests", "shares"]);
  const provider = providers?.find(p => p.id === invite.accountId);
  const senderName = provider?.name ?? invite.senderName;

  useEffect(() => {
    if(provider?.logoPath) dispatch(loadProviderLogo(provider.id, provider.logoPath));
  }, [provider, dispatch]);

  //Flag that we've viewed this request
  useEffect(() => {
    if(!invite) return;
    const changes = { viewedAt : Date.now() };
    console.log("marking invite as viewed.", invite.id, changes);
    dispatch(updateReceivedInvite(invite.id, changes));    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!invite) return null;

  // const isWorking = iStatus.isWorking || sStatus.isWorking;
  const onAccept  = async () => {
    await dispatch(acceptInviteApi(invite)); 
  }

  const onReject  = async () => {
    await dispatch(rejectInvite(invite.id));
  }

  const emailUrl  = `mailto:${provider?.email ?? invite.senderEmail}`;
  const website = formatExternalUrl(provider?.website);
  
  if(!provider || (provider.logoPath && !provider.logoUrl)) return null;

  return (
    <Grid id="request-item" container alignItems="center">
      {provider?.logoUrl && (
        <Grid sm={12} container className={classes.contentCol} sx={{ justifyContent: "center"}} mb={3}>
          <img src={provider.logoUrl} alt={`${provider?.name} logo`} height={96} />
        </Grid>
      )}

      <Grid container sm={12} justifyContent="center">
        {/* <Typography variant="h6" fontSize="1.3rem" fontWeight="500" mb={2}> */}
          {website && (
            <Typography variant="h6" fontSize="1.3rem" fontWeight="500" mb={2}>
              <Link to={website} target="_blank"><strong>{senderName}</strong></Link> has invited you to collaborate on FormHop
            </Typography>
          )}
          {!website && (
            <Typography variant="h6" fontSize="1.3rem" fontWeight="500" mb={2}><strong>{senderName}</strong> has invited you to collaborate</Typography>
          )}
          
        {/* </Typography> */}
        <Typography variant="body2" mb={2}>
          By accepting this invitation, you will be able to collaborate with {senderName}, and share both information and documents. {senderName} will be able to view the information you enter, and share documents and forms with you. <strong>Only accept this invitation if you know {senderName}</strong>.
        </Typography>
      </Grid>

      <Grid container sm={12} justifyContent="center" sx={{ my: 2 }}>
        <Divider variant="middle" width="25%" />
      </Grid>
      
      <Grid container sm={12}>
        <Grid container sm={8}>
          <Table className={classes.detailsTable}>
            <TableBody sx={{ "& td": { borderBottomWidth: 0, padding: 1, pl: 0 }}}>
              <TableRow>
                <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Requested On:</TableCell>
                <TableCell>{formatDateString(invite.invitedAt ?? invite.createdAt)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Sender Email:</TableCell>
                <TableCell><a href={emailUrl} target="_blank" rel="noopener noreferrer">{provider?.email ?? invite.senderEmail}</a></TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ color: "grey.500", textWrap: "nowrap"}}>Message: </TableCell>
                <TableCell>{invite.message ?? "Your invitation to FormHop"}</TableCell>
              </TableRow>
            </TableBody>
          </Table>        
        </Grid>
        <Grid container sm={4} alignItems="flex-end" justifyContent="flex-end" spacing={1} gap={1}>
          <Button size="small" variant="outlined" disabled={status.isWorking} onClick={onReject}>Reject</Button>
          <Button size="small" color="secondary" variant="outlined" disabled={status.isWorking} onClick={onAccept}>Accept</Button>
        </Grid>
      </Grid>
    </Grid>    
  );
}

export default SharingRequestItem;

const buildStyles   = makeStyles(theme => ({
  
  deleteRow   : {
    background    : theme.palette.error.light,
    // color         : theme.palette.error.dark,
  },
  actionsCol  : {
    width       : 180,
    padding     : `${theme.spacing(0)} ${theme.spacing(1)}`, //`          
  },
  actionButton  : {
    marginLeft  : theme.spacing(0.5),
    fontSize    : 15,
    fontWeight  : 600,
    display     : "inline-block",
  },
  detailsGrid   : {
    // padding      : `${theme.spacing(1)} ${theme.spacing(2)}`, //`
  },
  detailsLabel  : {
    fontSize    : 15,
    fontWeight  : 400,
    color       : theme.palette.grey[500],
    // padding     : `${theme.spacing(0.5)} ${theme.spacing(2)}`, //`,
  },
  detailsValue  : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.grey[800],
  }
}));