import React from "react";
import { Tooltip } from '@mui/material';
import { useAppContext } from "sections/app/app-context";

export interface IDataTableCommentTooltipProps {
  children: any;

}

const ttPropsA: any   = { placement: "top", enterDelay: 750 };


const DataTableCommentTooltip = ({ children }: IDataTableCommentTooltipProps) => {
  const { allowComments } = useAppContext();
  
  if(!allowComments) return children;

  return (
    <Tooltip title="click to add or view comments" {...ttPropsA}>
      {children}
    </Tooltip>
  );
};

export default DataTableCommentTooltip; 
