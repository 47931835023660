import { useState } from "react";
import { Box, IconButton, Stack, TableCell, TableRow, Tooltip, useTheme } from "@mui/material";
import { Delete, Edit, Email } from "@mui/icons-material";
import { AccountMember, ProviderAccount } from "types";
import { AccountInvitationSchema } from "types/schema";
import { ConfirmationRow } from "components/data-table";
import { useCopyToClipboard } from "react-use";
import { toast } from "react-toastify";

export type MemberItem = AccountMember | AccountInvitationSchema;

export interface IAccountMememberListItemProps {
  account: ProviderAccount;
  item: MemberItem;
  onEdit?: (member: MemberItem) => void;
  onDelete?: (item: MemberItem, isMember: boolean) => void;
}

const itemName = (item: MemberItem) => "profile" in item ? item.profile?.displayName : `${item.firstName} ${item.lastName}`;

const AccountMememberListItem = ({ account, item, onEdit, onDelete }: IAccountMememberListItemProps) => {
  const theme = useTheme();
  const [_, copyToClipboard] = useCopyToClipboard();
  const isMember = "profile" in item;
  const isOwner = account.ownerId === item.id;
  const [isConfirming, setConfirming] = useState(false);

  const name = itemName(item);
  const role = isMember ? item.role : "invited";

  const handleCopyInvitation = () => {
    copyToClipboard(item.id);
    toast.success("Invitation code copied to clipboard");
  };

  const handleDelete = () => {
    // if(!isConfirming) {
      setConfirming(true);
      return;
    // }
    // else {
    //   onDelete!(item, isMember);
    // }
  };


  if(isConfirming) return <ConfirmationRow colSpan={5} onYes={() => onDelete!(item, isMember)} onNo={() => setConfirming(false)}>Are you sure you want to delete {isMember ? "member" : "the invitation for"} <strong>{name}</strong>?</ConfirmationRow>;

  return (
    <TableRow key={item.id}>
      <TableCell>{name}</TableCell>
      <TableCell>{item.email}</TableCell>
      <TableCell>{role}{isOwner ? ", owner" : ""}</TableCell>
      <TableCell>
        {!isMember && (
          <Tooltip title="Invitation code: click to copy">
            <Email onClick={handleCopyInvitation} fontSize="small" color="disabled" sx={{ "&:hover": { color: theme.palette.primary.main }}}/>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Stack direction="row" gap={1}>
            { !isOwner && (
              <>
                {isMember && onEdit && (
                  <Tooltip title="Edit this member">
                    <IconButton size="small" color="primary"><Edit  fontSize="small"/></IconButton>
                  </Tooltip>
                )}
                {onDelete && (
                  <Tooltip title="Delete this member">
                    <IconButton onClick={handleDelete} size="small" sx={{ color: "grey.400", "&:hover": { color: theme.palette.secondary.main } }}><Delete fontSize="small" /></IconButton>
                  </Tooltip>
                )}
              </>
            )}
          </Stack>
        </Box>                        
      </TableCell>
    </TableRow>
  );
};

export default AccountMememberListItem;