import { useMemo } from "react";
import { useRegion } from "./region-context";
import { QuestionRow } from "./question-row";
import { TableSectionRow } from "./table-section-row";
import { usePetitionerForm } from "../form-hooks";
import { TableBody } from "@mui/material";
import { SubtotalRow } from "./subtotal-row";
import { FormField, FormSection } from "types";

export interface ITableSectionBodyProps {
  sectionId: string;
  fields: FormField[];
  isCollapsed: boolean;
  onChange: (fieldId: string, total: number, details: any, index: number) => void;
}

const TableSectionBody = ({ sectionId, fields, isCollapsed, onChange}: ITableSectionBodyProps) => {
  const { values } = usePetitionerForm();
  const { sections } = useRegion();
  const section = useMemo<FormSection | undefined>(() => sections.find(s => s.id === sectionId), [sectionId, sections]);

  if(isCollapsed || fields?.length === 0) return null;
  if(!section){
    console.error(`Section not found: ${sectionId}`);
    return null;
  }

  let rows = [];
  for(var i = 0; i < fields.length; i++){
    const field = fields[i];    
    if(!field) continue;

    let row = null;
    const rowId = `row-${i}`;

    if(field.type === "question"){
      const colProps = {colSpan : "4"};
      row = <QuestionRow key={rowId} field={field} data={values} colProps={colProps}/>;
    }
    else{
      row = <TableSectionRow key={rowId} field={field} data={values} onChangeDetails={onChange} section={section}/>;
    }

    if(row) rows.push(row);
  }

  const myKey         = `${sectionId}-subtotal`;
  const totals        = section.noTotals ? null : <SubtotalRow key={myKey} label={"Total " + (section.name || "")} data={values} section={section} fields={fields}></SubtotalRow>;
  if(totals) rows.push(totals);

  return (
    <TableBody>
      {rows}
    </TableBody>
  );
};

export default TableSectionBody;