import Icon from '@mui/material/Icon';
import Badge from '@mui/material/Badge';

export default function IconWithBadge({count, fontSize, badgeColor, iconColor, icon, classes, otherProps}){
  return (
    <>
      {count > 0 &&
        <Badge badgeContent={count} color={badgeColor} classes={{badge: classes?.badge}} {...otherProps}>
          <Icon color={iconColor} fontSize={fontSize || "small"} className={classes?.icon}>{icon}</Icon>
        </Badge>
      }
      {count <= 0 &&
        <Icon fontSize={fontSize || "small"} color={iconColor}>{icon}</Icon>
      }
    </>
  );
}