import buildStore from 'store/build-store';
import { initializeFirebase } from 'config/firebase-config';
// import ReactGA from 'react-ga';

// const trackingId  = process.env.REACT_APP_TRACKING_ID;

//----------------
// Bootstraps the app by setting up the various configurations
// keep this separate so that hot-reload can work without 
// re-running this configuration every time the app hot-reloads
export const bootstrapApp   = () => {
  const store     = buildStore();
  const firebase  = initializeFirebase();

  //Initialize Google Analytics
  return {
    store       : store,
    firebase    : firebase,
    // trackingId  : trackingId,
  };
}