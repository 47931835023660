import { ChangeEvent, useState } from "react";
import { FormField } from "types";
import { SwitchText } from "components";
import { useRegion } from "../region-context";

export interface ISwitchFieldProps {
  field: FormField;
  dataProps?: any;
  value: any;
}

//NOTE: MaterialUI Components don't use the native events, so they don't bubble to get handled by the withFieldChange HOC.
// Therefore, we need to explicitly handle the change event here, and call the handleValueChanged function from the region context
const SwitchField = ({ field, dataProps, value } : ISwitchFieldProps ) => {
  // const opts = field.options;
  const [val, setVal] = useState<boolean>(value ?? false);    //add a field.defaultValue
  const { handleValueChanged } = useRegion();
  const classNames = `form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  //row index gets assigned as a dataProp
  const rowIndex = dataProps ? dataProps["data-index"] : null;
  const labels = { trueLabel: field.config?.trueLabel?.toUpperCase() ?? "YES", falseLabel: field.config?.falseLabel.toUpperCase() ?? "NO" };
  // if(!field.options || field.options.length != 2) throw new Error("Switch field must have exactly 2 options");

  // const handleChange = async (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
  //   console.log(checked);
  //   setVal(checked);
  //   // const nextValue = checked ? field.options![0].id : field.options![1].id;
  //   await handleValueChanged(field, checked, null, rowIndex);
  // };
 
  return (
    <SwitchText 
      id={field.id}
      // {...labels}
      value={value} 
      checked={value}
      // disabled={field.isDisabled} 
      size="small"
      // onChange={handleChange}
      className={classNames}
      sx={{ py: 0.5, fontSize: "0.85rem" }}
    />
  );
};

export default SwitchField;