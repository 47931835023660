import { Card, CardContent, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatus } from "redux-action-status";
import { ProductPrice } from "components";
import { StatusKeys } from "helpers";

const ProviderProductCard = ( { onChoose }: { onChoose: (product: any) => void }) => {
  const theme = useTheme();
  const status = useStatus(StatusKeys.subscriptions);
  const colWidth = 12 / productDescriptions.length;

  return (
    <Grid container sm={12}>
      <Card variant="outlined" sx={{ width: "100%" }}>
        <CardContent sx={{ textAlign: "center" }}>
          {/* <Typography fontSize="1.4rem" fontWeight="500">Account Types</Typography> */}
          <Typography fontSize="1.4rem" fontWeight="500">Choose the subscription that best fits your needs</Typography>
          {/* <Typography variant="body2" color="grey.600">You will be taken to a secure checkout page to complete your purchase, then re-directed back here when you are finished</Typography> */}
          <Grid container mt={4}>
            {productDescriptions.map(product => (
              <Grid container key={product.id} sm={colWidth} sx={{ "&:not(:last-child)": { borderRight: `1px solid ${theme.palette.grey[300]}` }, "&:last-child": { pb: 0 }}}>
                <ProductPrice priceInfo={product} onPurchase={() => onChoose(product)} isWorking={status.isWorking} />
              </Grid>
            )) }
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  )

};

export default ProviderProductCard;

const productDescriptions = [
  // {
  //   id: "basic",
  //   active: true,
  //   description: "Basic Edition",
  //   unit_amount: 0,
  //   info: { description: "For a single-person practice, and includes all features of the product" }
  // },
  {
    id: "practice",
    active: true,
    description: "Small Practice Edition",
    unit_amount: -2, //12500,
    info: { description: "For small practices with multiple team members. Includes access to all forms and templates, plus access to our library of legal resources" }
  },
  {
    id: "enterprise",
    active: true,
    description: "Enterprise Practice Edition",
    unit_amount: -1,
    info: { description: "For larger firms, includes access to all forms and templates, plus access to our library of legal resources, and priority support" }
  }
];