import { useMemo, useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Divider, IconButton, Paper, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircle, Logout } from "@mui/icons-material";
import OneDriveIcon from "./onedrive-icon";
import OneDriveFolderTree from "./onedrive-folder-tree";
import OneDriveSettings from "./onedrive-settings";
import { useCloudDrive } from "../cloud-storage-context/onedrive-context";
import OneDriveLogin from "./onedrive-login";
import { useCloudStorage } from "../cloud-storage-context/cloud-storage-context";

const CloudStorageMicrosoft = () => {
  const theme = useTheme();
  const { isAuthenticated } = useCloudDrive();
  const { setting } = useCloudStorage();
  const isMissing = useMemo(() => !setting?.id, [setting]);
  const [isEdit, setEdit] = useState(isMissing);

  const toggleEdit = () => {
    setEdit(!isEdit);
  };

  return (
    <Grid container sm={12}>
      <Grid container xs={12} sm={9} md={7}>

        <Paper id="cloud-storage-card-microsoft" variant="outlined" sx={{ width: "100%", p: 2, borderRadius: 2, bgcolor: isAuthenticated ? alpha(theme.palette.primary.light, 0.15) : undefined, position: "relative" }}>
          <Grid container sm={12}>
            {/* Header with the icon and name */}
            <Grid container sm={12} direction="column" alignItems="center">
              <OneDriveIcon sx={{ width: 72, height: 48 }}/>
              <Typography fontSize="1.4rem" fontWeight="600" color="secondary.main">Microsoft OneDrive</Typography>
              {(isAuthenticated && isEdit) && (
                <Tooltip title="Sign out of OneDrive">
                  <IconButton size="small" sx={{ position: "absolute", top: 2, right: 2 }} onClick={toggleEdit}>
                    <Logout fontSize="small" sx={{ color: "grey.500" }}/>
                  </IconButton>
                </Tooltip>
              )}
            </Grid>

            {/* Status area */}
            <Grid container sm={12} direction="column" alignItems="center" my={1}>
              <Grid container columnGap={1} alignItems="center">
                <CheckCircle color={isAuthenticated ? "primary" : "disabled"} fontSize="small" />
                <Typography fontSize="1.4rem" fontWeight="600" color={isAuthenticated ? "primary" : "default" }>{isAuthenticated ? "Connected" : "Not Connected"}</Typography>                
              </Grid>
            </Grid>

            <Divider flexItem sx={{ my: 1, width: "100%" }}/>

            <AuthenticatedTemplate>
              {isEdit && <OneDriveFolderTree onToggleEdit={toggleEdit} /> }
              {!isEdit && <OneDriveSettings onToggleEdit={toggleEdit} /> }
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              <Box sx={{ width: "100%", pt: 2, pb: 1 }}>
                <OneDriveLogin />
              </Box>
            </UnauthenticatedTemplate>

          </Grid>
        </Paper>
        
      </Grid>
    </Grid>
  );
};

export default CloudStorageMicrosoft;