import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { CloudSyncStatus, CurrentClient, Share } from "types";
import { LoadingBar } from "components";
import { chooseClient, loadClient } from "store/actions/attorney-actions";
import { chooseConversation } from "store/actions/conversation-actions";
import { selectClientShare } from "store/selectors/attorney-selectors";
import { ShareProvider } from "../app/share-context";
import InviteClientDialog from "./invite-client-dialog";
import { useClientWatcher } from "hooks/cloud-storage";

export type IClientContext = {
  clientId: string;
  clientUid: string;
  currentClient: CurrentClient;
  share: Share | null;
  documentId: string | null;
  inviteClient: () => void;
  refreshClient: () => void;
  closeClient: () => void;
  isNew: boolean;
  isEdit: boolean;
  isInviting: boolean;
  syncStatus: CloudSyncStatus;
  doSync: (force?: boolean) => Promise<void>;
}

export const ClientContext = createContext<IClientContext | null>(null);

export const useClient = () => {
  const context = useContext(ClientContext);
  if(!context) throw new Error("useClientContext must be used within a ClientProvider");
  return context;
}

//A hook to safely get the client identifiers, and not throw an error if we're not in the provider client context
export const useClientIdentifiers = () => {
  const context = useContext(ClientContext);
  if(!context) return { clientId: "", clientUid: "", shareId: "", documentId: "" };
  return { clientId: context.clientId, clientUid: context.clientUid, shareId: context.share?.id ?? "", documentId: context.documentId ?? ""};
}

export const ClientProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { clientId, documentId = null } = useParams();
  const [isInviting, setInviting] = useState(false);

  const currentClient = useSelector<any, CurrentClient>(state => state.attorney.currentClient);
  const share = useSelector<any, Share | null>(selectClientShare);
  
  const { syncStatus, doSync } = useClientWatcher(currentClient, share);

  const isEdit = useMemo(() => clientId === "new" || pathname.includes("/edit/"), [clientId, pathname]);
  //we're ready when there's a client id, and either it's new, or we have a current client and a share
  const isReady = useMemo(() => (!!clientId && (clientId === "new" || (!!currentClient && !!share))), [clientId, currentClient, share]);

  useEffect(() => {
    if(!clientId) return;
    dispatch(chooseClient(clientId));
  }, [clientId, dispatch]);

  const handleClose = useCallback(() => {
    dispatch(chooseClient(null));
    dispatch(chooseConversation(null));
    navigate("/app");
  }, [dispatch, navigate]);

  const handleRefresh = useCallback(() => {
    if(!clientId) return;
    dispatch(loadClient(clientId));
  }, [clientId, dispatch]);

  const value = useMemo(() => ({
    clientId: clientId!,
    clientUid: currentClient?.profile?.uid ?? "",
    currentClient,
    share: share, //: currentClient?.share,
    documentId,
    isNew: clientId === "new",
    isEdit,
    isInviting,
    inviteClient: () => setInviting(true),
    refreshClient: handleRefresh,
    closeClient: handleClose,
    syncStatus,
    doSync,
  }), [clientId, currentClient, share, syncStatus, doSync, documentId, handleRefresh, handleClose, isEdit, isInviting]);

  return (
    <ShareProvider>
      <ClientContext.Provider value={value}>
        
        <Box id="client-provider" sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
          {isReady ? children : <LoadingBar message="Loading client, please wait..." />}
        </Box>

        {isInviting && currentClient && (<InviteClientDialog isOpen={true} onClose={() => setInviting(false)} clientProfile={currentClient} />) }

      </ClientContext.Provider>
    </ShareProvider>
  )
};
