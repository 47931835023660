import { useDispatch } from "react-redux";
import { Box, Divider, IconButton, Stack, Table, TableBody, TableCell, TableContainer, TableRow, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Close, Refresh } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { TemplateOptions } from "types";
import { ExportTemplateSchema } from "types/schema";
import { StatusKeys } from "helpers";
import { useFormContext } from "./form-context";
import FormExportOption from "./form-export-option";
import { ClientFormProgress } from "../collaborator/parts/chips";
import FormDueDate from "./form-due-date";
import { exportForm } from "store/actions/attorney-actions";
import { useNavigate, useParams } from "react-router-dom";

export interface IProviderFormPropertiesProps {
  onHide?: () => void;
  onRefresh?: () => Promise<void>;
}

const ProviderFormProperties = ({ onHide, onRefresh }: IProviderFormPropertiesProps) => {
  const dispatch = useDispatch();
  const { clientId } = useParams<{clientId: string}>();
  const navigate = useNavigate();
  const { form, formInfo, templates, schema } = useFormContext();
  const status = useStatus(StatusKeys.forms);
  
  const handleExport = async (option: ExportTemplateSchema, clientKey?: string) => {
    console.log("Exporting", option);

    //First, refresh the client data to make sure we have the latest values
    if(onRefresh) await onRefresh();
    //Next, perform the export
    const result = await dispatch(exportForm(option, clientKey as TemplateOptions, schema, true)) as any;
    
    if(result.isOk){
      toast.success("The document was successfully exported, and uploaded to the client's documents.");
      //Navigate to the uploaded document, if there is one
      const doc = result.data.doc;
      if(doc.id){
        const url = `/app/clients/${clientId}/documents/${doc.id}`;
        navigate(url);
      }
    }
    else {
      toast.error("Failed to export document. If this persists, please contact support.");
    }
  }

  return (
    <Box id="provider-form-properties" width="100%" sx={{ position: "relative" }}>
      <TableContainer sx={{ border: "1px solid", borderRadius: 2, borderColor: "grey.300", overflowX: "hidden"}}>
        <Table>
          <TableBody>

            <TableRow>
              <TableCell colSpan={2} sx={{ py: 1 }}>
                <Grid container justifyContent="flex-end" alignItems="center" spacing={1} columnGap={2}>
                  <Tooltip title="Refresh client data">
                    <IconButton onClick={onRefresh} size="small">
                      <Refresh sx={{ fontSize: "1.5rem" }} />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Hide form properties">
                    <IconButton onClick={onHide} size="small">
                      <Close sx={{ fontSize: "1.5rem" }}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell sx={labelSx}>Status</TableCell>
              <TableCell sx={{ pr: 6 }}>
                <ClientFormProgress formInfo={formInfo} />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={labelSx}>Due</TableCell>
              <TableCell sx={{ pr: 5 }}>
                <FormDueDate form={form} />
              </TableCell>
            </TableRow>
            
            <TableRow>
              <TableCell sx={labelSx}>Description</TableCell>
              <TableCell>{form.description}</TableCell>
            </TableRow>

            <TableRow>
              <TableCell sx={labelSx}>Notes</TableCell>
              <TableCell>{form.notes}</TableCell>
            </TableRow>

            {templates && (
              <TableRow>
                <TableCell colSpan={2}>
                  <Stack>
                    <Typography sx={labelSx}>Form Exports</Typography>
                    {/* <ProviderFormExportButton options={templates} isWorking={status.isWorking} onExport={handleExport} /> */}
                    {templates.map((opt, index) => (
                      <Box key={opt.id} mb={1}>
                        {(index > 0) && <Divider variant="middle" />}
                        <FormExportOption option={opt} isWorking={status.isWorking} onExport={handleExport} />
                      </Box>
                    ))}

                  </Stack>
                </TableCell>
              </TableRow>
            )}

          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProviderFormProperties;


const labelSx = { color: "grey.500", fontSize: "1rem", textTransform: "uppercase"};

// const exportOptions: ExportOption[] = [
//   {
//     id: "jdf-1111",
//     name: "CO Sworn Financial Statements",
//     desc: "The primary sworn financial statement for Colorado",
//     isRequired: true,
//     locKey: "co",
//     order: 0
//   },
//   {
//     id: "jdf-1111-ss",
//     name: "Supporting Statements",
//     desc: "for CO Sworn Financial Statements",
//     isRequired: false,
//     locKey: "co",
//     order: 1
//   }
// ];