import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, Divider, Stack } from "@mui/material";
import CustomerNavButton from "./customer-nav-button";
import DocumentNavButton from "./document-nav-button";
import { AssignedDocument } from "types";
import { selectPetitionerDocs, selectPetitionerUploads } from "store/selectors/petitioner-selectors";

const CustomerNavBar = () => {
  const { shareId, documentId } = useParams<{shareId: string, documentId: string}>();
  const docs = useSelector<any, AssignedDocument[]>(selectPetitionerDocs);
  const uploads = useSelector<any, AssignedDocument[]>(selectPetitionerUploads);

  return (
    <Box id="document-container-header" sx={ rowSx }>
      <CustomerNavButton />
      <Stack direction="row" spacing={2} ml={2}>
        {docs.map(doc => <DocumentNavButton key={doc.id} document={doc} shareId={shareId} documentId={documentId} />)}
        <Divider orientation="vertical" flexItem />
        {uploads.map(doc => <DocumentNavButton key={doc.id} document={doc} shareId={shareId} documentId={documentId} />)}
      </Stack>
    </Box>
  );
};

export default CustomerNavBar;


const rowSx = {
  height: 60,
  minHeight: 60,
  borderBottom: "1px solid #dee2e6",
  bgcolor: "white",
  color: "#808080",
  px: 2,
  display: "flex",
  alignItems: "center",
  gap: 2,
  width: "100%",
};