import { useMemo } from "react";
import { AccountBox, CloudSync, Edit, Folder, Logout } from "@mui/icons-material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Avatar, Chip, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
import { useCloudDrive } from "../cloud-storage-context/onedrive-context";
import { useCloudStorage } from "../cloud-storage-context/cloud-storage-context";

export interface IOneDriveSettingsProps {
  onToggleEdit: () => void;
}

const OneDriveSettings = ({ onToggleEdit }: IOneDriveSettingsProps) => {
  const { providerAccount: msalAccount, logout } = useCloudDrive();
  const { setting, updateSetting } = useCloudStorage();
  const isFolderMissing = useMemo(() => !setting?.id, [setting]);
  const isModeMissing = useMemo(() => !setting?.syncMode, [setting]);

  const handleSignOut = async () => {
    await updateSetting(null);
    await logout();
  }

  return (
    <Grid container sm={12} justifyContent="center">
      <List sx={{ width: "100%" }}>

        <ListItem disablePadding secondaryAction={
            <Tooltip title="Change sync folder">
              <IconButton edge="end" color="primary" onClick={onToggleEdit}>
                <Edit fontSize="small" />
              </IconButton>
            </Tooltip>
          }>
            <ListItemAvatar>
              <Avatar sx={{ bgcolor: isFolderMissing ? "grey.400" : "primary.main" }}>
                <Folder fontSize="small" />
              </Avatar>
            </ListItemAvatar>
            <ListItemText secondary="Sync folder" primaryTypographyProps={{ fontStyle: isFolderMissing ? "italic" : "unset", color: isFolderMissing ? "grey.400" : "inherit" }}>
              {!setting?.name && "Not Set" }
              {setting?.name}&nbsp;
              {setting?.isRemote && <Tooltip title="This folder is owned by a different user."><Chip size="small" variant="outlined" color="secondary" label="shared" /></Tooltip>}
            </ListItemText>
        </ListItem>
        
        {/* TODO: re-add this when we have different sync modes */}
        {/* <Divider variant="middle" sx={{ my: 1 }}/> */}

        {/* <ListItem disablePadding secondaryAction={
          <Tooltip title="Change sync mode">
            <IconButton edge="end" color="primary" onClick={onToggleEdit}>
              <Edit fontSize="small" />
            </IconButton>
          </Tooltip>
        }>
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: isModeMissing ? "grey.400" : "primary.main" }}>
              <CloudSync fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary="Sync mode" primaryTypographyProps={{ fontStyle: isModeMissing ? "italic" : "unset", color: isModeMissing ? "grey.400" : "inherit" }}>{setting?.syncMode ?? "Not Set"}</ListItemText>
        </ListItem> */}
        
        <Divider variant="middle" sx={{ my: 1 }}/>
        
        <ListItem disablePadding secondaryAction={
          <Tooltip title="Sign out of OneDrive">
            <IconButton edge="end" color="primary" onClick={handleSignOut}>
              <Logout fontSize="small" />
            </IconButton>
          </Tooltip>
        }>
          <ListItemAvatar color="primary.main">
            <Avatar sx={{ bgcolor: "primary.main" }}>
              <AccountBox fontSize="small" />
            </Avatar>
          </ListItemAvatar>
          <ListItemText secondary="OneDrive account" sx={{ fontSize: "1rem" }}>{msalAccount?.username}</ListItemText>
        </ListItem>                  

      </List>
    </Grid>
  );
};

export default OneDriveSettings;