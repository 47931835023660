import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Alert, Typography } from "@mui/material";
import { useCommentOwner } from "helpers/selector-hooks-ts";
import { useAppContext } from "sections/app/app-context";
import { ConversationProvider, useConversationContext } from "components/values-table/conversation-context";
import { CommentHistory, CommentInput } from "components/conversations";

const MyConversationComponent = () => {
  const { isReviewer } = useAppContext();
  const { addComment, comments, topic } = useConversationContext();
  const addOwner = useCommentOwner();
  const { shareId, topicId, topicType, documentId } = useParams();
  const clientId = useSelector<any, string>(state => state.attorney.currentClientId);

  const formUrl = useMemo(() => {
    if(topicType !== "f") return "";
    if(isReviewer) return `/app/clients/${clientId}/forms/${topicId}`;
    else return `/app/${shareId}/forms/${topicId}/review`;    //for a user
  }, [isReviewer, shareId, clientId, topicId, topicType])
  
  //For a form, only show comments that are not associated with a field
  const filteredComments = useMemo(() => {
    let items = [];
    if(topic?.id !== topicId && topic?.id !== documentId) return [];   //to prevent showing stale comments, before reducer is updated
    if(topicType === "f") items = comments.filter(c => !c.fieldKey);
    else items = comments;
    return items.map(i => ({...i, ...addOwner(i)}));

  }, [comments, topicType, topic, topicId, documentId, addOwner]);

  const handleCommentSaved = async (content: string) => {
    const result = await addComment(content);
    return result.isOk;
  }

  //To account for the alert displayed for forms
  const historyHeightOffset = topicType === "f" ? 210 : 160;

  return (
    <Grid id="my-conversation" container width="100%" rowGap={2} sx={{ p: 2, pr: 0, position: "relative", height: "100%", overflowY: "hidden" }} >
      {topicType === "f" && (
        <Alert severity="info" sx={{ width: "95%", mt: -2 }}>
          <Typography fontSize="1.1rem" sx={{ mt: -0.5 }}>
            To see comments for a specific field, <Typography component={Link} to={formUrl} color="secondary.dark" fontSize="1.1rem">click here</Typography>.
          </Typography>
        </Alert>
      )}
      <CommentHistory comments={filteredComments ?? []} sx={{ height: `calc(100% - ${historyHeightOffset}px)` }} />
      <CommentInput onCommentSaved={handleCommentSaved} rows={3} />
    </Grid>
  );
};

const MyConversation = () => {
  return (
    <ConversationProvider>
      <MyConversationComponent />
    </ConversationProvider>
  );
};

export default MyConversation;