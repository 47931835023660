import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { Header } from 'sections/financials/layout';
import AdminNav from './admin-nav';
import { initialize } from 'store/actions/app-actions';
import { LoadingContainer } from 'components';
import DashboardView from "./dashboard/dashboard-view";
import WaitlistView from './waitlist/waitlist-view';
import UsersView from './users/users-view';
import FeedbackView from './feedback/feedback-view';
import AuditLogView from './auditlog/auditlog-view';
import InvitationsView from './invitations/invitations-view';

const AdminView = () => {
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const isInitialized   = useSelector(state => state.app.isInitialized);
  const isAuthed        = useSelector(state => state.app.isAuthenticated);

  useEffect(() => {
    if(!isInitialized){
      async function init(){
        await dispatch(initialize());
      }
      init();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  

  return (
    <Grid id="admin-view" container justifyContent="center" className={classes.adminRoot}>
      <Grid item container className={classes.adminHeader} >
        <Header />
      </Grid>
      <Grid item container className={classes.adminBody}>
        <Grid item className={classes.navCol}>
          <AdminNav/>
        </Grid>
        <Grid item container alignItems="flex-start" className={classes.contentWrapper}>
          {!isAuthed && <LoadingContainer isVisible={isInitialized} style={{width: "100%"}}/>}
          {isAuthed && 
            <Routes>
              <Route path="/*" exact element={<DashboardView />} />
              <Route path="users" exact element={<UsersView />} />
              <Route path="invitations" element={<InvitationsView />} />
              <Route path="feedback" element={<FeedbackView />} />
              <Route path="waitlist" element={<WaitlistView />} /> 
              <Route path="auditlog" element={<AuditLogView />} />
            </Routes>
          }
        </Grid>
      </Grid>

    </Grid>
  );
}

export default AdminView;

const buildStyles   = makeStyles(theme => ({
  adminRoot  : {
    // padding     : theme.spacing(1),
  },
  adminBody  : {
    flexWrap    : "nowrap",
  },
  contentWrapper  : {
    position        : "relative",
    paddingTop      : theme.spacing(2),
    paddingLeft     : theme.spacing(2),
    minHeight          : "calc(100vh - 60px)",
    overflowY       : "auto",
    overflowX       : "hidden",
  },
}));