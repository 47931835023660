import { useSelector } from "react-redux";
import { isUndefined } from "lodash";
import { FormField, SameAsConfig } from "types";
import { useAppContext } from "sections/app/app-context";
import { useEditorValue } from "sections/forms/form-editor-context";
import { sameAsFlag } from "helpers/model-helpers";

export function useAttorneyFormValue(fieldId: string, sectionId?: string, rowIndex?: number, defaultValue?: any) {
  const isRowValue = !isUndefined(rowIndex) && sectionId;
  const valueKey = isRowValue ? sectionId : fieldId;
  
  let itemValue: any = undefined;
  let originalValue = useSelector<any, any>(state => state.attorney.clientData.values[valueKey]);
  if(isRowValue){
    originalValue = originalValue ?? [ {} ];
    itemValue = originalValue ? originalValue[rowIndex] : undefined;
    itemValue = itemValue ? itemValue[fieldId] : undefined;
  }
  else itemValue = originalValue ?? undefined;

  // let itemValue = (originalValue && isRowValue) ? (originalValue[rowIndex][fieldId]) : originalValue;
  
  if(itemValue === undefined && defaultValue !== undefined) itemValue = defaultValue;
  
  return [valueKey, itemValue];
}

export function useFormValue(fieldId: string, sectionId?: string, rowIndex?: number, defaultValue?: any) {
  const { isReviewer } = useAppContext();
  const isRowValue = !isUndefined(rowIndex) && sectionId;
  const valueKey = isRowValue ? sectionId : fieldId;
  
  let itemValue: any = undefined;
  const valueSelector = isReviewer ? (state: any) => state.attorney.clientData?.values[valueKey] : (state: any) => state.values[valueKey];
  let originalValue = useSelector<any, any | undefined>(valueSelector);
  
  // let originalValue = useSelector<any, any>(state => state.attorney.clientData.values[valueKey]);
  if(isRowValue){
    originalValue = originalValue ?? [ {} ];
    itemValue = originalValue ? originalValue[rowIndex] : undefined;
    itemValue = itemValue ? itemValue[fieldId] : undefined;
  }
  else itemValue = originalValue ?? undefined;

  // let itemValue = (originalValue && isRowValue) ? (originalValue[rowIndex][fieldId]) : originalValue;
  
  if(itemValue === undefined && defaultValue !== undefined) itemValue = defaultValue;
  
  return [valueKey, itemValue];
}

//TODO: add support for sectionId and rowIndex
export function useFormSameAs(node: SameAsConfig) {
  const flagId = sameAsFlag(node);
  let [, flagValue] = useFormValue(flagId);
  let [, sameAsValue] = useFormValue(node.sameAs ?? "undefined");
  
  const tempFlagValue = useEditorValue(flagId);   //check for temporary changes in the form editor
  const tempValue = useEditorValue(node.sameAs ?? "undefined");

  const flagV = tempFlagValue === undefined ? flagValue : tempFlagValue;
  const sameAsV = tempValue === undefined ? sameAsValue : tempValue;

  const sameAsLabel = node.sameAsLabel ?? "Same as Client 1";

  return [flagV, sameAsV, sameAsLabel];
}

// export function useSameAs(node: SameAsConfig) {
//   let isSameAs = false;
//   let sameAsValue: any = undefined;
//   let sameAsLabel = node.sameAsLabel ?? "Same as Client 1";

//   //TODO: make this support either attorney or client values
//   const values = useSelector<any, any>(state => state.attorney.clientData.values);
//   const sameAs = node.sameAs;
//   if(!!sameAs){
//     const sameAsFieldId = sameAsFlag(node);
//     isSameAs = !!values[sameAsFieldId];
//     if(isSameAs){
//       sameAsValue = values[sameAs];
//     }
//   }

//   return [isSameAs, sameAsValue, sameAsLabel];  
// }