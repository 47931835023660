import { useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import { Box, Button, ButtonGroup, Divider, ListItemIcon, ListItemText, MenuItem, MenuList, Popover } from "@mui/material";
import { Home as HomeIcon, ArrowDropDown as ArrowDropDownIcon, ContentPaste as FormIcon, FilePresent, CheckOutlined, CloudUploadOutlined, ForumOutlined } from "@mui/icons-material";
import { FormAssignment } from "types";
import { AssignedDocument } from "types/client-document-types";
import { useAnchor } from "helpers/hooks";
import { selectPetitionerDocs, selectPetitionerForms, selectPetitionerUploads } from "store/selectors/petitioner-selectors";

const CustomerNavButton = () => {
  const { shareId, documentId } = useParams();
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor();
  const { pathname } = useLocation();
  const isConversations = pathname.includes("app/conversations");
  const forms = useSelector<any, FormAssignment[]>(selectPetitionerForms);
  const docs = useSelector<any, AssignedDocument[]>(selectPetitionerDocs);
  const uploads = useSelector<any, AssignedDocument[]>(selectPetitionerUploads);

  return (
    <Box>
      <ButtonGroup variant="outlined" size="small" aria-label="Home button group" ref={anchor} sx={{ height: 38.34}}>
        <Button component={Link} to="/app" title="Home" size="small">
          <HomeIcon fontSize="small" />
        </Button>
        {/* <Button component={Link} to="/app/conversations" title="Conversations" size="small">
          <ForumOutlined fontSize="small" />
        </Button> */}
        <Button size="small" onClick={onOpen} aria-haspopup="menu">
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popover open={isOpen} onClose={onClose} anchorEl={anchor} {...position}>
        <MenuList id="split-button-menu" autoFocusItem>
          
        <MenuItem onClick={onClose} component={Link} to={"/app/conversations"} disabled={isConversations}>
          <ListItemIcon><ForumOutlined fontSize="small" /></ListItemIcon>
          <ListItemText>All Conversations</ListItemText>
          {isConversations && <ListItemIcon><CheckOutlined fontSize="small" /></ListItemIcon>}
        </MenuItem>
        <Divider />
          
          {forms.map(form => {
            return (
              <MenuItem key={form.id} onClick={onClose} component={Link} to={`/app/${shareId}/forms/${form.id}`} disabled={form.id === documentId}>
                <ListItemIcon><FormIcon fontSize="small" /></ListItemIcon>
                <ListItemText>{form.formName}</ListItemText>
                {form.id === documentId && <ListItemIcon><CheckOutlined fontSize="small" /></ListItemIcon>}
              </MenuItem>
            )
          })}
          <Divider />
          {docs.map(doc => {
            return (
              <MenuItem key={doc.id} onClick={onClose} component={Link} to={`/app/${shareId}/documents/${doc.id}`} disabled={doc.id === documentId}>
                <ListItemIcon><FilePresent fontSize="small" /></ListItemIcon>
                <ListItemText>{doc.name}</ListItemText>
                {doc.id === documentId && <ListItemIcon><CheckOutlined fontSize="small" /></ListItemIcon>}
              </MenuItem>
            )
          })}
          <Divider />
          {uploads.map(doc => {
            return (
              <MenuItem key={doc.id} onClick={onClose} component={Link} to={`/app/${shareId}/documents/${doc.id}`} disabled={doc.id === documentId}>
                <ListItemIcon><CloudUploadOutlined fontSize="small" /></ListItemIcon>
                <ListItemText>{doc.name}</ListItemText>
                {doc.id === documentId && <ListItemIcon><CheckOutlined fontSize="small" /></ListItemIcon>}
              </MenuItem>
            )
          })}
        </MenuList>
      </Popover>
    </Box>
  );
};

export default CustomerNavButton;