import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormField, FormAssignment, FormRegion } from "types";
import { useStatus } from "redux-action-status";
import { StatusKeys } from "helpers/status-keys";
import { selectClientForm } from "store/selectors/attorney-selectors";
import { downloadFormConfig } from "store/actions/share-actions";
import LEGACY_FIELDS from "../../config/fields-config";
import Loading from "components/loading";

//=== 
// This Context is meant for the attorney when they're working with one of their client's forms
//===

export type IClientFormContext = {
  documentId: string | null;
  form: FormAssignment | null;
  schema: any;
  regions: FormRegion[];
  formFields: FormField[];
  clientStatus: any | null;
  getRegionStatus: (regionId: string) => boolean;
  getFormField: (fieldId: string) => FormField | undefined;
}

export const ClientFormContext = createContext<IClientFormContext>({
  documentId: null,
  form: null,
  schema: {},
  regions: [],
  formFields: [],
  clientStatus: null,
  getRegionStatus: () => false,
  getFormField: () => undefined,
});

//=== Hook for using ClientFormContext
export const useClientForm = () => {
  const context = useContext(ClientFormContext);
  if(!context) throw new Error("useClientFormContext must be used within a ClientFormProvider");
  return context;
}

export const ClientFormProvider = ({ children }: { children: ReactNode }) => {
  const dispatch = useDispatch();
  const { documentId = null } = useParams();
  const status = useStatus(StatusKeys.formContext);

  const form = useSelector<any, FormAssignment | null>(state => selectClientForm(state, documentId ?? "default"));
  const schema = useMemo(() => form?.config?.schema ?? null, [form]);
  const regions = useMemo<FormRegion[]>(() => {
    const regionsMap = schema?.layout?.regions ?? schema?.regions ?? null;
    if(!regionsMap) return [];
    return Object.keys(regionsMap).map(key => regionsMap[key]);
  }, [schema]);

  //The metadata (including status) for the client's form from the client's data
  const clientFormsMeta = useSelector<any, any[]>(state => state.attorney.clientData?.forms);
  const clientFormMeta = useMemo(() => clientFormsMeta?.find(form => form.id === documentId), [clientFormsMeta, documentId]);
  
  const formFields = useMemo<FormField[]>(() => {
    if(!schema) return [];
    const version = schema.version ?? "1.0.0";
    const allFields: FormField[] = version === "1.0.0" ? LEGACY_FIELDS : schema.fields;
    return allFields;
  }, [schema]);

  //Load the configuration for the current form
  useEffect(() => {
    async function refreshConfig(){
      //as the attorney, need to download the *client* form config so it goes to the right place (shares/{shareId}/forms/{formId})
      //TODO: look at unifying these so both attorney and petitioner look in the shares/{shareId}/forms/{formId} path
      if(!form) return;   //unreachable
      await dispatch(downloadFormConfig(form));
    }

    if(!status.isWorking && (form && !form.config && !form.configError)) {
      refreshConfig();
    }
  }, [status.isWorking, form, dispatch]);

  const getRegionStatus = useCallback((regionId: string) => {
    if(!clientFormMeta?.status) return false;
    const status = clientFormMeta.status[regionId];
    return status ?? false;
  }, [clientFormMeta]);

  const getFormField = useCallback((fieldId: string) => {
    const field = formFields.find(f => f.id === fieldId);
    if(!field){
      console.warn("Field not found in client form context", fieldId);
    }
    return field;
  }, [formFields]);

  const isReady = useMemo(() => !!regions && !!formFields, [regions, formFields]);

  const providerValue = useMemo<IClientFormContext>(() => ({
    documentId,
    form,
    schema,
    regions,
    formFields,
    clientStatus: clientFormMeta,
    getRegionStatus,
    getFormField,
  }), [documentId, form, schema, regions, formFields, clientFormMeta, getRegionStatus, getFormField]);

  return (
    <ClientFormContext.Provider value={providerValue}>
      {!isReady && <Loading isVisible={!isReady} />}
      {isReady && children}
    </ClientFormContext.Provider>
  )
}