import { AddCommentOutlined, Comment } from "@mui/icons-material";
import { TableCell, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useFieldComments } from "components/values-table/conversation-context";

export interface ICommentCellProps {
  isHovered?: boolean;
  hasComment?: boolean;
  onClick?: () => void;

  sectionId?: string;
  fieldId?: string;
}

const CommentCell = ({ isHovered, hasComment, onClick, sectionId, fieldId }: ICommentCellProps) => {
  const commentInfo = useFieldComments(fieldId ?? sectionId ?? "none");
  const hasUnresolved = commentInfo?.hasComments && !commentInfo?.isResolved;

  return (
    <TableCell className="comment-cell" align="center" sx={{ padding: "2px !important", width: 24 }}>
      <Grid container height="100%" justifyContent="center" alignItems="center">
        {(isHovered && !hasUnresolved) && (
          <Tooltip title="Add a comment or ask a question" arrow placement="top">
            <AddCommentOutlined onClick={onClick} color="info" sx={{ fontSize: 18, cursor: "pointer" }} />
          </Tooltip>
        )}
        {hasUnresolved && (
          <Tooltip title="View comments for this field" arrow placement="top">
            <Comment onClick={onClick} color="info" sx={{ fontSize: 18, cursor: "pointer" }} />
          </Tooltip>
        )}

      </Grid>
    </TableCell>
  );
};

export default CommentCell;