import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga';

export function trackEvent(event, props){
  const eData   = props ? {...event, ...props} : event;
  ReactGA.event(eData);
}

export function TrackUser(user){
  ReactGA.set({
    userId  : user ? user.uid : null,
  });
}

export const Events = {
  log_in            : {category: "auth", action: "user signed in"},
  log_out           : {category: "auth", action: "user signed out"},

  location_voted    : {category: "pre-release",  action: "voted for a state" }, 
  waitlist_joined   : {category: "pre-release", action: "joined waitlist"},
  invitation_entered: {category: "pre-release", action: "invitation validated"},

  location_changed  : {category: "setup", action: "changed location" },
  choose_local      : {category: "setup", action: "choose local" },
  choose_remote     : {category: "setup", action: "choose remote" },
  agree_tos         : {category: "setup", action: "agree to TOS"},
  agree_safety      : {category: "setup", action: "agree to Safety"},
  
  resume_work       : {category: "flow", action: "resumed working"},
  start_over        : {category: "flow", action: "started over"},
  // open_existing     : {category: "flow", action: "opened existing file"},
  review_confirm    : {category: "flow", action: "confirmed data"},
  skip_download     : {category: "flow", action: "skipped download"},

  download          : {category: "data", action: "download data"},
  open_existing     : {category: "data", action: "opened existing file"},
  export            : {category: "data", action: "export doc"},
  move_data_server  : {category: "data", action: "move data data to server"},
  move_data_client  : {category: "data", action: "move data data to client"},
  delete_data       : {category: "data", action: "delete data"},

  convo_created     : {category: "convo", action: "create a new conversation"},
  com_created       : {category: "convo", action: "create a new comment"},

  invite            : {category: "share", action: "invite a user"},

  form_created: {category: "form", action: "create a new form"},
  assignment_created: {category: "form", action: "created a new assignment relationship"},
  forms_assigned: {category: "form", action: "assign forms to a user"},
}


const trackPageView = (location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
  console.debug("GA|Pageview Sent: ", location.pathname);
}

const getGAConfig = () => {
  return {
    debug     : false, //(window.location.href.indexOf("localhost") >= 0),
    // NOTE: Below may be necessary if not getting any page timings data (https://github.com/react-ga/react-ga)
    // gaOptions : {
    //   siteSpeedSampleRate   : 100,
    // },
  }
}

export const AnalyticsListener = ({ children, trackingId }) => {
  const location = useLocation();

  // useEffect(() => {
  //     if (trackingId) {
  //         const gaConfig    = getGAConfig();
  //         ReactGA.initialize(trackingId, gaConfig);
  //         trackPageView(location, 'REPLACE');
  //         return location(trackPageView);
  //     }
  // }, [location, trackingId]);

  return children;
}