import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { selectProfile } from 'store/selectors/app-selectors';
import ProviderView from '../collaborator/provider-view';
// import FinancialsView from '../financials/financials-view';
import PetitionerView from '../../sections/petitioner/petitioner-view';

const AppView = () => {
  const classes   = buildStyles();
  const profile   = useSelector(selectProfile);

  return (
    <Grid id="app-view" container justifyContent="center" className={classes.appViewroot}>
      {profile.isReviewer && <ProviderView />}
      {profile.isUser && <PetitionerView />}
    </Grid>
  );
}

export default AppView;

const buildStyles   = makeStyles(theme => ({
  appViewroot  : {
    // padding     : theme.spacing(1),
  },
  
}));