import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormAssignment, FormRegion } from "types";
import { useStatus } from "redux-action-status";
import { ConfirmDialog, LoadingBar } from "components";
import { selectPetitionerForm } from "store/selectors/petitioner-selectors";
import { saveDataLocal, saveDataToServer, updateFormStatus, updateFieldValue, submitForm } from "store/actions/value-actions";
import { selectCurrentTopic } from "store/selectors/conversation-selectors";
import { chooseConversation } from "store/actions/conversation-actions";
// import { StatusKeys } from "helpers/status-keys";
// import { downloadFormConfig } from "store/actions/share-actions";
import { ShareProvider } from "../../app/share-context";
import { useFormSideEffects } from "./form-side-effect-utils";
import { chooseForm } from "store/actions/petitioner-actions";
import { Stack, Typography } from "@mui/material";

//=== 
// This Context is meant for the petitioner when they're filling out a forms.
//===

export type IPetitionerFormContext = {
  documentId: string;
  form: FormAssignment;
  schema: any;
  regions: FormRegion[];
  values: any;
  isReady: boolean;  
  onValueChanged: (regionId: string, sectionId: string, fieldId: string, value: any, details: any, index: any) => void;
  saveData: () => Promise<void>;
  conversation: any;
  submitForm: () => void;
  hasSummary: boolean;
};

export const defaultPetitionerContext = {
  documentId: "",
  form: null,
  schema: null,
  regions: [],
  values: {},
  isReady: false,
  onValueChanged: () => {},
  saveData: async () => {},
  conversation: null,
  submitForm: () => {},
  hasSummary: false,
};

export const PetitionerFormContext = createContext<IPetitionerFormContext | null>(null);

//not exported because we're wrapping it with the ShareProvider below.
//TODO: update to leverage the share provider
const PetitionerFormProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [isReady, setIsReady] = useState<boolean>(false);
  const [isConfirming, setIsConfirming] = useState<boolean>(false);
  // const { isWorking } = useStatus(StatusKeys.formContext);
  
  //Get the form, and it's regions
  const form = useSelector<any, FormAssignment | any>(state => selectPetitionerForm(state, documentId ?? "default"));
  const schema = useMemo(() => form?.config?.schema ?? null, [form]);
  const regions = useMemo<FormRegion[]>(() => {
    const regionsMap = schema?.layout?.regions ?? schema?.regions ?? null;
    if(!regionsMap) return [];
    return Object.keys(regionsMap).map(key => regionsMap[key]);
  }, [schema]);

  const hasSummary = useMemo(() => regions.some(r => r.type === "summary"), [regions]);

  //Get the conversation
  const convoStatus = useStatus("conversations");
  const conversation = useSelector<any, any>(selectCurrentTopic);

  //Side Effects are how we handle form fields that affect other form fields.
  const sideEffects = useFormSideEffects(schema, regions);

  //TODO: Need to remove this so the form doesn't re-render every time a value changes.
  const values = useSelector((state: any) => state.values);

  //NOTE: This is removed because the FormContext loads the config, and wraps this context.
  //Load the configuration for the current form
  // useEffect(() => {
  //   async function refreshConfig(){
  //     //as the petitioner, need to download the *petitioner* form config so it goes to the right place (petitioner/forms)
  //     await dispatch(downloadFormConfig(form));
  //   }

  //   if(!isWorking && (form && !form.config && !form.configError)) {
  //     refreshConfig();
  //   }
  // }, [isWorking, form, dispatch]);

  //Load the conversation for the current form
  useEffect(() => {
    if(convoStatus.isInitialized && form?.shareId) {
      dispatch(chooseConversation(form.shareId, form.id, "f"));
    }
  }, [form?.shareId, form?.id, convoStatus.isInitialized, dispatch]);

  //Indicate we are ready to render the form
  useEffect(() => {
    if(form && form.config && regions.length > 0 && values) {
      setIsReady(true);
      dispatch(chooseForm(form.id));
    }
  }, [form, regions, values, dispatch]);

  const onValueChanged = useCallback((regionId: string, sectionId: string, fieldId: string, value: any, details: any, index: any) => {
    
    let isManual = false;
    if(index !== undefined && index !== null && index >= 0){
      const section = regions.find(r => r.id === regionId)?.sections.find(s => s.id === sectionId);
      isManual = (section?.type === "list" && section?.subtype === "table");
    }

    dispatch(updateFieldValue(regionId, sectionId, fieldId, value, details, index, isManual));

    if(sideEffects[fieldId]){
      const updateAction = (updField: string, updVal: any) => dispatch(updateFieldValue(regionId, sectionId, updField, updVal, details, index));
      sideEffects[fieldId](null, value, updateAction);
    }  
  }, [dispatch, sideEffects]);

  const onSaveData = useCallback(async () => {
    console.debug("saving data");
    await dispatch(saveDataLocal());
    await dispatch(saveDataToServer());
    await dispatch(updateFormStatus(documentId!));
  }, [dispatch, documentId]);

  const onSubmitForm = useCallback(async () => {
    if(!documentId) return;

    if(!isConfirming) return setIsConfirming(true);
    else {
      const result = await dispatch(submitForm(documentId)) as any;
      if(result.isOk){
        toast("Form successfully submitted", { type: "success" });
        navigate("/app");
      }
      // console.log("submitting form");
      setIsConfirming(false);
    }
    
  }, [dispatch, documentId, navigate, isConfirming]);

  const providerValue = useMemo<IPetitionerFormContext>(() => ({
    documentId: documentId!,
    form,
    schema,
    regions,
    values,
    conversation,
    isReady,
    onValueChanged,
    saveData: onSaveData,
    submitForm: onSubmitForm,
    hasSummary,
  }), [documentId, form, schema, regions, hasSummary, values, conversation, isReady, onValueChanged, onSaveData, onSubmitForm]);

  return (
    <PetitionerFormContext.Provider value={providerValue}>
      {!isReady && <LoadingBar />}
      {isReady && children}
      <ConfirmDialog
        open={isConfirming}
        title="Submit Form"
        maxWidth="sm"
        onConfirm={onSubmitForm}
        onClose={() => setIsConfirming(false)}
        confirmButtonLabel="Submit"        
      >
        <Stack>
          <Typography mb={1}>Take a moment to review your details before submitting to ensure everything's in order for your attorney. <strong>When you're ready, hit submit.</strong></Typography>
          {/* <Typography mb={1}>You may want to do a quick review to make sure you have provided all of the supporting information for your attorney.</Typography>
          <Typography mb={1}>If you are confident you have provided all of the required information, please submit.</Typography> */}
          <Typography mb={1}>Remember, you can always discuss the submitted information further with your attorney.</Typography>
        </Stack>
      </ConfirmDialog>
    </PetitionerFormContext.Provider>
  );
};

//Wrapper around hte PetitionerFormProvider to provide the ShareProvider
const ContextWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <ShareProvider>
      <PetitionerFormProvider>
        {children}
      </PetitionerFormProvider>
    </ShareProvider>
  );
};

export default ContextWrapper;