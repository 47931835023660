import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Grid from "@mui/material/Unstable_Grid2/Grid2"
import { Box, Stack, Tab, Tabs, Typography, useTheme } from "@mui/material"
import { BadgeOutlined, CreditCard, PeopleOutline, PinDropOutlined, SettingsOutlined } from "@mui/icons-material";
import { ErrorStatus } from "components";
import { StatusKeys } from "helpers";
import { loadAccount } from "store/actions/account-actions";
import { useNavigate, useParams } from "react-router-dom";
import AccountProfileCard from "./account-profile-card";
import AccountContactCard from "./account-contact-card";
import AccountSubscriptionCard from "./account-subscription-card";
import AccountSettingsCard from "./account-settings-card";
import AccountMemberList from "./account-member-list";

const PracticeAccountView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { tab } = useParams();
  const tabIndex = tabNames.indexOf(tab?.toUpperCase() ?? "PROFILE");

  useEffect(() => {
    async function load(){
      const result = await dispatch(loadAccount());
      console.log("loadAccountApi result", result);
    };
    load();
  }, [dispatch]);

  const onTabChange = (event: any, newValue: number) => {
    // setTabIndex(newValue);
    if(newValue === 0) return navigate(`/app/account`);
    const newTab = tabNames[newValue].toLowerCase();    
    return navigate(`/app/account/${newTab}`, { replace: true });
  };

  return (
    <Grid id="basic-account-view" container sm={12} alignContent="flex-start" p={1}>
      {/* Header */}
      <Grid container sm={12} alignItems="flex-start">
        
        <Grid sm={9} container alignContent="flex-start">
          <Stack>
            <Typography variant="h6" color="primary">My Account</Typography>
            {/* <Typography variant="body1" fontWeight="600">Small Practice Account</Typography> */}
            <Typography variant="body2" color="grey.500">Manage your practice, team and your practice's public profile.</Typography>
          </Stack>
        </Grid>
        <Grid sm={3} container alignItems="center" justifyContent="center">
          <Box sx={{ p: 1, border: "0.5px solid", borderColor: "grey.300", borderRadius: "50%", height: 90, width: 90, lineHeight: 90, display: "flex", alignItems:"center", justifyContent: "center" }}>
            <Box sx={{ bgcolor: theme.palette.primary.main, borderRadius: "50%", height: 76, width: 80, display: "flex", alignItems:"center", justifyContent: "center"  }}>
              <PeopleOutline sx={{ fontSize: "3.5rem", color: theme.palette.primary.contrastText }}/> 
            </Box>
          </Box>
        </Grid>      

      </Grid>

      <ErrorStatus statusKey={StatusKeys.accounts} sx={{ my: 2, width: "100%" }}/>

      {/* Body Card */}
      <Grid container sm={12}>
        <Box width="100%">
          <Tabs value={tabIndex ?? 0} onChange={onTabChange} role="navigation">
            <Tab label="Practice Profile" value={0} icon={<BadgeOutlined />} iconPosition="start" />
            <Tab label="Team" value={1} icon={<PeopleOutline />} iconPosition="start"/>
            <Tab label="Contact Information" value={2} icon={<PinDropOutlined />} iconPosition="start" />
            <Tab label="Settings" value={3} icon={<SettingsOutlined />} iconPosition="start" />
            <Tab label="Subscription" value={4} icon={<CreditCard /> } iconPosition="start" />
          </Tabs>
        </Box>
      </Grid>

      {/* <PracticeAccountRouter /> */}
      { tabIndex === 0 && <AccountProfileCard /> }
      { tabIndex === 1 && <AccountMemberList /> }
      { tabIndex === 2 && <AccountContactCard /> }
      { tabIndex === 3 && <AccountSettingsCard /> }
      { tabIndex === 4 && <AccountSubscriptionCard /> }

    </Grid>
  );
};

export default PracticeAccountView;

const tabNames = ["PROFILE", "TEAM", "CONTACT", "SETTINGS", "SUBSCRIPTION"];