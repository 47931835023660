import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { IconWithBadge } from 'components';
import { useInterval } from 'helpers/hooks';
import { useProfile } from 'helpers/selector-hooks';
import { loadMyConversations } from 'store/actions/conversation-actions';
import { useAppContext } from 'sections/app/app-context';
import { useMemo } from 'react';

const SAVE_INTERVAL  = 30*1000; //60000;    //auto-check every 30 seconds...

const ConversationsButton = () => {
  const dispatch        = useDispatch();
  const { allowComments } = useAppContext();
  const profile         = useProfile();
  const classes         = buildStyles();
  const count = useSelector(state => state.convo.dirty?.length ?? 0);
  const url = profile.isUser ? "/app/conversations" : "/app";
  
  const interval = useMemo(() => (allowComments && profile.isAuthenticated) ? SAVE_INTERVAL : null, [allowComments, profile.isAuthenticated]);

  //an effect that will run a timer and save the data to the database automatically every 60 seconds
  useInterval(() => {
    console.log("refreshing conversations.");
    if(profile?.isAuthenticated) dispatch(loadMyConversations());
  }, interval);

  if(!allowComments) return null;

  const tip   = count ? `You have ${count} conversation${count > 1 ? "s" : ""} with new messages.` : "You have no new messages"; //`
  
  return (
    <Tooltip title={tip}>
      <IconButton
        component={Link}
        to={url}
        className={classes.menuButton}
        size="large">
        <IconWithBadge count={count} badgeColor="secondary" fontSize="small" icon="forum" classes={{badge: classes.badge}}/>
      </IconButton>
    </Tooltip>
  );
}

export default ConversationsButton;

const buildStyles   = makeStyles(theme => ({
  menuButton   : {
    marginLeft      : theme.spacing(1),
    color         : theme.palette.grey[400],
    border        : `1px solid ${theme.palette.grey[300]}`,
    padding       : theme.spacing(0.5),
    "&:hover"   : {
      color         : theme.palette.secondary.main,
      background    : theme.palette.grey[200],
    },    
  },
  badge   : {
    fontSize    : 12,
  }
}));