import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { isString } from "lodash";
import { useMeasure } from "react-use";
import { Chip, TableCell, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CloudUploadOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { ClientDocument, RowItem } from "types";
import { formatDateString, formatShortDate } from "helpers/general-helpers";
import { useClient } from "../../client-context";
import { useAppContext } from "sections/app/app-context";
import { useAttorneyContext } from "../../attorney-context";
import { ClientAssignmentChip } from "../chips";
import { updateClientDocument } from "store/actions/attorney-actions";


export interface IClientUploadItemProps {
  item: RowItem<ClientDocument>;
  onChecked: (e: any) => void;
  onSelected: (e: any) => void;
  onHover?: (isEntering: boolean) => (e: any) => void;
  
}

const ClientUploadItemContainer = ({ item, onSelected, onChecked, onHover }: IClientUploadItemProps) => {
  const { requestedAt, filePath } = item;
  const isRequest = !!requestedAt && !filePath;
  const Component = isRequest ? ClientUploadRequestItem : ClientUploadItem;
  return <Component item={item} onSelected={onSelected} onChecked={onChecked} onHover={onHover} />;
}

export default ClientUploadItemContainer;

const ClientUploadItem = ({ item, onSelected, onChecked, onHover }: IClientUploadItemProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { uid } = useAppContext();
  const { members } = useAttorneyContext();
  const [cellRef, { width: cellWidth } ] = useMeasure();  
  const { clientId } = useClient();
  const nameProps = {component: Link, to: `/app/clients/${clientId}/documents/${item.id}`};
  const lastViewed = item?.viewed ? item.viewed[uid] ?? 0 : 0;
  const isNew = lastViewed < (item.modifiedAt ?? item.createdAt);

  const handleAssign = async (e: any) => {
    let uid   = isString(e) ? e : e.target.value;
    if(uid === "") uid = null;
    //TODO: does this work for client uploads?
    const result: any = await dispatch(updateClientDocument(clientId, item.id, { assignedTo: uid }));
    if(result.isOk){
      toast.success("Client upload assigned successfully");
    }
  };

  return (
    <TableRow key={item.id} sx={{backgroundColor: (item.isSelected ? alpha(theme.palette.primary.light, 0.25) : undefined)}} >
      <TableCell width={36} >
        <CloudUploadOutlined fontSize="small" color="primary" />         
      </TableCell>
      <TableCell onClick={onSelected} ref={cellRef} sx={{ minWidth: "10rem", maxWidth: "28rem" }}>
        <Grid container xs={12} alignItems="center" justifyContent="space-between" >
          <Typography {...nameProps} sx={{ width: cellWidth - 64, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.name}</Typography>
          {isNew && (
            <Tooltip title="You haven't viewed this upload yet">
              <Chip label={"new"} color="primary" size="small" />
            </Tooltip>
          )}
        </Grid>
      </TableCell>
      <TableCell align="center">
        <ClientAssignmentChip assignedTo={item.assignedTo} members={members} onChange={handleAssign} variant="subtle" />
      </TableCell>
      <TableCell align="center">
        <Chip label={item.fileExtension} size="small" />
      </TableCell>
      <TableCell align="right">
        <Typography>{formatDateString(item.requestedAt, "M/D/YY")}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatDateString(item.createdAt, "M/D/YY")}</Typography>
      </TableCell>
    </TableRow>
  );
};


const ClientUploadRequestItem = ({ item, onSelected, onChecked, onHover }: IClientUploadItemProps) => {
  const theme = useTheme();
  const [cellRef, { width: cellWidth } ] = useMeasure();  
  
  return (
    <TableRow key={item.id} sx={{backgroundColor: (item.isSelected ? alpha(theme.palette.primary.light, 0.25) : undefined)}} >
      <TableCell width={36} >
        <Tooltip title="The client hasn't uploaded this document yet">
          <CloudUploadOutlined fontSize="small" sx={{ color: theme.palette.grey[500] }} />         
        </Tooltip>
      </TableCell>
      <TableCell onClick={onSelected} ref={cellRef} sx={{ minWidth: "10rem", maxWidth: "28rem" }}>
        <Grid container xs={12} alignItems="center" justifyContent="space-between" flexWrap="nowrap">
          <Typography sx={{ color: theme.palette.grey[500], width: cellWidth - 64, display: "inline-block", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{item.name}</Typography>          
          <Tooltip title={`You requested this document on ${formatShortDate(item.requestedAt)}`}>
            <Chip label="requested" size="small" color="info" />
          </Tooltip>
        </Grid>
      </TableCell>
      <TableCell />
      <TableCell align="center">
      </TableCell>
      <TableCell align="right">
        <Typography>{formatDateString(item.requestedAt, "M/D/YY")}</Typography>
      </TableCell>
      <TableCell align="right">
        <Typography>{formatDateString(item.createdAt, "M/D/YY")}</Typography>
      </TableCell>
    </TableRow>
  );

};