import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, TextField, Typography, alpha, useTheme } from "@mui/material";
import { StatusKeys } from "helpers";
import { useStatus } from "redux-action-status";
import { MuiFileInput } from "mui-file-input";
import { CheckCircle, Close, CloudUpload, ErrorOutline } from "@mui/icons-material";
import { AssignedDocument } from "types";
import { uploadDocument, uploadRequestedDocument } from "store/actions/share-actions";
import { parseFbResult } from "store/store-helpers";
import { selectPetitionerUploadRequests } from "store/selectors/petitioner-selectors";
import { usePetitionerContext } from "../contexts/petitioner-context";

export interface IPetitionerUploadDialogProps {
  open: boolean;
  onClose: (uploaded: boolean) => void;
  file?: File | null;
  request?: AssignedDocument;
}

type UploadProps = {
  name: string;
  description: string;
  match: string;
  providerId: string;
  file: File | null;
}

const PetitionerUploadDialog = ({open, onClose, file, request}: IPetitionerUploadDialogProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { providers } = usePetitionerContext();
  const requests = useSelector<any, AssignedDocument[]>(selectPetitionerUploadRequests);  
  const [props, setProps] = useState<UploadProps>({name: request?.name ?? "", description: "", providerId: "", match: request?.id ?? "", file: file ?? null});
  const filePlaceholder = props.file ? props.file.name : "Click here to upload document";
  const status = useStatus(StatusKeys.petitioner);
  const canUpload = (props.file && props.name && !status.isWorking);
  const [error, setError]   = useState<string | null>(null);

  const pendingRequests = useMemo(() => {
    return requests.filter(r => !r.filePath);
  }, [requests]);
  
  useEffect(() => {
    if(props.file && !request?.name){
      const fileName = props.file.name;
      const name = fileName.slice(0, fileName.lastIndexOf('.') || fileName.length) ?? "";
      setProps({...props, name});
    }
    else if(!request?.name){
      setProps({...props, name: ""});
    }
  }, []);

  const clearFields = () => {
    setProps({name: "", description: "", providerId: "", match: "", file: null });
    setError("");
  }

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  };

  const handleSelectChange = (key: string) => (e: any) => {
    const val = e.target.value;
    setProps({...props, [key]: val});
  };

  const handleFileChange = (e: File | null) => {
    const name = getFileName(e);
    setProps({...props, name, file: e});
  };

  const handleUpload = async () => {
    console.log("Upload Document", props);
    let shareId: string | null = null;
    if(providers.length === 1) shareId = providers[0].shareId ?? null;
    else {
      const provider = providers.find(p => p.id === props.providerId);
      shareId = provider?.shareId ?? null;
    }

    if(!props.name || !props.file || !shareId){
      console.error("petitioner-upload-dialog: missing required fields", { props, shareId });
      return;
    }

    if(!props.match || props.match === "n/a") handleUploadNew(shareId);
    else handleUploadRequested(shareId);
  }

  const handleUploadNew = async (shareId: string) => {
    const { providerId, ...docProps } = props;
    const uploadProps = { ...docProps, direction: "fromClient", isSharedWithClient: true };
    const uploadResult = await dispatch(uploadDocument(shareId, uploadProps));
    console.log("upload document result", uploadResult);
    const pr = parseFbResult(uploadResult);

    if(pr.isOk){
      clearFields();
      onClose(true);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  }

  const handleUploadRequested = async (shareId: string) => {
    const { providerId, ...docProps } = props;
    const uploadResult = await dispatch(uploadRequestedDocument(shareId, request, docProps));
    console.log("upload requested document result", uploadResult);
    const pr = parseFbResult(uploadResult);

    if(pr.isOk){
      clearFields();
      onClose(true);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  }

  return (
    <Dialog open={open} onClose={() => onClose(false)} fullWidth maxWidth="sm">
      <DialogTitle>Upload Document</DialogTitle>
      <DialogContent>
        <Stack gap={2} width="100%" alignItems="center" justifyContent="center">

          {error && (
            <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <Typography variant="body1" color="grey.600" textAlign="center">Tell your attorney about this document</Typography>

          {providers.length > 1 && (
            <TextField value={props.providerId} onChange={handleSelectChange("providerId")} select disabled={status.isWorking} margin="dense" id="match" label="Who is this for?" type="text" fullWidth>
              {providers.map((provider, idx) => (
                <MenuItem key={idx} value={provider.id}>{provider.name}</MenuItem>
              ))}
            </TextField>
          )}

          <MuiFileInput autoFocus={!file} value={props.file} onChange={handleFileChange} disabled={status.isWorking} placeholder={filePlaceholder}  sx={{ width: "100%", mt: 1, bgcolor: props.file ? alpha(theme.palette.secondary.light, 0.3) : undefined, cursor: "pointer" }}
            clearIconButtonProps={{ children: <Close fontSize="small" /> }}
            InputProps={{ startAdornment: props.file ? <CheckCircle color="secondary" /> : <CloudUpload color="primary"/> }} 
          />
          <TextField autoFocus={!!file} value={props.name} onChange={handleChange} disabled={status.isWorking} margin="dense" id="name" label="Name" required type="text" fullWidth helperText="If applicable, please include the year or the date in the name. E.g. 2023 Tax Returns" />
          <TextField value={props.description} onChange={handleChange}  disabled={status.isWorking} margin="dense" id="description" label="Description" type="text" fullWidth multiline rows={4}/>            
          <TextField value={props.match} onChange={handleSelectChange("match")} select disabled={status.isWorking} margin="dense" id="match" label="Is this a file your attorney requested?" type="text" fullWidth>
            <MenuItem value="n/a">N/A</MenuItem>
            {pendingRequests.map((req, idx) => (
              <MenuItem key={idx} value={req.id}>{req.name}</MenuItem>
            ))}
            {/* <MenuItem value="taxes">Tax Return</MenuItem>
            <MenuItem value="bank">Bank Statement</MenuItem>
            <MenuItem value="pay">Pay Stub</MenuItem>
            <MenuItem value="other">Other</MenuItem> */}
          </TextField>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} disabled={status.isWorking}>Cancel</Button>
        <Button onClick={handleUpload} disabled={!canUpload}>Upload</Button>
      </DialogActions>
    </Dialog>
  )
};

export default PetitionerUploadDialog;

//== HELPERS
const getFileName = (file: File | null) => file?.name.slice(0, file?.name.lastIndexOf('.') || file?.name.length) ?? "";
