import { Typography } from "@mui/material";
import { useFieldChanged } from "sections/forms/form-editor-context";
import FormBooleanField from "./form-boolean-field";
import FormSelectField from "./form-select-field";
import FormTextField from "./form-text-field";
import FormToggleField from "./form-toggle-field";
import FormCurrencyField from "./form-currency-field";
import { IFormFieldInputProps, IFormInputProps } from "./part-types";
import FormNumberField from "./form-number-field";
import { useFormSameAs, useFormValue } from "./part-hooks";
import { getDefaultValue } from "./part-utils";

export const FormInput = ({ field, sectionId, rowIndex, showLabel }: IFormInputProps) => {
  
  const defaultValue = getDefaultValue(field);    //TODO: need to test that this. form-boolean-field is the only one this was implemented in
  const [valueKey, initialValue] = useFormValue(field.id, sectionId, rowIndex, defaultValue);
  const onFieldChanged = useFieldChanged(valueKey, field.id, rowIndex);

  if(field.sameAs) { 

    return (
      <FormInputSameAs 
        field={field} 
        sectionId={sectionId} 
        rowIndex={rowIndex} 
        showLabel={showLabel} 
        initialValue={initialValue} 
        onChanged={onFieldChanged} 
      />
    );
  }

  return (
    <FormItemInput 
      field={field} 
      showLabel={showLabel}
      initialValue={initialValue}
      onChanged={onFieldChanged} 
    />
  );
};

const FormInputSameAs = ({ field, sectionId, rowIndex, showLabel, initialValue, onChanged }: IFormFieldInputProps) => {
  const [isSameAs, sameAsValue] = useFormSameAs(field);
  let dataProps: any = {};
  
  if(isSameAs) {
    dataProps.disabled = true;
  };

  const value = isSameAs ? sameAsValue : initialValue;

  return (
    <FormItemInput 
      field={field} 
      showLabel={showLabel}
      initialValue={value}
      onChanged={onChanged} 
      dataProps={dataProps}
    />
  );
};

export const FormItemInput = ({ field, sectionId, rowIndex, showLabel, initialValue, onChanged, dataProps }: IFormFieldInputProps) => {
  switch(field.type){
    case "text": 
      return <FormTextField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;

    case "select":
      if(field.subtype === "toggle") return <FormToggleField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />
      else return <FormSelectField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;

    case "toggle":
      return <FormToggleField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;

    case "boolean":
    case "checkbox":
    case "switch":
      return <FormBooleanField field={field} showLabel={showLabel} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;
    
    case "currency":
      return <FormCurrencyField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;

    case "number":
      return <FormNumberField field={field} sectionId={sectionId} rowIndex={rowIndex} initialValue={initialValue} onChanged={onChanged} dataProps={dataProps} />;

    case "date":
    // case "number":
      return <Typography color="error">Field type not implemented</Typography>;
  }
  
};
