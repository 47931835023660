import { Box, Divider, Paper, Stack, ToggleButton, ToggleButtonGroup, Tooltip, Typography, alpha, useTheme } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatus } from "redux-action-status";
import { StatusKeys } from "helpers/status-keys";
import { ErrorStatus } from "components";
import { AddCircleOutlineOutlined, Star, StarBorder } from "@mui/icons-material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClientNoteSchema } from "@/types/schema";
import { useClient } from "../../client-context";
import { selectClientNotes } from "store/selectors/attorney-selectors";
import { deleteClientNote, loadClientNotes } from "store/actions/attorney-actions";
import ClientNoteInput from "./client-note-input";
import ClientNoteItem from "./client-note-item";
import { NoteFilters, filterNotes } from "./client-note-helpers";
import SearchField from "components/search-field";
import { useAppContext } from "sections/app/app-context";
import SettingKeys from "helpers/setting-keys";

const ClientNotesCard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { settingValue, saveSetting } = useAppContext();
  const { clientId } = useClient();
  const status = useStatus(StatusKeys.notes);
  const [isAdding, setAdding] = useState(settingValue(SettingKeys.app.notes.isAdding));
  const [editingId, setEditingId] = useState<string | null>(null);
  const [filter, setFilter] = useState<NoteFilters>({ starred: true, unstarred: true, calls: true, search: "" });
  const notes = useSelector<any, ClientNoteSchema[] | null | undefined | undefined>(selectClientNotes);
  const displayedNotes = useMemo(() => filterNotes(notes, filter), [notes, filter]);

  const itemToEdit = useMemo(() => notes?.find(note => note.id === editingId) ?? null, [notes, editingId]);

  useEffect(() => {
    if(notes === undefined && !status.isWorking && !status.error){
      //means we have the client, but notes aren't loaded yet
      dispatch(loadClientNotes(clientId)); //loadNotes();
    }
    else if(notes && notes.length === 0 && !status.isWorking && !status.error){
      //means we have the client, but notes aren't loaded yet
      setAdding(true);  //notes are loaded, but there aren't any, so show the add note section
    }
  }, [clientId, notes, status, dispatch]);

  const beginEditing = (noteId: string) => {
    setEditingId(noteId);
  }
  const finishEditing = (wasSaved: boolean) => {
    setEditingId(null);
  };

  const handleAdding = async () => {
    const nextValue = !isAdding;
    setAdding(nextValue);
    await saveSetting(SettingKeys.app.notes.isAdding, nextValue);
  };

  const handleDelete = useCallback(async (noteId: string) => {
    dispatch(deleteClientNote(clientId, noteId)) as any;
  }, [clientId, dispatch]);

  return (
    <Paper id="client-notes-card" variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: 2}}>
      {status.error && (
        <Box sx={{ width: "100%" }}>
          <ErrorStatus statusKey={StatusKeys.notes} sx={{width: "100%"}}/>
        </Box>
      )}

      {/* Header Grid */}
      <Grid container sm={12} sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderBottomColor: "grey.100", borderRadius: "6px 6px 0 0", px: 2, py: 1 }}>
        <Grid container sm={7} gap={2}>
          
          <Tooltip title="Show / Hide new note panel">
            <ToggleButton value="isAdding" size="small" color="primary" selected={isAdding} onChange={handleAdding} sx={{ py: 0, height: "2.3rem" }}>
              <Stack direction="row" spacing={1} alignItems="center">
                <span>New</span>
                <AddCircleOutlineOutlined sx={{ fontSize: "18px" }} />
              </Stack>
            </ToggleButton>
          </Tooltip>

          <Divider orientation="vertical" />

          <ToggleButtonGroup>
            <Tooltip title="Show / Hide starred notes">
              <ToggleButton value="starred" size="small" color="primary" selected={filter.starred} onChange={() => { setFilter({...filter, starred: !filter.starred}); }} sx={{ py: 0, height: "2.3rem" }}>
                <Star fontSize="small" />
              </ToggleButton>
            </Tooltip>

            <Tooltip title="Show / Hide un-starred notes">
              <ToggleButton value="unstarred" size="small" color="primary" selected={filter.unstarred} onChange={() => { setFilter({...filter, unstarred: !filter.unstarred}); }} sx={{ py: 0, height: "2.3rem" }}>
                <StarBorder fontSize="small" />
              </ToggleButton>
            </Tooltip>
            
          </ToggleButtonGroup>

        </Grid>
        <Grid container sm={5} gap={1} justifyContent="flex-end">
          <Divider orientation="vertical" sx={{ mr: 1 }}/>

          <SearchField placeholder="search notes" onChange={(val) => setFilter({...filter, search: val})} />

        </Grid>
        
      </Grid>

      {/* Info banner */}
      <Grid container xs={12} justifyContent="center" sx={{ bgcolor: alpha(theme.palette.info.light, 0.3), borderTop: "1px solid", borderBottom: "1px solid", borderColor: alpha(theme.palette.info.light, 0.5), px: 2, py: 0.5 }}>
        <Typography fontSize="1rem" fontWeight="600" color="info.dark">These notes are <strong>NOT</strong> shared with the client</Typography>
      </Grid>

      {/* Body Grid */}
      <Grid container xs={12}>
        { (isAdding || !!editingId) && <ClientNoteInput itemToEdit={itemToEdit} onEdited={finishEditing}/> }
        <Box id="notes-scroll-box" sx={{ width: "100%", overflowX: "none", overflowY: "auto", maxHeight: "calc(100vh - 400px)" }}>
          {displayedNotes?.length > 0 && (
            <Stack width="100%" mt={2}>
              {displayedNotes.map(note => <ClientNoteItem key={note.id} note={note} onDelete={handleDelete} onEdit={beginEditing} editingId={editingId} />)}  
            </Stack>
          )}
          { (notes && notes.length > 0 && displayedNotes?.length === 0) && (
            <Typography fontSize="1rem" fontWeight="300" textAlign="center" mt={2}>There are no notes matching the current filters.</Typography> 
          )}
          { notes && notes.length === 0 && (
            <Typography fontSize="1rem" fontWeight="300" textAlign="center" mt={2}>This client doesn't have any notes yet.</Typography>
          )}
        </Box>

      </Grid>
      
    </Paper>
  );
};

export default ClientNotesCard;