import { Link } from "react-router-dom";
import { Box, Button, ButtonGroup, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Popover, Typography } from "@mui/material";
import { Home as HomeIcon, ArrowDropDown as ArrowDropDownIcon, CheckOutlined, CloudUploadOutlined, ForumOutlined, AssignmentTurnedInOutlined, Close } from "@mui/icons-material";
import { useAnchor } from "helpers/hooks";

const MyConversationNavBar = () => {

  return (
    <Box id="document-container-header" sx={ rowSx }>
      <MyConversationsNavButton />

      <Box sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "space-between", gap: 2 }}>
        <Typography variant="h6" color="primary">My Conversations</Typography>
        <IconButton size="small" component={Link} to="/app">
          <Close fontSize="small" />
        </IconButton>
      </Box>

    </Box>
  );
};

export default MyConversationNavBar;


const rowSx = {
  height: 60,
  minHeight: 60,
  borderBottom: "1px solid #dee2e6",
  bgcolor: "white",
  color: "#808080",
  px: 2,
  display: "flex",
  alignItems: "center",
  gap: 2,
  width: "100%",  
};

const MyConversationsNavButton = () => {
  const [anchor, isOpen, onOpen, onClose, position] = useAnchor();
  
  return (
    <Box>
      <ButtonGroup variant="outlined" size="small" aria-label="Home button group" ref={anchor} sx={{ height: 38.34}}>
        <Button component={Link} to="/app" title="Home" size="small">
          <HomeIcon fontSize="small" />
        </Button>
        <Button size="small" onClick={onOpen} aria-haspopup="menu">
          <ArrowDropDownIcon fontSize="small" />
        </Button>
      </ButtonGroup>
      <Popover open={isOpen} onClose={onClose} anchorEl={anchor} {...position}>
        <MenuList id="split-button-menu" autoFocusItem>
          
          {/* <Divider /> */}
          
          <MenuItem onClick={onClose} component={Link} to={"/app"}>
            <ListItemIcon><HomeIcon fontSize="small" /></ListItemIcon>
            <ListItemText>Home</ListItemText>
          </MenuItem>
          <MenuItem onClick={onClose} component={Link} to={"/app/done"}>
            <ListItemIcon><AssignmentTurnedInOutlined fontSize="small" /></ListItemIcon>
            <ListItemText>Done Tasks</ListItemText>
          </MenuItem>
          <MenuItem onClick={onClose} component={Link} to={"/app/uploads"}>
            <ListItemIcon><CloudUploadOutlined fontSize="small" /></ListItemIcon>
            <ListItemText>My Uploads</ListItemText>
          </MenuItem>
          
          <MenuItem onClick={onClose} component={Link} to={"/app/conversations"} disabled={true}>
            <ListItemIcon><ForumOutlined fontSize="small" /></ListItemIcon>
            <ListItemText>My Conversations</ListItemText>
            <ListItemIcon><CheckOutlined fontSize="small" /></ListItemIcon>
          </MenuItem>

        </MenuList>
      </Popover>
    </Box>
  );
}