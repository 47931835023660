import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Divider, IconButton, Paper, Table, TableCell, TableHead, TableRow, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Refresh as RefreshIcon, PostAdd as AddFormIcon, Star, AccountCircle, Work, StarOutline } from '@mui/icons-material';
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { FormSummary } from "types";
import { StatusKeys } from "helpers";
import { tableStyles } from "helpers/style-helpers";
import { SearchField, TBodyWrapper } from "components";
import AddFormDialog from "./add-form-dialog";
import { selectAttorneyForms } from "store/selectors/attorney-selectors";
import FormListItem from "./form-list-item";
import { useRefreshForms } from "../../attorney-hooks";

const FormList = () => { 
  const status = useStatus(StatusKeys.forms);
  const myForms = useSelector(selectAttorneyForms);
  const onRefresh = useRefreshForms();
  const [itemToEdit, setItemToEdit] = useState<FormSummary | undefined>(undefined);
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [oldFilter, setOldFilter] = useState("");
  const [filter, setFilter] = useState({ client: true, attorney: true, starred: true, unstarred: true, search: ""});
  
  // const categories = useMemo(() => {
  //   if(!myForms) return ["all"];
  //   const items = myForms.map(form => form.category?.toLowerCase() ?? "(none)");
  //   const unique = Array.from(new Set(items));
  //   return ["all", ...unique];
  // }, [myForms]);

  const filteredForms = useMemo(() => {
    if(!myForms) return null;
    let working = myForms;

    const { client, attorney, starred, unstarred, search } = filter;
    working = !client ? working.filter(form => !form.isClientForm) : working;
    working = !attorney ? working.filter(form => form.isClientForm) : working;
    working = !starred ? working.filter(form => !form.isStarred) : working;
    working = !unstarred ? working.filter(form => form.isStarred) : working;
    working = search !== "" ? working.filter(form => form.name.toLowerCase().includes(search.toLowerCase())) : working;
    return working;

    // const filtered = myForms.filter(form => (form.category?.toLowerCase() ?? "(none)") === oldFilter);
    // return filtered;
  }, [myForms, filter])
  
  const isEmpty = status.isInitialized && myForms?.length === 0;

  const onSearchChange = (val: string) => {
    setFilter({ ...filter, search: val });
  }

  const handleEdit = (form: FormSummary) => {
    setItemToEdit(form);
    setDialogOpen(true);
  }

  const onClose = (isSaved: boolean) => {
    if(isSaved){
      const message = itemToEdit ? "The form was updated successfully" : "The form was added successfully";
      toast(message, { type: "success" });
    }

    setDialogOpen(false);
    setItemToEdit(undefined);
  }

  return (
    <>
      <Paper id="attorney-forms-card" elevation={0} variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: 2 }}>
        <Grid id="card-content" container direction="column">

          {/* The Header Panel */}
          <Grid id="card-header" container gap={1} alignItems="center" flexWrap="nowrap" sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid", backgroundColor: "grey.200" }}>
            
            <Grid container sm={6} gap={2} alignItems="center">
              <Typography variant="h5" sx={{ fontSize: "1.2rem", fontWeight: "700", color: "grey.800" }}>My Forms</Typography>
              <Divider orientation="vertical" flexItem />
              <Button onClick={() => setDialogOpen(true)} size="small" endIcon={<AddFormIcon fontSize="small" />}>Add a Form</Button>
            </Grid>

            <Grid container sm={6} gap={2} flexWrap="nowrap" alignItems="center" justifyContent="flex-end">
              <Tooltip title="Refresh this list">
                <IconButton size="small" color="secondary" sx={{ float: "right" }} onClick={onRefresh}>
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>

          <Grid id="filter-row" container gap={2} sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid" }}>
            {/* <TabsApple onChange={index => setFilterIndex(index)} tabs={["Active", "Invited", "Archived", "All"]} /> */}
            
            <Grid container gap={2} alignItems="center">
              
              <ToggleButtonGroup>
                <Tooltip title="Show / Hide starred forms">
                  <ToggleButton value="starred" size="small" color="primary" selected={filter.starred} onChange={() => { setFilter({...filter, starred: !filter.starred}); }} sx={{ py: 0, height: "2.3rem" }}>
                    <Star fontSize="small" />
                  </ToggleButton>
                </Tooltip>

                <Tooltip title="Show / Hide unstarred forms">
                  <ToggleButton value="unstarred" size="small" color="primary" selected={filter.unstarred} onChange={() => { setFilter({...filter, unstarred: !filter.unstarred}); }} sx={{ py: 0, height: "2.3rem" }}>
                    <StarOutline fontSize="small" />
                  </ToggleButton>
                </Tooltip>

                <Tooltip title="Show / Hide client forms">
                  <ToggleButton value="account" size="small" color="primary" selected={filter.client} onChange={() => { setFilter({...filter, client: !filter.client}); }} sx={{ py: 0, height: "2.3rem" }}>
                    <AccountCircle fontSize="small" />
                  </ToggleButton>
                </Tooltip>

                <Tooltip title="Show / Hide attorney forms">
                  <ToggleButton value="account" size="small" color="primary" selected={filter.attorney} onChange={() => { setFilter({...filter, attorney: !filter.attorney}); }} sx={{ py: 0, height: "2.3rem" }}>
                    <Work fontSize="small" />
                  </ToggleButton>
                </Tooltip>
                
              </ToggleButtonGroup>

            </Grid>

            {/* <Divider orientation="vertical" flexItem sx={{ height: "80%" }}/> */}
              
            <Grid container gap={2} alignItems="center">
              <SearchField placeholder="search" onChange={(val) => onSearchChange(val)} />
              {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }} size="small">
                <Select id="filter-select" value={filter} onChange={(e) => setFilter(e.target.value)} title="Category Filter">
                  { categories.map((filter, index) => <MenuItem key={index} value={filter}>{filter}</MenuItem>) }                  
                </Select>
              </FormControl> */}
            </Grid>
          </Grid>

          {/* The Content */}
          <Grid container >
            <Table id="forms-table" sx={{ fontSize: "1.1rem" }}>
              <TableHead>
                <TableRow sx={tableStyles.headerRow}>
                  <TableCell className="active" sx={tableStyles.cell}>Name</TableCell>
                  <TableCell sx={tableStyles.cell}>Category</TableCell>
                  <TableCell sx={tableStyles.cell}>Responsibility</TableCell>
                  <TableCell sx={tableStyles.cell}>Description</TableCell>
                  <TableCell sx={tableStyles.cell}>Status</TableCell>
                  <TableCell sx={tableStyles.cell}></TableCell>
                </TableRow>
              </TableHead>
              <TBodyWrapper colSpan={4} isWorking={status.isWorking} isEmpty={isEmpty}>
                {
                  filteredForms?.map((form: any, index: number) => <FormListItem key={index} item={form} onEdit={handleEdit} />)  
                }
              </TBodyWrapper>
            </Table>
          </Grid>

        </Grid>
      </Paper>

      {dialogOpen && <AddFormDialog open={dialogOpen} onClose={onClose} toEdit={itemToEdit} />}
    </>
  );

}

export default FormList;