import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, Divider, Modal, Paper, Popover, ToggleButton, ToggleButtonGroup, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { Delete as DeleteIcon, AddCircleOutline, AssignmentTurnedInOutlined, AttachFileOutlined, SettingsSuggestOutlined, CloudUploadOutlined } from "@mui/icons-material";
import { buttonStyles } from "helpers";
import { useAnchor } from "helpers/hooks";
import { useClient } from "../../client-context";
import AssignFormDialog from "../assign-form-dialog/assign-form-dialog";
import { unassignForms } from "store/actions/attorney-actions";
import { useShareContext } from "sections/app/share-context";
import { AssignedDocument, AssignedForm } from "../../../../types/client-document-types";
import ClientDocumentItem from "./client-document-item";
import UploadDocumentDialog from "./upload-document-dialog";
import ClientFormItem from "./client-form-item";
import ShareDocumentDialog from "./share-document-dialog";
import ClientDocumentList from "./client-document-list";
import { selectClientDocs, selectClientForms } from "store/selectors/attorney-selectors";
import GenerateDocumentDialog from "./generate-document-dialog";

const ClientDocumentsCard = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const { clientId } = useClient();
  const { share } = useShareContext();

  const shareForms = useSelector(selectClientForms);
  const shareDocs = useSelector(selectClientDocs);

  const [assigned, setAssigned] = useState<AssignedForm[]>(shareForms);
  const [anchor, isOpen, onOpen, onClose, positionProps] = useAnchor("bottom-right");
  const [hovered, setHovered] = useState<any | null>(null);
  const checkedCount = useMemo(() => assigned.filter(form => form.isChecked).length, [assigned]);
  
  const [filter, setFilter] = useState({ forms: true, docs: true });
  const [isAssigning, setAssigning] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [isConfirming, setConfirming] = useState(false);
  const [isSharing, setSharing] = useState<AssignedDocument | null>(null);
  const [isGenerating, setGenerating] = useState(false);

  useEffect(() => { setAssigned(shareForms); }, [shareForms]);  //respond if the form assignments change

  const handleSelected = (formId: string) => (e: any) => {
    const updated = assigned.map(form => {
      if(form.id === formId) return { ...form, isSelected: !form.isSelected };
      else if(form.isSelected) return { ...form, isSelected: false };
      return form;
    });
    setAssigned(updated);
  };

  const handleChecked = (formId: string) => (e: any) => {
    const updated = assigned.map(form => {
      if(form.id === formId) return { ...form, isChecked: e.target.checked };
      return form;
    });
    setAssigned(updated);
  }; 

  const handleHover = (type: string, id: string) => (isEntering: boolean) => (e: React.MouseEventHandler) => {
    if(isEntering){
      onOpen(e);
      const item = type === "form" ? assigned.find(form => form.formId === id) : shareDocs.find(doc => doc.id === id);
      setHovered(item)
    }
    else {
      onClose();
      setHovered(null);
    }
  };

  const handleOpen = (formId: string) => (e: any) => {
    console.log("open form", formId);
    const url = `/app/clients/${clientId}/forms/${formId}`;
    navigate(url);
  };

  const handleUnassign = async (e: any, confirmed = false) => {
    if(!confirmed) return setConfirming(true);
    const toUnassign = assigned.filter(form => form.isChecked);
    console.log("unassign", toUnassign);
    
    const result = await dispatch(unassignForms(clientId!, toUnassign));
    setConfirming(false);
    console.log("unassign result", result);
  };

  //TODO: need ability to remove a document from a client

  return (
    <Paper id="client-documents-card" variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: 2 }}>
      {/* Header Grid */}
      <Grid container sm={12} sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderBottomColor: "grey.100", borderRadius: "6px 6px 0 0", px: 2, py: 1 }}>
        <Grid container sm={8} gap={1}>
          <Tooltip title="Add a Client or Attorney form to this client's account">
            <Button onClick={() => setAssigning(true)} variant="outlined" disableElevation sx={{ backgroundColor: alpha(theme.palette.primary.light, 0.25), ...buttonStyles.toolbarSx}} endIcon={<AddCircleOutline fontSize="small"/>} >Add</Button>
          </Tooltip>
          <Tooltip title="Upload a document to this client's account">
            <Button onClick={() => setUploading(true)} variant="outlined" disableElevation sx={{ backgroundColor: alpha(theme.palette.primary.light, 0.25), ...buttonStyles.toolbarSx}} endIcon={<CloudUploadOutlined fontSize="small"/>} >Upload</Button>
          </Tooltip>
          {/* <Tooltip title="Generate a document from a template using the client's data">
            <Button onClick={() => setGenerating(true)} variant="outlined" disableElevation endIcon={<SettingsSuggestOutlined fontSize="small" />} sx={{ backgroundColor: alpha(theme.palette.primary.light, 0.25), ...buttonStyles.toolbarSx}}>Generate</Button>
          </Tooltip> */}
          <Tooltip title="Remove selected items from this client's account">
            <div>
              <Button onClick={handleUnassign} variant="outlined" disableElevation disabled={checkedCount === 0} endIcon={<DeleteIcon fontSize="small"/>} sx={{ display: checkedCount > 0 ? "flex" : "none", backgroundColor: alpha(theme.palette.primary.light, 0.25), ...buttonStyles.toolbarSx }}>Remove</Button>
            </div>
          </Tooltip>
        </Grid>
        <Grid container sm={4} gap={1}>
          
          <Divider orientation="vertical" flexItem sx={{ mr: 1 }}/>

          <ToggleButtonGroup>
            <Tooltip title="Show / Hide Forms">
              <ToggleButton value="history" size="small" color="primary" selected={filter.forms} onChange={() => { setFilter({...filter, forms: !filter.forms}); }} sx={buttonStyles.toolbarSx}>
                <AssignmentTurnedInOutlined fontSize="small" />
              </ToggleButton>
            </Tooltip>

            <Tooltip title="Show / Hide Documents">
              <ToggleButton value="account" size="small" color="primary" selected={filter.docs} onChange={() => { setFilter({...filter, docs: !filter.docs}); }} sx={buttonStyles.toolbarSx}>
                <AttachFileOutlined fontSize="small" />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup>
          
        </Grid>
      </Grid>

      {/* Body Grid */}
      <Grid container sm={12}>
        <ClientDocumentList isEmpty={assigned.length === 0 && shareDocs.length === 0}>
          {filter.forms && 
            assigned.map((form: AssignedForm) => (
              <ClientFormItem
                key={form.formId} 
                form={form} 
                onChecked={handleChecked(form.id)} 
                onSelected={handleSelected(form.id)} 
                onHover={handleHover("form", form.id)} 
                onOpen={handleOpen(form.id)}
                canOpen={!!share?.sharer}
              />
            ))                
          }
          {filter.docs && 
            shareDocs.map((doc: AssignedDocument) => (
              <ClientDocumentItem
                key={doc.id} 
                document={doc} 
                onChecked={handleChecked(doc.id)} 
                onSelected={handleSelected(doc.id)} 
                onHover={handleHover("doc", doc.id)} 
                onShare={() => setSharing(doc)}
                // canOpen={!!share?.sharer}
              />
            ))                
          }
        </ClientDocumentList>

        <Popover open={!!isOpen && !!hovered} onClose={onClose} anchorEl={anchor} {...positionProps} sx={{pointerEvents: "none"}} disableRestoreFocus>
          <Box sx={{ p: 2, width: 350, minHeight: 150, background: "grey.200" }}>
            {hovered?.description && (
              <>
                <Typography fontSize="1.1rem" fontWeight="700">Description:</Typography>
                <Typography fontSize="1.1rem">{hovered.description}</Typography>
              </>
            )}
            { hovered?.description && hovered?.notes && <Divider sx={{ my: 2 }}/> }
            {hovered?.notes && (
              <>
                <Typography fontSize="1.1rem" fontWeight="700">Notes:</Typography>
                <Typography fontSize="1.1rem">{hovered?.notes}</Typography>
              </>
            )}
          </Box>
        </Popover>

        
      </Grid>

      {/* Modals */}
      {isAssigning && <AssignFormDialog open={isAssigning} onClose={() => setAssigning(false)} />}
      {isUploading && <UploadDocumentDialog open={isUploading} onClose={() => setUploading(false)} />}
      {isConfirming && (
        <Modal open={isConfirming} onClose={() => setConfirming(false)}>
          <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", width: 300, bgcolor: "white", p: 2, borderRadius: 2 }}>
            <Typography>Are you sure you want to unassign {checkedCount} form(s)?</Typography>
            <Button onClick={(e) => handleUnassign(e, true)}>Yes</Button>
            <Button onClick={() => setConfirming(false)}>No</Button>
          </Box>
        </Modal>
      )}        
      {isSharing && <ShareDocumentDialog item={isSharing} open={!!isSharing} onClose={() => setSharing(null)} />}
      {/* {isGenerating && <GenerateDocumentDialog open={isGenerating} onClose={() => setGenerating(false)} /> } */}
    </Paper>
  );
};

export default ClientDocumentsCard;