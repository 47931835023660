import createCachedSelector from 're-reselect';

// const _getValueForms = (state) => state.values.forms;
const _getValueForm = (state, documentId) => documentId ? state.values.forms?.find(f => f.id === documentId)?.status : null;
const _getFieldValue    = (state, fieldKey)   => fieldKey ? state.values[fieldKey] : null;
const _getRowFieldValue = (state, fieldKey, index)   => fieldKey && state.values[fieldKey] ? state.values[fieldKey][index] : null;
const _getFormConfig = (state, formId) => formId ? state.shares.formConfigs?.find(f => f.id === formId) : null;
// const _getValues = (state) => state.values;
// const _getClientValues = (state) => state.client?.clientData?.values;

export const selectValue = createCachedSelector(
  [_getFieldValue],
  (value) => {
    // console.log("** Value Selector Called **")
    return value;
  }
)(
  (_, fieldKey) => fieldKey //the cache key
);

export const selectRowValue = createCachedSelector(
  [_getRowFieldValue],
  (value) => {
    // console.log("** Value Selector Called **")
    return value;
  }
)(
  (_, fieldKey, index) => `${fieldKey}-${index}` //the cache key  //`
);

export const selectFormStatus = createCachedSelector(
  [_getValueForm],
  (form) => {
    // console.log("** Value Selector Called **")
    return form;
  }
)(
  (_, documentId) => documentId //the cache key
);