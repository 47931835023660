import { useCallback, useContext, useMemo, useState } from "react"
// import { PetitionerFormContext } from "../petitioner/petitioner-form-context";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Comment, FormField, FormInfo } from "types";
import { useAppContext } from "../app/app-context";
import { useClientForm } from "../collaborator/client-form-context";
import { IPetitionerFormContext, PetitionerFormContext, defaultPetitionerContext } from "../petitioner/contexts/petitioner-form-context";
import { useConversationContext } from "components/values-table/conversation-context";
import { useRegion } from "./parts/region-context";

export const useCurrentForm = () => {
  const { isReviewer } = useAppContext();
  const { schema: petSchema, regions: petRegions } = usePetitionerForm(true);
  const { schema: cliSchema, regions: cliRegions } = useClientForm();

  const schema = useMemo(() => isReviewer ? cliSchema : petSchema, [isReviewer, petSchema, cliSchema]);
  const regions = useMemo(() => isReviewer ?  cliRegions: petRegions, [isReviewer, petRegions, cliRegions]);
  
  return { schema, regions };
}

export const usePetitionerForm = (safe = false) => {
  const context = useContext(PetitionerFormContext);
  if(!context && safe) return defaultPetitionerContext;
  else if(!context && !safe) throw new Error("useForm must be used within a PetitionerFormProvider");
  return context as IPetitionerFormContext;
}

//== Gets the status for a specific region of the current form / document
export const useRegionStatus = (regionId: string) => {
  const { documentId } = useParams();
  if(!documentId) throw new Error("Attemping to use form region status without a documentId");

  const forms = useSelector<any, FormInfo[]>(state => state.values.forms);
  if(!forms) return false;
  const form = forms.find((f: any) => f.id === documentId);
  return form?.status ? !!form.status[regionId] : false;
}

//== Gets the status for all regions of the current form / document
export const useFormStatus = (): FormInfo => {
  const { documentId } = useParams();
  if(!documentId) throw new Error("Attemping to use form region status without a documentId");

  const forms = useSelector<any, FormInfo[]>(state => state.values.forms);
  if(!forms) return {} as FormInfo;
  const form = forms.find(f => f.id === documentId);
  return form ?? {} as FormInfo;
}

//== Gets the comments for a specific form region. Must be used inside the Region and Conversation Providers
export const useFormRegionComments = () => {
  const { fields, sections } = useRegion();
  const { comments, addComment } = useConversationContext();

  const regionComments = useMemo(() => {
    console.log("**calculating region comments");
    const rcs = comments?.reduce((acc, c) => {
      const field = fields.find(f => f.id === c.fieldKey);
      if(field) {
        acc.push({ ...c, fieldLabel: field.label ?? (field as any).name });
      }
      else {
        //Check for section comments
        const section = sections.find(s => s.id === c.fieldKey);
        if(section) {
          acc.push({ ...c, fieldLabel: section.itemLabel ?? section.name });
        }
      }
      return acc;
    }, [] as Comment[]);
    return rcs;
  }, [comments, sections, fields]);

  //Now, order them the same as the fields are ordered
  const orderedComments = useMemo(() => {
    if (!regionComments) return [];
    return regionComments.sort((a, b) => {
      const aIndex = fields.findIndex(f => f.id === a.fieldKey);
      const bIndex = fields.findIndex(f => f.id === b.fieldKey);
      return aIndex - bIndex;
    });
  }, [regionComments, fields]);

  //Gets the label for a field or section
  const getLabel = useCallback((itemKey: string) => {
    let field = fields.find(f => f.id === itemKey);
    if(field){
      if(field?.type === "multi" && !!field?.children){
        field = fields.find(f => f.id === field!.children![0]);
      }

      return field?.label ?? (field as any).name ?? undefined;
    }

    const section = sections.find(s => s.id === itemKey);
    if(section) return section.itemLabel ?? section.name;

  }, [fields, sections]);

  return { regionComments: orderedComments, hasRegionComments: regionComments.length > 0, addComment, getLabel };
}

//== Gets the values for the current client or petitioner, depending on whether this is an attorney or not
export const useFormValues = () => {
  const { isReviewer } = useAppContext();
  const values = useSelector<any, Record<string, any>>(state => isReviewer ? state.attorney.clientData?.values : state.values);
  return values;
}

//LOOK AT useFormValue in part-hooks.ts
//== Gets the value for a specific field for either the attorney or a client
// export const useFieldValue = (field: FormField, defaultValue?: any, sectionId?: string, rowIndex?: number) => {
//   const { isReviewer } = useAppContext();
//   const valueKey = (rowIndex === undefined) ? field.id : sectionId ?? "undefined";
//   const valueSelector = isReviewer ? (state: any) => state.attorney.clientData?.values[valueKey] : (state: any) => state.values[valueKey];
//   const value = useSelector<any, any | undefined>(valueSelector);
  
//   if(rowIndex !== undefined && !sectionId) throw new Error("useFieldValue: rowIndex is defined but no sectionId provided");

//   if(rowIndex !== undefined) {
//     const rowValue = value ? value[rowIndex] : undefined;
//     return rowValue ? rowValue[field.id] : undefined;
//   }

//   return value ?? defaultValue;
// }