import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import CopyIcon from '@mui/icons-material/FileCopy';
// import { WaitButton } from 'components';
import { formatDate } from 'helpers/general-helpers';

const InvitationDialog = ({invitation, onClose}) => {
  const classes   = buildStyles();
  if(!invitation) return null;

  function copyId(){
    navigator.clipboard.writeText(invitation.id);
  }

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xs">
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Invitation</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container justifyContent="center" className={classes.contentRoot} spacing={2}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField value={invitation.id} disabled className={classes.textField} fullWidth label="Id" 
                InputProps={{
                  endAdornment : <InputAdornment position="end" aria-label="copy invitation id">
                    <IconButton onClick={copyId} size="small" title="Copy Invitation ID">
                      <CopyIcon fontSize="small"/>
                    </IconButton>
                  </InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField value={invitation.issuedTo} disabled className={classes.textField} fullWidth label="Issued To" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={invitation.issuedToEmail} disabled className={classes.textField} fullWidth label="Issued To Email" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={invitation.issuedBy} disabled className={classes.textField} fullWidth label="Issued By" />
            </Grid>
            <Grid item xs={12}>
              <TextField value={formatDate(invitation.issuedOn)} disabled className={classes.textField} fullWidth label="Issued On" />
            </Grid>
            {invitation.acceptedOn && 
              <>
                <Grid item xs={12}>
                  <TextField value={formatDate(invitation.acceptedOn)} disabled className={classes.textField} fullWidth label="Accepted On" />
                </Grid>            
                <Grid item xs={12}>
                  <TextField value={invitation.acceptedBy} disabled className={classes.textField} fullWidth label="Accepted By" />
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <TextField value={invitation.message} disabled className={classes.textField} fullWidth multiline rows={3} label="Message" />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      
      <DialogActions>
        {/* <WaitButton color="danger" size="small" isWaiting={status.isWorking} disabled={!canDelete} onClick={onDelete}>Delete</WaitButton> */}
        <Button size="small" onClick={onClose}>Cancel</Button>
      </DialogActions>

    </Dialog>
  );
}

export default InvitationDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot  : {
    padding     : theme.spacing(1),
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  textField           : {
    // width         : "100%",
    "& input, textarea, label"   : { 
      fontSize      : 16,      
    },
    "& label" : {
      color   : theme.palette.grey[400],
    }
  },
}));