import { useMemo } from "react";
import { FormField, FormRegion } from "types";
import LEGACY_FIELDS from "../../../config/fields-config";

type SideEffectAction = Record<string, any>;
type SideEffectFunction = (previousValue: any, nextValue: any, doUpdate: any) => Promise<void>;

const createSideEffect = (field: FormField, allFields: FormField[]) : Record<string, SideEffectFunction> => {
  const action: SideEffectAction = field.action;
  const actionKeys = Object.keys(action);

  switch(actionKeys[0]){
    case "toggle":
      return createToggleSideEffect(field, allFields);
    // case "update":
    //   return createUpdateSideEffect(field, allFields, dispatch);
    default:
      throw new Error(`side effect type not found: ${action.type}`);
  }
};

const createToggleSideEffect = (field: FormField, allFields: FormField[]) => {
  //first, create the sideEffect for the primary field
  const action: SideEffectAction = field.action;
  const childFieldIds: string[] = action.toggle;
  const childFields = allFields.filter(f => childFieldIds.includes(f.id));    
    
  const primarySideEffect = async (previousValue: any, nextValue: any, doUpdate: any) => {
    //When the field is changed, need to set all fields in the action.children to the new value
    childFields.forEach(f => {
      return doUpdate(f.id, nextValue);
    });  
  };

  //Now, for each of the dependent fields, create a side effect that toggles the original field if they are toggled independently
  const dependentEffects = childFields.reduce((acc, f) => {
    const result = {
      ...acc,
      [f.id]: async (previousValue: any, nextValue: any, doUpdate: any) => {
        //When the field is changed, need to set all fields in the action.children to the new value
        if(nextValue === false) return doUpdate(field.id, nextValue);
      }
    };
    return result;
  }, {} as Record<string, SideEffectFunction>);

  const effects: Record<string, SideEffectFunction> = {
    [field.id]: primarySideEffect,
    ...dependentEffects,
  };

  return effects;
};

//A hook to create the side effects for a form
//Side Effects are how we handle dynamic changes within the form,
// when one field changes, it can trigger changes in other fields
// this is used for things like checking an "all of the above" and having it
// automatically check all the related boxes
export function useFormSideEffects(schema: any, regions: FormRegion[]) {
  const effects = useMemo<Record<string, any>>(() => {
    if(!schema || !regions) return {};

    const version = schema.version ?? "1.0.0";
    const fieldIds = [
      ...regions.flatMap(r => r.sections?.flatMap(s => s.fields)),
      ...regions.flatMap(r => r.sections?.map(s => s.headerField).filter(f => f)),
    ];
    const allFields: FormField[] = version === "1.0.0" ? LEGACY_FIELDS : schema.fields;
    const fields = allFields.filter(f => fieldIds.includes(f.id));
    const withSideEffects = fields.filter(f => f.action);
    
    const sideEffects = withSideEffects.reduce((acc, f) => {
      const result = {
        ...acc,
        ...createSideEffect(f, fields),
      };
      return result;
    }, {} as Record<string, any>);

    return sideEffects;
  }, [schema, regions]);

  return effects;
}