import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Divider, List } from "@mui/material";
import { Inbox as InboxIcon, AssignmentTurnedIn, CalendarMonth, AttachFile } from "@mui/icons-material";
import { TopicItem } from "types";
import { TopicListItem } from "components/conversations";
import { selectClientTopics } from "store/selectors/attorney-selectors";
import { selectDirtyByTopic } from "store/selectors/conversation-selectors";
import { useShareContext } from "sections/app/share-context";

const ClientConversationList = () => {
  const navigate = useNavigate();
  const { clientId, topicId } = useParams();
  const { shareId } = useShareContext();
  const dirty = useSelector<any, any>(state => (selectDirtyByTopic as any)(state, shareId));
  const topics = useSelector<any, TopicItem[]>(selectClientTopics);

  const handleListItemClick = (selectedTopic: string, selectedType: string) => () => {
    if(selectedTopic === topicId) return; //already selected
    const url = `/app/clients/${clientId}/conversations/${selectedType}/${selectedTopic}`;
    navigate(url, { replace: true });    
  }

  return (
    <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <List component="nav" aria-label="main mailbox folders" sx={{}}>
        <TopicListItem label="General" icon={<InboxIcon fontSize="small" color="inherit" />} isSelected={topicId === "_general_"} isDirty={dirty["_general_"]} onClick={handleListItemClick("_general_", "g")} />
        <TopicListItem label="Schedule" icon={<CalendarMonth fontSize="small" color="inherit" />} isSelected={topicId === "_schedule_"} isDirty={dirty["_schedule_"]} onClick={handleListItemClick("_schedule_", "g")} />
        
        <Divider sx={{ my: 2 }}/>
      
        {
          topics.map((item, index) => (
            <TopicListItem 
              key={item.topicId} 
              label={item.topicName} 
              icon={item.topicType === "f" ? <AssignmentTurnedIn fontSize="small" color="inherit" /> : <AttachFile fontSize="small" color="inherit" />} 
              isSelected={topicId === item.topicId} 
              isDirty={dirty[item.topicId]} 
              onClick={handleListItemClick(item.topicId, item.topicType)} 
            />            
          ))
        }

      </List>
    </Box>
  );
};

export default ClientConversationList;
