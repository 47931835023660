import React from 'react';
import { useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ProfileCard from './parts/profile-card';
import { selectProfile } from 'store/selectors/app-selectors';

const MyProfile = () => {
  const classes   = buildStyles();
  const profile   = useSelector(selectProfile);
  const hasPhoto  = Boolean(profile.photo);
  const image     = hasPhoto ? <img className={classes.avatar} src={profile.photo} alt="profile"/> : <AccountCircleIcon className={classes.avatar} color="primary"/>;

  return (
    <Grid id="profile-view" container className={classes.profileRoot}>
      
      <Grid item xs={12} container justifyContent="flex-start" alignItems="flex-start">
        <Grid item sm={9} container>
          <Grid container><Typography className={classes.title}>My Profile</Typography></Grid>
          <Grid container><Typography className={classes.subTitle}>Manage your information, data and sharing to customize your FormHop experience</Typography></Grid>
        </Grid>
        <Grid item sm={3} container alignItems="center" justifyContent="center">{image}</Grid>            
      </Grid>

      <Grid item container spacing={4}>
        <Grid item sm={12} container>
          <ProfileCard profile={profile} />
        </Grid>
      </Grid>

    </Grid>
  );
}

export default MyProfile;

const buildStyles   = makeStyles(theme => ({
  profileRoot  : {
    padding     : theme.spacing(1),
  },
  avatar      : {
    border        : `0.5px solid ${theme.palette.grey[300]}`,
    borderRadius  : "50%",
    height        : "90px",
    width         : "90px",
    lineHeight    : "90px",
    display       : "block",
    // marginRight   : theme.spacing(1),
    marginBottom  : theme.spacing(2),
  },
  title   : {
    fontSize    : 25,
    fontWeight  : 500,
    // textAlign   : "center",
    marginBottom: theme.spacing(1),
    color       : theme.palette.primary.main,
  },
  subTitle  : {
    fontSize      : 18,
    fontWeight    : 400,
    // textAlign     : "center",
    marginBottom  : theme.spacing(1),
    color         : theme.palette.grey[600],
  },  
}));