import { createRoot } from "react-dom/client";
// import ReactDOM from 'react-dom';
import App from './sections/app/app';
import { bootstrapApp } from 'config/app-config';

const config  = bootstrapApp();
// const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App config={config}/>);

// const render = () => {
//   ReactDOM.render(
//     // <React.StrictMode>
//       <App config={config}/>,
//     // </React.StrictMode>,
//     container
//   );
// };

// render();

// if (module.hot) {
//   module.hot.accept("./sections/app/app", () => {
//     render();
//   });
// }