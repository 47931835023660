import SHA256 from "crypto-js/sha256";
import _ from "lodash";
import { Comment, CommentWithOwner, ClientListProfile, SameAsConfig, Share, ShareStatus } from "types";

export const getShareStatus = (share?: Share | null) => {
  if(!share) return null;
  if(share.sharer) return ShareStatus.ACCEPTED;
  else if(share.invitedAt) return ShareStatus.INVITED;
  else if(share.rejectedAt) return ShareStatus.REJECTED;
  else return ShareStatus.NOT_INVITED;    
};

const refreshSeconds = 8;
export const REFRESH_INTERVAL = 1000 * refreshSeconds; //10 seconds

//===
// Flattens all the comments in a conversation to be organized by field
export const flattenComments = (comments: Comment[], addOwnerFn: (comment: Comment) => any) => {
  const filtered = comments.filter(c => !!c.fieldKey).map(c => ({ ...c, ...addOwnerFn(c) } as CommentWithOwner));  //only pay attention to comments with a fieldKey
  const ordered = filtered.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
  
  //Group the comments by field
  const commentsByField: Record<string, CommentWithOwner[]> = ordered.reduce((acc, comment) => {
    if (!acc[comment.fieldKey]) acc[comment.fieldKey] = [comment];
    else acc[comment.fieldKey].push(comment);
    return acc;
  }, {} as Record<string, CommentWithOwner[]>);

  return commentsByField;
};

export const hashString = (value: string)  => {
  return SHA256(value).toString();
}

export const initializeProps = (item: Record<string, any>, fields: string[]) => {
  const obj = fields.reduce((acc, key) => {
    acc[key] = item[key] ?? "";
    return acc;
  }, {} as Record<string, any>);
  return obj;
};

//remove all props of an object that are undefined only
export const removeUndefinedProps = (obj: any) => _.omitBy(obj, _.isUndefined);
export const replaceUndefinedProps = (obj: any, replacement: any = null) => _.mapValues(obj, v => v === undefined ? replacement : v);

//removes all blank array items from the object's properties (only affects properties that are arrays)
export const removeBlankArrayItems = (obj: Record<string, any>) => {
  const result = Object.keys(obj).reduce((acc: any, key: string) => {
    const value = obj[key];
    if(Array.isArray(value)){
      acc[key] = value.filter((v: any) => v !== null);
    }
    else acc[key] = value;
    return acc;
  }, {});

  return result;
};


export const sameAsFlag = (field: SameAsConfig) => {
  return `sa_${field.id}`;
};

export const isSameAs = (field: SameAsConfig, values: Record<string, any>) => {
  const saField = field.sameAs;
  if(!saField) return false;
  const saFlag = sameAsFlag(field);
  return !!values[saFlag];
};

export const sameAs = (field: SameAsConfig, values: Record<string, any>): [boolean, string | null, any, string] => {
  if(!field || !values) throw new Error("Field and values are required for same as check");

  if(isSameAs(field, values)){
    const sameAsValue = values[field.sameAs!];
    return [true, field.sameAs!, sameAsValue, field.sameAsLabel ?? "Same as Client 1"];
  }
  return [false, null, null, ""];
}

export const sortClients = (
  items: ClientListProfile[], 
  sortConfig: { field: string, order: string },
  members?: any[]
) => {
  if (!items?.length) return [];
  
  return [...items].sort((a, b) => {
    const { field, order } = sortConfig;
    const multiplier = order === 'asc' ? 1 : -1;

    switch (field) {
      case 'name':
        const nameA = `${a.firstName || ''}${a.lastName || ''}`.toLowerCase();
        const nameB = `${b.firstName || ''}${b.lastName || ''}`.toLowerCase();
        return nameA.localeCompare(nameB) * multiplier;

      case 'status':
        // Order: ACCEPTED -> INVITED -> NOT_INVITED
        const statusOrder = {
          [ShareStatus.ACCEPTED]: 1,
          [ShareStatus.INVITED]: 2, 
          [ShareStatus.NOT_INVITED]: 3,
          [ShareStatus.REJECTED]: 4
        };
        const statusA = statusOrder[getShareStatus(a.share) ?? ShareStatus.NOT_INVITED] || 999;
        const statusB = statusOrder[getShareStatus(b.share) ?? ShareStatus.NOT_INVITED] || 999;
        return (statusA - statusB) * multiplier;

      case 'starred':
        const starA = a.isStarred ? 1 : 0;
        const starB = b.isStarred ? 1 : 0;
        return (starB - starA) * multiplier;

      case "assignedTo":
        const assignedToA = a.assignedTo ? members?.find(m => m.id === a.assignedTo)?.profile?.displayName : "";
        const assignedToB = b.assignedTo ? members?.find(m => m.id === b.assignedTo)?.profile?.displayName : "";
        return String(assignedToA).localeCompare(String(assignedToB)) * multiplier;
      // case 'unseen':
      //   const unseenA = a.unseen ? 1 : 0;
      //   const unseenB = b.unseen ? 1 : 0;
      //   return (unseenB - unseenA) * multiplier;

      default:
        // For any other fields, try direct comparison
        const valA = a[field] || '';
        const valB = b[field] || '';
        return String(valA).localeCompare(String(valB)) * multiplier;
    }
  });
};