import { APP_ACTIONS } from '../actions/action-types';
import _ from 'lodash';
import moment from 'moment';
import { getNow } from 'store/actions/action-helpers';

const DEFAULT_STATE     = {
    isWorking   : false,
    error       : null,
    status      : null
};

export const NOT_WORKING   = {
    isWorking   : false,
    status      : null
};

export const NO_ERROR   = {
    error       : null
};

//---- For status structure
export const DEFAULT_STATUS  = { isWorking: false, error: null, isEmpty: false, isInitialized: false, loadedAt: null };
export function workingStatus(key){
    return {
        [key]   : {
            isWorking   : true,
            error       : null,
            isEmpty     : false,
        },
    };
}
export function finishedStatus(key, data){
    return {
        [key]   : {
            isWorking   : false,
            error       : null,
            isEmpty     : Boolean(!data || (_.isArray(data) && data.length === 0)),
            isInitialized   : true,
            loadedAt    : getNow(),
        },
    };
}
export function failedStatus(key, error){
    return {
        [key]   : {
            isWorking   : false,
            error       : error,
            isEmpty     : false,
        },
    };
}

export function workingFunc(statusKey){
    return function working(state, action){
        return {
            ...state,
            ...workingStatus(statusKey),
        };
    }
}
export function failedFunc(statusKey){
    return function working(state, action){
        return {
            ...state,
            ...failedStatus(statusKey, action.error),
        };
    }
}

//--------
//helper function for just changing the state to not working and no errors
export function allClear(state){
    return {
        ...state, 
        ...NOT_WORKING,
        ...NO_ERROR
    };
}

function mapChangeResult(propName){
    return (state, action) => {
        let result = {
            ...state,
            ...NOT_WORKING,
            ...NO_ERROR
        };
        result[propName]    = action.meta.changes;
        return result;
    }
}

function mapSimpleResult(propName){
    return (state, action) => {
        let result = {
            ...state,
            ...NOT_WORKING,
            ...NO_ERROR
        };
        result[propName]    = action.result;
        return result;
    }
}

//------------
// Will handle a result that is expecting either an array of items, or a paged-result
// that includes the items and paging information
export function unpackItems(result, itemKey = "values"){
    let response    = {
        updatedAt       : new moment(),
        page            : 1,
        itemCount       : 1,
        pageCount       : 1,
        pageSize        : 0,
        sort            : null,
        filter          : null,
        beginTime       : null,
        endTime         : null
    };

    if(_.isArray(result)){
        response[itemKey]  = result;
    }
    else if(result.items && _.isArray(result.items)){
        response[itemKey]   = result.items;
        const otherProps    = _.omit(result, "items");
        response            = {...response, ...otherProps};
    }

    return response;
}

//A common set of reducer handler functions for specific actions
export function getDefaultHandlers(ActionTypes){
    let handlers    = {
        [APP_ACTIONS.CLEAR_ALL_ERRORS]  : clearError,
    };

    handlers[ActionTypes.WORKING]       = beginWorking;
    handlers[ActionTypes.ERROR]         = reduceError;
    handlers[ActionTypes.CLEAR_ERROR]   = clearError;

    return handlers;
}

//--------------
// Creates a reducer on the fly out of an object map
function createReducer(initialState, handlers) {
    return function reducer(state = initialState, action) {
      if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action)
      } 
      else {
        return state
      }
    }
  }

//--------------
// Will update an object in an immutable way.
function immUpdate(original, updates){
    return Object.assign({}, original, updates);
}

//--------------
// Will update a specific item in an array, either by the item or by
// a selector func that finds the item, then return a new array where
// the original has been replaced with the updated item.
function immUpdateArray(origArray, originalOrSelector, updatedOrCallback){
    if(!origArray) return origArray;

    let myArray         = origArray;
    let isPaged         = false;
    if(!_.isArray(myArray) && myArray.values){
        myArray     = myArray.values;
        isPaged     = true;
    }

    const index         = findIndex(myArray, originalOrSelector);
    if(index < 0) return origArray;

    const toUpdate      = myArray[index];
    const updated       = isFunc(updatedOrCallback) ? updatedOrCallback(toUpdate) : updatedOrCallback;
    
    const resultArray     = [
        ...myArray.slice(0, index), 
        updated, 
        ...myArray.slice(index + 1)
    ];

    return isPaged ? {...origArray, values: resultArray} : resultArray;
}

//--------------
// Removes an item from an array in an immutable-safe way
export function immRemoveItem(array, itemOrSelector){
    var isPaged     = false;
    var myArray     = array;
    if(!_.isArray(array) && array.values){
        myArray = array.values;
        isPaged = true;
    }

    let index   = findIndex(myArray, itemOrSelector, true);

    let resultArr   = [
        ...myArray.slice(0, index), 
        ...myArray.slice(index + 1)
    ];

    return isPaged ? {...array, values: resultArr} : resultArr;
}

//--------------
// Removes an item from an array in an immutable-safe way
function immRemoveItemByIndex(array, index){
    var isPaged     = false;
    var myArray     = array;
    if(!_.isArray(array) && array.values){
        myArray = array.values;
        isPaged = true;
    }

    // let index   = findIndex(myArray, itemOrSelector, true);

    let resultArr   = [
        ...myArray.slice(0, index), 
        ...myArray.slice(index + 1)
    ];

    return isPaged ? {...array, values: resultArr} : resultArr;
}

//--------------
// Will add an item to an array if it's not already there, or
// update the existing version if it is already there.
export function immAddOrReplace(array, replacement, originalOrSelector){
    let myArray = array;
    let isPaged = false;
    if(!_.isArray(myArray) && myArray.values){
        //this is a paginated list
        myArray = myArray.values;
        isPaged = true;
    }
    
    let resultArray     = [];
    if(myArray === null || myArray.length === 0){
        resultArray     = [replacement];
    }

    let index   = originalOrSelector ? findIndex(myArray, originalOrSelector, false) : null;
    if(_.isNull(index) || _.isUndefined(index) || index < 0){
        resultArray = [
            ...myArray, 
            replacement
        ];  //add the item
    }
    else{
        resultArray = immUpdateArray(myArray, originalOrSelector, replacement);
        // return replaceItem(array, index, replacement);
    }

    return isPaged ? {...array, values: resultArray} : resultArray;
}

//Will merge two arrays of objects, using a key selector to determine if the item
// is added or updated.
// the mergeItems flag determines if the items are merged or replaced. If false (default), items
// in the second array will replace items in the first array.
export function immMergeArrays(array1, array2, keySelector, mergeItems = true){
    if(!array1) array1 = [];
    if(!array2) array2 = [];
    let resultArray = [...array1];

    array2.forEach(item => {
        let key = keySelector(item);
        let index = findIndex(resultArray, i => keySelector(i) === key);
        if(index < 0){
            resultArray.push(item);
        }
        else{
            resultArray[index] = mergeItems ? {...resultArray[index], ...item} : item;
        }
    });

    return resultArray;
}

//--------------
// Finds the index of an item in an array, either via a selector function
// or from the item itself.
function findIndex(array, originalOrSelector, throwIfNotFound = false){    
    let index   = isFunc(originalOrSelector) ? 
        _.findIndex(array, originalOrSelector) : 
        array.indexOf(originalOrSelector);
    
    if(index < 0 && throwIfNotFound){
        throw new Error('Item not found');
    }
    return index;
}


//--------------
// Finds an item in an array, either via a selector function
// or from the item itself.
function findItem(array, originalOrSelector){
    const itemIndex     = findIndex(array, originalOrSelector);
    return array[itemIndex];
}

//--------------
// A common function for reducers to handle the Working state
function beginWorking(state, action){
    return {
        ...state,
        isWorking   : true,
        status      : (action.meta ? action.meta.status : null) || "Loading..."
    };
}

//--------------
// A common function for reducers to handle the Error state
function reduceError(state, action){
    return {
        ...state,
        ...NOT_WORKING,
        error       : action.error
    };
}

function clearError(state, action){
    return {
        ...state,
        ...NO_ERROR
    };
}

const getCurrentDate = () => {
    return new Date().toLocaleDateString();
}

export const getCurrentDateTime = () => {
    return new Date().toLocaleString();
}

//------------------
// The module export for this helper
//------------------
const reducerHelpers = {
    DEFAULT_STATE   : DEFAULT_STATE,
    NOT_WORKING     : NOT_WORKING,
    NO_ERROR        : NO_ERROR,
    allClear        : allClear,

    mapSimpleResult : mapSimpleResult,
    mapChangeResult : mapChangeResult,
    
    getDefaultHandlers  : getDefaultHandlers,
    createReducer   : createReducer,

    unpackItems     : unpackItems,
    immUpdate       : immUpdate,
    immUpdateArray  : immUpdateArray,
    immRemoveItem   : immRemoveItem,
    immAddOrReplace : immAddOrReplace,
    immRemoveItemByIndex    : immRemoveItemByIndex,
    immMergeArrays  : immMergeArrays,

    findIndex       : findIndex,
    findItem        : findItem,

    getCurrentDate  : getCurrentDate,
    getCurrentDateTime  : getCurrentDateTime,

    beginWorking    : beginWorking,
    reduceError     : reduceError,
    clearError      : clearError
};

export default reducerHelpers;
//------------------
// HELPER FUNCS

function isFunc(thing){
    return typeof(thing) === 'function';
}