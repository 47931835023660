import { useCallback, useEffect, useMemo, useState } from "react";
import { CurrentClient, Share } from "types";
import { docsSyncHash } from "helpers/cloud-storage-helpers";
import useCloudSync from "./use-cloud-sync";

//Will monitor the client documents and if any changes are detected, will sync
// the changes with the cloud.
const useClientWatcher = (client: CurrentClient, share: Share | null, onSynced?: () => Promise<void>) => {
  const { canSync, isClientSynced, syncClient, syncStatus } = useCloudSync();
  const isSyncable = useMemo(() => !!client && canSync && isClientSynced(client), [client, canSync, isClientSynced]);
  const isSyncing = useMemo(() => syncStatus.isSyncing, [syncStatus]);
  const [lastSyncHash, setLastSyncHash] = useState<string | null>(null);
  const syncHash = useMemo(() => docsSyncHash(share?.documents ?? []), [share?.documents]);
  const [isWorking, setWorking] = useState(false);

  const handleSync = useCallback(async (force = false) => {
    console.log("syncing client files", client.id, share?.documents);
    if((force === true && canSync && !!client) || (isSyncable && syncHash !== lastSyncHash)) {
      await syncClient(client, share?.documents);
      setLastSyncHash(syncHash);
    }
    else {
      // if(!isSyncable || syncHash === lastSyncHash) {
        console.log("not syncable or no changes detected");
      // }      
    }
  }, [client, share, canSync, isSyncable, syncClient, syncHash, lastSyncHash]);

  //This effect will catch any changes to the client's documents, and trigger a sync if the client is synced
  useEffect(() => {
    async function doSync() {
      setWorking(true);
      await handleSync();
      setLastSyncHash(syncHash);

      if(onSynced) await onSynced();  //callback to let the caller know the sync is complete

      setWorking(false);
    }

    if(!isWorking && isSyncable && !isSyncing && syncHash !== lastSyncHash) doSync();

  }, [isWorking, isSyncable, syncHash, lastSyncHash, isSyncing, handleSync, onSynced]);

  return { syncStatus, doSync: handleSync };
};

export default useClientWatcher;