import { FirebaseActionResult, FirebaseErrorResult, FirebaseOkResult } from "./store-types";

export const parseFbResult = <T = any>(result: any): FirebaseActionResult => {
  if(result && result.status === "ok"){
    return (result as unknown) as FirebaseOkResult<T>;
  }

  return (result as unknown) as FirebaseErrorResult;
}


export const noUndef = (value: any, key: string) => {
  if(!value) return {};
  else return { [key]: value };
}