import makeStyles from '@mui/styles/makeStyles';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useAppContext } from 'sections/app/app-context';
import DataTableFieldComments from './data-table-field-comments';

const CommentContainer = ({fieldKey, profile, isReadOnly, children}) => {
  const classes   = buildStyles();
  const { allowComments } = useAppContext();
  const headerId  = `cc-header-${fieldKey}`;
  const bodyId    = `cc-body-${fieldKey}`;

  //If comments are turned off for this practice, just return the children
  if(!allowComments) return children;

  return (
    <Accordion defaultExpanded={false} className={classes.headerPanel} elevation={0} TransitionProps={{ unmountOnExit: true }} disabled={isReadOnly}>
      <AccordionSummary className={classes.headerSummaryPanel} classes={{content: classes.headerContent}} expandIcon={null} aria-controls={bodyId} id={headerId}>
        {children}
      </AccordionSummary>
      <AccordionDetails className={classes.commentsPanel}>
        <DataTableFieldComments fieldKey={fieldKey} profile={profile}/>
      </AccordionDetails>
    </Accordion>
  );
}

export default CommentContainer;

const buildStyles   = makeStyles(theme => ({
  headerPanel  : {
    "&.Mui-expanded"  : {
      marginBottom    : `${theme.spacing(0.5)}px !important`,  //`
      marginTop       : `${theme.spacing(0.5)}px !important`,  //`
      boxShadow       : "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "&.Mui-disabled"  : {
      background      : theme.palette.common.white,
    }
  },
  headerSummaryPanel  : {
    minHeight   : 24,
    padding     : 0, 
    "&.Mui-expanded"   : {
      background    : `${theme.palette.secondary.light}33`, //`
      borderBottom  : `1px solid ${theme.palette.secondary.main}`, //`
      paddingLeft   : theme.spacing(1),
      minHeight   : 24,
    },
    "&.Mui-disabled"  : {
      opacity      : 1,
    }
},
  headerContent   : {
    margin    : theme.spacing(0.5, 0), // `${theme.spacing(0.5)} ${theme.spacing(0)}`, //`
    "&.Mui-expanded"   : {
      margin        : theme.spacing(1, 0), //`${theme.spacing(1)} ${theme.spacing(0)} `, //`
    }
  },
  commentsPanel  : {
    background    : `${theme.palette.secondary.light}22`, //`
    padding       : theme.spacing(1),
    paddingBottom : theme.spacing(2),
  }, 
}));