import { sameAsFlag } from "helpers/model-helpers";
import { FormSchema } from "types";

export const prepareSameAsFields = (data: Record<string, any>, schema: FormSchema | null | undefined) => {
  if(!schema) return data;

  const allFields = schema.fields || [];
  const allSections = Object.keys(schema.layout.regions).flatMap(key => schema.layout.regions[key].sections) || [];
  
  //TODO: NEXT - TEST THIS OUT
  //Deal with the 'sameAs' properties - any fields that are sameAs, check to see if we need to copy a value over
  const sameAsFields = allFields.filter(f => !!f.sameAs);
  const sameAsSections = allSections.filter(s => !!s.sameAs);
  const sameAsItems = [...sameAsFields, ...sameAsSections];

  let outData = {...data};

  sameAsItems.forEach(item => {
      const saFlag = sameAsFlag(item);        //If the user checks sameAs, a field sa_
      if(outData[saFlag] === true){
          const saValue = outData[item.sameAs!]; //same as value
          const dValue = outData[item.id];      //current value
          if(saValue !== dValue){
              if(saValue === undefined && dValue !== undefined) delete outData[item.id]; //outData[field.id] = undefined;
              else outData[item.id] = saValue;
          }
      }
  });

  return outData;
}