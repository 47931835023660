import { useRef, useState } from "react";
import { useHoverDirty } from "react-use";
import { alpha, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Tooltip, Typography, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { UploadFileOutlined } from "@mui/icons-material";
import WordIcon from "./word-icon";
import { ExportTemplateSchema } from "types/schema";
import { useClient } from "../collaborator/client-context";

// export type ExportOption = {
//   id: string;
//   name: string;
//   desc: string;
//   isRequired: boolean;
//   locKey: string;     //co
//   order?: number;
// }

export interface IFormExportOptionProps {
  option: ExportTemplateSchema;
  isWorking: boolean;
  onExport: (option: ExportTemplateSchema, clientKey?: string) => void;
}

const FormExportOption = ({option, isWorking, onExport}: IFormExportOptionProps) => {
  const { currentClient: client } = useClient();
  const theme = useTheme();
  const ref = useRef(null);
  const isOver = useHoverDirty(ref);
  const [isOpen, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if(client.isCouple){
      setOpen(!isOpen);
    }
    else {
      onExport(option);
    }
  }

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  };

  const options = [
    { label: `Export for ${client.firstName}`, onClick: () => onExport(option, "client1"), icon: <UploadFileOutlined fontSize="small" /> },
    { label: `Export for ${client.partnerFirstName ?? "Partner"}`, onClick: () => onExport(option, "client2"), icon: <UploadFileOutlined fontSize="small" /> }
  ];

  const sxProps = (isOver || isOpen) ? { borderColor: "primary.main", bgcolor: alpha(theme.palette.primary.light, 0.25) } : { borderColor: "transparent" };

  return (
    <>
      <Tooltip title="Click to export this document">
        <Grid ref={ref} onClick={handleClick} container alignItems="center" width="100%" flexWrap="nowrap" mt={1} py={0.5} sx={{ cursor: "pointer", border: "1px solid", borderRadius: 1, ...sxProps }}>
          <Grid container alignItems="center" px={0.5}>
            <WordIcon size={24}/>
          </Grid>
          <Grid flexGrow={1}>
            <Typography component="span" fontSize={18} fontWeight="600" mb={0.5} color="primary">{option.name}</Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <UploadFileOutlined color="primary" sx={{ fontSize: 24 }} />
          </Grid>
        </Grid>
      </Tooltip>

      <Popper sx={{ zIndex: 1 }} open={isOpen} anchorEl={ref.current} role={undefined} transition disablePortal placement="top-end">
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "top-end" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem key={index} onClick={option.onClick}>
                      {option.icon && (<ListItemIcon>{option.icon}</ListItemIcon>)}
                      <ListItemText>{option.label}</ListItemText>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default FormExportOption;