import { Typography } from "@mui/material";
import { FormRegion } from "types";

export interface IRegionSubtitleProps {
  region: FormRegion;
}

const RegionSubtitle = ({ region }: IRegionSubtitleProps) => {

  const content = region.subTitle ?? region.desc;
  if(!content) return null;

  if(content.toLowerCase().startsWith("<html>")){
    return (
      <Typography id="region-subtitle" fontSize="1rem">
        <div dangerouslySetInnerHTML={{__html: content}} />
      </Typography>
    );
  }

  return (
    <Typography id="region-subtitle" fontSize="1rem">
      {region.subTitle ?? region.desc}
    </Typography>
  );
}

export default RegionSubtitle; 
