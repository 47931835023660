import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, TextField, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ClientNoteSchema } from "types/schema";
import { addClientNote, updateClientNote } from "store/actions/attorney-actions";
import { useClient } from "../../client-context";

export interface IClientNoteInputProps {
  itemToEdit?: ClientNoteSchema | null;
  onEdited?: (wasSaved: boolean) => void;
}

const ClientNoteInput = ({ itemToEdit, onEdited }: IClientNoteInputProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { clientId } = useClient();
  const [props, setProps] = useState<Partial<ClientNoteSchema>>({content: "", tags: []});

  useEffect(() => {
    if(itemToEdit){
      setProps({content: itemToEdit.content, tags: itemToEdit.tags});
    }
    else {
      setProps({content: "", tags: []});
    }
  }, [itemToEdit]);
  
  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleSaveNote = async () => {
    if(!props.content) return;
    if(!itemToEdit){
      const result = await dispatch(addClientNote(clientId, props.content, props.tags)) as any;
      if(!result.error){
        setProps({content: "", tags: []});
      }
    }
    else{
      const result = await dispatch(updateClientNote(clientId, itemToEdit.id!, props as any)) as any;
      if(!result.error){
        setProps({content: "", tags: [] });
        onEdited && onEdited(true);
      }
    }
  };

  const handleCancelEdit = () => {
    setProps({content: "", tags: []});
    onEdited && onEdited(false);
  }

  return (
    <Grid container sm={12} justifyContent="stretch">
      <TextField id="content" value={props.content} onChange={handleChange} multiline rows={4} fullWidth placeholder="Add a note..." variant="filled" size="small" sx={{ m: 1, mb: 0, "& .MuiInputBase-root": { fontSize: "1rem", } }} /> {/* "&:before": { border: 0 } */}
      <Grid container sm={12} justifyContent="flex-end" columnGap={1}>
        {itemToEdit && <Button onClick={handleCancelEdit} disabled={!props.content} variant="contained" size="small" disableElevation sx={{ m: 1, backgroundColor: theme.palette.primary.light }}>Cancel Edit</Button>}
        <Button onClick={handleSaveNote} disabled={!props.content} variant="contained" size="small" disableElevation sx={{ m: 1, backgroundColor: theme.palette.primary.light }}>Save Note</Button>
      </Grid>      
    </Grid>
  )
};

export default ClientNoteInput;