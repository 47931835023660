import { createContext, useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useStatus } from "redux-action-status";
import { ClientProfile, Share, ShareStatus } from "types";
import { StatusKeys } from "helpers";
import { useAppContext } from "./app-context";
import { getShareStatus } from "helpers/model-helpers";
import { selectClientProfile } from "store/selectors/attorney-selectors";

export interface IShareContext {
  shares: Share[];
  share: Share | null;
  shareId: string | null;
  shareStatus: ShareStatus | null;
}

export const ShareContext = createContext<IShareContext | null>(null);

export const useShareContext = () => {
  const context = useContext(ShareContext);
  if (!context) throw new Error("useShareContext must be used within a ShareProvider");
  return context;
};

export const ShareProvider = ({ children }: { children: React.ReactNode }) => {
  const { isAuthed, isReviewer } = useAppContext();
  const { isInitialized } = useStatus(StatusKeys.shares);
  const shares = useSelector<any, Share[]>(state => state.share.shares);
  const { clientId, shareId } = useParams();
  //this will return null in the petitioner perspective
  const clientProfile = useSelector<any, ClientProfile | null>(state => selectClientProfile(state, clientId));

  const share = useMemo(() => {
    if(!isAuthed || !isInitialized) return null;  //wait until we've initialized
    
    let share: Share | null = null;
    if(shareId) share = shares.find(s => s.id === shareId) ?? null;
    else if(isReviewer && clientProfile) share = shares.find(s => s.id === clientProfile.shareId) ?? null;

    console.log("recomputing share", share);
    return share;
  }, [isAuthed, isInitialized, isReviewer, shares, clientProfile, shareId]);
  
  const shareStatus = useMemo<ShareStatus | null>(() => getShareStatus(share), [share]);
    const value = useMemo(() => ({
    shares,
    share,
    shareId: share?.id ?? null,
    shareStatus,    
  }), [shares, share, shareStatus]);

  return <ShareContext.Provider value={value}>{children}</ShareContext.Provider>;
};