
import { useEffect, useState } from "react";
import { Alert } from "@mui/material"
import { SxProps, Theme } from "@mui/material/styles";
import { useStatus } from "redux-action-status";

export interface IErrorStatusProps {
  title?: string;
  statusKey: string;
  // error: any;
  // message: string;
  noClose?: boolean;
  sx?: SxProps<Theme>;
}

const ErrorStatus = ({ title, statusKey, noClose, sx = [] }: IErrorStatusProps) => {
  const [isDisplayed, setDisplayed] = useState(false);
  const { isWorking, error, onClear } = useStatus(statusKey ?? "none");
  
  useEffect(() => {
    if(isWorking) setDisplayed(false);
    if(error) setDisplayed(true);
  }, [isWorking, error]);

  const onClose = () => {
    onClear();
    setDisplayed(false);
  }
  
  if(!isDisplayed) return null;   // Hide the error message if it's not displayed

  const closeProp = noClose ? {} : { onClose };
  const mySxProps = { }; //{ width: 'auto', textDecoration: 'underline', }
  const sxProp = [ mySxProps,
    // You cannot spread `sx` directly because `SxProps` (typeof sx) can be an array.
    ...(Array.isArray(sx) ? sx : [sx]),
  ];

  return (
    <Alert id="error-display" severity="error" { ...closeProp } sx={ sxProp }>
      { getErrorMessage(error) }
    </Alert>
  )
};

export default ErrorStatus;

const getErrorMessage = (error: any) => {
  if(typeof error === "string") return error;
  if(error?.message) return error.message;
  if(error?.response?.data?.message) return error.response.data.message;
  if(error?.response?.data?.error) return error.response.data.error;
  if(error?.response?.data) return error.response.data;
  if(error?.response) return error.response;
  return "An unknown error occurred.";
};