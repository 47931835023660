import { useMemo, useRef, useState } from "react";
import { useClickAway, useToggle } from "react-use";
import { AccountCircle, Check, CheckCircle, MoreVert, SendOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, Divider, IconButton, Paper, Stack, TextField, Theme, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CommentThread, CommentWithOwner } from "types";
import { formatDateFromNow, formatDateString } from "helpers/general-helpers";

export interface ICommentCardProps {
  thread: CommentThread;
  onClick?: (id: string) => void;
  onSave?: (content: string, fieldKey: string) => void;
  onResolve?: (fieldKey: string) => void;
  isSelected?: boolean;
}

const CommentCard = ({ thread, onClick, onSave, onResolve, isSelected }: ICommentCardProps) => {
  const theme = useTheme();
  const cardRef = useRef<any>(null);
  const isNew = thread.comments.length === 0;
  const [showResolved, toggleResolved] = useToggle(false);

  //If the card is expanded, and they click elsewhere, close it.
  // useClickAway(cardRef, () => {
  //   if(isSelected && onClick) onClick(thread.itemKey);
  // }, ["click", "mouseDown"]);
  
  const [text, setText] = useState("");

  const { label, isResolved } = thread;

  const handleClick = (e: any) => {
    e.stopPropagation();

    if(isSelected || e.target.tagName === "BUTTON" || e.target.tagName === "INPUT" || e.target.tagName === "TEXTAREA") return;
    if(onClick) {
      onClick(thread.itemKey);
    }
  }

  const handleValueChanged = (e: any) => {
    setText(e.target.value);
  }

  const handleSend = () => {
    if(!!text && onSave) {
      onSave(text, thread.itemKey);
      setText("");
    }
  }

  const handleHeaderClick = (action: string) => (e: any) => {
    if(action === "resolve") {
      if(thread.isComplete || !onResolve) return;
      onResolve(thread.itemKey);
    }
    else if(action === "menu") {
      console.log("Menu", thread.itemKey);
    }
  };

  const headerColor = isSelected ? theme.palette.secondary.light : theme.palette.info.light;
  const appearance = useMemo(() => {
    if(isResolved) return { bgcolor: theme.palette.grey[200] };
    else if (isSelected) {
      if(isNew) return { bgcolor: alpha(theme.palette.info.light, 0.1) };
      else return { bgcolor: alpha(theme.palette.secondary.light, 0.2) };
    }
    else return { bgcolor: alpha(theme.palette.info.light, 0.1) };
  }, [isSelected, isResolved, isNew, theme]);


  return (
    <Paper ref={isSelected ? cardRef : null} onClick={handleClick} variant={(isSelected && !isNew) ? "elevation" : "outlined"} elevation={(isSelected && !isNew) ? 3 : 0} sx={{ width: "100%", borderRadius: 2, ...appearance }}>
      <Grid container>

        {/* Card Header */}
        <Grid container width="100%" sx={{ px: 1, py: 0.5, borderBottom: "1px solid", borderColor: "divider", borderRadius: "8px 8px 0 0", bgcolor: isResolved ? "grey.300" : alpha(headerColor, 0.2) }}>
          <Typography variant="caption">{label}</Typography>
        </Grid>
        
        {isSelected && <ResolvedNotification thread={thread} showResolved={showResolved} toggleResolved={toggleResolved}/> }

        {/* Card Body */}
        <Grid container rowGap={1} sx={{ px: 2, py: 1, }}>
          <ThreadHeader thread={thread} showResolved={showResolved} theme={theme} onClick={handleHeaderClick} />
          
          <Grid container xs={12}>
            <Stack width="100%">
              {thread.comments.length > 1 && !isSelected && (
                <Typography variant="caption" fontSize="0.8rem" color="grey.600">+ {thread.comments.length - 1} more</Typography>
              )}

              <ThreadHistory thread={thread} isSelected={isSelected} showResolved={showResolved} theme={theme} />
              
              {isSelected && (
                <Box sx={{ width: "100%" }}>
                  <TextField value={text} onChange={handleValueChanged} size="small" fullWidth multiline rows={2} variant="outlined" sx={{ bgcolor: "white", "& .MuiInputBase-input": { fontSize: "1rem", lineHeight: "18px" } }}/>
                  <Grid container justifyContent="flex-end" mt={0.5}>
                    <Button size="small" endIcon={<SendOutlined fontSize="small" />} onClick={handleSend}>
                      Send
                    </Button>
                  </Grid>
                </Box>
              )}
            </Stack>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CommentCard;

interface ICommentPartProps { 
  thread: CommentThread, 
  isSelected?: boolean, 
  showResolved?: boolean, 
  toggleResolved?: () => void;
  theme?: any;
}

const avatarColor = (comment: CommentWithOwner | undefined, theme: Theme) => {
  if(!comment) return theme.palette.primary.main;   //new comment, so it's mine
  const { isMine, isMyTeam } = comment;
  return isMine ? theme.palette.primary.main : (isMyTeam ? theme.palette.secondary.main : theme.palette.grey[500]);
};

const ResolvedNotification = ({ thread, showResolved, toggleResolved}: ICommentPartProps) => {
  if(thread.isResolved || !thread.hasResolved) return null;
  const resolvedCount = thread.comments?.filter(c => c.resolvedAt).length;
  const content = `${showResolved ? "hide" : "show"} ${resolvedCount} resolved comments`;

  return (
    <Grid container width="100%" py={0.5} px={1} sx={{ bgcolor: "grey.300" }}>
      <Button onClick={toggleResolved} size="small" color="secondary" sx={{ textTransform: "none", fontStyle: "italic", fontWeight: "500" }}>{content}</Button>
    </Grid>
  )
};

const ThreadHistory = ({ thread, isSelected, showResolved, theme }: ICommentPartProps) => {
  if(!isSelected || thread.comments.length === 0) return null;
  //If the thread is fully resolved, show all the comments. Otherwise, if the user has expanded the thread, show all, otherwise, show only unresolved.
  const items = thread.isResolved || showResolved ? thread.comments : thread.comments.filter(c => !c.resolvedAt);

  return (
    <Box className="thread-history">
      { items.slice(1).map((comment, index) => (
        <Box key={index}>
          <CommentHeader comment={comment} theme={theme} />
          <Typography variant="caption" color={!!comment.resolvedAt ? "grey.600" : undefined}>{comment.content}</Typography>
          <Divider sx={{ my: 1, borderColor: "divider"}} />
        </Box>
      ))}
    </Box>
  )
};

const CommentHeader = ({ comment, theme }: { comment: CommentWithOwner, theme: any }) => {
  const isResolved = !!comment.resolvedAt;

  return (
    <Grid container columnGap={1} mb={0.5}>
      <Grid container xs="auto" alignItems="center">
        {isResolved && (
          <Tooltip title={`Resolved on ${formatDateString(comment.resolvedAt)}`}>
            <Box sx={{ width: 24, height: 24, }}>  
              <CheckCircle fontSize="small" sx={{ color: "grey.400" }} />
            </Box>
          </Tooltip>
        )}
        {!isResolved && (
          <Tooltip title={formatDateFromNow(comment.createdAt)}>
            <Box sx={{ width: 24, height: 24, }}>            
              {comment.initial && <Avatar sx={{ width: 24, height: 24, fontSize: "1.1rem", fontWeight: "700", bgcolor: avatarColor(comment, theme), color: "white" }}>{comment.initial}</Avatar>}
              {!comment.initial && <AccountCircle fontSize="small" sx={{ color: avatarColor(comment, theme) }}/>}
            </Box> 
          </Tooltip>
        )}
      </Grid>
      <Grid container flexGrow={1} alignItems="center" justifyContent="space-between">
        <Typography variant="caption" fontSize="0.9rem" fontWeight="700" color={isResolved ? "grey.600" : undefined}>{comment.name}</Typography>
        <Typography variant="caption" fontSize="0.8rem" color="grey.600">{formatDateFromNow(comment.createdAt)}</Typography>
      </Grid>
    </Grid>
  );
};

interface IThreadHeaderProps extends ICommentPartProps {
  onClick: (action: string) => (e: any) => void;
}

const ThreadHeader = ({ thread, showResolved, theme, onClick }: IThreadHeaderProps) => {
  const isNew = thread.comments.length === 0;
  // const items = thread.comments; // showResolved ? thread.comments : thread.comments.filter(c => !c.resolvedAt);
  //If the thread is fully resolved, show all the comments. Otherwise, if the user has expanded the thread, show all, otherwise, show only unresolved.
  const items = thread.isResolved || showResolved ? thread.comments : thread.comments.filter(c => !c.resolvedAt);
  const firstComment = isNew ? undefined : items[0];
  const { startedByName: name, startedByInitial: initial, startedAt, isResolved } = thread;
  const isFirstResolved = !!firstComment?.resolvedAt;

  return (
    <Stack width="100%">
      <Grid container xs={12} columnGap={1}>
        {!isNew && (
          <Grid container xs="auto" alignItems="center">
            {isFirstResolved && (
              <Tooltip title="This comment thread has been resolved">
                <Box sx={{ width: 24, height: 24, }}>  
                  <CheckCircle fontSize="small" sx={{ color: "grey.400" }} />
                </Box>
              </Tooltip>
            )}
            {!isFirstResolved && (
              <Box>
                {initial && <Avatar sx={{ width: 24, height: 24, fontSize: "1.1rem", fontWeight: "700", bgcolor: avatarColor(firstComment, theme), color: "white" }}>{initial}</Avatar>}
                {!initial && <AccountCircle fontSize="small" sx={{ color: avatarColor(firstComment, theme) }}/>}
              </Box> 
            )}
          </Grid>
        )}
        <Grid container xs flexGrow={1}>
          <Stack>
            <Typography variant="caption" fontSize="0.9rem" fontWeight="700" color={isFirstResolved ? "grey.600" : undefined}>{name}</Typography>
            {!isNew && <Typography variant="caption" fontSize="0.8rem" color={isFirstResolved ? "grey.600" : undefined}>{formatDateFromNow(startedAt)}</Typography>}
          </Stack>
        </Grid>
        <Grid container xs="auto" alignItems="center" mr={-1.5}>
          {!isNew && (
            <>
              {!isResolved && (
                <Tooltip title="Resolve this thread">
                  <IconButton size="small"  onClick={onClick("resolve")}>
                    <Check fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
              <IconButton size="small" onClick={onClick("menu")}>
                <MoreVert fontSize="small" />
              </IconButton>
            </>
          )}              
        </Grid>
      </Grid>

      {!!firstComment && (
        <>
          <Typography variant="caption" color={isFirstResolved ? "grey.600" : undefined}>{firstComment.content}</Typography>
          <Divider sx={{ my: 1, borderColor: "divider"}} />
        </>
      )}
    </Stack>
  )
};