import _ from 'lodash';
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';

const _getConversations   = state => state.convo.conversations;
const _getSelectedConvo   = state => state.convo.selected;
const _getShares          = state => state.share.shares;
const _getInvitations     = state => state.share.invitations;
const _getFieldComments   = (state, fieldKey) => state.convo.currentComments[fieldKey];
const _getDirtyConversations = state => state.convo.dirty;
const _inItemId = (state, itemId) => itemId;


const _getProfile = state => state.app.profile;
const _getProviderCommentSetting = state => state.account?.account?.settings?.communication?.allowComments;
const _getPetitionerProviders = state => state.petitioner?.providers;

export const selectAllowComments = createSelector(
  [_getProfile, _getProviderCommentSetting, _getPetitionerProviders],
  (profile, attorneySetting, providers) => {
    if(!profile) return false;

    if(profile.isReviewer){
      return (attorneySetting === undefined) ? true : attorneySetting;
    }
    else if(providers && providers.length){
      const setting = providers[0].allowComments === undefined ? true : providers[0].allowComments;
      return setting;
    }
  }
);

export const selectConversations = createSelector(
  [_getConversations, _getShares, _getInvitations],
  (conversations, shares, invitations) => {
    if(!conversations) return null;

    const results   = _.map(conversations, convo => {
      const result   = prepareConversation(convo, shares, invitations);
      return result;
    });

    return results;
  }
);

export const selectCurrentTopic = createSelector(
  [_getConversations, _getSelectedConvo, _getShares, _getInvitations],
  (conversations, selected, shares, invitations) => {
    if(!conversations || !selected?.shareId || !selected?.topicId) return null;
    const conversation = conversations[selected.shareId];
    if(!conversation) return null;

    const topic = conversation.topics?.find(c => c.id === selected.topicId);
    if(!topic) return null;

    const prepared = prepareConversation(topic, shares, invitations);
    return prepared;

  }
);

export const selectDirtyByShare = createSelector(
  [_getDirtyConversations],
  (dirty) => {
    if(!dirty) return {};
    const byShare = dirty.reduce((accum, item) => { 
      return accum[item.shareId] ? accum : {...accum, [item.shareId]: true }; 
    }, {})
    return byShare;
  }
);

export const selectDirtyByTopic = createSelector(
  [_getDirtyConversations, _inItemId],
  (dirty, shareId) => {
    if(!dirty || !shareId) return {};
    const items = dirty.filter(d => d.shareId === shareId);
    const byTopic = items.reduce((accum, item) => { 
      return accum[item.id] ? accum : {...accum, [item.id]: true }; 
    }, {})
    return byTopic;
  }
);

export const selectTopicDirtyState = createSelector(
  [_getDirtyConversations, _inItemId],
  (dirty, props) => {
    if(!dirty) return {};
    const { shareId, topicId } = props;

    return dirty.find(item => item.shareId === shareId && item.id === topicId) !== undefined;
  }
);

export const selectFieldComments = createCachedSelector(
  [_getFieldComments],
  (comments) => {
    console.log(`comment selector called`); //`
    return comments || [];
  }
)(
  (_, fieldKey) => fieldKey //the cache key
);


//-----------------
// Helper function to build the conversation with the necessary properties
function prepareConversation(convo, shares, invitations){
  const share   = _.find(shares, share => share.id === convo.shareId);
  const invite  = _.find(invitations, inv => inv.id === convo.requestId);
  return {
    ...convo,
    share   : share,
    invite  : invite,
  };
}