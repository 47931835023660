import React, { ReactNode, useMemo } from "react";
import { Box, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Link, useNavigate } from "react-router-dom";

export interface IDocumentCardProps {
  title: string;
  headerImage?: any;
  subTitle?: string;
  url?: string;
  bgcolor?: any;
  clickable?: boolean;
  children?: ReactNode;
}

const ItemCard = ({ title, headerImage, subTitle, url, bgcolor, clickable, children}: IDocumentCardProps) => {
  const navigate = useNavigate();
  const linkProps = url ? { component: Link, to: url, sx: { ":hover": { color: "secondary.light"} } } : {};
  const backgroundColor = useMemo(() => bgcolor ? bgcolor : "white", [bgcolor]);

  const handleClick = (e: React.MouseEvent) => {
    if(!clickable || !url) return;

    // Check if the event's target or any of its parents is a button
    let target: any = e.target;
    while (target) {
      if (target.tagName === "BUTTON" || target.tagName === "A") return;
      target = target.parentNode;
    }

    //not a button or anchor, so let's navigate
    navigate(url);
  }

  return (
    <Paper variant="elevation" elevation={2} sx={{ display: "flex", minHeight: 175, height: "100%", position: "relative", bgcolor: backgroundColor }}>
      <Grid container direction="column" width="100%">
        <Grid>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 75, width: "100%", background: `url(${headerImage})`, backgroundSize: "cover", borderRadius: "3px 3px 0 0", position: "relative" }}>
            <Box color="white" bgcolor="rgb(0, 0, 0, 0.5)" width="90%" height="80%" borderRadius={1} sx={{ display: "flex", flexDirection: "column", justifyItems: "flex-start", alignItems: "center" }}>
              <Typography {...linkProps} color="white" fontSize="1.2rem" fontWeight="700" textAlign="center" mt={2}>{title}</Typography>
              {/* {subTitle && <Typography fontSize="1rem" fontWeight="400" color="grey.200" textAlign="center" px={2}>{subTitle}</Typography>} */}
            </Box>       
          </Box>
        </Grid>
        <Grid flexGrow={1} className="card-content"  onClick={handleClick} sx={{ cursor: clickable ? "pointer" : "default" }}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ItemCard;