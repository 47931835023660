import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Accordion, AccordionDetails, AccordionSummary, Icon, Tooltip, Typography } from '@mui/material';
import { ExpandMore as ExpandMoreIcon, CheckCircleOutline,  } from '@mui/icons-material';
import DataTableSection from './data-table-section';
import { asCurrency, calculateRegionTotal } from 'helpers';
import { useClientForm } from 'sections/collaborator/client-form-context';
import { useRegion } from 'sections/financials/parts/region-context';
import { useAppContext } from 'sections/app/app-context';
import FormEditorProvider, { useFormEditor } from 'sections/forms/form-editor-context';
import DataTableRegionToolbar from './data-table-region-toolbar';

const DataTableRegion = ({region, values, profile, isDefaultExpanded}) => {
  const classes         = buildStyles();
  const { isReviewer } = useAppContext();
  const { getRegionStatus } = useClientForm();
  const { fields: regionFields } = useRegion();
  const { isEditing } = useFormEditor();
  const [expanded, setExpanded] = useState(isDefaultExpanded);

  //If this is the petitioner, filter out any attorney-only sections
  const sections = useMemo(() => {
    if(isReviewer) return region.sections;
    else return region.sections.filter(sec => !sec.isAttorneyOnly);
  }, [region, isReviewer]);

  const panelHeaderId   = `panel-header-${region.id}`;
  const panelBodyId     = `panel-body-${region.id}`;
  const isComplete = useMemo(() => getRegionStatus(region.id), [getRegionStatus, region.id]);

  const isTotaled   = !!region.totals;
  const total       = isTotaled ? asCurrency(calculateRegionTotal(region, values, undefined, regionFields), "$0") : "";
  const isNegative  = Boolean(["deductions", "expenses", "debts"].indexOf(region.id) >= 0);

  // Effect to ensure the Accordion remains expanded based on a condition (e.g., isDefaultExpanded)
  useEffect(() => {
    setExpanded(isDefaultExpanded);
  }, [isDefaultExpanded]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    //don't let them collapse if they are editing
    if(expanded && isEditing) return;
    setExpanded(!expanded);
  };
  return (
    <Accordion expanded={expanded} onChange={handleAccordionChange(`panel-${region.id}`)} className={classes.panelRoot} slotProps={{ transition: { unmountOnExit: true } }}>
      <AccordionSummary className={classes.panelHeader} classes={{content: classes.panelHeaderContent, expandIcon: classes.expandIcon}} expandIcon={<ExpandMoreIcon />} aria-controls={panelBodyId} id={panelHeaderId}>    
        
        <Grid container flexWrap="nowrap" width="100%">
          <Grid xs={9} container alignItems="center" justifyItems="flex-start" flexGrow={1}>
            <Icon fontSize="small" color="inherit" className={classes.titleIcon}>{region.muiIcon}</Icon>
            <Typography className={classes.title}>{region.title}</Typography>
          </Grid>
          <Grid xs={1} container justifyContent="flex-end">
            {isComplete && (
              <Tooltip title="The client has marked this region complete" placement="top">
                <CheckCircleOutline color="success" fontSize="small" /> 
              </Tooltip>
            )}
          </Grid>
          <Grid xs={2} container alignItems="center" justifyContent="flex-end">
            <Typography className={clsx(classes.total, {[classes.neg]: isNegative})}>{total}</Typography>
          </Grid>
        </Grid>

      </AccordionSummary>
      <AccordionDetails className={classes.panelBody}>
        <Grid container direction="column">
          
          {/* Add a toolbar for attorneys to be able to edit the user's answers */}
          { isReviewer &&  <DataTableRegionToolbar region={region} /> }

          {/* Render each section */}
          { sections.map(sec => <DataTableSection key={sec.id} section={sec} values={values} profile={profile} isEditing={isEditing}/>) }
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

// export default DataTableRegion;

const WrappedDataTableRegion = (props) => {
  return (
    <FormEditorProvider regionId={props.region.id}>
      <DataTableRegion {...props} />
    </FormEditorProvider>
  );
};

export default WrappedDataTableRegion;

const buildStyles   = makeStyles(theme => ({
  panelHeader   : {
    fontSize    : 16,
    fontWeight  : 500,
    color       : theme.palette.primary.dark,
    "&.Mui-expanded" : {
      fontWeight  : 600,
      borderBottom  : `1px solid ${theme.palette.primary.light}`, //`
      background    : `${theme.palette.primary.main}22`, //`
      minHeight     : theme.spacing(6),
    }
  },
  panelHeaderContent  : {
    margin      : `${theme.spacing(1)} ${theme.spacing(0)} !important`, //`    
  },
  panelBody   : {
    padding     : `${theme.spacing(1)} ${theme.spacing(2)}`, //`
  },
  expandIcon  : {
    padding     : `${theme.spacing(0)} ${theme.spacing(1.5)}`, //`
  },
  titleIcon   : {
    marginRight   : theme.spacing(1),
    display       : "inline-block",
  },
  title   : {
    fontSize    : 16,
    fontWeight  : 500,
    display     : "inline-block",
    "& a, a:hover"   : {
      textDecoration  : "none",
      color           : theme.palette.primary.main,
    },
  },
  total   : {
    fontSize    : 16,
    fontWeight  : 500,
    marginRight   : theme.spacing(2),
  },
  neg : {
    color     : theme.palette.error.main,
  }
}));