import { Box, useTheme } from "@mui/material";
import React from "react"

export interface IAvatarIconProps {
  icon: React.ReactElement;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

const AvatarIcon = ({ icon, size }: IAvatarIconProps ) => {
  const theme = useTheme();
  const sizeProp = sizeProps[size || "md"];

  // Clone the icon element and set the fontSize and color attributes on it
  const clonedIcon = React.cloneElement(icon, { sx: { color: theme.palette.primary.contrastText, ...sizeProp.icon } });


  return (
    <Box className="outer-box" sx={{ border: "0.5px solid", borderColor: "grey.300", borderRadius: "50%", display: "flex", alignItems:"center", justifyContent: "center", ...sizeProp.outerBox }}>
      <Box className="inner-box" sx={{ bgcolor: theme.palette.primary.main, borderRadius: "50%", display: "flex", alignItems:"center", justifyContent: "center", ...sizeProp.innerBox  }}>
        { clonedIcon }
      </Box>
    </Box>
  );
};

export default AvatarIcon;

const sizeProps = {
  xs: {
    outerBox: {
      height: 32,
      width: 32,
      lineHeight: 32,
      p: "3px",
    },
    innerBox: {
      height: 24,
      width: 24,
    },
    icon: {
      fontSize: "1.1rem",
    }
  },
  sm: {
    outerBox: {
      height: 48,
      width: 48,
      lineHeight: 48,
      p: 0.5,
    },
    innerBox: {
      height: 40,
      width: 40,
    },
    icon: {
      fontSize: "1.85rem",
    }
  },
  md: {
    outerBox: {
      height: 64,
      width: 64,
      lineHeight: 64,
      p: 0.25,
    },
    innerBox: {
      height: 54,
      width: 54,
    },
    icon: {
      fontSize: "2.5rem",
    }
  },
  lg: {
    outerBox: {
      height: 72,
      width: 72,
      lineHeight: 72,
      p: 0.5,
    },
    innerBox: {
      height: 64,
      width: 64,
    },
    icon: {
      fontSize: "3.0rem",
    }
  },
  xl: {
    outerBox: {
      height: 90,
      width: 90,
      lineHeight: 90,
      p: 1,
    },
    innerBox: {
      height: 76,
      width: 80,
    },
    icon: {
      fontSize: "3.5rem",
    }
  },
}