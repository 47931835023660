import { Favorite, InfoOutlined, Mood } from "@mui/icons-material"
import { Alert, Box, Button, Paper, Typography, alpha, useTheme } from "@mui/material"
import Grid from "@mui/material/Unstable_Grid2/Grid2"

const AccountSubscriptionCard = () => {
  const theme = useTheme();

  return (
    <Paper id="account-profile" variant="outlined" sx={{ borderRadius: "0 0 8px 8px", width: "100%", minHeight: 500 }}>
      
      <Grid container xs={12} justifyContent="center" alignContent="center" height="100%">

        <Box sx={{ width: "50%", p: 4, borderRadius: 2, bgcolor: alpha(theme.palette.secondary.light, 0.2), textAlign: "center" }}>
          <Mood sx={{ fontSize: "3rem", color: theme.palette.secondary.main }} />
          <Typography fontWeight="400" color="grey.700">Thank you for participating in our Free Beta!</Typography>
        </Box>

      </Grid>
    
    </Paper>
  );
};

export default AccountSubscriptionCard;