import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CheckCircle, Close, CloudUpload, InfoOutlined } from "@mui/icons-material";
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { MuiFileInput } from "mui-file-input";
import { toast } from "react-toastify";
import { ProviderAccount } from "types";
import { loadAccountLogo, updateAccountProfile } from "store/actions/account-actions";
import { parseFbResult } from "store/store-helpers";
import { initializeProps } from "helpers/model-helpers";

const fields = ["name", "tagline", "summary", "logoFileName"];

export interface IAccountProfileCardProps {
  onboardingMode?: boolean;
  onSaved?: () => void;
}

const AccountProfileCard = ({ onboardingMode, onSaved }: IAccountProfileCardProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const accountProps = useSelector<any, ProviderAccount>(state => state.account.account);
  const [initialValues, setInitialValues] = useState<Record<string, any>>(initializeProps(accountProps, fields));
  const [props, setProps] = useState<Record<string, any>>(initialValues);
  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>();
  const hasChanges = useMemo(() => fields.some(f => initialValues[f] !== props[f]), [props, initialValues]);
  const hasExistingLogo = accountProps?.logoPath;

  useEffect(() => {
    if(accountProps?.logoPath && !accountProps?.logoUrl) dispatch(loadAccountLogo());
  }, [accountProps, dispatch]);

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleFileChange = (e: File | null) => {
    console.log("file change", e);
    setError(null);

    //don't take image files larger than 1MB
    if(e && e.size > 1024*1024){
      setError("Logo file size must be 1MB or less. Please choose a smaller file.");
      return;
    }

    setLogoFile(e);
    setProps({...props, logoFileName: e ? e.name : ""});
  }

  const handleSave = async () => {
    if(!props.name){
      setError("Name is required");
      return;
    }

    if(!hasChanges && onboardingMode && !!accountProps.name){
      //just skip this, they're not changing anything
      if(onSaved) onSaved();
      return;
    }

    const result = await dispatch(updateAccountProfile(props, logoFile));
    const pr = parseFbResult(result);
    if(pr.isOk){
      toast.success("Profile updated successfully");
      setInitialValues(initializeProps(props, fields));
      if(onboardingMode && onSaved) onSaved();
    }
    else {
      toast.error("Failed to save your profile, see the error above");
    }
  }

  return (
    <Paper id="account-profile" variant="outlined" sx={{ borderRadius: "0 0 8px 8px", width: "100%", minHeight: 500 }}>
      
      {!onboardingMode && (
        //Info banner
        <Grid container xs={12} justifyContent="space-between" alignItems="center" sx={{ bgcolor: "grey.200", borderBottom: "1px solid", borderColor: "grey.300", px: 2, py: 1 }}>
          
          <Grid container>
            <InfoOutlined sx={{ fontSize: "1.5rem", color: "grey.600", mr: 1, mt: 0.25 }} />
            <Typography fontSize="1.3rem" fontWeight="300" color="grey.600">Help your clients and prospects recognize you. This is part of your firm's public profile.</Typography>
          </Grid>

          <Button onClick={handleSave} variant="text" color="secondary" disabled={!hasChanges}>Save Changes</Button>

        </Grid>
      )}

      {/* Error Banner */}
      {error && (
        <Grid container xs={12} justifyContent="center" sx={{ bgcolor: alpha(theme.palette.error.light, 0.3), borderBottom: "1px solid", borderColor: "error.light", px: 2, py: 1 }}>
          <Typography fontSize="1.3rem" fontWeight="300" color="error.main">{error}</Typography>
        </Grid>
      )}

      <TableContainer sx={{ px: 2, py: 1 }}>
        <Table sx={{ fontSize: "1.1rem" }}>
          <TableBody>            
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Practice Name*</TableCell>
              <TableCell>
                <TextField id="name" required value={props.name} onChange={handleChange} size="small" fullWidth />
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Tagline</TableCell>
              <TableCell>
                <TextField id="tagline" value={props.tagline} onChange={handleChange} size="small" fullWidth />
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem", verticalAlign: "top" }}>Summary</TableCell>
              <TableCell>
                <TextField id="summary" value={props.summary} onChange={handleChange} multiline rows={3} size="small" fullWidth />
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>  
            <TableRow>
              <TableCell sx={{ color: "grey.600", width: "10rem" }}>Logo</TableCell>
              <TableCell>
                <MuiFileInput value={logoFile} onChange={handleFileChange} placeholder={hasExistingLogo ? accountProps?.logoFileName : "Add your logo"}
                  sx={{ bgcolor: (logoFile || hasExistingLogo) ? alpha(theme.palette.success.light, 0.1) : undefined }}
                  clearIconButtonProps={{ children: <Close fontSize="small" /> }}
                  InputProps={{ 
                    startAdornment: (logoFile || hasExistingLogo) ? <CheckCircle color="primary" /> : <CloudUpload color="primary"/>
                  }} 
                  inputProps={{
                    accept: "image/*"
                  }}
                />
                {/* Show the logo image here, if there is one */}
                {logoFile && (
                  <Box ml={2} display="inline-block">
                    <img src={URL.createObjectURL(logoFile)} alt="Logo" style={{ maxWidth: "100%", maxHeight: "60px" }} />
                  </Box>
                )}
                {!logoFile && accountProps?.logoUrl && (
                  <Box ml={2} display="inline-block">
                    <Tooltip title="This is your current logo">
                      <img src={accountProps.logoUrl} alt="Logo" style={{ maxWidth: "100%", maxHeight: "60px" }} />
                    </Tooltip>
                  </Box>
                )}
              </TableCell>
              <TableCell sx={{ width: "8rem" }}></TableCell>
            </TableRow>           

          </TableBody>
        </Table>
      </TableContainer>

      {onboardingMode && (
        <Grid container width="100%" justifyContent="flex-end" mt={2} px={2}>
          <Button onClick={handleSave} variant="contained" color="primary" disableElevation sx={{ width: "8rem" }} disabled={!hasChanges && !accountProps.name}>Next</Button>
        </Grid>
      )}

    </Paper>
  );
};

export default AccountProfileCard;