import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import PersonIcon from '@mui/icons-material/Person';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';

const UserTypes = ({value, setValue}) => {
  const classes   = buildStyles();
  const isLawyer  = Boolean(value === "reviewer");
  const isUser = Boolean(value === "user");

  return (
    <Grid id="user-type-toggle" container justifyContent="center" className={classes.userTypeRoot}>
      <Grid item xs={6} container justifyContent="center" direction="column" alignItems="center" className={clsx(classes.col, classes.leftCol, {[classes.selected] : isUser })} onClick={() => setValue("user")}>
        <PersonIcon />
        <Typography className={classes.text}>My lawyer invited me</Typography>
        {!isUser && <RadioButtonUncheckedIcon fontSize="small" className="check-icon"/>}
        {isUser && <CheckCircleIcon fontSize="small" className="check-icon"/>}
      </Grid>
      <Grid item xs={6} container justifyContent="center" direction="column" alignItems="center" className={clsx(classes.col, classes.rightCol, {[classes.selected] : isLawyer })} onClick={() => setValue("reviewer")}>
        <BusinessCenterIcon />
        <Typography className={classes.text}>I'm a lawyer</Typography>
        {!isLawyer && <RadioButtonUncheckedIcon fontSize="small" className="check-icon"/>}
        {isLawyer && <CheckCircleIcon fontSize="small" className="check-icon"/>}
      </Grid>
    </Grid>
  );
}

export default UserTypes;

const buildStyles   = makeStyles(theme => ({
  userTypeRoot  : {
    border      : `1px solid ${theme.palette.primary.light}`, //`
    borderRadius: 5,  
    position    : "relative",
  },
  col   : {
    padding     : theme.spacing(1),
    cursor      : "pointer",
  },
  rightCol  : {
    borderLeft  : `1px dotted ${theme.palette.primary.light}`, //`
    "& .check-icon"   : {
      position  : "absolute",
      top       : 5,
      right     : 5,
      fontSize  : 16,
    }
  },
  leftCol   : {
    "& .check-icon"   : {
      position  : "absolute",
      top       : 5,
      left      : 5,
      fontSize  : 16,
    }
  },
  text  : {
    fontSize    : 16,
    textAlign   : "center",
  },
  selected  : {
    background  : `${theme.palette.primary.light}33`, //`
    color       : theme.palette.secondary.main,
  },  
}));