import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AttachFile, CheckCircle, Close, CloudUpload, ErrorOutline } from "@mui/icons-material";
import { MuiFileInput } from "mui-file-input";
import { useStatus } from "redux-action-status";
import { ClientDocument } from "types";
import { StatusKeys } from "helpers";
import { addClientDocumentVersion } from "store/actions/attorney-actions";
import { parseFbResult } from "store/store-helpers";
import { ClientDocumentUpload } from "../../types/client-document-types";

export interface IUploadDocumentProps {
  document: ClientDocument;
  open: boolean;
  onClose: (uploadedCount: number) => void;
}

const defaultDoc = (document: ClientDocument): ClientDocumentUpload => {
  const { name, dueAt, isSharedWithClient } = document;
  return { name, versionChanges: "", dueAt, isSharedWithClient: isSharedWithClient ?? false, file: null };
};

const DocumentVersionUploadDialog = ({document, open, onClose}: IUploadDocumentProps) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { clientId } = useParams();
  const [props, setProps] = useState<ClientDocumentUpload>(defaultDoc(document));
  const [error, setError]   = useState<string>("");
  const isReady = useMemo(() => props.file !== null && props.name.length > 0, [props]);
  const status = useStatus(StatusKeys.clientDocuments);
  const isClientUpload = document?.direction === "fromClient";

  const filePlaceholder = props.file ? props.file.name : "Upload document";
  
  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleToggle = (value: boolean) => (e: any) => {
    setProps({...props, isSharedWithClient: value});
  }
  
  const onFileChange = (e: File | null) => {
    console.log("file change", e);
    setProps({...props, file: e});
  }

  const clearFields = () => {
    setProps({...props, versionChanges: "", file: null });
    setError("");
  }

  const onCancel = () => {
    onClose(0);
  }

  const onSave = async () => {
    console.log("save document", props);
    if(!props.file || !props.versionChanges) return;

    const result = await dispatch(addClientDocumentVersion(clientId!, document.id, props.file, props.versionChanges, props.isSharedWithClient));
    console.log("add document version result", result);
    const pr = parseFbResult(result);

    if(pr.isOk){
      clearFields();
      onClose(1);
    }
    else{
      setError(pr.error?.message ?? pr.error?.toString() ?? "An error occurred");
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(0)} fullWidth maxWidth="sm">
      <DialogTitle>
        <AttachFile fontSize="small" />
        Upload Document Version
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" color="textSecondary" mb={2}>
          Upload a new document version. Previous versions will be preserved.
        </Typography>
        
        {error && (
          <Alert icon={<ErrorOutline fontSize="inherit" />} severity="error">
            {error}
          </Alert>
        )}

        <Grid container>
          <Stack gap={2} width="100%" alignItems="center" justifyContent="center">
            
            <Grid container alignItems="center" width="100%" flexWrap="nowrap">
              <Grid xs={6}>
                <MuiFileInput value={props.file} onChange={onFileChange} disabled={status.isWorking} placeholder={filePlaceholder}  sx={{ mt: 1, bgcolor: props.file ? alpha(theme.palette.secondary.light, 0.3) : undefined }}
                  clearIconButtonProps={{
                    children: <Close fontSize="small" />
                  }}
                  InputProps={{
                    startAdornment: props.file ? <CheckCircle color="primary" /> : <CloudUpload />            
                  }} 
                > 
                </MuiFileInput>
              </Grid>
              <Grid xs={6} container justifyContent="flex-end" pl={2} height="100%" pt={1}>
                {!isClientUpload && (
                  // TODO: Can this be switched to ShareDocumentToggle?
                  <ToggleButtonGroup disabled={status.isWorking}>
                    <Tooltip title="Share this document with the client">
                      <ToggleButton value="yes" selected={props.isSharedWithClient} onChange={handleToggle(true)} size="small" color="secondary" sx={{ width: 150 }}>
                        Shared
                      </ToggleButton>
                    </Tooltip>

                    <Tooltip title="Do not share this document with the client">
                      <ToggleButton value="client" selected={!props.isSharedWithClient} onChange={handleToggle(false)} size="small" color="secondary" sx={{ width: 150 }}>
                        Not Shared
                      </ToggleButton>
                    </Tooltip>
                    
                  </ToggleButtonGroup>
                )}
              </Grid>
            </Grid>

            <TextField autoFocus value={props.name} disabled={true} margin="dense" id="name" label="Name" type="text" fullWidth />
            <TextField id="versionChanges" value={props.versionChanges} onChange={handleChange} required disabled={status.isWorking} margin="dense" label="Changes" placeholder="Describe what has changed in this version" type="text" fullWidth multiline rows={2}/>
            {/* <DatePicker value={currDate} onChange={handleDateChange} disabled={status.isWorking} label="Due Date" sx={{ width: "100%" }}/> */}
            {/* <TextField value={props.category} onChange={handleChange}  disabled={status.isWorking} margin="dense" id="category" label="Category" type="text" fullWidth/> */}
            {/* <TextField value={props.description} onChange={handleChange}  disabled={status.isWorking} margin="dense" id="description" label="Description" type="text" fullWidth multiline rows={4}/> */}


          </Stack>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" disabled={status.isWorking}>Cancel</Button>
        <Button onClick={onSave} disabled={!isReady || status.isWorking} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DocumentVersionUploadDialog;