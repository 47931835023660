import { AccountInvitation } from "types";
import { AccountLinkSchema, AccountProfile, ClientSchema } from "types/schema";
import { StatusKeys } from "helpers";
import { API_URL } from "./action-helpers";

const CREATED = "ACCOUNT_CREATED";
const LOADED = "ACCOUNT_LOADED";
const UPDATED = "ACCOUNT_UPDATED";
const MEMBERS_LOADED = "ACCOUNT_MEMBERS_LOADED";
const MEMBER_INVITED = "ACCOUNT_MEMBER_INVITED";
const MY_INVITES_LOADED = "MY_INVITES_LOADED";
const INVITE_ACCEPTED = "INVITE_ACCEPTED";
const INVITE_REMOVED = "INVITE_REMOVED";
const LOGO_DOWNLOADED = "LOGO_DOWNLOADED";
const MEMBER_REMOVED = "ACCOUNT_MEMBER_REMOVED";
const LINK_ADDED = "ACCOUNT_LINK_ADDED";

export const ACCOUNT_ACTIONS = {
  CREATED, LOADED, UPDATED, LOGO_DOWNLOADED, 
  MEMBERS_LOADED, MEMBER_INVITED,
  MY_INVITES_LOADED, INVITE_ACCEPTED,
  MEMBER_REMOVED, INVITE_REMOVED,
  LINK_ADDED,
};

//TODO: make this an environment variable
const baseUrl = `${API_URL}/accounts`;

export const loadAccount = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  
  //No account, so just initialize the state
  if(!accountId) {
    await dispatch(loadMyInvitationsApi());
    return dispatch({ type: LOADED, data: null, status: StatusKeys.accounts });    
  }

  const url = `${baseUrl}/${accountId}`;
  const accountResult = await dispatch({
    type: LOADED,
    fetch: {
      url,
      token: true,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });

  return accountResult;
};

export const loadAccountMembers = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  const url = `${baseUrl}/${accountId}/members`;
  const membersResult = await dispatch({
    type: MEMBERS_LOADED,
    fetch: {
      url,
      token: true,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });

  return membersResult;
};

export const loadMyInvitationsApi = () => async (dispatch: any, getState: any) => {
  const url = `${baseUrl}/invitations`;
  const invitationsResult = await dispatch({
    type: MY_INVITES_LOADED,
    fetch: {
      url,
      token: true,
    },
    statusKey: StatusKeys.accounts,
  });

  return invitationsResult;
};

export const createAccountApi = (plan: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const uid = state.app.profile.uid;

  const url = `${baseUrl}`;
  const body = { plan };

  const createAccountResult = await dispatch({
    type: CREATED,
    fetch: {
      url,
      verb: "POST",
      data: body,
      token: true,
    },
    uid,
    statusKey: StatusKeys.accounts,
  });

  return createAccountResult;
};

export const inviteMemberApi = (invitation: any) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  const url = `${baseUrl}/${accountId}/invitations`;
  if(invitation.email) invitation.email = invitation.email.toLowerCase();
  const body = invitation;

  const inviteResult = await dispatch({
    type: MEMBER_INVITED,
    fetch: {
      url,
      verb: "POST",
      data: body,
      token: true,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });

  //If we have a problem, need to finish anyway
  // if(!inviteResult.isOk) dispatch(finishStatus(StatusKeys.accounts));

  return inviteResult;
};

export const acceptInvite = (invitation: AccountInvitation) => async (dispatch: any, getState: any) => {
  const { accountId, id: inviteId } = invitation;
  const url = `${baseUrl}/${accountId}/invitations/${inviteId}/accept`;

  const acceptResult = await dispatch({
    type: INVITE_ACCEPTED,
    fetch: {
      url,
      verb: "POST",
      token: true,
    },
    accountId,
    inviteId,
    statusKey: StatusKeys.accounts,
  });

  if(acceptResult.isOk){
    //the above action will force a reload of the app
    //reload the user so we get the new custom claims
    // await reloadUser();
    //update the redux state
    // return await dispatch(loadAccountApi());
  }

  return acceptResult;
};

export const removeMember = (memberId: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  const url = `${baseUrl}/${accountId}/members/${memberId}`;
  const removeResult = await dispatch({
    type: MEMBER_REMOVED,
    fetch: {
      url,
      verb: "DELETE",
      token: true,
    },
    accountId,
    memberId,
    statusKey: StatusKeys.accounts,
  });

  return removeResult;
};

export const removeInvitation = (inviteId: string) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  const url = `${baseUrl}/${accountId}/invitations/${inviteId}`;
  const removeResult = await dispatch({
    type: INVITE_REMOVED,
    fetch: {
      url,
      verb: "DELETE",
      token: true,
    },
    accountId,
    inviteId,
    statusKey: StatusKeys.accounts,
  });

  return removeResult;
};

export const updateAccountProfile = (updates: AccountProfile, logoFile?: File | null) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  
  if(logoFile) {
    const logoPath = `${accountId}/files/${logoFile.name}`
    const logoResult = await dispatch({
      type: "LOGO_UPLOADED",  //don't do anything in the reducer
      firebase: {
        type: "uploadFile",
        path: logoPath,
        value: logoFile,
      },
      accountId,
      statusKey: StatusKeys.accounts,
    });

    if(logoResult.isOk) {
      // logoPath = logoResult.data;
      updates.logoPath = logoPath;
      updates.logoFileName = logoFile.name;
    }
  }
  
  if(updates.email) updates.email = updates.email.toLowerCase();

  const updateResult = await dispatch({
    type: UPDATED,
    firebase: {
      type: "updateSingle",
      collection: "accounts",
      key: accountId,
      value: updates,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });
  
  // const url = `${baseUrl}/${accountId}`;
  // const updateResult = await dispatch({
  //   type: UPDATED,
  //   fetch: {
  //     url,
  //     verb: "PUT",
  //     data: updates,
  //     token: true,
  //   },
  //   accountId,
  //   statusKey: StatusKeys.accounts,
  // });

  return updateResult;
};

export const loadAccountLogo = () => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;
  const { logoPath } = state.account.account;

  // const path = `${accountId}/files/${logoFileName}`;

  const result = await dispatch({
    type: LOGO_DOWNLOADED,
    firebase: {
      type: "getFileUrl",
      path: logoPath,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });
  
  return result;
};

//=== Account Links ====//
// These are links to 3rd party services that the account has connected to

export const addLink = (link: AccountLinkSchema) => async (dispatch: any, getState: any) => {
  const state = getState();
  const accountId = state.app.profile.accountId;

  
  //make sure all the propertes are present
  if(!link.provider || !link.username || !link.scopes || !link.expiration) {
    return { isOk: false, message: "Provider, username, scopes and expiration are required" };
  }

  const url = `${baseUrl}/${accountId}/links`;
  const body = {
    provider: link.provider,
    username: link.username,
    scopes: link.scopes,
    expiration: link.expiration,
  };

  const addResult = await dispatch({
    type: "LINK_ADDED",
    fetch: {
      url,
      verb: "POST",
      data: body,
      token: true,
    },
    accountId,
    statusKey: StatusKeys.accounts,
  });

  return addResult;
};