import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import helper from 'helpers/number-helper';
import DetailModal from './detail-modal';

export class CurrencyField extends React.Component {
    constructor(props){
        super(props);

        this.onMouseChange      = this.onMouseChange.bind(this);
        this.onToggleDetails    = this.onToggleDetails.bind(this);
        this.renderLabel        = this.renderLabel.bind(this);

        this.state  = {
            isMouseOver     : false,
            isDetails       : false
        };
    }

    onMouseChange(isInside){
        this.setState({isMouseOver: isInside});
    }

    //TODO: Should I Move this so there aren't copies on every one of the currency fields?
    async onToggleDetails(data){
        const isOpen    = this.state.isDetails;
        if(isOpen && data){
            await this.props.onChangeDetails(this.props.field.id, data.total, data.details);
        }

        this.setState({isDetails : !isOpen});
    }

    renderLabelChild(child, data){
        let value       = data[child.id];

        switch(child.type){
            case "checkbox":
                value   = value || false;
                return (
                    <div key={child.id} className="custom-control custom-checkbox my-1 mr-sm-2 field-child">
                        <input type="checkbox" className="custom-control-input" id={child.id} checked={value} onChange={() => {}}/>
                        <label className="custom-control-label" htmlFor={child.id}>{child.label}</label>
                    </div>
                );
            
            case "text":
                value = value || "";
                return (
                    <div key={child.id} className="my-1 mr-sm-2 field-child">
                        <input type="text" className="form-control form-control-small" id={child.id} placeholder={child.label} value={value} onChange={() => {}}/>
                    </div>
                );

            case "number":
                value   = value || "";
                return (
                    <div key={child.id} className="my-1 mr-sm-2 field-child">
                        <input type="text" id={child.id} className="form-control form-control-small" value={value} onKeyDown={helper.onlyNumbers} placeholder={child.label} onChange={() => {}}></input>
                    </div>
                );

            default: return null;
        }
    }

    renderLabel(field, data, details){
        let otherLabel          = null;
        if(field.isOther){
            const otherTextKey  = field.id + "Label";
            const otherText     = data[otherTextKey] || "";
            otherLabel          = <input id={otherTextKey} type="text" className="input-md form-control other-input" value={otherText} onChange={() => {}}></input>;
        }

        let childNodes          = null;
        if(field.children && field.children.length > 0){
            const children      = _.map(field.children, child => this.renderLabelChild(child, data));
            childNodes          = (
                <div className="field-children">
                    {children}
                </div>
            );
        }

        // let detailsButton       = null;
        // if(field.withDetails !== false){
        //     const toggleDetails     = details ? "text-primary" : (this.state.isMouseOver ? "" : "hidden");
        //     const btnClasses        = `detail-icon zmdi zmdi-format-list-bulleted ${toggleDetails}`;
        //     detailsButton           = <i className={btnClasses} onClick={this.onToggleDetails} title="Click to add/edit details"></i>;
        // }
        
        return (
            <React.Fragment>
                {field.label}
                {otherLabel}
                {/* {detailsButton} */}
                {childNodes}
            </React.Fragment>
        );
    }

    render(){
      const { field, data }   = this.props;
      //If there's no field, just return 
      if(!field) return <React.Fragment><td colSpan="2"></td></React.Fragment>;

      const value             = data ? data[field.id] : 0;
      const fieldDetails      = _.find(data.a_details, d => d.field === field.id);
      const label             = this.renderLabel(field, data, fieldDetails);        
      
      return (
        <>
          <td className={`${field.isTotal ? "text-right" : "col-label"}`} onMouseEnter={() => this.onMouseChange(true)} onMouseLeave={() => this.onMouseChange(false)}>
            {label}
          </td>
          <td className="col-other"></td>
          <td className="col-value field-currency">
            <CurrencyInput fieldKey={field.id} value={value}></CurrencyInput>
          </td>
          <DetailModal isOpen={this.state.isDetails} total={value} field={field} details={fieldDetails} onClose={this.onToggleDetails}/>
        </>
      );
    }
}

CurrencyField.propTypes = {
    field               : PropTypes.object.isRequired,
    data                : PropTypes.object,
    onChangeDetails     : PropTypes.func
}

export const CurrencyInput = ({fieldKey, value, onChange, isDisabled}) => {
    const currValue     = helper.asCurrency(value);
    const myChange      = onChange || (() => {});
    
    return <input 
        id={fieldKey} 
        type="text" 
        data-type="currency" 
        className="form-control" 
        onKeyDown={helper.onlyNumbers} 
        value={currValue} 
        onChange={myChange} 
        disabled={isDisabled}
    ></input>;
};