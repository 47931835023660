import { Divider, Stack, Typography } from "@mui/material"
import { CloudStorageSettingSchema } from "types/schema";
import CloudStorageMicrosoft from "./cloud-storage-microsoft";

export interface ICloudStorageSettingProps {
  settings?: CloudStorageSettingSchema,
  onUpdated: (newValue: Partial<CloudStorageSettingSchema> | null) => Promise<boolean>;
}

const CloudStorageSetting = ({ settings, onUpdated }: ICloudStorageSettingProps) => {
  return (
    <Stack p={2}>
      <Typography fontSize="1.2rem" color="primary">Cloud Storage</Typography>
      <Typography fontSize="1.1rem" color="grey.500">Link your account and easily synchronize client files with your cloud storage provider</Typography>

      <Divider sx={{ my: 2 }}/>

      <CloudStorageMicrosoft />  
      <Divider sx={{ my: 2 }}/>
    

    </Stack>
  );
}

export default CloudStorageSetting; 
