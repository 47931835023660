import { Alert, Stack } from '@mui/material';
import DataTable from '../../../components/values-table/data-table';

const ReviewSection = () => {
  return (
    <Stack width="100%" gap={3} justifyContent="center" justifyItems="center">
      <Alert severity="info" sx={{ width: "80%", ml: "15%" }}>
        Please review the information below for accuracy and completeness.<br/><strong>Click any row</strong> to leave a comment for or ask a question of your attorney. Click the <strong>Submit</strong> button when you are finished.
      </Alert>

      <DataTable isFirstExpanded={true}/>
    </Stack>
  );
}

export default ReviewSection;
