import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CommentOwner, CommentThread, CommentWithOwner } from "types";
import { useCommentOwner } from "helpers/selector-hooks-ts";
import CommentCard from "./comment-card";
import { useFormRegionComments } from "./form-hooks";
import { useRegionSelection } from "./parts/region-context";
import { resolveThread } from "store/actions/conversation-actions";

const CommentColumn = () => {
  const dispatch = useDispatch();
  const { selectedField, handleFieldSelected } = useRegionSelection();
  const { regionComments: comments, addComment, getLabel } = useFormRegionComments();
  const addOwner = useCommentOwner();

  //Which, if any, of the threads are selected
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [showResolved, setShowResolved] = useState(false);

  useEffect(() => {
    setSelectedId(selectedField?.id ?? null);
  }, [selectedField, addOwner]);

  const threads = useMemo(() => { 
    const withOwner = comments?.map<CommentWithOwner>(comment => ({...comment, ...addOwner(comment),  })) ?? [];
    const commentThreads = createThreads(withOwner);
    return commentThreads;
  }, [comments, addOwner]);

  const resolvedThreadCount = useMemo(() => threads?.filter(t => t.isResolved).length ?? 0, [threads]);

  const visibleThreads = useMemo(() => {
    if(selectedField) {
      const fieldThreads = threads.filter(t => t.itemKey === selectedField.id);
      const availableThreads = showResolved ? fieldThreads : fieldThreads.filter(t => !t.isResolved);

      //If there are no (available) threads for this field, create an empty one
      if(availableThreads.length === 0){
        const label = getLabel(selectedField.id);
        const emptyThread = createEmptyThread(selectedField.id, label, addOwner());
        return [emptyThread];
      }

      return availableThreads;
      // return threads.filter(t => t.itemKey === selectedField.id);
    }
    else if(!showResolved) return threads.filter(t => !t.isResolved); 
    else return threads;
  }, [threads, selectedField, showResolved, addOwner, getLabel]);
  
  const handleCardClicked = (id: string) => {
    const value = id === selectedId ? null : id;
    handleFieldSelected(value);
    setSelectedId(value);
  };

  const handleSaveComment = (content: string, fieldKey: string) => {
    return addComment(content, fieldKey);
  };

  const handleResolveThread = async (fieldKey: string) => {
    const result = await dispatch(resolveThread(fieldKey)) as any;
    if(result.isOk){
      handleFieldSelected(null);
      setSelectedId(null);
    }
  };

  const resolvedButtonContent = showResolved ? "Hide resolved comments" : `Show ${resolvedThreadCount} resolved thread${resolvedThreadCount > 1 ? "s" : ""}`;

  return (
    <Grid id="comments-sidebar" container width="100%" direction="column" rowGap={2} pr={2}>

      {/* <Divider flexItem sx={{ my: 1, width: "100%", fontSize: "1rem", color: "primary.main" }} >Comments</Divider> */}

      {resolvedThreadCount > 0 && (
        <Button onClick={() => setShowResolved(!showResolved)} size="small" sx={{ color: "grey.600", textTransform: "none", fontStyle: "italic", fontWeight: "500" }}>{resolvedButtonContent}</Button>
      )}
      {visibleThreads.map((thread: CommentThread, index: number) => (
        <CommentCard 
          key={index} 
          thread={thread} 
          onClick={handleCardClicked} 
          onSave={handleSaveComment} 
          onResolve={handleResolveThread} 
          isSelected={selectedId === thread.itemKey} 
        />
      ))}

      {visibleThreads.length === 0 && (
        <Typography variant="caption" color="grey.600" fontWeight="500" fontStyle="italic" textAlign="center">Click any field to ask a question</Typography>
      )}
    </Grid>
  );
}

export default CommentColumn;

//Rolls up the comments to be threads based on the field, ordered by date ascending
const createThreads = (comments: CommentWithOwner[]): CommentThread[] => {
  const threads: CommentThread[] = [];
  
  comments.forEach(comment => {
    const thread = threads.find(t => t.itemKey === comment.fieldKey);
    if(thread){
      thread.comments.push(comment);
    } else {
      threads.push({
        itemKey: comment.fieldKey,
        label: comment.fieldLabel,
        startedAt: comment.createdAt,
        startedBy: comment.createdBy,
        startedByName: comment.name,
        startedByInitial: comment.initial,
        comments: [comment]
      });
    }
  });

  return threads.map(thread => {
    const sortedComments = thread.comments.sort((a, b) => a.createdAt - b.createdAt);
    const resolvedComments = sortedComments.filter(c => !!c.resolvedAt);
    const isResolved = resolvedComments.length === sortedComments.length;
    // const allResolved = sortedComments.every(c => c.resolvedAt);
    return { ...thread, comments: sortedComments, isResolved, hasResolved: resolvedComments.length > 0 };
  });
};

const createEmptyThread = (fieldKey: string, label: string | undefined, owner: CommentOwner): CommentThread => {
  return {
    itemKey: fieldKey,
    label: label ?? fieldKey,
    startedAt: Date.now(),
    startedBy: "me",
    startedByName: "Do you have a question?",
    startedByInitial: owner.initial,
    comments: []
  };
};

// const prepareComment = (comment: Comment, addOwner: (comment: Comment) => CommentOwner): CommentWithOwner => {
  
//   return { 
//     ...comment, 
//     ...addOwner(comment) 
//     ...(comment.resolvedBy ? { resolvedByName:})
//   };
// };