import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import logo from "figure-ex-small-color.png";
import { redirectConsideringReroute } from 'helpers/general-helpers';
import { initialize } from 'store/actions/app-actions';
import { selectProfile } from 'store/selectors/app-selectors';
import { CircularProgress } from '@mui/material';

const Initializing = ({classes}) => {
  console.log("NAV: Initializing");

  const location      = useLocation();
  const dispatch      = useDispatch();
  const isInitialized = useSelector(state => state.app.isInitialized);
  const profile       = useSelector(selectProfile);
  const [isWaiting, setWaiting]   = useState(true);

  //Effect to initialize the app
  useEffect(() => {
    async function init(){
      const pWait   = new Promise((result) => { setTimeout(() => result(true), 1500); });
      const pInit   = dispatch(initialize());
      await Promise.all([pWait, pInit]);
      setWaiting(false);
    }
    if(!isInitialized) init();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!isWaiting && profile.isInitialized){
    return redirectConsideringReroute(location, "/app"); 
  }
  else{
    return (
      <Grid container direction="column" className={classes.root}>
        <div className="initialize-text-animation">
          <div id="start" className={classes.first}>
            <CircularProgress className={classes.circle}/>
            Initializing
          </div>
          <div id="second" className={classes.second}>
            <span>
                <img src={logo} alt="FormHop logo" className={classes.logo}/> FormHop
            </span>
          </div>
        </div>
      </Grid>
    );
  }
};

const styles    = {
    root    : {
        textAlign   : "center",
        color       : "#555",
        fontFamily  : '"Nunito Sans",Roboto',
        fontWeight  : 300,
        fontSize    : 32,
        paddingTop  : "30vh",
        height      : "100vh",
        overflow    : "hidden",
    },
    "@keyframes showup"     : {
        "0%"    : {opacity  : 0 },
        "20%"   : {opacity  : 1 },
        "80%"   : {opacity  : 1 },
        "100%"  : {opacity  : 0 },
    },
    "@keyframes slideup"    : {
        "0%"    : { marginBottom  : -300 },
        "20%"   : { marginBottom  : -300 },
        "35%"   : { marginBottom  : 0 },
        "100%"  : { marginBottom  : 0 },
    }, 
    "@keyframes slidein"    : {
        "0%"    : { marginLeft  : -800 },
        "20%"   : { marginLeft  : -800 },
        "35%"   : { marginLeft  : 0 },
        "100%"  : { marginLeft  : 0 },
    },    
    "@keyframes reveal" : {
        "0%"    : { opacity : 0, width  : 0 },
        "20%"   : { opacity : 1, width  : 0 },
        "30%"   : { width   : "100%" },
        "80%"   : { opacity : 1 },
        "100%"  : { opacity : 0, width   : "100%"},
    },
    first   : {
        animation   : "$showup 4s infinite"
    },
    second  :  {
        width       : 0,
        animation   : "$reveal 4s infinite",

        "& span"    : {
            // marginBottom: 0,
            // animation   : "$slideup 4s infinite",
            marginLeft  : 0, //-355,
            animation   : "$slidein 4s infinite",
        },
    },
    logo    : {
        marginBottom  : "-5px",
        maxHeight       : 32,
        maxWidth        : 32,
    },
    circle : {
        marginRight : 8,
    }
}

export default withStyles(styles)(Initializing);