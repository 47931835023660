import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import PetitionerFormView from "./petitioner-form-view";
import { ErrorAlert } from "components";
import { Button, Stack } from "@mui/material";
import { usePetitionerForm } from "./form-hooks";
import { useMemo } from "react";
import { ConversationProvider } from "components/values-table/conversation-context";
import PetitionerFormProvider from "../petitioner/contexts/petitioner-form-context";
import FormProvider from "../forms/form-context";
import RegionProvider from "./parts/region-context";

const FormContainerComponent = () => {
  const navigate = useNavigate();
  const { form } = usePetitionerForm();
  const error = useMemo(() => prettyError(form?.configError), [form?.configError]);
  
  return (
    <Grid id="form-container" container>
      { error && (
        <Grid xs={12} justifyItems="flex-start">
          <Stack>
            <ErrorAlert error={error} /> 
            <Button onClick={() => navigate(-1)} startIcon={<ArrowBackIcon />}>Go Back</Button>
          </Stack>
        </Grid>
      )}
      { !error && (
        <ConversationProvider>
          <PetitionerFormView /> 
        </ConversationProvider>
      )}
    </Grid>
  );
};

const FormContainer = () => {
  return (
    // TODO: the PetitionerFormProvider duplicates some of the FormProvider features, so refactor to remove dupes
    <FormProvider>
      <PetitionerFormProvider>
        <RegionProvider>
          <FormContainerComponent />
        </RegionProvider>
      </PetitionerFormProvider>
    </FormProvider>
  )
}
export default FormContainer;

const prettyError = (error: any) => {
  if(!error) return null;
  const msg = (typeof error === "string") ? error : error.message;

  if(msg) {
    if(msg.includes("does not exist")) return "This form is not properly configured.";
    return msg;
  }
  else return error.toString();
}