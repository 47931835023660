import { Link } from "react-router-dom";
import { Box, Button, Chip, IconButton, Stack, Theme, Tooltip, Typography, alpha } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckCircle, Forum } from "@mui/icons-material";
import { formatDateFromNow, formatDateString, formatShortDate } from "helpers/general-helpers";
import ClientFormProgress from "sections/collaborator/parts/chips/client-form-progress";
import ItemCard from "../parts/item-card";
const contractImage = require("./contract.jpg");

export interface IFormCardProps {
  form: any;
  theme: Theme;
}

const FormCard = ({ form, theme }: IFormCardProps) => {
  const status = form.status;
  const subHead = status?.isSubmitted ? "Completed Form" : "Form to Complete";
  const url = `/app/${form.shareId}/forms/${form.id}`;
  const isDirty = (form as any).isDirty ?? false;

  return (
    <ItemCard title={form.formName} url={url} subTitle={form.description} headerImage={contractImage} clickable>

      {isDirty && (
        <Tooltip title="You have unread comments about this form">
          <IconButton component={Link} to={`${url}/review`} sx={{ position: "absolute", top: 5, right: 5, bgcolor: theme.palette.secondary.light, color: "white" }} size="small"><Forum fontSize="small" /></IconButton>
        </Tooltip>
      )}

      <Stack sx={{ flexGrow: 1, justifyContent: "space-between", height: "100%" }}>
        <div>
          <Tooltip title="This is a form that your attorney has asked you to complete">
            <Typography mt={1} align="center" width="100%" fontWeight="800" color={alpha(theme.palette.primary.main, 0.75)} fontSize="1rem" textTransform="uppercase">{subHead}</Typography>
          </Tooltip>
          
          <Stack spacing={0.5} px={2} py={1} width="100%">
            {!status?.isSubmitted && (
              <>
                <Stack direction="row" justifyContent="space-between" width="100%">
                  <Typography variant="body1" fontSize="1.1rem" color="grey.500">Due:</Typography>
                  {form.dueAt && (
                    <Tooltip title={formatDateString(form.dueAt, "MMM D, YYYY")}>
                      <Typography variant="body1" fontSize="1.1rem">{formatDateFromNow(form.dueAt)}</Typography>
                    </Tooltip>
                  )}
                  {!form.dueAt && <Typography variant="body1" color="grey.600" fontStyle="italic">Not set</Typography>}
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignContent="center" alignItems="center" width="100%">
                  <Typography variant="body1" fontSize="1.1rem" color="grey.500">Progress:</Typography>
                  <Box width="9rem" sx={{display: "flex", justifyContent: "flex-end"}}>
                    <ClientFormProgress formInfo={status} />
                  </Box>
                </Stack>
              </>
            )}
            {status?.isSubmitted && (
              <Grid container width="100%" justifyContent="center" pt={2}>
                <Tooltip title={`You submitted this form on ${formatShortDate(status.submittedOn ?? status.updatedOn)}`}>
                  <Chip icon={<CheckCircle fontSize="small" />} label="Submitted" color="primary" />
                </Tooltip>
              </Grid>
            )}
            
            {form.notes && !status?.isSubmitted && <Typography fontSize="1rem" textAlign="center" px={1} pt={1}>{form.notes}</Typography>}
          </Stack>

        </div>

        <Stack px={2} py={1}>
          <Button component={Link} to={url} variant="text" color="secondary" size="small" fullWidth>{status?.isSubmitted ? "View" : "Open"} Form</Button>
        </Stack>
      </Stack>
    </ItemCard>
  );

};

export default FormCard;