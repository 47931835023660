import React from "react";
import { TableCell, TableRow, Tooltip } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { toast } from "react-toastify";
import { FormField } from "types";
import { asCurrency, calculateCalcField } from "helpers";
import { IDataTableListRowProps } from "../data-table-types";

// interface IDataTableListRowProps {
//   row: Record<string, any>;
//   rowIndex: number;
//   fields: FormField[];
// }

export function DataTableListRow({row, fields}: IDataTableListRowProps){
  if(!row) return null;
  let key   = 0;
  
  const handleCopyValue = (value: any) => (e: any) => {
    e.stopPropagation();
    const actualValue = (value === undefined || value === null) ? "- not set -" : value;
    navigator.clipboard.writeText(actualValue);
    toast.info(`Value copied to the clipboard`, { autoClose: 1500 })
  };

  return (
      <TableRow key={key++}>
        {
          fields.map((f, i) => {
            const value = getFieldValue(row, f);
            return (
              <React.Fragment key={i}>
                <Tooltip title="Click to copy value">
                  <TableCell key={key++} title={(f as any).header} sx={cellSx(f.type)} onClick={handleCopyValue(value)}>
                    {value}
                    {!!value && <ContentCopy sx={{ fontSize: "0.75rem", color: "secondary.main", alignSelf: "center", visibility: "hidden", ml: 0.25 }} />}
                  </TableCell>
                </Tooltip>
              </React.Fragment>
            )
          })
        }
      </TableRow>
  );
}

function getFieldValue(row: any, field: FormField, type?: string){
  const myType  = type !== undefined ? type : field.type;
  const value   = field.calculation ? calculateCalcField(field, row) : row[field.id];

  if(field.options){
    const iValue = parseIntSafe(value);
    const oVal  = field.options.find(o => o.id === iValue);
    // if(oVal === undefined || oVal === null) return value;
    return oVal ? oVal.label : field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    return asCurrency(value);
  }
  else if(field.calculation){
    //TODO: Calculate the field value

  }
  else{
    return value;
  }
}

const parseIntSafe = (value: any, defaultValue = 0) => {
  try{
    return parseInt(value);
  }
  catch(e){
    return defaultValue;
  }
}

const cellSx = (fieldType: string) => ({ 
  fontWeight: 300, 
  fontSize: 15, 
  color: "grey.900",
  p: 0.25,
  textAlign: fieldType === "currency" ? "right !important" : "left",
  borderBottom: "none",
  "&:hover": {
    "& .MuiSvgIcon-root": {
      display: "inline-block",
      visibility: "visible",
    }
  },
});