import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
// import AuthProviders from './auth-providers';
import StandardLogin from './standard-login';
// import TextDivider from 'components/text-divider';
import { signIn } from 'store/actions/app-actions';
import { useStatus } from 'redux-action-status';
import { Typography } from '@mui/material';
// import { StatusCard } from 'components';

function SignIn({tab, changeTab}){

  const classes       = useStyles();
  const dispatch      = useDispatch();
  const user          = useSelector(state => state.app.user);
  const profile       = useSelector(state => state.app.profile);
  const status        = useStatus("signIn");

  //They've already signed in, just need to enter their invitation
  if(user && !profile && changeTab) changeTab(1);

  const onAuthenticate = async (provider, username, password) => {
    await dispatch(signIn(provider, username, password));    
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      {/* {tab === 0 && (
        <>            
          <AuthProviders header="Sign in with" onAuth={onAuthenticate} isWorking={status.isWorking}/>
          <TextDivider text="or" cols={8} />
        </>
      )} */}
      <Typography fontSize="1.2rem" color="primary" sx={{ mt: 1, mb: 4 }}>Welcome back. Please sign in to get started.</Typography>
      <StandardLogin onAuth={(uname, pwd) => onAuthenticate(null, uname, pwd)} onToggle={(tabIndex) => changeTab(tabIndex)} tab={tab} isWorking={status.isWorking}/>

      {/* <StatusCard status="error" message={error} isVisible={Boolean(error)} /> */}
    </Grid>
  );
}

export default SignIn;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
}));