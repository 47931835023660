import { useState } from "react";
import { useSelector } from "react-redux";
import { useToggle } from "react-use";
import { AddTaskOutlined, CloudUploadOutlined, TaskAltOutlined } from "@mui/icons-material";
import { Divider, Paper, Stack, ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { StatusKeys, buttonStyles } from "helpers";
import { ErrorStatus, SearchField } from "components";
import ClientUploadsList from "./client-uploads-list";
import { selectClientUploads } from "store/selectors/attorney-selectors";
import ClientUploadRequest from "./client-upload-request-form";

export type UploadFilter = {
  search: string;
  requested: boolean;
  other: boolean;
}

const ClientUploadsCard = () => {
  const uploads = useSelector(selectClientUploads);
  const [isRequesting, toggleRequesting] = useToggle(false);
  const [filter, setFilter] = useState({ search: "", requested: true, other: true });
  
  return (
    <Paper id="client-uploads-card" variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: 2 }}>
      {/* Header Grid */}
      <Grid container sm={12} columnGap={1} sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderBottomColor: "grey.100", borderRadius: "6px 6px 0 0", px: 2, py: 1 }}>
        
        <Grid container gap={1} flexWrap="nowrap">
          <SearchField placeholder="search uploads" onChange={(val) => setFilter({...filter, search: val})} />          
        </Grid>
        
        <Grid container gap={1}>
          <Divider orientation="vertical" flexItem variant="middle" sx={{ mx: 1 }}/>
          
          <Tooltip title="Request a specific document from the client">
            <ToggleButton value={isRequesting} selected={isRequesting} onChange={() => toggleRequesting()} size="small" color="primary" sx={buttonStyles.toolbarSx}>
              <Stack direction="row" columnGap={0.5}>Request <AddTaskOutlined fontSize="small"/></Stack>
            </ToggleButton>
          </Tooltip>

          
          {/* <ToggleButtonGroup>
            <Tooltip title="Show / Hide Requested Uploads">
              <ToggleButton value="requested" size="small" color="primary" selected={filter.requested} onChange={() => { setFilter({...filter, requested: !filter.requested}); }} sx={buttonStyles.toolbarSx}>
                <TaskAltOutlined fontSize="small" />
              </ToggleButton>
            </Tooltip>

            <Tooltip title="Show / Hide Other Uploads">
              <ToggleButton value="other" size="small" color="primary" selected={filter.other} onChange={() => { setFilter({...filter, other: !filter.other}); }} sx={buttonStyles.toolbarSx}>
                <CloudUploadOutlined fontSize="small" />
              </ToggleButton>
            </Tooltip>
          </ToggleButtonGroup> */}
        </Grid>
      </Grid>

      {/* Body Grid */}
      <Grid container sm={12}>
        <ErrorStatus statusKey={StatusKeys.clientDocuments} sx={{ width: "100%" }} />
        {isRequesting && (
          <ClientUploadRequest items={uploads} onClose={toggleRequesting} />
        )}
        <ClientUploadsList isEmpty={uploads.length === 0} items={uploads} filter={filter}/>
      </Grid>
      
    </Paper>
  );  
};

export default ClientUploadsCard;