import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import { IconButton } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Person as PersonIcon, Group as GroupIcon, ContentCopy as FormIcon, ChevronLeft, ChevronRight, BadgeOutlined } from "@mui/icons-material";
import { useAppContext } from 'sections/app/app-context';
import SettingKeys from 'helpers/setting-keys';

const AttorneyNav = () => {
  const classes   = buildStyles();
  const { settingValue, saveSetting } = useAppContext();
  const [isCollapsed, setCollapsed]   = useState(settingValue(SettingKeys.app.layout.sidebarCollapsed));

  useEffect(() => {
    if(isCollapsed === true || isCollapsed === false){
      saveSetting(SettingKeys.app.layout.sidebarCollapsed, isCollapsed ?? false);
    }
  }, [isCollapsed, saveSetting]);

  return (
    <Grid container className={clsx(classes.navWrapper, {"collapsed": isCollapsed})} direction="column" justifyContent="flex-start">
      <NavLink to="/app" end className={classes.navItem}>
        <GroupIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Clients"}
      </NavLink>
      <NavLink to="/app/forms" end className={classes.navItem}>
        <FormIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Forms"}
      </NavLink>
      <NavLink to="/app/profile" end className={classes.navItem} title="My Profile">
        <PersonIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Profile"}
      </NavLink>
      <NavLink to="/app/account" end className={classes.navItem} title="My Account">
        <BadgeOutlined fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Account"}
      </NavLink>
      
      <IconButton size="small" className={classes.expandButton} onClick={() => setCollapsed(!isCollapsed)} title="Expand / Collapse Menu">
        {isCollapsed && <ChevronRight fontSize="small" />}
        {!isCollapsed && <ChevronLeft fontSize="small" />}
      </IconButton>
    </Grid>
  );
}

export default AttorneyNav;

const buildStyles   = makeStyles(theme => ({
  navWrapper      : {
    // padding     : theme.spacing(2),
    marginTop  : theme.spacing(4),
    width       : 180,

    "&.collapsed" : {
      width   : 60,
    }
  },  
  navItem   : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.grey[800],

    marginBottom  : theme.spacing(2),
    padding       : theme.spacing(1), //`${theme.spacing(1)} ${theme.spacing(1)}`, //`,
    borderRadius  : "0 5px 5px 0", 
    textDecoration  : "none",
    "&:hover"   : {
      textDecoration  : "none",
      background    : `${theme.palette.grey[400]}44`, //`
    },
    "&.active"  : {
      color         : theme.palette.secondary.main,
      background    : `${theme.palette.secondary.light}44`, //`
    }
  },
  navIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : theme.spacing(-1),   //`
  },
  expandButton : {
    marginTop     : theme.spacing(2),
    alignSelf     : "flex-start",
    marginLeft    : theme.spacing(1),
  },
  disabled  : {
    color       : theme.palette.grey[400],
  },
}));