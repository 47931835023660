import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import { WaitButton, ErrorAlert } from 'components';
import { sendInvite } from 'store/actions/share-actions';
import { useStatus } from 'redux-action-status';
import { rest } from 'lodash';
import { MenuItem } from '@mui/material';

const TYPE_MAP = {
  client: "Invite client",
  lawyer: "Invite your lawyer",
  share: "Share with someone",
  wait: "Invite from Waitlist",
  invite: "New Invitation",
  member: "Invite team member",
};

export interface IInviteDialogProps {
  isOpen: boolean;
  onClose: (success?: boolean) => void;
  type: "client" | "lawyer" | "share" | "wait" | "invite" | "member";
  sendCallback?: (model: any) => void;
  waitlistItem?: any;
  clientProfile?: any;

}

const InviteDialog = ({isOpen, onClose, type, sendCallback, waitlistItem, clientProfile}: IInviteDialogProps) => {
  const [props, setProps]   = useState({firstName: "", lastName: "", email: "", message: "", role: "member"});
  const status    = useStatus("requests");
  const dispatch  = useDispatch();

  useEffect(() => {
    if(waitlistItem){
      setProps(p => ({...p, firstName: waitlistItem.name, email: waitlistItem.email, message: "Welcome to FormHop!"}));
    }
  }, [waitlistItem]);

  useEffect(() => {
    if(clientProfile){
      setProps(p => ({...p, firstName: clientProfile.firstName, lastName: clientProfile.lastName, email: clientProfile.email}));
    }
  }, [clientProfile]);

  const canSave   = props.firstName.length > 0 && props.email.length > 0;

  const handleChange  = (e: any) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  }

  const handleSelectChange = (prop: string) => (e: any) => {
    const key   = prop;
    const val   = e.target.value;
    setProps({...props, [key]: val});
  }

  const onInvite = async () => {
    let model: any   = {
      ...props,
      requestType : type,   
    };

    //the role property is only for the member invite
    if(type !== "member") {
      const { role, ...rest } = model;
      model = rest;
    }

    if(sendCallback){
      sendCallback(model);
      setProps({firstName: "", lastName: "", email: "", message: "", role: "member"});
      onClose(true);
    }
    else{
      
      const result  = await dispatch(sendInvite(model)) as any;
      if(result.isOk){
        setProps({firstName: "", lastName: "", email: "", message: "", role: "member"});
        onClose(true);
      }
    }
  }

  const onDialogClose = () => {
    if(!status.isWorking) onClose();
  }

  const title   = TYPE_MAP[type]; //isFromUser ? "Invite your lawyer" : "Invite user";

  return (
    <Dialog open={isOpen} onClose={onDialogClose} maxWidth="xs">
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        {/* <Grid container justifyContent="center" spacing={2}> */}
          <Grid container spacing={2} sx={{ pt: 1 }}>
            {status.error && (
              <Grid item xs={12}>
                <ErrorAlert error={status.error} title="Invitation failed" />          
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField id="firstName" value={props.firstName} onChange={handleChange} variant="outlined" fullWidth label="First name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="lastName" value={props.lastName} onChange={handleChange} variant="outlined" fullWidth label="Last name *" />
            </Grid>
            <Grid item xs={12}>
              <TextField id="email" value={props.email} type="email" onChange={handleChange} variant="outlined" fullWidth label="Email address *" />
            </Grid>
            {type === "member" && (
              <Grid item xs={12}>
                <TextField id="role" select value={props.role} onChange={handleSelectChange("role")} variant="outlined" fullWidth label="Role *">
                  <MenuItem value="member">Member</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </TextField>
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField id="message" value={props.message} onChange={handleChange} variant="outlined" fullWidth multiline rows={3} label="Optional message" />
            </Grid>
          </Grid>
        {/* </Grid> */}
      </DialogContent>
      
      <DialogActions>
        <WaitButton color="primary" size="small" isWaiting={status.isWorking} disabled={!canSave} onClick={onInvite}>Send Invitation</WaitButton>
        <Button size="small" onClick={() => onClose(false)} disabled={status.isWorking}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default InviteDialog;