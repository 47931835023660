import nHelp from 'helpers/number-helper';
import fHelp from 'helpers/financials-helper';
import { useValue, useRowValue } from 'helpers/selector-hooks';
import { BooleanField, SelectField, SwitchField, ToggleField } from './v2.0.0';
import { Condition } from './condition';

export function InputField({ field, sectionId, rowIndex, dataProps, onChangeOverride, showLabel }) {
  if (field.condition) {
    return (
      <Condition field={field}>
        <StandardInputField field={field} dataProps={dataProps} onChangeOverride={onChangeOverride} showLabel={showLabel}/>
      </Condition>
    );
  }
  else {
    if (sectionId !== undefined && rowIndex !== undefined && rowIndex >= 0) {
      return (<RowInputField field={field} sectionId={sectionId} rowIndex={rowIndex} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
    }
    else {
      return (<StandardInputField field={field} dataProps={dataProps} onChangeOverride={onChangeOverride} showLabel={showLabel} />);
    }
  }
}

export function RowInputField({ field, sectionId, rowIndex, dataProps, onChangeOverride }) {
  const rowData = useRowValue(sectionId, rowIndex);
  const value = rowData ? rowData[field.id] : null;
  return (<CoreInputField field={field} value={value} rowIndex={rowIndex} rowData={rowData} dataProps={dataProps} onChangeOverride={onChangeOverride} />);
}

export function StandardInputField({ field, dataProps, onChangeOverride, showLabel }) {
  let value = useValue(field.id);
  return (<CoreInputField field={field} value={value} dataProps={dataProps} onChangeOverride={onChangeOverride} showLabel={showLabel}/>);
}


export const CoreInputField = ({ field, value, rowIndex, rowData, dataProps, onChangeOverride, showLabel = true }) => {
  console.log("** render input field");
  //TODO: eleminate the duplicate field ids, but need to wait until the onChange doesn't rely on the id...
  const elementId = field.id;
  const isUndefined = Boolean(value === undefined);
  let isDisabled = false;
  let classNames = `form-control form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  //NOTE: don't do anything in onChange, since it will bubble up automatically. But we need to include onChange or it will render read-only fields
  const onChange = (e) => {};

  switch (field.type) {
    case "text":
      if(!field.rows || field.rows === 1) {
        return <input key={field.id} type="text" id={elementId} className={classNames} value={value ?? ""} placeholder={field.placeholder ?? field.label} {...dataProps} autoComplete="off" onChange={onChange}></input>;
      }
      else{
        return <textarea key={field.id} id={elementId} rows={field.rows} className={classNames} value={value ?? ""} placeholder={field.label} {...dataProps} autoComplete="off" onChange={onChange}></textarea>;
      }

    case "number":
      return <input key={field.id} type="text" id={elementId} className={classNames} value={value} onKeyDown={nHelp.onlyNumbers} placeholder={field.label} {...dataProps} onChange={onChange}></input>;

    case "currency":
      if (field.calculation) {
        value = rowData ? fHelp.calculateCalcField(field, rowData) : "";
        isDisabled = true;
      }
      value = nHelp.asCurrency(value);
      // classNames += "field-currency";

      return <input key={field.id} type="text" data-type="currency" disabled={isDisabled} id={elementId} className={classNames} value={value} onKeyDown={nHelp.onlyNumbers} placeholder={field.label} {...dataProps} onChange={onChange}></input>;

    case "select":
      if(field.subtype === "toggle"){
        return <ToggleField field={field} dataProps={dataProps} value={value} />;
      }
      
      //TODO: If no options...?
      // const options = _.map(field.options, opt => <option key={opt.id} value={opt.id} disabled={opt.isDisabled}>{opt.label}</option>);
      // value = value ? value : field.options[0].id;
      // return <select key={field.id} id={elementId} className={classNames} value={value} {...dataProps} placeholder="choose" onChange={onChange}>{options}</select>;
      return <SelectField field={field} dataProps={dataProps} value={value} />;

    case "toggle":
      return <ToggleField field={field} dataProps={dataProps} value={value} />;

    case "switch": 
      return <SwitchField field={field} dataProps={dataProps} value={value} />;

    case "date":
      //TODO: switch this to a date picker
      return <input key={field.id} type="text" id={elementId} className={classNames} value={value} placeholder={field.label} {...dataProps} onChange={onChange}></input>;

    case "boolean":
    case "checkbox":
      return <BooleanField field={field} dataProps={dataProps} value={value} onChangeOverride={onChangeOverride} showLabel={showLabel} />;

    default:
      //TODO: what If I don't recognize the type
      return <span>[unrecognized field]</span>
  }
}