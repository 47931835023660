import { Box, Table, TableBody, TableCell, TableRow, Tooltip, Typography } from '@mui/material';
import { AccountCircleOutlined } from '@mui/icons-material';
import { FormSection } from 'types';
import { IDataTableSectionProps } from './data-table-types';
import DataTableRow from './data-table-row';
import DataTableListSection from './data-table-list';
import DataTableColumnarSection from './data-table-columnar-section';
import DataTableRowEditor from './data-table-row-editor';


const DataTableSection = ({profile, section, values, isEditing}: IDataTableSectionProps) => {
  const isList = section.type === "list";
  const isColumnar = ((section.config?.inputHeaders?.length) ?? 0) > 0;
  
  if(isList)
    return <DataTableListSection section={section} values={values} profile={profile} isEditing={isEditing}/>;
  else if(isColumnar)
    return <DataTableColumnarSection section={section} values={values} profile={profile} isEditing={isEditing}/>;
  else{
    const fields  = section.headerField ? [section.headerField, ...section.fields] : section.fields as string[];
    const rows = fields.map(f => {
      if(isEditing) return <DataTableRowEditor key={f} fieldKey={f} section={section}/>;
      else return <DataTableRow key={f} profile={profile} fieldKey={f} values={values} />;
    });

    return (
      <Table width="100%" className="data-table-section">
        <TableBody>
          <DataTableSectionHeader section={section} />
          {rows}
        </TableBody>
      </Table>
    );
  }
}

export default DataTableSection;

export interface IDataTableSectionHeaderProps {
  section: FormSection | any;
}

const DataTableSectionHeader = ({ section }: IDataTableSectionHeaderProps) => {
  if(!section) return null;
  const isAttorney = (section.isAttorneyOnly === true);

  return (
    <TableRow>
      <TableCell colSpan={3} sx={{ p: 0.5 }}>
        <Box sx={{ display: "flex", alignItems: "center", fontStyle: isAttorney ? "italic" : "none" }}>
          <Typography component="span" fontSize="16px" fontWeight="600" color="primary">{section.title ?? section.label }</Typography>
          {isAttorney && (
            <Tooltip title="This field is not visible to the client, and must be filled by an attorney" placement="top">
              <AccountCircleOutlined sx={{ fontSize: "1rem", color: "grey.600", marginLeft: 1 }} />
            </Tooltip>
          )}
        </Box>
      </TableCell>
    </TableRow>
  );
}