import { useMemo } from "react";
import { Box, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { FormField, FormSection } from "types";
import { useRegion } from "./region-context";
import { InputField } from "./input-field";
import SectionSubtitle from "./section-subtitle";

export interface ITableSectionHeaderProps {
  sectionId: string;
  onChange: (fieldId: string, total: number, details: any, index: number) => void;
}

const TableSectionHeader = ({sectionId, onChange}: ITableSectionHeaderProps) => {
  const { sections, getField } = useRegion();
  const section = useMemo<FormSection | undefined>(() => sections.find(s => s.id === sectionId), [sectionId, sections]);

  if(!section){
    console.error(`Section not found: ${sectionId}`);
    return null;
  }

  if(section.title){
    let titleCols   = 2;  //5
    let headerRow: any = null;

    if(section.headerField){    //jdf-1111.edStatus
      titleCols = 2;  //use 2 cols for this field
      const hField = getField(section.headerField);

      if(hField && hField.type === "question"){
        headerRow = (
          <TableRow>
            <TableCell colSpan={titleCols + 1} sx={{ borderBottomWidth: 0, pl: 1, py: 2 }}>
              <Grid container>
                <Grid sm={6}>
                  <Typography color="primary" fontSize="1.2rem">{section.title}</Typography>
                  <SectionSubtitle section={section} />
                </Grid>
                <Grid sm={6}>
                  <TableSectionHeaderQuestion field={hField} onChange={onChange}/>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        );
      }
    }
    else if(section.config?.inputHeaders){
      //The input column has multiple items (e.g. client 1, client 2)
      const headers = section.config.inputHeaders ?? [];
      const headerCols = headers.length;
      headerRow = (
        <>
          {/* Put the title and subtitle on it's own row, since space will be limited with the input header columns */}
          <TableRow>
            <TableCell sx={{ borderBottomWidth: 0, pl: 1, pt: 2 }} colSpan={headerCols + 1}>
              <Typography color="primary" fontSize="1.2rem">{section.title}</Typography>
              <SectionSubtitle section={section} />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ borderBottomWidth: 0, textAlign: "center" }} />
            {headers.map((header: string, index: number) => (
              <TableCell key={index} sx={{ borderBottomWidth: 0, textAlign: "center" }}>
                {/* <Box display="grid"> */}
                  <Typography color="grey.700" fontSize="1rem">{header}</Typography>
                  {/* {(index !== headers.length - 1) && <Divider orientation="vertical" variant="middle" sx={{ marginY: 0, justifySelf: "flex-end" }}/>} */}
                {/* </Box> */}
              </TableCell>
            ))}
          </TableRow>
        </>
      );
    }
    else {
      headerRow = (
        <TableRow>
          <TableCell colSpan={titleCols} sx={{ borderBottomWidth: 0, pl: 1, pt: 2 }}>
            <Typography color="primary" fontSize="1.2rem">{section.title}</Typography>
            <SectionSubtitle section={section} />
            {/* {(section.subTitle || section.desc) && <Typography color="grey.800" fontSize="1.2rem" fontStyle="italic" sx={{ my: 1 }}>{section.subTitle ?? section.desc}</Typography> } */}
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableHead className="table-section-header-cmp">
        {headerRow}
      </TableHead>
    );
  }

  return null;
};

export default TableSectionHeader;

interface ITableSectionHeaderQuestionProps {
  field: FormField;
  onChange: (fieldId: string, total: number, details: any, index: number) => void;
}

const REGEXP = /{\w+}/g;

const TableSectionHeaderQuestion = ({ field, onChange }: ITableSectionHeaderQuestionProps) => {
  const {label, params}    = field;
  let pIndex          = 0;
  
  const labelParts = label.split(REGEXP);
  if(labelParts.length === 1 || !params) return <Typography color="grey.700" fontSize="1rem">{label}</Typography>;
  
  const matches = label.match(REGEXP);
  const parts = labelParts.map((part: any, index: number) => {
    let node = null;
    if(pIndex < params.length ?? 0){
      const match = matches![pIndex++];
      const mId   = match.replace("{", "").replace("}","");
      const p     = params.find( p => p.id === mId); // {id: mId});
      node        = <InputField field={p} sectionId={undefined} rowIndex={0} dataProps={{ width: "auto" }} onChangeOverride={onChange} showLabel={true} />;
    }

    return (
      <Box key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
        <Typography color="grey.700" fontSize="1rem" component="span" sx={{ textWrap: "nowrap", mr: 2}}>{part}</Typography>
        <Box sx={{ width: "auto" }}>
          {node}
        </Box>
      </Box>
    );        
  });

  return (
    <>
      {parts}
    </>
  )
}