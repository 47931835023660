import { FormAssignment, ProviderInfo, Share } from 'types';
import { SHARE_ACTIONS } from '../actions/share-actions';
import helpers from './reducer-helpers';
import { APP_ACTIONS } from '../actions/action-types';
import { PETITIONER_ACTIONS } from "../actions/petitioner-actions";

export type PetitionerState = {
  forms: null | FormAssignment[],
  currentFormId: string | null,
  providers: ProviderInfo[];
};

const INITIAL_STATE = {
  forms: null,  
  currentFormId: null,
  providers: [],
};

//====
// Reducer functions

function sharesLoaded(state: PetitionerState, action: any) {
  if(action.isOk === false) return state;

  //we only want to recognize forms from shares that are accepted
  const filtered = action.data.filter((share: Share) => !!share?.sharer) as Share[];
  if(filtered.length === 0) return state;

  //add the share id to the form
  let forms = filtered.map((share: any) => share?.forms?.map((f: any) => ({...f, shareId: share.id})) ?? []).flat();
  if(forms.length === 0) return state;
  
  //merge these forms into the existing ones in state
  const updates = helpers.immMergeArrays(state.forms, forms, (f: any) => `${f.shareId}-${f.id}`)
  
  return {
    ...state,
    forms: updates, //forms,
  };
}

function providerLoaded(state: PetitionerState, action: any) {
  if(action.isOk === false) return state;

  const provider = action.data;
  return {
    ...state,
    providers: helpers.immAddOrReplace(state.providers, provider, (p: any) => p.id),
  };
}

function providerLogoLoaded(state: PetitionerState, action: any) {
  if(action.isOk === false) return state;

  const provider = state.providers.find((p: any) => p.id === action.accountId);
  const updated = { ...provider!, logoUrl: action.data };

  return {
    ...state,
    providers: helpers.immUpdateArray(state.providers, provider, updated)    
  };
}

function formChosen(state: PetitionerState, action: any) {
  return {
    ...state,
    currentFormId: action.formId,
  };
}

// function formConfigLoaded(state: PetitionerState, action: any) {
//   // const form = state.forms?.find(f => f.formId === action.formId);
//   // const updated = { ...form!, config: action.data };

//   // return {
//   //   ...state,
//   //   forms: helpers.immUpdateArray(state.forms, form, updated)    
//   // };
//   return state;
// }

// function formConfigError(state: PetitionerState, action: any) {
//   const form = state.forms?.find(f => f.formId === action.formId);
//   const updated = { ...form!, configError: action.error };

//   return {
//     ...state,
//     forms: helpers.immUpdateArray(state.forms, form, updated)    
//   };
// }

function onSignedOut(state: PetitionerState) {
  return { ...INITIAL_STATE };
}

//====
// Action Handlers
const ACTION_HANDLERS = {
  [SHARE_ACTIONS.SHARES_LOADED]: sharesLoaded,
  [PETITIONER_ACTIONS.PROVIDER_LOADED]: providerLoaded,
  [PETITIONER_ACTIONS.PROVIDER_LOGO_LOADED]: providerLogoLoaded,
  [PETITIONER_ACTIONS.CHOOSE_FORM]: formChosen,
  [APP_ACTIONS.SIGNED_OUT]    : onSignedOut,
};

//======
// The reducer
export default helpers.createReducer(INITIAL_STATE, ACTION_HANDLERS);