import React from 'react';
import { Link } from 'react-router-dom';


const NotFound = () => (
    <div className="jr-card">
        <div className="col-md-12 page-error page-404">
            <div className="number text-danger">404</div>
            <div className="details">
                <h3>Oops! You're lost.</h3>
                <p>
                    We can't find the page you're looking for.
                    <br/>
                    Perhaps you'd like to go <Link to="/app">Home</Link>
                </p>
            </div>
        </div>
    </div>
)

export default NotFound;