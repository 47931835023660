import { useState } from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@mui/material";
import AccountProfileCard from "./account-profile-card";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { StatusKeys } from "helpers";
import { ErrorStatus } from "components";
import { updateAccountProfile } from "store/actions/account-actions";
import { parseFbResult } from "store/store-helpers";
import { toast } from "react-toastify";
import AccountContactCard from "./account-contact-card";

const maxSteps = 2;

const ProviderOnboarding = () => {
  const dispatch = useDispatch();
  const [step, setStep] = useState<number>(1);

  const handleNext = async () => {
    if(step === maxSteps){
      const result = await dispatch(updateAccountProfile({ isOnboarded: true }));
      const pr = parseFbResult(result);
      if(pr.isOk){
        toast.success("Thank you for completing your profile");        
      }
      else {
        toast.error("Failed to complete onboarding, see the error above");
      }
      return;
    }

    setStep(step + 1);
  };

  const handlePrevious = () => {
    if(step > 1) setStep(step - 1);
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" width="100%" pt={2}>
      <Grid container direction="column" alignItems="center" mb={2}>
        <Typography variant="h5" color="primary">Welcome to FormHop</Typography>
        <Typography variant="body1" color="grey.500" mb={1}>Thank you for choosing to participate in our product beta.</Typography>        
      </Grid>
      
      <ErrorStatus statusKey={StatusKeys.accounts} />

      { step === 1 && <ProfileStep onNext={handleNext} /> }
      { step === 2 && <ContactStep onNext={handleNext} onPrevious={handlePrevious}/> }
    </Grid>
  );

};

export default ProviderOnboarding;

const ProfileStep = ({ onNext }: { onNext: () => Promise<void> }) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" width="100%">
      <Grid container direction="column" alignItems="center" mb={2}>
        <Typography variant="body1">To begin, please complete your practice profile.</Typography>
        <Typography variant="body2" color="grey.500">Name is the only required field, but more information will help your clients recognize you.</Typography>
      </Grid>
      <Grid>
        <AccountProfileCard onboardingMode onSaved={onNext}/>
      </Grid>
      
    </Grid>
  );
}

const ContactStep = ({ onNext, onPrevious }: { onNext: () => Promise<void>, onPrevious: () => void }) => {
  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" width="100%">
      <Grid container direction="column" alignItems="center" mb={2}>
        <Typography variant="body1">Next, please provide your firm's contact information.</Typography>
        <Typography variant="body2" color="grey.500">This step is optional, but will help your clients reach you.</Typography>
      </Grid>
      <Grid>
        <AccountContactCard onboardingMode onSaved={onNext} onBack={onPrevious}/>
      </Grid>
      
    </Grid>
  );
}