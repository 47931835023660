import { createTheme } from '@mui/material';
// import { experimental_extendTheme as extendTheme} from '@mui/material/styles';
import { blue, grey, teal } from '@mui/material/colors';

const fontList  = ['"Nunito Sans"', 'Roboto', 'sans-serif'];

//Customizations for the default Material UI Theme go here
const themeCustomizations   = {
    breakpoints     : {
        values  : {
            xs      : 0,
            sm      : 750,
            md      : 960,
            lg      : 1280,
            xl      : 1920,
        },
    },
    // colorSchemes: {
    //     light: {
    //         primary     : teal,
    //         secondary   : blue,
    //         // error       : {
    //         //     light       : "#fdd9d7",
    //         //     main        : "#7f231c",
    //         //     dark        : "#fccac7",
    //         // }, 
    //     }
    // },
    palette: {
        primary     : teal,
        secondary   : blue,
    },
    typography  : {
        //Override the default fonts and font weights
        fontFamily                 : fontList.join(','),
        // fontSize                   : 14,
        fontWeightLight            : 300,
        fontWeightRegular          : 400,
        fontWeightMedium           : 600,
        useNextVariants            : true,
        suppressDeprecationWarnings: true,
        htmlFontSize               : 12,
    },
    overrides   : {
        MuiCssBaseline: {
            '@global': {
                body: {
                    backgroundColor: '#f4f4f4',
                    fontSize: 14,
                    fontWeight: 400
                },
            },
        },        
        MuiIconButton  : {
            root    : {
                "&:focus"   : {
                    outline : 0,
                },
            },            
        },
        MuiButton   : {
            root    : {
                "&:focus"   : {
                    outline : 0,
                },
                color       : grey[600],
            },
            outlined : {
                "&:focus"   : {
                    outline : 0,
                },
            },
            textSizeSmall   : {
                fontSize    : 16,
            },
            sizeSmall   : {
                fontSize    : 16,
            }
        },
        MuiTabs     : {
            root    : {
                "& button:focus"    : {
                    outline     : 0,
                },
            },
        },
        MuiFilledInput  : {
            root    : {
                backgroundColor     : "unset",
            },
            input   : {
                paddingTop  : "10px",
                background  : "#0096881A !important", //#2979ff33 !important",
                borderRadius: "5px 5px 0 0 !important",
                "&$disabled" : {
                    background      : "#fafafa !important",                     
                },
            },                        
        },  
        MuiSelect   : {
            root  : {
                "&$focused" : {
                    background  : "unset",
                }
            }
        }      
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    "&::placeholder": {
                        color: grey[800],
                        opacity: 0.65,
                        fontSize: "1rem",
                    },
                }
            }
        }
    }
};

//-----------------
// Creates and returns the custom theme for the Portal
export const buildTheme = () => {
    const customized    = createTheme(themeCustomizations);
    // const customized    = extendTheme(themeCustomizations);
    return customized;
};

