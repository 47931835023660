import { createContext, useCallback, useContext, useMemo } from "react";
import { useInterval } from "helpers/hooks";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Comment, CommentWithOwner, Topic } from "types";
import { createComment, refreshCurrentComments } from "store/actions/conversation-actions";
import { REFRESH_INTERVAL, flattenComments } from "helpers/model-helpers";
import { useCommentOwner } from "helpers/selector-hooks-ts";
import { useAppContext } from "sections/app/app-context";

//=== Types & Context ===
export interface IConversationContext {
  topic: Topic;
  comments: Comment[];
  commentsByField: Record<string, CommentWithOwner[]>;
  getFieldComments: (fieldKey: string) => { hasComments: boolean, comments: Comment[], isResolved: boolean };
  addComment: (comment: string, fieldKey?: string) => Promise<any>;
}

export const ConversationContext = createContext<IConversationContext | null>(null);

//#region === Hooks ===
export const useConversationContext = () => {
  const context = useContext(ConversationContext);
  if (!context) throw new Error("useConversation must be used within a ConversationProvider");
  return context;
};

export const useFieldComments = (fieldKey: string) => {
  const { allowComments } = useAppContext();
  const { getFieldComments } = useConversationContext();

  if(!allowComments) return { hasComments: false, comments: [], isResolved: false };

  const fieldComments = getFieldComments(fieldKey);
  return fieldComments;
};
//#endregion

//=== Provider ===
export const ConversationProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const addOwner = useCommentOwner();
  const { topic, selected, currentComments } = useSelector<any, any>(state => state.convo);
  const { topicId, topicType } = selected;

  //Refresh the comments on a regular basis
  useInterval(() => {
    dispatch(refreshCurrentComments());
  }, REFRESH_INTERVAL);

  //Organize the comments by field
  const commentsByField: Record<string, CommentWithOwner[]> = useMemo(() => {
    if(topicType !== "f") return {};
    return currentComments ? flattenComments(currentComments, addOwner) : {};
  }, [currentComments, topicType, addOwner]);

  //Function to get comments by field
  const getFieldComments = useCallback((fieldKey: string) => {
    const comments = commentsByField[fieldKey] || [];
    const resolvedCount = comments.filter(c => c.resolvedAt).length;
    return { 
      hasComments: comments.length > 0, 
      comments,
      isResolved: comments.length > 0 && resolvedCount === comments.length,
    };
  }, [commentsByField]);

  //Function to add a new comment to the conversation
  const addComment = useCallback(async (comment: string, fieldKey?: string) => {
    if(!topicId) {
      console.error("No current topic for comment");
      return;
    }

    const result  = await dispatch(createComment(comment, fieldKey));
    return result;
  }, [topicId, dispatch]);

  const resolveThread = useCallback((fieldKey: string) => {
    console.log("Resolve", fieldKey);
  }, [dispatch]);

  //Context Value to provide
  const contextValue = useMemo(() => ({
    topic,
    comments: currentComments,
    commentsByField: commentsByField,
    getFieldComments,
    addComment,
  }), [topic, currentComments, commentsByField, getFieldComments, addComment]);

  return (
    <ConversationContext.Provider value={contextValue}>
      <Box id="conversation-context" width="100%">
        {children}
      </Box>
    </ConversationContext.Provider>
  );
}
