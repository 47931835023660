import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider, Paper, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { useStatus } from "redux-action-status";
import { StatusKeys } from "helpers";
import { SearchField } from "components";
import { ShareProvider } from "sections/app/share-context";
import MyConversationList from "./my-conversation-list";
import MyConversation from "./my-conversation";
import { chooseConversation } from "store/actions/conversation-actions";
import { Header } from "sections/financials/layout";
import MyConversationNavBar from "./my-conversation-navbar";

const MyConversationsComponent = () => {
  const { shareId, topicType, topicId } = useParams();
  const dispatch = useDispatch();
  const { isInitialized } = useStatus(StatusKeys.conversations);
  const [ filter, setFilter ] = useState({ search: "" });
  
  useEffect(() => {
    if(isInitialized){
      if(shareId) dispatch(chooseConversation(shareId, topicId, topicType ?? "g"));
      else dispatch(chooseConversation(null));
    }
  }, [isInitialized, shareId, topicId, topicType, dispatch]);

  return (
    <Grid id="my-conversations-root" container alignContent="flex-start" width="100%" height="100%">
      
      <Header />
      <MyConversationNavBar />

      <Grid container xs={12}  py={4} px={6} bgcolor="white" height="calc(100vh - 60px)">
        <Grid sm={12} container flexGrow={1} height="calc(100% - 8rem)">
          <Paper id="my-conversations-card" variant="outlined" elevation={0} sx={{ width: "100%", height: "100%", borderRadius: 2, mr: 2 }}>
            {/* Header Grid */}
            <Grid container sm={12} columnGap={1} flexWrap="nowrap" sx={{ bgcolor: "grey.100", borderBottom: "1px solid", borderBottomColor: "grey.200", borderRadius: "6px 6px 0.5px 0", px: 2, py: 1 }}>
              <Grid container sm={5} md={4}>

              </Grid>
              <Grid container sm={7} md={8}>
                <Divider orientation="vertical" sx={{ mr: 1 }}/>
                <SearchField placeholder="search selected conversation" onChange={(val) => setFilter({...filter, search: val})} />
              </Grid>
              
            </Grid>
            
            {/* Body Grid */}
            <Grid container sm={12} columnGap={1} flexWrap="nowrap" flexGrow="1" height="calc(100% - 3rem)">
              <Grid sm={4} sx={{ borderRadius: 2}}>
                <MyConversationList />
              </Grid>
              <Grid sm={8} sx={{ p: 2 }}>
                { shareId && <MyConversation /> }
                { !shareId && (
                  <Grid container sx={{ p: 3 }}>
                    <Typography variant="body1" color="grey.600">Select a conversation to view</Typography>
                  </Grid>            
                )}
              </Grid>
            </Grid>

          </Paper>
        </Grid>
      </Grid>

    </Grid>
  );
};


const MyConversations = () => {
  return (
    <ShareProvider>
      <MyConversationsComponent />
    </ShareProvider>
  );
};

export default MyConversations;