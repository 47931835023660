//TODO: Temporarily import the template json locally, but this will get moved to the database once
// this process if validated.
import { isUndefined } from "lodash";
import { FieldAdapter, FieldAdapters, TemplateAdapter, TemplateOptions } from "types";
import fHelper from "../financials-helper";
import adapters from "../../config/template-adapters";

///===
// Will adapt the client values for a couple, so that the template doesn't need to be changed to handle
// multiple clients. The adapter will take in the values map for the client, and the index of which
// client is being processed, and then if it is the second client, swap out values as necessary.
// Parameters:
// - values: Record<string, any> - The values for the client
// - templateOptions: string - this will be "client1" or "client2" for now, to select which client to adapt for
///===
export function runValuesAdapter(templateId: string, values: Record<string, any>, templateOption: TemplateOptions = "client1") {
  const config = getLocalTemplateAdapter(templateId);
  if(!config || (!config.fields && !config.adapters)) return values;  //no config for this template

  const { fields, adapters } = config;
  const adapter = (adapters && templateOption) ? adapters[templateOption] : undefined;
  //adapters first, to get a valid values object
  let templateValues = values;
  if(adapter) templateValues = adaptValues(values, adapter);

  //TODO: for now, we don't want to re-deploy the code every time we add a field to the template, so we're going to
  // skip this. In the future, when we use tools to build this, we can re-enable the field filtering.
  // if(fields && fields.length > 0) templateValues = filterFields(fields, templateValues);

  return templateValues;
};

//Gets the configuration for this template, and uses the templateOptions to load the adapter for this generation.
export const getLocalTemplateAdapter = (templateId: string): TemplateAdapter | undefined => {
  const adapter = adapters[templateId];
  if(!adapter) {
    console.warn(`No template adapter found for template ${templateId}`);
    return undefined;
  }
  return adapter;
};

//Applies the adapter for this generator run
const adaptValues = (values: Record<string, any>, adapter?: FieldAdapters, replaceKey = false) => {
  let adaptedValues = {...values};

  if(adapter) {
    
    const adaptions = Object.keys(adapter).reduce((acc, fieldKey) => {
      const fieldAdapter = adapter[fieldKey];
      let adaptedValue = adaptValue(fieldKey, fieldAdapter, values);
      
      if(adaptedValue === undefined) return acc;
      
      //If this is an array with children that need to be adapted, then handle that.
      if(fieldAdapter.children && Array.isArray(adaptedValue)){
        adaptedValue = adaptedValue.map((child: Record<string, any>) => adaptValues(child, fieldAdapter.children, true));
      }

      return {
        ...acc,
        [fieldKey]: adaptedValue,
      };
    }, {});

    adaptedValues = {
      ...adaptedValues,
      ...adaptions,
    };

    if(replaceKey === true){
      Object.keys(adapter).forEach((key) => {
        const keyToReplace = adapter[key].fieldId;
        delete adaptedValues[keyToReplace];
      });
    }
  }

  return adaptedValues;
};

//Adapts a specific field value based on the configuration
const adaptValue = (fieldKey: string, fieldAdapter: FieldAdapter, values: any) => {
  const { fieldId, fallback, if: ifValue, else: elseValue } = fieldAdapter;
  
  let valueKey = fieldId;   //the key for the value we will use

  if(!!ifValue){
    const isTrue = fHelper.isConditionMet(ifValue, values);
    //If the condition is met, then we continue with the fieldId below, as if there wasn't an if
    if(!isTrue && !!elseValue){
      valueKey = elseValue;     //if the condition isn't met, the else has the value key we're going to use
    }
  }
  
  let value = values[valueKey];
  if((isUndefined(value)) && !!fallback){
    value = values[fallback];
  }

  return value;
  
};

//Removes any unnecessary fields from the values object based on the template configuration
const filterFields = (fields: string[], values: Record<string, any>) => {
  //keep the value keys from the fields array, and any value keys that have an underscore in them
  const filteredValues = Object.keys(values).reduce((acc, key) => {
    if(fields.includes(key) || key.includes("_")){
      return {
        ...acc,
        [key]: values[key],
      };
    }
    return acc;
  }, {});

  console.log("filtered fields based on template config: ", filteredValues);
  return filteredValues;
};