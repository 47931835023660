// import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import DeletableTr from './deletable-tr';
import WaitContainer from './wait-container';
import IfBlock from './if-block';
import { useHover } from 'helpers/hooks';

const ItemListRow = ({isLoading, isDeleting, itemName, isWorking, colSpan, onCancelDelete, onDelete, children}) => {
  const classes   = buildStyles();
  // const [isDeleting, setDeleting]  = useState(false);
  const [isOver, onEnter, onLeave]  = useHover();

  return (
    <>
      <IfBlock condition={isLoading}>
        <tr className={classes.clientWaitRow} style={{textAlign: "center"}}>
          <td colSpan={5} >
            <WaitContainer isWaiting={true}/>
          </td>
        </tr>
      </IfBlock>
      <IfBlock condition={!isLoading}>
        <DeletableTr isDeleting={isDeleting} itemName={itemName} colSpan={colSpan} onDelete={onDelete} onCancel={onCancelDelete} isWorking={isWorking}>
            <tr className={clsx(classes.itemRow, {"over" : isOver})} onMouseEnter={onEnter} onMouseLeave={onLeave}>
              {children}
            </tr>
        </DeletableTr>
      </IfBlock>
    </>
  );
}

export default ItemListRow;

const buildStyles   = makeStyles(theme => ({
  itemRow   : {
    fontSize    : 14,
    fontWeight  : 500,
    color       : theme.palette.grey[700],
    "& td"  : {
      padding   : `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} `, //`,
      "& a"  : {
        textDecoration  : "none",
        color           : theme.palette.secondary.main,
        "&:hover"   : {
          textDecoration  : "underline",
        },
      },  
      "&.moreCol"   : {
        width       : 50,
        padding     : `${theme.spacing(0)} ${theme.spacing(1)}`, //`
        "& button"  : {
          display   : "none",
          "& svg"   : {
            fontSize  : 20,
            fontWeight  : 600,
          }
        },        
      }   
    },
    "&.over"  : {
      backgroundColor     : `${theme.palette.primary.light}11`, //`
    },
    "&.over .moreCol"  : {
      "& button"  : {
        display   : "unset",
      },
    },
  },
  deleteRow   : {
    background    : theme.palette.error.light,
    // color         : theme.palette.error.dark,
  },  
}));
