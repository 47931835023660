import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
// import { useDebounce } from "react-use";
import { Button, Typography, IconButton, Paper, Table, TableCell, TableHead, TableRow, Divider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { Refresh as RefreshIcon, AddCircleOutlineOutlined } from '@mui/icons-material';
import { TBodyWrapper } from 'components';
import ClientListItem from './client-list-item';
import { SHARE_CODES, isPending } from 'config/share-config';
import { useStatus } from 'redux-action-status';
import { ShareStatus } from 'types';
import { tableStyles } from 'helpers/style-helpers';
import { getShareStatus, sortClients } from "helpers/model-helpers";
import { useRefreshClients } from '../../attorney-hooks';
import { Link, useNavigate } from 'react-router-dom';
import { selectClientList } from 'store/selectors/attorney-selectors';
import { chooseClient } from 'store/actions/attorney-actions';
import { selectDirtyByShare } from 'store/selectors/conversation-selectors';
import { useAppContext } from 'sections/app/app-context';
import { useAttorneyContext } from '../../attorney-context';
import ClientListToolbar from './client-list-toolbar';

const TITLES = {
  accepted : "My Clients",
  pending : "Sent Invitations",
  all: "My Clients",
};

const ClientList = ({mode}) => {
  const dispatch = useDispatch();
  const classes     = buildStyles();
  const navigate = useNavigate();
  const { uid, allowComments } = useAppContext();
  const { members } = useAttorneyContext();
  const shareStatus = useStatus("shares");
  const profiles = useSelector(selectClientList);
  const unseenConvos = useSelector(selectDirtyByShare);
  const onRefresh = useRefreshClients();
  const [sort, setSort] = useState({ field: 'name', order: 'asc' });

  //Effect to add the unseen property to the profiles for conversations that are not viewed yet
  const items = useMemo(() => {
    const withUnseen = profiles.map(item => unseenConvos[item.shareId] ? { ...item, unseen: true } : item);
    const withShareStatus = withUnseen.map(item => ({...item, shareStatus: getShareStatus(item.share) }));
    return withShareStatus;
  }, [profiles, unseenConvos]);

  const [filter, setFilter] = useState({ mine: false, starred: true, unstarred: true, archived: false, active: true, invited: true, notinvited: true, unseen: false, search: "" });
  
  useEffect(() => {
    dispatch(chooseClient(null));   //if we're here, we don't have a specific client selected
  }, [dispatch]);
 
  const filtered = useMemo(() => {
    if(!items) return [];
    let filtered = items;
    if(filter.mine) filtered = filtered.filter(item => item.assignedTo === uid);
    if(filter.unseen) filtered = filtered.filter(item => item.unseen);

    // else {
      if(!filter.starred) filtered = filtered.filter(item => !item.isStarred);
      if(!filter.unstarred) filtered = filtered.filter(item => item.isStarred);
      if(filter.search?.length > 0) filtered = filtered.filter(item => item.searchString?.includes(filter.search));
      
      if(!filter.notinvited) filtered = filtered.filter(item => item.shareStatus !== ShareStatus.NOT_INVITED);   //remove not invited, means filter out ones without a request.invitedAt
      if(!filter.invited) filtered = filtered.filter(item => item.shareStatus !== ShareStatus.INVITED);   //remove invited, means filter out ones with a request.invitedAt
      if(!filter.active) filtered = filtered.filter(item => item.shareStatus !== ShareStatus.ACCEPTED);   //remove accepted, means filter out ones with a request.acceptedAt
      if(filter.archived) 
        filtered = filtered.filter(item => item.isArchived);   //remove archived, means filter out ones with a request.acceptedAt
      else 
        filtered = filtered.filter(item => !item.isArchived);   //remove archived, means filter out ones with a request.acceptedAt
    // }

    filtered = sortClients(filtered, sort, members);
    return filtered;
  }, [items, filter, sort, uid, members]);

  const isEmpty     = shareStatus.isInitialized && filtered.length === 0;
  
  const onAssign = (item) => {
    const clientId = item.share.sharer;
    navigate(`/app/clients/${clientId}/forms`);
  }

  const onSearchChange = (val) => {
    setFilter({...filter, search: val.toLowerCase()});
  }

  const handleColumnSort = (field) => {
    setSort(prev => ({
      field,
      order: prev.field === field && prev.order === 'asc' ? 'desc' : 'asc'
    }));
  };

  //TODO: add a Filter drop down to filter this client list ("accepted", "pending / invited")

  return (
      <Paper id="client-list-card" elevation={0} variant="outlined" sx={{ minHeight: 500, width: "100%", borderRadius: 2 }}>
        <Grid id="card-content" container direction="column">
          
          <Grid id="card-header" container alignItems="center" sx={{ height: 55, px: 2, borderBottomWidth: 1, borderBottomColor: "grey.300", borderBottomStyle: "solid", backgroundColor: "grey.200" }}>
            
            <Grid sm={10} container alignContent="center" alignItems="center" gap={2}>
              <Typography variant="h5" sx={{ fontSize: "1.2rem", fontWeight: "700", color: "grey.800" }}>{TITLES[mode ?? "all"]}</Typography>
              
              <Divider orientation="vertical" flexItem />

              <Button component={Link} to="/app/clients/new" size="small" startIcon={<AddCircleOutlineOutlined fontSize="small" />} >New Client</Button>

            </Grid>
            <Grid container sm={2} alignContent="center" justifyContent="flex-end">
              <IconButton size="small" color="secondary" title="Refresh the client list" className={classes.refreshButton} onClick={onRefresh}>
                <RefreshIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
          
          <ClientListToolbar filter={filter} setFilter={setFilter} onSearchChange={onSearchChange} allowComments={allowComments} />

          <Grid container className={classes.cardBody}>
            <Table id="client-table" sx={tableStyles.table}>
              <TableHead>
                <TableRow sx={tableStyles.headerRow}>
                  <TableCell 
                    sx={{...tableStyles.cell, cursor: 'pointer'}} 
                    className="active"
                    onClick={() => handleColumnSort('name')}
                  >
                    Name {sort.field === 'name' && (sort.order === 'asc' ? '▲' : '▼')}
                  </TableCell>
                  <TableCell 
                    sx={{...tableStyles.cell, cursor: 'pointer'}}
                    onClick={() => handleColumnSort('assignedTo')}
                  >
                    Assigned To {sort.field === 'assignedTo' && (sort.order === 'asc' ? ' ▲' : ' ▼')}
                  </TableCell>
                  <TableCell sx={tableStyles.cell}></TableCell>
                  <TableCell sx={tableStyles.cell}>Forms</TableCell>
                  <TableCell 
                    sx={{...tableStyles.cell, cursor: 'pointer'}}
                    onClick={() => handleColumnSort('shareStatus')}
                  >
                    Status {sort.field === 'shareStatus' && (sort.order === 'asc' ? ' ▲' : ' ▼')}
                  </TableCell>
                  <TableCell sx={tableStyles.cell}></TableCell>
                </TableRow>
              </TableHead>
              <TBodyWrapper colSpan={5} isWorking={shareStatus.isWorking} isEmpty={isEmpty}>
                {filtered ? _.map(filtered, i => <ClientListItem key={i.id} item={i} mode={mode} onAssign={onAssign} />) : null}
              </TBodyWrapper>
            </Table>
          </Grid>
        </Grid>

        {/* <InviteDialog type="client" isOpen={props.isInviting} onClose={() => setProps({...props, isInviting: false})}/> */}
            
      </Paper>
  );
}

export default ClientList;

const buildStyles   = makeStyles(theme => ({
  title   : {
    fontSize    : 15,
    fontWeight  : 700,
    color       : theme.palette.grey[800],
  },
  subTitle  : {
    fontSize  : 17,
    fontWeight: 500,
    textAlign : "center",
    marginBottom  : theme.spacing(3),
    color     : theme.palette.grey[800],
  },
  cardHeader    : {
    background      : theme.palette.grey[100],
    height          : 45,
    padding         : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  actionButton  : {
    fontSize        : 15,
    fontWeight      : 500,
    textTransform   : "none",
  },
  refreshButton   : {
    float           : "right",
  },
  filterRow     : {
    height          : 45,
    padding         : theme.spacing(2),
    borderBottom    : `1px solid ${theme.palette.grey[300]}`, //`
  },
  errorCard   : {
    width           : "80%",
  },
  cardBody      : {
    // marginTop       : theme.spacing(2),
  },
  clientTable   : {
    borderCollapse  : "collapse",
    width           : "100%",
    "& .table-header"   : {
      background    : theme.palette.grey[200],
      borderBottom  : `1px solid ${theme.palette.grey[300]}`, //`
      height        : 45,
      padding       : `${theme.spacing(0.5)} ${theme.spacing(1)}`, //`
    },
    "& th"  : {
      fontSize    : 12,
      fontWeight  : 500,
      textAlign   : "left",
      border      : "none",
      padding     : theme.spacing(1),
      color       : theme.palette.grey[700],
      "&.active"  : {
        fontWeight    : 600,
        color         : theme.palette.grey[900],
      }
    }
  },
    
}));

//TODO: need to adjust these for the ClientListProfile type...
// const filterFunc = (mode) => {
//   if(mode === 0) return (item) => true;
//   else if(mode === 1) return (item) => item.status === SHARE_CODES.accepted;   //accepted / active
//   else if(mode === 2) return (item) => isPending(item);   //invited / pending
//   else if(mode === 3) return item => item.status === SHARE_CODES.archived;   //archived
//   else return (item) => true;  
//   // return (item) => (mode === "accepted") ? item.status === SHARE_CODES.accepted : isPending(item);
// }

// function isPending(item){ return (!item.status || item.status === SHARE_CODES.pending); };