import { createContext, useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProviderInfo, Share } from "types";
import { loadProviderInfo, noProviders } from "store/actions/petitioner-actions";
import { selectPrimaryProvider, selectProviders } from "store/selectors/petitioner-selectors";

export type IPetitionerContext = {
  shares: Share[];
  providers: ProviderInfo[];
  primaryProvider: ProviderInfo | null;
}

export const PetitionerContext = createContext<IPetitionerContext | null>(null);

export const usePetitionerContext = () => {
  const context = useContext(PetitionerContext);
  if(!context) throw new Error("usePetitionerContext must be used within an PetitionerProvider.");
  return context;
};

const PetitionerProvider = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useDispatch();
  const shares = useSelector<any, Share[]>(state => state.share.shares);
  const providers = useSelector<any, ProviderInfo[]>(selectProviders);
  const primaryProvider = useSelector(selectPrimaryProvider);
  
  const contextValue = useMemo(() => ({ providers, primaryProvider, shares }), [primaryProvider, providers, shares]);

  useEffect(() => {
    async function fetchProviders(){
      const missing = shares.filter(share => !providers.find(provider => provider.id === share.accountId));
      await Promise.all(missing.map(share => dispatch(loadProviderInfo(share.accountId))));
    }

    if(shares?.length > 0) fetchProviders();
    else if(shares && shares.length === 0) dispatch(noProviders());
  }, [shares, providers, dispatch]);

  return (
    <PetitionerContext.Provider value={contextValue}>
      {children}
    </PetitionerContext.Provider>
  )
};

export default PetitionerProvider;