import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { OverlayScrollbarsComponent as ScrollContainer } from 'overlayscrollbars-react';
import CommentColumn from './form-comments-sidebar';
import { SummaryContainer } from './layout';
import { FeedbackPanel } from 'components';
import { useFormRegionComments } from './form-hooks';
import { useRegionSelection } from './parts/region-context';
import { FormNavigationCard } from './layout/sidebar';

export interface IFormSidebarProps {
  hasSummary: boolean;
  reposition: () => void;
}

const FormSidebar = ({ hasSummary, reposition }: IFormSidebarProps) => {
  // const { regionComments } = useFormRegionComments();
  const { selectedField } = useRegionSelection();
  // if(!hasSummary && !regionComments?.length && !selectedField) return null;
  
  return (
    <ScrollContainer defer style={{ width: "100%", height: "100%" }} options={{ scrollbars: { autoHide: "move" } }} >
      
      {/* <Grid container width="100%" pr={2}>
        <FormNavigationCard reposition={reposition} />
      </Grid> */}

      {hasSummary && !selectedField && (
        <>
          <SummaryContainer />
          <FeedbackPanel />
        </>
      )}
      {(!hasSummary || selectedField) && (
        <CommentColumn />
      )}
    </ScrollContainer>
  );
}

export default FormSidebar;