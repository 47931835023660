import { Box, LinearProgress, Stack, Typography } from "@mui/material";

export interface ILoadingBarProps {
  id?: string;
  isHidden?: boolean;
  message?: string;
  sx?: any;
}

const LoadingBar = ({ id="loading-bar", isHidden, message = "Loading...", sx}: ILoadingBarProps) => {
  if(isHidden) return null;

  return (
    <Box id={id} width="100%" display="flex" justifyContent="center" alignItems="center" sx={sx ?? {}}>
      <Stack rowGap={2} width="100%" alignItems="center">
        <LinearProgress color="primary" sx={{ width: "100%" }} />
        <Typography variant="body2" color="grey.500">{message}</Typography>
      </Stack>
    </Box>
  );
};

export default LoadingBar;