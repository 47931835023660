import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
// import makeStyles from '@mui/styles/makeStyles';
import { ItemList } from 'components';
import { useStatus } from 'redux-action-status';
import { createInvitation, loadInvitations, loadProfiles } from 'store/actions/admin-actions';
import InvitationItem from './invitation-item';
import InvitationDialog from '../common/invitation-dialog';
import { selectInvitations } from 'store/selectors/admin-selectors';
import UserDialog from '../common/user-dialog';
import { InviteDialog } from 'components/index';

const InvitationsView = () => {
  // const classes                   = buildStyles();
  const [view, setView]           = useState("all");
  const [invitation, setInvitation]   = useState(null);
  const [user, setUser]           = useState(null);
  const [isInviting, setInviting] = useState(false);
  const items  = useSelector(selectInvitations);
  const status    = useStatus("invitations");
  const dispatch  = useDispatch();

  const filtered  = filterList(items, view);
  // const isEmpty   = status.isInitialized && filtered.length === 0;

  useEffect(() => {
    if(!items){
      onRefresh();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);

  function onRefresh(){
    dispatch(loadInvitations());
    dispatch(loadProfiles());
  }

  function onAdd(){
    setInviting(true);
  }

  function onAction(action, item){
    if(action === "view_invitation"){
      setInvitation(item);
    }    
    else if(action === "view_user"){
      setUser(item);
    }
  }

  async function onSaveInvite(model){
    const result  = await dispatch(createInvitation(model));
    if(result.isOk){
      setInviting(false);
    }
  }

  return (
    <>
      <ItemList config={listConfig} items={filtered} status={status} onRefresh={onRefresh} onFilter={v => setView(v)} onAction={onAction} ItemComponent={InvitationItem} onAdd={onAdd}/>
      <InvitationDialog isOpen={Boolean(invitation)} onClose={() => setInvitation(null)} invitation={invitation}/>
      <UserDialog isOpen={Boolean(user)} onClose={() => setUser(null)} profile={user} />
      <InviteDialog isOpen={isInviting} onClose={() => setInviting(false)} type="invite" sendCallback={onSaveInvite} />
      
    </>
  );
}

export default InvitationsView;

// const buildStyles   = makeStyles(theme => ({  
// }));

function filterList(list, view){
  switch(view){
    case "all"  : return list;
    case "invited"  : return _.filter(list, item => !item.isAccepted);
    case "accepted" : return _.filter(list, item => item.isAccepted);
    default: return list;
  }
}

const listConfig  = {
  title   : "Invitations",
  cols    : [
    {id: 0, label: "Date", className: "active",},
    {id: 10, label: "Sent To", className: ""},
    {id: 15, label: "Status", className: ""},
    {id: 20, label: "Accepted On", className: ""},
    {id: 30, label: "Accepted By", className: ""},
    {id: 40, label: "Message", className: ""},
    // {id: 60, label: "", className: ""},
  ],
  filters : [
    {id: 0, label: "All", value: "all", icon: "clear_all" },
    {id: 20, label: "Invited", value: "invited", icon: "mail_outline"},
    {id: 30, label: "Accepted", value: "accepted", icon: "done_all"},
  ],
}