import { IPublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";
import { isArray } from "lodash";
import { ClientDocument, CloudFolder, CloudItem, SharedCloudFolder } from "types";
import { ClientSchema, CloudStorageSettingSchema } from "types/schema";

export const CloudProviders = {
  OneDrive: "onedrive",
};

export const oneDriveScopes = ["User.Read", "Files.ReadWrite.All"];
const oneDriveUrl = "https://graph.microsoft.com/v1.0";
const oneDriveSegment = "/me/drive";
// Redirect to a blank page for this reason: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/errors.md#monitor_window_timeout
export const oneDriveRedirectUri = `${window.location.origin}/auth`;  
// export const withMetadata = ""; // "?$expand=extensions"; //"?expand=extensions($filter=id eq 'com.formhop.metadata')";
export const TO_FOLDER_NAME = "To Client";
export const FROM_FOLDER_NAME = "From Client";


const rootFolders = ["sharedWithMe", "root"];

const fetchOneDrive = async (instance: IPublicClientApplication, path: string, verb = "GET", body: any | null = null) => {
  const msalAccount = instance.getAllAccounts()[0];

  try {
    const response = await instance.acquireTokenSilent({
      scopes: oneDriveScopes,
      account: msalAccount
    });

    if (response.accessToken) {
      const slash = path.startsWith("/") ? "" : "/";
      const driveSegment = path.startsWith("/drives") ? "" : oneDriveSegment;
      const rootUrl = `${oneDriveUrl}${driveSegment}${slash}${path}`;

      const result = await fetch(rootUrl, {
        headers: {
          "Authorization": "Bearer " + response.accessToken,
          "Content-Type": "application/json",
        },
        method: verb,
        ...(body ? { body } : {})
      });

      const data = await result.json();
      return data.value ?? data;
    }
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      console.log("BrowserAuthError", error);
      // fallback to interaction when silent call fails
      instance.acquireTokenPopup({ scopes: oneDriveScopes, });
    }
    else {
      console.error("Error fetching from OneDrive", error);
      throw error;
    }
  }
};

export const findRecursive = (folders: (CloudFolder | SharedCloudFolder)[], id: string) => {
  for (const folder of folders) {
    const f = folder as SharedCloudFolder;
    if(f.remoteItem && f.remoteItem.id === id) return folder;
    else if (folder.id === id) return folder;
        
    if (folder.children) {
      const found: any = findRecursive(folder.children, id);
      if (found) return found;
    }
  }
  return null;
};

export const createFolder = async (instance: IPublicClientApplication, cloudSetting: CloudStorageSettingSchema, parentFolderId: string, name: string) : Promise<CloudFolder> => {
  
  const body = {
    name: name,
    folder: {},
    "@microsoft.graph.conflictBehavior": "fail",
  };
  let path = "";

  if(cloudSetting.isRemote) path = `/drives/${cloudSetting.driveId}/items/${parentFolderId}/children`;
  else path = `items/${parentFolderId}/children`;

  const folder = await fetchOneDrive(instance, path, "POST", JSON.stringify(body));
  return folder;
};

//TODO: This doesn't work, get an error back from the API
// export const addFolderMetadata = async (instance: IPublicClientApplication, folderId: string, metadata: Record<string, any>) => {
//   const path = `items/${folderId}/extensions`;
//   const folderMetadata = {
//     "@odata.type": "microsoft.graph.openTypeExtension",
//     extensionName: "com.formhop.metadata",
//     ...metadata,
//   };

//   const result = await fetchOneDrive(instance, path, "POST", JSON.stringify(folderMetadata));
//   return result;
// };

export const getFolder = async <T = CloudFolder>(instance: IPublicClientApplication, folderId: string) : Promise<T> => {
  const prefix = rootFolders.includes(folderId) ? "" : "items/";
  const url = `${prefix}${folderId}`;
  const folder = await fetchOneDrive(instance, url);
  return folder;
};

export const getCloudItems = async <T = CloudItem>(instance: IPublicClientApplication, folderId: string) : Promise<T[]> => {
  const prefix = rootFolders.includes(folderId) ? "" : "items/";
  const url = `${prefix}${folderId}`;
  const items = await fetchOneDrive(instance, url);
  return items;
};

export const getRemoteFolderChildren = async <T = CloudItem>(instance: IPublicClientApplication, item: SharedCloudFolder) : Promise<T> => {
  
  //depending on how the item is retrieved, not all have the remoteItem property...
  const remoteItem = item.remoteItem || item;
  const driveId = remoteItem.parentReference.driveId;
  const itemId = remoteItem.id;

  return getRemoteItem(instance, driveId, itemId, "/children");
  // const url = `/drives/${driveId}/items/${itemId}/children`;
  // const remoteItem = await fetchOneDrive(instance, url);
  // return remoteItem;
};

export const getRemoteItem = async <T = CloudItem>(instance: IPublicClientApplication, driveId: string, itemId: string, path?: string) : Promise<T> => {
  const slash = (!path || path.startsWith("/")) ? "" : "/";
  const url = `/drives/${driveId}/items/${itemId}${slash}${path ?? ""}`;
  const remoteItem = await fetchOneDrive(instance, url);
  if(isArray(remoteItem)) {
    return remoteItem.map((i: any) => ({ ...i, isRemote: true })) as T;
  }
  else return { ...remoteItem, isRemote: true };
};

export const getFolderChildren = async <T = CloudItem>(instance: IPublicClientApplication, folder: CloudFolder | SharedCloudFolder | string, cloudSetting: CloudStorageSettingSchema | undefined) : Promise<T[]> => {
  let folderId: string = folder as string;
  if(typeof folder !== "string"){
    const remoteFolder = folder as SharedCloudFolder;
    if(remoteFolder.remoteItem || folder.parentReference.driveId !== cloudSetting?.myDriveId){
      return getRemoteFolderChildren(instance, remoteFolder);
    }
    
    //one of our own folders, so get it by the id
    folderId = folder.id;
  }

  const prefix = rootFolders.includes(folderId) ? "" : "items/";
  const url = `${prefix}${folderId}/children`;
  const children = await fetchOneDrive(instance, url);
  
  if(children.error){
    console.error("Error fetching children", children.error);
    return [];
  }

  return children;
};

export const uploadToFolder = async (instance: IPublicClientApplication, folderId: string, file: any, fileName: string, cloudSetting: CloudStorageSettingSchema) => {
  // const fileExtension = doc.fileExtension ?? doc.originalFileName.split(".").pop() ?? "unknown";
  // const fileName = `${doc.name}.${fileExtension}`;
  let path = "";
  if(cloudSetting.isRemote) path = `/drives/${cloudSetting.driveId}/items/${folderId}:/${fileName}:/content`;
  else path = `items/${folderId}:/${fileName}:/content`;
  // const path = `items/${folderId}:/${fileName}:/content`;
  const result = await fetchOneDrive(instance, path, "PUT", file);
  return result;
};

export const getFilePreview = async (instance: IPublicClientApplication, fileId: string) => {
  const path = `items/${fileId}/preview`;
  
  const body = {
    chromeless: true,
    allowEdit: false,      
  };

  const preview = await fetchOneDrive(instance, path, "POST", JSON.stringify(body));
  return preview;

  // const path = `items/${fileId}`;
  // const file = await fetchOneDrive(instance, path);
  // return file.webUrl;
};

export const getShareableLink = async (instance: IPublicClientApplication, fileId: string) => {
  const path = `items/${fileId}/createLink`;
  
  const body = {
    type: "embed",    
    // type: "edit",
  };

  const preview = await fetchOneDrive(instance, path, "POST", JSON.stringify(body));
  return preview;

  // const path = `items/${fileId}`;
  // const file = await fetchOneDrive(instance, path);
  // return file.webUrl;
};

export const getRemoteShareableLink = async (instance: IPublicClientApplication, driveId: string, fileId: string) => {
  const path = `/drives/${driveId}/items/${fileId}/createLink`;
  
  const body = {
    type: "embed",    
  };

  const preview = await fetchOneDrive(instance, path, "POST", JSON.stringify(body));
  return preview;

  // const path = `items/${fileId}`;
  // const file = await fetchOneDrive(instance, path);
  // return file.webUrl;
};

export const cloudFoldersOnly = (children: any[]) => {
  return children.filter(c => !!c.folder || !!c.remoteItem?.folder) as CloudFolder[];
};

export const childCount = (folder: any) => {
  return folder.remoteItem?.folder?.childCount || folder.folder?.childCount;
};

export const getFolderProps = (folder: CloudFolder | SharedCloudFolder, provider: string, syncMode: string) : CloudStorageSettingSchema => {
  const remoteFolder = folder as SharedCloudFolder;
  if(remoteFolder.remoteItem){
    return {
      provider,
      syncMode,
      myDriveId: remoteFolder.remoteItem?.parentReference?.driveId ?? remoteFolder.parentReference.driveId,
      id: remoteFolder.remoteItem.id,
      name: folder.name,
      driveId: remoteFolder.remoteItem.parentReference.driveId,
      driveType: remoteFolder.remoteItem.parentReference.driveType,
      isRemote: true,
    };
  }
  else {
    return {
      provider,
      syncMode,
      myDriveId: remoteFolder.parentReference.driveId,
      id: folder.id,
      name: folder.name,
      driveId: folder.parentReference.driveId,
      driveType: folder.parentReference.driveType,
      isRemote: false,
    };
  }

};

export const getFolderName = (client: ClientSchema) => {
  return `${client.firstName} ${client.lastName}`;
};

export const getFileName = (doc: ClientDocument) => {
  const fileExtension = doc.fileExtension ?? doc.originalFileName.split(".").pop() ?? "unknown";
  const fileName = `${doc.name}.${fileExtension}`;
  return fileName;
};

//Creates a hash of the documents that can be used to detect changes that need to be synced
export const docsSyncHash = (docs: ClientDocument[]) => {
  const docInfo = docs.map(d => `${d.id}-${d.modifiedAt}`).join("|");
  return docInfo;
};