import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import HappyIcon from '@mui/icons-material/SentimentVerySatisfied';
import SadIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import BugReportIcon from '@mui/icons-material/BugReport';
import { WaitButton, ErrorAlert } from 'components';
import { sendFeedback } from 'store/actions/app-actions';
import { useStatus } from 'redux-action-status';

const FeedbackDialog = ({feedbackType, isOpen, onClose}) => {
  const classes   = buildStyles();
  const dispatch  = useDispatch();
  const status    = useStatus("feedback");
  const [text, setText]     = useState("");
  const [type, setType]     = useState(feedbackType || "happy");
  const [invalid, setInvalid]   = useState("");
  const canSave   = type !== "bug" || (text && text.length > 3)

  //effect to sync the type in case the dialog has already been opened and closed
  useEffect(() => {
    if(isOpen && type !== feedbackType) setType(feedbackType);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const pholder   = type === "bug" ? "Please provide details and steps to reproduce" : "Comments (optional)";

  async function onSend(){

    if(type === "bug" && text.trim().length === 0){
      setInvalid("Please enter details about the problem you'd like to report.");
      return;
    }

    const result  = await dispatch(sendFeedback(type, text));
    if(result.isOk){
      setText("");
      setInvalid("");
      onClose(true);
    }    
  }

  function onDialogClose() {
    if(!status.isWorking) onClose();
  }

  return (
    <Dialog open={isOpen} onClose={onDialogClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Send Feedback</Typography>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" className={classes.contentRoot}>
          <ErrorAlert error={status.error}/>
          <ErrorAlert error={invalid}/>
          <FormControl component="fieldset" className={classes.radioFormControl}>
            <RadioGroup aria-label="feedback type" name="feedbackType" value={type} onChange={(e) => {setType(e.target.value)}}>
              <FormControlLabel value="happy" disabled={status.isWorking} control={<Radio/>} classes={{label: classes.radioLabel}} className={classes.radioControlLabel} label={<Typography className={classes.radioLabel}>Send a smile<HappyIcon fontSize="small"/></Typography>} />
              <FormControlLabel value="sad" disabled={status.isWorking} control={<Radio/>} classes={{label: classes.radioLabel}} className={classes.radioControlLabel} label={<Typography className={classes.radioLabel}>Send a frown<SadIcon fontSize="small"/></Typography>} />
              <FormControlLabel value="bug" disabled={status.isWorking} control={<Radio/>} classes={{label: classes.radioLabel}} className={classes.radioControlLabel} label={<Typography className={classes.radioLabel}>Report a problem<BugReportIcon fontSize="small"/></Typography>} />              
            </RadioGroup>
          </FormControl>

          <TextField value={text} disabled={status.isWorking} onChange={(e) => {setText(e.currentTarget.value)}} className={classes.comments} multiline rows={3} fullWidth variant="outlined" placeholder={pholder}/>

        </Grid>
      </DialogContent>
      <DialogActions>
        <WaitButton id="close" size="small" onClick={onClose} isWaiting={status.isWorking}>Cancel</WaitButton>
        <WaitButton id="cancel" size="small" color="primary" onClick={onSend} disabled={!canSave} isWaiting={status.isWorking}>Send</WaitButton>        
      </DialogActions>
    </Dialog>
  );
}

export default FeedbackDialog;

const buildStyles   = makeStyles(theme => ({
  title   : {
    padding     : theme.spacing(2),
    background  : theme.palette.primary.light,
    color       : theme.palette.primary.contrastText,
  },
  titleText   : {
    fontSize    : 20,
    fontWeight  : 500,
  },
  contentRoot : {
    padding         : theme.spacing(1),
    marginBottom    : theme.spacing(2),
  },
  radioFormControl  : {
    marginBottom    : theme.spacing(2),
  },
  radioControlLabel   : {
    marginTop       : theme.spacing(2),
    "& .MuiButtonBase-root"   : {
      padding     : theme.spacing(0, 1), // `${theme.spacing(0)} ${theme.spacing(1)}`, //`
    },    
  },
  radioLabel  : {
    fontSize      : 17,
    color         : theme.palette.grey[700],
    "& svg" : {
      // fontSize    : 22,
      color       : theme.palette.grey[500],
      marginLeft  : theme.spacing(1),
      marginBottom: theme.spacing(-0.5),
    },
    
  },
  comments  : {
    "& textarea"  : {
      fontSize      : 15,

    },
    "& MuiIputBase-root"  : {
      padding     : theme.spacing(0.5),
    },
  },
}));