import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import { StatusCard } from 'components';
import LandingHeader from './landing-header';
import LandingFooter from './landing-footer';

function AboutPage(){
  const classes   = buildStyles();

  return (
    <Grid container className={classes.aboutPage}>
      <LandingHeader/>
      <Grid item container className={classes.aboutView} alignItems="center" justifyContent="center">
        <Grid item xs={3}>
          <StatusCard isVisible={true} status="ok" message="Coming Soon: About Us"/>
        </Grid>
      </Grid>
      <LandingFooter/>
    </Grid>
  )
}

export default AboutPage;

const buildStyles   = makeStyles(theme => ({
  aboutPage   : {
    width   : "100%",
    height  : "100vh",
  },
  aboutView  : {
    height    : "calc(100vh - 120px)",
    marginTop : 60,
  }
}));