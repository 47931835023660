import React from 'react';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import { IfBlock } from 'components';

const DeletableTr = ({isDeleting, onDelete, onCancel, colSpan, itemName, isWorking, classNameDelete, children}) => {

  const classes   = buildStyles();

  return (
    <>
      <IfBlock condition={isDeleting}>
        <tr className={clsx(classes.itemRow, classes.deleteRow, classNameDelete)}>
          <td colSpan={colSpan}>
            Are you sure you'd like to delete <strong>{itemName}</strong>?
          </td>
          <td colSpan={3} style={{textAlign: "right"}}>
            <Button color="secondary" size="small" variant="outlined" className={classes.actionButton} onClick={onDelete} disabled={isWorking}>Continue</Button>
            <Button size="small" variant="outlined" onClick={onCancel} className={classes.actionButton} disabled={isWorking}>Cancel</Button>
          </td>
        </tr>
      </IfBlock>
      <IfBlock condition={!isDeleting}>
        {children}
      </IfBlock>      
    </>
  );
}

export default DeletableTr;

const buildStyles   = makeStyles(theme => ({
  itemRow   : {
    fontSize    : 14,
    fontWeight  : 500,
    color       : theme.palette.grey[700],
    "& td"  : {
      padding   : `${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} ${theme.spacing(1)} `,               
    },    
  },
  deleteRow   : {
    background    : theme.palette.error.light,
    // color         : theme.palette.error.dark,
  },
  actionButton  : {
    marginLeft   : theme.spacing(0.5),
  },
}));