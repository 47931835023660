import { useSelector } from 'react-redux';
import { Route, Routes,  useLocation } from 'react-router-dom';
import { selectProfile } from 'store/selectors/app-selectors';
import { redirectAddingReroute, redirectWithQuery } from 'helpers/general-helpers';
import AppView from 'sections/app/app-view';
import MyView from '../my/my-view';
import WelcomeView from '../welcome/welcome-view';
import AdminView from '../admin/admin-view';
import { AppProvider } from './app-context';
import { Bounce, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'overlayscrollbars/overlayscrollbars.css';

function AppContainer(){
  const location        = useLocation();
  const profile         = useSelector(selectProfile);
  const isInitialized   = useSelector(state => state.app.isInitialized);

  const redirect  = checkForRedirect(location, isInitialized, profile);
  if(redirect) return redirect;

  return (
    <AppProvider>
      <Routes id="app-container">
        <Route path="/welcome/*" element={<WelcomeView />} />
        <Route path="/my/*" element={<MyView />} />
        <Route path="/app/*" element={<AppView />} />
        <Route path="/admin/*" element={<AdminView />} />
      </Routes>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
      
    </AppProvider> 
  );
}

export default AppContainer;

function checkForRedirect(location, isInitialized, profile){

  if(!isInitialized){
    // return <Navigate to="/initializing" />;
    // return redirect("/initializing");
    return redirectAddingReroute(location, "/initializing", "/app");
  }

  if(location.pathname.indexOf("/welcome") !== 0){
    //Make sure they're signed in...
    if(profile.isInitialized){
      if(!profile.isAuthenticated){
        //Only need to pass along user type query parameter because user isn't authenticated
        return redirectWithQuery(location, "/welcome", ["type"]);
      }
      else if(profile.isUser && (!profile.hasDataChoice || !profile.hasLocation)){
          //still need to go through the welcome flow
          return redirectAddingReroute(location, "/welcome", "/app");
      }
    }
  }

  return null;
}