// import { useState } from "react";
import { ToggleButton, ToggleButtonGroup, Tooltip } from "@mui/material"

interface IShareDocumentToggleProps {
  isShared: boolean;
  onChanged: (value: boolean) => void;
  disabled?: boolean;
}

const ShareDocumentToggle = ({ isShared, onChanged, disabled }: IShareDocumentToggleProps) => {
  
  // const [shared, setShared] = useState<boolean>(false);
  
  const handleToggle = (value: boolean) => (e: any) => {
    // setShared(!shared);
    onChanged(value);
  }

  return (
    <ToggleButtonGroup disabled={disabled}>
      <Tooltip title="Share this document with the client">
        <ToggleButton value="yes" selected={isShared} onChange={handleToggle(true)} size="small" color="secondary" sx={{ width: 150 }}>
          Shared
        </ToggleButton>
      </Tooltip>

      <Tooltip title="Do not share this document with the client">
        <ToggleButton value="client" selected={!isShared} onChange={handleToggle(false)} size="small" color="secondary" sx={{ width: 150 }}>
          Not Shared
        </ToggleButton>
      </Tooltip>
      
    </ToggleButtonGroup>
  );
};

export default ShareDocumentToggle;