import { ArrowBack, AssignmentTurnedInOutlined, SummarizeOutlined } from "@mui/icons-material";
import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AvatarIcon } from "components";

export interface IProviderFormHeaderProps {
  formName: string;
  clientName: string;
  onClose: () => void;
  showProperties?: boolean;
  onToggleProperties: () => void;
}

const ProviderFormHeader = ({ formName, clientName, onClose, showProperties, onToggleProperties }: IProviderFormHeaderProps) => {

  return (
    <Grid id="header-grid" container xs={12} justifyContent="flex-start" alignItems="flex-start">
        <Grid sm={9} container flexWrap="nowrap" alignItems="flex-start" gap={2}>
          <Box>
            <Tooltip title="Go back">
              <IconButton size="small" onClick={onClose}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Form name and info */}
          <Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Box sx={{ display: { xs: 'flex', sm: 'none' }, alignItems: "center" }}>
                <AvatarIcon size="xs" icon={ <AssignmentTurnedInOutlined /> } />
              </Box>
              <Typography variant="h6" color="primary">{formName}</Typography>
              {!showProperties && (
                <Tooltip title="Show form properties">
                  <IconButton size="small" onClick={onToggleProperties}>
                    <SummarizeOutlined fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}              
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" textTransform="uppercase" color="grey.700">Form</Typography>
              <Typography variant="body2" textTransform="uppercase" color="grey.500">assigned to</Typography>
              <Typography variant="body2" textTransform="uppercase" color="primary.main">{clientName}</Typography>
            </Stack>
          </Stack>
        </Grid>

        {/* The Avatar icon */}
        <Grid sm={3} container alignItems="center" justifyContent="flex-end" sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <AvatarIcon size="lg" icon={ <AssignmentTurnedInOutlined /> } />
        </Grid> 
      </Grid>
  );
}

export default ProviderFormHeader; 
