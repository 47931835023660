import { useEffect, useMemo, useState } from "react";
import { Box, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import moment, { Moment } from "moment";
import { useDateValidation } from "helpers/component-hooks";
import { AssignableForm } from "./assignable-form-types";
import { AccountCircle, Work } from "@mui/icons-material";

export interface IAssignFormDialogProps {
  item: AssignableForm;
  onChange: (e: any) => void;
}

const AssignedFormProps = ({ item, onChange }: IAssignFormDialogProps) => {
  const minValue = useMemo(() => moment().startOf("day"), []);  //min value should be the beginning of today
  const [dateValue, setDateValue] = useState<Moment | null>(item.dueOn ? moment(item.dueOn).endOf("day") : null);
  const { setError, errorMessage } = useDateValidation();
  const notesPlaceholder = item.isClientForm ? "Notes for the client" : "Notes for the attorney completing this form";
  
  useEffect(() => {
    if(dateValue && dateValue.isValid() && dateValue.year() >= 2020){
      onChange({target: {id: "dueOn"}, currentTarget: {value: dateValue.toDate().getTime()}});
    }
    else{
      onChange({target: {id: "dueOn"}, currentTarget: {value: ""}});
    }
  }, [dateValue, onChange]);  

  return (
    <Stack sx={{ px: 2 }} spacing={1}>
      <Typography variant="h6" >{item.name}</Typography>
      
      <Stack spacing={1} >
        <DatePicker 
          label="Due Date" 
          value={dateValue} 
          minDate={minValue} 
          onChange={(newVal) => setDateValue(newVal)} 
          onError={(newError) => setError(newError)}
          slotProps={{
            textField: {
              title: "Set a due date for the client (optional)",
              helperText: errorMessage,
            },
          }}
        />

        <Tooltip title={`Add notes for the ${item.isClientForm ? "client" : "attorney"} (optional)`}>
          <TextField id="notes" label="Notes" placeholder={notesPlaceholder} value={item.notes ?? ""} onChange={onChange} size="small" multiline rows={4} />
        </Tooltip>

        <Box py={1}>
          {item.isClientForm && (
            <Stack direction="row" spacing={1} alignItems="center">
              <AccountCircle />
              <Typography variant="body2" color="grey.600">This form is completed by the <strong>client</strong></Typography>
            </Stack>
          )}
          {!item.isClientForm && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Work />
              <Typography variant="body2" color="grey.600">This form is completed by the <strong>attorney</strong></Typography>
            </Stack>
          )}
        </Box>

        {!item.isClientForm && (
          // If the lawyer is part of a firm, provide an assignment option
          <Tooltip title="Assign this form to a team member">
            <TextField id="assignedTo" label="Assigned To" placeholder="Assign this form to a specific attorney" size="small" />
          </Tooltip>
        )}
        {/* <Typography variant="body2" color="grey.600" fontWeight="300" fontStyle="italic">all fields are optional</Typography> */}

      </Stack>
    </Stack>
  );
};

export default AssignedFormProps;