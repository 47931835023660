import { combineReducers } from 'redux';
import onboarding from './onboarding-reducer';
import app from './app-reducer';
import { statusReducer } from 'redux-action-status';
import values from './values-reducer';
import share from './share-reducer';
import convo from './conversation-reducer';
import admin from './admin-reducer';
import petitioner from './petitioner-slice';
import attorney from "./attorney-slice";
import account from "./account-slice";

const rootReducer = combineReducers({
    app,
    admin,
    onboarding,
    values,
    share,
    convo,
    petitioner,
    attorney,
    account,
    status: statusReducer,
});

export default rootReducer;