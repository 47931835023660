import { compact } from 'lodash';
import { useMatch } from 'react-router-dom';
import { Grid, Table, TableBody, Typography } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { useFormEditor } from 'sections/forms/form-editor-context';
import FormSameAsField from 'sections/financials/parts/v2.0.0/form-sameas-field';
import CommentContainer from "../comment-container";
import { useFieldComments } from '../conversation-context';
import { useRegion } from 'sections/financials/parts/region-context';
import { useFormSameAs } from 'sections/financials/parts/v2.0.0/part-hooks';
import { IDataTableSectionProps } from '../data-table-types';
import { DataTableListRow } from './data-table-list-row';
import { DataTableListRowEditor } from './data-table-list-row-editor';
import SameAsChip from '../same-as-chip';
import DataTableListHeader from './data-table-list-header';
import DataTableListEmptyRow from './data-table-list-empty-row';
import { commentIconSx } from './data-table-list-utils';
import DataTableCommentTooltip from '../data-table-comment-tooltip';

const DataTableListSection = ({section, values, profile, isEditing}: IDataTableSectionProps) => {
  const { getField } = useRegion();
  const { changes, addRow, deleteRow } = useFormEditor();

  const rawRows: Record<string, any>[] = changes[section.id] ?? values[section.id] ?? [];
  const rows    = isEditing ? rawRows : compact(rawRows); //remove any rows that are empty if we're not editing (when there may be a new row that is null)
  const fields  = compact((section.fields as string[]).map(f => getField(f, true)));  //remove any fields that are not found
  const isEmpty = Boolean(!rows || rows.length === 0);
  const { hasComments }   = useFieldComments(section.id);
  const [isSameAs] = useFormSameAs(section);

  const tableRows = rows?.map((row: Record<string, any>, index: number) => {
    if(isEditing) return <DataTableListRowEditor key={index} sectionId={section.id} row={row} rowIndex={index} fields={fields} onRowDeleted={deleteRow(section.id, index)} itemLabel={section.itemLabel}/>;
    else return <DataTableListRow key={index} sectionId={section.id} row={row} rowIndex={index} fields={fields}/>;
  });

  return (
    <Grid container className="data-table-list-section" py={1} sx={{ borderBottom: "1px solid", borderBottomColor: "grey.300" }}>
      <Grid container alignContent="center">
        <DataTableCommentTooltip>
          <Typography variant="h4" sx={{ fontWeight: "400", fontSize: "1rem", color: "primary.main" }}>{section.title}</Typography>
        </DataTableCommentTooltip>
        {!isEditing && <SameAsChip item={section} values={values}/>}
        {isEditing && <FormSameAsField item={section} sx={{ ml: 1 }}/>}
        {hasComments && <ChatBubbleIcon color="secondary" sx={commentIconSx} />}
      </Grid>
      {!isSameAs && (
        <Grid item container>
          <Table sx={{ width: "100%", mx: 0.5, my: 1 }}>
            <DataTableListHeader fields={fields} isEditing={!!isEditing} isEmpty={isEmpty}/>
            <TableBody>
              {tableRows}
              <DataTableListEmptyRow isEditing={!!isEditing} isEmpty={isEmpty} colCount={fields.length} onRowAdded={addRow(section.id)}/>
            </TableBody>
          </Table>
        </Grid>
      )}
    </Grid>
  );
};

//TODO: break out a DataTableListSectionSameAs  for sections with the sameAs attribute? Would eliminate a lot of (hook/selector) logic for sections without the attribute.

// Wrapper component that adds the comment container around the section, if we're not in edit mode
const DataTableListSectionWrapper = ({section, values, profile, isEditing}: IDataTableSectionProps) => {
  const match     = useMatch("/app/review");         //TODO: a better way to make this read only??

  if(isEditing) return <DataTableListSection section={section} values={values} profile={profile} isEditing={isEditing}/>;
  else {
    return (
      <CommentContainer fieldKey={section.id} profile={profile} isReadOnly={!!match}>
        <DataTableListSection section={section} values={values} profile={profile} isEditing={isEditing}/>
      </CommentContainer>
    )
  }
};

export default DataTableListSectionWrapper;