import { useMemo } from 'react';
import fHelper from 'helpers/financials-helper';
import { Box, Table } from '@mui/material';
import TableSectionBody from './table-section-body';
import TableSectionHeader from './table-section-header';
import { usePetitionerForm } from '../form-hooks';
import { withFieldChanged } from './with-field-changed';
import { useRegion } from './region-context';
import { FormField } from 'types';

export interface IStandardSectionProps {
  section: any;
  onChange: (sectionId: string, fieldId: string, total: number, details: any, index: number) => void;
  onFieldChanged: (event: any) => void;
}

const StandardSection = ({ section, onFieldChanged }: IStandardSectionProps) => {
  const { values } = usePetitionerForm();
  const { getSectionFields } = useRegion();
  // const section = useMemo<Section | undefined>(() => sections.find(s => s.id === sectionId), [sectionId, sections]);
  const fields = useMemo<FormField[]>(() => getSectionFields(section.id), [section.id, getSectionFields]);
  const isReady = useMemo(() => fields?.length > 0, [fields]);

  const isCollapsed   = !fHelper.isConditionMet(section.condition, values);


  return (
    <Box data-id="standard-section" width="100%" sx={{ borderBottom: "1px solid", pb: 2, borderBottomColor: "grey.300" }}>
      {/* {!isReady && <Loading isVisible={true} />} */}
      {isReady && (
        <Table size="small" className="section-table standard-section" onChange={onFieldChanged}>
          <TableSectionHeader sectionId={section.id} onChange={onFieldChanged} />
          <TableSectionBody sectionId={section.id} fields={fields} isCollapsed={isCollapsed} onChange={onFieldChanged} />
        </Table>
      )}
    </Box>
  );
};

export default withFieldChanged(StandardSection);