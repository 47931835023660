import Grid from '@mui/material/Grid';
import { ErrorAlert } from 'components';
import ClientList from './parts/client-list/client-list';
import { useStatus } from 'redux-action-status';

const AttorneyClients = () => {
  const status    = useStatus("requests");
  
  return (
    <Grid id="attorney-clients" container sx={{ my: 2, mx: 1 }}>
      <ErrorAlert error={status.error} title="Failed to load Client List" sx={{ width: "80%" }} />
      <ClientList mode="all" />
    </Grid>
  );
}

export default AttorneyClients;
