// import moment from 'moment';
// import { useSelector } from 'react-redux';
// import Grid from '@mui/material/Grid';
// import Tooltip from '@mui/material/Tooltip';
// import Typography from '@mui/material/Typography';
// import InfoIcon from '@mui/icons-material/InfoOutlined';
import { SummarySection } from '../parts';
import { ErrorBoundary } from 'components';
// import { selectProfile } from 'store/selectors/app-selectors';
import { usePetitionerForm } from '../form-hooks';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { Info } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { selectProfile } from 'store/selectors/app-selectors';
import moment from 'moment';

export const SummaryContainer = () => {
  const { regions } = usePetitionerForm();
  const summaryIds = regions.filter(r => r.type === "summary").map(r => r.id);
  const summaryNodes = summaryIds.map(sid => <SummarySection key={sid} regionId={sid} />);

  const profile = useSelector(state => selectProfile(state));
  const dbProps = useSelector<any, any>(state => state.values.database);
  const localProps = useSelector<any, any>(state => state.values.local);
  const lastSave = profile.isRemote ? dbProps.savedAt : localProps.savedAt;
  let lastSaveText = "You have not made any changes during this session.  All changes are saved automatically as you work.";
  if(!!lastSave){
      lastSaveText = `Last saved ${moment(lastSave).fromNow()}.  All changes are saved automatically as you work.`
  }

  return (
    <Box id="summary-container" sx={{ position: "fixed", width: "24%" }} className="summary-container" >
      <ErrorBoundary>
        {summaryNodes}
        <Tooltip title={lastSaveText}>
          <Grid container justifyContent="center" alignItems="center">
            <Info sx={{ fontSize: 20, color: "grey.500", mr: 0.5 }} />
            <Typography fontSize="0.9rem" fontWeight="400" color="grey.500">Auto-save: On</Typography>
          </Grid>
        </Tooltip>
      </ErrorBoundary>
    </Box>
  );
}
