import { useMatch } from "react-router-dom";
import { Box, TableCell, TableRow } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { FormField } from "@/types";
import { IDataTableSectionRowProps } from "./data-table-types";
import { numberHelper, financialsHelper as fHelper } from "helpers";
import DataTableRow from "./data-table-row";
import { cellSx, labelCellSx } from "./data-table-constants";
import { useRegion } from "sections/financials/parts/region-context";
import { FormInput } from "sections/financials/parts/v2.0.0/form-input";

//==========
// Multi-column row
function DataTableColumnarRowEditor({fieldKey, values, profile, section}: IDataTableSectionRowProps){
  const { fields, getField } = useRegion();
  const field = getField(fieldKey, false);
  //NOTE: Toggle this to turn off the commenting feature...  Set it to true and comments will be disabled
  const match     = useMatch("/app/review");     //TODO: a better way to make this read only??
  const inputHeaders = section?.config?.inputHeaders ?? [];

  //Make sure we have a field, and if so, if the condition for the field is met
  if(!field || (field.condition && !fHelper.isConditionMet(field.condition, values))){
    return null;
  }

  const hasChildren = field.children && field.children.length > 0;
    if(!hasChildren){
      return <DataTableRow key={field.id} fieldKey={field.id} values={values} profile={profile} />;      
    }

    const children = fields.filter(cf => field.children?.includes(cf.id));
    const childValues = children.map(c => getFieldValue(c, values));
    const hasValues = childValues.some(cv => !!cv.rawValue);
    const label = field.label?.length > 0 ? field.label : children?.[0].label ?? children?.[1].label ?? "";

    // return (
    //   <TableRow key={field.id}>
    //     <TableCell colSpan={3} sx={{...cellSx, ...labelCellSx}}>
    //       <Grid container xs={12} flexWrap="nowrap" columnSpacing={1}>
    //         <Grid xs flexGrow={1} sx={{ fontWeight: hasValues ? "700" : "unset"}}>{label}</Grid>
    //         {children.map((childField, index) => {
    //           const { fieldProps } = childValues[index];
    //           return (
    //             <Grid key={index} container sx={{...valueSx, ...fieldProps}}>
    //               <FormField key={index} field={childField} />
    //             </Grid>
    //           );
    //         })}
    //       </Grid>
    //     </TableCell>
    //   </TableRow>
    // );

    return (
      <TableRow key={field.id}>
        <TableCell colSpan={3} sx={{...cellSx, ...labelCellSx}}>
          <Grid container xs={12} rowSpacing={1} rowGap={0.5} direction="column">
            <Grid xs flexGrow={1} sx={{ fontWeight: hasValues ? "700" : "unset"}}>{label}</Grid>
            {children.map((childField, index) => {
              const { fieldProps } = childValues[index];
              return (
                <Grid key={index} container xs={12} columnSpacing={1} flexWrap="nowrap" px={2}>
                  <Grid xs flexGrow={1} textAlign="end" alignContent="center">{inputHeaders[index] ?? `Client ${index + 1}`}</Grid>
                  <Grid key={index} container sx={{justifyContent: "center", px: 1, ...fieldProps}}>
                    <FormInput key={index} field={childField} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
      </TableRow>
    );
}

export default DataTableColumnarRowEditor;


//TODO: This is copied from data-table-multi-field.tsx.  It should be moved to a helper file
function getFieldValue(field: FormField, values: Record<string, any>){
  const myType = field.type;
  let rawValue = values[field.id];
  let value = rawValue;
  let sx = {};

  if(field.options){
    let cVal = value;
    if(typeof field.options[0].id === "number" && typeof value === "string") cVal = parseInt(value);
    const oVal  = field.options.find(o => o.id === cVal);
    value = oVal ? oVal.label : ""; //field.options[0].label;
  }  
  else if(myType === "currency" || !myType){
    value = numberHelper.asCurrency(value);
  }
  else if(myType === "boolean"){
    if(field.subtype === "switch"){
      value = value ? "YES" : "NO";
    } 
    else {
      value = <Box sx={{ display: "flex", justifyContent: "flex-end" }}>{value ? <CheckBoxOutlined fontSize="small" /> : <CheckBoxOutlineBlank fontSize="small"/>}</Box>;      
      sx = { color: "grey.600", justifyContent: "flex-end" };
    }
  }
  
  return { value, rawValue, fieldProps: sx };
}