import { useEffect, useState } from "react";
import { IFormFieldInputProps } from "./part-types";
import { useAttorneyFormValue } from "./part-hooks";
import { useFieldChanged } from "sections/forms/form-editor-context";

const FormSelectField = ({ field, sectionId, dataProps, rowIndex } : IFormFieldInputProps ) => {
  if(!field.options) throw new Error("Select field must have options");

  const [valueKey, initialValue] = useAttorneyFormValue(field.id, sectionId, rowIndex);
  const onFieldChanged = useFieldChanged(valueKey, field.id, rowIndex);
  const [val, setVal] = useState<string | number | null>(initialValue ?? field.options[0].id);
  
  //=== watch the initialValue. It may change if this is a sameAs field, or there's some other dependency
  useEffect(() => {
    setVal(initialValue);
  }, [initialValue]);

  const classNames = `form-control form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  //TODO: convert to MUI TextField

  // const options = field.options.map(opt => <MenuItem key={opt.id} value={opt.id} disabled={opt.isDisabled}>{opt.label}</MenuItem>);
  const options = field.options.map(opt => <option key={opt.id} value={opt.id} disabled={opt.isDisabled}>{opt.label}</option>);
  
  const handleChange = (e: any) => {
    const nextVal = e.target.value;
    setVal(nextVal);

    if(onFieldChanged){ //  && nextVal !== initialValue){
      onFieldChanged(nextVal);
    }
  };

  // return (
  //   <TextField 
  //     id={field.id} 
  //     select
  //     className={classNames} 
  //     value={val} 
  //     {...dataProps} 
  //     placeholder="choose" 
  //     onChange={handleChange}
  //   >
  //     {options}
  //   </TextField>
  // );
  return (
      <select 
        id={field.id} 
        className={classNames} 
        value={val} 
        {...dataProps} 
        placeholder="choose" 
        onChange={handleChange}
      >
        {options}
      </select>
  );

};

export default FormSelectField;