import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useToggle } from "react-use";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { Box, Button, ButtonGroup, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import { ArrowBack, CloseOutlined, CloudDownload, CloudDownloadOutlined, CloudUpload, CloudUploadOutlined, DeleteOutlined, Edit, FilePresent, OpenInNewOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useStatus } from "redux-action-status";
import { ClientDocument, CurrentClient } from "types";
import { deleteDocument, downloadDocument, touchedDocument } from "store/actions/share-actions";
import { StatusKeys } from "helpers";
import { getFolderName } from "helpers/cloud-storage-helpers";
import { AvatarIcon, ConfirmDialog, LoadingBar, SplitButton } from "components";
import { ConversationProvider } from "components/values-table/conversation-context";
import { useCloudPreview, useCloudSync } from "hooks/cloud-storage";
import { useAppContext } from "../app/app-context";
import { ShareProvider, useShareContext } from "../app/share-context";
import MyConversation from "../my/my-conversations/my-conversation";
import DocumentPropertiesTable from "./document-properties-table";
import DocumentVersionUploadDialog from "./upload-document-version-dialog";
import { chooseConversation } from "store/actions/conversation-actions";
import UploadDocumentDialog from "../collaborator/parts/client-documents-card/upload-document-dialog";

//Document View specifically for a provider (attorney)

const DocumentViewCore = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { documentId, clientId } = useParams<{ documentId: string, clientId: string, shareId: string }>();
  const { allowComments } = useAppContext();
  const { share } = useShareContext();
  const client = useSelector<any, CurrentClient | undefined>(state => state.attorney.currentClient);
  const { isInitialized } = useStatus(StatusKeys.conversations);
  const [isConfirmingRemove, toggleConfirmRemove] = useToggle(false);
  const [isPreviewing, setPreviewing] = useState(false);
  
  const document = useMemo(() => share?.documents?.find(d => d.id === documentId), [share?.documents, documentId]);
  const person = useMemo(() => client ? getFolderName(client) : "Loading...", [client]);
  const { canSync } = useCloudSync();
  const hasPreview = useMemo(() => canSync && !!document?.syncId, [canSync, document?.syncId]);
  const { previewUrl, loadPreview } = useCloudPreview();
  const [iframeSrc, setIframeSrc] = useState<string | null>(null);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  
  const isClientUpload = document?.direction === "fromClient";
  const direction = (isClientUpload ? "From" : "For");

  const [isUploading, setIsUploading] = useState(false);
  const [toEdit, setToEdit] = useState<ClientDocument | null>(null);

  //Flag that this user has viewed this document
  useEffect(() => {
    if(share?.id) dispatch(touchedDocument(share?.id, documentId));
  }, []);

  //When a preview finishes loading, set it as the iframe source
  useEffect(() => {
    if(previewUrl){
      setIframeSrc(previewUrl);
    }
  }, [previewUrl]);

  //Select the conversation for this document
  useEffect(() => {
    if(share?.id && document?.id && isInitialized) dispatch(chooseConversation(share.id, document.id, "d"));
  }, [isInitialized, dispatch, share?.id, document?.id]);


  if(!document) return null;

  const onGoBack = () => {
    const url = isClientUpload ? `/app/clients/${clientId}/uploads` : `/app/clients/${clientId}/documents`;
    navigate(url);
  }

  const handlePreview = async () => {
    if(!document.syncId) return;
    setPreviewing(true);
    if(!iframeSrc) loadPreview(document.syncId);
  };

  const handlePreviewInTab = () => {
    if(!document.syncId || !iframeSrc) return;
    const url = iframeSrc; //`/app/clients/${clientId}/documents/${documentId}/preview`;
    window.open(url, "_blank");
  }

  const handleDownload = async () => {
    await dispatch(downloadDocument(document)) as any;
    toast("Document downloaded successfully", { type: "success" });
  };

  const handleArchive = async () => {
    console.log("TODO: Archive document");
  }

  const handleEdit = async () => {
    setToEdit(document);
  }
  
  const handleRemove = async () => {
    if(!share) return;
    toggleConfirmRemove();
    const result = await dispatch(deleteDocument(share.id, document.id));
    if((result as any).isOk){
      toast.success("File removed successfully");
      onGoBack();
    }
  };

  const handleClosePreview = () => {
    setPreviewing(false);
    setIframeLoaded(false);
  }

  const viewOptions = [{ label: "Download", onClick: handleDownload, icon: <CloudDownload fontSize="small" /> }];

  return (
    <Grid id="provider-document-view" container px={4} pt={1}>
      {/* Header */}
      <Grid id="header-grid" container xs={12} justifyContent="flex-start" alignItems="flex-start">
        <Grid sm={9} container flexWrap="nowrap" alignItems="flex-start" gap={2}>
          <Box>
            <Tooltip title="Go back">
              <IconButton size="small" onClick={onGoBack}>
                <ArrowBack />
              </IconButton>
            </Tooltip>
          </Box>

          {/* Document name and info */}
          <Stack >
            <Typography variant="h6" color="primary">{document.name}</Typography>
            <Stack direction="row" spacing={1}>
              <Typography variant="body2" textTransform="uppercase" color="grey.500">{isClientUpload ? "upload" : "document"}</Typography>
              <Typography variant="body2" textTransform="uppercase" color="grey.500">{direction}</Typography>
              <Typography variant="body2" textTransform="uppercase" color="primary.main">{person}</Typography>
            </Stack>
          </Stack>
        </Grid>

        {/* The Avatar icon */}
        <Grid sm={3} container alignItems="center" justifyContent="flex-end">
          { isClientUpload && <AvatarIcon size="lg" icon={ <CloudUpload /> } /> }
          { !isClientUpload && <AvatarIcon size="lg" icon={ <FilePresent /> } /> }          
        </Grid> 
      </Grid>

      <Grid id="body-grid" container xs={12} mt={2}>
        <Grid container sm={6} md={5} lg={4}>
          <DocumentPropertiesTable document={document} />
        </Grid>

        <Grid container sm={6} md={7} lg={8} px={2} gap={2} alignContent="flex-start">
          <Grid container sm={12} gap={2} alignItems="flex-start">
            {/* If in preview mode, actions are different */}
            {isPreviewing && (
              <ButtonGroup variant="outlined" size="small">
                <Tooltip title="Close the preview">
                  <Button onClick={handleClosePreview} size="small" variant="outlined" startIcon={<CloseOutlined fontSize="small" />}>Close</Button>
                </Tooltip>
                <Tooltip title="Open in a new tab">
                  <Button onClick={handlePreviewInTab} size="small" variant="outlined"><OpenInNewOutlined fontSize="small" /></Button>
                </Tooltip>
                <Tooltip title="Download the file">
                  <Button onClick={handleDownload} size="small" variant="outlined"><CloudDownloadOutlined fontSize="small" /></Button>
                </Tooltip>
              </ButtonGroup>
            )}

            {/* If they are synced with the cloud, show the preview option, otherwise, only download */}
            {(hasPreview && !isPreviewing) && <SplitButton onClick={handlePreview} options={viewOptions} label="Preview" size="small" buttonProps={{ startIcon: <FilePresent fontSize="small" /> }} /> }
            {(!hasPreview) && <Button onClick={handleDownload} size="small" variant="outlined" color="primary" startIcon={<CloudDownloadOutlined fontSize="small" />}>Download</Button> }
            
            {/* If not previewing and not a client upload, can perform actions on the document */}
            {!isPreviewing && !isClientUpload && (
              <ButtonGroup variant="outlined" size="small">
                <Button onClick={handleEdit} size="small" variant="outlined" startIcon={<Edit fontSize="small" />}>Edit</Button>
                  {/* TODO: wire up the new version functionality for client uploads */}
                <Tooltip title="Remove this file">
                  <Button onClick={() => toggleConfirmRemove()} size="small" variant="outlined"><DeleteOutlined fontSize="small" /></Button>
                </Tooltip>
                <Tooltip title="Upload a new version of this file">
                  <Button onClick={() => setIsUploading(true)} disabled={isClientUpload} size="small" variant="outlined" startIcon={<CloudUploadOutlined fontSize="small" />}>Add Version</Button>            
                </Tooltip>
                {/* <Button onClick={handleArchive} size="small" variant="outlined" startIcon={<Archive fontSize="small" />}>Archive</Button>             */}
              </ButtonGroup>
            )}
          </Grid>

          <Grid container sm={12}>
            {isPreviewing && (
              <Box sx={{ width: "100%", minHeight: "calc(100vh - 255px)", position: "relative", bgcolor: "grey.100", border: "1px solid", borderColor: "grey.200", borderRadius: 0.5 }}>
                {(!iframeSrc || !iframeLoaded) && <LoadingBar message="Loading document preview..." />}
                {!!iframeSrc && (
                  <iframe src={iframeSrc} width="100%" height="100%" style={{ border: "none" }} title={document.name} onLoad={() => setIframeLoaded(true)}/>
                )}
              </Box>
            )}
            {!isPreviewing && allowComments && (
              <Paper variant="outlined" sx={{ width: "100%", minHeight: 500, pb: 1 }}>
                <MyConversation />
              </Paper>
            )}
            {!isPreviewing && !allowComments && (
              <Typography variant="body2" color="grey.500" sx={{ fontStyle: "italic" }}>Comments are disabled for your practice</Typography>
            )}
          </Grid>
            
        </Grid>
      </Grid>
      
      <DocumentVersionUploadDialog open={isUploading} onClose={() => setIsUploading(false)} document={document} />
      {toEdit && <UploadDocumentDialog open={!!toEdit} onClose={() => setToEdit(null)} toEdit={toEdit} /> }
      {isConfirmingRemove && <ConfirmDialog open={isConfirmingRemove} onConfirm={handleRemove} onClose={toggleConfirmRemove} title="Remove this file" message="Are you sure you want to remove this file?" />}
    </Grid>
  );
};

const ProviderDocumentView = () => {
  return (
    <ShareProvider>
      <ConversationProvider>
        <DocumentViewCore />
      </ConversationProvider>
    </ShareProvider>
  );
};

export default ProviderDocumentView;