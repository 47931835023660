import { useState } from "react";
import { Button, Chip, TextField, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { AddCircleOutline } from "@mui/icons-material";
import { ClientUploadSettingSchema } from "types/schema";

export interface IUploadSettingsRowProps {
  settings?: ClientUploadSettingSchema[];
  onUpdated: (newValue: ClientUploadSettingSchema[]) => Promise<boolean>;
}

const UploadSettingsRow = ({ settings, onUpdated }: IUploadSettingsRowProps) => {
  const items: ClientUploadSettingSchema[] = settings ?? [];

  const handleAddItem = async (item: ClientUploadSettingSchema) : Promise<boolean> => {
    console.log("add item", item);
    const newValue = [...items, item];
    return await onUpdated(newValue);
  }

  const handleDeleteItem = (index: number) => async () => {
    console.log("delete item", index);
    const newValue = items.filter((_, i) => i !== index);
    return await onUpdated(newValue);
  };

  return (
    <Grid container p={2}>
      <Typography variant="body1">Items you add here will be available to request from your clients with one click.</Typography>
      <CommonUploadsForm onAdd={handleAddItem} />
      <Grid container sm={12} px={2} columnGap={1} rowGap={1.5} sx={{ }}>
        { !items?.length && <Typography variant="caption" fontStyle="italic">No items yet</Typography> }
        {!!items?.length && (
          items.map((item: any, index: number) => (
            <Tooltip key={index} title={`Description: ${item.description}`}>
              <Chip variant="outlined" onDelete={handleDeleteItem(index)} label={item.name} />
            </Tooltip>
          ))
        )}
      </Grid>
    </Grid>
  );
};

export default UploadSettingsRow;

const initialValues: ClientUploadSettingSchema = { name: "", description: "" };

const CommonUploadsForm = ( { onAdd } : { onAdd: (item: ClientUploadSettingSchema) => Promise<boolean> }) => {
  const [values, setValues] = useState<ClientUploadSettingSchema>(initialValues);
  const [error, setError] = useState<string | null>(null);

  const handleChange = (e: any) => {
    const key = e.target.id;
    const val = e.currentTarget.value;
    setValues({ ...values, [key]: val });
    if(error && key === "name") setError(null);
  };

  const handleSave = async () => {
    const isOk = await onAdd(values);
    if(isOk){
      setError(null);
      setValues(initialValues);
    }
  };

  return(
    <Grid container sm={12} p={2} columnGap={1} rowGap={1.5} sx={{ }}>
      <Grid container sm={4}>
        <TextField id="name" value={values.name} onChange={handleChange} required helperText={error} error={!!error} size="small" placeholder="Document Name*" variant="outlined" fullWidth sx={textSx}/>
      </Grid>
      <Grid container sm={6}>
        <TextField id="description" value={values.description} onChange={handleChange} size="small" placeholder="Description" variant="outlined" fullWidth sx={textSx}/>
      </Grid>
      <Grid container sm justifyContent="flex-end" alignItems="center">
        <Tooltip title="Add to list">
          <>
            <Button onClick={handleSave} disabled={!values.name} variant="outlined" size="small" color="primary" startIcon={<AddCircleOutline fontSize="small"/>} >Add</Button>
          </>
        </Tooltip>        
      </Grid>
    </Grid>
  );
};

const textSx = {
  "& .MuiInputBase-root": { fontSize: "1rem", },
}