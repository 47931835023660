import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextDivider from 'components/text-divider';
import AuthProviders from './auth-providers';
import StandardLogin from './standard-login';
import Invitation from './invitation';
import UserType from './user-type';
import { signUpWithEmail, signUpWithProvider } from 'store/actions/app-actions';
import { useQuery } from 'helpers/hooks';
import { useStatusMulti } from 'redux-action-status';
import { Button, Stack } from '@mui/material';

const NON_INVITE = {
  id: "none",
  isWildcard: true,
}

function SignUp({tab, changeTab}){
  const query       = useQuery();
  const [userType, setUserType]   = useState(query.get("type") || null);   //"reviewer" || "user"
  const status      = useStatusMulti(["signIn", "profile"]);
  const inviteRequired = useSelector(state => state.app?.settings?.isInviteRequired);
  const invite      = useSelector(state => state.onboarding.invitation);
  const dispatch    = useDispatch();
  const isReviewer  = Boolean(userType === "reviewer");
  const classes     = useStyles();
  
  const onAuthenticate = async (provider, username, password) => {
    if(!userType) return;
    const myInvite = inviteRequired ? invite : NON_INVITE;

    if(provider){
      await dispatch(signUpWithProvider(provider, myInvite, isReviewer));
    }
    else if(username && password){
      const uname = username.toLowerCase();
      await dispatch(signUpWithEmail(uname, password, myInvite, isReviewer));
    }
  }

  return (
    <Grid container justifyContent="center" className={classes.root}>
      
      {inviteRequired && !invite && <Invitation /> }

      {(invite || !inviteRequired) && 
        <Grid container justifyContent="center">
          
          <Grid item sm={8} container justifyContent="center" className={classes.infoRow}>
            {inviteRequired && <Typography className={classes.info}>Your invitation code has been accepted. Choose an option below to create your account.</Typography>}
            {!inviteRequired && <Typography color="primary" fontSize="1.2rem" textAlign="center" sx={{ mb: 4 }}>Welcome to FormHop. Please choose from the options below to create your account.</Typography>}
          </Grid>
          <Grid item sm={8} container justifyContent="center" className={classes.userTypeGrid}>
            <UserType value={userType} setValue={setUserType} />
          </Grid>

          {!!userType && (
            <>
              {/* <TextDivider text="sign up with" cols={12} textCols={4}/>

              <AuthProviders onAuth={onAuthenticate} isWorking={status.isWorking}/>
              <TextDivider text="or" cols={8} /> */}
              <StandardLogin isConfirm={true} onAuth={(email, pwd) => onAuthenticate(null, email, pwd)} onToggle={(tabIndex) => changeTab(tabIndex)} tab={tab} isWorking={status.isWorking}/>          
            </>
          )}
          {!userType && (
            <Grid item container sm={12} justifyContent="center">
              <Stack direction="row" alignItems="center">
                <Typography fontSize="small">Already have an account?</Typography>
                <Button onClick={() => changeTab(0)} sx={{ textTransform: "none", fontSize: "1rem", fontWeight: "400", padding: "2px 6px",}} size="small" color="primary">Sign in</Button>
              </Stack>
            </Grid>
          )}
        </Grid>
      }

    </Grid>
  );
}

export default SignUp;

const useStyles  = makeStyles(theme => ({
  root      : {
    padding       : `${theme.spacing(2)} ${theme.spacing(1)}`,
  },
  infoRow   : {
    marginBottom  : theme.spacing(1),
  },
  info      : {
    marginBottom  : theme.spacing(1),
    fontSize      : 18,
    fontWeight    : 300,
    color         : theme.palette.grey[600],
    textAlign     : "center",
  },
  userTypeGrid  : {
    marginBottom  : theme.spacing(2),
  },
  userTypeButton  : {
    margin    : `${theme.spacing(0)} ${theme.spacing(2)}`, //`
    fontSize  : 16,
  },
  tosGrid   : {
    marginTop     : theme.spacing(2),
  },
  text   : {
    fontSize      : 14,
    color         : theme.palette.grey[600],
    marginTop     : theme.spacing(1),
    marginBottom  : theme.spacing(1),
    "& a, a:hover"  : {
      // textDecoration  : "none",
      color       : `${theme.palette.primary.light}cc`, //`
    }
  },
  
}));


