import { useState } from "react";
import { SwitchText } from "components";
import { useFieldChanged } from "sections/forms/form-editor-context";
import { IFormFieldInputProps } from "./part-types";
import { useAttorneyFormValue } from "./part-hooks";

//NOTE: MaterialUI Components don't use the native events, so they don't bubble to get handled by the withFieldChange HOC.
// Therefore, we need to explicitly handle the change event here, and call the handleValueChanged function from the region context
const FormSwitchField = ({ field, sectionId, dataProps, rowIndex } : IFormFieldInputProps ) => {
  const defaultValue = field.defaultValue === undefined ? false : !!field.defaultValue;

  const [valueKey, initialValue] = useAttorneyFormValue(field.id, sectionId, rowIndex, defaultValue);
  const onFieldChanged = useFieldChanged(valueKey, field.id, rowIndex);
  const [val, setVal] = useState<boolean>(initialValue);

  const classNames = `form-control-sm field-${field.size ? field.size : "sm"} field-${field.type}`;
  //row index gets assigned as a dataProp
  const labels = { trueLabel: field.config?.trueLabel?.toUpperCase() ?? "YES", falseLabel: field.config?.falseLabel.toUpperCase() ?? "NO" };
  // if(!field.options || field.options.length != 2) throw new Error("Switch field must have exactly 2 options");

  const handleChange = (e: any) => {
    const nextVal = e.target.checked;
    setVal(nextVal);

    if(onFieldChanged){ //  && nextVal !== initialValue){
      onFieldChanged(nextVal);
    }
  }
 
  return (
    <SwitchText 
      id={field.id}
      // {...labels}
      value={val} 
      checked={val}
      // disabled={field.isDisabled} 
      size="small"
      onChange={handleChange}
      className={classNames}
      sx={{ py: 0.5, fontSize: "0.85rem" }}
    />
  );
};

export default FormSwitchField;