import React from 'react';
import _ from 'lodash';

function IfBlock({condition, children}){
  let isConditionMet  = false;
  if(_.isFunction(condition)){
    isConditionMet = condition();
  }
  else{
    isConditionMet = condition;
  }

  return (
    <React.Fragment>
      {isConditionMet && children }
    </React.Fragment>
  )
}

export default IfBlock;