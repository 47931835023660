import poa from "./poa.template.json";
import mopp from "./mopp.template.json";
import dolr from "./dolr.template.json";
import pow from "./pow.template.json";

import c_gdpoa from "./c_gdpoa.template.json";

const adapters: Record<string, any> = {
  //legacy
  poa,
  mopp,
  dolr,
  pow,
  
  //chelsea
  c_gdpoa,
};

export default adapters;