import { useMemo } from "react";
import { Box, Chip, LinearProgress, Tooltip, linearProgressClasses, styled } from "@mui/material";
import { FormInfo } from "types";
import { CheckCircle } from "@mui/icons-material";

export interface IClientFormProgressProps {
  formInfo?: FormInfo;
}

export const ClientFormProgress = ({ formInfo }: IClientFormProgressProps) => {
  const [ percentComplete, completeCount, totalCount ] = useMemo(() => {
    if(!formInfo?.status) return [ 0, -1, 10 ];
    const total = Object.keys(formInfo.status).filter(k => k !== "updatedOn").length;
    const value = Object.keys(formInfo.status).filter(k => formInfo.status[k] === true).length;
    if(total === 0 || value > total) return [ 100, value, total ];
    else return [(Math.round((value / total) * 100)), value, total];
  }, [formInfo?.status]);

  const isSubmitted = useMemo(() => formInfo?.isSubmitted, [formInfo?.isSubmitted]);

  const message = useMemo(() => {
    if(completeCount <= 0) return "not started";
    if(completeCount === totalCount) return "complete";
    return `${percentComplete}% complete`;
  }, [percentComplete, completeCount, totalCount]);

  return (
    <>
      {isSubmitted && (
        <Box width="100%" minWidth="2rem" display="flex">
          <Tooltip title="Submitted">
            <Chip color="primary" icon={<CheckCircle fontSize="small"/>} label="Submitted" />
          </Tooltip>
        </Box>
      )}
      {!isSubmitted && (
        <Box width="100%" minWidth="2rem">
          <Tooltip title={message}>
            <BorderLinearProgress variant="determinate" value={percentComplete} sx={{ height: "0.8rem" }} />
          </Tooltip>
        </Box>
      )}
    </>
  );
};

export default ClientFormProgress;



const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 3,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 3,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    // boxShadow: "0 0 5px rgba(0,0,0,0.2)",
  },
}));