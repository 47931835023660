import { Button, Card, CardContent, Checkbox, FormControlLabel, Stack, Table, TableBody, TableCell, TableContainer, TableRow, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { cardStyles } from "helpers/style-helpers";
import { useClient } from "../../client-context";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createClient, updateClient } from "store/actions/attorney-actions";
import { ClientProfile } from "types";

const initialValues: Partial<ClientProfile> = {
  firstName: "",
  lastName: "",
  isCouple: false,
  partnerFirstName: "",
  partnerLastName: "",
  phone: "",
  email: "",
  address1: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  summary: "",
  areas: [],
};

const ClientProfileCardEdit = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentClient: client, isNew, isEdit } = useClient();
  const [props, setProps]   = useState<Partial<ClientProfile>>({ ...initialValues, ...(client ? client : {}) });
  
  if(!client && !isNew) return null;

  const handleChange  = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key   = e.target.id;
    const val   = e.currentTarget.value;
    setProps({...props, [key]: val});
  };

  const handleCheckChange = (prop: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const key = e.target.id;    
    const items   = (props[prop] || []) as string[];

    if(checked) items.push(key);
    else items.splice(items.indexOf(key), 1);
    setProps({...props, [prop]: items });
  };

  const handleTypeChange = (e: React.MouseEvent<HTMLElement>, value: string[] | null) => {
    if(value && value[0] === "individual") setProps({...props, isCouple: false});
    else if(value && value[0] === "couple") setProps({...props, isCouple: true}); 
  }

  const onSave = async () => {
    if(props.firstName === "" || props.email === ""){
      console.error("missing required field", props);
      return;
    }

    if(isNew){
      const result: any = await dispatch(createClient(props));
      if(result.isOk) {
        const nextUrl = result.data.profile?.id ? `/app/clients/${result.data.profile?.id}` : "/app";
        navigate(nextUrl, { replace: true });
      }
    }
    else if(isEdit){
      // console.error("update client profile not implemented yet", props);
      const result: any = await dispatch(updateClient(props));
      if(result.isOk) navigate(`/app/clients/${result.data.id}`, { replace: true });
    }

  }

  const onCancel = () => {
    const isChanged = JSON.stringify(props) !== JSON.stringify(initialValues);
    if(isChanged) {
      //TODO: if there are changes, confirm with the user they want to lose the changes.
      // return;
    }
    const url = isNew ? "/app" : `/app/clients/${client?.id}`;
    navigate(url, { replace: true });
  }

  return (
    <Card id="client-profile-card" {...cardStyles.primary}>
      <CardContent sx={{ position: "relative" }}>
        <Grid sm={12}>
          <TableContainer>
            <Table>
              <TableBody>
                
                {/* Client Name */}
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <TextField id="firstName" value={props.firstName} onChange={handleChange} label="First name" required size="small"/>
                      <TextField id="lastName" value={props.lastName} onChange={handleChange} label="Last Name" size="small" />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>
                    <ToggleButtonGroup onChange={handleTypeChange}>
                      <ToggleButton value="individual" selected={!props.isCouple}>Individual</ToggleButton>
                      <ToggleButton value="couple" selected={!!props.isCouple}>Couple</ToggleButton>
                    </ToggleButtonGroup>
                  </TableCell>
                </TableRow>

                {/* Partner Name */}
                {props.isCouple && (
                  <TableRow>
                    <TableCell>Partner</TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" gap={2}>
                        <TextField id="partnerFirstName" value={props.partnerFirstName} onChange={handleChange} label="First name" required size="small"/>
                        <TextField id="partnerLastName" value={props.partnerLastName} onChange={handleChange} label="Last Name" size="small" />
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}

                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>
                    <TextField id="email" value={props.email} onChange={handleChange} disabled={!isNew} label="Email Address" required size="small" sx={{ width: "34rem" }} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone</TableCell>
                  <TableCell>
                    <TextField id="phone" value={props.phone} onChange={handleChange} label="Phone Number" size="small" />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ verticalAlign: "top" }}>Address</TableCell>
                  <TableCell>
                    <Grid container gap={1}>
                      <TextField id="address1" value={props.address1} onChange={handleChange} label="Address 1" fullWidth size="small" />
                      <TextField id="address2" value={props.address2} onChange={handleChange} label="Address 2" fullWidth size="small" />
                      <TextField id="city" value={props.city} onChange={handleChange} label="City" size="small" />
                      <TextField id="state" value={props.state} onChange={handleChange} label="State" size="small" sx={{ width: "6rem" }} />
                      <TextField id="zip" value={props.zip} onChange={handleChange} label="Zip" size="small" sx={{ width: "16rem" }} />
                      </Grid>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Areas</TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={2}>
                      <FormControlLabel control={<Checkbox id="estate" checked={props["areas"]?.includes("estate")} onChange={handleCheckChange("areas")} />} label="Estate Planning" />
                      <FormControlLabel control={<Checkbox id="probate" checked={props["areas"]?.includes("probate")} onChange={handleCheckChange("areas")} />} label="Probate" />
                      <FormControlLabel control={<Checkbox id="family" checked={props["areas"]?.includes("family")} onChange={handleCheckChange("areas")} />} label="Family" />
                    </Stack>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ verticalAlign: "top" }}>Summary</TableCell>
                  <TableCell>
                    <TextField id="summary" value={props.summary} onChange={handleChange} label="Client Summary" multiline rows={5} fullWidth size="small" />
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2}>
                    <Grid container justifyContent="flex-end">
                      <Button onClick={onCancel} variant="outlined" color="secondary" size="small" sx={{ mr: 2, minWidth: "6rem" }}>Cancel</Button>
                      <Button onClick={onSave} variant="outlined" color="primary" size="small" sx={{ mr: 2, minWidth: "6rem", marginRight: 0 }}>Save</Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </CardContent>
    </Card>
  );
};


export default ClientProfileCardEdit;