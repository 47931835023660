import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Icon, IconButton, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { saveDataLocal, saveDataToServer } from 'store/actions/value-actions';
import { useRegionStatus } from '../../form-hooks';
// import { useMeasure } from 'react-use';
// import { useEffect } from 'react';

export interface IRegionTabProps {
  items: any[];
  onChanged: (item: any) => void;
}

export interface ITabItemProps {
  item: any;
  onSave?: () => void;
  onChanged: (item: any) => void;
}

function RegionTabs({items, onChanged} : IRegionTabProps){
  const dispatch  = useDispatch();
  // const [ref, { width }] = useMeasure<any>();

  // useEffect(() => {
  //   console.log("region tabs width: ", width);
  // }, [width]);

  const onSave  = async () => {
    await dispatch(saveDataLocal());
    await dispatch(saveDataToServer());
  }

  return (
    <Grid id="region-tabs" container direction="row" justifyContent="flex-start" gap={1} wrap="nowrap">
    {
      items.map(item => {
        return (
          <Grid id={`tab-${item.id}`} key={item.id}>
            <RegionTabItem item={item} onSave={onSave} onChanged={onChanged}/>
          </Grid>
        );
      })
    }
    </Grid>
  );
}

export default RegionTabs;

//=== Tab Item

function RegionTabItem({item, onSave, onChanged}: ITabItemProps){
    const { shareId, documentId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isSmall = useMediaQuery('(max-width:1000px)')
    const isComplete = useRegionStatus(item.id);

    const itemPath = `/app/${shareId}/forms/${documentId}${item.path}`;
    const isSelected = isActive(location, item, itemPath);
    const onClick = async () => {
      if(onSave) await onSave();
      navigate(itemPath);
      onChanged(item);
    };

    const label = isSmall ? "" : item.name ?? item.title;

    return (
      <>
        {/* {isSmall && (
          <IconButton
            onClick={onClick} 
            title={item.tip || item.name} 
            color={isSelected ? "secondary" : "inherit"}
            sx={{ border: isSelected ? undefined : "1px solid transparent", whiteSpace: "nowrap", height: 38.34 }}        
          >
            <Icon>{ isComplete ? "check_circle": item.muiIcon }</Icon>
          </IconButton>
        )} */}

        {/* {!isSmall && ( */}
          <Button 
            variant="outlined"
            onClick={onClick} 
            title={item.tip || item.name} 
            size="small" 
            color={isSelected ? "secondary" : "inherit"}
            startIcon={<Icon>{ isComplete ? "check_circle": item.muiIcon }</Icon>}
            sx={{ border: isSelected ? undefined : "1px solid transparent", whiteSpace: "nowrap", height: 38.34 }}        
          >
            {label}
          </Button>
        {/* )} */}
      </>
    );
}

//====
// Helper Functions
const isActive = (location: any, item: any, path: string, isHash?: boolean) => {
  if(isHash){
    if(location.hash === ""){
      return item.alias && path === location.pathname;
    }
    else{
      return path.replace("/", "") === location.hash;
    }
  }
  else{
    return path === location.pathname;
  }
}

