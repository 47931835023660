import { useState } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
// import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import StorageIcon from '@mui/icons-material/Storage';
import PersonIcon from '@mui/icons-material/Person';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import GroupIcon from '@mui/icons-material/Group';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChevronLeft, ChevronRight, Forum as ForumIcon } from '@mui/icons-material';
import { useAppContext } from 'sections/app/app-context';

const MyNav = () => {
  const classes   = buildStyles();
  const [isCollapsed, setCollapsed]   = useState(false);
  const { allowComments } = useAppContext();

  return (
    <Grid container className={clsx(classes.navWrapper, {"collapsed": isCollapsed})} direction="column" justifyContent="flex-start">
      <NavLink to="/app" className={classes.navItem} title="Back to your Financials"><ArrowBackIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "Back"}</NavLink>
      <NavLink to="/my" end className={classes.navItem} title="My Profile"><PersonIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Profile"}</NavLink>
      {/* <NavLink to="/my/sharing" end className={classes.navItem} title="My Sharing"><GroupIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Sharing"}</NavLink> */}
      <NavLink to="/my/data" end className={classes.navItem} title="My Data"><StorageIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Data"}</NavLink>
      { allowComments && (
        <NavLink to="/my/conversations" end className={classes.navItem} title="My Conversations"><ForumIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Conversations"}</NavLink>
      )}
      {/* <NavLink to="/my/subscription" end className={classes.navItem} title="My Subscription"><ShoppingCartIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Subscription"}</NavLink> */}
      {/* <NavLink to="/my/account" end className={classes.navItem} title="My Account"><PaymentIcon fontSize="small" className={classes.navIcon}/>{isCollapsed ? "" : "My Account"}</NavLink> */}
      <IconButton size="small" className={classes.expandButton} onClick={() => setCollapsed(!isCollapsed)} title="Expand / Collapse Menu">
        {isCollapsed && <ChevronRight fontSize="small" />}
        {!isCollapsed && <ChevronLeft fontSize="small" />}
      </IconButton>
    </Grid>
  );
}

export default MyNav;

const buildStyles   = makeStyles(theme => ({
  navWrapper      : {
    // padding     : theme.spacing(2),
    marginTop  : theme.spacing(4),
    width       : 180,
    "&.collapsed" : {
      width   : 60,
    }
  },
  navItem   : {
    fontSize    : 14,
    fontWeight  : 600,
    color       : theme.palette.grey[800],

    marginBottom  : theme.spacing(2),
    padding       : theme.spacing(1), //`${theme.spacing(1)} ${theme.spacing(1)}`, //`,
    borderRadius  : "0 5px 5px 0", 
    textDecoration  : "none",
    "&:hover"   : {
      textDecoration  : "none",
      background    : `${theme.palette.grey[400]}44`, //`
    },
    "&.active"  : {
      color         : theme.palette.secondary.main,
      background    : `${theme.palette.secondary.light}44`, //`
    }
  },
  navIcon   : {
    marginRight   : theme.spacing(1),
    marginBottom  : theme.spacing(-1),   //`
  },
  disabled  : {
    color       : theme.palette.grey[400],
  },
  expandButton : {
    marginTop     : theme.spacing(2),
    alignSelf     : "flex-start",
    marginLeft    : theme.spacing(1),
  },
}));