import { StandardSection } from '.';
import FinalSection from './final-section';
import { usePetitionerForm } from '../form-hooks';
import { useRegion } from './region-context';
import { useMemo } from 'react';
import ReviewSection from './review-section';
import ListTableSection from './list-table-section';
import { FormRegion, FormSection as SectionConfig } from "types";
import QuestionSection2 from './question-section-2';
import ListSection2 from './list-section-2';

export interface ISectionProps {
  region: FormRegion;
  section: SectionConfig;
  onChange: (sectionId: string, fieldId: string, total: number, details: any, index: number) => void;
}

export const Section = ({section, onChange}: ISectionProps) => {
  const { regions, values } = usePetitionerForm();
  const { region, getSectionFields } = useRegion();
  const fields = useMemo(() => getSectionFields(section.id), [section, getSectionFields]);

  if(!fields || fields.length === 0) return null;

  const onChangeDetails   = (fieldId: string, total: number, details: any, index: number) => { 
    return onChange(section.id, fieldId, total, details, index)
  };

  switch(section.type){
    case "list" : 
      if(section.subtype === "table") return <ListTableSection section={section} values={values} onChange={onChange}/>;
      else return <ListSection2 section={section} fields={fields} values={values} onChange={onChange}/>;
    
    case "questions":
      return <QuestionSection2 section={section} fields={fields} values={values} onChange={onChange}/>;

    case "review":
      return <ReviewSection />

    case "docs":
      return <FinalSection regions={regions} region={region} section={section} values={values} fields={fields}/>

    default : 
      return <StandardSection section={section} onChange={onChange} onChangeDetails={onChangeDetails}/>;
  }
};

export default Section;