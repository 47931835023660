import { Box, Checkbox, Table, TableBody, TableCell, TableRow, Tooltip, Typography, alpha, useTheme } from "@mui/material";
import { AssignableForm } from "./assignable-form-types";
import { AccountCircle, Work } from "@mui/icons-material";

export interface IAssignFormDialogProps {
  items: AssignableForm[];
  onChecked: (formId: string) => void;
  onSelected: (formId: string) => void;
}

const AssignableFormList = ({ items, onChecked, onSelected }: IAssignFormDialogProps) => {
  const theme = useTheme();

  const handleSelectChange = (formId: string) => () => {
    onSelected(formId);
  }

  const handleCheckChange = (formId: string) => () => {
    onChecked(formId);
  }

  return (
    <Table size="small" sx={{ overflow: "scroll" }}>
      <TableBody>
      { items?.length > 0 &&
        items.map(form => {
          return (
            <TableRow key={form.id} onClick={handleSelectChange(form.id)} sx={{ py: 0, cursor: "pointer", bgcolor: form.isSelected ? alpha(theme.palette.primary.light, 0.25) : undefined }}>
              <TableCell sx={{ width: "4rem" }}>
                <Checkbox  checked={form.isChecked} onChange={handleCheckChange(form.id)} />
              </TableCell>
              <TableCell>{form.name}</TableCell>
              <TableCell align="right">
                <Tooltip title={`This form is completed by the ${form.isClientForm ? "client" : "attorney"}`}>
                  {form.isClientForm ? <AccountCircle fontSize="small" /> : <Work fontSize="small" />}
                </Tooltip>
              </TableCell>
            </TableRow>                  
          )
        })
      }
      { items?.length === 0 && (
        <TableRow>
          <TableCell colSpan={2}>
            <Box display="flex" width="100%" alignItems="center" p={2}>
              <Typography>No forms of this type available</Typography>
            </Box>
          </TableCell>
        </TableRow>      
      )}
      </TableBody>
    </Table>
  );
};

export default AssignableFormList;