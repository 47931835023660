import React from 'react';
import _ from 'lodash';
import { Popover, TableCell, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { FormField, FormSection } from 'types';
import { CurrencyField, InputField } from '.';
import { HelpOutline, HelpOutlined, InfoOutlined, QuestionMarkOutlined } from '@mui/icons-material';

export interface ISectionRowProps {
  field: FormField;
  data: Record<string, any>;
  onChangeDetails: (fieldId: string, total: number, details: any, index: number) => void;
  section: FormSection;
}

export const TableSectionRow = ({field, data, onChangeDetails, section}: ISectionRowProps) => {  
  const cols = section.config?.inputHeaders?.length ?? section.config?.columns ?? 1;
  if(cols > 1) return <MultiColumnRow field={field} data={data} onChangeDetails={onChangeDetails} section={section}></MultiColumnRow>;
  else return <SingleColumnRow field={field} data={data} onChangeDetails={onChangeDetails} section={section}></SingleColumnRow>;
}

export const SingleColumnRow = ({field, data, onChangeDetails, section}: ISectionRowProps) => {
  return (
    <TableRow data-type="section-row" className="single-column-row" sx={ section?.config?.rowSx }>
      <FieldNode field={field} data={data} onChangeDetails={onChangeDetails} section={section}></FieldNode>
    </TableRow>
  );
}

export const MultiColumnRow = ({field, data, onChangeDetails, section}: ISectionRowProps) => {
  // const cols = section.config?.inputHeaders?.length ?? section.config?.columns ?? 1;
  const columns = field.childFields ?? [];

  const [helpAnchor, setHelpAnchor] = React.useState<SVGSVGElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<SVGSVGElement>) => {
    setHelpAnchor(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setHelpAnchor(null);
  };

  const open = Boolean(helpAnchor);

  return (
    <TableRow data-type="section-row-multi" className="multi-column-row" sx={ section?.config?.rowSx }>
      <TableCell>
        <Grid container justifyContent="space-between">
          {field.label}
          {field.description && (
            <>
              <HelpOutline 
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                sx={{ color: "info.light", fontSize: "1.2rem", ml: 1 }}
              />
              <Popover
                id="help-popover"
                sx={{ pointerEvents: 'none'}}
                open={open}
                anchorEl={helpAnchor}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left', }}
                transformOrigin={{ vertical: 'top', horizontal: 'left', }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Grid container maxWidth="300px" sx={{ p: 1 }}>
                  <Typography>{field.description}</Typography>
                </Grid>
              </Popover>
          </>
          )}
        </Grid>
      </TableCell>

      {columns.map((field, index) => {
        return (
          <TableCell key={index} >
            {/* TODO: NEXT - deal with total fields */}
            <InputField field={field} onChangeOverride={() => {}} sectionId={section.id} rowIndex={undefined} dataProps={undefined} showLabel={false}></InputField>
          </TableCell>
        );
      })}

    </TableRow>
  );
}

const FieldNode = ({field, data, onChangeDetails, section}: ISectionRowProps) => {
  if(field.type === "content"){
    return (
      <td colSpan={2}>{field.label}</td>
    );
  }
  if(field.type === "checkbox" || field.type === "boolean" || field.type === "text"){
    const cbField   = _.omit(field, ["label"]);

    return (
      <>
        <td>{field.label}</td>
        <td><InputField field={cbField} onChangeOverride={() => {}} sectionId={section.id} rowIndex={undefined} dataProps={undefined} showLabel={undefined}></InputField></td>
      </>
    );
  }
  // else if(field.type === "text"){
  //   return (
  //     <>
  //       <td>{field.label}</td>
  //       <td><InputField field={cbField} data={data} onChangeOverride={() => {}}></InputField></td>
  //     </>
  //   );
  // }
  else{
    //For now, everything else is currency...
    return <CurrencyField field={field} data={data} onChangeDetails={onChangeDetails}></CurrencyField>;
  }
}