import { Chip } from "@mui/material";
import { useMemo } from "react";
import { CurrentClient } from "types";
import { ArchiveOutlined } from "@mui/icons-material";

export interface IClientArchivedChipProps {
  client: CurrentClient | null;
}

const ClientArchivedChip = ({ client } : IClientArchivedChipProps) => {
  const isArchived = useMemo(() => client?.isArchived ?? false, [client]);

  if(!isArchived) return null;

  return (
    <>
      <Chip icon={<ArchiveOutlined fontSize="small" />} color="secondary" label="Archived" />
    </>
  );
};

export default ClientArchivedChip;