import { useCallback, useMemo } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import { Checkbox, FormControl, InputLabel, Typography } from '@mui/material';


//TODO: This needs to be made more flexible and reuasable.
// For now it is just used in the client-upload-request-form.tsx
// but could do some work on it and use it elsewhere.

// export type SelectChoice = {
//   id: string;
//   label: string;
// }

export interface IMultiSelectChipProps {
  options: any[];
  selected: any[];
  onChange: (selected: any[]) => void;
  idProperty?: string;
  displayProperty?: string;
  placeholder?: string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, items: readonly string[], theme: Theme) {
  return {
    fontWeight:
      items.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

// function isSelected(item: string, items: readonly string[]) {
//   return items.indexOf(item) >= 0;
// }

export default function MultipleSelectChip({options, selected, onChange, idProperty, displayProperty, placeholder}: IMultiSelectChipProps) {
  const theme = useTheme();
  // const [selected, setSelected] = useState<any[]>([]);
  const choices = options;

  const getValue = useCallback((item: any) => {
    return idProperty ? item[idProperty] : item;
  }, [idProperty]);

  const getLabel = useCallback((item: any) => {
    return displayProperty ? item[displayProperty] : item;
  }, [displayProperty]);

  const labels = useMemo<string[]>(() => selected.map((item: any) => getLabel(item)), [selected, getLabel]);

  const handleChange = (event: SelectChangeEvent<typeof selected>) => {
    const { target: { value }, } = event;
    console.log("selected: ", value);
    const ids = (typeof value === 'string') ? value.split(',') : value;
    const values = ids.map((val: any) => options.find((opt: any) => getValue(opt) === val));
    // const items = values.map(val => options.find((opt: any) => getValue(opt) === val));
    // setSelected(values);
    onChange(values);
  };


  return (
    <FormControl fullWidth>
      <InputLabel shrink={labels.length > 0 ? true : false} id="multiple-chip-label" sx={{ mt: "-9px", fontSize: "1rem", fontStyle: "italic", color: "grey.600" }}>
        {labels.length > 0 ? ' ' : placeholder}
      </InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        fullWidth
        sx={{ minWidth: 300,  "& .MuiSelect-select": { py: `4px !important` } }}
        size="small"
        value={labels}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
        renderValue={(selected) => (
          <Box id="inner-box" sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {choices.map((choice, index) => (
          <MenuItem
            key={index}
            value={getValue(choice)}
            style={getStyles(choice, labels, theme)}
          >
            <Checkbox checked={labels.indexOf(getLabel(choice)) > -1} />
            {getLabel(choice)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}