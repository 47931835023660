import { Route, Routes } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { Header } from '../financials/layout';
import AttorneyClients from './attorney-clients';
import AttorneyNav from './parts/attorney-nav';
import AttorneyAccount from './provider-account-view';
import ProfileView from '../my/my-profile';
import AttorneyForms from './parts/attorney-forms';
import { AttorneyProvider, useAttorneyContext } from './attorney-context';
import ClientRouter from './client-router';
import ProviderWelcome from './parts/provider-accounts/provider-welcome';
import ProviderOnboarding from './parts/provider-accounts/provider-onboarding';

import { MsalProvider } from "@azure/msal-react";
import { BrowserCacheLocation, Configuration,  PublicClientApplication } from "@azure/msal-browser";
import { CloudStorageProvider } from './parts/provider-accounts/cloud-storage-context/cloud-storage-context';
import { ClientViewWrapper } from './client-view';

//MSAL Configuration
const clientId = process.env.REACT_APP_AZURE_ID;
if(!clientId) throw new Error("Azure Client ID not set");

const configuration: Configuration = {
  auth: {
    clientId,
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: true,   //to help with older browsers
  },
};

const pca = new PublicClientApplication(configuration);

const ProviderViewCore = () => {
  const { account } = useAttorneyContext();

  return (
    <Grid id="attorney-view" container>
      <Grid item sm={12} container direction="column">
        <Header/>
      </Grid>
      
      {!account && ( <ProviderWelcome /> )}
      {account && !account.isOnboarded && ( <ProviderOnboarding />)}

      {account && account.isOnboarded && (
        <Grid item container sx={{ flexWrap: "nowrap" }}>
          <Grid item>
            <AttorneyNav />
          </Grid>
          
          <Grid item sx={{ pl: 2, pr: 1, py: 2, flexGrow: 1, position: "relative" }} container>
            <Routes>
              <Route path="forms" element={<AttorneyForms />} />
              <Route path="profile" element={<ProfileView />} />
              <Route path="account" element={<AttorneyAccount />} />
              <Route path="account/:tab" element={<AttorneyAccount />} />
              <Route path="account/:tab/:subtab" element={<AttorneyAccount />} />
              <Route path="clients/:clientId/*" element={<ClientRouter />} />
              <Route path="clients/edit/:clientId" element={<ClientViewWrapper />} />
              <Route path="/*" element={<AttorneyClients />}/>
            </Routes>
          </Grid>

        </Grid>
      )}

    </Grid>
  );
}


const ProviderView = () => {
  return (
    <MsalProvider instance={pca}>
      <CloudStorageProvider>
        <AttorneyProvider>
          <ProviderViewCore />
        </AttorneyProvider>
      </CloudStorageProvider>
    </MsalProvider>
  );
};

export default ProviderView;
